import React from "react";
import types from "prop-types"
import clsx from "clsx"
import { v4 as makeUuid } from "uuid"
import { withFormsy } from "formsy-react"
import ReactSelect from "react-dropdown-select";
import { ensureDropdownVisible } from "utils/reactSelectHelpers"
import InputErrorMessage from "shared/InputErrorMessage";

// https://github.com/sanusart/react-dropdown-select

const MultiSelect = ({
  border = "thin",
  className = "",
  clearable = false,
  contentRenderer = undefined,
  disabled = false,
  errorMessages = [],
  isPristine = true,
  itemRenderer = undefined,
  dropdownRenderer = undefined,
  name = "",
  onChange,
  options = [],
  placeholder = "Select multiple...",
  searchable = false,
  style = undefined,
  uuid = makeUuid(),
  isFullWidth = true,
  hasBaseInputStyle = true,
  validations = undefined,
  values = [],
  ...rest
}) => {
  const selectedOptions = options.filter((option) => values?.includes(option.value))

  const onSelectChange = (data) => onChange(data.map((opt) => opt.value))
  if (style) {
    const defaultStyle = ReactSelect.defaultProps.style
    style = { ...defaultStyle, ...style, borderRadius: "0.375rem" }
  }
  if (isFullWidth) {
    style = { ...style, width: "100%", borderRadius: "0.375rem" }
  }

  return (
    <>
      <div className={clsx(isFullWidth && "w-full", "react-select")}>
        <ReactSelect
          className={clsx(
            className,
            hasBaseInputStyle && "base-input",
            hasBaseInputStyle && `component-border-${border}`,
          )}
          clearable={clearable}
          contentRenderer={contentRenderer}
          dropdownGap={0}
          id={name || uuid}
          isDisabled={disabled}
          disabled={disabled}
          itemRenderer={itemRenderer}
          dropdownRenderer={dropdownRenderer}
          labelField="label"
          multi
          onChange={onSelectChange}
          onDropdownOpen={ensureDropdownVisible}
          options={options}
          placeholder={placeholder}
          searchable={searchable}
          style={style}
          validations={validations}
          valueField="value"
          values={selectedOptions}
          {...rest}
        />
        <InputErrorMessage isPristine={isPristine} errorMessages={errorMessages} />
      </div>
    </>
  )
}

MultiSelect.propTypes = {
  border: types.oneOf(["thin", "bold", "bolder"]),
  className: types.string,
  clearable: types.bool,
  contentRenderer: types.func,
  disabled: types.bool,
  hasBaseInputStyle: types.bool,
  isFullWidth: types.bool,
  errorMessages: types.arrayOf(types.string),
  isPristine: types.bool,
  itemRenderer: types.func,
  dropdownRenderer: types.func,
  name: types.string,
  onChange: types.func.isRequired,
  options: types.arrayOf(
    types.shape({
      label: types.oneOfType([types.string, types.number]).isRequired,
      value: types.oneOfType([types.string, types.number]).isRequired,
    }),
  ),
  placeholder: types.string,
  searchable: types.bool,
  // eslint-disable-next-line react/forbid-prop-types
  style: types.object,
  uuid: types.string,
  values: types.oneOfType([
    types.arrayOf(types.string),
    types.arrayOf(types.number),
  ]),
  // eslint-disable-next-line react/forbid-prop-types
  validations: types.object,
}

export default MultiSelect

export const FormsyMultiSelect = withFormsy(({ setValue, ...rest }) => (
  <MultiSelect onChange={setValue} {...rest} />
))

FormsyMultiSelect.displayName = "FormsyMultiSelect"
