import React, { useState } from "react"
import types from "prop-types"
import EyeToggle from "./EyeToggle"
import CapsLockMessage from "./CapsLockMessage"

const UserPasswordInput = ({ label = "Password" }) => {
  const [value, setValue] = useState("")
  const updateValue = ({ target }) => setValue(target.value)

  const [isObfuscated, setIsObfuscated] = useState(true)
  const toggleObfuscation = () => setIsObfuscated(!isObfuscated)

  return (
    <>
      <label className="text-left block text-sm font-semibold mb-1" htmlFor="password">
        { label }
      </label>
      <div className="mb-5 relative">
        <input
          id="password"
          type={isObfuscated ? "password" : "text"}
          name="password"
          required
          placeholder="Password..."
          value={value}
          onChange={updateValue}
          className="bordered-base-input !text-sm h-12 login-password-input"
        />
        <EyeToggle isObfuscated={isObfuscated} onClick={toggleObfuscation} />
      </div>
      <CapsLockMessage />
    </>
  )
}

UserPasswordInput.propTypes = {
  label: types.string,
}

export default UserPasswordInput
