import React from "react"
import types from "prop-types"
import * as MultiSelectAttributes from "./MultiSelectAttributes"
import QuestionRequired from "./QuestionRequired"
import QuestionShowDescription from "./QuestionShowDescription"

const MultiSelectConfig = ({ sectionIndex, questionIndex }) => (
  <>
    <QuestionRequired
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <QuestionShowDescription
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <MultiSelectAttributes.DisplayAs
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <MultiSelectAttributes.ColumnCount
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <MultiSelectAttributes.DefaultAnswers
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
  </>
)

MultiSelectConfig.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default MultiSelectConfig
