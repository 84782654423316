import React from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import {
  getFormQuestionDescription, getFormQuestionDescriptionShown, setFormQuestionDescription,
} from "reduxSlices/formBuilderSlice"
import { TextInput } from "shared/inputs"

const QuestionDescription = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const value = useSelector(getFormQuestionDescription({ sectionIndex, questionIndex }))
  const showDescription = useSelector(getFormQuestionDescriptionShown({ sectionIndex, questionIndex }))

  if (!showDescription) return null

  const updateQuestionDescription = (newDescription) => dispatch(setFormQuestionDescription(
    { sectionIndex, questionIndex, newDescription },
  ))

  return (
    <TextInput
      className="px-0"
      placeholder="Description (optional)"
      value={value}
      onChange={updateQuestionDescription}
    />
  )
}

QuestionDescription.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default QuestionDescription
