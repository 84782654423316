import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const EyeToggle = ({ className = "absolute-vertical-center right-3 cursor-pointer", isObfuscated, onClick }) => {
  if (isObfuscated) {
    return (
      <SvgSpriteIcon
        iconName="password-obfuscate"
        className={className}
        onClick={onClick}
      />
    )
  }

  return (
    <SvgSpriteIcon
      iconName="password-visible"
      className={className}
      onClick={onClick}
    />
  )
}

EyeToggle.propTypes = {
  className: types.string,
  isObfuscated: types.bool.isRequired,
  onClick: types.func.isRequired,
}

export default EyeToggle
