import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { areInactiveFormsShown, hideInactiveForms, showInactiveForms } from "reduxSlices/formIndexSlice"
import Switch from "shared/Switch"

const FormIndexSidebarActiveFilter = () => {
  const dispatch = useDispatch()

  const inactiveFormsShown = useSelector(areInactiveFormsShown)

  const toggleReducer = inactiveFormsShown ? hideInactiveForms : showInactiveForms

  const toggleSelection = () => dispatch(toggleReducer())

  return (
    <div className="p-6 border-b border-lightgray-3">
      <div className="font-semibold flex-center mb-2">
        Show inactive forms
        <Switch checked={inactiveFormsShown} onChange={toggleSelection} />
      </div>
    </div>
  )
}

export default FormIndexSidebarActiveFilter
