/* eslint-disable camelcase */
import React from "react"
import types from "prop-types"
import { categoryShape } from "utils/propTypeShapes"
import { AccessLevelSelect } from "shared/selects"
import * as API from "services/api"

const AccessLevelIdentifier = ({
  grantId,
  category,
  accessLevel,
  updateAccessGranteeLists,
  granteeType: grantee_type,
  granteeId: grantee_id,
}) => {
  const updateCategoryAccessGrant = async (access_level) => {
    const access_level_grant = { access_level, grantee_type, grantee_id }
    const response = await API.updateCategoryAccessGrant({
      access_level_grant,
      categorySlug: category.slug,
      grantId,
    })

    if (response.ok) {
      updateAccessGranteeLists(response.data)
    } else {
      console.error("Error updating access level grant: ", response);
    }
  }

  return (
    <AccessLevelSelect value={accessLevel} onChange={updateCategoryAccessGrant} />
  )
}

AccessLevelIdentifier.propTypes = {
  category: categoryShape.isRequired,
  grantId: types.number.isRequired,
  accessLevel: types.string.isRequired,
  updateAccessGranteeLists: types.func.isRequired,
  granteeType: types.string.isRequired,
  granteeId: types.number.isRequired,
}

export default AccessLevelIdentifier
