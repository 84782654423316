// Feature names match those defined in app/models/add_on_feature.rb
export const IMPERSONATION_BY_ORGANIZATION_ADMINS_FEATURE_NAME = "impersonation_by_organization_admins"
export const MFA_FEATURE_NAME = "multifactor_authentication"
export const REPORT_SFTP_FEATURE_NAME = "report_sftp"
export const SCIM_FEATURE_NAME = "scim_user_provisioning"
export const SEND_EMAILS = "send_emails"
export const SURVEY_FEATURE_NAME = "surveys"

export const ADD_ON_FEATURES = [
  IMPERSONATION_BY_ORGANIZATION_ADMINS_FEATURE_NAME,
  MFA_FEATURE_NAME,
  REPORT_SFTP_FEATURE_NAME,
  SCIM_FEATURE_NAME,
  SEND_EMAILS,
  SURVEY_FEATURE_NAME,
]

const READABLE_FEATURE_NAMES = {
  [IMPERSONATION_BY_ORGANIZATION_ADMINS_FEATURE_NAME]: "Impersonation by organization admins",
  [MFA_FEATURE_NAME]: "Multifactor authentication (MFA)",
  [REPORT_SFTP_FEATURE_NAME]: "Report SFTP",
  [SCIM_FEATURE_NAME]: "SCIM user provisioning",
  [SEND_EMAILS]: "Send emails",
  [SURVEY_FEATURE_NAME]: "Surveys",
}

export const addOnFeatureDisplayName = (featureName) => READABLE_FEATURE_NAMES[featureName] ?? featureName.replace(/_-/g, " ")

// Configuration property names match those defined in app/modes/add_on_feature_configuration.rb
export const SCIM_DEPARTMENTS_MANAGED_PROPERTY = "departments-managed-by-scim"

const READABLE_CONFIGURATION_PROPERTY_NAMES = {
  [SCIM_DEPARTMENTS_MANAGED_PROPERTY]: "SCIM manages department memberships",
}

export const addOnFeatureConfigurationPropertyDisplayName = (property) => READABLE_CONFIGURATION_PROPERTY_NAMES[property] ?? property.replace(/[_-]/g, " ")
