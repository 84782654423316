import React from "react"
import types from "prop-types"
import Tooltip from "shared/Tooltip"
import { PrimaryButton } from "shared/buttons"
import withTaskComplete from "./withTaskComplete"

const helpContent = "Please review form for unanswered questions or other validation issues.";
const helpMessage = (taskComplete) => (taskComplete ? null : helpContent);

// Wrapped in withTaskComplete higher order component, which requires task prop
const CloseTaskButton = ({ closeTask, taskComplete }) => (
  <Tooltip text={helpMessage(taskComplete)}>
    <PrimaryButton
      text="Mark as Complete"
      className="bg-dark"
      onClick={closeTask}
      disabled={!taskComplete}
    />
  </Tooltip>
)

CloseTaskButton.propTypes = {
  closeTask: types.func.isRequired,
  taskComplete: types.bool.isRequired,
}

export default withTaskComplete(CloseTaskButton)
