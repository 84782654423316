import React, { useEffect } from "react"
import { useSurveyScheduledReportExportContext } from "contexts/SurveyScheduledReportExportContext"
import {
  DAILY,
  WEEKLY,
  MONTHLY,
} from "utils/recurrenceDetailsHelpers"
import {
  DAYS, MONTHS, WEEKS,
} from "utils/dateHelpers"
import queryParameter from "utils/queryParameter"
import DetailFields from "shared/ScheduledReportExport/DetailFields"

const RANGE_UNIT_BY_FREQUENCY = {}
RANGE_UNIT_BY_FREQUENCY[DAILY] = DAYS
RANGE_UNIT_BY_FREQUENCY[WEEKLY] = WEEKS
RANGE_UNIT_BY_FREQUENCY[MONTHLY] = MONTHS

const NAME_PARAM = "name"
const REPORT_FREQUENCY_PARAM = "frequency"

const unitByFrequency = (frequency) => RANGE_UNIT_BY_FREQUENCY[frequency]

const NewSurveyScheduledReportExportDetailFields = () => {
  const {
    frequency,
    recurrenceDayOfMonth,
    recurrenceDayOfWeek,
    setDateAnswerPreviousRangeUnit,
    setReportFrequency,
    setRecurrenceDayOfMonth,
    setRecurrenceDayOfWeek,
    setScheduledReportExportName,
    setStartDate,
    setSurveySentPreviousRangeUnit,
    scheduledReportExportName,
    startDate,
  } = useSurveyScheduledReportExportContext()

  // When the component is registered, check the query params for name and frequency
  useEffect(() => {
    if (queryParameter(NAME_PARAM)) {
      setScheduledReportExportName(queryParameter(NAME_PARAM))
    }

    if (queryParameter(REPORT_FREQUENCY_PARAM)) {
      setReportFrequency(queryParameter(REPORT_FREQUENCY_PARAM))
      setSurveySentPreviousRangeUnit(unitByFrequency(queryParameter(REPORT_FREQUENCY_PARAM)))
      setDateAnswerPreviousRangeUnit(unitByFrequency(queryParameter(REPORT_FREQUENCY_PARAM)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DetailFields
      frequency={frequency}
      recurrenceDayOfMonth={recurrenceDayOfMonth}
      recurrenceDayOfWeek={recurrenceDayOfWeek}
      setRecurrenceDayOfMonth={setRecurrenceDayOfMonth}
      setRecurrenceDayOfWeek={setRecurrenceDayOfWeek}
      setScheduledReportExportName={setScheduledReportExportName}
      setStartDate={setStartDate}
      scheduledReportExportName={scheduledReportExportName}
      startDate={startDate}
    />
  )
}

export default NewSurveyScheduledReportExportDetailFields
