/* eslint-disable react-hooks/exhaustive-deps, camelcase */
import React, { useEffect } from "react"
import types from "prop-types"
import { useDispatch } from "react-redux"
import { initializeFormBuilderState } from "reduxSlices/formBuilderSlice"
import { initializeWorkflowsState } from "reduxSlices/workflowsSlice"
import { categoryFormBuilderShape } from "utils/propTypeShapes"
import withReduxProvider from "shared/withReduxProvider"
import FormBuilderTabs from "./FormBuilderTabs"

const FormBuilder = ({
  answeredQuestions,
  category,
  form,
  workflows,
}) => {
  const dispatch = useDispatch()

  // When the component mounts, store all the organization's workflows in redux
  // and load the category's public forms
  useEffect(() => {
    dispatch(initializeWorkflowsState({ categorySlug: category.slug, workflows }))
  }, [])

  // When the component mounts, set up form builder data in redux.
  // IF the component unmounts before the thunk is finished, cancel.
  // See https://redux-toolkit.js.org/api/createAsyncThunk#canceling-while-running
  useEffect(() => {
    const promise = dispatch(initializeFormBuilderState({ answeredQuestions, category, form }))

    return () => promise.abort()
  }, [])

  return (
    <div className="form-builder">
      <FormBuilderTabs />
    </div>
  )
}

FormBuilder.propTypes = {
  answeredQuestions: types.arrayOf(types.string).isRequired,
  category: categoryFormBuilderShape.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  form: types.object.isRequired,
  workflows: types.arrayOf(types.object).isRequired,
}

export default withReduxProvider(FormBuilder)
