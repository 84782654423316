import React from "react"
import { useSurveyReportExportContext } from "contexts/SurveyReportExportContext"
import SurveyConfigurationFilters from "./SurveyConfigurationFilters"

const NewSurveyReportExportSurveyFilters = () => {
  const {
    surveyConfigurationsWithBatches,
  } = useSurveyReportExportContext()

  return (
    <>
      {surveyConfigurationsWithBatches.map((configuration) => (
        <SurveyConfigurationFilters surveyConfiguration={configuration} key={`survey-config-${configuration.id}`} />
      ))}
    </>
  )
}

export default NewSurveyReportExportSurveyFilters
