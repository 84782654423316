export const ENCOUNTER_ACCOUNT_FIN_NUMBER_QUESTION_TAG = "encounterAccountFinNumber"
export const ENCOUNTER_ROOM_NUMBER_QUESTION_TAG = "encounterRoomNumber"
export const ENCOUNTER_ADMISSION_DATE_QUESTION_TAG = "encounterAdmissionDate"
export const ENCOUNTER_ATTENDING_PHYSICIAN = "encounterAttendingPhysician"

export const ENCOUNTER_QUESTION_TAGS = [
  ENCOUNTER_ACCOUNT_FIN_NUMBER_QUESTION_TAG,
  ENCOUNTER_ROOM_NUMBER_QUESTION_TAG,
  ENCOUNTER_ADMISSION_DATE_QUESTION_TAG,
  ENCOUNTER_ATTENDING_PHYSICIAN,
]
