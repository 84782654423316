/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef } from "react"
import types from "prop-types"
import { debounce } from "lodash-es"
import { useDispatch, useSelector } from "react-redux"
import { questionShape } from "utils/propTypeShapes"
import { getDraftAnswer, setDraftAnswer } from "reduxSlices/taskDraftAnswersSlice"
import { FormsyMultilineTextInput } from "shared/inputs"

const MultiEntriesAnswerField = ({
  question,
  initialValue = null,
  disabled = false,
  taskId = undefined,
}) => {
  const answer = useRef(initialValue)

  const { required, uuid } = question

  const dispatch = useDispatch()

  // We dont' store drafts when the question appears in a form preview,
  // whic is identified by the lack of a task id.
  const storeDraft = useCallback(debounce(
    (value) => {
      if (taskId) dispatch(setDraftAnswer({ answer: value, questionUuid: uuid, taskId }))
    },
    500,
    { leading: true, trailing: true },
  ), [dispatch, setDraftAnswer])

  const draftAnswer = useSelector(getDraftAnswer({ questionUuid: uuid, taskId }))

  useEffect(() => {
    if (draftAnswer !== null && draftAnswer !== undefined) {
      answer.current = draftAnswer
    }
  }, [draftAnswer, initialValue])

  return (
    <FormsyMultilineTextInput
      className="multi-entries-answer-field"
      disabled={disabled}
      name={uuid}
      onChangeSideEffect={storeDraft}
      placeholder="Add an answer..."
      required={required}
      validations={{
        isNotBlankString: required,
        noExcelMetacharacters: true,
        noHTML: true,
      }}
      value={answer.current || ""}
    />
  )
}

MultiEntriesAnswerField.propTypes = {
  disabled: types.bool,
  initialValue: types.string,
  question: questionShape.isRequired,
  taskId: types.number,
}

export default MultiEntriesAnswerField
