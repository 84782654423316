import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const WorkflowActionRemoveAllMembersIcon = ({ className = "w-8 h-8" }) => (
  <SvgSpriteIcon iconName="workflow-actions-remove-all-members" className={className} />
)

WorkflowActionRemoveAllMembersIcon.propTypes = {
  className: types.string,
}

export default WorkflowActionRemoveAllMembersIcon
