import React, { forwardRef } from "react"
import clsx from "clsx"
import types from "prop-types"
import { useDrop } from "react-dnd"
import { createVerticalStrength, useDndScrolling } from "react-dnd-scrolling"

const useDndScrollZone = (ref) => {
  const linearVerticalStrength = createVerticalStrength(75)

  // To determine acceleration of scroll, utilizes the easeInOutQuad function found in https://gist.github.com/gre/1650294
  //   so that the resulting animation for the user feels smoother
  // Value passed is in range [-1, 1] (denoting direction scroll should occur) but easing expects value in range [0, 1]
  const ease = (val) => {
    const strength = (val + 1) / 2 // [-1, 1] -> [0, 1]
    const easedStrength = strength < 0.5 ? (2 * strength * strength) : (-1 + (4 - 2 * strength) * strength)
    return easedStrength * 2 - 1 // [0, 1] -> [-1, 1]
  }

  // https://github.com/TechStark/react-dnd-scrolling/blob/c3523f827f49494774060e1239d6485d7e5c24f4/src/index.jsx#L35C17-L35C39
  // linearVerticalStrength function returns value between -1 and 1 depending on how far into the buffer arg (# of pixels) the mouse is positioned
  const vStrength = (box, point) => ease(linearVerticalStrength(box, point))

  useDndScrolling(ref, { verticalStrength: vStrength })
}

const SelectionDropZone = forwardRef(({
  acceptableTypes,
  canDropPredicate,
  children,
  className,
  dropFunction,
}, ref) => {
  const [, drop] = useDrop(() => ({
    accept: [acceptableTypes].flat(),
    canDrop: (item) => canDropPredicate(item),
    drop: (item, monitor) => {
      if (monitor.didDrop()) return

      dropFunction(item)
    },
  }), [acceptableTypes, canDropPredicate, dropFunction])

  drop(ref)
  useDndScrollZone(ref)

  return (
    <div
      className={clsx("h-full overflow-y-auto no-scroll-bar", className)}
      ref={drop(ref)}
    >
      { children }
    </div>
  )
})

SelectionDropZone.displayName = "SelectionDropZone"

SelectionDropZone.defaultProps = {
  canDropPredicate: (item) => (item),
  className: "",
  dropFunction: () => {},
}

SelectionDropZone.propTypes = {
  acceptableTypes: types.oneOfType([
    types.string,
    types.arrayOf(types.string),
  ]).isRequired,
  canDropPredicate: types.func,
  children: types.node.isRequired,
  className: types.string,
  dropFunction: types.func,
}

export default SelectionDropZone
