/* eslint-disable react/prop-types */
import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { snakeToCapitalCase } from "utils/stringHelpers"
import {
  FORM_CONDITION_PROPERTIES,
  SUBFORM_CONDITION_PROPERTIES,
  QUESTION_ANSWER_CONDITION_PROPERTY,
  SUBFORM_ANSWER_CONDITION_PROPERTY,
  isGenericFormSubmissionProperty,
} from "utils/workflowHelpers"
import { workflowConditionShape } from "utils/propTypeShapes"
import { Select } from "shared/selects"
import { getFormQuestions } from "reduxSlices/formBuilderSlice"
import { useSelector } from "react-redux"
import {
  SELECT_TYPE,
  SELECT_CSV_TYPE,
  MULTI_SELECT_TYPE,
  TEXT_TYPE,
  LONG_ANSWER_TYPE,
  LOCATION_TYPE,
} from "views/Forms/FormElementTypes"
import FormsyValidation from "shared/FormsyValidation"

const PERMITTED_QUESTION_TYPES_FOR_CONDITION_PROPERTY = [
  SELECT_TYPE,
  SELECT_CSV_TYPE,
  MULTI_SELECT_TYPE,
  TEXT_TYPE,
  LONG_ANSWER_TYPE,
  LOCATION_TYPE,
]

const questionsToPropertyOptions = (questions, conditionProperty) => (
  questions
    .filter(({ type }) => PERMITTED_QUESTION_TYPES_FOR_CONDITION_PROPERTY.includes(type))
    .map(({ uuid, prompt }) => ({
      value: `${conditionProperty}-${uuid}`,
      label: prompt,
    }))
)

const conditionPropertiesToPropertyOptions = (properties) => properties.map((property) => (
  { value: property, label: snakeToCapitalCase(property) }
))

const VariableLabel = ({ option, className }) => {
  if (!option) {
    return <span className="text-placeholder text-sm">Select Property...</span>
  }

  const { value, label } = option

  return (
    <span className={className}>
      { `${isGenericFormSubmissionProperty(value) ? "@" : ""}${label}` }
    </span>
  )
}

const contentRenderer = ({ state }) => {
  const { values } = state
  const [selectedOption] = values
  const variableStyle = selectedOption?.value
    && isGenericFormSubmissionProperty(selectedOption.value)
    && "text-dark font-semibold text-sm"

  return (
    <VariableLabel
      option={selectedOption}
      className={clsx(variableStyle)}
    />
  )
}

const dropdownRenderer = ({ props: { options, updateSubProperty }, methods: { addItem } }) => {
  const variableStyle = (value) => (
    isGenericFormSubmissionProperty(value) && "py-1 px-1.5 bg-lightgray border border-light-300 rounded text-dark"
  )

  const isAnswer = (option) => (option.value.startsWith(QUESTION_ANSWER_CONDITION_PROPERTY)
     || option.value.startsWith(SUBFORM_ANSWER_CONDITION_PROPERTY))

  const questionOptions = options.filter(isAnswer)
  options = options.filter((value) => !isAnswer(value))

  const onQuestionSelect = (option) => () => {
    const [, uuid] = option.value.split("answer-")
    updateSubProperty(uuid)
    addItem(option)
  }

  return (
    <div>
      {
        options.length > 0 && (
          <div className="p-4">
            <div className="text-dark text-xs font-semibold tracking-wider">VARIABLE OPTIONS</div>
            {
              options.map((option) => (
                <div key={option.value} className="mt-3" onClickCapture={() => addItem(option)}>
                  <VariableLabel
                    option={option}
                    className={clsx("text-sm font-medium", variableStyle(option.value))}
                  />
                </div>
              ))
            }
          </div>
        )
      }
      <div className="p-4" style={{ boxShadow: "inset 0px 1px 0px #D6D8E4" }}>
        <div className="text-dark text-xs font-semibold tracking-wider">
          QUESTIONS
        </div>
        {
          questionOptions.map((option) => (
            <div key={option.value} className="mt-3" onClickCapture={onQuestionSelect(option)}>
              <span className="text-sm font-medium">
                { option.label }
              </span>
            </div>
          ))
        }
      </div>
    </div>
  )
}

function calculateInitialValue(condition) {
  if (condition?.property === QUESTION_ANSWER_CONDITION_PROPERTY) {
    return `${QUESTION_ANSWER_CONDITION_PROPERTY}-${condition.subProperty.questionUuid}`
  }
  if (condition?.property === SUBFORM_ANSWER_CONDITION_PROPERTY) {
    return `${SUBFORM_ANSWER_CONDITION_PROPERTY}-${condition.subProperty.questionUuid}`
  }

  return condition?.property
}

const DefineConditionProperty = ({
  isSubform,
  condition,
  conditionIndex,
  changeConditionProperty,
  changeConditionSubProperty,
}) => {
  const initialValue = calculateInitialValue(condition);

  const questions = useSelector(getFormQuestions)

  const options = [
    ...conditionPropertiesToPropertyOptions(isSubform ? SUBFORM_CONDITION_PROPERTIES : FORM_CONDITION_PROPERTIES),
    ...questionsToPropertyOptions(questions,
      isSubform ? SUBFORM_ANSWER_CONDITION_PROPERTY : QUESTION_ANSWER_CONDITION_PROPERTY),
  ]

  const updateSubProperty = (questionUuid) => changeConditionSubProperty({ questionUuid })

  const updateConditionProperty = (newProperty) => {
    const [property] = newProperty.split("-")
    changeConditionProperty(property)
  }

  return (
    <span className="relative">
      <Select
        backspaceDelete={false}
        onChange={updateConditionProperty}
        options={options}
        contentRenderer={contentRenderer}
        dropdownRenderer={dropdownRenderer}
        style={{ height: "32px", minWidth: "180px" }}
        placeholder="Select property... *"
        required
        updateSubProperty={updateSubProperty}
        value={initialValue}
      />
      <FormsyValidation
        name={`conditions[${conditionIndex}].property`}
        value={condition?.property}
        validations={{ isNotBlankString: true }}
        validationErrors={{ isDefaultRequiredValue: "required" }}
      />
    </span>
  )
}

DefineConditionProperty.propTypes = {
  condition: workflowConditionShape.isRequired,
  isSubform: types.bool.isRequired,
  conditionIndex: types.number.isRequired,
  changeConditionProperty: types.func.isRequired,
  changeConditionSubProperty: types.func.isRequired,
}

export default DefineConditionProperty
