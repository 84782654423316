import React from "react"
import types from "prop-types"

const OptionMarker = ({ value = undefined }) => {
  if (!value) return null

  return (
    <span className="text-xs p-1.5 bg-light-100 rounded">{ value }</span>
  )
}

OptionMarker.propTypes = {
  value: types.string,
}

export default OptionMarker
