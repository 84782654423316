/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"
import types from "prop-types"
import clsx from "clsx"

const MassSelection = ({ deselectAll, selectAll, className = "" }) => (
  <div className={clsx("text-xs text-darkgray flex", className)}>
    <span className="mr-4 cursor-pointer" onClick={selectAll}>select all</span>
    <span className="cursor-pointer" onClick={deselectAll}>deselect all</span>
  </div>
)

MassSelection.propTypes = {
  deselectAll: types.func.isRequired,
  selectAll: types.func.isRequired,
  className: types.string,
}

export default MassSelection
