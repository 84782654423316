import React from "react"
import { DndProvider } from "react-dnd"
import { TouchBackend } from "react-dnd-touch-backend"
import { useDispatch, useSelector } from "react-redux"
import { addDepartment, facilityId } from "reduxSlices/adminFacilitySlice"
import { useSCIMResourceManagement } from "hooks"
import CreateDepartmentModal from "shared/modals/CreateDepartmentModal"
import DepartmentList from "./DepartmentList"
import DepartmentMembersDragLayer from "./DepartmentMembersDragLayer"
import DisplayOnlyMembers from "./DisplayOnlyMembers"
import SearchableMembers from "./SearchableMembers"

const DepartmentsTab = () => {
  const dispatch = useDispatch()

  const id = useSelector(facilityId)

  const { departmentsManagedBySCIM } = useSCIMResourceManagement()

  const storeNewDepartment = (department) => dispatch(addDepartment(department))

  return (
    <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
      <div className="flex justify-between">
        <div className="flex-grow max-w-45">
          <h2 className="tab-title">Departments</h2>
          <DepartmentList />
          <CreateDepartmentModal
            facilityId={id}
            onDepartmentCreated={storeNewDepartment}
          />
        </div>
        <div className="flex-grow max-w-45 sticky top-20 max-h-116 flex flex-col">
          <h2 className="tab-title">Users</h2>
          {
            departmentsManagedBySCIM
              ? <DisplayOnlyMembers />
              : <SearchableMembers />
          }
        </div>
      </div>
      <DepartmentMembersDragLayer />
    </DndProvider>
  )
}

export default DepartmentsTab
