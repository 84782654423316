import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const BullhornIcon = ({ className = "w-8 h-8", ...rest }) => <SvgSpriteIcon iconName="bullhorn" className={className} {...rest} />

BullhornIcon.propTypes = {
  className: types.string,
}

export default BullhornIcon
