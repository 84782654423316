/* eslint-disable no-use-before-define */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  clone, cloneDeep, isEmpty, isNil, set,
} from "lodash-es"
import * as API from "services/api"
import {
  ADD_OPERATOR,
  MAX_QUESTION_COUNT_FOR_NUMBER_CALCULATION_NON_ADDITION_OPERATIONS,
} from "utils/calculationOperators"
import { buildCondition, changeConditionOperator, changeConditionQuestionUuid } from "utils/Condition"
import {
  DEFAULT_CONDITIONALS,
  appliedQuestionTags,
  appliedReportableFields,
  canonicalOptionforOptionCount,
  canonicalPromptForPromptCount,
  filteredQuestionList,
  formQuestionConditionQuestions,
  formQuestionLocations,
  formQuestionOptionCounts,
  formQuestionOptionsInFlagConditions,
  formQuestionOptionsInWorkflowConditions,
  formQuestionsInWorkflowActions,
  formSectionConditionQuestions,
  makeQuestion,
  makeSection,
  makeUuid,
  optionCounts,
  optionsInFlagCondition,
  preventQuestionDeletionDescriptions,
  preventSectionDeletionDescriptions,
  questionList,
  questionListFromOutsideSection,
  questionPromptCounts,
  questionOptionUsageDescriptions,
  undeleteInsertIndexes,
} from "utils/formDataHelpers"
import { reportableFieldsByType } from "utils/reportableFieldHelpers"
import { makeFlagCondition } from "utils/FlagCondition"
import {
  LOCATION_TYPE,
  MULTI_ENTRIES_TYPE,
  NOTE_ANSWER_TYPE,
  NUMBER_CALCULATION_TYPE,
  NUMBER_TYPE,
  TIME_TYPE,
  TIME_DIFFERENCE_TYPE,
  answerMayBeRequired,
  initialAttributes,
  reportableFieldQuestionType,
} from "components/views/Forms/FormElementTypes"

export const SLICE_NAME = "formBuilder"

const INITIAL_STATE = {
  formBuilderStateReady: false,
  isWorkflowFormVisible: false,
  newWorkflowName: "",
  selectedWorkflow: null,
  category: {},
  form: {},
  promptCounts: {},
  formQuestionFocus: {
    sectionIndex: 0,
    questionIndex: 0,
  },
  deletedQuestion: null,
  reportableFields: [],
  usedReportableFieldIds: [],
  workflows: [],
  groups: [],
  users: [],
  categoryStatuses: [],
  categorySubforms: [],
  questionTags: [],
  departments: [],
  facilities: [],
}

export const recordActive = (record) => (record.active)

export const valueMatches = (optionValue, selectedValue) => (
  selectedValue && (Array.isArray(selectedValue) ? selectedValue.includes(optionValue) : selectedValue === optionValue)
)

// Called by initializeFormBuilderState() to build redux state
// from API calls.  If an API call does not return ok,
// then we throw rejectWithValue() from the thunkAPI object.
const addResponseData = (base, property, response, thunkAPI) => {
  if (response.ok) {
    base[property] = response.data
  } else {
    throw thunkAPI.rejectWithValue(response.data)
  }
}

export const initializeFormBuilderState = createAsyncThunk(
  `${SLICE_NAME}/initilizeFormBuilderState`,
  // eslint-disable-next-line no-unused-vars
  async ({ answeredQuestions, category, form }, thunkAPI) => {
    const { slug: formSlug } = form
    const { slug: categorySlug } = category

    const answeredQuestionsObject = answeredQuestions.reduce((aggregagtor, questionUuid) => (
      { ...aggregagtor, [questionUuid]: true }
    ), {})

    const updatedData = {
      answeredQuestions: answeredQuestionsObject,
      category,
      form,
      formBuilderStateReady: true,
      formQuestions: questionList(form),
      promptCounts: questionPromptCounts({ form }),
      questionLocations: formQuestionLocations({ form }),
      questionOptionCounts: formQuestionOptionCounts({ form }),
      questionOptionsInFlagConditions: formQuestionOptionsInFlagConditions({ form }),
      sectionConditionQuestions: formSectionConditionQuestions({ form }),
      questionConditionQuestions: formQuestionConditionQuestions({ form }),
      appliedReportableFields: appliedReportableFields({ form }),
      appliedQuestionTags: appliedQuestionTags({ form }),
    }

    const [
      workflowsResponse,
      usersResponse,
      groupsResponse,
      statusesResponse,
      departmentsResponse,
      facilitiesResponse,
      facilityGroupCodesResponse,
      categorySubformsResponse,
      questionTagResponse,
      reportableFieldsResponse,
    ] = await Promise.all([
      API.getFormWorkflows({ formSlug }),
      API.getUsers({ active: true }),
      API.getGroups(),
      API.getCategoryStatuses({ categorySlug }),
      API.getDepartments(),
      API.getFacilities(),
      API.facilityGroupCodes(),
      API.getCategoryActiveSubformOptions({ categorySlug }),
      API.questionTags(),
      API.getCategoryReportableFields({ categorySlug }),
    ])

    addResponseData(updatedData, "workflows", workflowsResponse, thunkAPI)
    addResponseData(updatedData, "users", usersResponse, thunkAPI)
    addResponseData(updatedData, "groups", groupsResponse, thunkAPI)
    addResponseData(updatedData, "categoryStatuses", statusesResponse, thunkAPI)
    addResponseData(updatedData, "departments", departmentsResponse, thunkAPI)
    addResponseData(updatedData, "facilities", facilitiesResponse, thunkAPI)
    addResponseData(updatedData, "facilityGroupCodes", facilityGroupCodesResponse, thunkAPI)
    addResponseData(updatedData, "categorySubforms", categorySubformsResponse, thunkAPI)
    addResponseData(updatedData, "questionTags", questionTagResponse, thunkAPI)

    if (reportableFieldsResponse.ok) {
      updatedData.reportableFieldsByType = reportableFieldsByType({ reportableFields: reportableFieldsResponse.data })
    } else {
      throw thunkAPI.rejectWithValue(reportableFieldsResponse.data)
    }

    updatedData.questionOptionsInWorkflowConditions = formQuestionOptionsInWorkflowConditions({ workflows: updatedData.workflows })
    updatedData.questionsInWorkflowActions = formQuestionsInWorkflowActions({ workflows: updatedData.workflows })

    return updatedData
  },
)

export const formBuilderSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setFormValueByPath: (state, { payload }) => {
      const { path, value } = payload
      set(state.form, path, value)
    },
    setFormDescription: (state, { payload: description }) => {
      state.form.description = description
    },
    setFormIcon: (state, { payload: iconData }) => {
      state.form = { ...state.form, ...iconData }
    },
    setFormAcceptsFiles: (state, { payload: acceptsSubmittedFiles }) => {
      state.form.acceptsSubmittedFiles = acceptsSubmittedFiles
    },
    setFormActive: (state, { payload: active }) => {
      state.form.active = active
    },
    setFormListed: (state, { payload: listed }) => {
      state.form.listed = listed
    },
    setFormTitle: (state, { payload: title }) => {
      state.form.title = title
    },
    setFormPublicSubmissionStatus: (state, { payload: publicSubmissionStatus }) => {
      state.form.publicSubmissionStatus = publicSubmissionStatus
    },
    setFormTooltipDescription: (state, { payload: tooltipDescription }) => {
      state.form.tooltipDescription = tooltipDescription
    },
    addFormSection: (state) => {
      state.form.sections.push(makeSection())

      state.promptCounts = questionPromptCounts({ form: state.form })
      state.questionLocations = formQuestionLocations({ form: state.form })
      state.questionOptionCounts = formQuestionOptionCounts({ form: state.form })
      state.sectionConditionQuestions = formSectionConditionQuestions({ form: state.form })
      state.questionConditionQuestions = formQuestionConditionQuestions({ form: state.form })

      state.formQuestionFocus = {
        sectionIndex: state.form.sections.length - 1,
        questionIndex: 0,
      }
    },
    deleteFormSection: (state, { payload: sectionIndex }) => {
      state.form.sections = state.form.sections.filter((_val, index) => index !== sectionIndex)
      state.promptCounts = questionPromptCounts({ form: state.form })
      state.questionLocations = formQuestionLocations({ form: state.form })
      state.questionOptionCounts = formQuestionOptionCounts({ form: state.form })
      state.questionOptionsInFlagConditions = formQuestionOptionsInFlagConditions({ form: state.form })
      state.sectionConditionQuestions = formSectionConditionQuestions({ form: state.form })
      state.questionConditionQuestions = formQuestionConditionQuestions({ form: state.form })
    },
    addFormQuestion: (state, { payload: sectionIndex }) => {
      const selectedSection = state.form.sections[sectionIndex]
      const { questions } = selectedSection
      const lastQuestionType = questions[questions.length - 1]?.type || "text"
      questions.push(makeQuestion({ type: lastQuestionType }))

      state.promptCounts = questionPromptCounts({ form: state.form })
      state.questionLocations = formQuestionLocations({ form: state.form })
      state.questionOptionCounts = formQuestionOptionCounts({ form: state.form })

      state.formQuestionFocus = {
        sectionIndex,
        questionIndex: questions.length - 1,
      }
    },
    restoreFormQuestion: (state, { payload }) => {
      const { question } = payload
      const sectionIndex = state.form.sections.length - 1;
      const lastSection = state.form.sections[sectionIndex]
      lastSection.questions.push(question)
      state.form.deletedQuestions = state.form.deletedQuestions.filter((deletedQuestion) => (
        deletedQuestion.uuid !== question.uuid
      ))

      state.promptCounts = questionPromptCounts({ form: state.form })
      state.questionLocations = formQuestionLocations({ form: state.form })
      state.questionOptionCounts = formQuestionOptionCounts({ form: state.form })
      state.questionConditionQuestions = formQuestionConditionQuestions({ form: state.form })

      const { uuid, flagCondition } = question

      if (flagCondition) {
        state.questionOptionsInFlagConditions[uuid] = optionsInFlagCondition(flagCondition)
      }

      state.formQuestionFocus = {
        sectionIndex,
        questionIndex: lastSection.questions.length - 1,
      }
    },
    deleteFormQuestion: (state, { payload }) => {
      const { sectionIndex, questionIndex } = payload
      const selectedSection = state.form.sections[sectionIndex]
      const { questions } = selectedSection

      const deletedQuestion = selectedSection.questions[questionIndex]
      const { uuid } = deletedQuestion

      selectedSection.questions = questions.filter((_q, qIndex) => qIndex !== questionIndex)

      state.promptCounts = questionPromptCounts({ form: state.form })
      state.questionLocations = formQuestionLocations({ form: state.form })
      state.questionOptionCounts = formQuestionOptionCounts({ form: state.form })
      state.questionConditionQuestions = formQuestionConditionQuestions({ form: state.form })
      delete state.questionOptionsInFlagConditions[uuid]

      state.deletedQuestion = {
        value: deletedQuestion,
        sectionIndex,
        questionIndex,
      }
    },
    undeleteFormQuestion: (state) => {
      if (!state.deletedQuestion) return

      const insertIndexes = undeleteInsertIndexes(
        state.form,
        state.deletedQuestion.sectionIndex,
        state.deletedQuestion.questionIndex,
      )
      const {
        sectionIndex: sectionInsertIndex,
        questionIndex: questionInsertIndex,
      } = insertIndexes

      const selectedSection = state.form.sections[sectionInsertIndex]
      const { questions } = selectedSection

      const { value: undeletedQuestion } = state.deletedQuestion
      questions.splice(questionInsertIndex, 0, undeletedQuestion)

      state.promptCounts = questionPromptCounts({ form: state.form })
      state.questionLocations = formQuestionLocations({ form: state.form })
      state.questionOptionCounts = formQuestionOptionCounts({ form: state.form })
      state.questionConditionQuestions = formQuestionConditionQuestions({ form: state.form })

      const { uuid, flagCondition } = undeletedQuestion

      if (flagCondition) {
        state.questionOptionsInFlagConditions[uuid] = optionsInFlagCondition(flagCondition)
      }

      state.deletedQuestion = null;
      state.formQuestionFocus = {
        sectionIndex: sectionInsertIndex,
        questionIndex: questionInsertIndex,
      }
    },
    copyFormQuestion: (state, { payload }) => {
      const { sectionIndex, questionIndex } = payload
      const selectedSection = state.form.sections[sectionIndex]
      const { questions } = selectedSection
      const questionToCopy = questions[questionIndex]

      const copiedQuestion = {
        ...clone(questionToCopy, true),
        uuid: makeUuid(),
        prompt: `${questionToCopy.prompt} (copy)`,
        reportableFieldId: null,
      }
      questions.splice(questionIndex + 1, 0, copiedQuestion)

      state.promptCounts = questionPromptCounts({ form: state.form })
      state.questionLocations = formQuestionLocations({ form: state.form })
      state.questionOptionCounts = formQuestionOptionCounts({ form: state.form })
      state.questionConditionQuestions = formQuestionConditionQuestions({ form: state.form })

      const { flagCondition, uuid } = copiedQuestion

      if (flagCondition) {
        state.questionOptionsInFlagConditions[uuid] = optionsInFlagCondition(flagCondition)
      }

      state.formQuestionFocus = { sectionIndex, questionIndex: questionIndex + 1 }
    },
    setFormQuestionDescription: (state, { payload }) => {
      const { sectionIndex, questionIndex, newDescription } = payload

      state.form.sections[sectionIndex].questions[questionIndex].description = newDescription
    },
    deleteFormQuestionOption: (state, { payload }) => {
      const { sectionIndex, questionIndex, optionIndex } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { uuid } = question

      const currentOptions = question.attributes?.options ?? []
      const newOptions = [...currentOptions]
      newOptions.splice(optionIndex, 1)

      question.attributes.options = newOptions

      state.questionOptionCounts[uuid] = optionCounts(question.attributes.options)
    },
    updateFormQuestionOption: (state, { payload }) => {
      const {
        sectionIndex, questionIndex, optionIndex, newOption = "",
      } = payload

      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { uuid } = question

      // Check to see if this is a new option being added
      if (!isNil(question.attributes.options[optionIndex])) {
        const canonicalOldOption = canonicalOptionforOptionCount(question.attributes.options[optionIndex])

        if (state.questionOptionCounts[uuid][canonicalOldOption] <= 1) {
          delete state.questionOptionCounts[uuid][canonicalOldOption]
        } else {
          state.questionOptionCounts[uuid][canonicalOldOption] -= 1
        }
      }

      question.attributes.options[optionIndex] = newOption
      const canonicalNewOption = canonicalOptionforOptionCount(newOption)

      if (state.questionOptionCounts[uuid][canonicalNewOption]) {
        state.questionOptionCounts[uuid][canonicalNewOption] += 1
      } else {
        state.questionOptionCounts[uuid][canonicalNewOption] = 1
      }
    },
    moveFormQuestionOption: (state, { payload }) => {
      const {
        sectionIndex, questionIndex, sourceIndex, destinationIndex,
      } = payload

      const currentOptions = state.form.sections[sectionIndex].questions[questionIndex].attributes?.options ?? []
      const newOptions = [...currentOptions]
      const [optionToMove] = newOptions.splice(sourceIndex, 1)
      newOptions.splice(destinationIndex, 0, optionToMove)

      state.form.sections[sectionIndex].questions[questionIndex].attributes.options = newOptions
    },
    setFormQuestionPrompt: (state, { payload }) => {
      const { sectionIndex, questionIndex, newPrompt } = payload
      const canonicalOldPrompt = canonicalPromptForPromptCount(state.form.sections[sectionIndex].questions[questionIndex].prompt)
      const canonicalNewPrompt = canonicalPromptForPromptCount(newPrompt)

      state.form.sections[sectionIndex].questions[questionIndex].prompt = newPrompt

      if (state.promptCounts[canonicalOldPrompt] <= 1) {
        delete state.promptCounts[canonicalOldPrompt]
      } else {
        state.promptCounts[canonicalOldPrompt] -= 1
      }

      if (state.promptCounts[canonicalNewPrompt]) {
        state.promptCounts[canonicalNewPrompt] += 1
      } else {
        state.promptCounts[canonicalNewPrompt] = 1
      }
    },
    setFormQuestionType: (state, { payload }) => {
      const { sectionIndex, questionIndex, newType } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { uuid } = question

      question.type = newType
      question.attributes = initialAttributes({ questionType: newType })
      delete question.flagCondition

      if (!answerMayBeRequired({ questionType: newType })) {
        question.required = false
      }

      const { options = [] } = question.attributes
      state.questionOptionCounts[uuid] = optionCounts(options)
      delete state.questionOptionsInFlagConditions[uuid]
    },
    moveFormSection: (state, { payload: action }) => {
      const newSections = [...state.form.sections]
      const { from, to } = action
      const [removed] = newSections.splice(from.currentIndex, 1)

      newSections.splice(to.newIndex, 0, removed)
      state.form.sections = newSections

      state.formQuestionFocus = {
        ...state.formQuestionFocus,
        sectionIndex: to.newIndex,
      }

      state.questionLocations = formQuestionLocations({ form: state.form })
      state.sectionConditionQuestions = formSectionConditionQuestions({ form: state.form })
      state.questionConditionQuestions = formQuestionConditionQuestions({ form: state.form })
    },
    moveFormQuestion: (state, { payload: action }) => {
      const { sections } = state.form
      const { from, to } = action

      const fromSectionIndex = sections.findIndex(({ uuid }) => uuid === from.sectionUuid)
      const toSectionIndex = sections.findIndex(({ uuid }) => uuid === to.sectionUuid)
      const fromQuestions = sections[fromSectionIndex].questions
      const questionToMove = fromQuestions[from.currentIndex]

      fromQuestions.splice(from.currentIndex, 1)

      if (fromSectionIndex === toSectionIndex) {
        fromQuestions.splice(to.newIndex, 0, questionToMove)
      } else {
        const toQuestions = sections[toSectionIndex].questions || []

        toQuestions.splice(to.newIndex, 0, questionToMove)
        sections[toSectionIndex].questions = toQuestions
      }

      sections[fromSectionIndex].questions = fromQuestions

      state.formQuestionFocus = { sectionIndex: toSectionIndex, questionIndex: to.newIndex }
      state.questionLocations = formQuestionLocations({ form: state.form })
      state.questionConditionQuestions = formQuestionConditionQuestions({ form: state.form })
    },
    setQuestionFocus: (state, { payload }) => {
      state.formQuestionFocus = payload
    },
    setFormQuestions: (state, action) => {
      state.formQuestions = action.payload
    },

    // Reducers for working with a question's flag condition
    addQuestionFlagCondition: (state, { payload }) => {
      const { sectionIndex, questionIndex } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { type, uuid } = question
      const newFlagCondition = makeFlagCondition(type)

      question.flagCondition = newFlagCondition
      state.questionOptionsInFlagConditions[uuid] = optionsInFlagCondition(newFlagCondition)
    },
    removeQuestionFlagCondition: (state, { payload }) => {
      const { sectionIndex, questionIndex } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { uuid } = question

      delete question.flagCondition
      delete state.questionOptionsInFlagConditions[uuid]
    },
    setQuestionFlagConditionOperator: (state, { payload }) => {
      const { sectionIndex, questionIndex, newOperator } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { flagCondition, uuid } = question

      flagCondition.operator = newOperator
      flagCondition.matchValue = null

      state.questionOptionsInFlagConditions[uuid] = optionsInFlagCondition(flagCondition)
    },
    setQuestionFlagConditionMatchValue: (state, { payload }) => {
      const { sectionIndex, questionIndex, newMatchValue } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { flagCondition, uuid } = question

      flagCondition.matchValue = newMatchValue

      state.questionOptionsInFlagConditions[uuid] = optionsInFlagCondition(flagCondition)
    },

    // Reducers for working with the form's workflows
    setNewWorkflowName: (state, { payload }) => {
      state.newWorkflowName = payload
    },
    setSelectedWorkflow: (state, { payload }) => {
      state.selectedWorkflow = payload
    },
    showWorkflowForm: (state) => {
      state.isWorkflowFormVisible = true
    },
    hideWorkflowForm: (state) => {
      state.isWorkflowFormVisible = false
    },
    setWorkflows: (state, action) => {
      state.workflows = action.payload
      state.questionOptionsInWorkflowConditions = formQuestionOptionsInWorkflowConditions({ workflows: state.workflows })
      state.questionsInWorkflowActions = formQuestionsInWorkflowActions({ workflows: state.workflows })
    },
    setWorkflowActiveStatus: (state, action) => {
      const { payload } = action
      const { id, active } = payload

      const workflowIndex = state.workflows.findIndex((workflow) => workflow.id === id)
      state.workflows[workflowIndex].active = active
    },
    removeWorkflow: (state, action) => {
      const id = action.payload
      const workflowIndex = state.workflows.findIndex((workflow) => workflow.id === id)

      state.workflows.splice(workflowIndex, 1)
      state.questionOptionsInWorkflowConditions = formQuestionOptionsInWorkflowConditions({ workflows: state.workflows })
      state.questionsInWorkflowActions = formQuestionsInWorkflowActions({ workflows: state.workflows })
    },
    addWorkflow: (state, action) => {
      state.workflows.push(action.payload)
      state.questionOptionsInWorkflowConditions = formQuestionOptionsInWorkflowConditions({ workflows: state.workflows })
      state.questionsInWorkflowActions = formQuestionsInWorkflowActions({ workflows: state.workflows })
    },

    // Reducers for working with section branching conditions
    addSectionBranchingCondition: (state, { payload }) => {
      const { sectionIndex } = payload
      const section = state.form.sections[sectionIndex]

      if (!section.conditionals) {
        section.conditionals = cloneDeep(DEFAULT_CONDITIONALS)
      }

      const mockGlobalState = { [SLICE_NAME]: state }

      const unusedQuestionOptions = getFormSectionBranchingConditionUnusedQuestionOptions(sectionIndex)(mockGlobalState)
      const newConditionQuestion = unusedQuestionOptions[0]

      if (!newConditionQuestion) return

      const { type: questionType, uuid: questionUuid } = newConditionQuestion

      const newCondition = buildCondition({ questionType, questionUuid })

      section.conditionals.conditions.push(newCondition)
      state.sectionConditionQuestions = formSectionConditionQuestions({ form: state.form })
    },
    replaceSectionBranchingConditionWithConditionals: (state, { payload }) => {
      const { sectionIndex } = payload
      const section = state.form.sections[sectionIndex]
      const { condition } = section

      const newConditionals = cloneDeep(DEFAULT_CONDITIONALS)
      newConditionals.conditions = [condition]

      section.conditionals = newConditionals
      delete section.condition
      state.sectionConditionQuestions = formSectionConditionQuestions({ form: state.form })
    },
    removeSectionBranchingConditionalsCondition: (state, { payload }) => {
      const { sectionIndex, conditionIndex } = payload
      state.form.sections[sectionIndex].conditionals.conditions.splice(conditionIndex, 1)
      state.sectionConditionQuestions = formSectionConditionQuestions({ form: state.form })
    },
    updateSectionBranchingConditionalsControl: (state, { payload }) => {
      const { sectionIndex, newControl } = payload
      const section = state.form.sections[sectionIndex]
      const { conditionals } = section

      if (!conditionals) return

      conditionals.control = newControl
    },
    updateSectionBranchingConditionMatchValue: (state, { payload }) => {
      const { sectionIndex, conditionIndex, newMatchValue } = payload
      const section = state.form.sections[sectionIndex]
      const { conditionals = {} } = section
      const { conditions = [] } = conditionals
      const condition = conditions[conditionIndex]

      condition.matchValue = newMatchValue
      state.sectionConditionQuestions = formSectionConditionQuestions({ form: state.form })
    },
    updateSectionBranchingConditionOperator: (state, { payload }) => {
      const { sectionIndex, conditionIndex, newOperator } = payload
      const section = state.form.sections[sectionIndex]
      const { conditionals = {} } = section
      const { conditions = [] } = conditionals
      const condition = conditions[conditionIndex]
      const { questionUuid: conditionQuestionUuid } = condition

      const mockGlobalState = { [SLICE_NAME]: state }
      const conditionQuestionType = getFormQuestionTypeByUuid(conditionQuestionUuid)(mockGlobalState)

      changeConditionOperator({ condition, newOperator, conditionQuestionType })
      state.sectionConditionQuestions = formSectionConditionQuestions({ form: state.form })
    },
    updateSectionBranchingConditionQuestionUuid: (state, { payload }) => {
      const { sectionIndex, conditionIndex, newQuestionUuid } = payload
      const section = state.form.sections[sectionIndex]
      const { conditionals = {} } = section
      const { conditions = [] } = conditionals
      const condition = conditions[conditionIndex]

      const mockGlobalState = { [SLICE_NAME]: state }
      const newQuestionType = getFormQuestionTypeByUuid(newQuestionUuid)(mockGlobalState)

      changeConditionQuestionUuid({ condition, newQuestionType, newQuestionUuid })
      state.sectionConditionQuestions = formSectionConditionQuestions({ form: state.form })
    },

    // Reducers for working with question branching conditions
    addQuestionBranchingCondition: (state, { payload }) => {
      const { sectionIndex, questionIndex } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]

      if (!question.conditionals) {
        question.conditionals = cloneDeep(DEFAULT_CONDITIONALS)
      }

      const mockGlobalState = { [SLICE_NAME]: state }

      const unusedQuestionOptions = getFormQuestionBranchingConditionUnusedQuestionOptions({ sectionIndex, questionIndex })(mockGlobalState)
      const newConditionQuestion = unusedQuestionOptions[0]

      if (!newConditionQuestion) return

      const { type: questionType, uuid: questionUuid } = newConditionQuestion

      const newCondition = buildCondition({ questionType, questionUuid })

      question.conditionals.conditions.push(newCondition)
      state.questionConditionQuestions = formQuestionConditionQuestions({ form: state.form })
    },
    replaceQuestionBranchingConditionWithConditionals: (state, { payload }) => {
      const { sectionIndex, questionIndex } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { condition } = question

      const newConditionals = cloneDeep(DEFAULT_CONDITIONALS)
      newConditionals.conditions = [condition]

      question.conditionals = newConditionals
      delete question.condition
      state.questionConditionQuestions = formQuestionConditionQuestions({ form: state.form })
    },
    removeQuestionBranchingConditionalsCondition: (state, { payload }) => {
      const { sectionIndex, questionIndex, conditionIndex } = payload
      state.form.sections[sectionIndex].questions[questionIndex].conditionals.conditions.splice(conditionIndex, 1)
      state.questionConditionQuestions = formQuestionConditionQuestions({ form: state.form })
    },
    updateQuestionBranchingConditionalsControl: (state, { payload }) => {
      const { sectionIndex, questionIndex, newControl } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { conditionals } = question

      if (!conditionals) return

      conditionals.control = newControl
    },
    updateQuestionBranchingConditionMatchValue: (state, { payload }) => {
      const {
        sectionIndex, questionIndex, conditionIndex, newMatchValue,
      } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { conditionals = {} } = question
      const { conditions = [] } = conditionals
      const condition = conditions[conditionIndex]

      condition.matchValue = newMatchValue
      state.questionConditionQuestions = formQuestionConditionQuestions({ form: state.form })
    },
    updateQuestionBranchingConditionOperator: (state, { payload }) => {
      const {
        sectionIndex, questionIndex, conditionIndex, newOperator,
      } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { conditionals = {} } = question
      const { conditions = [] } = conditionals
      const condition = conditions[conditionIndex]
      const { questionUuid: conditionQuestionUuid } = condition

      const mockGlobalState = { [SLICE_NAME]: state }
      const conditionQuestionType = getFormQuestionTypeByUuid(conditionQuestionUuid)(mockGlobalState)

      changeConditionOperator({ condition, newOperator, conditionQuestionType })
      state.questionConditionQuestions = formQuestionConditionQuestions({ form: state.form })
    },
    updateQuestionBranchingConditionQuestionUuid: (state, { payload }) => {
      const {
        sectionIndex, questionIndex, conditionIndex, newQuestionUuid,
      } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { conditionals = {} } = question
      const { conditions = [] } = conditionals
      const condition = conditions[conditionIndex]

      const mockGlobalState = { [SLICE_NAME]: state }
      const newQuestionType = getFormQuestionTypeByUuid(newQuestionUuid)(mockGlobalState)

      changeConditionQuestionUuid({ condition, newQuestionType, newQuestionUuid })
      state.questionConditionQuestions = formQuestionConditionQuestions({ form: state.form })
    },

    // Reducers for setting whether a question is required
    setQuestionRequired: (state, { payload }) => {
      const { sectionIndex, questionIndex, newRequired } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { type: questionType } = question

      question.required = answerMayBeRequired({ questionType }) ? newRequired : false
    },

    // Reducers for setting whether a question's description is shown
    setQuestionShowDescription: (state, { payload }) => {
      const { sectionIndex, questionIndex, newShowDescription } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]

      question.showDescription = newShowDescription
    },

    // Reducers for working with a question's reportable field
    setQuestionReportableField: (state, { payload }) => {
      const { sectionIndex, questionIndex, newReportableFieldId } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]

      if (newReportableFieldId) {
        question.reportableFieldId = newReportableFieldId
      } else {
        delete question.reportableFieldId
      }

      state.appliedReportableFields = appliedReportableFields({ form: state.form })
    },

    // Reducers for working with a question's question tag
    setQuestionQuestionTag: (state, { payload }) => {
      const { sectionIndex, questionIndex, newQuestionTagId } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { attributes: questionAttributes } = question

      if (newQuestionTagId) {
        questionAttributes.questionTagId = newQuestionTagId
      } else {
        delete questionAttributes.questionTagId
      }

      state.appliedQuestionTags = appliedQuestionTags({ form: state.form })
    },

    // Reducers for working with a number calculation question's calc question uuids
    setQuestionAttributeCalcQuestionUuid: (state, { payload }) => {
      const {
        sectionIndex, questionIndex, calcQuestionUuidIndex, newCalcQuestionUuid,
      } = payload

      const { attributes: questionAttributes } = state.form.sections[sectionIndex].questions[questionIndex]
      const { calcQuestionUuids } = questionAttributes

      calcQuestionUuids[calcQuestionUuidIndex] = newCalcQuestionUuid
    },
    removeQuestionAttributeCaclQuestionUuid: (state, { payload }) => {
      const { sectionIndex, questionIndex, calcQuestionUuidIndex } = payload

      const { attributes: questionAttributes } = state.form.sections[sectionIndex].questions[questionIndex]
      const { calcQuestionUuids } = questionAttributes

      calcQuestionUuids.splice(calcQuestionUuidIndex, 1)
    },
    addQuestionAttributeCaclQuestionUuid: (state, { payload }) => {
      const { sectionIndex, questionIndex } = payload

      const { attributes: questionAttributes } = state.form.sections[sectionIndex].questions[questionIndex]
      const { calcQuestionUuids } = questionAttributes

      calcQuestionUuids.push("")

      // If a third, fourth, etc question is added to the number calculation,
      // ensure that the operator is addition.
      if (calcQuestionUuids.length > MAX_QUESTION_COUNT_FOR_NUMBER_CALCULATION_NON_ADDITION_OPERATIONS) {
        questionAttributes.operator = ADD_OPERATOR
      }
    },

    // Reducers for working with question individual attributes
    setQuestionAttributeDefaultFacility: (state, { payload }) => {
      const { sectionIndex, questionIndex, newDefaultFacilityId } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { attributes: questionAttributes } = question

      if (newDefaultFacilityId) {
        questionAttributes.defaultFacility = newDefaultFacilityId
      } else {
        delete questionAttributes.defaultFacility
      }
    },
    setQuestionAttributeColumnCount: (state, { payload }) => {
      const { sectionIndex, questionIndex, newColumnCount } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { attributes: questionAttributes } = question

      questionAttributes.columnCount = newColumnCount
    },
    setQuestionAttributeDisplayAs: (state, { payload }) => {
      const { sectionIndex, questionIndex, newDisplayAs } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { attributes: questionAttributes } = question

      questionAttributes.displayAs = newDisplayAs
    },
    setQuestionAttributeDefaultAnswer: (state, { payload }) => {
      const { sectionIndex, questionIndex, newDefaultAnswer } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { attributes: questionAttributes } = question

      questionAttributes.defaultAnswer = newDefaultAnswer
    },
    setQuestionAttributeDefaultAnswers: (state, { payload }) => {
      const { sectionIndex, questionIndex, newDefaultAnswers } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { attributes: questionAttributes } = question

      questionAttributes.defaultAnswers = newDefaultAnswers
    },
    setQuestionAttributeIsDropdown: (state, { payload }) => {
      const { sectionIndex, questionIndex, newIsDropdown } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { attributes: questionAttributes } = question

      questionAttributes.isDropdown = newIsDropdown
    },
    setQuestionAttributeMax: (state, { payload }) => {
      const { sectionIndex, questionIndex, newMax } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { attributes: questionAttributes } = question

      questionAttributes.max = newMax
    },
    setQuestionAttributeMin: (state, { payload }) => {
      const { sectionIndex, questionIndex, newMin } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { attributes: questionAttributes } = question

      questionAttributes.min = newMin
    },
    setQuestionAttributePrecision: (state, { payload }) => {
      const { sectionIndex, questionIndex, newPrecision } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { attributes: questionAttributes } = question

      questionAttributes.precision = newPrecision || 0
    },
    setQuestionAttributeOperator: (state, { payload }) => {
      const { sectionIndex, questionIndex, newOperator } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { attributes: questionAttributes } = question

      questionAttributes.operator = newOperator
    },
    setQuestionAttributeSetCurrentDate: (state, { payload }) => {
      const { sectionIndex, questionIndex, newSetCurrentDate } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { attributes: questionAttributes } = question

      questionAttributes.setCurrentDate = newSetCurrentDate
    },
    setQuestionAttributePreventPastDate: (state, { payload }) => {
      const { sectionIndex, questionIndex, newPreventPastDate } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { attributes: questionAttributes } = question

      questionAttributes.preventPastDate = newPreventPastDate
    },
    setQuestionAttributePreventFutureDate: (state, { payload }) => {
      const { sectionIndex, questionIndex, newPreventFutureDate } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { attributes: questionAttributes } = question

      questionAttributes.preventFutureDate = newPreventFutureDate
    },
    setQuestionAttributeOptions: (state, { payload }) => {
      const { sectionIndex, questionIndex, newOptions } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { attributes: questionAttributes } = question

      questionAttributes.options = newOptions
    },
    setQuestionAttributeCsv: (state, { payload }) => {
      const { sectionIndex, questionIndex, newCsv } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { attributes: questionAttributes } = question

      questionAttributes.csv = newCsv
    },
    setQuestionAttributeStartTimeQuestionUuid: (state, { payload }) => {
      const { sectionIndex, questionIndex, newStartTimeQuesitonUuid } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { attributes: questionAttributes } = question

      questionAttributes.startTimeQuestionUuid = newStartTimeQuesitonUuid
    },
    setQuestionAttributeEndTimeQuestionUuid: (state, { payload }) => {
      const { sectionIndex, questionIndex, newEndTimeQuesitonUuid } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { attributes: questionAttributes } = question

      questionAttributes.endTimeQuestionUuid = newEndTimeQuesitonUuid
    },
    setQuestionAttributeSetCurrentTime: (state, { payload }) => {
      const { sectionIndex, questionIndex, newSetCurrentTime } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { attributes: questionAttributes } = question

      questionAttributes.setCurrentTime = newSetCurrentTime
    },
    setQuestionAttributeNote: (state, { payload }) => {
      const { sectionIndex, questionIndex, newNote } = payload
      const question = state.form.sections[sectionIndex].questions[questionIndex]
      const { attributes: questionAttributes } = question

      questionAttributes.note = newNote
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeFormBuilderState.rejected, () => {
        // Not implemented
      })
      .addCase(initializeFormBuilderState.pending, () => {
        // Not implemented
      })
      .addCase(initializeFormBuilderState.fulfilled, (state, action) => {
        Object.assign(state, action.payload)
      })
  },
})

export const {
  setNewWorkflowName,
  setSelectedWorkflow,
  showWorkflowForm,
  hideWorkflowForm,
  setFormTitle,
  setFormDescription,
  setFormValueByPath,
  addFormSection,
  deleteFormSection,
  addFormQuestion,
  restoreFormQuestion,
  deleteFormQuestion,
  undeleteFormQuestion,
  copyFormQuestion,
  setFormQuestionType,
  setFormQuestionDescription,
  deleteFormQuestionOption,
  updateFormQuestionOption,
  moveFormQuestionOption,
  setFormQuestionPrompt,
  moveFormSection,
  moveFormQuestion,
  setQuestionFocus,
  setUsedReportableFieldsIdsWithCurrentForm,
  setWorkflows,
  setWorkflowActiveStatus,
  setFormQuestions,
  removeWorkflow,
  addWorkflow,
  setFormTooltipDescription,
  setFormIcon,
  setFormAcceptsFiles,
  setFormActive,
  setFormListed,
  setFormPublicSubmissionStatus,
  addQuestionFlagCondition,
  removeQuestionFlagCondition,
  setQuestionFlagConditionOperator,
  setQuestionFlagConditionMatchValue,
  updateSectionBranchingConditionalsControl,
  addSectionBranchingCondition,
  removeSectionBranchingConditionalsCondition,
  replaceSectionBranchingConditionWithConditionals,
  updateSectionBranchingConditionMatchValue,
  updateSectionBranchingConditionOperator,
  updateSectionBranchingConditionQuestionUuid,
  updateQuestionBranchingConditionalsControl,
  addQuestionBranchingCondition,
  removeQuestionBranchingConditionalsCondition,
  replaceQuestionBranchingConditionWithConditionals,
  updateQuestionBranchingConditionMatchValue,
  updateQuestionBranchingConditionOperator,
  updateQuestionBranchingConditionQuestionUuid,
  setQuestionRequired,
  setQuestionShowDescription,
  setQuestionReportableField,
  setQuestionQuestionTag,
  setQuestionAttributeCalcQuestionUuid,
  removeQuestionAttributeCaclQuestionUuid,
  addQuestionAttributeCaclQuestionUuid,
  setQuestionAttributeDefaultFacility,
  setQuestionAttributeColumnCount,
  setQuestionAttributeDisplayAs,
  setQuestionAttributeDefaultAnswer,
  setQuestionAttributeDefaultAnswers,
  setQuestionAttributeIsDropdown,
  setQuestionAttributeMax,
  setQuestionAttributeMin,
  setQuestionAttributePrecision,
  setQuestionAttributeOperator,
  setQuestionAttributeSetCurrentDate,
  setQuestionAttributePreventPastDate,
  setQuestionAttributePreventFutureDate,
  setQuestionAttributeOptions,
  setQuestionAttributeCsv,
  setQuestionAttributeStartTimeQuestionUuid,
  setQuestionAttributeEndTimeQuestionUuid,
  setQuestionAttributeSetCurrentTime,
  setQuestionAttributeNote,
} = formBuilderSlice.actions

export const getFormBuilder = (state) => state[SLICE_NAME]

export const getFormBuilderStateReady = (state) => state[SLICE_NAME].formBuilderStateReady

export const getIsWorkflowFormVisible = (state) => state[SLICE_NAME].isWorkflowFormVisible

// Related to focus
export const getFormQuestionFocus = (state) => state[SLICE_NAME].formQuestionFocus

export const isFocusOnQuestion = ({ sectionIndex, questionIndex }) => (state) => (
  state[SLICE_NAME].formQuestionFocus.sectionIndex === sectionIndex
  && state[SLICE_NAME].formQuestionFocus.questionIndex === questionIndex
)

export const isFocusInSection = (sectionIndex) => (state) => (
  state[SLICE_NAME].formQuestionFocus.sectionIndex === sectionIndex
)

export const isFocusOnSection = (sectionIndex) => (state) => (
  state[SLICE_NAME].formQuestionFocus.sectionIndex === sectionIndex
  && state[SLICE_NAME].formQuestionFocus.questionIndex === null
)

// Related to a question deleted in the form builder
export const isDeletedQuestionPresent = (state) => Boolean(state[SLICE_NAME].deletedQuestion)

export const getDeletedQuestion = (state) => state[SLICE_NAME].deletedQuestion

// Related to form sections

export const getFormSectionAtIndex = (sectionIndex) => (state) => state[SLICE_NAME].form.sections[sectionIndex]

export const getFormSectionCondition = (sectionIndex) => (state) => state[SLICE_NAME].form.sections[sectionIndex].condition

export const getFormSectionConditionals = (sectionIndex) => (state) => (
  state[SLICE_NAME].form.sections[sectionIndex].conditionals || cloneDeep(DEFAULT_CONDITIONALS)
)

export const getFormSectionBranchingConditionQuestionOptions = (sectionIndex) => (state) => {
  const { form } = state[SLICE_NAME]
  const { uuid: sectionUuid } = form.sections[sectionIndex]

  return (
    questionListFromOutsideSection(form, sectionUuid, {
      excludeTypes: [
        MULTI_ENTRIES_TYPE,
        NOTE_ANSWER_TYPE,
        NUMBER_CALCULATION_TYPE,
        TIME_DIFFERENCE_TYPE,
      ],
    })
  )
}

export const getFormSectionBranchingConditionUnusedQuestionOptions = (sectionIndex) => (state) => {
  const section = state[SLICE_NAME].form.sections[sectionIndex]
  const { conditionals = {} } = section
  const { conditions = [] } = conditionals

  const allQuestionOptions = getFormSectionBranchingConditionQuestionOptions(sectionIndex)(state)

  const usedQuestionUuids = conditions.reduce((uuids, condition) => {
    const { questionUuid } = condition

    uuids[questionUuid] = true
    return uuids
  }, {})

  return allQuestionOptions.filter((questionOption) => !usedQuestionUuids[questionOption.uuid])
}

export const getFormSectionDescription = (sectionIndex) => (state) => (
  state[SLICE_NAME].form.sections[sectionIndex].description
)

export const getFormSectionHasConditionals = (sectionIndex) => (state) => {
  const conditions = state[SLICE_NAME].form.sections[sectionIndex].conditionals?.conditions ?? []
  return conditions.length > 0
}

export const getFormSectionIsLastSection = (sectionIndex) => (state) => (
  state[SLICE_NAME].form.sections.length === sectionIndex + 1
)

export const getFormSectionDeletable = (sectionIndex) => (state) => {
  const descriptions = preventSectionDeletionDescriptions({ formBuilderReduxSlice: state[SLICE_NAME], sectionIndex })
  return [descriptions.length === 0, descriptions]
}

export const getFormSectionName = (sectionIndex) => (state) => state[SLICE_NAME].form.sections[sectionIndex].name

export const getFormSectionQuestions = (sectionIndex) => (state) => state[SLICE_NAME].form.sections[sectionIndex].questions

export const getFormSectionQuestionUuids = (sectionIndex) => (state) => (
  state[SLICE_NAME].form.sections[sectionIndex].questions.map((question) => question.uuid)
)

export const getFormSectionUuid = (sectionIndex) => (state) => state[SLICE_NAME].form.sections[sectionIndex].uuid

export const getFormSectionUuids = (state) => state[SLICE_NAME].form.sections.map((section) => section.uuid)

export const getFormAdditionalSectionUuids = (state) => getFormSectionUuids(state).slice(1)

// Related to form questions
export const getFormNumberTypeQuestions = (state) => (
  state[SLICE_NAME].form.sections.flatMap((section) => (
    section.questions.filter((question) => question.type === NUMBER_TYPE)
  ))
)

export const getFormNumberTypeQuestionCount = (state) => (
  state[SLICE_NAME].form.sections.reduce((count, section) => {
    section.questions.forEach((question) => {
      if (question.type === NUMBER_TYPE) {
        count += 1
      }
    })

    return count
  }, 0)
)

export const getFormTimeTypeQuestions = (state) => (
  state[SLICE_NAME].form.sections.flatMap((section) => (
    section.questions.filter((question) => question.type === TIME_TYPE)
  ))
)

export const getFormQuestionByUuid = (uuid) => (state) => {
  const { sectionIndex, questionIndex } = state[SLICE_NAME].questionLocations[uuid]
  return state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
}

export const getFormQuestionBranchingConditionQuestionOptions = ({ sectionIndex, questionIndex }) => (state) => {
  const { form } = state[SLICE_NAME]
  const question = form.sections[sectionIndex].questions[questionIndex]
  const { uuid: questionUuid } = question

  return (
    filteredQuestionList(form, {
      exclude: [questionUuid],
      excludeTypes: [
        MULTI_ENTRIES_TYPE,
        NOTE_ANSWER_TYPE,
        NUMBER_CALCULATION_TYPE,
        TIME_DIFFERENCE_TYPE,
      ],
    })
  )
}

export const getFormQuestionBranchingConditionUnusedQuestionOptions = ({ sectionIndex, questionIndex }) => (state) => {
  const question = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
  const { conditionals = {} } = question
  const { conditions = [] } = conditionals

  const allQuestionOptions = getFormQuestionBranchingConditionQuestionOptions({ sectionIndex, questionIndex })(state)

  const usedQuestionUuids = conditions.reduce((uuids, condition) => {
    const { questionUuid } = condition

    uuids[questionUuid] = true
    return uuids
  }, {})

  return allQuestionOptions.filter((questionOption) => !usedQuestionUuids[questionOption.uuid])
}

export const getFormQuestionCondition = ({ sectionIndex, questionIndex }) => (state) => (
  state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex].condition
)

export const getFormQuestionConditionals = ({ sectionIndex, questionIndex }) => (state) => (
  state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex].conditionals || cloneDeep(DEFAULT_CONDITIONALS)
)

export const getFormQuestionDescription = ({ sectionIndex, questionIndex }) => (state) => (
  state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex].description
)

export const getFormQuestionDescriptionShown = ({ sectionIndex, questionIndex }) => (state) => (
  state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex].showDescription
)

export const getFormQuestionHasConditionals = ({ sectionIndex, questionIndex }) => (state) => {
  const conditions = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex].conditionals?.conditions ?? []
  return conditions.length > 0
}

export const getFormQuestionIsLastInSection = ({ sectionIndex, questionIndex }) => (state) => (
  state[SLICE_NAME].form.sections[sectionIndex].questions.length === questionIndex + 1
)

export const getFormQuestionUuid = ({ sectionIndex, questionIndex }) => (state) => (
  state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex].uuid
)

export const getFormQuestionOptions = ({ sectionIndex, questionIndex }) => (state) => (
  state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex].attributes?.options ?? []
)

export const getFormQuestionOptionsByUuid = (uuid) => (state) => {
  const question = getFormQuestionByUuid(uuid)(state)
  return question.attributes?.options ?? []
}

export const getFormQuestionOptionsCount = ({ sectionIndex, questionIndex }) => (state) => (
  getFormQuestionOptions({ sectionIndex, questionIndex })(state).length
)

export const getFormQuestionOption = ({ sectionIndex, questionIndex, optionIndex }) => (state) => {
  const options = getFormQuestionOptions({ sectionIndex, questionIndex })(state)

  return options[optionIndex] ?? ""
}

export const getFormQuestionOptionCount = ({ sectionIndex, questionIndex, optionIndex }) => (state) => {
  const uuid = getFormQuestionUuid({ sectionIndex, questionIndex })(state)
  const option = getFormQuestionOption({ sectionIndex, questionIndex, optionIndex })(state)

  return state[SLICE_NAME].questionOptionCounts[uuid][canonicalOptionforOptionCount(option)]
}

export const getFormQuestionOptionLocked = ({ sectionIndex, questionIndex, optionIndex }) => (state) => {
  const question = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
  const { uuid: questionUuid } = question
  const option = getFormQuestionOption({ sectionIndex, questionIndex, optionIndex })(state)
  const usageDescriptions = questionOptionUsageDescriptions({ formBuilderReduxSlice: state[SLICE_NAME], option, questionUuid })

  return [usageDescriptions.length > 0, usageDescriptions]
}

export const getFormQuestionDeletable = ({ sectionIndex, questionIndex }) => (state) => {
  const descriptions = preventQuestionDeletionDescriptions({ formBuilderReduxSlice: state[SLICE_NAME], sectionIndex, questionIndex })
  return [descriptions.length === 0, descriptions]
}

export const getFormQuestionPrompt = ({ sectionIndex, questionIndex }) => (state) => (
  state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex].prompt
)

export const getFormQuestionPromptCount = ({ sectionIndex, questionIndex }) => (state) => {
  const prompt = getFormQuestionPrompt({ sectionIndex, questionIndex })(state)
  return state[SLICE_NAME].promptCounts[canonicalPromptForPromptCount(prompt)]
}

export const getFormQuestionRequired = ({ sectionIndex, questionIndex }) => (state) => (
  state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex].required
)

export const getFormQuestionType = ({ sectionIndex, questionIndex }) => (state) => (
  state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex].type
)

export const getFormQuestionTypeByUuid = (uuid) => (state) => {
  if (!uuid) return null

  const question = getFormQuestionByUuid(uuid)(state)
  return question?.type
}

export const getFormQuestionFlagCondition = ({ sectionIndex, questionIndex }) => (state) => (
  state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex].flagCondition ?? {}
)

export const getFormQuestionHasFlagCondition = ({ sectionIndex, questionIndex }) => (state) => (
  !isEmpty(getFormQuestionFlagCondition({ sectionIndex, questionIndex })(state))
)

export const getFormQuestionReportableFieldId = ({ sectionIndex, questionIndex }) => (state) => (
  state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex].reportableFieldId
)

export const getFormQuestionReportableFieldOptions = ({ sectionIndex, questionIndex }) => (state) => {
  const {
    appliedReportableFields: sliceAppliedReportableFields,
    form,
    reportableFieldsByType: sliceReportableFieldsByType,
  } = state[SLICE_NAME]

  const question = form.sections[sectionIndex].questions[questionIndex]
  const { reportableFieldId: questionReportableFieldId, type: questionType } = question

  const optionsForQuestionType = sliceReportableFieldsByType[reportableFieldQuestionType({ questionType })] ?? []

  return optionsForQuestionType.filter((reportableField) => (
    reportableField.id === questionReportableFieldId || !sliceAppliedReportableFields[reportableField.id]
  ))
}

export const getFormQuestionQuestionTagId = ({ sectionIndex, questionIndex }) => (state) => (
  state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex].attributes?.questionTagId
)

export const getFormQuestionQuestionTagOptions = ({ sectionIndex, questionIndex }) => (state) => {
  const { appliedQuestionTags: sliceAppliedQuestionTags, form, questionTags } = state[SLICE_NAME]
  const { attributes: questionAttributes = {} } = form.sections[sectionIndex].questions[questionIndex]
  const { questionTagId: questionQuestionTagId } = questionAttributes

  return questionTags.filter((questionTag) => (
    questionTag.id === questionQuestionTagId || !sliceAppliedQuestionTags[questionTag.id]
  ))
}

export const getFormQuestionAttributeDefaultFacility = ({ sectionIndex, questionIndex }) => (state) => {
  const question = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
  const { attributes: questionAttributes } = question

  return questionAttributes.defaultFacility
}

export const getFormQuestionAttributeDefaultFacilityOptions = ({ sectionIndex, questionIndex }) => (state) => {
  const { facilities, form } = state[SLICE_NAME]
  const { attributes: questionAttributes } = form.sections[sectionIndex].questions[questionIndex]
  const { defaultFacility: questionDefaultFacilityId } = questionAttributes

  return facilities.filter((facility) => (
    facility.active || facility.id === questionDefaultFacilityId
  ))
}

export const getFormQuestionAttributeColumnCount = ({ sectionIndex, questionIndex }) => (state) => {
  const { attributes: questionAttributes } = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
  return questionAttributes.columnCount
}

export const getFormQuestionAttributeDisplayAs = ({ sectionIndex, questionIndex }) => (state) => {
  const { attributes: questionAttributes } = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
  return questionAttributes.displayAs
}

export const getFormQuestionAttributeDefaultAnswer = ({ sectionIndex, questionIndex }) => (state) => {
  const { attributes: questionAttributes } = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
  return questionAttributes.defaultAnswer
}

export const getFormQuestionAttributeDefaultAnswers = ({ sectionIndex, questionIndex }) => (state) => {
  const { attributes: questionAttributes } = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
  return questionAttributes.defaultAnswers
}

export const getFormQuestionAttributeIsDropdown = ({ sectionIndex, questionIndex }) => (state) => {
  const { attributes: questionAttributes } = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
  return questionAttributes.isDropdown
}

export const getFormQuestionAttributeMax = ({ sectionIndex, questionIndex }) => (state) => {
  const { attributes: questionAttributes } = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
  return questionAttributes.max
}

export const getFormQuestionAttributeMin = ({ sectionIndex, questionIndex }) => (state) => {
  const { attributes: questionAttributes } = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
  return questionAttributes.min
}

export const getFormQuestionAttributePrecision = ({ sectionIndex, questionIndex }) => (state) => {
  const { attributes: questionAttributes } = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
  return questionAttributes.precision
}

export const getFormQuestionAttributeOperator = ({ sectionIndex, questionIndex }) => (state) => {
  const { attributes: questionAttributes } = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
  return questionAttributes.operator
}

export const getFormQuestionAttributeCalcQuestionUuids = ({ sectionIndex, questionIndex }) => (state) => {
  const { attributes: questionAttributes } = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
  return questionAttributes.calcQuestionUuids
}

export const getFormQuestionAttributeCalcQuestionUuidsCount = ({ sectionIndex, questionIndex }) => (state) => (
  getFormQuestionAttributeCalcQuestionUuids({ sectionIndex, questionIndex })(state).length
)

export const getFormQuestionAttributeCalcQuestionUuid = ({ sectionIndex, questionIndex, calcQuestionUuidIndex }) => (state) => {
  const { attributes: questionAttributes } = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
  return questionAttributes.calcQuestionUuids[calcQuestionUuidIndex]
}

export const getFormQuestionAttributeCalcQuestionUuidOptions = ({ sectionIndex, questionIndex, calcQuestionUuidIndex }) => (state) => {
  const numberQuestions = getFormNumberTypeQuestions(state)
  const { attributes: questionAttributes } = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
  const { calcQuestionUuids } = questionAttributes
  const usedCaclQuestionUuids = [...calcQuestionUuids]
  const [calcQuestionUuidAtIndex] = usedCaclQuestionUuids.splice(calcQuestionUuidIndex, 1)

  return numberQuestions.filter((question) => (
    question.uuid === calcQuestionUuidAtIndex || !usedCaclQuestionUuids.includes(question.uuid)
  ))
}

export const getFormQuestionAttributeSetCurrentDate = ({ sectionIndex, questionIndex }) => (state) => {
  const { attributes: questionAttributes } = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
  return questionAttributes.setCurrentDate ?? false
}

export const getFormQuestionAttributePreventPastDate = ({ sectionIndex, questionIndex }) => (state) => {
  const { attributes: questionAttributes } = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
  return questionAttributes.preventPastDate ?? false
}

export const getFormQuestionAttributePreventFutureDate = ({ sectionIndex, questionIndex }) => (state) => {
  const { attributes: questionAttributes } = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
  return questionAttributes.preventFutureDate ?? false
}

export const getFormQuestionAttributeCsv = ({ sectionIndex, questionIndex }) => (state) => {
  const { attributes: questionAttributes } = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
  return questionAttributes.csv
}

export const getFormQuestionAttributeStartTimeQuestionUuid = ({ sectionIndex, questionIndex }) => (state) => {
  const { attributes: questionAttributes } = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
  return questionAttributes.startTimeQuestionUuid
}

export const getFormQuestionAttributeStartTimeQuestionUuidOptions = ({ sectionIndex, questionIndex }) => (state) => {
  const timeQuestions = getFormTimeTypeQuestions(state)
  const { attributes: questionAttributes } = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
  const { endTimeQuestionUuid } = questionAttributes

  return timeQuestions.filter((question) => question.uuid !== endTimeQuestionUuid)
}

export const getFormQuestionAttributeEndTimeQuestionUuid = ({ sectionIndex, questionIndex }) => (state) => {
  const { attributes: questionAttributes } = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
  return questionAttributes.endTimeQuestionUuid
}

export const getFormQuestionAttributeEndTimeQuestionUuidOptions = ({ sectionIndex, questionIndex }) => (state) => {
  const timeQuestions = getFormTimeTypeQuestions(state)
  const { attributes: questionAttributes } = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
  const { startTimeQuestionUuid } = questionAttributes

  return timeQuestions.filter((question) => question.uuid !== startTimeQuestionUuid)
}

export const getFormQuestionAttributeSetCurrentTime = ({ sectionIndex, questionIndex }) => (state) => {
  const { attributes: questionAttributes } = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
  return questionAttributes.setCurrentTime
}

export const getFormQuestionAttributeNote = ({ sectionIndex, questionIndex }) => (state) => {
  const { attributes: questionAttributes } = state[SLICE_NAME].form.sections[sectionIndex].questions[questionIndex]
  return questionAttributes.note
}

export const hasQuestionBeenAnswered = (uuid) => (state) => state[SLICE_NAME].answeredQuestions[uuid] ?? false

export const hasQuestionBeenAnsweredByIndex = ({ sectionIndex, questionIndex }) => (state) => {
  const questionUuid = getFormQuestionUuid({ sectionIndex, questionIndex })(state)
  return hasQuestionBeenAnswered(questionUuid)(state)
}

// Form attributes
export const getFormAcceptsSubmittedFiles = (state) => state[SLICE_NAME].form.acceptsSubmittedFiles

export const getFormActive = (state) => state[SLICE_NAME].form.active

export const getFormDescription = (state) => state[SLICE_NAME].form.description

export const getFormHasLocationQuestions = (state) => {
  const formQuestions = getFormQuestions(state)

  return formQuestions.some((question) => question.type === LOCATION_TYPE)
}

export const getFormIcon = (state) => state[SLICE_NAME].form.icon

export const getFormIconColor = (state) => state[SLICE_NAME].form.iconColor

export const getFormId = (state) => state[SLICE_NAME].form.id

export const getFormIsSubform = (state) => state[SLICE_NAME].form.isSubform

export const getFormListed = (state) => state[SLICE_NAME].form.listed

export const getFormPublicSubmissionStatus = (state) => state[SLICE_NAME].form.publicSubmissionStatus

export const getFormQuestions = (state) => state[SLICE_NAME].formQuestions

export const getFormSlug = (state) => state[SLICE_NAME].form?.slug

export const getFormTitle = (state) => state[SLICE_NAME].form.title

export const getFormTooltipDescription = (state) => state[SLICE_NAME].form.tooltipDescription

export const getFormUsage = (state) => state[SLICE_NAME].form.usage

// Category attributes
export const getCategory = (state) => state[SLICE_NAME].category

export const getCategoryName = (state) => state[SLICE_NAME].category.name

export const getCategorySlug = (state) => state[SLICE_NAME].category.slug

export const getCategoryStatuses = (state) => state[SLICE_NAME].categoryStatuses

export const getCategorySubforms = (state) => state[SLICE_NAME].categorySubforms

// Form workflows attributes
export const getWorkflows = (state) => state[SLICE_NAME].workflows

export const getFormHasWorkflows = (state) => state[SLICE_NAME].workflows.length > 0

export const getGroups = (state) => state[SLICE_NAME].groups

export const getUsers = (state) => state[SLICE_NAME].users

export const getDeletedQuestions = (state) => state[SLICE_NAME].form.deletedQuestions

export const getDepartments = (state) => state[SLICE_NAME].departments

export const getActiveOrMatchingDepartments = (ids) => (state) => (
  state[SLICE_NAME].departments.filter((department) => recordActive(department) || valueMatches(department.id, ids))
)

export const getDepartmentFacilityMapping = (state) => (
  state[SLICE_NAME].departments.reduce((mapping, department) => {
    mapping[department.id] = department.facilityId
    return mapping
  }, {})
)

export const getFacilities = (state) => state[SLICE_NAME].facilities

export const getActiveOrMatchingFacilities = (ids) => (state) => (
  state[SLICE_NAME].facilities.filter((facility) => recordActive(facility) || valueMatches(facility.id, ids))
)

export const getFacilityGroupCodes = (state) => state[SLICE_NAME].facilityGroupCodes

export const getFacilityDepartments = (state) => (
  state[SLICE_NAME].departments.reduce((mapping, department) => {
    if (mapping[department.facilityId]) {
      mapping[department.facilityId].push(department)
    } else {
      mapping[department.facilityId] = [department]
    }

    return mapping
  }, {})
)

export default formBuilderSlice.reducer
