import React from "react"
import types from "prop-types"
import ContentHeader from "shared/ContentHeader"
import SidebarVisibilityControl from "shared/Sidebar/SidebarVisibilityControl"
import { PrimaryButton } from "components/shared/buttons"
import { useFormSubmissionAttachmentDropzoneContext } from "contexts/FormSubmissionAttachmentDropzoneContext"

const AttachmentsHeader = ({ hasAttachments, hiddenSidebar, toggleSidebar }) => {
  const { openAttachmentFileDialog } = useFormSubmissionAttachmentDropzoneContext()

  return (
    <ContentHeader
      leftElements={(
        hasAttachments
          ? <SidebarVisibilityControl className="hidden lg:flex" hiddenSidebar={hiddenSidebar} toggleSidebar={toggleSidebar} />
          : null
      )}
      heading={
        <h1 className="header-title">Attachments</h1>
      }
      rightElements={
        hasAttachments
          ? <PrimaryButton text="+ Upload File" onClick={openAttachmentFileDialog} />
          : null
      }
    />
  )
}

AttachmentsHeader.propTypes = {
  hasAttachments: types.bool.isRequired,
  hiddenSidebar: types.bool.isRequired,
  toggleSidebar: types.func.isRequired,
}

export default AttachmentsHeader
