import React, { useState } from "react"
import types from "prop-types"
import { formOptionShape } from "utils/propTypeShapes"
import { FormsyHiddenInput } from "shared/inputs"
import { FormSelect } from "shared/selects"
import FieldLabel from "../FieldLabel"

const BaseFormIdField = ({ forms }) => {
  const [baseFormId, setBaseFormId] = useState(null)

  return (
    <FieldLabel text="Form to copy">
      <FormSelect
        options={forms}
        value={baseFormId}
        onChange={setBaseFormId}
      />
      <FormsyHiddenInput
        name="base_form_id"
        required
        value={baseFormId}
      />
    </FieldLabel>
  )
}

BaseFormIdField.propTypes = {
  forms: types.arrayOf(formOptionShape).isRequired,
}

export default BaseFormIdField
