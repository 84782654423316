import React from "react"
import { sortBy } from "lodash-es"
import { useSelector } from "react-redux"
import { getFormHasWorkflows, getWorkflows } from "reduxSlices/formBuilderSlice"
import { TileGrid } from "shared/tiles"
import WorkflowTile from "./WorkflowTile"
import EmptyWorkflows from "./EmptyWorkflows"

const lowerCaseName = (workflow) => workflow.name.toLowerCase()

const WorkflowList = () => {
  const formHasWorkflows = useSelector(getFormHasWorkflows)
  const workflows = useSelector(getWorkflows)

  return (
    formHasWorkflows ? (
      <TileGrid>
        {
          sortBy(workflows, lowerCaseName).map((workflow) => (
            <WorkflowTile key={workflow.id} workflow={workflow} />
          ))
        }
      </TileGrid>
    ) : (
      <EmptyWorkflows />
    )
  )
}

export default WorkflowList
