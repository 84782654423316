import React, { useMemo } from "react"
import types from "prop-types"
import { Checkbox } from "components/shared/checkboxes"
import { SURVEY_NON_ANONYMOUS_TYPE } from "reduxSlices/reportBuilderSlice"

const ColumnOption = ({
  option,
  selectedColumns,
  selectOption,
  deselectOption,
}) => {
  const isSelected = useMemo(() => (
    selectedColumns.some((col) => col.id === option.id && col.type === option.type)
  ), [option, selectedColumns.length])

  const toggleOption = isSelected
    ? () => deselectOption(option)
    : () => selectOption(option)

  const optionIdentifier = `columnOption-checkbox-${option.type}-${option.id}`

  return (
    <div className="flex items-center">
      <Checkbox
        className="mr-4"
        onChange={toggleOption}
        uuid={optionIdentifier}
        value={isSelected}
      />
      <label className="cursor-pointer" htmlFor={optionIdentifier}>
        {option.label}{option.type === SURVEY_NON_ANONYMOUS_TYPE && " *"}
      </label>
    </div>
  )
}

ColumnOption.propTypes = {
  option: types.shape({
    id: types.oneOfType([types.string, types.number]).isRequired,
    label: types.string.isRequired,
    type: types.string.isRequired,
  }).isRequired,
  selectedColumns: types.arrayOf(
    types.shape({
      id: types.oneOfType([types.string, types.number]).isRequired,
      label: types.string.isRequired,
      type: types.string.isRequired,
    }),
  ).isRequired,
  selectOption: types.func.isRequired,
  deselectOption: types.func.isRequired,
}

export default React.memo(ColumnOption)
