import React from "react"
import types from "prop-types"
import UserInfo from "shared/UserInfo"
import { capitalize } from "utils/stringHelpers"
import activeBeforeInactive from "utils/activeBeforeInactive"

const shortenedAccessText = (text) => capitalize(text).replace(/_access/g, "")

const accessDescription = (user, grant) => (
  user.role === "Admin"
    ? user.role
    : shortenedAccessText(grant?.accessLevel)
)

const AccessUserList = ({ membersWithGrant, emptyListText }) => {
  if (!membersWithGrant.length) {
    return (<div className="font-bold">{ emptyListText }</div>)
  }

  const sortableMembersWithGrants = [...membersWithGrant];

  return sortableMembersWithGrants
    .sort(({ user }, { user: user2 }) => activeBeforeInactive(user, user2))
    .map(({ user, grant }) => (
      <UserInfo
        key={user.id}
        className="py-4 mb-2 border-b border-lightgray-3 last:border-0"
        user={user}
        showContentIfInactive={false}
      >
        <div className="text-xs text-dark">
          {accessDescription(user, grant)}
        </div>
      </UserInfo>
    ))
}

AccessUserList.propTypes = {
  membersWithGrant: types.arrayOf(types.object).isRequired,
  emptyListText: types.string.isRequired,
}

export default AccessUserList
