import { useEffect } from "react"
import { setSession } from "reduxSlices/sessionSlice"
import { sessionDataShape } from "utils/propTypeShapes"
import { store } from "store"

const SetupSessionInRedux = ({ session }) => {
  useEffect(() => {
    store.dispatch(setSession(session))
  }, [session])

  return null
}

SetupSessionInRedux.propTypes = {
  session: sessionDataShape.isRequired,
}

export default SetupSessionInRedux
