import React from "react"
import types from "prop-types"
import DataCollectorStepHeader from "./DataCollectorStepHeader"
import DataCollectorStepConnector from "./DataCollectorStepConnector"

const DataCollectorStep = ({ heading = "", stepNumber, children }) => (
  <>
    <DataCollectorStepConnector stepNumber={stepNumber} />
    <div className="border border-lightgray-3 rounded-lg">
      <DataCollectorStepHeader heading={heading} />
      <div className="py-5 px-6">
        {children}
      </div>
    </div>
  </>
)

DataCollectorStep.propTypes = {
  children: types.node.isRequired,
  heading: types.string,
  stepNumber: types.number.isRequired,
}

export default DataCollectorStep
