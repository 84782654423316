import React from "react"
import types from "prop-types"
import { Button, PrimaryButton } from "shared/buttons"

const FormActions = ({ cancelForm, isFormValid = false, saveScheduledReportExport }) => (
  <div className="flex justify-center gap-4">
    <Button
      text="Cancel"
      onClick={cancelForm}
      className="border border-light-300 mr-2"
    />
    <PrimaryButton
      text="Save"
      type="submit"
      disabled={!isFormValid}
      onClick={saveScheduledReportExport}
    />
  </div>
)

FormActions.propTypes = {
  cancelForm: types.func.isRequired,
  isFormValid: types.bool,
  saveScheduledReportExport: types.func.isRequired,
}

export default FormActions
