import React from "react"
import types from "prop-types"
import { SidebarVisibilityControl } from "shared/Sidebar"
import { PrimaryButton } from "shared/buttons"
import { ACTIVITY_HUB_SURVEYS_SURVEY_CONFIGURATIONS_ROOT } from "utils/routeHelpers"

const goToSurveyConfigurations = () => {
  window.location.pathname = ACTIVITY_HUB_SURVEYS_SURVEY_CONFIGURATIONS_ROOT
}

const BatchesHeader = ({ hiddenSidebar, toggleSidebar }) => (
  <div className="flex items-center justify-between">
    <SidebarVisibilityControl
      hiddenSidebar={hiddenSidebar}
      toggleSidebar={toggleSidebar}
    />
    <h1 className="header-title">
      Survey Batches
    </h1>
    <PrimaryButton
      text="Configurations"
      onClick={goToSurveyConfigurations}
    />
  </div>
)

BatchesHeader.propTypes = {
  hiddenSidebar: types.bool.isRequired,
  toggleSidebar: types.func.isRequired,
}

export default BatchesHeader
