const uniqueRandomIndexes = ({ count = 1, arraySize = 1 }) => {
  if (count > arraySize) throw new Error("Cannot return more indexes than are in the array")

  const indexes = new Set()

  while (indexes.size < count) {
    indexes.add(Math.floor(Math.random() * arraySize))
  }

  return Array.from(indexes)
}

export default uniqueRandomIndexes
