import React from "react"
import types from "prop-types"
import { dynamicRangeAnswersExample, weekRangeAnswerInclusionDescription } from "utils/scheduledReportExportHelpers"

const WeeksAnswerDescription = ({ startDate, dateAnswerPreviousRangeLength = null }) => {
  const weekCount = dateAnswerPreviousRangeLength || 1

  const beginningWeek = startDate.subtract(weekCount, "w")
  const beginningDate = beginningWeek.weekday(0)

  // endingDate is n weeks from start date minus one day
  const endingDate = beginningDate.add(weekCount, "w").subtract(1, "d")

  return (
    <div className="text-xs text-dark mt-2">
      <p className="mb-4">
        {weekRangeAnswerInclusionDescription}
      </p>
      <p>
        {dynamicRangeAnswersExample({ startDate, beginningDate, endingDate })}
      </p>
    </div>
  )
}

WeeksAnswerDescription.propTypes = {
  startDate: types.object.isRequired,
  dateAnswerPreviousRangeLength: types.number,
}
export default WeeksAnswerDescription
