import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getVisibleColumns } from "reduxSlices/taskTableSlice"
import TaskGrouping from "./TaskGrouping"

const NO_DATA_TEXT = "There are no tasks for you right now."

const TaskGroupingList = ({ taskGroups }) => {
  const visibleColumns = useSelector(getVisibleColumns)

  if (Object.values(taskGroups).every((group) => group.length === 0)) {
    return <div className="text-center">{ NO_DATA_TEXT }</div>
  }

  return (
    Object.entries(taskGroups).map(([groupName, tableData]) => (
      <TaskGrouping
        key={groupName}
        columns={visibleColumns}
        groupName={groupName}
        tableData={tableData}
      />
    ))
  )
}

TaskGroupingList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  taskGroups: types.object.isRequired,
}

export default TaskGroupingList
