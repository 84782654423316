import React from "react"
import types from "prop-types"
import { RadioButtonLabeledGroupInput } from "shared/inputs"
import { ListSelect, InactiveOptionsItemRenderer } from "shared/selects"
import { DEFAULT_SELECT_PLACEHOLDER } from "shared/selects/Select"
import { DROPDOWN, RADIO } from "FormBuilder/QuestionFields/QuestionTypeAttributes/LocationAttributes/LocationDisplayAs"

const facilityOptions = (items) => items?.map(({ name, id, active }) => (
  { label: name, value: id, active }
))

const FacilitySelector = ({
  columnCount = 1,
  disabled,
  displayAs = RADIO,
  facilities = [],
  id = "",
  onChange,
  required,
  showErrors,
  questionUuid,
  value = undefined,
}) => {
  // ListSelect and RadioButtonLabeledGroupInput
  // look for an isPristine prop when determining
  // whether or not to show errors
  const isPristine = !showErrors
  const errorMessages = required && !value ? ["Must select a facility"] : []

  return (
    <>
      { displayAs === DROPDOWN
        ? (
          <ListSelect
            className="form-input"
            searchable
            clearable
            id={id}
            itemRenderer={InactiveOptionsItemRenderer}
            disabled={disabled}
            hasBaseInputStyle={false}
            onChange={onChange}
            options={facilities}
            optionsFromList={facilityOptions}
            placeholder={required ? `${DEFAULT_SELECT_PLACEHOLDER} *` : undefined}
            value={value}
            isPristine={isPristine}
            errorMessages={errorMessages}
          />
        )
        : (
          <RadioButtonLabeledGroupInput
            className="mt-3 location-answer-field"
            disabled={disabled}
            id={id}
            radioInputClassName="form-hover-highlight"
            options={facilityOptions(facilities)}
            value={value}
            onChange={onChange}
            questionUuid={questionUuid}
            columnCount={columnCount}
            isPristine={isPristine}
            errorMessages={errorMessages}
          />
        )}
    </>
  )
}

FacilitySelector.propTypes = {
  columnCount: types.number,
  disabled: types.bool.isRequired,
  displayAs: types.oneOf([DROPDOWN, RADIO]),
  id: types.string,
  required: types.bool.isRequired,
  questionUuid: types.string.isRequired,
  facilities: types.arrayOf(types.object),
  onChange: types.func.isRequired,
  showErrors: types.bool.isRequired,
  value: types.number,
}

export default FacilitySelector
