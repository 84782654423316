import React from "react"
import { useFacilityAuditTrailContext } from "contexts/FacilityAuditTrailContext"
import { TertiaryButton } from "shared/buttons"

const LoadMoreButton = () => {
  const { allAuditsLoaded, fetchAudits } = useFacilityAuditTrailContext()

  if (allAuditsLoaded) return null

  return (
    <div className="text-center mb-40">
      <TertiaryButton onClick={fetchAudits} text="Load more" />
    </div>
  )
}

export default LoadMoreButton
