import React, { Component, createRef } from "react"
import types from "prop-types"
import Formsy from "formsy-react"
import * as API from "services/api"
import { surveyConfigurationPath } from "utils/routeHelpers"
import {
  ACTIVE_SURVEY_DESCRIPTION,
  ANONYMOUS_SURVEY_DESCRIPTION,
  AUTO_CLOSE_DESCRIPTION_PREFIX,
  AUTO_SEND_DESCRIPTION_PREFIX,
  BATCH_AUTO_CLOSE_TITLE,
  BATCH_DURATION_UNITS,
  BATCH_RECURRENCE_TITLE,
  BATCH_RECURRENCE_UNITS,
  END_OF_MONTH_EXPLANATION,
  MANUAL_CLOSE_DESCRIPTION,
  MANUAL_SEND_DESCRIPTION,
  REMINDER_FREQUENCY_OPTIONS,
  TRACKED_SURVEY_DESCRIPTION,
} from "utils/surveyHelpers"
import {
  DAILY,
  WEEKLY,
  MONTHLY,
  QUARTERLY,
} from "utils/recurrenceDetailsHelpers"
import Switch from "components/shared/Switch"
import { PrimaryButton, Button } from "shared/buttons"
import FieldLabel from "shared/CopyFormToOrganization/FieldLabel"
import {
  FormsyNumberInput, FormsyTextInput, FormsySwitchInput,
} from "shared/inputs"
import { FormsyFormSelect, FormsyListSelect, FormsySelect } from "shared/selects"
import { errorToast, successToast } from "shared/toast"
import Tooltip from "shared/Tooltip"
import DailyRecurrenceDetails from "Surveys/SurveyConfigurationForm/Content/DailyRecurrenceDetails"
import MonthlyRecurrenceDetails from "Surveys/SurveyConfigurationForm/Content/MonthlyRecurrenceDetails"
import QuarterlyRecurrenceDetails from "Surveys/SurveyConfigurationForm/Content/QuarterlyRecurrenceDetails"
import WeeklyRecurrenceDetails from "Surveys/SurveyConfigurationForm/Content/WeeklyRecurrenceDetails"

class SurveyConfigurationEdit extends Component {
  formRef = createRef()

  state = {
    isFormValid: true,
    surveyCloses: Boolean(this.props.surveyConfiguration.batchDurationUnit),
    surveyRecurs: Boolean(this.props.surveyConfiguration.surveySchedule),
    recurrenceData: this.props.surveyConfiguration.surveySchedule,
  }

  onSubmit = async (model) => {
    if (!model.batch_duration_unit) {
      model.batch_duration_count = null
      model.batch_duration_unit = null
    }

    const { surveyConfiguration } = this.props
    const { id: surveyConfigurationId } = surveyConfiguration

    const response = await API.updateSurveyConfiguration({ surveyConfigurationId, surveyConfiguration: model })

    if (response.ok) {
      successToast("Survey updated successfully!")
      window.location = surveyConfigurationPath(surveyConfigurationId, this.props.activeTab)
    } else {
      errorToast("Something went wrong. Unable to update survey.", response)
    }
  }

  cancelForm = () => {
    this.props.closeEditMode()
  }

  toggleSurveyRecurs = () => this.setState((prevState) => (
    { surveyRecurs: !prevState.surveyRecurs }
  ))

  toggleSurveyCloses = () => this.setState((prevState) => (
    { surveyCloses: !prevState.surveyCloses }
  ))

  enableSubmit = () => this.setState({ isFormValid: true })

  disableSubmit = () => this.setState({ isFormValid: false })

  getFormAnswers = () => this.formRef.current?.getModel() || {}

  renderRecurrenceDetails = (batchRecurrenceUnit) => {
    const { recurrenceData } = this.state

    switch (batchRecurrenceUnit) {
    case DAILY:
      return <DailyRecurrenceDetails startDate={recurrenceData?.startDate} />
    case WEEKLY:
      return <WeeklyRecurrenceDetails recurrenceDay={recurrenceData?.recurrenceDayOfWeek} />
    case MONTHLY:
      return <MonthlyRecurrenceDetails recurrenceDate={recurrenceData?.recurrenceDayOfMonth} />
    case QUARTERLY:
      return <QuarterlyRecurrenceDetails />
    default:
      return null
    }
  }

  render() {
    const { surveyConfiguration } = this.props
    const { surveyForm } = surveyConfiguration

    const { isFormValid, surveyCloses, surveyRecurs } = this.state

    const {
      batch_anonymous: batchAnonymous,
    } = this.getFormAnswers()

    const batchRecurrenceUnit = this.getFormAnswers()?.survey_schedule_attributes?.batch_frequency
    const recurrenceDayOfMonth = this.getFormAnswers()?.survey_schedule_attributes?.recurrence_day_of_month

    return (
      <Formsy
        action={this.updateSurveyConfiguration}
        method="post"
        ref={this.formRef}
        onSubmit={this.onSubmit}
        onValid={this.enableSubmit}
        onInvalid={this.disableSubmit}
      >
        <p className="text-center mb-6">
          Changes will be applied to future surveys.
          <br />
          Surveys that have already been sent will not be impacted.
        </p>
        <FieldLabel text="Name" className="!max-w-full" boldLabel required>
          <FormsyTextInput
            required
            className="bordered-base-input text-sm"
            name="name"
            placeholder="Survey name..."
            value={surveyConfiguration.name}
            validations={{ isNotBlankString: true }}
          />
        </FieldLabel>
        <FieldLabel text="Description" className="!max-w-full" boldLabel>
          <FormsyTextInput
            className="bordered-base-input text-sm"
            name="description"
            placeholder="Description..."
            value={surveyConfiguration.description}
          />
        </FieldLabel>
        <FieldLabel text="Survey Form" className="mb-6" boldLabel>
          <Tooltip text="Form cannot be changed">
            <FormsyFormSelect
              className="min-w-80"
              name="survey_form_id"
              disabled
              options={[surveyForm]}
              value={surveyForm.id}
            />
          </Tooltip>
        </FieldLabel>
        <FieldLabel text="Email Reminders" className="mb-6" boldLabel required>
          <FormsySelect
            className="min-w-80"
            required
            name="reminders"
            backspaceDelete={false}
            options={REMINDER_FREQUENCY_OPTIONS}
            value={surveyConfiguration.reminders}
          />
        </FieldLabel>
        <div className="mb-4">
          <div className="mb-2">
            <span className="font-semibold mr-2">Active</span>
            <FormsySwitchInput
              className="align-top"
              name="active"
              value={surveyConfiguration.active}
            />
          </div>
          <p>
            {ACTIVE_SURVEY_DESCRIPTION}
          </p>
        </div>
        <div className="mb-4">
          <div className="mb-2">
            <span className="font-semibold mr-2">Anonymous Submissions</span>
            <FormsySwitchInput
              className="align-top"
              name="batch_anonymous"
              value={surveyConfiguration.batchAnonymous}
            />
          </div>
          <p>
            {
              batchAnonymous ? ANONYMOUS_SURVEY_DESCRIPTION : TRACKED_SURVEY_DESCRIPTION
            }
          </p>
        </div>
        <div className="mb-6">
          <div className="mb-2">
            <span className="font-semibold mr-2">{BATCH_RECURRENCE_TITLE}</span>
            <Switch
              className="align-top"
              checked={surveyRecurs}
              onChange={this.toggleSurveyRecurs}
            />
          </div>
          {
            surveyRecurs
              ? (
                <div className="flex items-center">
                  <span className="required-prompt block mr-2">{AUTO_SEND_DESCRIPTION_PREFIX}</span>
                  <FormsyListSelect
                    className="mr-2"
                    backspaceDelete={false}
                    name="survey_schedule_attributes.batch_frequency"
                    required
                    options={BATCH_RECURRENCE_UNITS}
                    value={surveyConfiguration.surveySchedule?.batchFrequency}
                  />
                  { this.renderRecurrenceDetails(batchRecurrenceUnit) }
                </div>
              )
              : (
                <p>
                  { MANUAL_SEND_DESCRIPTION }
                </p>
              )
          }
          {
            recurrenceDayOfMonth > 28
              && (
                <p className="text-sm mt-4">{END_OF_MONTH_EXPLANATION}</p>
              )
          }
        </div>
        <div className="mb-6">
          <div className="mb-2">
            <span className="font-semibold mr-2">{BATCH_AUTO_CLOSE_TITLE}</span>
            <Switch
              className="align-top"
              checked={surveyCloses}
              onChange={this.toggleSurveyCloses}
            />
          </div>
          {
            surveyCloses
              ? (
                <div className="flex items-center">
                  <span className="required-prompt block mr-2">{ AUTO_CLOSE_DESCRIPTION_PREFIX }</span>
                  <div className="w-16 mr-2">
                    <FormsyNumberInput
                      name="batch_duration_count"
                      min={1}
                      required
                      placeholder="2"
                      className="bordered-base-input"
                      value={surveyConfiguration.batchDurationCount}
                    />
                  </div>
                  <FormsyListSelect
                    backspaceDelete={false}
                    name="batch_duration_unit"
                    options={BATCH_DURATION_UNITS}
                    value={surveyConfiguration.batchDurationUnit}
                  />
                </div>
              )
              : (
                <p>
                  { MANUAL_CLOSE_DESCRIPTION }
                </p>
              )
          }
        </div>

        <div className="flex justify-center mt-8">
          <Button
            text="Cancel"
            onClick={this.cancelForm}
            className="border border-light-300 mr-2"
          />
          <PrimaryButton
            text="Update"
            type="submit"
            disabled={!isFormValid}
          />
        </div>
      </Formsy>
    )
  }
}

SurveyConfigurationEdit.propTypes = {
  surveyConfiguration: types.object.isRequired,
  activeTab: types.string.isRequired,
  closeEditMode: types.func.isRequired,
}

export default SurveyConfigurationEdit
