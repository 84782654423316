import { useState, useCallback } from "react"

const useAccordion = (expanded = false) => {
  const [isExpanded, setIsExpanded] = useState(expanded)
  const toggleAccordion = useCallback(() => setIsExpanded(!isExpanded), [isExpanded])

  return [isExpanded, toggleAccordion]
}

export default useAccordion
