import React from "react"
import types from "prop-types"
import HistoricalAccessToolLocationFilterDetailsSelectedFacilityDepartments from "./HistoricalAccessToolLocationFilterDetailsSelectedFacilityDepartments"

const ReportableFieldsLocationAnswerFiltersLocationDetailsSelectedFacilities = ({ selectedFacilityIds }) => (
  <>
    {
      selectedFacilityIds.map((facilityId) => (
        <HistoricalAccessToolLocationFilterDetailsSelectedFacilityDepartments
          key={facilityId}
          className="mb-4 last:mb-0"
          selectedFacilityId={facilityId}
        />
      ))
    }
  </>
)

ReportableFieldsLocationAnswerFiltersLocationDetailsSelectedFacilities.propTypes = {
  selectedFacilityIds: types.arrayOf(types.number).isRequired,
}

export default ReportableFieldsLocationAnswerFiltersLocationDetailsSelectedFacilities
