import React, { useState } from "react"
import types from "prop-types"
import { categoryShape } from "utils/propTypeShapes"
import ContentBar from "shared/lists/ContentBar"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import { questionTypeIcon, questionTypeLabel } from "utils/reportableFieldHelpers"
import UpdateReportableFieldsForm from "./UpdateReportableFieldsForm"
import ReportableFieldMenu from "./ReportableFieldMenu"

const ReportableField = ({
  reportableFields, field, setReportableFields, category,
}) => {
  const [isUpdateFormVisible, setIsUpdateFormVisible] = useState(false)

  const setFormEditField = () => setIsUpdateFormVisible(true)
  const cancelForm = () => setIsUpdateFormVisible(false)

  if (isUpdateFormVisible) {
    return (
      <UpdateReportableFieldsForm
        visible={isUpdateFormVisible}
        reportableField={field}
        cancelForm={cancelForm}
        categorySlug={category.slug}
        reportableFields={reportableFields}
        setReportableFields={setReportableFields}
      />
    )
  }

  return (
    <div className="flex relative">
      <ContentBar
        title={field.name}
        description={field.description}
      >
        <div className="flex">
          <SvgSpriteIcon iconName={questionTypeIcon(field)} />
          <div className="font-bold text-gray-800 text-sm ml-3">{ questionTypeLabel({ ...field }) }</div>
        </div>
      </ContentBar>
      <ReportableFieldMenu
        reportableFieldId={field.id}
        reportableFields={reportableFields}
        setReportableFields={setReportableFields}
        field={field}
        setFormEditField={setFormEditField}
        categorySlug={category.slug}
      />
    </div>
  )
}

ReportableField.propTypes = {
  category: categoryShape.isRequired,
  field: types.shape({
    id: types.number,
    name: types.string,
    description: types.string,
    questionType: types.string,
  }).isRequired,
  reportableFields: types.arrayOf(types.object).isRequired,
  setReportableFields: types.func.isRequired,
}

export default ReportableField
