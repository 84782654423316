/* eslint-disable camelcase */
import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"

// There's no unique audit action for a form submission's status changing,
// it's being discarded, or recovered.  All of these use the same "update" action.
// To determine what type of change occurred, we must
// investigate the audited changes.
const changeDescription = (audit) => {
  const { auditedChanges, fromStatus, toStatus } = audit
  const { discarded_at = [], submission_status_id } = auditedChanges

  const submissionStatusUpdated = Boolean(submission_status_id)
  const submissionRecovered = Boolean(discarded_at[0] && !discarded_at[1])
  const submissionDiscarded = Boolean(!discarded_at[0] && discarded_at[1])

  let updateDescription = "modified the form submission"

  if (submissionStatusUpdated) {
    updateDescription = `changed the form submission's status from ${fromStatus} to ${toStatus}`
  } else if (submissionRecovered) {
    updateDescription = "recovered the form submission"
  } else if (submissionDiscarded) {
    updateDescription = "deleted the form submission"
  }

  return updateDescription
}

const FormSubmissionUpdatedAuditItemDetails = ({ audit }) => (
  <div>
    <span>
      <AuditedUser audit={audit} /> {changeDescription(audit)}.
    </span>
  </div>
)

FormSubmissionUpdatedAuditItemDetails.propTypes = {
  audit: auditShape.isRequired,
}

export default FormSubmissionUpdatedAuditItemDetails
