import React from "react"
import { questionTagShape } from "utils/propTypeShapes"

const QuestionTag = ({ tag }) => (
  <p className="list-row dark">{tag.name}</p>
)

QuestionTag.propTypes = {
  tag: questionTagShape.isRequired,
}

export default QuestionTag
