import React, { useState, useMemo, useCallback } from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getUndiscardedTasks } from "reduxSlices/formSubmissionSlice"
import FormSubmissionExportContext from "../FormSubmissionExportContext"

const FormSubmissionExportContextProvider = ({ children }) => {
  const tasks = useSelector(getUndiscardedTasks)

  const taskIds = useMemo(() => tasks.map((task) => task.id), [tasks])

  const [includeFormSubmission, setIncludeFormSubmission] = useState(true)
  const [includedTaskIds, setIncludedTaskIds] = useState(taskIds)

  const selectFormSubmission = useCallback(() => {
    setIncludeFormSubmission(true)
  }, [])

  const deselectFormSubmission = useCallback(() => {
    setIncludeFormSubmission(false)
  }, [])

  const selectAllTasks = useCallback(() => {
    setIncludedTaskIds(taskIds)
  }, [taskIds])

  const deselectAllTasks = useCallback(() => {
    setIncludedTaskIds([])
  }, [])

  const selectTask = useCallback((taskId) => {
    setIncludedTaskIds([...includedTaskIds, taskId])
  }, [includedTaskIds])

  const deselectTask = useCallback((taskId) => {
    setIncludedTaskIds(includedTaskIds.filter((id) => id !== taskId))
  }, [includedTaskIds])

  const taskSelected = useCallback((taskId) => includedTaskIds.includes(taskId), [includedTaskIds])

  // Object exposed to context consumers
  const contextConsumerValue = {
    canSubmitExport: includeFormSubmission || includedTaskIds.length > 0,
    deselectAllTasks,
    deselectFormSubmission,
    deselectTask,
    formSubmissionHasTasks: tasks.length > 0,
    includeFormSubmission,
    includedTaskIds,
    selectAllTasks,
    selectTask,
    selectFormSubmission,
    taskSelected,
    tasks,
  }

  return (
    <FormSubmissionExportContext.Provider value={contextConsumerValue}>
      {children}
    </FormSubmissionExportContext.Provider>
  )
}

FormSubmissionExportContextProvider.propTypes = {
  children: types.node.isRequired,
}

export default FormSubmissionExportContextProvider
