import React from "react"
import SurveyBatchStatistics from "./SurveyBatchStatistics"
import SurveyBatchSurveyTokens from "./SurveyBatchSurveyTokens"

const SubmissionsContent = () => (
  <>
    <SurveyBatchStatistics className="mb-8" />
    <SurveyBatchSurveyTokens />
  </>
)

export default SubmissionsContent
