import React from "react"
import { SFTPSettingsContextProvider } from "contexts/SFTPSettingsContext"
import ConnectionTestModal from "./ConnectionTestModal"
import CredentialsForm from "./CredentialsForm"

export const SFTP_SETTINGS_TAB_HEADING = "SFTP Settings"

const SFTPSettingsTagTab = () => (
  <SFTPSettingsContextProvider>
    <div className="flex justify-between">
      <h2 className="tab-title">{ SFTP_SETTINGS_TAB_HEADING }</h2>
      <ConnectionTestModal />
    </div>
    <p className="text-sm mb-4">Check with your IT team to see what credentials your server requires.</p>

    <CredentialsForm />
  </SFTPSettingsContextProvider>
)

export default SFTPSettingsTagTab
