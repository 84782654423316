import React from "react"
import types from "prop-types"
import { reportableFieldSelectReportableFieldShape } from "utils/propTypeShapes"
import Select from "./Select"

const reportableFieldsToOptions = (reportableFields) => reportableFields.map((reportableField) => (
  {
    label: reportableField.name,
    value: reportableField.id,
  }
))

const ReportableFieldSelect = ({ reportableFields, ...rest }) => (
  <Select options={reportableFieldsToOptions(reportableFields)} {...rest} />
)

ReportableFieldSelect.propTypes = {
  reportableFields: types.arrayOf(reportableFieldSelectReportableFieldShape).isRequired,
}

export default ReportableFieldSelect
