import React, { useState } from "react"
import types from "prop-types"
import { useModal } from "hooks"
import Modal, { DEFAULT_MODAL_STYLE, ModalHeader } from "shared/Modal"
import { TertiaryButton } from "shared/buttons"
import FormPreviewForm from "./FormPreviewForm"

const MODAL_STYLE = {
  content: {
    ...DEFAULT_MODAL_STYLE.content,
    top: "48%",
    overflow: "visible",
    maxWidth: "70vw",
    width: "70vw",
    maxHeight: "85vh",
    display: "flex",
    flexDirection: "column",
  },
}

const FormPreview = ({ className = "" }) => {
  const { modalIsOpen, openModal, closeModal } = useModal()
  const [reset, setReset] = useState()

  return (
    <>
      <TertiaryButton className={className} onClick={openModal} text="Preview" />
      <Modal isOpen={modalIsOpen} style={MODAL_STYLE}>
        <ModalHeader
          closeModal={closeModal}
          heading="Form Preview"
        />
        <div className="flex-grow p-8 rounded bg-primary-light border border-light overflow-y-auto mb-8">
          <FormPreviewForm setReset={setReset} />
        </div>
        <div className="flex justify-center">
          <TertiaryButton className="mr-2" onClick={closeModal} text="Close" />
          {
            reset && <TertiaryButton onClick={reset} text="Reset" />
          }
        </div>
      </Modal>
    </>
  )
}

FormPreview.propTypes = {
  className: types.string,
}

export default FormPreview
