import React from "react"
import types from "prop-types"
import * as API from "services/api"
import { useOncePerPageLoadMessage } from "hooks"
import { errorToast } from "components/shared/toast"
import GroupSpan from "./GroupSpan"

const GROUP_REMOVAL_MESSAGE = "Removing a user from a group does not remove the user "
                              + "from any existing tasks or form submissions. Removal from existing "
                              + "tasks or form submissions must be done manually or by using "
                              + "the Historical Access Tool under the user's profile in the Admin area."

function GroupsTab({
  user, removeGroupMembership,
}) {
  const { groupMemberships } = user
  const { messageAcknowledged, confirmMessage } = useOncePerPageLoadMessage(GROUP_REMOVAL_MESSAGE, "group_removal_message")

  const removeMembership = async (id, groupId, memberId) => {
    if (messageAcknowledged() || confirmMessage()) {
      const response = await API.deleteGroupMembership({ groupId, memberId })

      if (response.ok) {
        removeGroupMembership(id)
      } else {
        errorToast("Something went wrong. Unable to remove user from group.")
      }
    }
  }

  return (
    <>
      <h1 className="tab-title">Groups</h1>
      {
        (groupMemberships.length === 0)
          ? (
            <span className="font-light">
              This user is not a member of any groups.
            </span>
          )
          : groupMemberships.map((groupMembership) => (
            <GroupSpan
              key={groupMembership.id}
              memberId={user.id}
              groupMembership={groupMembership}
              removeMembership={removeMembership}
            />
          ))
      }
    </>
  )
}

GroupsTab.propTypes = {
  user: types.object.isRequired,
  removeGroupMembership: types.func.isRequired,
}

export default GroupsTab
