import React from "react"
import types from "prop-types"
import clsx from "clsx"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import IconContainer from "shared/icons/IconContainer"
// eslint-disable-next-line max-len
import { FORM_ICON_COLOR_MAP } from "components/views/FormBuilder/FormBuilderTabs/FormSettingsTab/IconSetting/IconSelector/constants"

const FormIcon = ({ className = "w-8 h-8", form, onClick = () => {} }) => {
  const formIconColor = FORM_ICON_COLOR_MAP[form?.iconColor] || "bg-icon-gray"

  return (
    <IconContainer className={clsx(className, formIconColor, "shrink-0")}>
      <SvgSpriteIcon
        iconName={form.icon}
        style={{ height: "82%", width: "82%" }}
        onClick={onClick}
      />
    </IconContainer>
  )
}

FormIcon.propTypes = {
  className: types.string,
  // eslint-disable-next-line react/forbid-prop-types
  form: types.object.isRequired,
  onClick: types.func,
}

export default FormIcon
