/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react"
import types from "prop-types"
import Formsy from "formsy-react"
import * as API from "services/api"
import { Button, PrimaryButton } from "shared/buttons"
import { errorToast, successToast } from "shared/toast"
import { CREATION_SUCCESS_MESSAGE, formatReportableField } from "utils/reportableFieldHelpers"
import * as ReportableField from "./ReportableFieldFields"

const CreateReportableFieldsForm = ({
  visible, cancelForm, categorySlug, setReportableFields, reportableFields,
}) => {
  const [canSubmit, setCanSubmit] = useState(false)
  const enableSubmit = () => setCanSubmit(true)
  const disableSubmit = () => setCanSubmit(false)

  if (!visible) return null

  const createReportableField = async (reportableField) => {
    const response = await API.createCategoryReportableField({
      categorySlug,
      reportableField: formatReportableField(reportableField),
    })

    if (response.ok) {
      setReportableFields([...reportableFields, response.data])
      successToast(CREATION_SUCCESS_MESSAGE)
      cancelForm()
    } else {
      errorToast(response.data.errors.join(", "))
      console.error("Error creating reportable field: ", response);
    }
  }

  return (
    <div className="solution-container-dark">
      <Formsy
        onSubmit={createReportableField}
        onValid={enableSubmit}
        onInvalid={disableSubmit}
      >
        <div className="flex w-full mb-6">
          <div className="mr-2 w-1/2">
            <label>
              <span className="font-bold">Name</span>
              <ReportableField.Name />
            </label>
          </div>
          <div className="ml-2 w-1/2">
            <label>
              <span className="font-bold">Type</span>
              <ReportableField.QuestionType />
            </label>
          </div>
        </div>
        <div className="mb-6">
          <label>
            <span className="font-bold">Description (Optional)</span>
            <ReportableField.Description />
          </label>
        </div>
        <div className="flex justify-center">
          <PrimaryButton text="Add this Field" type="submit" disabled={!canSubmit} />
          <Button className="mx-3 bg-white border border-lightgray-3" text="Cancel" onClick={cancelForm} />
        </div>
      </Formsy>
    </div>
  )
}

CreateReportableFieldsForm.propTypes = {
  visible: types.bool.isRequired,
  cancelForm: types.func.isRequired,
  categorySlug: types.string.isRequired,
  setReportableFields: types.func.isRequired,
  reportableFields: types.arrayOf(types.object).isRequired,
}

export default CreateReportableFieldsForm
