import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { XIcon } from "shared/icons"

const RemoveButton = ({ className = "", form = "", onClick }) => (
  <button
    className={clsx(className, "bg-light rounded-full p-1 outline:none focus:outline-none")}
    form={form}
    onClick={onClick}
  >
    <XIcon width={12} height={12} />
  </button>
)

RemoveButton.propTypes = {
  className: types.string,
  form: types.string,
  onClick: types.func.isRequired,
}

export default RemoveButton
