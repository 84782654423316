import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { subscribedToAddOnFeature } from "reduxSlices/addOnFeatureSubscriptionsSlice"
import { MFA_FEATURE_NAME } from "utils/addOnFeatureHelpers"
import * as API from "services/api"
import Switch from "shared/Switch"
import ContentBar from "shared/lists/ContentBar"
import { errorToast, successToast } from "components/shared/toast"

// We only support removing an OTP confirmation.
// An admin may not add a confirmation to a user.
// Therefore, the onChange callback passed to the Switch
// is specifically for removing a confirmation.
// When the user has not confirmed an OTP authenticator application,
// the switch is disabled to prevent attempting to add one.

const CONFIRMED_DESCRIPTION = "This user has successfully set up two factor authentication. "
                              + "Toggle this attribute off if the user needs to receive a new QR code "
                              + "when next signing in."

const UNCONFIRMED_DESCRIPTION = "This user has not set up two factor authentication "
                                + "and will be required to do so when next signing in."

const UserOtpConfirmed = ({ user, updateOtpConfirmed }) => {
  const subscribedToMFA = useSelector(subscribedToAddOnFeature(MFA_FEATURE_NAME))

  const description = user.otpConfirmed ? CONFIRMED_DESCRIPTION : UNCONFIRMED_DESCRIPTION

  const removeOtpConfirmation = async () => {
    const response = await API.removeOtpConfirmation({ userId: user.id })

    if (response.ok) {
      successToast("Confirmation removed successfully.")
      updateOtpConfirmed(false)
    } else {
      errorToast("Something went wrong.  Unable to remove confirmation.")
    }
  }

  if (!subscribedToMFA) return null

  return (
    <ContentBar
      description={description}
      title="Two Factor Authentication Confirmed"
    >
      <Switch
        checked={user.otpConfirmed}
        disabled={!user.otpConfirmed}
        onChange={removeOtpConfirmation}
      />
    </ContentBar>
  )
}

UserOtpConfirmed.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: types.object.isRequired,
  updateOtpConfirmed: types.func.isRequired,
}

export default UserOtpConfirmed
