import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { useDispatch, useSelector } from "react-redux"
import { Draggable } from "react-beautiful-dnd"
import Switch from "shared/Switch"
import { DragGripHandleIcon } from "shared/icons"
import { getFormSubmissionTable, showColumn, hideColumn } from "reduxSlices/formSubmissionTableSlice"

const SubmissionColumn = ({
  label, columns, index, value,
}) => {
  const dispatch = useDispatch()

  const { visibleColumns } = useSelector(getFormSubmissionTable)

  const column = columns[index]

  const isColumnVisible = visibleColumns.includes(column.value)

  const toggleSelectedColumns = () => {
    const functionToDispatch = isColumnVisible ? hideColumn : showColumn

    dispatch(functionToDispatch(column.value))
  }

  return (
    <Draggable draggableId={value} index={index}>
      {
        (dragProvided) => (
          <div
            ref={dragProvided.innerRef}
            className="flex-center"
            {...dragProvided.draggableProps}
          >
            <DragGripHandleIcon dragProvided={dragProvided} />
            <div className={clsx(
              "flex-center flex-grow",
              "font-medium text-sm",
              "mb-2 p-2 border border-lightgray-3 rounded-md",
            )}
            >
              { label }
              <Switch
                checked={isColumnVisible}
                onChange={toggleSelectedColumns}
              />
            </div>
          </div>
        )
      }
    </Draggable>
  )
}

SubmissionColumn.propTypes = {
  label: types.string.isRequired,
  index: types.number.isRequired,
  value: types.string.isRequired,
  columns: types.arrayOf(types.object).isRequired,
}

export default SubmissionColumn
