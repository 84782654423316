import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"

const TaskCreatedAuditItemDetails = ({ audit }) => {
  const { task } = audit

  return (
    <div>
      <span>
        <AuditedUser audit={audit} /> created a
        task titled <span className="font-semibold">{task.title}</span>.
      </span>
    </div>
  )
}

TaskCreatedAuditItemDetails.propTypes = {
  audit: auditShape.isRequired,
}

export default TaskCreatedAuditItemDetails
