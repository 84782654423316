import React, { useMemo } from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getFormQuestionAttributeEndTimeQuestionUuid, getFormQuestionAttributeStartTimeQuestionUuid } from "reduxSlices/formBuilderSlice"
import FormsyValidation from "shared/FormsyValidation"

const NoBlankOptionsValidation = ({ sectionIndex, questionIndex }) => {
  const questionEndTimeQuestionUuid = useSelector(getFormQuestionAttributeEndTimeQuestionUuid({ sectionIndex, questionIndex }))
  const questionStartTimeQuestionUuid = useSelector(getFormQuestionAttributeStartTimeQuestionUuid({ sectionIndex, questionIndex }))

  const value = useMemo(
    () => [questionEndTimeQuestionUuid, questionStartTimeQuestionUuid],
    [questionEndTimeQuestionUuid, questionStartTimeQuestionUuid],
  )

  return (
    <FormsyValidation
      name={`sections[${sectionIndex}].questions[${questionIndex}].noBlankQuestions`}
      value={value}
      validations={{
        noBlankQuestions(_model, val) {
          if (val?.every(Boolean)) return true
          return "Must have questions selected"
        },
      }}
    />
  )
}

NoBlankOptionsValidation.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default NoBlankOptionsValidation
