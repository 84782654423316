import React, { useCallback, useEffect } from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { getFormQuestionAttributeDisplayAs, setQuestionAttributeDisplayAs } from "reduxSlices/formBuilderSlice"
import { Select } from "shared/selects"

export const DROPDOWN = "dropdown"
export const RADIO = "radio"

const options = [
  { label: "Dropdown", value: DROPDOWN },
  { label: "Radio Buttons", value: RADIO },
]

const LocationDisplayAs = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const questionDisplayAs = useSelector(getFormQuestionAttributeDisplayAs({ sectionIndex, questionIndex }))

  const updateDisplayAs = useCallback((newDisplayAs) => dispatch(setQuestionAttributeDisplayAs({
    sectionIndex, questionIndex, newDisplayAs,
  })), [sectionIndex, questionIndex])

  useEffect(() => {
    if (!questionDisplayAs) updateDisplayAs(RADIO)
  }, [questionDisplayAs, updateDisplayAs])

  return (
    <div className="flex-center mb-4">
      <div className="font-medium">Display</div>
      <Select
        className="text-sm"
        style={{ height: "32px" }}
        backspaceDelete={false}
        value={questionDisplayAs}
        options={options}
        onChange={updateDisplayAs}
      />
    </div>
  )
}

LocationDisplayAs.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default LocationDisplayAs
