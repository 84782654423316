import React from "react"
import types from "prop-types"
import ExpandIconButton from "./ExpandIconButton"
import LockedIcon from "./LockedIcon"

const ExpansionControl = ({ isExpanded, isDisabled = false, toggleAccordion }) => (
  isDisabled
    ? <LockedIcon />
    : (
      <ExpandIconButton
        isExpanded={isExpanded}
        toggleAccordion={toggleAccordion}
      />
    )
)

ExpansionControl.propTypes = {
  isExpanded: types.bool.isRequired,
  isDisabled: types.bool,
  toggleAccordion: types.func.isRequired,
}

export default ExpansionControl
