import React from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { useSelector } from "react-redux"
import { getFormQuestionOptions } from "reduxSlices/formBuilderSlice"
// eslint-disable-next-line max-len
import * as SelectOptionsValidations from "FormBuilder/QuestionFields/QuestionTypeAttributes/SelectAttributes/SelectOptionsValidations"
import SelectCSVViewOptions from "./SelectCSVViewOptions"
import SelectCSVUploadCSV from "./SelectCSVUploadCSV"

const SelectCSVOptions = ({ questionIndex, sectionIndex }) => {
  const options = useSelector(getFormQuestionOptions({ sectionIndex, questionIndex }), isEqual)
  const hasOptions = options?.length > 0

  return (
    <>
      {
        hasOptions
          ? <SelectCSVViewOptions questionIndex={questionIndex} sectionIndex={sectionIndex} />
          : <SelectCSVUploadCSV questionIndex={questionIndex} sectionIndex={sectionIndex} />
      }
      <SelectOptionsValidations.OptionsLengthValidation
        value={options}
        sectionIndex={sectionIndex}
        questionIndex={questionIndex}
      />
      <SelectOptionsValidations.NoBlankOptionsValidation
        value={options}
        sectionIndex={sectionIndex}
        questionIndex={questionIndex}
      />
      <SelectOptionsValidations.NoDuplicateOptionsValidation
        value={options}
        sectionIndex={sectionIndex}
        questionIndex={questionIndex}
      />
      <SelectOptionsValidations.NoOptionsWithHTMLValidation
        value={options}
        sectionIndex={sectionIndex}
        questionIndex={questionIndex}
      />
      <SelectOptionsValidations.NoOptionsWithExcelMetacharactersValidation
        value={options}
        sectionIndex={sectionIndex}
        questionIndex={questionIndex}
      />

    </>
  )
}

SelectCSVOptions.propTypes = {
  questionIndex: types.number.isRequired,
  sectionIndex: types.number.isRequired,
}

export default SelectCSVOptions
