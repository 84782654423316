/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react"
import types from "prop-types"
import Formsy from "formsy-react"
import * as API from "services/api"
import { PrimaryButton, Button } from "shared/buttons"
import { errorToast, successToast } from "shared/toast"
import { formatReportableField, UPDATE_SUCCESS_MESSAGE } from "utils/reportableFieldHelpers"
import * as ReportableField from "./ReportableFieldFields"

const UpdateReportableFieldsForm = ({
  visible, reportableField = {}, cancelForm, categorySlug, setReportableFields, reportableFields,
}) => {
  const [canSubmit, setCanSubmit] = useState(false)
  const enableSubmit = () => setCanSubmit(true)
  const disableSubmit = () => setCanSubmit(false)

  if (!visible) return null

  const updateReportableField = async (reportableFieldUpdate) => {
    const response = await API.updateCategoryReportableField({
      categorySlug,
      reportableField: formatReportableField(reportableFieldUpdate),
      reportableFieldId: reportableField.id,
    })

    if (response.ok) {
      const copyReportableFields = [...reportableFields]
      const index = copyReportableFields.findIndex(({ id }) => id === reportableField.id)
      copyReportableFields.splice(index, 1, response.data)

      setReportableFields(copyReportableFields)
      successToast(UPDATE_SUCCESS_MESSAGE)
      cancelForm()
    } else {
      errorToast(response.data.errors.join(", "))
      console.error("Error updating reportable field: ", response);
    }
  }

  return (
    <div className="solution-container-dark">
      <Formsy
        onSubmit={updateReportableField}
        onValid={enableSubmit}
        onInvalid={disableSubmit}
      >
        <div className="flex w-full mb-6">
          <div className="mr-2 w-1/2">
            <label>
              <span className="font-bold">Name</span>
              <ReportableField.Name value={reportableField.name} />
            </label>
          </div>
          <div className="ml-2 w-1/2">
            <label>
              <span className="font-bold">Type</span>
              <ReportableField.QuestionType value={reportableField.questionType} />
            </label>
          </div>
        </div>
        <div className="mb-6">
          <label>
            <span className="font-bold">Description (Optional)</span>
            <ReportableField.Description value={reportableField.description} />
          </label>
        </div>
        <div className="flex justify-center">
          <PrimaryButton text="Save Field" type="submit" disabled={!canSubmit} />
          <Button className="mx-3 bg-white border border-lightgray-3" text="Cancel" onClick={cancelForm} />
        </div>
      </Formsy>
    </div>
  )
}

UpdateReportableFieldsForm.propTypes = {
  visible: types.bool.isRequired,
  cancelForm: types.func.isRequired,
  reportableField: types.shape({
    id: types.number,
    name: types.string,
    description: types.string,
    questionType: types.string,
  }),
  categorySlug: types.string.isRequired,
  setReportableFields: types.func.isRequired,
  reportableFields: types.arrayOf(types.object).isRequired,
}

export default UpdateReportableFieldsForm
