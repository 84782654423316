import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"

const FallbackAuditItemDetails = ({ audit }) => {
  const { action, auditableType } = audit

  return (
    <div>
      <span>
        <AuditedUser audit={audit} /> performed an
        action: {action} {auditableType}.
      </span>
    </div>
  )
}

FallbackAuditItemDetails.propTypes = {
  audit: auditShape.isRequired,
}

export default FallbackAuditItemDetails
