import React from "react"
import types from "prop-types"

const ActionHeading = ({ iconComponent, text }) => {
  const Icon = iconComponent

  return (
    <div className="flex items-center mb-2">
      <Icon className="mr-1" height="20" width="20" />
      <span>{text}</span>
    </div>
  )
}

ActionHeading.propTypes = {
  iconComponent: types.elementType.isRequired,
  text: types.string.isRequired,
}

export default ActionHeading
