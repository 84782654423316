import React from "react"
import types from "prop-types"
import { useDispatch } from "react-redux"
import { removeQuestionAttributeCaclQuestionUuid } from "reduxSlices/formBuilderSlice"
import { Button } from "shared/buttons"

const RemoveQuestionButton = ({ sectionIndex, questionIndex, calcQuestionUuidIndex }) => {
  const dispatch = useDispatch()
  const removeCalcQuestionUuid = () => dispatch(removeQuestionAttributeCaclQuestionUuid({ sectionIndex, questionIndex, calcQuestionUuidIndex }))

  return (
    <Button
      icon="fas fa-times-circle"
      onClick={removeCalcQuestionUuid}
    />
  )
}

RemoveQuestionButton.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
  calcQuestionUuidIndex: types.number.isRequired,
}

export default RemoveQuestionButton
