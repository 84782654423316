import React from "react"
import types from "prop-types"
import { store } from "store"
import Sidebar from "shared/Sidebar"
import { setShowClosedTasks } from "reduxSlices/taskTableSlice"
import ClosedTasksFilter from "./ClosedTasksFilter"
import ColumnSelector from "./ColumnSelector"
import TaskGroupBy from "./GroupTasksBy/TaskGroupBy"
import TaskCategorySelect from "./GroupTasksBy/TaskCategorySelect"

const TaskSidebar = ({ hiddenSidebar }) => {
  const toggleClosed = (value) => store.dispatch(setShowClosedTasks(value))

  const toggleShowClosedTasks = (value) => {
    toggleClosed(value)
  }

  return (
    <Sidebar hiddenSidebar={hiddenSidebar}>
      <ClosedTasksFilter toggleShowClosedTasks={toggleShowClosedTasks} />
      <TaskGroupBy />
      <TaskCategorySelect />
      <ColumnSelector />
    </Sidebar>
  )
}

TaskSidebar.propTypes = {
  hiddenSidebar: types.bool.isRequired,
}

export default TaskSidebar
