import React from "react"
import types from "prop-types"
import { useTaskFormContext } from "contexts/TaskFormContext"
import FormsyValidation from "shared/FormsyValidation"
import { FormsyHiddenInput } from "components/shared/inputs"
import TaskMemberDepartmentSelector from "./TaskMemberDepartmentSelector"
import TaskMemberGroupList from "./TaskMemberGroupList"
import TaskMemberGroupSelector from "./TaskMemberGroupSelector"
import TaskMemberList from "./TaskMemberList"
import TaskMemberSelector from "./TaskMemberSelector"
import TaskMemberDepartmentList from "./TaskMemberDepartmentList"

const TaskMembers = ({ className }) => {
  const { taskMemberGroupIds, taskMemberIds, taskMemberDepartments } = useTaskFormContext()

  const groupsSelected = taskMemberGroupIds.length > 0

  return (
    <div className={className}>
      <span className="block mb-1 font-semibold">Members</span>
      <TaskMemberList className={groupsSelected ? "mb-8" : "mb-4"} />
      <TaskMemberGroupList className="mb-4" />
      <TaskMemberDepartmentList className="mb-4" />
      <div className="flex flex-col md:flex-row gap-2">
        <TaskMemberSelector />
        <TaskMemberGroupSelector />
        <TaskMemberDepartmentSelector />
      </div>
      <FormsyValidation
        name="task.member_segments_validation"
        validations={{
          requireDepartmentSegments: (values) => {
            if (!values["task.member_departments"]?.length > 0) return true
            if (values["task.member_departments"].filter((dept) => !dept.segments?.length > 0).length === 0) return true

            return "selected departments require at least one segment"
          },
        }}
      />
      <FormsyHiddenInput name="task.member_ids" value={taskMemberIds} />
      <FormsyHiddenInput name="task.member_group_ids" value={taskMemberGroupIds} />
      <FormsyHiddenInput name="task.member_departments" value={taskMemberDepartments} />
    </div>
  )
}

TaskMembers.defaultProps = {
  className: "",
}

TaskMembers.propTypes = {
  className: types.string,
}

export default TaskMembers
