import React, { useMemo } from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { useDispatch, useSelector } from "react-redux"
import {
  forms,
  selectForm,
  selectedCategorySlug,
  selectedFormSlug,
} from "reduxSlices/reportBuilderSlice"
import { Select } from "shared/selects"

const FormSelector = ({ className = "" }) => {
  const dispatch = useDispatch()

  const storedForms = useSelector(forms, isEqual)
  const categorySlug = useSelector(selectedCategorySlug)
  const formSlug = useSelector(selectedFormSlug)

  const formOptions = useMemo(() => (
    storedForms.map((form) => (
      {
        label: form.title,
        sort: form.title.toLowerCase(),
        value: form.slug,
      }
    ))
  ), [storedForms])

  const customNoDataRenderer = () => (<div className="mx-2 my-1">No forms available</div>)

  if (!categorySlug) return null

  return (
    <Select
      className={className}
      noDataRenderer={customNoDataRenderer}
      onChange={(slug) => { dispatch(selectForm({ selectedFormSlug: slug })) }}
      options={formOptions}
      sortBy="sort"
      value={formSlug}
    />
  )
}

FormSelector.propTypes = {
  className: types.string,
}

export default FormSelector
