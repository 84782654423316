import React, { useCallback, useRef } from "react"
import types from "prop-types"
import { useDispatch } from "react-redux"
import { moveTaskFormQuestionColumn } from "reduxSlices/reportBuilderSlice"
import SelectedColumn from "./SelectedColumn"
import { DraggableColumnTypes } from "./DragAndDrop/DraggableColumnTypes"
import SelectionDropZone from "./DragAndDrop/SelectionDropZone"
import useScrollToAdded from "./DragAndDrop/useScrollToAdded"

const TaskFormQuestionColumnSelections = ({ taskForm }) => {
  const { id: slug, questions } = taskForm
  const selectedQuestions = questions.map((q) => ({ ...q, formSlug: slug }))

  const dispatch = useDispatch()
  const ref = useRef(null)

  useScrollToAdded(ref, selectedQuestions)

  const moveColumn = useCallback((column, toIndex) => {
    dispatch(moveTaskFormQuestionColumn({ column, toIndex, slug }))
  }, [dispatch, slug])

  const canMove = (draggedQuestion, hoveredQuestion) => (
    draggedQuestion.formSlug === hoveredQuestion.formSlug
      && draggedQuestion.id !== hoveredQuestion.id
  )

  const canDrop = (item) => (item.formSlug === slug)

  return (
    <SelectionDropZone
      acceptableTypes={DraggableColumnTypes.TASK_QUESTION_SELECTION}
      canDropPredicate={canDrop}
      ref={ref}
    >
      {
        selectedQuestions.map((question, index) => (
          <SelectedColumn
            key={question.id}
            column={{ ...question, index }}
            moveColumn={moveColumn}
            canMove={canMove}
            canDropPredicate={canDrop}
          />
        ))
      }
    </SelectionDropZone>
  )
}

TaskFormQuestionColumnSelections.propTypes = {
  taskForm: types.object.isRequired,
}

export default TaskFormQuestionColumnSelections
