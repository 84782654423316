import React from "react"
import { isEqual } from "lodash-es"
import { useSelector } from "react-redux"
import { getFormUsages } from "reduxSlices/formIndexSlice"
import { SidebarCollapsibleContent } from "shared/Sidebar"
import FormIndexSidebarFormTypeSectionFormTypeCheckbox from "./FormIndexSidebarFormTypeSectionFormTypeCheckbox"

const FormIndexSidebarFormTypeSection = () => {
  const formUsages = useSelector(getFormUsages, isEqual)

  return (
    <SidebarCollapsibleContent title="Form Types">
      {
        formUsages.map((formUsage) => (
          <FormIndexSidebarFormTypeSectionFormTypeCheckbox key={formUsage} formUsage={formUsage} />
        ))
      }
    </SidebarCollapsibleContent>
  )
}

export default FormIndexSidebarFormTypeSection
