import React from "react"
import types from "prop-types"
import * as API from "services/api"
import ExpandMenu, { ExpandMenuItem } from "shared/ExpandMenu"
import { EllipsisIcon } from "shared/icons"
import { redirectTo } from "utils/routeHelpers"

const DeletedSubmissionMenuDataElement = ({ rowData, rowHrefFunc }) => {
  const recoverSubmission = async () => {
    const response = await API.recoverFormSubmission({ slug: rowData.slug })

    if (response.ok) {
      redirectTo(rowHrefFunc(rowData))
    } else {
      console.error("Error recovering form submission: ", response);
    }
  }

  return (
    <td className="w-8">
      <ExpandMenu
        expandMenuComponent={(
          <EllipsisIcon />
        )}
      >
        <ExpandMenuItem onClick={recoverSubmission}>
          <span>Recover</span>
        </ExpandMenuItem>
      </ExpandMenu>
    </td>
  )
}

DeletedSubmissionMenuDataElement.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rowData: types.object.isRequired,
  rowHrefFunc: types.func.isRequired,
}

export default DeletedSubmissionMenuDataElement
