import React, { useState } from "react"
import types from "prop-types"
import { MediumWrap } from "shared/wraps"
import * as API from "services/api"
import { successToast, errorToast } from "shared/toast"
import DepartmentTabs from "./DepartmentTabs"

const goToFacilityShow = (facilityId) => `/admin/facilities/${facilityId}`

const ShowDepartment = ({ department: initialDepartment, facility }) => {
  const [department, setDepartment] = useState(initialDepartment)

  const addMember = (member) => setDepartment((prevDepartment) => ({
    ...prevDepartment,
    members: [...prevDepartment.members, member],
  }))

  const removeMember = (memberId) => setDepartment((prevDepartment) => ({
    ...prevDepartment,
    members: [...prevDepartment.members.filter(({ id }) => id !== memberId)],
  }))

  const updateDepartment = async (model) => {
    const response = await API.updateFacilityDepartment({
      facilityId: department.facilityId,
      departmentId: department.id,
      department: model,
    })

    if (response.ok) {
      setDepartment((prevDepartment) => ({ ...prevDepartment, ...model }))
      successToast("Department updated successfully")
    } else {
      console.error("Error updating department: ", response)
      errorToast("Something went wrong. Unable to update department", response)
    }
  }

  const tabProps = {
    department,
    addMember,
    removeMember,
    updateDepartment,
  }

  return (
    <MediumWrap>
      <div className="admin-header">
        <a
          className="header-link"
          href={goToFacilityShow(department.facilityId)}
        >
          { facility.name }
        </a>
        <div className="header-title">{ department.name }</div>
      </div>
      <DepartmentTabs tabProps={tabProps} />
    </MediumWrap>
  )
}

ShowDepartment.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  department: types.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  facility: types.object.isRequired,
}

export default ShowDepartment
