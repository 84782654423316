import React, { useState } from "react"
import clsx from "clsx"
import { useFormSubmissionReportExportContext } from "contexts/FormSubmissionReportExportContext"
import LocationAnswerFiltersLocationDetailsFacilitySelect from "./LocationAnswerFiltersLocationDetailsFacilitySelect"
import LocationAnswerFiltersLocationDetailsSelectedFacilities from "./LocationAnswerFiltersLocationDetailsSelectedFacilities"

const LocationAnswerFiltersLocationDetails = () => {
  const {
    addAllFacilityDepartmentsToLocationAnswerFilters,
    removeAllFacilityDepartmentsFromLocationAnswerFilters,
  } = useFormSubmissionReportExportContext()

  const [selectedFacilityIds, setSelectedFacilityIds] = useState([])

  const updateSelectedFacilities = (facilityIds) => {
    const currentSelectedFacilityIds = selectedFacilityIds

    const facilityIdsToAdd = facilityIds.filter((id) => !currentSelectedFacilityIds.includes(id))
    facilityIdsToAdd.forEach(addAllFacilityDepartmentsToLocationAnswerFilters)

    const facilityIdsToRemove = currentSelectedFacilityIds.filter((id) => !facilityIds.includes(id))
    facilityIdsToRemove.forEach(removeAllFacilityDepartmentsFromLocationAnswerFilters)

    setSelectedFacilityIds(facilityIds)
  }

  return (
    <>
      <LocationAnswerFiltersLocationDetailsFacilitySelect
        className={clsx(selectedFacilityIds.length > 0 && "mb-4")}
        onChange={updateSelectedFacilities}
        values={selectedFacilityIds}
      />
      {
        selectedFacilityIds.length > 0 && (
          <div>
            <span className="block mb-4">Departments:</span>
            <LocationAnswerFiltersLocationDetailsSelectedFacilities selectedFacilityIds={selectedFacilityIds} />
          </div>
        )
      }
    </>
  )
}

export default LocationAnswerFiltersLocationDetails
