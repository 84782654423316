import React from "react"
import types from "prop-types"
import { UserSearch } from "shared/searches"
import * as API from "services/api"
import MemberList from "./MemberList"

const MembersTab = ({ department, addMember, removeMember }) => {
  const createDepartmentMembership = async (memberId) => {
    const response = await API.createDepartmentMembership({
      facilityId: department.facilityId,
      departmentId: department.id,
      department_membership: { member_id: memberId },
    })
    if (response.ok) {
      addMember(response.data)
    } else {
      console.error("Error adding member", response)
    }
  }

  const deleteDepartmentMembership = async (memberId) => {
    const response = await API.deleteDepartmentMembership({
      facilityId: department.facilityId,
      departmentId: department.id,
      memberId,
    })
    if (response.ok) {
      removeMember(memberId)
    } else {
      console.error("Error removing member", response)
    }
  }

  return (
    <>
      <h2 className="tab-title">Members</h2>
      <UserSearch
        userList={department.members}
        onUserSelected={createDepartmentMembership}
      />
      <MemberList
        members={department.members}
        deleteDepartmentMembership={deleteDepartmentMembership}
      />
    </>
  )
}

MembersTab.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  department: types.object.isRequired,
  addMember: types.func.isRequired,
  removeMember: types.func.isRequired,
}

export default MembersTab
