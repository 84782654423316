import React from "react"
import { categoryShape } from "utils/propTypeShapes"
import StatusList from "./StatusList"

const SubmissionStatusesTab = ({ category }) => (
  <>
    <div className="tab-title">Submission Statuses</div>
    <StatusList categorySlug={category.slug} />
  </>
)

SubmissionStatusesTab.propTypes = {
  category: categoryShape.isRequired,
}

export default SubmissionStatusesTab
