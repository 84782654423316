import { useState } from "react"

function useSubmissionRelativeDateFilter({
  submissionPreviousRangeLength: initialPreviousRangeLength = "",
  submissionPreviousRangeUnit: initialPreviousRangeUnit = "",
} = {}) {
  const [submissionPreviousRangeLength, setSubmissionPreviousRangeLength] = useState(initialPreviousRangeLength)
  const [submissionPreviousRangeUnit, setSubmissionPreviousRangeUnit] = useState(initialPreviousRangeUnit)

  return {
    submissionPreviousRangeLength,
    submissionPreviousRangeUnit,
    setSubmissionPreviousRangeLength,
    setSubmissionPreviousRangeUnit,
  }
}

export default useSubmissionRelativeDateFilter
