import React, { useEffect } from "react"
import types from "prop-types"
import { Provider } from "react-redux"
import { setForms, setSurveyBatches } from "reduxSlices/surveyBatchTableSlice"
import { store } from "store"
import { useHiddenSidebar } from "hooks"
import Sidebar from "./Sidebar"
import BatchesContent from "./Content/BatchesContent"

const SurveyBatchIndex = ({ surveyBatches, forms }) => {
  useEffect(() => {
    store.dispatch(setSurveyBatches(surveyBatches))
  }, [])

  useEffect(() => {
    store.dispatch(setForms(forms))
  }, [])

  const { hiddenSidebar, toggleSidebar } = useHiddenSidebar()

  return (
    <Provider store={store}>
      <Sidebar hiddenSidebar={hiddenSidebar} />
      <BatchesContent
        hiddenSidebar={hiddenSidebar}
        toggleSidebar={toggleSidebar}
      />
    </Provider>
  )
}

SurveyBatchIndex.propTypes = {
  surveyBatches: types.arrayOf(types.object).isRequired,
  forms: types.arrayOf(types.object).isRequired,
}

export default SurveyBatchIndex
