import React from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { getFormQuestionDescriptionShown, setQuestionShowDescription } from "reduxSlices/formBuilderSlice"
import { SwitchInput } from "shared/inputs"

const QuestionShowDescription = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const questionDescriptionShown = useSelector(getFormQuestionDescriptionShown({ sectionIndex, questionIndex }))

  const updateQuestionShowDescription = (newShowDescription) => dispatch(setQuestionShowDescription({
    sectionIndex, questionIndex, newShowDescription,
  }))

  return (
    <div className="mb-4 flex-center">
      <div className="font-medium">Include field description</div>
      <SwitchInput
        value={questionDescriptionShown}
        onChange={updateQuestionShowDescription}
      />
    </div>
  )
}

QuestionShowDescription.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default QuestionShowDescription
