import { INITIAL_DATE_BY_WEEK_GROUPING, dateCreatedGrouping } from "utils/groupingHelpers"

export const GROUP_BY_DATE_CREATED = "createdAt"
export const GROUP_BY_FILE_TYPE = "fileType"

const fileTypeGroupTitle = (fileType) => `${fileType.toUpperCase()} Files`

export const groupByDateCreated = (attachments) => attachments.reduce((acc, attachment) => {
  const { createdAt } = attachment
  const groupName = dateCreatedGrouping(createdAt)
  acc[groupName].push(attachment)

  return acc
}, INITIAL_DATE_BY_WEEK_GROUPING())

export const groupByFileType = (attachments) => {
  const initialAttachmentGrouping = Object.fromEntries(
    attachments.map((attachment) => [fileTypeGroupTitle(attachment.fileType), []]).sort(),
  )

  return attachments.reduce((acc, attachment) => {
    const { fileType } = attachment
    acc[fileTypeGroupTitle(fileType)].push(attachment)

    return acc
  }, initialAttachmentGrouping)
}

export const GROUPABLE_COLUMNS = {
  [GROUP_BY_DATE_CREATED]: {
    label: "Date Uploaded",
    value: GROUP_BY_DATE_CREATED,
    groupingMethod: groupByDateCreated,
  },
  [GROUP_BY_FILE_TYPE]: {
    label: "File Type",
    value: GROUP_BY_FILE_TYPE,
    groupingMethod: groupByFileType,
  },
}

export const getGroupingMethod = (groupName) => GROUPABLE_COLUMNS[groupName].groupingMethod
