import { redirectTo, SIGN_OUT_PATH } from "utils/routeHelpers"
import { BroadcastChannel } from "broadcast-channel"

const END_SESSION = "end_session"

const logoutChannel = new BroadcastChannel(END_SESSION)

export const endSession = () => {
  logoutChannel.postMessage(END_SESSION)
  redirectTo(SIGN_OUT_PATH)
}

export const endAllTabSessionsListener = () => {
  logoutChannel.onmessage = () => {
    endSession()
    logoutChannel.close()
  }
}
