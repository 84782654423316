import React from "react"
import types from "prop-types"
import { SurveyBatchContextProvider } from "contexts/SurveyBatchContext"
import SurveyBatchTabs from "./SurveyBatchTabs"

const SurveyBatchShow = ({ surveyBatch }) => (
  <SurveyBatchContextProvider surveyBatch={surveyBatch}>
    <SurveyBatchTabs surveyBatch={surveyBatch} />
  </SurveyBatchContextProvider>
)

SurveyBatchShow.propTypes = {
  surveyBatch: types.object.isRequired,
}

export default SurveyBatchShow
