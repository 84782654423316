import React, { useState, useRef } from "react"
import clsx from "clsx"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const isActive = (index, activeIndex) => (index === activeIndex)

const HiddenSideTabs = ({
  className = "",
  tabs,
  initialActiveIndex = 0,
  SideTabContents,
  sideTabContentClassName = "side-tabs-container",
}) => {
  const [activeIndex, setActiveIndex] = useState(initialActiveIndex)
  const tabRef = useRef([])

  return (
    <div className={clsx(className, "flex")}>
      <ol className="mr-8">
        {
          tabs.map(({ iconName, title }, index) => (
            <li
              key={title}
              ref={(el) => {
                tabRef.current[index] = el
              }}
              onClickCapture={() => setActiveIndex(index)}
              className={clsx(
                !isActive(index, activeIndex) && "text-dark",
                isActive(index, activeIndex) && "font-semibold",
                "text-right cursor-pointer text-lg mt-8 whitespace-nowrap",
              )}
            >
              {
                (iconName) && (
                  <SvgSpriteIcon iconName={iconName} className="mr-2" />
                )
              }
              { title }
            </li>
          ))
        }
      </ol>

      <div className={sideTabContentClassName}>
        <SideTabContents tabs={tabs} activeIndex={activeIndex} />
      </div>
    </div>
  )
}

HiddenSideTabs.propTypes = {
  className: types.string,
  tabs: types.arrayOf(types.object).isRequired,
  initialActiveIndex: types.number,
  sideTabContentClassName: types.string,
  SideTabContents: types.func.isRequired,
}

export default HiddenSideTabs
