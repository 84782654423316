import React from "react"
import types from "prop-types"
import { useTaskFormContext } from "contexts/TaskFormContext"
import FormsyValidation from "shared/FormsyValidation"
import { FormsyHiddenInput } from "shared/inputs"
import TaskAssigneeDepartmentSelector from "./TaskAssigneeDepartmentSelector"
import TaskAssigneeGroupList from "./TaskAssigneeGroupList"
import TaskAssigneeGroupSelector from "./TaskAssigneeGroupSelector"
import TaskAssigneeList from "./TaskAssigneeList"
import TaskAssigneeSelector from "./TaskAssigneeSelector"
import TaskAssigneeDepartmentList from "./TaskAssigneeDepartmentList"

const TaskAssignees = ({ className }) => {
  const { taskAssigneeGroupIds, taskAssigneeIds, taskAssigneeDepartments } = useTaskFormContext()

  const groupsSelected = taskAssigneeGroupIds.length > 0

  return (
    <div className={className}>
      <span className="block mb-1 font-semibold required-prompt">Assignees</span>
      <TaskAssigneeList className={groupsSelected ? "mb-8" : "mb-4"} />
      <TaskAssigneeGroupList className="mb-4" />
      <TaskAssigneeDepartmentList className="mb-4" />
      <div className="relative w-full">
        <div className="w-full flex flex-col md:flex-row gap-2">
          <TaskAssigneeSelector className="w-full md:w-auto" />
          <TaskAssigneeGroupSelector />
          <TaskAssigneeDepartmentSelector />
        </div>
        <FormsyValidation
          name="task.assignees_validation"
          validations={{
            requireAssignees: (values) => {
              if (values["task.assignee_ids"]?.length > 0) return true
              if (values["task.assignee_group_ids"]?.length > 0) return true
              if (values["task.assignee_departments"]?.length > 0) return true

              return "task must be assigned to a user, group, or department"
            },
            requireDepartmentSegments: (values) => {
              if (!values["task.assignee_departments"]?.length > 0) return true
              /* eslint-disable-next-line max-len */
              if (values["task.assignee_departments"].filter((dept) => !dept.segments?.length > 0).length === 0) return true

              return "selected departments require at least one segment"
            },
          }}
        />
      </div>
      <FormsyHiddenInput name="task.assignee_ids" value={taskAssigneeIds} />
      <FormsyHiddenInput name="task.assignee_group_ids" value={taskAssigneeGroupIds} />
      <FormsyHiddenInput name="task.assignee_departments" value={taskAssigneeDepartments} />
    </div>
  )
}

TaskAssignees.defaultProps = {
  className: "",
}

TaskAssignees.propTypes = {
  className: types.string,
}

export default TaskAssignees
