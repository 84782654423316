import React from "react"
import types from "prop-types"
import { useReportContext } from "contexts/ReportContext"
import Switch from "shared/Switch"

const ScheduleActive = ({ scheduleId }) => {
  const { activate, deactivate, getScheduleActiveStatus } = useReportContext()

  return (
    <Switch
      checked={getScheduleActiveStatus(scheduleId)}
      onChange={getScheduleActiveStatus(scheduleId) ? deactivate(scheduleId) : activate(scheduleId)}
    />
  )
}

ScheduleActive.propTypes = {
  scheduleId: types.number.isRequired,
}

export default ScheduleActive
