import React from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import * as API from "services/api"
import { isEqual } from "lodash-es"
import {
  facilityId,
  facilityGroupCodeIds as getAssignedFacilityGroupCodeIds,
  setAssignedFacilityGroupCodeIds,
} from "reduxSlices/adminFacilitySlice"
import { MultiSelect } from "components/shared/multiSelects"
import { errorToast } from "components/shared/toast"

const UPDATE_FAILURE_MESSAGE = "Something went wrong. Unable to update facility group codes."

const facilityGroupCodesOptionsList = (facilityGroupCodes) => facilityGroupCodes.map((facilityGroupCode) => ({
  label: facilityGroupCode.name,
  value: facilityGroupCode.id,
}))

const FacilityGroupCodesSelect = ({ facilityGroupCodes }) => {
  const id = useSelector(facilityId)
  const assignedFacilityGroupCodeIds = useSelector(getAssignedFacilityGroupCodeIds)

  const dispatch = useDispatch()

  const updateFacilityGroupCodes = async (values) => {
    if (!isEqual(assignedFacilityGroupCodeIds, values)) {
      const response = await API.updateFacilityGroupCodeAssignments({ facilityId: id, facilityGroupCodeIds: values })

      if (response.ok) {
        dispatch(setAssignedFacilityGroupCodeIds(response.data))
      } else {
        errorToast(UPDATE_FAILURE_MESSAGE)
      }
    }
  }

  return (
    <MultiSelect
      onChange={updateFacilityGroupCodes}
      options={facilityGroupCodesOptionsList(facilityGroupCodes)}
      placeholder="Select facility group codes"
      style={{ minHeight: "32px" }}
      values={assignedFacilityGroupCodeIds}
    />
  )
}

FacilityGroupCodesSelect.propTypes = {
  facilityGroupCodes: types.arrayOf(types.object).isRequired,
}

export default FacilityGroupCodesSelect
