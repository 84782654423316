import React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getFormSlug, getFormTooltipDescription, getFormUsage, setFormTooltipDescription,
} from "reduxSlices/formBuilderSlice"
import * as API from "services/api"
import { USAGES_TO_EXCLUDE_FORM_TOOLTIP_DESCRIPTION } from "utils/formHelpers"
import ContentBar from "shared/lists/ContentBar"
import TooltipDescriptionInput from "./TooltipDescriptionInput"

const TITLE = "Form Description"
// eslint-disable-next-line max-len
const DESCRIPTION = `Text that would display in a tooltip when hovering over forms.
(Press "Enter" or "Return" to enter a new line)`

const FormTooltipDescription = () => {
  const dispatch = useDispatch()

  const formSlug = useSelector(getFormSlug)
  const formTooltipDescription = useSelector(getFormTooltipDescription)
  const formUsage = useSelector(getFormUsage)

  if (USAGES_TO_EXCLUDE_FORM_TOOLTIP_DESCRIPTION.includes(formUsage)) return null

  const updateTooltipDescription = async (tooltipDescription) => {
    const response = await API.updateFormTooltipDescription({ formSlug, tooltipDescription })
    if (response.ok) {
      dispatch(setFormTooltipDescription(tooltipDescription))
    } else {
      console.error("Error updating form tooltip description: ", response.errors)
    }
    return response
  }

  return (
    <ContentBar
      title={TITLE}
      description={DESCRIPTION}
      footer={(
        <TooltipDescriptionInput
          tooltipDescription={formTooltipDescription}
          updateTooltipDescription={updateTooltipDescription}
        />
      )}
    />
  )
}

export default FormTooltipDescription
