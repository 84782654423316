import { cloneDeep } from "lodash-es"
import { conditionOperators } from "Forms/FormElementTypes"

const EMPTY_CONDITION = {
  questionUuid: null,
  operator: null,
  matchValue: null,
}

const emptyCondition = () => cloneDeep(EMPTY_CONDITION)

export const buildCondition = ({ questionType, questionUuid }) => {
  const condition = emptyCondition()

  condition.questionUuid = questionUuid

  const [operator] = conditionOperators({ questionType })
  condition.operator = operator

  return condition
}

export const changeConditionOperator = ({ condition, newOperator, conditionQuestionType }) => {
  const { operator } = condition

  if (operator === newOperator) return

  condition.matchValue = null

  const operatorOptions = conditionOperators({ questionType: conditionQuestionType })

  if (operatorOptions.includes(newOperator)) {
    condition.operator = newOperator
  } else {
    const [fallbackOperator] = operatorOptions
    condition.operator = fallbackOperator
  }
}

export const changeConditionQuestionUuid = ({ condition, newQuestionUuid, newQuestionType }) => {
  const { operator, questionUuid } = condition

  if (questionUuid === newQuestionUuid) return

  condition.questionUuid = newQuestionUuid
  condition.matchValue = null

  const operatorOptions = conditionOperators({ questionType: newQuestionType })

  if (!operatorOptions.includes(operator)) {
    const [newOperator] = operatorOptions
    condition.operator = newOperator
  }
}
