import React, { forwardRef } from "react"
import clsx from "clsx"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import buttonPropTypes from "./buttonPropTypes"
import { LEFT } from "./constants"

// This button uses the icons in _sprite.html.erb

const CustomIconButton = forwardRef((
  {
    background = "currentcolor",
    className = "",
    color = "currentcolor",
    cursor = "pointer",
    disabled = false,
    form = undefined,
    icon = undefined,
    iconClassName = "h-5 w-5",
    iconPosition = LEFT,
    noTab = undefined,
    onClick = () => {},
    text = undefined,
    type = "button",
  },
  ref,
) => {
  // If is iconButton then make it a square otherwise rectangle padding
  const isIconButton = (!text && icon)
  return (
    <button
      className={clsx(
        className,
        `bg-${background}`,
        `text-${color}`,
        "button items-center",
        `cursor-${cursor}`,
        disabled && "opacity-75",
        {
          "p-2": isIconButton,
          "py-1.5 px-3": !isIconButton,
          "cursor-not-allowed": disabled,
          "flex-row-reverse": iconPosition === LEFT,
        },
      )}
      ref={ref}
      disabled={disabled}
      form={form}
      type={type}
      onClick={onClick}
      tabIndex={noTab ? "-1" : undefined}
    >
      {
        text && <span className="mx-2">{text}</span>
      }
      {
        icon && <SvgSpriteIcon iconName={icon} className={iconClassName} />
      }
    </button>
  )
})

CustomIconButton.displayName = "CustomIconButton"

CustomIconButton.propTypes = buttonPropTypes

export default CustomIconButton
