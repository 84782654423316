import React from "react"
import types from "prop-types"
import { Button } from "shared/buttons"

const AddActionButton = ({ addAction }) => (
  <Button
    onClick={addAction}
    className="text-focus border border-light"
    text="+ Add an Action"
  />
)

AddActionButton.propTypes = {
  addAction: types.func.isRequired,
}

export default AddActionButton
