import React from "react"
import { toast } from "react-toastify"
import ToastValidations from "./ToastValidations"

// Language across the app should be formatted as "Something went wrong. Unable to create user."

const ErrorToast = (message, errors) => {
  if (!errors) {
    return toast.error(<ToastValidations message={message} errors={[]} />)
  }

  const { data } = errors
  return toast.error(<ToastValidations message={message} errors={data.errors} />)
}
export default ErrorToast
