import React from "react"
import types from "prop-types"
import { PrimaryButton } from "shared/buttons"

const SaveStatusListButton = ({ saveStatusList, ...rest }) => (
  <PrimaryButton
    text="Save Statuses"
    onClick={saveStatusList}
    {...rest}
  />
)

SaveStatusListButton.propTypes = {
  saveStatusList: types.func.isRequired,
}

export default SaveStatusListButton
