import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const TrashCanIcon = ({ className = "w-8 h-8", ...rest }) => <SvgSpriteIcon iconName="trash-can" className={className} {...rest} />

TrashCanIcon.propTypes = {
  className: types.string,
}

export default TrashCanIcon
