// Based on https://designcode.io/react-hooks-handbook-useonclickoutside-hook
import { useEffect } from "react"

const useOnFocusChange = (ref, { focusInsideCallback, focusOutsideCallback }) => {
  useEffect(() => {
    const eventHandler = (event) => {
      if (!ref.current) return

      if (ref.current.contains(event.target)) {
        focusInsideCallback(event)
      } else {
        focusOutsideCallback(event)
      }
    }

    document.addEventListener("focusin", eventHandler)

    return () => {
      document.removeEventListener("focusin", eventHandler)
    };
  }, [ref, focusInsideCallback, focusOutsideCallback])
};

export default useOnFocusChange
