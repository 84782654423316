import React, { useState } from "react"
import types from "prop-types"
import { withFormsy } from "formsy-react"
import InputErrorMessage from "shared/InputErrorMessage"
import PasswordRequirements from "./PasswordRequirements"
import EyeToggle from "./EyeToggle"

const BasePasswordInputForFormsy = ({
  errorMessages = [],
  isPristine = true,
  label = "Password",
  name = "password",
  onChange,
  required = false,
  showErrorMessages = true,
  value = "",
}) => {
  const [isObfuscated, setIsObfuscated] = useState(true)
  const toggleObfuscation = () => setIsObfuscated(!isObfuscated)

  const inputId = `password-${name}`

  return (
    <>
      <label className="text-left block text-sm font-semibold mb-1" htmlFor={inputId}>
        { label } { required && "*" }
      </label>
      <div className="mb-5 relative">
        <input
          id={inputId}
          type={isObfuscated ? "password" : "text"}
          name={name}
          placeholder="Password..."
          required={required}
          value={value}
          onChange={({ target }) => onChange(target.value)}
          className="bordered-base-input !text-sm h-12 login-password-input"
        />
        <EyeToggle isObfuscated={isObfuscated} onClick={toggleObfuscation} />
        { showErrorMessages
          && <InputErrorMessage isPristine={isPristine} errorMessages={errorMessages} /> }
      </div>
    </>
  )
}

BasePasswordInputForFormsy.propTypes = {
  errorMessages: types.arrayOf(types.string),
  isPristine: types.bool,
  label: types.string,
  name: types.string,
  onChange: types.func.isRequired,
  required: types.bool,
  showErrorMessages: types.bool,
  value: types.string,
}

const FormsyUserPasswordInput = withFormsy(({
  setValue, value, showErrorMessages = true, ...rest
}) => (
  <>
    {
      !showErrorMessages && (
        <PasswordRequirements className="mb-8" value={value} />
      )
    }
    <BasePasswordInputForFormsy
      onChange={(password) => setValue(password.trimStart())}
      value={value}
      showErrorMessages={showErrorMessages}
      {...rest}
    />
  </>
))

FormsyUserPasswordInput.displayName = "FormsyUserPasswordInput"

export default FormsyUserPasswordInput
