import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { activateDepartment, deactivateDepartment } from "reduxSlices/adminFacilitySlice"
import * as API from "services/api"
import { adminFacilityDepartmentShape } from "utils/propTypeShapes"
import Switch from "shared/Switch"
import { errorToast } from "shared/toast"

const DepartmentActiveStatus = ({ department }) => {
  const { active, id } = department

  const dispatch = useDispatch()

  const activate = useCallback(async () => {
    const response = await API.activateDepartment(id)

    if (response.ok) {
      dispatch(activateDepartment({ departmentId: id }))
    } else {
      errorToast("Something went wrong. Unable to activate department.")
    }
  }, [dispatch, id])

  const deactivate = useCallback(async () => {
    const response = await API.deactivateDepartment(id)

    if (response.ok) {
      dispatch(deactivateDepartment({ departmentId: id }))
    } else {
      errorToast(`Unable to deactivate department: ${response.data.errors.join(", ")}.`)
    }
  }, [dispatch, id])

  return (
    <Switch
      checked={active}
      onChange={active ? deactivate : activate}
    />
  )
}

DepartmentActiveStatus.propTypes = {
  department: adminFacilityDepartmentShape.isRequired,
}

export default DepartmentActiveStatus
