import React from "react"
import types from "prop-types"
import { ChevronUp, ChevronDown } from "shared/icons"

const SidebarAccordionHeader = ({ accordionTitle, isExpanded, toggleAccordion }) => (
  <div className="text-sm cursor-pointer flex-center" onClickCapture={toggleAccordion}>
    <div className="font-semibold tracking-wider uppercase">{ accordionTitle }</div>
    {
      isExpanded ? (
        <ChevronDown className="h-3 w-3" />
      ) : (
        <ChevronUp className="h-3 w-3" />
      )
    }
  </div>
)

SidebarAccordionHeader.propTypes = {
  accordionTitle: types.string.isRequired,
  isExpanded: types.bool.isRequired,
  toggleAccordion: types.func.isRequired,
}

export default SidebarAccordionHeader
