import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { SidebarVisibilityControl } from "shared/Sidebar"
import ContentHeader from "shared/ContentHeader"
import OptionsSidebar from "./OptionsSidebar"

const AuditsHeader = ({ hiddenSidebar, toggleSidebar }) => (
  <>
    <OptionsSidebar
      hiddenSidebar={hiddenSidebar}
    />
    <ContentHeader
      className={clsx(!hiddenSidebar && "ml-80")}
      leftElements={(
        <SidebarVisibilityControl
          className="hidden lg:flex"
          hiddenSidebar={hiddenSidebar}
          toggleSidebar={toggleSidebar}
        />
      )}
      heading={
        <h1 className="header-title">Audit Trail</h1>
      }
    />
  </>
)

AuditsHeader.propTypes = {
  hiddenSidebar: types.bool.isRequired,
  toggleSidebar: types.func.isRequired,
}

export default AuditsHeader
