/* eslint-disable react/prop-types */
import React from "react"
import Options from "shared/Options"

const doesMatchSearch = (search, label) => label.toLowerCase().includes(search.toLowerCase())

// eslint-disable-next-line react/display-name
const GroupAndUserAndDepartmentDropdown = (users, groups, departments) => (props) => {
  const {
    state: { search },
    methods: { addItem },
  } = props

  const filteredUsers = users.filter(({ label }) => doesMatchSearch(search, label))
  const filteredGroups = groups.filter(({ label }) => doesMatchSearch(search, label))
  const filteredDepartments = departments.filter(({ label }) => doesMatchSearch(search, label))

  return (
    <>
      <Options
        title="Groups"
        selectOption={addItem}
        options={filteredGroups}
        search={search}
      />
      <Options
        title="Users"
        selectOption={addItem}
        options={filteredUsers}
        search={search}
      />
      <Options
        title="Departments"
        selectOption={addItem}
        options={filteredDepartments}
        search={search}
      />
    </>
  )
}

export default GroupAndUserAndDepartmentDropdown
