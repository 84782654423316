import React from "react"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import CreateFacilityButtons from "./CreateFacilityButtons"

const EmptyFacilities = () => (
  <div className="flex flex-col items-center pt-4">
    <SvgSpriteIcon iconName="empty-facilities" className="h-10 w-10 mb-4" />
    <div className="font-medium text-sm">
      You do not have any facilities yet
    </div>
    <CreateFacilityButtons />
  </div>
)

export default EmptyFacilities
