import React from "react"
import { useSolutionLevelReportExportContext } from "contexts/SolutionLevelReportExportContext"
import * as API from "services/api"
import { SmallWrap } from "shared/wraps"
import { DataCollectorContainer, DataCollectorStep } from "shared/dataCollector"
import { errorToast } from "shared/toast"
import NewSolutionLevelReportExportFormActions from "./NewSolutionLevelReportExportFormActions"
import NewSolutionLevelReportExportFormReportableFieldDateAnswerFilters from "./NewSolutionLevelReportExportFormReportableFieldDateAnswerFilters"
import NewSolutionLevelReportExportFormReportableFieldLocationAnswerFilters from "./NewSolutionLevelReportExportFormReportableFieldLocationAnswerFilters"
import NewSolutionLevelReportExportSubmissionFilters from "./NewSolutionLevelReportExportSubmissionFilters"
import NewSolutionLevelReportExportFileTypeSelect from "./NewSolutionLevelReportExportFileTypeSelect"

const CREATE_REPORT_EXPORT_ERROR_MESSAGE = "Something went wrong.  Unable to initiate export."

const NewSolutionLevelReportExportForm = () => {
  const {
    earliestSubmissionDate,
    latestSubmissionDate,
    reportableFieldDateAnswerFilters,
    reportableFieldLocationAnswerFilters,
    reportExportFileType,
    setReportExport,
    solutionLevelReport,
  } = useSolutionLevelReportExportContext()

  const {
    id: solutionLevelReportId,
    name: solutionLeveLReportName,
  } = solutionLevelReport

  const renderDataCollectorHeaderContent = () => (
    <span className="font-medium text-xl">{solutionLeveLReportName}</span>
  )

  const exportSolutionLevelReport = async () => {
    const response = await API.createSolutionLevelReportReportExport({
      earliestDate: earliestSubmissionDate,
      latestDate: latestSubmissionDate,
      reportableFieldDateAnswerFilters,
      reportableFieldLocationAnswerFilters,
      reportFileType: reportExportFileType,
      reportId: solutionLevelReportId,
    })

    if (response.ok) {
      setReportExport(response.data)
    } else {
      errorToast(CREATE_REPORT_EXPORT_ERROR_MESSAGE)
    }
  }

  return (
    <SmallWrap>
      <DataCollectorContainer renderHeaderContent={renderDataCollectorHeaderContent}>
        <div className="mb-6">
          <DataCollectorStep stepNumber={1} heading="Filter by the Date the Submission was Received">
            <NewSolutionLevelReportExportSubmissionFilters />
          </DataCollectorStep>
          <DataCollectorStep stepNumber={2} heading="Filter by the Answer to a Reportable Field Date Question">
            <NewSolutionLevelReportExportFormReportableFieldDateAnswerFilters />
          </DataCollectorStep>
          <DataCollectorStep stepNumber={3} heading="Filter by the Answer to a Reportable Field Location Question">
            <NewSolutionLevelReportExportFormReportableFieldLocationAnswerFilters />
          </DataCollectorStep>
          <DataCollectorStep stepNumber={4} heading="Select File Type">
            <NewSolutionLevelReportExportFileTypeSelect />
          </DataCollectorStep>
        </div>
        <NewSolutionLevelReportExportFormActions exportSolutionLevelReport={exportSolutionLevelReport} />
      </DataCollectorContainer>
    </SmallWrap>
  )
}

export default NewSolutionLevelReportExportForm
