import React from "react"
import types from "prop-types"
import QuestionCondition from "views/FormBuilder/QuestionFields/QuestionCondition"
import QuestionSidebarSection from "./QuestionSidebarSection"

const QuestionBranchingSection = ({ isFocused, questionIndex, sectionIndex }) => (
  <QuestionSidebarSection hide={!isFocused} title="Question Branching" headerClassName="py-2.5 text-dark">
    <>
      <p className="mb-4">
        Control whether this question is shown to someone filling out the form.
      </p>
      <QuestionCondition
        sectionIndex={sectionIndex}
        questionIndex={questionIndex}
      />
    </>
  </QuestionSidebarSection>
)

QuestionBranchingSection.propTypes = {
  isFocused: types.bool.isRequired,
  questionIndex: types.number.isRequired,
  sectionIndex: types.number.isRequired,
}

export default QuestionBranchingSection
