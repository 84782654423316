import React from "react"
import types from "prop-types"
import clsx from "clsx"

const SmallWrap = ({ className = "", children = null }) => (
  <div
    className={clsx(
      "small-wrap",
      className,
    )}
  >
    {children}
  </div>
)

SmallWrap.propTypes = {
  children: types.node,
  className: types.string,
}

export default SmallWrap
