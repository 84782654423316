import React from "react"
import ExpandMenu, { ExpandMenuItem } from "shared/ExpandMenu"
import { EllipsisIcon } from "shared/icons"
import { useSurveyConfigurationContext } from "contexts/SurveyConfigurationContext"
import { surveyConfigurationPath } from "utils/routeHelpers"

const goToSurveyConfigurationPath = (surveyConfigurationId) => () => {
  window.location.pathname = surveyConfigurationPath(surveyConfigurationId)
}

const SurveyConfigurationTileMenu = () => {
  const { surveyConfiguration } = useSurveyConfigurationContext()
  const { id: surveyConfigurationId } = surveyConfiguration

  return (
    <ExpandMenu expandMenuComponent={<EllipsisIcon />}>
      <ExpandMenuItem onClick={goToSurveyConfigurationPath(surveyConfigurationId)}>
        <span>Open</span>
      </ExpandMenuItem>
    </ExpandMenu>
  )
}

export default SurveyConfigurationTileMenu
