import React from "react"
import { useDragLayer } from "react-dnd"
import { ProfileIcon } from "shared/icons"
import UserInfo from "shared/UserInfo"
import { DraggableItemTypes } from "./DraggableItemTypes"

const layerStyles = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 100,
  left: 0,
  top: 0,
  width: "100vw",
  height: "100vh",
}

function getItemStyles(initialOffset, currentOffset) {
  if (!initialOffset || !currentOffset) {
    return {
      display: "none",
    }
  }

  const { x, y } = currentOffset

  const transform = `translate(${x}px, ${y}px)`
  return {
    transform,
    WebkitTransform: transform,
  }
}

const DepartmentMembersDragLayer = () => {
  const {
    currentOffset,
    initialOffset,
    isDragging,
    item,
    itemType,
  } = useDragLayer((monitor) => ({
    currentOffset: monitor.getSourceClientOffset(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    isDragging: monitor.isDragging(),
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
  }))

  const renderItem = () => {
    switch (itemType) {
    case DraggableItemTypes.MEMBER_PROFILE_ICON:
      return <ProfileIcon user={item.user} />
    case DraggableItemTypes.USER_PROFILE_ICON:
      return (
        <span className="flex items-center max-w-max px-2 py-1 rounded bg-white border border-light">
          <UserInfo user={item.user} className="mr-2" />
        </span>
      )
    default:
      return null
    }
  }

  if (!isDragging) return null

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        {renderItem()}
      </div>
    </div>
  )
}

export default DepartmentMembersDragLayer
