import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = []

export const facilitiesSlice = createSlice({
  name: "facilities",
  initialState: INITIAL_STATE,
  reducers: {
    setFacilities: (state, action) => {
      Object.assign(state, action.payload)
    },
  },
})

export const {
  setFacilities,
} = facilitiesSlice.actions

export const getDepartmentFacilityMapping = (state) => state.facilities.reduce((mapping, facility) => {
  facility.departments.forEach((department) => {
    mapping[department.id] = facility.id
  })

  return mapping
}, {})

export const getFacilities = (state) => state.facilities

export const getFacilityAndDepartmentFromDepartmentId = (departmentId) => (state) => {
  let facilityAndDepartment = []

  if (departmentId === null || departmentId === undefined) return facilityAndDepartment

  for (let i = 0; i < state.facilities.length; i += 1) {
    if (facilityAndDepartment.length > 0) break

    for (let k = 0; k < state.facilities[i].departments.length; k += 1) {
      if (facilityAndDepartment.length > 0) break

      const department = state.facilities[i].departments[k]

      if (department.id === departmentId) {
        facilityAndDepartment = [state.facilities[i], department]
      }
    }
  }

  return facilityAndDepartment
}

export default facilitiesSlice.reducer
