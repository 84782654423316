import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { CustomHeaderAccordion, useAccordion } from "shared/accordions"
import { SidebarAccordionHeader } from "shared/Sidebar"

const SidebarCollapsibleContent = ({
  title,
  initiallyExpanded = true,
  headerClassName = "",
  children,
}) => {
  const [isExpanded, toggleAccordion] = useAccordion(initiallyExpanded)

  return (
    <CustomHeaderAccordion
      isExpanded={isExpanded}
      header={(
        <div className={clsx("p-4 sidebar-inner-shadow", headerClassName)}>
          <SidebarAccordionHeader
            headerClassName={headerClassName}
            accordionTitle={title}
            isExpanded={isExpanded}
            toggleAccordion={toggleAccordion}
          />
        </div>
      )}
      content={(
        <div className="p-5 w-full">
          { children }
        </div>
      )}
    />
  )
}

SidebarCollapsibleContent.propTypes = {
  title: types.string.isRequired,
  headerClassName: types.string,
  children: types.node.isRequired,
  initiallyExpanded: types.bool,
}

export default SidebarCollapsibleContent
