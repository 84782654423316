import {
  DATE_TYPE as FORM_DATE_TYPE,
  NUMBER_TYPE as FORM_NUMBER_TYPE,
  MULTI_SELECT_TYPE as FORM_MULTI_SELECT_TYPE,
  LOCATION_TYPE as FORM_LOCATION_TYPE,
} from "views/Forms/FormElementTypes"

import ArrayDataElement from "./ArrayDataElement"
import DateDataElement from "./DateDataElement"
import DueDateDataElement from "./DueDateDataElement"
import FlaggedDataElement from "./FlaggedDataElement"
import FormDataElement from "./FormDataElement"
import LocationDataElement from "./LocationDataElement"
import LongTextDataElement from "./LongTextDataElement"
import NumberDataElement from "./NumberDataElement"
import StatusDataElement from "./StatusDataElement"
import TextDataElement from "./TextDataElement"
import TimeDataElement from "./TimeDataElement"
import UsersDataElement from "./UsersDataElement"

import {
  sortByArray,
  sortByBoolean,
  sortByDateTime,
  sortByText,
  sortByFormName,
  sortByNumber,
  sortByAssigneeCount,
  sortByMemberCount,
} from "./tableSortingHelper"

export const ARRAY_TYPE = "array"
export const ASSIGNEES_TYPE = "assignees"
export const DATE_TYPE = "date"
export const DUE_DATE_TYPE = "dueDate"
export const FLAGGED_TYPE = "flagged"
export const FORM_TYPE = "form"
export const LOCATION_TYPE = "location"
export const LONG_TEXT_TYPE = "longText"
export const MEMBERS_TYPE = "members"
export const NUMBER_TYPE = "number"
export const STATUS_TYPE = "status"
export const TEXT_TYPE = "text"
export const TIME_TYPE = "time"

export const tableDataElementTypeForQuestionType = (questionType) => {
  switch (questionType) {
  case FORM_DATE_TYPE:
    return DATE_TYPE
  case FORM_NUMBER_TYPE:
    return NUMBER_TYPE
  case FORM_MULTI_SELECT_TYPE:
    return ARRAY_TYPE
  case FORM_LOCATION_TYPE:
    return LOCATION_TYPE
  default:
    return TEXT_TYPE
  }
}

const DATA_ELEMENT_TYPES = {
  [ARRAY_TYPE]: {
    renderComponent: ArrayDataElement,
    sortingMethod: sortByArray,
  },
  [ASSIGNEES_TYPE]: {
    renderComponent: UsersDataElement,
    sortingMethod: sortByAssigneeCount,
  },
  [FLAGGED_TYPE]: {
    renderComponent: FlaggedDataElement,
    sortingMethod: sortByBoolean,
  },
  [DATE_TYPE]: {
    renderComponent: DateDataElement,
    sortingMethod: sortByDateTime,
  },
  [DUE_DATE_TYPE]: {
    renderComponent: DueDateDataElement,
    sortingMethod: sortByDateTime,
  },
  [FORM_TYPE]: {
    renderComponent: FormDataElement,
    sortingMethod: sortByFormName,
  },
  [LOCATION_TYPE]: {
    renderComponent: LocationDataElement,
    sortingMethod: sortByText,
  },
  [LONG_TEXT_TYPE]: {
    renderComponent: LongTextDataElement,
    sortingMethod: sortByText,
  },
  [MEMBERS_TYPE]: {
    renderComponent: UsersDataElement,
    sortingMethod: sortByMemberCount,
  },
  [NUMBER_TYPE]: {
    renderComponent: NumberDataElement,
    sortingMethod: sortByNumber,
  },
  [STATUS_TYPE]: {
    renderComponent: StatusDataElement,
    sortingMethod: sortByText,
  },
  [TEXT_TYPE]: {
    renderComponent: TextDataElement,
    sortingMethod: sortByText,
  },
  [TIME_TYPE]: {
    renderComponent: TimeDataElement,
    sortingMethod: sortByDateTime,
  },
}

const DEFAULT_DATA_ELEMENT = DATA_ELEMENT_TYPES[TEXT_TYPE]

export const getDataRenderComponent = ({ dataType }) => (
  DATA_ELEMENT_TYPES[dataType] || DEFAULT_DATA_ELEMENT
).renderComponent

export const getDataSortingMethod = ({ dataType }) => (
  DATA_ELEMENT_TYPES[dataType] || DEFAULT_DATA_ELEMENT
).sortingMethod
