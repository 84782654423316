import React from "react"
import types from "prop-types"
import clsx from "clsx"
// import { Wrap } from "shared/wraps"
import { useSelector } from "react-redux"
import { getGroupedForms, getFormBuilderPermissions } from "reduxSlices/formIndexSlice"
import FormHeader from "./FormHeader"
import FormGroupingList from "./FormGroupingList"

const FormContent = ({ hiddenSidebar, toggleSidebar }) => {
  const formGroups = useSelector(getGroupedForms)
  const formBuilderPermissions = useSelector(getFormBuilderPermissions)

  return (
    <div className={clsx("max-w-screen-xl mx-auto", !hiddenSidebar && "pl-96")}>
      <FormHeader
        hiddenSidebar={hiddenSidebar}
        toggleSidebar={toggleSidebar}
        formBuilderPermissions={formBuilderPermissions}
      />
      <FormGroupingList formGroups={formGroups} />
    </div>
  )
}

FormContent.propTypes = {
  hiddenSidebar: types.bool.isRequired,
  toggleSidebar: types.func.isRequired,
}

export default FormContent
