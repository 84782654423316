import React from "react"
import types from "prop-types"
import { categoryShape } from "utils/propTypeShapes"
import { SearchSelectedUser } from "shared/searches"
import AccessLevelIdentifier from "./AccessLevelIdentifier"

const UserGranteeList = ({
  category, users, updateAccessGranteeLists, deleteAccessLevelGrant,
}) => {
  if (!users.length) {
    return <div className="m-5">No Group with Grants</div>
  }

  return users.map(({ id, grantee, accessLevel }) => (
    <SearchSelectedUser
      key={id}
      user={grantee}
      deselect={deleteAccessLevelGrant(id)}
    >
      <AccessLevelIdentifier
        category={category}
        grantId={id}
        updateAccessGranteeLists={updateAccessGranteeLists}
        granteeType="User"
        granteeId={grantee.id}
        accessLevel={accessLevel}
      />
    </SearchSelectedUser>
  ))
}

UserGranteeList.propTypes = {
  category: categoryShape.isRequired,
  users: types.arrayOf(types.object).isRequired,
  updateAccessGranteeLists: types.func.isRequired,
  deleteAccessLevelGrant: types.func.isRequired,
}

export default UserGranteeList
