import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const WorkflowActionAddDepartmentIcon = ({ className = "w-8 h-8" }) => (
  <SvgSpriteIcon iconName="workflow-actions-add-department" className={className} />
)

WorkflowActionAddDepartmentIcon.propTypes = {
  className: types.string,
}

export default WorkflowActionAddDepartmentIcon
