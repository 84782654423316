import React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  deselectAllSurveyDefaultColumns,
  deselectSubmissionColumn,
  orderedSubmissionColumns,
  selectAllSurveyDefaultColumns,
  selectSubmissionColumn,
  surveyColumnOptions,
} from "reduxSlices/reportBuilderSlice"
import MassSelection from "shared/MassSelection"
import ColumnOption from "../ColumnOption"

const SurveyDefaultColumnOptions = () => {
  const dispatch = useDispatch()
  const columnOptions = useSelector(surveyColumnOptions)

  const selectedSubmissionColumns = useSelector(orderedSubmissionColumns)

  const deselectAll = () => dispatch(deselectAllSurveyDefaultColumns())
  const selectAll = () => dispatch(selectAllSurveyDefaultColumns())

  const addOption = (column) => dispatch(selectSubmissionColumn(column))
  const undoMove = (column) => dispatch(deselectSubmissionColumn(column))

  return (
    <div>
      <div className="mb-1 text-xl">Survey Column Defaults</div>
      <MassSelection
        deselectAll={deselectAll}
        selectAll={selectAll}
      />
      <div className="w-full mt-4 space-y-2">
        {
          columnOptions.map((option) => (
            <ColumnOption
              key={`${option.type}-${option.id}`}
              option={option}
              selectedColumns={selectedSubmissionColumns}
              selectOption={addOption}
              deselectOption={undoMove}
            />
          ))
        }
      </div>
    </div>
  )
}

export default SurveyDefaultColumnOptions
