import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { useRemoveQueryStringFromLocation } from "hooks"
import { FormIcon } from "shared/icons"
import Tooltip from "shared/Tooltip"
import removeQueryStringFromLocation from "utils/removeQueryStringFromLocation"
import { publicFormPath } from "utils/routeHelpers"

const formListGridStyle = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(150px, calc(25% - 32px)))",
  gridAutoRows: "min-content",
  alignContent: "start",
  justifyContent: "center",
  gap: "20px",
}

const goToPublicForm = (slug) => () => {
  window.open(publicFormPath(slug, window.location.search), "_blank")
  removeQueryStringFromLocation()
}

const PublicCategoryFormList = ({ forms }) => {
  useRemoveQueryStringFromLocation()

  return (
    <div style={formListGridStyle} className="mt-6 sm:mt-10 mx-6 bg-primary-light">
      {
        forms.map((form) => (
          <Tooltip key={form.slug} text={form.tooltipDescription}>
            <div
              className={clsx(
                "relative cursor-pointer w-full h-full",
                "py-4 px-6 sm:py-6 sm:px-8",
                "inline-flex flex-col justify-start items-center",
                "public-form-icon",
              )}
              onClickCapture={goToPublicForm(form.slug)}
            >
              <FormIcon className="w-12 h-12 sm:w-16 sm:h-16 mb-3" form={form} />
              <div
                onClickCapture={goToPublicForm(form.slug)}
                className="font-medium text-center cursor-pointer"
              >
                { form.title }
              </div>
            </div>
          </Tooltip>
        ))
      }
    </div>
  )
}

PublicCategoryFormList.propTypes = {
  forms: types.arrayOf(types.object).isRequired,
}

export default PublicCategoryFormList
