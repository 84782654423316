import React from "react"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const AllSolutionGroupingsAreEmpty = () => (
  <div className="flex flex-col w-full items-center">
    <SvgSpriteIcon iconName="submissions" className="w-16 h-16 mb-4" />
    <span>There are no submissions with the selected options.</span>
  </div>
)

export default AllSolutionGroupingsAreEmpty
