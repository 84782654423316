import { createSlice } from "@reduxjs/toolkit";

const SLICE_NAME = "questionTags"

const INITIAL_STATE = {
  questionTags: [],
}

export const questionTagsSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setQuestionTags: (state, action) => {
      Object.assign(state, action.payload)
    },
  },
})

export const { setQuestionTags } = questionTagsSlice.actions

export const getQuestionTag = ({ questionTagId }) => (store) => (
  store[SLICE_NAME].questionTags.find((tag) => tag.id === questionTagId)
)

export default questionTagsSlice.reducer
