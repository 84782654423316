import React from "react"
import types from "prop-types"
import Popup from "reactjs-popup"
import { questionShape } from "utils/propTypeShapes"
import { ensureDropdownVisible } from "utils/reactSelectHelpers"
import Dropdown from "shared/multiSelects/Dropdown"
import { CustomIconButton } from "shared/buttons"
import { Select } from "shared/selects"

const questionsToOptions = (questions) => questions.map((question) => (
  { label: question.prompt, value: question.uuid }
))

const TaskDepartmentByQuestionSelector = ({ onChange, questions }) => (
  (
    <div>
      <Popup
        trigger={
          (
            <div>
              <CustomIconButton
                className="border border-light text-sm"
                icon="add-department"
                text="Add Department"
              />
            </div>
          )
        }
        position="bottom left"
      >
        <div className="h-0 invisible">
          <div className="w-96">
            <Select
              className="react-dropdown-select base-input component-border-thin rounded-md -top-11"
              dropdownGap={0}
              dropdownRenderer={Dropdown(false, () => {}, "Select question ...")}
              keepOpen
              labelField="label"
              multi={false}
              onChange={onChange}
              onDropdownOpen={ensureDropdownVisible}
              options={questionsToOptions(questions)}
              searchable
              style={{ width: "24rem" }}
            />
          </div>
        </div>
      </Popup>
    </div>
  )
)

TaskDepartmentByQuestionSelector.propTypes = {
  onChange: types.func.isRequired,
  questions: types.arrayOf(questionShape).isRequired,
}

export default TaskDepartmentByQuestionSelector
