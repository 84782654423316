/* eslint-disable camelcase */
import React from "react"
import { accessLevelDescription } from "utils/accessLevelHelpers"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"

const AccessLevelGrantRemovedAuditItemDetails = ({ audit }) => {
  const { auditedChanges, grantee } = audit
  const { access_level } = auditedChanges

  return (
    <div>
      <span>
        <AuditedUser audit={audit} /> deleted the { accessLevelDescription(access_level) } access
        level grant made by the form submission to <span className="font-semibold">{grantee.fullName}</span>.
      </span>
    </div>
  )
}

AccessLevelGrantRemovedAuditItemDetails.propTypes = {
  audit: auditShape.isRequired,
}

export default AccessLevelGrantRemovedAuditItemDetails
