import React from "react"
import { useSurveyScheduledReportExportContext } from "contexts/SurveyScheduledReportExportContext"
import { SmallWrap } from "shared/wraps"
import { DataCollectorContainer, DataCollectorStep } from "shared/dataCollector"
import ActiveStatus from "shared/ScheduledReportExport/ActiveStatus"
import FormActions from "shared/ScheduledReportExport/FormActions"
import NewSurveyScheduledReportExportFormDateAnswerFilters from "./NewSurveyScheduledReportExportFormDateAnswerFilters"
import NewSurveyScheduledReportExportFormLocationAnswerFilters from "./NewSurveyScheduledReportExportFormLocationAnswerFilters"
import NewSurveyScheduledReportExportDetailFields from "./NewSurveyScheduledReportExportDetailFields"
import NewSurveyScheduledReportExportSurveyDateFilters from "./NewSurveyScheduledReportExportSurveyDateFilters"
import NewSurveyScheduledReportExportSurveyConfigurationsFilters from "./NewSurveyScheduledReportExportSurveyConfigurationsFilters"

const NewSurveyScheduledReportExportForm = () => {
  const {
    activeStatus,
    cancelForm,
    dateAnswerQuestionUuid,
    dateAnswerPreviousRangeLength,
    surveyReport,
    recurrenceDayOfMonth,
    recurrenceDayOfWeek,
    saveScheduledReportExport,
    setActiveStatus,
    startDate,
    surveySentPreviousRangeLength,
  } = useSurveyScheduledReportExportContext()

  const {
    name: surveyReportName,
  } = surveyReport

  const renderDataCollectorHeaderContent = () => (
    <span className="font-medium text-xl">{surveyReportName}</span>
  )

  const recurrenceDataCollected = startDate || recurrenceDayOfWeek || recurrenceDayOfMonth

  const dateAnswerSelectionsValid = dateAnswerQuestionUuid ? dateAnswerPreviousRangeLength : true
  const isFormValid = startDate && surveySentPreviousRangeLength && dateAnswerSelectionsValid

  return (
    <SmallWrap>
      <DataCollectorContainer renderHeaderContent={renderDataCollectorHeaderContent}>
        <div className="mb-6">
          <DataCollectorStep stepNumber={1}>
            <NewSurveyScheduledReportExportDetailFields />
          </DataCollectorStep>

          {
            recurrenceDataCollected && (
              <>
                {startDate && (
                  <>
                    <DataCollectorStep stepNumber={2} heading="Filter by When the Survey was Sent">
                      <NewSurveyScheduledReportExportSurveyDateFilters />
                    </DataCollectorStep>

                  </>
                )}

                {surveySentPreviousRangeLength && (
                  <>
                    <DataCollectorStep stepNumber={3} heading="Filter by Survey Configuration">
                      <NewSurveyScheduledReportExportSurveyConfigurationsFilters />
                    </DataCollectorStep>

                    <DataCollectorStep stepNumber={4} heading="Filter by the Answer to a Date Question">
                      <NewSurveyScheduledReportExportFormDateAnswerFilters />
                    </DataCollectorStep>

                    <DataCollectorStep stepNumber={5} heading="Filter by the Answer to a Location Question">
                      <NewSurveyScheduledReportExportFormLocationAnswerFilters />
                    </DataCollectorStep>
                    <DataCollectorStep stepNumber={6}>
                      <ActiveStatus
                        activeStatus={activeStatus}
                        setActiveStatus={setActiveStatus}
                      />
                    </DataCollectorStep>
                  </>
                )}
              </>
            )
          }

        </div>
        <FormActions
          cancelForm={cancelForm}
          isFormValid={!!isFormValid}
          saveScheduledReportExport={saveScheduledReportExport}
        />
      </DataCollectorContainer>
    </SmallWrap>
  )
}

export default NewSurveyScheduledReportExportForm
