import React from "react"
import types from "prop-types"
import { store } from "store"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { reorderColumns } from "reduxSlices/formSubmissionTableSlice"
import SubmissionColumn from "./SubmissionColumn"

const SubmissionColumnList = ({ columns }) => {
  const changeColumns = ({ source, destination }) => store.dispatch(
    reorderColumns({
      startIndex: source.index,
      endIndex: destination.index,
    }),
  )

  return (
    <DragDropContext onDragEnd={changeColumns}>
      <Droppable droppableId="droppable">
        {
          (dropProvided) => (
            <div ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
              {
                columns.map(({ label, value }, index) => (
                  <SubmissionColumn
                    key={value}
                    label={label}
                    value={value}
                    index={index}
                    columns={columns}
                  />
                ))
              }
              { dropProvided.placeholder }
            </div>
          )
        }
      </Droppable>
    </DragDropContext>
  )
}

SubmissionColumnList.propTypes = {
  columns: types.arrayOf(types.object).isRequired,
}

export default SubmissionColumnList
