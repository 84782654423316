import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const BroomIcon = ({ className = "w-8 h-8", ...rest }) => <SvgSpriteIcon iconName="broom" className={className} {...rest} />

BroomIcon.propTypes = {
  className: types.string,
}

export default BroomIcon
