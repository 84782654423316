import React, { useRef } from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { useDispatch, useSelector } from "react-redux"
import {
  deselectAllTaskFormQuestionColumns,
  deselectTaskFormQuestionColumn,
  selectAllTaskFormQuestionColumns,
  selectTaskFormQuestionColumn,
  taskFormQuestionColumnOptions,
} from "reduxSlices/reportBuilderSlice"
import MassSelection from "shared/MassSelection"
import { DraggableColumnTypes } from "./DragAndDrop/DraggableColumnTypes"
import ColumnOption from "./ColumnOption"
import SelectableOptions from "./SelectableOptions"

const TaskFormQuestionColumnOptions = ({ taskForm }) => {
  const { id: formSlug } = taskForm
  const questionOptions = useSelector(taskFormQuestionColumnOptions(formSlug, isEqual)).map((q) => ({ ...q, formSlug }))

  const dispatch = useDispatch()
  const selectQuestion = (column) => dispatch(selectTaskFormQuestionColumn({ column, formSlug }))
  const deselectQuestion = (column) => dispatch(deselectTaskFormQuestionColumn(column))

  const deselectAll = () => dispatch(deselectAllTaskFormQuestionColumns(formSlug))
  const selectAll = () => dispatch(selectAllTaskFormQuestionColumns(formSlug))

  const ref = useRef(null)

  return (
    <SelectableOptions
      acceptableTypes={DraggableColumnTypes.TASK_QUESTION_SELECTION}
      dropFunction={deselectQuestion}
      canDropPredicate={(item) => item.formSlug === formSlug}
      ref={ref}
    >
      <div>
        <div className="mb-1 text-xl">{ taskForm.label } Questions</div>
        <MassSelection
          deselectAll={deselectAll}
          selectAll={selectAll}
        />
        <div className="w-full mt-4 space-y-2">
          {
            questionOptions.map((q) => (
              <ColumnOption
                key={`${formSlug}-${q.id}-option`}
                option={q}
                selectedColumns={taskForm.questions}
                selectOption={selectQuestion}
                deselectOption={deselectQuestion}
              />
            ))
          }
        </div>
      </div>
    </SelectableOptions>
  )
}

TaskFormQuestionColumnOptions.propTypes = {
  taskForm: types.object.isRequired,
}

export default TaskFormQuestionColumnOptions
