import React from "react";
import types from "prop-types"
import clsx from "clsx"
import { v4 as makeUuid } from "uuid"
import { withFormsy } from "formsy-react"
import ReactSelect from "react-dropdown-select"
import { ensureDropdownVisible } from "utils/reactSelectHelpers"
import InputErrorMessage from "shared/InputErrorMessage"

// https://github.com/sanusart/react-dropdown-select

export const DEFAULT_SELECT_PLACEHOLDER = "Select one ..."

const Select = ({
  border = "thin",
  className = undefined,
  clearable = false,
  contentRenderer = undefined,
  disabled = false,
  dropdownRenderer = undefined,
  errorMessages = [],
  hasBaseInputStyle = true,
  isFullHeight = false,
  isFullWidth = false,
  isPristine = true,
  itemRenderer = undefined,
  name = "",
  onBlur = () => { },
  onChange,
  onFocus = () => { },
  options = [],
  placeholder = DEFAULT_SELECT_PLACEHOLDER,
  searchable = false,
  style = { height: "42px", borderRadius: "0.375rem", width: "max-content" },
  uuid = makeUuid(),
  value = undefined,
  ...rest
}) => {
  const selectedOptions = options.filter((opt) => opt.value === value)

  const onSelectChange = ([item]) => onChange(item ? item.value : null)

  if (style) {
    const defaultStyle = ReactSelect.defaultProps.style
    style = { ...defaultStyle, ...style, borderRadius: "0.375rem" }
  }
  if (isFullWidth) {
    style = { ...style, width: "100%", borderRadius: "0.375rem" }
  }
  if (isFullHeight) {
    style = { ...style, height: "100%" }
  }

  const onOpen = () => {
    ensureDropdownVisible()
    onFocus()
  }

  const onClose = () => {
    onBlur()
  }

  return (
    <>
      <div className={clsx(
        isFullWidth && "w-full",
        "react-select z-5",
      )}
      >
        <ReactSelect
          className={clsx(
            hasBaseInputStyle && "base-input",
            className,
            hasBaseInputStyle && `component-border-${border}`,
          )}
          clearable={clearable}
          contentRenderer={contentRenderer}
          dropdownGap={0}
          dropdownPosition="auto"
          id={name || uuid}
          disabled={disabled}
          itemRenderer={itemRenderer}
          dropdownRenderer={dropdownRenderer}
          labelField="label"
          multi={false}
          onChange={onSelectChange}
          onDropdownClose={onClose}
          onDropdownOpen={onOpen}
          options={options}
          placeholder={placeholder}
          searchable={searchable}
          style={style}
          valueField="value"
          values={selectedOptions}
          {...rest}
        />
      </div>
      <InputErrorMessage isPristine={isPristine} errorMessages={errorMessages} />
    </>
  )
}

Select.propTypes = {
  border: types.oneOf(["thin", "bold", "bolder"]),
  className: types.string,
  clearable: types.bool,
  contentRenderer: types.func,
  dropdownRenderer: types.func,
  disabled: types.bool,
  errorMessages: types.arrayOf(types.string),
  isFullWidth: types.bool,
  isFullHeight: types.bool,
  isPristine: types.bool,
  itemRenderer: types.func,
  name: types.string,
  onChange: types.func.isRequired,
  onFocus: types.func,
  onBlur: types.func,
  options: types.arrayOf(
    types.shape({
      label: types.oneOfType([types.string, types.number]).isRequired,
      value: types.oneOfType([types.string, types.number]).isRequired,
    }),
  ),
  placeholder: types.string,
  searchable: types.bool,
  // eslint-disable-next-line react/forbid-prop-types
  style: types.object,
  uuid: types.string,
  hasBaseInputStyle: types.bool,
  value: types.oneOfType([types.string, types.bool, types.number]),
}

export default Select

export const FormsySelect = withFormsy(({ setValue, ...rest }) => (
  <Select onChange={setValue} {...rest} />
))

FormsySelect.displayName = "FormsySelect"
