import React, { useRef } from "react"
import { withFormsy } from "formsy-react"
import types from "prop-types"
import clsx from "clsx"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import InputErrorMessage from "shared/InputErrorMessage"

const TimeInput = ({
  className = "",
  disabled = false,
  errorMessages = [],
  hasBaseInputStyle = true,
  isPristine = true,
  name = "",
  onBlur = () => {},
  onChange,
  onFocus = () => {},
  required = false,
  uuid = undefined,
  value = "",
}) => {
  const timeInputRef = useRef(null);

  const showTimePicker = () => {
    timeInputRef.current.showPicker();
  }

  return (
    <>
      <div className={clsx("relative bg-white", className)}>
        <div className="absolute right-0 flex justify-center items-center h-full w-8 z-1">
          <SvgSpriteIcon
            className="cursor-pointer"
            iconName="clock"
            onClick={showTimePicker}
          />
        </div>
        <div className="relative w-full">
          <input
            ref={timeInputRef}
            className={clsx(
              hasBaseInputStyle && "base-input",
              !value && "time-input-empty",
              "bg-transparent w-full",
            )}
            id={uuid}
            name={name || uuid}
            type="time"
            disabled={disabled}
            required={required}
            value={value}
            placeholder="HH:MM"
            onChange={({ target }) => onChange(target.value)}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </div>
      </div>
      <InputErrorMessage isPristine={isPristine} errorMessages={errorMessages} />
    </>
  )
}

TimeInput.propTypes = {
  className: types.string,
  disabled: types.bool,
  errorMessages: types.arrayOf(types.string),
  hasBaseInputStyle: types.bool,
  isPristine: types.bool,
  name: types.string,
  onBlur: types.func,
  onChange: types.func.isRequired,
  onFocus: types.func,
  required: types.bool,
  uuid: types.string,
  value: types.string,
}

export const FormsyTimeInput = withFormsy(({ setValue, ...rest }) => (
  <TimeInput onChange={setValue} {...rest} />
))

FormsyTimeInput.displayName = "FormsyTimeInput"

export default TimeInput
