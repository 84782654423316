import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const CalendarIcon = ({ className = "" }) => <SvgSpriteIcon iconName="calendar" className={className} />

CalendarIcon.propTypes = {
  className: types.string,
}

export default CalendarIcon
