import React, { useRef } from "react"
import { isEqual } from "lodash-es"
import { useDispatch, useSelector } from "react-redux"
import { moveTaskForm, selectedTaskForms } from "reduxSlices/reportBuilderSlice"
import { DraggableColumnTypes } from "./DragAndDrop/DraggableColumnTypes"
import SelectedColumn from "./SelectedColumn"
import useScrollToAdded from "./DragAndDrop/useScrollToAdded"
import SelectionDropZone from "./DragAndDrop/SelectionDropZone"

const TaskFormSelections = () => {
  const dispatch = useDispatch()
  const taskForms = useSelector(selectedTaskForms, isEqual)

  const ref = useRef(null)

  useScrollToAdded(ref, taskForms)

  const moveColumn = (column, toIndex) => (
    dispatch(moveTaskForm({ column, toIndex }))
  )

  return (
    <SelectionDropZone
      acceptableTypes={DraggableColumnTypes.TASK_FORM_SELECTION}
      ref={ref}
    >
      {
        taskForms.map((column, index) => (
          <SelectedColumn
            key={`${column.type}-${column.id}`}
            column={{ ...column, index }}
            moveColumn={moveColumn}
          />
        ))
      }
    </SelectionDropZone>
  )
}

export default TaskFormSelections
