import { v4 as makeUuid } from "uuid"

class RejectedFile {
  static errorMessages(rejectedFileData) {
    const { errors } = rejectedFileData

    return errors.map((errorData) => {
      const { code } = errorData

      let message

      switch (code) {
      case "file-too-large":
        message = "exceeds size limit"
        break
      case "file-invalid-type":
        message = "invalid file type"
        break
      default:
        message = code
      }

      return message
    })
  }

  // rejectedFileData is expended to be a FileRejection from react-dropzone
  // https://github.com/react-dropzone/react-dropzone/blob/72c6c348660a0a904d174e10108d5c4d44d1812d/typings/react-dropzone.d.ts#L26-L29
  //
  // UUID is only generated to provide a key when mapping rejected files in React.
  // UUID is not related to the file or upload in any way.
  constructor(rejectedFileData) {
    this.errorMessages = RejectedFile.errorMessages(rejectedFileData)
    this.filename = rejectedFileData.file.name
    this.uuid = makeUuid()
  }
}

export default RejectedFile
