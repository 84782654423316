import React from "react"
import types from "prop-types"
import CreateFormModal from "shared/modals/CreateFormModal"
import { SidebarVisibilityControl } from "shared/Sidebar"

const FormHeader = ({ hiddenSidebar, toggleSidebar, formBuilderPermissions = {} }) => (
  <div className="flex items-center justify-between">
    <SidebarVisibilityControl hiddenSidebar={hiddenSidebar} toggleSidebar={toggleSidebar} />
    <h1 className="header-title">Forms</h1>
    <CreateFormModal formBuilderPermissions={formBuilderPermissions} />
  </div>
)

FormHeader.propTypes = {
  hiddenSidebar: types.bool.isRequired,
  toggleSidebar: types.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formBuilderPermissions: types.object,
}

export default FormHeader
