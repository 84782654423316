import React from "react"
import types from "prop-types"
import { withFormsy } from "formsy-react"
import { Select } from "shared/selects"
import InputErrorMessage from "shared/InputErrorMessage"

const DEFAULT_OPTION_CONVERTER = (options) => options.map((opt) => ({ label: opt, value: opt }))

const ListSelect = ({
  disabled = false,
  errorMessages = [],
  isPristine = true,
  options = [],
  optionsFromList = DEFAULT_OPTION_CONVERTER,
  value = "",
  ...rest
}) => (
  <>
    <Select
      disabled={disabled}
      errorMessages={errorMessages}
      isPristine={isPristine}
      options={optionsFromList(options)}
      value={value}
      {...rest}
    />
    <InputErrorMessage isPristine={isPristine} errorMessages={errorMessages} />
  </>
)

ListSelect.propTypes = {
  errorMessages: types.arrayOf(types.string),
  isPristine: types.bool,
  onChange: types.func.isRequired,
  optionsFromList: types.func,
  options: types.oneOfType([
    types.arrayOf(types.string),
    types.arrayOf(types.number),
    types.arrayOf(types.object),
  ]),
  value: types.oneOfType([types.string, types.number]),
  disabled: types.bool,
}

export const FormsyListSelect = withFormsy(({ setValue, ...rest }) => (
  <ListSelect onChange={setValue} {...rest} />
))

FormsyListSelect.displayName = "FormsyListSelect"

export default ListSelect
