import React from "react"
import types from "prop-types"
import QuestionFlagCondition from "views/FormBuilder/QuestionFields/QuestionFlagCondition"
import QuestionSidebarSection from "./QuestionSidebarSection"

const SECTION_DESCRIPTION = "Apply a question condition which will be used to flag the submission."

const QuestionFlagConditionSection = ({ isFocused, questionIndex, sectionIndex }) => (
  <QuestionSidebarSection
    description={SECTION_DESCRIPTION}
    hide={!isFocused}
    title="Flag Condition"
    headerClassName="py-2.5 text-dark"
  >
    <QuestionFlagCondition questionIndex={questionIndex} sectionIndex={sectionIndex} />
  </QuestionSidebarSection>
)

QuestionFlagConditionSection.propTypes = {
  isFocused: types.bool.isRequired,
  questionIndex: types.number.isRequired,
  sectionIndex: types.number.isRequired,
}

export default QuestionFlagConditionSection
