import React from "react"
import { CONDITION_DETAILS, STATUS_CONDITION_PROPERTY } from "utils/workflowHelpers"
import { snakeToCapitalCase } from "utils/stringHelpers"
import { workflowConditionShape } from "utils/propTypeShapes"
import types from "prop-types"
import { Select } from "shared/selects"
import FormsyValidation from "shared/FormsyValidation"

const DefineStatusOperator = ({ condition, conditionIndex, changeConditionOperator }) => {
  const operators = CONDITION_DETAILS[STATUS_CONDITION_PROPERTY]?.operators
  const options = operators.map((op) => ({
    value: op,
    label: snakeToCapitalCase(op),
  }))

  return (
    <span className="relative">
      <Select
        backspaceDelete={false}
        style={{ height: "32px" }}
        onChange={changeConditionOperator}
        options={options}
        placeholder="Select operator... *"
        required
        value={condition.operator}
      />
      <FormsyValidation
        name={`conditions[${conditionIndex}].operator`}
        value={condition?.operator ?? ""}
        validations={{ isNotBlankString: true }}
        validationErrors={{ isDefaultRequiredValue: "required" }}
      />
    </span>
  )
}

DefineStatusOperator.propTypes = {
  condition: workflowConditionShape.isRequired,
  changeConditionOperator: types.func.isRequired,
  conditionIndex: types.number.isRequired,
}

export default DefineStatusOperator
