import React from "react"
import { useHistoricalAccessToolContext } from "contexts/HistoricalAccessToolContext"
import { DataCollectorStep } from "shared/dataCollector"
import HistoricalAccessToolAccessLevel from "shared/HistoricalAccessTool/HistoricalAccessToolAccessLevel"
import HistoricalAccessToolFormsForSubmitting from "shared/HistoricalAccessTool/HistoricalAccessToolFormsForSubmitting"
import HistoricalAccessToolLocationFilter from "shared/HistoricalAccessTool/HistoricalAccessToolLocationFilter"
import HistoricalAccessToolSolution from "shared/HistoricalAccessTool/HistoricalAccessToolSolution"
import HistoricalAccessToolSubmissionDateFilter from "shared/HistoricalAccessTool/HistoricalAccessToolSubmissionDateFilter"
import HistoricalAccessToolTaskForms from "shared/HistoricalAccessTool/HistoricalAccessToolTaskForms"
import HistoricalAccessToolAddAccessPreview from "./HistoricalAccessToolAddAccessPreview"

const HistoricalAccessToolAddSteps = () => {
  const { categoryIsSelected } = useHistoricalAccessToolContext()

  return (
    <>
      <div className="mb-6">
        <DataCollectorStep stepNumber={2} heading="Solution">
          <HistoricalAccessToolSolution />
        </DataCollectorStep>
        {
          categoryIsSelected && (
            <>
              <DataCollectorStep stepNumber={3} heading="Forms">
                <p className="mb-2 required-prompt">Select forms:</p>
                <p className="text-sm text-gray mb-8">
                  The user will only gain access to submissions from the selected forms.
                </p>
                <HistoricalAccessToolFormsForSubmitting />
              </DataCollectorStep>
              <DataCollectorStep stepNumber={4} heading="Tasks">
                <p className="mb-2">Select tasks:</p>
                <p className="text-sm text-gray mb-8">
                  The user will be made a member of tasks where a selected task form is attached.
                  This will only apply when the user gains new access to a submission.
                </p>
                <HistoricalAccessToolTaskForms />
              </DataCollectorStep>
              <DataCollectorStep stepNumber={5} heading="Filter by the Date the Submission was Received">
                <HistoricalAccessToolSubmissionDateFilter />
              </DataCollectorStep>
              <DataCollectorStep stepNumber={6} heading="Filter by the Answer to a Reportable Field Location Question">
                <HistoricalAccessToolLocationFilter />
              </DataCollectorStep>
              <DataCollectorStep stepNumber={7} heading="Access Level">
                <p className="mb-2 required-prompt">Select access level:</p>
                <p className="text-sm text-gray mb-8">
                  The user will gain submission access at the selected level.
                  If a user already has access, the existing access level will not change.
                </p>
                <HistoricalAccessToolAccessLevel />
              </DataCollectorStep>
            </>
          )
        }
      </div>
      <HistoricalAccessToolAddAccessPreview />
    </>
  )
}

export default HistoricalAccessToolAddSteps
