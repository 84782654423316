import React from "react"
import types from "prop-types"

const ActionDetails = ({ children }) => (
  <div className="workflow-white-label p-2 flex-col items-start">
    {children}
  </div>
)

ActionDetails.propTypes = {
  children: types.node.isRequired,
}

export default ActionDetails
