import React from "react"
import types from "prop-types"
import pluralize from "pluralize"
import SingleUserIcon from "components/shared/icons/SingleUserIcon"
import TwoPersonGroupIcon from "components/shared/icons/TwoPersonGroupIcon"
import Tooltip, { TOP_CENTER } from "components/shared/Tooltip"

const userTooltipText = "Associated with this workflow as an individual."

const UserAndGroupAssociations = ({ workflow }) => {
  const groupNames = workflow.associatedGroupsForUser.map((group) => group.name)
  const groupTooltipText = () => {
    if (groupNames.length === 0) {
      return ""
    }

    const groupLabel = pluralize("group", groupNames.length)

    return `Associated with this workflow through ${groupLabel}: ${groupNames.join(", ")}.`
  }

  return (
    <div className="flex">
      {
        workflow.directlyAssociatedWithUser
        && (
          <Tooltip position={TOP_CENTER} text={userTooltipText}>
            <SingleUserIcon className="w-6 h-6" />
          </Tooltip>
        )
      }
      {
        workflow.associatedGroupsForUser.length > 0
        && (
          <Tooltip position={TOP_CENTER} text={groupTooltipText()}>
            <TwoPersonGroupIcon className="w-6 h-6 ml-2" />
          </Tooltip>
        )
      }
    </div>

  )
}

UserAndGroupAssociations.propTypes = {
  workflow: types.object.isRequired,
}

export default UserAndGroupAssociations
