import React from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { Droppable } from "react-beautiful-dnd"
import { useSelector } from "react-redux"
import { getFormSectionQuestionUuids, getFormSectionUuid } from "reduxSlices/formBuilderSlice"
import { Question } from "FormBuilder/QuestionFields"
import FormsyValidation from "shared/FormsyValidation"

// Ensures that at least one question in the section
// is registered with .
const SectionQuestionsLengthValidation = ({ sectionIndex, value }) => (
  <FormsyValidation
    name={`sections[${sectionIndex}].questionsLength`}
    value={value}
    validations={{
      requiresOneQuestion(_model, val) {
        if (val >= 1) return true
        return "Section must have at least one question"
      },
    }}
  />
)

SectionQuestionsLengthValidation.propTypes = {
  sectionIndex: types.number.isRequired,
  value: types.number.isRequired,
}

const SectionQuestions = ({ sectionIndex }) => {
  const questionUuids = useSelector(getFormSectionQuestionUuids(sectionIndex), isEqual)
  const sectionUuid = useSelector(getFormSectionUuid(sectionIndex))

  return (
    <>
      <SectionQuestionsLengthValidation sectionIndex={sectionIndex} value={questionUuids.length} />
      <Droppable droppableId={sectionUuid} type="QUESTION">
        {
          (dropProvided) => (
            <div
              ref={dropProvided.innerRef}
              {...dropProvided.droppableProps}
            >
              {
                questionUuids.map((questionUuid, questionIndex) => (
                  <Question
                    key={questionUuid}
                    questionIndex={questionIndex}
                    sectionIndex={sectionIndex}
                  />
                ))
              }
              {dropProvided.placeholder}
            </div>
          )
        }
      </Droppable>
    </>
  )
}

SectionQuestions.propTypes = {
  sectionIndex: types.number.isRequired,
}

export default SectionQuestions
