import React from "react";
import types from "prop-types"
import clsx from "clsx"

// A similarly styled renderer of Items in a selector to the original used as the default
//  by the ReactSelect imported library which our Select.jsx shared component utilizes.
const InactiveOptionsItemRenderer = ({
  state,
  methods,
  item,
  itemIndex,
}) => {
  let textStyle
  let bgStyle
  let interactStyle

  const isActive = (item.active ?? true)

  if (methods.isSelected(item)) {
    textStyle = "text-[#fff]"
    bgStyle = isActive ? "bg-reactSelectItemDefault" : "bg-reactSelectItemDefault/70"
  } else {
    textStyle = isActive ? "" : "text-gray-200"
    interactStyle = "hover:bg-reactSelectItemDefault/10 focus:bg-reactSelectItemDefault/10"
    bgStyle = (state.cursor === itemIndex) ? "bg-reactSelectItemDefault/10" : ""
  }

  return (
    <span
      className={clsx("py-[5px] px-[10px]", textStyle, bgStyle, interactStyle)}
      title={isActive ? undefined : `${item.label} is inactive`}
      onClickCapture={() => methods.addItem(item)}
    >
      { item.label }
    </span>
  )
}

InactiveOptionsItemRenderer.propTypes = {
  state: types.object.isRequired,
  methods: types.arrayOf(types.func).isRequired,
  item: types.object.isRequired,
  itemIndex: types.number.isRequired,
}

export default InactiveOptionsItemRenderer
