import React from "react";
import types from "prop-types"
import { withFormsy } from "formsy-react"
import { MultiSelect } from "shared/multiSelects"

const optionsFromList = (options) => options.map((opt) => ({ label: opt, value: opt }))

const ListMultiSelect = ({
  values = [],
  options = [],
  onChange,
  disabled = false,
  ...rest
}) => (
  <MultiSelect
    options={optionsFromList(options)}
    values={values}
    onChange={onChange}
    disabled={disabled}
    {...rest}
  />
)

ListMultiSelect.propTypes = {
  onChange: types.func.isRequired,
  options: types.oneOfType([
    types.arrayOf(types.string),
    types.arrayOf(types.number),
  ]),
  values: types.oneOfType([
    types.arrayOf(types.string),
    types.arrayOf(types.number),
  ]),
  disabled: types.bool,
}

export const FormsyListMultiSelect = withFormsy(({ setValue, ...rest }) => (
  <ListMultiSelect onChange={setValue} {...rest} />
))

FormsyListMultiSelect.displayName = "FormsyListMultiSelect"

export default ListMultiSelect
