import React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getFormPublicSubmissionStatus,
  getFormSlug,
  getFormUsage,
  setFormPublicSubmissionStatus,
} from "reduxSlices/formBuilderSlice"
import * as API from "services/api"
import { USAGES_TO_EXCLUDE_FORM_SUBMISSION_STATUS } from "utils/formHelpers"
import ContentBar from "components/shared/lists/ContentBar"
import Switch from "components/shared/Switch"
import { errorToast } from "components/shared/toast"

const TITLE = "Show Status on Form Submission Success Page"
// eslint-disable-next-line max-len
const DESCRIPTION = "When active, a user will see their submission's status and instructions to get an email link back to the page upon creating a form submission."

const FormPublicSubmissionStatusSetting = () => {
  const dispatch = useDispatch()

  const formPublicSubmissionStatus = useSelector(getFormPublicSubmissionStatus)
  const formSlug = useSelector(getFormSlug)
  const formUsage = useSelector(getFormUsage)

  if (USAGES_TO_EXCLUDE_FORM_SUBMISSION_STATUS.includes(formUsage)) return null

  const updatePublicSubmissionStatus = async (publicSubmissionStatus) => {
    const response = await API.updateFormPublicSubmissionStatus({ formSlug, publicSubmissionStatus })
    if (response.ok) {
      dispatch(setFormPublicSubmissionStatus(publicSubmissionStatus))
    } else {
      errorToast("Something went wrong. Unable to change status.")
    }
  }

  return (
    <ContentBar
      title={TITLE}
      description={DESCRIPTION}
    >
      <Switch
        checked={formPublicSubmissionStatus}
        onChange={updatePublicSubmissionStatus}
      />
    </ContentBar>
  )
}

export default FormPublicSubmissionStatusSetting
