import React, { useState } from "react"
import Formsy from "formsy-react"
import { store } from "store"
import * as API from "services/api"
import { Button, PrimaryButton } from "shared/buttons"
import {
  getFormBuilder, hideWorkflowForm, setSelectedWorkflow, setWorkflows,
} from "reduxSlices/formBuilderSlice"
import { useSubmit } from "hooks"
import { useSelector } from "react-redux"
import { DataCollectorStep } from "shared/dataCollector"
import * as WorkflowFields from "./WorkflowFields"

const EditWorkflowForm = () => {
  const { form, selectedWorkflow, workflows } = useSelector(getFormBuilder)
  const { isSubform, slug: formSlug } = form

  const [workflow, setWorkflow] = useState(selectedWorkflow)
  const { canSubmit, enableSubmit, disableSubmit } = useSubmit()

  const hideForm = () => {
    store.dispatch(hideWorkflowForm())
    store.dispatch(setSelectedWorkflow(null))
  }

  const updateWorkflow = async () => {
    const newWorkflow = { ...workflow }
    delete newWorkflow.updatedAt
    const response = await API.updateWorkflow({ formSlug, workflowId: workflow.id, workflow: newWorkflow })

    if (response.ok) {
      const copyWorkflows = [...workflows]
      const index = copyWorkflows.findIndex(({ id }) => id === workflow.id)

      copyWorkflows.splice(index, 1, response.data)
      store.dispatch(setWorkflows(copyWorkflows))
    } else {
      console.error("Error updating workflow: ", response)
    }

    hideForm()
  }

  return (
    <Formsy
      onSubmit={updateWorkflow}
      onValidSubmit={hideForm}
      onValid={enableSubmit}
      onInvalid={disableSubmit}
    >
      <DataCollectorStep stepNumber={1}>
        <WorkflowFields.Name name={workflow.name} setWorkflow={setWorkflow} />
      </DataCollectorStep>

      <DataCollectorStep stepNumber={2} heading="Trigger">
        {
          isSubform ? (
            <WorkflowFields.TaskFormTrigger
              trigger={workflow.trigger}
              setWorkflow={setWorkflow}
            />
          ) : (
            <WorkflowFields.FormTrigger
              trigger={workflow.trigger}
              setWorkflow={setWorkflow}
              frequency={workflow.frequency}
            />
          )
        }
      </DataCollectorStep>

      <DataCollectorStep stepNumber={3} heading="If">
        <WorkflowFields.Conditions
          conditions={workflow.conditions}
          isSubform={isSubform}
          onetime={workflow.onetime}
          setWorkflow={setWorkflow}
        />
      </DataCollectorStep>

      <DataCollectorStep stepNumber={4} heading="Then">
        <WorkflowFields.Actions
          formSlug={formSlug}
          setWorkflow={setWorkflow}
          actions={workflow.actions}
          name={workflow.name}
        />
      </DataCollectorStep>

      <DataCollectorStep stepNumber={5}>
        <WorkflowFields.Active
          active={workflow.active}
          setWorkflow={setWorkflow}
        />
      </DataCollectorStep>

      <div className="flex justify-center pt-6 pb-4">
        <Button className="border border-light mr-2" text="Cancel" onClick={hideForm} />
        <PrimaryButton text="Save" type="submit" disabled={!canSubmit} />
      </div>
    </Formsy>
  )
}

export default EditWorkflowForm
