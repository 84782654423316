import React from "react"
import { isEqual } from "lodash-es"
import { useDispatch, useSelector } from "react-redux"
import { categories, selectCategory, selectedCategorySlug } from "reduxSlices/reportBuilderSlice"
import { CategorySelect } from "shared/selects"

const SolutionSelector = () => {
  const dispatch = useDispatch()
  const categorySlug = useSelector(selectedCategorySlug)

  return (
    <CategorySelect
      id="report-builder-category-select"
      onChange={(slug) => dispatch(selectCategory({ categorySlug: slug }))}
      options={useSelector(categories, isEqual)}
      sortBy="label"
      value={categorySlug}
    />
  )
}

export default SolutionSelector
