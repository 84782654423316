import React from "react"
import { questionShape } from "utils/propTypeShapes"
import { useDispatch } from "react-redux"
import { restoreFormQuestion } from "reduxSlices/formBuilderSlice"
import { PrimaryButton } from "shared/buttons"

const RestoreFormVersionDeletedQuestion = ({ question }) => {
  const dispatch = useDispatch()

  const restoreQuestion = () => (dispatch(restoreFormQuestion({ question })))

  return (
    <PrimaryButton className="h-1/2" onClick={restoreQuestion} text="restore" />
  )
}

RestoreFormVersionDeletedQuestion.propTypes = {
  question: questionShape.isRequired,
}

export default RestoreFormVersionDeletedQuestion
