import React from "react"
import types from "prop-types"
import { HiddenSideTabs } from "shared/tabs"
import { useSelector } from "react-redux"
import {
  currentUserIsInAdminRole as getCurrentUserIsInAdminRole,
} from "reduxSlices/sessionSlice"
import { subscribedToAddOnFeature } from "reduxSlices/addOnFeatureSubscriptionsSlice"
import { REPORT_SFTP_FEATURE_NAME } from "utils/addOnFeatureHelpers"
import FacilityGroupCodesTab, { FACILITY_GROUP_CODES_TAB_HEADING } from "./FacilityGroupCodesTab"
import GeneralTab, { GENERAL_TAB_HEADING } from "./GeneralTab"
import QuestionTagsTab, { QUESTION_TAGS_TAB_HEADING } from "./QuestionTagsTab"
import SFTPSettingsTab, { SFTP_SETTINGS_TAB_HEADING } from "./SFTPSettingsTab"

const DEFAULT_TABS = (tabProps) => [
  {
    title: GENERAL_TAB_HEADING,
    TabContent: GeneralTab,
    props: tabProps,
  },
  {
    title: QUESTION_TAGS_TAB_HEADING,
    TabContent: QuestionTagsTab,
    props: tabProps,
  },
  {
    title: FACILITY_GROUP_CODES_TAB_HEADING,
    TabContent: FacilityGroupCodesTab,
    props: tabProps,
  },
]

const SFTPTab = {
  title: SFTP_SETTINGS_TAB_HEADING,
  TabContent: SFTPSettingsTab,
}

const GeneralSettingsTabs = ({ tabProps = {} }) => {
  const currentUserIsInAdminRole = useSelector(getCurrentUserIsInAdminRole)
  const organizationSubscribedToSFTPFeature = useSelector(
    subscribedToAddOnFeature(REPORT_SFTP_FEATURE_NAME),
  )

  const showSFTPTab = () => {
    if (!organizationSubscribedToSFTPFeature) {
      return false
    }
    return currentUserIsInAdminRole
  }

  const allTabs = [...DEFAULT_TABS(tabProps)]

  if (showSFTPTab()) {
    allTabs.push(SFTPTab)
  }

  return (
    <HiddenSideTabs tabs={allTabs} />
  )
}

GeneralSettingsTabs.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tabProps: types.object,
}

export default GeneralSettingsTabs
