import React from "react"
import types from "prop-types"
import FormsyValidation from "shared/FormsyValidation"

const NoBlankOptionsValidation = ({ sectionIndex, questionIndex, value = [] }) => (
  <FormsyValidation
    name={`sections[${sectionIndex}].questions[${questionIndex}].noBlankQuestions`}
    value={value}
    validations={{
      noBlankQuestions(_model, val) {
        if (val?.every(Boolean)) return true
        return "Must have questions selected"
      },
    }}
  />
)

NoBlankOptionsValidation.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
  value: types.arrayOf(types.string),
}

export default NoBlankOptionsValidation
