import React from "react"
import types from "prop-types"
import { useSCIMResourceManagement } from "hooks"

const UserExternalId = ({ user }) => {
  const { usersManagedBySCIM } = useSCIMResourceManagement()

  if (!usersManagedBySCIM) return null

  return (
    <div className="w-full p-4 mb-2 rounded-lg bg-primary-light flex justify-between">
      <div className="font-bold">External Identifier</div>
      <div className="text-dark">{ user.externalIdentifier }</div>
    </div>
  )
}

UserExternalId.propTypes = {
  user: types.object.isRequired,
}

export default UserExternalId
