import React from "react"
import types from "prop-types"
import { taskShape } from "utils/propTypeShapes"
import TaskPanelSection from "./TaskPanelSection"
import TaskUserProfileIconList from "./TaskUserProfileIconList"

const TaskPanelAssignees = ({ className, task }) => {
  const { assigneeIds } = task

  return (
    <TaskPanelSection className={className} name="Assignees">
      {
        assigneeIds.length > 0
          ? <TaskUserProfileIconList userIds={assigneeIds} />
          : <span>No one is assigned to this task.</span>
      }
    </TaskPanelSection>
  )
}

TaskPanelAssignees.defaultProps = {
  className: "",
}

TaskPanelAssignees.propTypes = {
  className: types.string,
  task: taskShape.isRequired,
}

export default TaskPanelAssignees
