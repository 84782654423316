import React from "react"
import types from "prop-types"
import DropdownListWrapper from "./DropdownListWrapper"
import SelectableIcon from "./SelectableIcon"

const IconList = ({ selectedIcon, iconOptions, setSelectedIcon }) => {
  const isIconSelected = (icon) => icon === selectedIcon

  return (
    <DropdownListWrapper title="Icons">
      {
        iconOptions.map(({ icon }) => (
          <SelectableIcon
            key={icon}
            isSelected={isIconSelected(icon)}
            icon={icon}
            setSelectedIcon={setSelectedIcon}
          />
        ))
      }
    </DropdownListWrapper>
  )
}

IconList.propTypes = {
  selectedIcon: types.string.isRequired,
  setSelectedIcon: types.func.isRequired,
  iconOptions: types.arrayOf(types.object).isRequired,
}

export default IconList
