import React, { useCallback, useState } from "react"
import types from "prop-types"
import CSVReader from "react-csv-reader"
import { useDispatch, useSelector } from "react-redux"
import { currentUserFullName as getCurrentUserFullName } from "reduxSlices/sessionSlice"
import { getFormQuestionPrompt, setQuestionAttributeOptions, setQuestionAttributeCsv } from "reduxSlices/formBuilderSlice"
import {
  isNotBlankString, mayNotBeIncludedIn, noExcelMetacharacters, noHTML,
} from "utils/customFormsyValidations"
import { yearMonthDayDatetime } from "utils/dateHelpers"
import { useModal } from "hooks"
import { TertiaryButton, PrimaryButton } from "shared/buttons"
import Modal, { ModalHeader } from "shared/Modal"
import SelectCSVUploadOverview from "../SelectCSVUploadOverview"

const CSV_READER_PARSER_OPTIONS = {
  header: false,
  dynamicTyping: true,
  skipEmptyLines: true,
}

const SelectCSVUploadCSV = ({ questionIndex, sectionIndex }) => {
  const dispatch = useDispatch()

  const [uploadDetails, setUploadDetails] = useState()
  const { modalIsOpen, openModal, closeModal } = useModal()

  const currentUserFullName = useSelector(getCurrentUserFullName)
  const questionPrompt = useSelector(getFormQuestionPrompt({ sectionIndex, questionIndex }))

  const updateOptions = useCallback((newOptions) => dispatch(setQuestionAttributeOptions({
    sectionIndex, questionIndex, newOptions,
  })), [sectionIndex, questionIndex, dispatch])

  const updateCSVDetails = useCallback((newCsv) => dispatch(setQuestionAttributeCsv({
    sectionIndex, questionIndex, newCsv,
  })), [sectionIndex, questionIndex, dispatch])

  const storeUploadDetails = useCallback((data, fileInfo) => {
    setUploadDetails({
      csv: {
        filename: fileInfo.name,
        rows: data.length,
        uploadedBy: currentUserFullName,
        uploadedAt: yearMonthDayDatetime(new Date()),
      },
      options: data.map((row) => row[0]),
    })
  }, [currentUserFullName])

  const close = useCallback(() => {
    setUploadDetails(null)
    closeModal()
  }, [closeModal, setUploadDetails])

  const commitUpload = useCallback(() => {
    const { csv, options } = uploadDetails

    updateCSVDetails(csv)
    updateOptions(options)
    close()
  }, [close, updateCSVDetails, updateOptions, uploadDetails])

  let invalidOverview = []

  if (uploadDetails) {
    const { options } = uploadDetails

    invalidOverview = options.reduce((list, option, index) => {
      const checks = [
        isNotBlankString(null, option),
        mayNotBeIncludedIn(null, option, { denyList: options.slice(0, index), errorMessage: "Repeat option" }),
        noExcelMetacharacters(null, option),
        noHTML(null, option),
      ]

      const errorMessages = checks.filter((result) => result !== true)

      if (errorMessages.length > 0) {
        list.push({ option, errorMessages })
      }

      return list
    }, [])
  }

  const allValidOptions = invalidOverview.length === 0
  const { csv = {}, options = [] } = uploadDetails || {}
  const { filename } = csv

  return (
    <>
      <TertiaryButton
        text="Upload CSV"
        onClick={openModal}
      />
      <Modal isOpen={modalIsOpen}>
        <ModalHeader
          closeModal={close}
          heading={`Upload CSV with Options for Question: "${questionPrompt}"`}
        />
        <div className="mb-6">
          {
            uploadDetails
              ? <SelectCSVUploadOverview filename={filename} invalidOverview={invalidOverview} options={options} />
              : <CSVReader onFileLoaded={storeUploadDetails} parserOptions={CSV_READER_PARSER_OPTIONS} />
          }
        </div>
        <div className="flex">
          <TertiaryButton className="mr-2" onClick={close} text="Cancel" />
          {
            uploadDetails && allValidOptions && (
              <PrimaryButton onClick={commitUpload} text="Commit" />
            )
          }
        </div>
      </Modal>
    </>
  )
}

SelectCSVUploadCSV.propTypes = {
  questionIndex: types.number.isRequired,
  sectionIndex: types.number.isRequired,
}

export default SelectCSVUploadCSV
