import React from "react"
import { useSelector } from "react-redux"
import { SidebarCollapsibleContent } from "shared/Sidebar"
import { getOrderedColumns } from "reduxSlices/formSubmissionTableSlice"
import SubmissionColumnList from "./SubmissionColumnList"

const SubmissionColumnSelector = () => {
  const columns = useSelector(getOrderedColumns)

  return (
    <div className="pb-16">
      <SidebarCollapsibleContent title="Column Selector">
        <SubmissionColumnList columns={columns} />
      </SidebarCollapsibleContent>
    </div>
  )
}

export default SubmissionColumnSelector
