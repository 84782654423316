import React from "react"
import types from "prop-types"
import * as API from "services/api"
import { useSurveyConfigurationContext } from "contexts/SurveyConfigurationContext"
import { CustomHeaderAccordion, useAccordion } from "shared/accordions"
import { RemoveButton } from "shared/buttons"
import { ChevronDown, ChevronUp } from "shared/icons"
import { errorToast, successToast } from "shared/toast"
import ContactInfoHeader from "./ContactInfoHeader"

const DELETE_CONFIRMATION_MESSAGE = "Are you sure you want to remove this contact?  They will not be sent any new surveys."

const ContactInfo = ({ contactInclusion }) => {
  const { surveyConfiguration, removeSurveyConfigurationContact } = useSurveyConfigurationContext()
  const { id: surveyConfigurationId } = surveyConfiguration

  const [isExpanded, toggleAccordion] = useAccordion(false)

  const removeContactFromSurveyConfiguration = async () => {
    // eslint-disable-next-line no-alert
    if (!window.confirm(DELETE_CONFIRMATION_MESSAGE)) return

    const contactInclusionId = contactInclusion.id

    const response = await API.destroySurveyConfigurationContact({ surveyConfigurationId, contactInclusionId })

    if (response.ok) {
      successToast("Contact removed")
      removeSurveyConfigurationContact(contactInclusionId)
    } else {
      errorToast("Something went wrong. Unable to remove contact.")
    }
  }
  const Chevron = isExpanded ? ChevronDown : ChevronUp

  return (
    <div className="list-row block py-3 bg-primary-light">
      <CustomHeaderAccordion
        isExpanded={isExpanded}
        header={(
          <div className="flex relative flex-center">
            <ContactInfoHeader contact={contactInclusion} />
            <div className="rounded bg-light-100 p-1 w-6">
              <Chevron
                className="h-4 w-4 cursor-pointer"
                onClick={toggleAccordion}
              />
            </div>
            <div className="ml-5 -mr-10">
              <RemoveButton onClick={removeContactFromSurveyConfiguration} />
            </div>
          </div>
        )}
        content={(
          <div className="my-3 font-normal">
            <div className="mb-3">
              <div className="font-semibold">
                Comment
              </div>
              <span>
                {contactInclusion.comment}
              </span>
            </div>
            <div>
              <div className="font-semibold">
                Reminder cc
              </div>
              <div className="break-all">
                {contactInclusion.reminderCcEmail}
              </div>
            </div>
          </div>
        )}
      />
    </div>
  )
}

ContactInfo.propTypes = {
  contactInclusion: types.object.isRequired,
}

export default ContactInfo
