import React from "react"
import {
  IS_PROPERTY_OP,
  IS_NOT_PROPERTY_OP,
  IS_EMPTY_PROPERTY_OP,
  IS_NOT_EMPTY_PROPERTY_OP,
  IS_FACILITY_PROPERTY_OP,
  IS_NOT_FACILITY_PROPERTY_OP,
  IS_ANY_OF_FACILITIES_PROPERTY_OP,
  HAS_ANY_OF_FACILITY_GROUP_CODES_PROPERTY_OP,
  DOES_NOT_HAVE_ANY_OF_FACILITY_GROUP_CODES_PROPERTY_OP,
  IS_DEPARTMENT_PROPERTY_OP,
} from "utils/workflowHelpers"
import {
  SELECT_TYPE,
  SELECT_CSV_TYPE,
  MULTI_SELECT_TYPE,
  TEXT_TYPE,
  LONG_ANSWER_TYPE,
  LOCATION_TYPE,
} from "views/Forms/FormElementTypes"
import { snakeToCapitalCase } from "utils/stringHelpers"
import { workflowConditionShape } from "utils/propTypeShapes"
import types from "prop-types"
import { Select } from "shared/selects"
import { useSelector } from "react-redux"
import { getFormQuestions } from "reduxSlices/formBuilderSlice"
import FormsyValidation from "shared/FormsyValidation"

const DefineQuestionAnswerOperator = ({ condition, conditionIndex, changeConditionOperator }) => {
  const questions = useSelector(getFormQuestions)
  const question = questions.find(({ uuid }) => uuid === condition.subProperty?.questionUuid)

  let operators

  switch (question?.type) {
  case SELECT_TYPE:
  case SELECT_CSV_TYPE:
  case MULTI_SELECT_TYPE:
    operators = [
      IS_PROPERTY_OP,
      IS_NOT_PROPERTY_OP,
      IS_EMPTY_PROPERTY_OP,
      IS_NOT_EMPTY_PROPERTY_OP,
    ]
    break;
  case TEXT_TYPE:
  case LONG_ANSWER_TYPE:
    operators = [
      IS_EMPTY_PROPERTY_OP,
      IS_NOT_EMPTY_PROPERTY_OP,
    ]
    break;
  case LOCATION_TYPE:
    operators = [
      IS_EMPTY_PROPERTY_OP,
      IS_NOT_EMPTY_PROPERTY_OP,
      IS_FACILITY_PROPERTY_OP,
      IS_NOT_FACILITY_PROPERTY_OP,
      IS_ANY_OF_FACILITIES_PROPERTY_OP,
      HAS_ANY_OF_FACILITY_GROUP_CODES_PROPERTY_OP,
      DOES_NOT_HAVE_ANY_OF_FACILITY_GROUP_CODES_PROPERTY_OP,
      IS_DEPARTMENT_PROPERTY_OP,
    ]
    break;
  default:
    operators = []
  }

  const options = operators.map((op) => ({
    value: op,
    label: snakeToCapitalCase(op),
  }))

  return (
    <span className="relative">
      <Select
        backspaceDelete={false}
        onChange={changeConditionOperator}
        options={options}
        style={{ height: "32px", minWidth: "180px" }}
        placeholder="Select operator... *"
        required
        value={condition.operator}
      />
      <FormsyValidation
        name={`conditions[${conditionIndex}].operator`}
        value={condition?.operator ?? ""}
        validations={{ isNotBlankString: true }}
        validationErrors={{ isDefaultRequiredValue: "required" }}
      />
    </span>
  )
}

DefineQuestionAnswerOperator.propTypes = {
  condition: workflowConditionShape.isRequired,
  changeConditionOperator: types.func.isRequired,
  conditionIndex: types.number.isRequired,
}

export default DefineQuestionAnswerOperator
