import React from "react"
import types from "prop-types"

const SurveyTokenHeader = ({ contact }) => (
  <div className="flex-grow flex-center mr-4">
    <span className="pr-4">
      {
        (contact.lastName || contact.firstName)
        && `${contact.lastName || ""}, ${contact.firstName || ""}`
      }
    </span>
    <span className="break-all font-normal ">{contact.email}</span>
  </div>
)

SurveyTokenHeader.propTypes = {
  contact: types.object.isRequired,
}

export default SurveyTokenHeader
