import React from "react"
import types from "prop-types"
import { questionShape } from "utils/propTypeShapes"

// eslint-disable-next-line max-len
const RETAINED_ANSWERS_MESSAGE = "The original form submission answers will be retained for these questions on the new form type."
const NO_RETAINED_ANSWERS_MESSAGE = "No answers will be retained."

const RetainedAnswersOverview = ({ answers, className, questions }) => {
  const hasRetainedAnswers = Object.getOwnPropertyNames(answers).length > 0

  return (
    <div className={className}>
      <p className="text-sm text-center text-gray mb-4 w-5/6 mx-auto">
        {hasRetainedAnswers ? RETAINED_ANSWERS_MESSAGE : NO_RETAINED_ANSWERS_MESSAGE}
      </p>
      {
        hasRetainedAnswers && (
          <ul className="m-0 p-4 border border-light-300 rounded max-h-24 lg:max-h-40 overflow-y-auto">
            {
              Object.getOwnPropertyNames(answers).map((questionUuid) => {
                const answeredQuestion = questions.find((question) => question.uuid === questionUuid)

                return (
                  <li className="truncate mb-2 last:mb-0" key={answeredQuestion.uuid}>
                    {answeredQuestion.prompt}
                  </li>
                )
              })
            }
          </ul>
        )
      }
    </div>
  )
}

RetainedAnswersOverview.defaultProps = {
  className: "",
}

RetainedAnswersOverview.propTypes = {
  answers: types.object.isRequired,
  className: types.string,
  questions: types.arrayOf(questionShape).isRequired,
}

export default RetainedAnswersOverview
