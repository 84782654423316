import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { CloseSidebarIcon, OpenSidebarIcon } from "shared/icons"

const SidebarVisibilityControl = ({ hiddenSidebar, toggleSidebar, className = "" }) => {
  const ToggleIcon = hiddenSidebar ? OpenSidebarIcon : CloseSidebarIcon

  return (
    <div
      className={clsx(className, "inline-flex rounded px-2.5 py-2 items-center border border-light cursor-pointer")}
      onClickCapture={toggleSidebar}
    >
      <ToggleIcon className="inline-block" />
      <span className="ml-2 font-semibold text-sm">
        Options
      </span>
    </div>
  )
}

SidebarVisibilityControl.propTypes = {
  className: types.string,
  hiddenSidebar: types.bool.isRequired,
  toggleSidebar: types.func.isRequired,
}

export default SidebarVisibilityControl
