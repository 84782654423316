import React from "react"
import types from "prop-types"
import { daysOfTheWeek, todayDay } from "utils/dateHelpers"
import { FormsyListSelect } from "shared/selects"

const WeeklyRecurrenceDetails = ({ recurrenceDay = "" }) => (
  <div className="flex items-center mr-2">
    <div className="mr-2">every</div>
    <FormsyListSelect
      backspaceDelete={false}
      required
      name="survey_schedule_attributes.recurrence_day_of_week"
      options={daysOfTheWeek}
      value={recurrenceDay}
    />
    {
      (recurrenceDay === todayDay())
        && <div className="ml-2">starting next week.</div>
    }
  </div>
)

WeeklyRecurrenceDetails.propTypes = {
  recurrenceDay: types.string,
}

export default WeeklyRecurrenceDetails
