import React from "react"
import types from "prop-types"
import { SearchSelectedUser } from "shared/searches"
import AccessLevelIdentifier from "./AccessLevelIdentifier"

const UserGranteeList = ({
  deleteFormGrant, users, formSlug, updateAccessGranteeLists,
}) => {
  if (!users.length) {
    return <div>No Users with Grants</div>
  }

  return (
    users.map(({ grantee, formGrant, categoryGrant }) => (
      <SearchSelectedUser
        key={grantee.id}
        user={grantee}
        deselect={deleteFormGrant(formGrant?.id)}
        showDeselectButton={!!formGrant}
      >
        <AccessLevelIdentifier
          formSlug={formSlug}
          formGrant={formGrant}
          categoryGrant={categoryGrant}
          updateAccessGranteeLists={updateAccessGranteeLists}
          granteeType="User"
          granteeId={grantee.id}
        />
      </SearchSelectedUser>
    ))
  )
}

UserGranteeList.propTypes = {
  users: types.arrayOf(types.object).isRequired,
  updateAccessGranteeLists: types.func.isRequired,
  deleteFormGrant: types.func.isRequired,
  formSlug: types.string.isRequired,
}

export default UserGranteeList
