import React, { Component, createRef } from "react"
import types from "prop-types"
import Formsy from "formsy-react"
import * as API from "services/api"
import { ACTIVITY_HUB_SURVEYS_SURVEY_CONFIGURATIONS_ROOT, surveyConfigurationPath } from "utils/routeHelpers"
import {
  ANONYMOUS_SURVEY_DESCRIPTION,
  AUTO_CLOSE_DESCRIPTION_PREFIX,
  AUTO_SEND_DESCRIPTION_PREFIX,
  BATCH_AUTO_CLOSE_TITLE,
  BATCH_DURATION_UNITS,
  BATCH_RECURRENCE_TITLE,
  BATCH_RECURRENCE_UNITS,
  END_OF_MONTH_EXPLANATION,
  MANUAL_CLOSE_DESCRIPTION,
  MANUAL_SEND_DESCRIPTION,
  REMINDER_FREQUENCY_OPTIONS,
  TRACKED_SURVEY_DESCRIPTION,
} from "utils/surveyHelpers"
import {
  DAILY,
  WEEKLY,
  MONTHLY,
  QUARTERLY,
} from "utils/recurrenceDetailsHelpers"
import { PrimaryButton, Button } from "shared/buttons"
import { DataCollectorContainer, DataCollectorStep } from "shared/dataCollector"
import FieldLabel from "shared/CopyFormToOrganization/FieldLabel"
import Switch from "components/shared/Switch"
import {
  FormsyNumberInput, FormsyTextInput, FormsySwitchInput,
} from "shared/inputs"
import { FormsyFormSelect, FormsyListSelect, FormsySelect } from "shared/selects"
import { errorToast, successToast } from "shared/toast"
import { SmallWrap } from "shared/wraps"
import { CONTACTS_TAB_HEADING } from "../SurveyConfigurationShow/Content/SurveyConfigurationTabs/SurveyConfigurationContactsTab"
import DailyRecurrenceDetails from "../SurveyConfigurationForm/Content/DailyRecurrenceDetails"
import MonthlyRecurrenceDetails from "../SurveyConfigurationForm/Content/MonthlyRecurrenceDetails"
import QuarterlyRecurrenceDetails from "../SurveyConfigurationForm/Content/QuarterlyRecurrenceDetails"
import WeeklyRecurrenceDetails from "../SurveyConfigurationForm/Content/WeeklyRecurrenceDetails"

const renderDataCollectorHeaderContent = () => (
  <span className="font-medium text-xl">Configure your Survey</span>
)

class SurveyConfigurationNew extends Component {
  static onSubmit = async (model) => {
    const response = await API.createSurveyConfiguration({ surveyConfiguration: model })
    if (response.ok) {
      window.location = surveyConfigurationPath(response.data.id, CONTACTS_TAB_HEADING)
      successToast("Survey created successfully!")
    } else {
      errorToast("Something went wrong. Unable to create survey.", response)
    }
  }

  static cancelForm = () => {
    window.location.pathname = ACTIVITY_HUB_SURVEYS_SURVEY_CONFIGURATIONS_ROOT
  }

  state = {
    isFormValid: false,
    surveyCloses: false,
    surveyRecurs: false,
  }

  formRef = createRef()

  toggleSurveyRecurs = () => this.setState((prevState) => (
    {
      surveyRecurs: !prevState.surveyRecurs,
    }
  ))

  toggleSurveyCloses = () => this.setState((prevState) => (
    { surveyCloses: !prevState.surveyCloses }
  ))

  enableSubmit = () => this.setState({ isFormValid: true })

  disableSubmit = () => this.setState({ isFormValid: false })

  getFormAnswers = () => this.formRef.current?.getModel() || {}

  static renderRecurrenceDetails = (batchRecurrenceUnit, recurrenceData) => {
    switch (batchRecurrenceUnit) {
    case DAILY:
      return <DailyRecurrenceDetails />
    case WEEKLY:
      return <WeeklyRecurrenceDetails recurrenceDay={recurrenceData?.recurrence_day_of_week} />
    case MONTHLY:
      return <MonthlyRecurrenceDetails recurrenceDate={recurrenceData?.recurrence_day_of_month} />
    case QUARTERLY:
      return <QuarterlyRecurrenceDetails />
    default:
      return null
    }
  }

  render() {
    const { surveyForms } = this.props
    const { isFormValid, surveyCloses, surveyRecurs } = this.state

    const {
      batch_anonymous: batchAnonymous,
    } = this.getFormAnswers()

    const batchRecurrenceUnit = this.getFormAnswers()?.survey_schedule_attributes?.batch_frequency
    const recurrenceData = this.getFormAnswers()?.survey_schedule_attributes

    return (
      <SmallWrap>
        <DataCollectorContainer renderHeaderContent={renderDataCollectorHeaderContent}>
          <Formsy
            action={this.createSurveyConfiguration}
            method="post"
            ref={this.formRef}
            onSubmit={SurveyConfigurationNew.onSubmit}
            onValid={this.enableSubmit}
            onInvalid={this.disableSubmit}
          >
            <DataCollectorStep stepNumber={1}>
              <FieldLabel text="Name" className="!max-w-full" boldLabel required>
                <FormsyTextInput
                  required
                  className="bordered-base-input text-sm"
                  name="name"
                  placeholder="Survey name..."
                  validations={{ isNotBlankString: true }}
                />
              </FieldLabel>
              <FieldLabel text="Description" className="!max-w-full" boldLabel>
                <FormsyTextInput
                  className="bordered-base-input text-sm"
                  name="description"
                  placeholder="Description..."
                />
              </FieldLabel>
            </DataCollectorStep>

            <DataCollectorStep stepNumber={2} heading="Batch Details">
              <p className="text-sm text-center mb-4">
                These settings will be applied each time the survey is sent.
              </p>
              <FieldLabel text="Batch Name" className="!max-w-full" boldLabel>
                <FormsyTextInput
                  className="bordered-base-input text-sm"
                  name="batch_name"
                  placeholder="Batch Name..."
                />
              </FieldLabel>
              <FieldLabel text="Survey Form" className="mb-6" boldLabel required>
                <FormsyFormSelect
                  className="min-w-80"
                  required
                  name="survey_form_id"
                  backspaceDelete={false}
                  options={surveyForms}
                />
              </FieldLabel>
              <FieldLabel text="Email Reminders" className="mb-6" boldLabel required>
                <FormsySelect
                  className="min-w-80"
                  required
                  name="reminders"
                  backspaceDelete={false}
                  options={REMINDER_FREQUENCY_OPTIONS}
                />
              </FieldLabel>
              <div className="mb-8">
                <div className="flex justify-between items-start mb-2">
                  <span className="font-semibold">Anonymous Submissions</span>
                  <FormsySwitchInput name="batch_anonymous" />
                </div>
                <p>
                  {
                    batchAnonymous ? ANONYMOUS_SURVEY_DESCRIPTION : TRACKED_SURVEY_DESCRIPTION
                  }
                </p>
              </div>
              <div className="mb-6">
                <div className="flex justify-between items-start mb-2">
                  <span className="font-semibold">{BATCH_RECURRENCE_TITLE}</span>
                  <Switch checked={surveyRecurs} onChange={this.toggleSurveyRecurs} />
                </div>
                {
                  surveyRecurs
                    ? (
                      <div className="flex items-center">
                        <span className="required-prompt block mr-2">{AUTO_SEND_DESCRIPTION_PREFIX}</span>
                        <FormsyListSelect
                          className="mr-2"
                          backspaceDelete={false}
                          name="survey_schedule_attributes.batch_frequency"
                          required
                          options={BATCH_RECURRENCE_UNITS}
                        />
                        { SurveyConfigurationNew.renderRecurrenceDetails(batchRecurrenceUnit, recurrenceData) }
                      </div>
                    )
                    : (
                      <p>
                        { MANUAL_SEND_DESCRIPTION }
                      </p>
                    )
                }
                {
                  recurrenceData?.recurrence_day_of_month > 28
                    && (
                      <p className="text-sm mt-4">{END_OF_MONTH_EXPLANATION}</p>
                    )
                }
              </div>
              <div className="mb-6">
                <div className="flex justify-between items-start mb-2">
                  <span className="font-semibold">{BATCH_AUTO_CLOSE_TITLE}</span>
                  <Switch checked={surveyCloses} onChange={this.toggleSurveyCloses} />
                </div>
                {
                  surveyCloses
                    ? (
                      <div className="flex items-center">
                        <span className="required-prompt block mr-2">{ AUTO_CLOSE_DESCRIPTION_PREFIX }</span>
                        <div className="w-16 mr-2">
                          <FormsyNumberInput
                            name="batch_duration_count"
                            min={1}
                            required
                            placeholder="2"
                            className="bordered-base-input"
                          />
                        </div>
                        <FormsyListSelect
                          backspaceDelete={false}
                          name="batch_duration_unit"
                          options={BATCH_DURATION_UNITS}
                        />
                      </div>
                    )
                    : (
                      <p>
                        { MANUAL_CLOSE_DESCRIPTION }
                      </p>
                    )
                }
              </div>
            </DataCollectorStep>

            <div className="flex justify-center mt-8">
              <Button
                text="Cancel"
                onClick={SurveyConfigurationNew.cancelForm}
                className="border border-light-300 mr-2"
              />
              <PrimaryButton
                text="Create"
                type="submit"
                disabled={!isFormValid}
              />
            </div>
          </Formsy>
        </DataCollectorContainer>
      </SmallWrap>
    )
  }
}

SurveyConfigurationNew.propTypes = {
  surveyForms: types.arrayOf(types.object).isRequired,
}

export default SurveyConfigurationNew
