import React from "react"
import types from "prop-types"
import { dayjsOrgTz } from "utils/dateHelpers"

const TimeDataElement = ({ value = "" }) => {
  const time = dayjsOrgTz(value)

  return (
    <span>
      {
        time.isValid()
          ? time.format("h:mm A")
          : ""
      }
    </span>
  )
}

TimeDataElement.propTypes = {
  value: types.string,
}

export default TimeDataElement
