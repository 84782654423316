import React from "react"
import types from "prop-types"
import { useDispatch } from "react-redux"
import { MenuButton } from "@szhsin/react-menu"
import { taskShape } from "utils/propTypeShapes"
import { recoverTask } from "reduxSlices/formSubmissionSlice"
import ExpandMenu, { ExpandMenuItem } from "shared/ExpandMenu"
import * as API from "services/api"

const RecoverTaskMenu = ({ task, taskIsRecoverable }) => {
  const dispatch = useDispatch()

  const recover = async () => {
    const taskId = task.id
    const response = await API.recoverTask({ taskId })

    if (response.ok) {
      dispatch(recoverTask(taskId))
    } else {
      console.error("Error recovering task: ", response)
    }
  }

  if (!taskIsRecoverable) return null

  return (
    <ExpandMenu expandMenuComponent={(
      <MenuButton className="circular-icon-button p-2.5">
        <svg className="!w-4 !h-4"><use xlinkHref="#ellipsis" /></svg>
      </MenuButton>
    )}
    >
      <ExpandMenuItem onClick={recover}>
        <span>Recover</span>
      </ExpandMenuItem>
    </ExpandMenu>
  )
}

RecoverTaskMenu.propTypes = {
  task: taskShape.isRequired,
  taskIsRecoverable: types.bool.isRequired,
}

export default RecoverTaskMenu
