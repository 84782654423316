import React from "react"
import { useSelector } from "react-redux"
import { allTasks } from "reduxSlices/formSubmissionSlice"
import { SidebarCollapsibleContent } from "shared/Sidebar"
import ShowFormSubmissionAuditsCheckbox from "./ShowFormSubmissionAuditsCheckbox"
import ShowTaskAuditsCheckbox from "./ShowTaskAuditsCheckbox"

const AuditableFilters = () => {
  const tasks = useSelector(allTasks)

  return (
    <SidebarCollapsibleContent title="Filter By Item">
      <ShowFormSubmissionAuditsCheckbox />
      {
        tasks.map((task) => (
          <ShowTaskAuditsCheckbox key={task.id} task={task} />
        ))
      }
    </SidebarCollapsibleContent>
  )
}

export default AuditableFilters
