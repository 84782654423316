import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import * as API from "services/api"
import { differenceWith, isEmpty } from "lodash-es"
import { DraggableColumnTypes } from "../components/views/ReportBuilder/DragAndDrop/DraggableColumnTypes"

const SLICE_NAME = "reportBuilder"

const INITIAL_GENERAL_PROPERTIES = {
  name: "",
  reportType: null,
  shared: false,
}

const INITIAL_CATEGORY_PROPERTIES = {
  categories: [],
  selectedCategorySlug: null,
}

const INITIAL_FORM_PROPERTIES = {
  forms: [],
  selectedFormSlug: null, // for form-specific reports (ie: survey/submission)
  // solution report - specify form slugs to include
  filterFormsEnabled: false,
  includedFormSlugs: [],
}

const INITIAL_QUESTION_PROPERTIES = {
  formQuestions: [],
  reportableFields: [],
}

const INITIAL_TASK_FORM_PROPERTIES = {
  taskForms: [],
  taskFormQuestions: {},
  selectedTaskForms: [],
}

const INITIAL_COLUMN_DEFAULT_PROPERTIES = {
  orderedSubmissionColumns: [],
  orderedTaskColumns: [],
  submissionColumnOptions: [],
  surveyColumnOptions: [],
  taskColumnOptions: [],
}

const INITIAL_STATE = {
  ...INITIAL_GENERAL_PROPERTIES,
  ...INITIAL_CATEGORY_PROPERTIES,
  ...INITIAL_FORM_PROPERTIES,
  ...INITIAL_QUESTION_PROPERTIES,
  ...INITIAL_TASK_FORM_PROPERTIES,
  ...INITIAL_COLUMN_DEFAULT_PROPERTIES,
}

// Matches column types used on BE (ExportInstructions::DefaultColumns)
const QUESTION_TYPE = "question"
const REPORTABLE_FIELD_TYPE = "reportable_field"
const SUBMISSION_TYPE = "submission"
const SURVEY_TYPE = "survey"
export const SURVEY_NON_ANONYMOUS_TYPE = "survey_non_anonymous"
const TASK_TYPE = "task"
const TASK_FORM_TYPE = "task_form"
const TASK_QUESTION_TYPE = "task_question"

const columnsMatch = (col1, col2) => (col1.type === col2.type && col1.id === col2.id)

export const SOLUTION_REPORT_TYPE = "solution"
export const SUBMISSION_REPORT_TYPE = "submission"
export const SURVEY_REPORT_TYPE = "survey"

export const getSortableOptionType = (columnType) => {
  switch (columnType) {
  case SUBMISSION_TYPE:
  case SURVEY_TYPE:
  case SURVEY_NON_ANONYMOUS_TYPE:
  case QUESTION_TYPE:
  case REPORTABLE_FIELD_TYPE:
    return DraggableColumnTypes.SUBMISSION_RELATED_SELECTION
  case TASK_TYPE:
    return DraggableColumnTypes.TASK_DEFAULT_SELECTION
  case TASK_QUESTION_TYPE:
    return DraggableColumnTypes.TASK_QUESTION_SELECTION
  case TASK_FORM_TYPE:
    return DraggableColumnTypes.TASK_FORM_SELECTION
  default:
    return ""
  }
}

const fetchFormsForCategory = (categorySlug, reportType) => (
  (reportType === SURVEY_REPORT_TYPE)
    ? API.categorySurveyForms({ categorySlug })
    : API.getCategoryForms({ categorySlug })
)

export const selectCategory = createAsyncThunk(
  `${SLICE_NAME}/selectCategory`,
  async ({ categorySlug }, thunkAPI) => {
    const currentState = thunkAPI.getState()[SLICE_NAME]
    const { reportType, selectedCategorySlug: currentSlug } = currentState

    if (categorySlug === currentSlug) return currentState

    const updatedData = {
      ...INITIAL_FORM_PROPERTIES,
      ...INITIAL_QUESTION_PROPERTIES,
      ...INITIAL_TASK_FORM_PROPERTIES,
      selectedCategorySlug: categorySlug,
    }

    if (!categorySlug) return updatedData

    const formsResponse = await fetchFormsForCategory(categorySlug, reportType)

    if (formsResponse.ok) {
      updatedData.forms = formsResponse.data
    } else {
      throw new Error(`Something went wrong. ${formsResponse.data}`)
    }

    if (reportType === SOLUTION_REPORT_TYPE) {
      const reportableFieldsResponse = await API.getCategoryReportableFields({ categorySlug })

      if (reportableFieldsResponse.ok) {
        updatedData.reportableFields = reportableFieldsResponse.data.map((rf) => ({ id: rf.id, label: rf.name, type: REPORTABLE_FIELD_TYPE }))
      } else {
        throw new Error(`Something went wrong.  ${reportableFieldsResponse.data}`)
      }
    }

    const taskFormsResponse = await API.getCategorySubforms({ categorySlug })

    if (taskFormsResponse.ok) {
      updatedData.taskForms = taskFormsResponse.data.map((tf) => ({ id: tf.slug, label: tf.title, type: TASK_FORM_TYPE }))
    } else {
      throw new Error(`Something went wrong. ${taskFormsResponse.data}`)
    }

    return updatedData
  },
)

export const selectForm = createAsyncThunk(
  `${SLICE_NAME}/selectForm`,
  async ({ selectedFormSlug }) => {
    const updatedData = {
      ...INITIAL_QUESTION_PROPERTIES,
      selectedFormSlug,
    }

    if (selectedFormSlug) {
      const response = await API.formQuestionHistory({ formSlug: selectedFormSlug })

      if (response.ok) {
        updatedData.formQuestions = response.data.map(({ uuid, prompt, removedAt }) => (
          { id: uuid, label: `${prompt}${removedAt ? " (removed)" : ""}`, type: QUESTION_TYPE }
        ))
      } else {
        throw new Error(`Something went wrong. ${response.data}`)
      }
    }

    return updatedData
  },
)

export const selectTaskForms = createAsyncThunk(
  `${SLICE_NAME}/selectTaskForms`,
  async (taskForms, thunkAPI) => {
    const { taskFormQuestions } = thunkAPI.getState()[SLICE_NAME]

    const newTaskFormQuestions = {}

    for (let i = 0; i < taskForms.length; i += 1) {
      const selectedSlug = taskForms[i].id

      if (!taskFormQuestions[selectedSlug]) {
        // eslint-disable-next-line no-await-in-loop
        const response = await API.formQuestionHistory({ formSlug: selectedSlug })

        if (response.ok) {
          newTaskFormQuestions[selectedSlug] = response.data.map(({ uuid, prompt, removedAt }) => (
            { id: uuid, label: `${prompt}${removedAt ? " (removed)" : ""}`, type: TASK_QUESTION_TYPE }
          ))
        } else {
          throw new Error(`Something went wrong getting questions for task form ${selectedSlug}. ${response.data}`)
        }
      }
    }

    return (
      {
        newTaskFormQuestions,
        taskForms,
      }
    )
  },
)

export const selectAllTaskForms = createAsyncThunk(
  `${SLICE_NAME}/selectAllTaskForms`,
  async (_, thunkAPI) => {
    const currentState = thunkAPI.getState()[SLICE_NAME]

    const unselectedForms = differenceWith(
      currentState.taskForms,
      currentState.selectedTaskForms,
      columnsMatch,
    )

    await thunkAPI.dispatch(selectTaskForms(unselectedForms))
  },
)

export const selectTaskForm = createAsyncThunk(
  `${SLICE_NAME}/selectTaskForm`,
  async (taskForm, thunkAPI) => {
    const currentState = thunkAPI.getState()[SLICE_NAME]
    const unselectedForms = currentState.selectedTaskForms.some((tf) => tf.id === taskForm.id) ? [] : [taskForm]

    await thunkAPI.dispatch(selectTaskForms(unselectedForms))
  },
)

export const reportBuilderSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    chooseAllCurrentAndFutureForms: (state) => {
      state.filterFormsEnabled = false
      reportBuilderSlice.caseReducers.excludeAllFormSlugs(state)
    },
    chooseSpecificForms: (state) => {
      state.filterFormsEnabled = true
      reportBuilderSlice.caseReducers.includeAllFormSlugs(state)
    },
    deselectAllQuestionColumns: (state) => {
      state.orderedSubmissionColumns = [...state.orderedSubmissionColumns].filter(({ type }) => type !== QUESTION_TYPE)
    },
    deselectAllReportableFieldColumns: (state) => {
      state.orderedSubmissionColumns = [...state.orderedSubmissionColumns].filter(({ type }) => type !== REPORTABLE_FIELD_TYPE)
    },
    deselectAllSubmissionDefaultColumns: (state) => {
      state.orderedSubmissionColumns = [...state.orderedSubmissionColumns].filter(({ type }) => type !== SUBMISSION_TYPE)
    },
    deselectAllSurveyDefaultColumns: (state) => {
      state.orderedSubmissionColumns = [...state.orderedSubmissionColumns].filter(({ type }) => (type !== SURVEY_TYPE && type !== SURVEY_NON_ANONYMOUS_TYPE))
    },
    deselectAllTaskDefaultColumns: (state) => {
      state.orderedTaskColumns = []
    },
    deselectAllTaskFormQuestionColumns: (state, { payload: formSlug }) => {
      const taskForm = state.selectedTaskForms.find((form) => form.id === formSlug)
      Object.assign(taskForm, { questions: [] })
    },
    deselectAllTaskForms: (state) => {
      state.selectedTaskForms = []
    },
    deselectSubmissionColumn: (state, { payload }) => {
      const { id, type } = payload
      state.orderedSubmissionColumns = [...state.orderedSubmissionColumns].filter((col) => !(col.id === id && col.type === type))
    },
    // Task column collection currently only consists of one type
    deselectTaskDefaultColumn: (state, { payload }) => {
      const results = [...state.orderedTaskColumns]
      state.orderedTaskColumns = results.filter((col) => col.id !== payload.id)
    },
    deselectTaskForm: (state, { payload }) => {
      const results = [...state.selectedTaskForms]
      state.selectedTaskForms = results.filter((form) => form.id !== payload.id)
    },
    deselectTaskFormQuestionColumn: (state, { payload }) => {
      const { id, formSlug } = payload

      const taskForm = state.selectedTaskForms.find((form) => form.id === formSlug)
      const newTaskFormQuestions = taskForm.questions.filter((q) => q.id !== id)

      Object.assign(taskForm, { questions: newTaskFormQuestions })
    },
    excludeAllFormSlugs: (state) => {
      state.includedFormSlugs = []
    },
    excludeFormSlug: (state, { payload }) => {
      state.includedFormSlugs = state.includedFormSlugs.filter((slug) => slug !== payload)
    },
    includeAllFormSlugs: (state) => {
      state.includedFormSlugs = state.forms.map(({ slug }) => slug)
    },
    includeFormSlug: (state, { payload }) => {
      state.includedFormSlugs = [...state.includedFormSlugs, payload]
    },
    loadReportData: (state, { payload }) => {
      //  Since selected columns only store the identifier/type, need to map current
      //    labels when loading option sets
      const mappedLabels = {}

      state.categories = payload.categories
      state.forms = payload.forms
      state.includedFormSlugs = payload.includedFormSlugs ?? []
      state.filterFormsEnabled = state.includedFormSlugs.length > 0
      state.name = payload.name
      state.reportType = payload.reportType
      state.selectedCategorySlug = payload.selectedCategorySlug
      state.selectedFormSlug = payload.selectedFormSlug
      state.shared = payload.shared

      state.taskForms = payload.taskForms.map(({ slug, title }) => {
        mappedLabels[TASK_FORM_TYPE]
          ? (mappedLabels[TASK_FORM_TYPE][slug] = title)
          : mappedLabels[TASK_FORM_TYPE] = { [slug]: title }

        return { id: slug, label: title, type: TASK_FORM_TYPE }
      })

      state.formQuestions = payload.formQuestions?.map(({ uuid, prompt }) => {
        mappedLabels[QUESTION_TYPE]
          ? (mappedLabels[QUESTION_TYPE][uuid] = prompt)
          : mappedLabels[QUESTION_TYPE] = { [uuid]: prompt }

        return { id: uuid, label: prompt, type: QUESTION_TYPE }
      })

      state.reportableFields = payload.reportableFields?.map(({ id, name: label }) => {
        mappedLabels[REPORTABLE_FIELD_TYPE]
          ? (mappedLabels[REPORTABLE_FIELD_TYPE][id] = label)
          : mappedLabels[REPORTABLE_FIELD_TYPE] = { [id]: label }

        return { id, label, type: REPORTABLE_FIELD_TYPE }
      })

      state.submissionColumnOptions = payload.submissionColumnOptions.map(({ id, label }) => {
        mappedLabels[SUBMISSION_TYPE]
          ? (mappedLabels[SUBMISSION_TYPE][id] = label)
          : mappedLabels[SUBMISSION_TYPE] = { [id]: label }

        return { id, label, type: SUBMISSION_TYPE }
      })

      const surveyOptions = payload.surveyColumnOptions.map(({ id, label }) => {
        mappedLabels[SURVEY_TYPE]
          ? (mappedLabels[SURVEY_TYPE][id] = label)
          : mappedLabels[SURVEY_TYPE] = { [id]: label }

        return { id, label, type: SURVEY_TYPE }
      })

      const surveyNonAnonOptions = payload.surveyNonAnonymousColumnOptions.map(({ id, label }) => {
        mappedLabels[SURVEY_NON_ANONYMOUS_TYPE]
          ? (mappedLabels[SURVEY_NON_ANONYMOUS_TYPE][id] = label)
          : mappedLabels[SURVEY_NON_ANONYMOUS_TYPE] = { [id]: label }

        return { id, label, type: SURVEY_NON_ANONYMOUS_TYPE }
      })

      state.surveyColumnOptions = [...surveyOptions, ...surveyNonAnonOptions]

      state.taskColumnOptions = payload.taskColumnOptions.map(({ id, label }) => {
        mappedLabels[TASK_TYPE]
          ? (mappedLabels[TASK_TYPE][id] = label)
          : mappedLabels[TASK_TYPE] = { [id]: label }

        return { id, label, type: TASK_TYPE }
      })

      state.taskFormQuestions = Object.fromEntries(
        Object.entries(payload.taskFormQuestions).map(([slug, questions]) => {
          const mappedQuestions = questions.map(({ uuid, prompt }) => {
            const key = `${TASK_QUESTION_TYPE}-${slug}`
            mappedLabels[key]
              ? (mappedLabels[key][uuid] = prompt)
              : mappedLabels[key] = { [uuid]: prompt }

            return { id: uuid, label: prompt, type: TASK_QUESTION_TYPE }
          })

          return [slug, mappedQuestions]
        }),
      )

      // If the mappedLabels are missing an entry for the type/id, then it is no longer part of the valid column options for the report and should be ommitted
      // This is uncommon but can happen with Reportable Fields, or if a "default" option is ever eliminated.
      // Questions should always remain in the question histories of forms
      state.orderedSubmissionColumns = payload.orderedSubmissionColumns.reduce((result, column) => {
        const label = (mappedLabels[column.type] ?? {})[column.id]

        if (label) {
          result.push({ ...column, label })
        }

        return result
      }, [])

      state.orderedTaskColumns = payload.orderedTaskColumns.map((column) => (
        { ...column, label: (mappedLabels[column.type] ?? {})[column.id] }
      ))

      state.selectedTaskForms = payload.selectedTaskForms.map(({ slug, questions: questionColumns }) => (
        {
          id: slug,
          label: (mappedLabels[TASK_FORM_TYPE] ?? {})[slug],
          questions: questionColumns.map((column) => (
            { ...column, label: (mappedLabels[`${column.type}-${slug}`] ?? {})[column.id] }
          )),
          type: TASK_FORM_TYPE,
        }
      ))
    },
    moveSubmissionColumn: (state, { payload }) => {
      const { column: movedColumn, toIndex } = payload
      const newColumns = [...state.orderedSubmissionColumns]
      const currentIndex = state.orderedSubmissionColumns.findIndex((col) => col.id === movedColumn.id && col.type === movedColumn.type)

      if (currentIndex === -1) {
        newColumns.splice(toIndex, 0, movedColumn)
      } else {
        const [column] = newColumns.splice(currentIndex, 1)
        newColumns.splice(toIndex, 0, column)
      }

      state.orderedSubmissionColumns = newColumns
    },
    moveTaskDefaultColumn: (state, { payload }) => {
      const { column: movedColumn, toIndex } = payload
      const newColumns = [...state.orderedTaskColumns]
      const currentIndex = state.orderedTaskColumns.findIndex((col) => col.id === movedColumn.id && col.type === movedColumn.type)

      if (currentIndex === -1) {
        newColumns.splice(toIndex, 0, movedColumn)
      } else {
        const [column] = newColumns.splice(currentIndex, 1)
        newColumns.splice(toIndex, 0, column)
      }

      state.orderedTaskColumns = newColumns
    },
    moveTaskForm: (state, { payload }) => {
      const { column: form, toIndex } = payload

      const newTaskForms = [...state.selectedTaskForms]
      const currentIndex = newTaskForms.findIndex((tf) => tf.id === form.id)

      if (currentIndex === -1) {
        newTaskForms.splice(toIndex, 0, form)
      } else {
        const [taskForm] = newTaskForms.splice(currentIndex, 1)
        newTaskForms.splice(toIndex, 0, taskForm)
      }

      state.selectedTaskForms = newTaskForms
    },
    moveTaskFormQuestionColumn: (state, { payload }) => {
      const { column, toIndex, slug } = payload
      const taskForm = state.selectedTaskForms.find((form) => form.id === slug)
      const newTaskFormQuestions = [...taskForm.questions]
      const currentIndex = taskForm.questions.findIndex((q) => q.id === column.id)

      if (currentIndex === -1) {
        newTaskFormQuestions.splice(toIndex, 0, column)
      } else {
        const [question] = newTaskFormQuestions.splice(currentIndex, 1)
        newTaskFormQuestions.splice(toIndex, 0, question)
      }

      Object.assign(taskForm, { questions: newTaskFormQuestions })
    },
    selectAllQuestionColumns: (state) => {
      const unselectedOptions = differenceWith(
        state.formQuestions,
        state.orderedSubmissionColumns,
        columnsMatch,
      )

      state.orderedSubmissionColumns = state.orderedSubmissionColumns.concat(unselectedOptions)
    },
    selectAllReportableFieldColumns: (state) => {
      const unselectedOptions = differenceWith(
        state.reportableFields,
        state.orderedSubmissionColumns,
        columnsMatch,
      )

      state.orderedSubmissionColumns = state.orderedSubmissionColumns.concat(unselectedOptions)
    },
    selectAllSubmissionDefaultColumns: (state) => {
      const unselectedOptions = differenceWith(
        state.submissionColumnOptions,
        state.orderedSubmissionColumns,
        columnsMatch,
      )

      state.orderedSubmissionColumns = state.orderedSubmissionColumns.concat(unselectedOptions)
    },
    selectAllSurveyDefaultColumns: (state) => {
      const unselectedOptions = differenceWith(
        state.surveyColumnOptions,
        state.orderedSubmissionColumns,
        columnsMatch,
      )

      state.orderedSubmissionColumns = state.orderedSubmissionColumns.concat(unselectedOptions)
    },
    selectAllTaskDefaultColumns: (state) => {
      const unselectedOptions = differenceWith(
        state.taskColumnOptions,
        state.orderedTaskColumns,
        columnsMatch,
      )

      state.orderedTaskColumns = state.orderedTaskColumns.concat(unselectedOptions)
    },
    selectAllTaskFormQuestionColumns: (state, { payload: formSlug }) => {
      const taskForm = state.selectedTaskForms.find((form) => form.id === formSlug)
      const unselectedQuestions = differenceWith(state.taskFormQuestions[formSlug], taskForm.questions, columnsMatch)

      Object.assign(taskForm, { questions: [...taskForm.questions, ...unselectedQuestions] })
    },
    selectSubmissionColumn: (state, { payload }) => {
      const { id, type } = payload
      const result = [...state.orderedSubmissionColumns]

      if (result.some((col) => col.id === id && col.type === type)) return

      state.orderedSubmissionColumns = [...result, payload]
    },
    selectTaskDefaultColumn: (state, { payload }) => {
      const { id, type } = payload
      const result = [...state.orderedTaskColumns]

      if (result.some((col) => col.id === id && col.type === type)) return

      state.orderedTaskColumns = [...result, payload]
    },
    selectTaskFormQuestionColumn: (state, { payload }) => {
      const { column, formSlug } = payload
      const taskForm = state.selectedTaskForms.find((form) => form.id === formSlug)

      if (taskForm.questions.some((q) => q.id === column.id && q.type === column.type)) return

      Object.assign(taskForm, { questions: [...taskForm.questions, column] })
    },
    setCategories: (state, { payload }) => {
      state.categories = payload
    },
    setName: (state, action) => {
      state.name = action.payload
    },
    setReportType: (state, { payload }) => {
      state.reportType = payload
    },
    setShared: (state, action) => {
      state.shared = action.payload
    },
    setSubmissionColumnOptions: (state, { payload }) => {
      state.submissionColumnOptions = payload.map((option) => ({ ...option, type: SUBMISSION_TYPE }))
    },
    setSurveyColumnOptions: (state, { payload }) => {
      const { surveyColumnOptions: columnOptions, surveyNonAnonymousColumnOptions } = payload

      state.surveyColumnOptions = [
        ...columnOptions.map((option) => ({ ...option, type: SURVEY_TYPE })),
        ...surveyNonAnonymousColumnOptions.map((option) => ({ ...option, type: SURVEY_NON_ANONYMOUS_TYPE })),
      ]
    },
    setTaskColumnOptions: (state, { payload }) => {
      state.taskColumnOptions = payload.map((option) => ({ ...option, type: TASK_TYPE }))
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(selectCategory.pending, () => {
        // Not implemented
      })
      .addCase(selectCategory.fulfilled, (state, action) => {
        const currentCategory = state.selectedCategorySlug
        const newCategory = action.payload.selectedCategorySlug

        Object.assign(state, action.payload)

        if (!newCategory || newCategory === currentCategory) return

        if (state.reportType === SOLUTION_REPORT_TYPE) {
          if (!currentCategory) {
            reportBuilderSlice.caseReducers.selectAllSubmissionDefaultColumns(state)
          }

          reportBuilderSlice.caseReducers.deselectAllReportableFieldColumns(state)
          reportBuilderSlice.caseReducers.selectAllReportableFieldColumns(state)
        }
      })
      .addCase(selectCategory.rejected, () => {
        // Not implemented
      })
      .addCase(selectForm.pending, () => {
        // Not implemented
      })
      .addCase(selectForm.fulfilled, (state, action) => {
        const currentForm = state.selectedFormSlug
        Object.assign(state, action.payload)

        // Don't select all if a Form Slug was already selected
        if (!currentForm) {
          reportBuilderSlice.caseReducers.selectAllSubmissionDefaultColumns(state)
        }

        reportBuilderSlice.caseReducers.deselectAllQuestionColumns(state)
        reportBuilderSlice.caseReducers.selectAllQuestionColumns(state)
      })
      .addCase(selectForm.rejected, () => {
        // Not implemented
      })
      .addCase(selectTaskForms.pending, () => {
        // Not implemented
      })
      .addCase(selectTaskForms.fulfilled, (state, action) => {
        const { newTaskFormQuestions, taskForms } = action.payload

        // Add new task form questions
        Object.assign(state.taskFormQuestions, newTaskFormQuestions)

        const result = [...state.selectedTaskForms]
        state.selectedTaskForms = [
          ...result,
          ...taskForms.map((taskForm) => ({ ...taskForm, questions: state.taskFormQuestions[taskForm.id] })),
        ]
      })
      .addCase(selectTaskForms.rejected, () => {
        // Not implemented
      })
  },
})

export const {
  chooseAllCurrentAndFutureForms,
  chooseSpecificForms,
  deselectAllQuestionColumns,
  deselectAllReportableFieldColumns,
  deselectAllSubmissionDefaultColumns,
  deselectAllSurveyDefaultColumns,
  deselectAllTaskDefaultColumns,
  deselectAllTaskFormQuestionColumns,
  deselectAllTaskForms,
  deselectSubmissionColumn,
  deselectTaskDefaultColumn,
  deselectTaskForm,
  deselectTaskFormQuestionColumn,
  excludeAllFormSlugs,
  excludeFormSlug,
  includeAllFormSlugs,
  includeFormSlug,
  loadReportData,
  moveSubmissionColumn,
  moveTaskDefaultColumn,
  moveTaskForm,
  moveTaskFormQuestionColumn,
  selectAllQuestionColumns,
  selectAllReportableFieldColumns,
  selectAllSubmissionDefaultColumns,
  selectAllSurveyDefaultColumns,
  selectAllTaskDefaultColumns,
  selectAllTaskFormQuestionColumns,
  selectSubmissionColumn,
  selectTaskDefaultColumn,
  selectTaskFormQuestionColumn,
  setCategories,
  setName,
  setReportType,
  setShared,
  setSubmissionColumnOptions,
  setSurveyColumnOptions,
  setTaskColumnOptions,
} = reportBuilderSlice.actions

export const categories = (state) => state[SLICE_NAME].categories

export const formQuestions = (state) => state[SLICE_NAME].formQuestions

export const forms = (state) => state[SLICE_NAME].forms

export const filterFormsEnabled = (state) => state[SLICE_NAME].filterFormsEnabled

export const includedFormSlugs = (state) => state[SLICE_NAME].includedFormSlugs

export const name = (state) => state[SLICE_NAME].name

export const orderedSubmissionColumns = (state) => state[SLICE_NAME].orderedSubmissionColumns

export const orderedTaskColumns = (state) => state[SLICE_NAME].orderedTaskColumns

export const reportableFields = (state) => state[SLICE_NAME].reportableFields

export const selectedCategorySlug = (state) => state[SLICE_NAME].selectedCategorySlug

export const selectedFormSlug = (state) => state[SLICE_NAME].selectedFormSlug

export const selectedReportType = (state) => state[SLICE_NAME].reportType

export const selectedTaskForms = (state) => state[SLICE_NAME].selectedTaskForms

export const selectedTaskFormSlugs = (state) => state[SLICE_NAME].selectedTaskForms.map(({ slug }) => slug)

export const shared = (state) => state[SLICE_NAME].shared

export const submissionColumnOptions = (state) => state[SLICE_NAME].submissionColumnOptions

export const surveyColumnOptions = (state) => state[SLICE_NAME].surveyColumnOptions

export const taskColumnOptions = (state) => state[SLICE_NAME].taskColumnOptions

export const taskForm = (formSlug) => (state) => state[SLICE_NAME].taskForms.find((storedTaskForm) => (
  storedTaskForm.slug === formSlug
))

export const taskFormQuestionColumnOptions = (formSlug) => (state) => (
  state[SLICE_NAME].taskFormQuestions[formSlug]
)

export const taskForms = (state) => state[SLICE_NAME].taskForms

const selectedTaskFormMissingSelectedQuestion = (state) => (
  Object.values(selectedTaskForms(state)).some((selectedTaskForm) => (
    isEmpty(selectedTaskForm.questions)
  ))
)

const answerRelatedColumnMissing = (state) => {
  const submissionColumns = orderedSubmissionColumns(state)
  if (selectedReportType(state) === SOLUTION_REPORT_TYPE) {
    return !submissionColumns.some((column) => column.type === REPORTABLE_FIELD_TYPE)
  }

  return !submissionColumns.some((column) => column.type === QUESTION_TYPE)
}

const formSlugMissing = (state) => (
  selectedReportType(state) === SOLUTION_REPORT_TYPE
    ? filterFormsEnabled(state) && isEmpty(includedFormSlugs(state))
    : !selectedFormSlug(state)
)

export const isMissingRequiredReportBuilderData = (state) => {
  const hasSelectedTaskForms = selectedTaskForms(state).length > 0
  const incompleteTaskFormData = hasSelectedTaskForms && selectedTaskFormMissingSelectedQuestion(state)
  return !selectedCategorySlug(state) || formSlugMissing(state) || incompleteTaskFormData || answerRelatedColumnMissing(state)
}

export default reportBuilderSlice.reducer
