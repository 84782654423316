import React from "react"
import { isEqual } from "lodash-es"
import { useSelector } from "react-redux"
import { selectedTaskForms } from "reduxSlices/reportBuilderSlice"
import TaskDefaultOptions from "./TaskDefaultOptions"
import TaskDefaultSelections from "./TaskDefaultSelections"
import SelectorSection from "./SelectorSection"

const TaskDefaultColumnSelector = () => {
  const storedSelectedTaskForms = useSelector(selectedTaskForms, isEqual)

  if (storedSelectedTaskForms.length === 0) return null

  const instructions = (
    <div className="space-y-2">
      <p>Which task form column defaults would you like to include?</p>
      <p className="text-xs text-dark">Your selections can be custom ordered by dragging and dropping</p>
    </div>
  )

  return (
    <div>
      <SelectorSection
        instructionComponent={instructions}
        optionsComponent={<TaskDefaultOptions />}
        selectionsComponent={<TaskDefaultSelections />}
      />
    </div>
  )
}

export default TaskDefaultColumnSelector
