import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getFormQuestionType } from "reduxSlices/formBuilderSlice"
import { optionsComponent } from "Forms/FormElementTypes"

const QuestionOptions = ({ sectionIndex, questionIndex }) => {
  const questionType = useSelector(getFormQuestionType({ sectionIndex, questionIndex }))
  const OptionsComponent = optionsComponent({ questionType })

  return (
    <OptionsComponent
      fieldPath={`sections[${sectionIndex}].questions[${questionIndex}].attributes.options`}
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
  )
}

QuestionOptions.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default QuestionOptions
