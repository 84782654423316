import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash-es";

const SLICE_NAME = "session"

const INITIAL_STATE = {
  currentUser: {},
  impersonator: {},
}

export const sessionSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setSession: (state, action) => {
      Object.assign(state, action.payload)
    },
  },
})

export const { setSession } = sessionSlice.actions

export const currentUser = (state) => state[SLICE_NAME].currentUser

export const currentUserFullName = (state) => state[SLICE_NAME].currentUser.fullName

export const currentUserId = (state) => state[SLICE_NAME].currentUser.id

export const currentUserIsInAdminRole = (state) => state[SLICE_NAME].currentUser.isInAdminRole

export const currentUserIsPhpAdmin = (state) => state[SLICE_NAME].currentUser.isPhpAdmin

export const currentUserRole = (state) => state[SLICE_NAME].currentUser.role

export const impersonationInProgress = (state) => !isEmpty(state[SLICE_NAME].impersonator)

export default sessionSlice.reducer
