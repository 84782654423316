import React, { useEffect, useRef, useState } from "react"
import types from "prop-types"
import { throttle } from "lodash-es"
import { BroadcastChannel } from "broadcast-channel";
import UserEventMonitor from "./UserEventMonitor"

const RESET_TIMER_EVENTS = ["mousemove", "scroll", "keydown", "mousedown", "touchstart"]

const IDLE_TIMER_RESET = "timer_reset"

// One minute in milliseconds
const THROTTLE_WAIT_DURATION = 60000

const IdleTracker = ({
  idleTime, onContinue, onTimeout, modal: TimeoutModal,
}) => {
  const channel = new BroadcastChannel(IDLE_TIMER_RESET)
  const [userIdle, setUserIdle] = useState(false)
  const markUserIdle = () => setUserIdle(true)
  const markUserActive = () => setUserIdle(false)

  const timerRef = useRef()

  const clearTimer = () => clearTimeout(timerRef.current)

  const resetTimer = () => {
    clearTimer()
    timerRef.current = setTimeout(markUserIdle, idleTime)
    markUserActive()
  }

  const syncTimerReset = () => {
    channel.postMessage(IDLE_TIMER_RESET)
    onContinue(resetTimer)
  }

  // Start the timer when the component mounts,
  // clear the timer when the component unmounts.
  useEffect(() => {
    resetTimer()

    return clearTimer
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  useEffect(() => {
    channel.onmessage = () => {
      onContinue(resetTimer)
    }

    return () => channel.close()
  })

  return (
    userIdle
      ? (
        <TimeoutModal
          modalIsOpen={userIdle}
          onContinue={syncTimerReset}
          onTimeout={onTimeout}
        />
      ) : (
        <UserEventMonitor
          events={RESET_TIMER_EVENTS}
          onEvent={throttle(syncTimerReset, THROTTLE_WAIT_DURATION, { trailing: false })}
        />
      )
  )
}

IdleTracker.propTypes = {
  idleTime: types.number.isRequired,
  onContinue: types.func.isRequired,
  onTimeout: types.func.isRequired,
  modal: types.elementType.isRequired,
}

export default IdleTracker
