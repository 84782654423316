import React from "react"
import pluralize from "pluralize"
import { useSurveyConfigurationContext } from "contexts/SurveyConfigurationContext"
import { shortMonthDate, dateOrdinal } from "utils/dateHelpers"
import {
  ACTIVE_SURVEY_DESCRIPTION,
  ANONYMOUS_SURVEY_DESCRIPTION,
  AUTO_CLOSE_DESCRIPTION_PREFIX,
  END_OF_MONTH_EXPLANATION,
  MANUAL_CLOSE_DESCRIPTION,
  MANUAL_SEND_DESCRIPTION,
  reminderFrequencyHumanized,
  TRACKED_SURVEY_DESCRIPTION,
} from "utils/surveyHelpers"
import {
  DAILY,
  WEEKLY,
  MONTHLY,
  QUARTERLY,
} from "utils/recurrenceDetailsHelpers"
import ContentBar from "shared/lists/ContentBar"
import { FormIcon } from "shared/icons"
import { MultilineTextInput, TextInput } from "shared/inputs"
import ActiveToggle from "../../../../SurveyConfigurationIndex/Content/ActiveToggle"

const SurveyConfigurationOverview = () => {
  const { surveyConfiguration } = useSurveyConfigurationContext()
  const { surveyForm, surveySchedule } = surveyConfiguration

  const batchesAutoClose = (surveyConfiguration.batchDurationUnit && surveyConfiguration.batchDurationCount)

  const batchDurationLabel = () => {
    if (!batchesAutoClose) return ""

    return pluralize(surveyConfiguration.batchDurationUnit, surveyConfiguration.batchDurationCount)
  }

  const batchDurationDescription = `${surveyConfiguration.batchDurationCount} ${batchDurationLabel()}`

  const surveyScheduleDetails = () => {
    switch (surveySchedule.batchFrequency) {
    case DAILY:
      return `daily, as of ${shortMonthDate(surveySchedule.startDate)}.`
    case WEEKLY:
      return `every ${surveySchedule.recurrenceDayOfWeek}.`
    case MONTHLY:
      return `on the ${dateOrdinal(surveySchedule.recurrenceDayOfMonth)} of every month.`
    case QUARTERLY:
      return "on the first day of each quarter: Jan 1, Apr 1, Jul 1, and Oct 1."
    default:
      return null
    }
  }

  return (
    <>
      <ContentBar
        title="Active"
        footer={(
          <div className="flex">
            <ActiveToggle />
            <div className="ml-4">
              {ACTIVE_SURVEY_DESCRIPTION}
            </div>
          </div>
        )}
      />
      <ContentBar
        title="Name"
        footer={(
          <TextInput
            disabled
            className="bordered-base-input text-sm bg-white"
            name="name"
            value={surveyConfiguration.name}
          />
        )}
      />

      <ContentBar
        title="Description"
        footer={(
          <MultilineTextInput
            disabled
            className="bordered-base-input text-sm"
            name="description"
            value={surveyConfiguration.description || ""}
            onChange={() => {}}
          />
        )}
      />

      <ContentBar
        title="Form"
        footer={(
          <div className="flex items-center">
            <FormIcon form={surveyForm} className="h-6 w-6 mr-1" />
            <span>{ surveyForm.title }</span>
          </div>
        )}
      />

      <ContentBar
        title="Email Reminders"
        footer={(
          <TextInput
            disabled
            className="bordered-base-input text-sm bg-white"
            name="name"
            value={reminderFrequencyHumanized(surveyConfiguration.reminders)}
          />
        )}
      />

      <ContentBar
        title={surveyConfiguration.batchAnonymous ? "Anonymous Submissions" : "Tracked Submissions"}
        footer={(
          <p>
            {
              surveyConfiguration.batchAnonymous ? ANONYMOUS_SURVEY_DESCRIPTION : TRACKED_SURVEY_DESCRIPTION
            }
          </p>
        )}
      />

      <ContentBar
        title="Schedule"
        footer={(
          <div>
            {
              surveySchedule
                ? `Survey sent ${surveyScheduleDetails()}`
                : MANUAL_SEND_DESCRIPTION
            }
            {
              surveySchedule?.recurrenceDayOfMonth > 28
            && (<p className="text-sm mt-4">{END_OF_MONTH_EXPLANATION}</p>)
            }
          </div>
        )}
      />

      <ContentBar
        title="Survey Closing"
        footer={(
          <p>
            {
              batchesAutoClose
                ? `${AUTO_CLOSE_DESCRIPTION_PREFIX} ${batchDurationDescription}.`
                : MANUAL_CLOSE_DESCRIPTION
            }
          </p>
        )}
      />
    </>
  )
}

export default SurveyConfigurationOverview
