import React from "react"
import types from "prop-types"
import { TertiaryButton } from "shared/buttons"

const DiscardChangesButton = ({ discardChanges, ...rest }) => (
  <TertiaryButton
    text="Reset Changes"
    onClick={discardChanges}
    {...rest}
  />
)

DiscardChangesButton.propTypes = {
  discardChanges: types.func.isRequired,
}

export default DiscardChangesButton
