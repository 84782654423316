import React, { useState, useEffect } from "react"
import types from "prop-types"
import { chunk } from "lodash-es"
import StrikeAround from "shared/StrikeAround"
import Paginate from "shared/Paginate"
import Result from "./Result"

const SearchResults = ({ results, hasCompletedSearch, perPage }) => {
  const [pages, setPages] = useState([])
  const [currentPage, setCurrentPage] = useState([])

  const onPageChange = (selected) => setCurrentPage(pages[selected])

  useEffect(
    () => { setPages(chunk(results, perPage)) },
    [results, perPage],
  )

  useEffect(
    () => { setCurrentPage(pages?.[0] ?? []) },
    [pages],
  )

  if (!hasCompletedSearch) return null

  return (
    <div className="mt-10">
      <StrikeAround className="font-medium text-xl py-3 flex items-center text-dark mb-6">
        <div className="rounded-full bg-light h-8 w-8 mr-2.5 flex items-center justify-center">
          { results.length }
        </div>
        Search Results
      </StrikeAround>
      {
        currentPage.map((result) => (
          <Result key={result.id} result={result} />
        ))
      }
      <Paginate
        className="mt-10 mb-20"
        pageCount={pages.length}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        onPageChange={onPageChange}
      />
    </div>
  )
}

SearchResults.propTypes = {
  results: types.arrayOf(types.object).isRequired,
  hasCompletedSearch: types.bool.isRequired,
  perPage: types.number.isRequired,
}

export default SearchResults
