import React from "react"
import { CustomIconButton } from "shared/buttons"
import CreateFacilityModal from "shared/modals/CreateFacilityModal"
import { ACTIVITY_HUB_FACILITY_AND_DEPT_IMPORTS_PATH, redirectTo } from "utils/routeHelpers"

const CreateFacilityButtons = () => (
  <>
    <CreateFacilityModal />
    <span className="mx-4 text-dark font-medium text-sm">or</span>
    <CustomIconButton
      text="Import From CSV"
      className="tertiary-button"
      icon="import-csv"
      onClick={() => redirectTo(ACTIVITY_HUB_FACILITY_AND_DEPT_IMPORTS_PATH)}
    />
  </>
)

export default CreateFacilityButtons
