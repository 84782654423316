import React from "react"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const BatchesEmpty = () => (
  <div className="flex flex-col items-center py-20">
    <SvgSpriteIcon iconName="surveys" className="h-10 w-10 mb-4" />
    <p className="font-medium text-semibold">No survey batches.</p>
  </div>
)

export default BatchesEmpty
