import React from "react"
import { SidebarCollapsibleContent } from "shared/Sidebar"
import SubmissionFormList from "./SubmissionFormList"

const SubmissionFormFilter = () => (
  <SidebarCollapsibleContent title="Showing">
    <SubmissionFormList />
  </SidebarCollapsibleContent>
)

export default SubmissionFormFilter
