import React from "react"
import LazyLoad from "react-lazyload"
import { sortBy } from "lodash-es"
import { useSurveyBatchContext } from "contexts/SurveyBatchContext"
import SurveyToken from "./SurveyToken"

const SURVEY_TOKENS_SORT_FUNCTIONS = [
  (surveyToken) => surveyToken.contact.lastName?.toLowerCase(),
  (surveyToken) => surveyToken.contact.firstName?.toLowerCase(),
  (surveyToken) => surveyToken.contact.email.toLowerCase(),
]

const SurveyTokensList = () => {
  const { surveyBatch } = useSurveyBatchContext()
  const { surveyTokens } = surveyBatch

  return (
    sortBy(surveyTokens, SURVEY_TOKENS_SORT_FUNCTIONS).map((surveyToken) => (
      <LazyLoad key={`contact-row-${surveyToken.id}`} height={66} offset={100} once resize>
        <SurveyToken surveyToken={surveyToken} />
      </LazyLoad>
    ))
  )
}

export default SurveyTokensList
