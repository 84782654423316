import React, { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  activateFacility,
  deactivateFacility,
  facilityActiveStatus,
  facilityId,
} from "reduxSlices/adminFacilitySlice"
import * as API from "services/api"
import ContentBar from "shared/lists/ContentBar"
import Switch from "shared/Switch"
import { errorToast } from "shared/toast"

const Active = () => {
  const id = useSelector(facilityId)
  const active = useSelector(facilityActiveStatus)

  const dispatch = useDispatch()

  const activate = useCallback(async () => {
    const response = await API.activateFacility({ facilityId: id })

    if (response.ok) {
      dispatch(activateFacility())
    } else {
      errorToast("Something went wrong. Unable to activate facility.")
    }
  }, [dispatch, id])

  const deactivate = useCallback(async () => {
    const response = await API.deactivateFacility({ facilityId: id })

    if (response.ok) {
      dispatch(deactivateFacility())
    } else {
      errorToast(`Unable to deactivate facility: ${response.data.errors.join(", ")}.`)
    }
  }, [dispatch, id])

  const description = useMemo(() => (
    active
      ? "This facility is active.  It will be presented as an option on forms."
      : "This facility is deactivated.  It will not be presented as an option on forms."
  ), [active])

  return (
    <ContentBar
      description={description}
      title="Active status"
    >
      <Switch
        checked={active}
        onChange={active ? deactivate : activate}
      />
    </ContentBar>
  )
}

export default Active
