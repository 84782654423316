import React, { useEffect, useMemo, useRef } from "react"
import types from "prop-types"
import clsx from "clsx"
import { FormsyTextInput } from "shared/inputs"

const buildFacilityGroupCodeValidations = ({ facilityGroupCodeNames }) => (
  {
    isNotBlankString: true,
    mayNotBeIncludedIn: {
      errorMessage: "Facility group code already exists",
      denyList: facilityGroupCodeNames,
    },
  }
)

const FacilityGroupCodeFormFields = ({ className = "", facilityGroupCodeNames }) => {
  const inputRef = useRef()

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  // eslint-disable-next-line max-len
  const validations = useMemo(() => buildFacilityGroupCodeValidations({ facilityGroupCodeNames }), [facilityGroupCodeNames])

  return (
    <div className={clsx(className)}>
      <FormsyTextInput
        className="bordered-base-input text-sm bg-white base-input"
        name="facilityGroupCode.name"
        inputRef={inputRef}
        validations={validations}
        value=""
      />
    </div>
  )
}

FacilityGroupCodeFormFields.propTypes = {
  className: types.string,
  facilityGroupCodeNames: types.arrayOf(types.string).isRequired,
}

export default FacilityGroupCodeFormFields
