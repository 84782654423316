import React from "react"
import types from "prop-types"
import { categoryShape } from "utils/propTypeShapes"
import { snakeToCapitalCase } from "utils/stringHelpers"

const CategoryUsageType = ({ category, className = "" }) => (
  <div className={className}>
    <p className="field-name">Type</p>
    <p className="text-sm">{snakeToCapitalCase(category.usage)}</p>
  </div>
)

CategoryUsageType.propTypes = {
  category: categoryShape.isRequired,
  className: types.string,
}

export default CategoryUsageType
