import React from "react"
import types from "prop-types"
import { SearchSelectedUser } from "shared/searches"

const MemberList = ({ members, deleteDepartmentMembership }) => {
  if (!members.length) {
    return <span className="m-2">No Department Members</span>
  }

  return (
    members.map((member) => (
      <SearchSelectedUser
        key={member.id}
        className="bg-primary-light"
        user={member}
        deselect={() => deleteDepartmentMembership(member.id)}
      />
    ))
  )
}

MemberList.propTypes = {
  members: types.arrayOf(types.object).isRequired,
  deleteDepartmentMembership: types.func.isRequired,
}

export default MemberList
