import React, {
  useCallback, useEffect, useRef, useState,
} from "react"
import { useSurveyConfigurationContext } from "contexts/SurveyConfigurationContext"
import { useModal } from "hooks"
import * as API from "services/api"
import Modal, { DEFAULT_MODAL_STYLE, ModalHeader } from "shared/Modal"
import { Button } from "shared/buttons"
import { errorToast, successToast } from "shared/toast"
import ContactsUploadProcessingCheck from "./ContactsUploadProcessingCheck"

const ContactsUploader = () => {
  const { modalIsOpen, openModal, closeModal } = useModal()
  const fileInputRef = useRef()

  const [contactsUpload, setContactsUpload] = useState({})
  const { id: contactsUploadId, processingStatus } = contactsUpload

  const { addSurveyConfigurationContacts, surveyConfiguration } = useSurveyConfigurationContext()
  const { id: surveyConfigurationId } = surveyConfiguration

  const getContacts = async () => {
    const response = await API.getSurveyConfigurationContacts({
      surveyConfigurationId,
    })

    addSurveyConfigurationContacts(response.data)
  }

  const close = useCallback(() => {
    setContactsUpload({})
    closeModal()
  }, [closeModal, setContactsUpload])

  useEffect(() => {
    if (processingStatus === "failed") {
      fileInputRef.current.value = ""
      errorToast("Something went wrong.  Unable to process uploaded file.")
      close()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processingStatus])

  useEffect(() => {
    if (processingStatus === "done") {
      getContacts()
      close()
      successToast("Your contacts upload has been processed successfully.")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processingStatus, getContacts])

  const openFileDialog = () => fileInputRef.current?.click()

  const uploadFile = async () => {
    const file = fileInputRef.current?.files[0]

    // eslint-disable-next-line no-undef
    const formData = new FormData()
    formData.append("file", file)

    const response = await API.createSurveyConfigurationContactsUpload({
      surveyConfigurationId,
      formData,
    })

    if (response.ok) {
      openModal()
      setContactsUpload(response.data)
    } else {
      errorToast("Something went wrong.  Unable to upload file.")
      closeModal()
    }
  }

  return (
    <div>
      <input
        accept="text/csv,application/vnd.ms-excel"
        hidden
        onChange={uploadFile}
        ref={fileInputRef}
        type="file"
      />
      <Button
        className="border border-light-300 text-focus max-w-max mb-4"
        onClick={openFileDialog}
        text="Upload Contacts"
      />
      <div className="text-sm">
        Supported format: CSV file with 5 ordered columns. Exclude header row.
        <ul className="m-4">
          <li>Column 1: email address (required)</li>
          <li>Column 2: first name</li>
          <li>Column 3: last name</li>
          <li>Column 4: brief comment (e.g., department)</li>
          <li>Column 5: email address that will be cc&apos;d on reminder emails</li>
        </ul>
      </div>
      <Modal isOpen={modalIsOpen} style={DEFAULT_MODAL_STYLE}>
        <ModalHeader
          closeModal={close}
          heading="Upload Contacts"
        />
        {
          contactsUploadId && (
            <ContactsUploadProcessingCheck
              contactsUploadId={contactsUploadId}
              onError={close}
              setContactsUpload={setContactsUpload}
              surveyConfigurationId={surveyConfigurationId}
            />
          )
        }
      </Modal>
    </div>
  )
}

export default ContactsUploader
