export const SEGMENT_A = "a"
export const SEGMENT_B = "b"
export const SEGMENT_C = "c"

export const SEGMENTS = [
  SEGMENT_A,
  SEGMENT_B,
  SEGMENT_C,
]

export const DEFAULT_SEGMENTS = [SEGMENT_A]
