import React from "react"
import { useTaskFormContext } from "contexts/TaskFormContext"
import { FormsyHiddenInput } from "shared/inputs"

const TaskNotifyIds = () => {
  const { taskNotifyIds } = useTaskFormContext()

  return (
    <FormsyHiddenInput name="task.notify_ids" value={taskNotifyIds} />
  )
}

export default TaskNotifyIds
