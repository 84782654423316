import React from "react"
import types from "prop-types"
import clsx from "clsx"
import * as API from "services/api"
import { useSurveyConfigurationContext } from "contexts/SurveyConfigurationContext"
import { errorToast, successToast } from "shared/toast"
import { Button } from "shared/buttons"
import Tooltip from "shared/Tooltip"

const SUCCESS_MESSAGE = "Batch created successfully!"
const ERROR_MESSAGE = "Something went wrong.  Unable to create batch."

const createBatchConfirmationMessage = () => `Are you sure you want to send this survey?

A new batch will be created, and emails will be sent immediately to all contacts.`

const CreateBatchButton = ({ className = "" }) => {
  const { addSurveyBatch, surveyConfiguration } = useSurveyConfigurationContext()
  const { id: surveyConfigurationId } = surveyConfiguration
  const { active } = surveyConfiguration

  const createSurveyBatch = async () => {
    if (!window.confirm(createBatchConfirmationMessage())) return

    const response = await API.createSurveyBatch({ surveyConfigurationId })

    if (response.ok) {
      successToast(SUCCESS_MESSAGE)
      addSurveyBatch(response.data)
    } else {
      errorToast(ERROR_MESSAGE)
    }
  }

  return (
    <Tooltip text={active ? "" : "Activate survey to send a batch."}>
      <Button
        className={clsx("text-focus border border-light", className)}
        text="+ Send Survey"
        onClick={() => createSurveyBatch()}
        disabled={!active}
      />
    </Tooltip>
  )
}

CreateBatchButton.propTypes = {
  className: types.string,
}

export default CreateBatchButton
