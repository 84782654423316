import React, { useRef } from "react"
import { isEqual } from "lodash-es"
import { useDispatch, useSelector } from "react-redux"
import {
  deselectAllTaskDefaultColumns,
  deselectTaskDefaultColumn,
  orderedTaskColumns,
  selectAllTaskDefaultColumns,
  selectTaskDefaultColumn,
  taskColumnOptions,
} from "reduxSlices/reportBuilderSlice"
import MassSelection from "shared/MassSelection"
import ColumnOption from "./ColumnOption"
import { DraggableColumnTypes } from "./DragAndDrop/DraggableColumnTypes"
import SelectableOptions from "./SelectableOptions"

const TaskDefaultOptions = () => {
  const defaultOptions = useSelector(taskColumnOptions, isEqual)
  const defaultSelections = useSelector(orderedTaskColumns, isEqual)

  const dispatch = useDispatch()
  const selectDefault = (column) => dispatch(selectTaskDefaultColumn(column))
  const deselectDefault = (column) => dispatch(deselectTaskDefaultColumn(column))

  const deselectAll = () => dispatch(deselectAllTaskDefaultColumns())
  const selectAll = () => dispatch(selectAllTaskDefaultColumns())

  const ref = useRef(null)

  return (
    <SelectableOptions
      acceptableTypes={DraggableColumnTypes.TASK_DEFAULT_SELECTION}
      dropFunction={deselectDefault}
      ref={ref}
    >
      <div>
        <div className="mb-1 text-xl">Task Column Defaults</div>
        <MassSelection
          deselectAll={deselectAll}
          selectAll={selectAll}
        />
        <div className="w-full mt-4 space-y-2">
          {
            defaultOptions.map((option) => (
              <ColumnOption
                key={`${option.type}-${option.id}`}
                option={option}
                selectedColumns={defaultSelections}
                selectOption={selectDefault}
                deselectOption={deselectDefault}
              />
            ))
          }
        </div>
      </div>
    </SelectableOptions>
  )
}

export default TaskDefaultOptions
