/* eslint-disable react-hooks/exhaustive-deps, no-undef */
import { useState, useEffect } from "react"

const DEFAULT_COUNTDOWN_TIME = 60

let webWorker = () => {
  const ONE_SEC_IN_MS = 1000
  self.onmessage = ({ data: { secondsToTimeout } }) => {
    setTimeout(
      () => self.postMessage({ secondsToTimeout: secondsToTimeout - 1 }),
      ONE_SEC_IN_MS,
    )
  }
}
webWorker = webWorker.toString()
webWorker = webWorker.substring(webWorker.indexOf("{") + 1, webWorker.lastIndexOf("}"))

const blob = new window.Blob([webWorker], { type: "application/javascript" })
const workerScript = URL.createObjectURL(blob)

const useCountdownSeconds = ({
  countdownSeconds = DEFAULT_COUNTDOWN_TIME,
  onFinishedCountdown,
}) => {
  const [secondsToTimeout, setSecondsToTimeout] = useState(countdownSeconds)

  useEffect(() => {
    const worker = new window.Worker(workerScript)
    worker.postMessage({ secondsToTimeout })

    worker.onmessage = ({ data }) => {
      setSecondsToTimeout(data.secondsToTimeout)
      if (data.secondsToTimeout === 0) {
        onFinishedCountdown?.()
      }
    }

    return () => worker.terminate()
  }, [secondsToTimeout])

  return {
    secondsToTimeout,
  }
}

export default useCountdownSeconds
