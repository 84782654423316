import { useContext } from "react"
import SurveyReportExportContext from "../SurveyReportExportContext"

const useSurveyReportExportContext = () => {
  const providedValue = useContext(SurveyReportExportContext)

  return { ...providedValue }
}

export default useSurveyReportExportContext
