import React, { useEffect, useState } from "react";
import types from "prop-types"
import FHIR from "fhirclient"
import ActivityIndicator from "shared/ActivityIndicator"
import * as API from "services/api"
import FHIRPersistence from "utils/FHIRPersistence"
import buildQueryString from "utils/buildQueryString"

// We request and are granted different patient scopes by different EHR's
// Cerner: patient/Patient.read
// Epic: user/Patient.read
// Meditech: patient/Patient.read
const PATIENT_PATIENT_SCOPE_MATCHER = /patient\/Patient.read/
const USER_PATIENT_SCOPE_MATCHER = /user\/Patient.read/
const PATIENT_SCOPE_MATCHERS = [PATIENT_PATIENT_SCOPE_MATCHER, USER_PATIENT_SCOPE_MATCHER]

// We request and are granted different encounter scopes by different EHR's
// Cerner: patient/Encounter.read
// Epic: user/Encounter.read
// Meditech: patient/Encounter.read
const PATIENT_ENCOUNTER_SCOPE_MATCHER = /patient\/Encounter.read/
const USER_ENCOUNTER_SCOPE_MATCHER = /user\/Encounter.read/
const ENCOUNTER_SCOPE_MATCHERS = [PATIENT_ENCOUNTER_SCOPE_MATCHER, USER_ENCOUNTER_SCOPE_MATCHER]

// We request the user/Practitioner scope from Epic, not other EHR's
const USER_PRACTITIONER_SCOPE_MATCHER = /user\/Practitioner.read/

const FetchData = ({ ehrName, organizationLandingPageUrl, requestedScopes }) => {
  const [error, setError] = useState(false)

  useEffect(() => {
    const handleError = async (err) => {
      setError(err.message)

      const errorDetails = {
        message: err.message,
        stack: err.stack,
        cause: err.cause,
        component_name: "SmartOnFhir",
      }

      await API.reportError({ errorDetails })
    }

    // We attempt to read the patient data if
    // (1) we request a patient scope
    // (2) we are granted a patient scope
    // (3) the EHR indicates that a patient is in context
    const readPatientData = (client) => {
      const patientScopeRequested = PATIENT_SCOPE_MATCHERS.some((pattern) => requestedScopes.match(pattern))

      const grantedScopes = client.getState("tokenResponse.scope") ?? ""
      const patientScopeGranted = PATIENT_SCOPE_MATCHERS.some((pattern) => grantedScopes.match(pattern))

      if (client.patient.id && patientScopeRequested && patientScopeGranted) {
        return client.patient.read()
      }
      return null
    }

    // We attempt to read the encounter data if
    // (1) we request an encounter scope
    // (2) we are granted an encounter scope
    // (3) the EHR indicates that an encounter is in context
    const readEncounterData = (client) => {
      const encounterScopeRequested = ENCOUNTER_SCOPE_MATCHERS.some((pattern) => requestedScopes.match(pattern))

      const grantedScopes = client.getState("tokenResponse.scope") ?? ""
      const encounterScopeGranted = ENCOUNTER_SCOPE_MATCHERS.some((pattern) => grantedScopes.match(pattern))

      if (encounterScopeRequested && encounterScopeGranted && client.encounter.id) {
        return client.encounter.read()
      }
      return null
    }

    // Retrieve data for the current user of the EHR system.  The current user
    // may or may not be available, so we need to perform some checks
    // before making the request.  We are currently only looking to request
    // data for the current user from Epic, not Cerner or Meditech.
    //
    // (1) We request access to the current user context by requesting the openid
    // and fhirUser scopes; without these, we should not have access to user data.
    // If we don't request them, then we assume we don't want user data.
    //
    // (2) If we request the appropriate scopes, we then determine whether the EHR
    // has made the user data available to us.  If so, an identifier is sent
    // as part of the access token response, and the client object will be able to provide
    // a fhirUser identifier (e.g., Practitioner/xyz).  For our application,
    // we assume that all users will be practitioners, so we check both that the user
    // is available and the user's resourse type.
    //
    // (3) In order to request the current user's data (i.e., practitioner data),
    // we must be granted a scope for reading practitioner data.  This scope is
    // expected to be user/Practitioner.read.
    //
    // If any of these checks fail, we would not want to fetch the current user data,
    // as we either do not want it or it is not available.  Requesting the data
    // would result in an error, which we want to avoid.
    const readUserData = (client) => {
      const openidScopeRequested = requestedScopes.match(/\bopenid\b/)
      const fhirUserScopeRequested = requestedScopes.match(/\bfhirUser\b/)
      if (!openidScopeRequested || !fhirUserScopeRequested) return null

      if (!client.user.resourceType?.match(/Practitioner/)) return null

      const grantedScopes = client.getState("tokenResponse.scope") ?? ""
      if (!grantedScopes.match(USER_PRACTITIONER_SCOPE_MATCHER)) return null

      return client.user.read()
    }

    const fetchAndLoadFhirData = async () => {
      FHIRPersistence?.clear()

      // eslint-disable-next-line no-undef
      FHIR.oauth2.ready()
        .then((client) => Promise.all([
          readPatientData(client),
          readEncounterData(client),
          readUserData(client),
        ]))
        .then(([patientData, encounterData, practitionerData]) => {
          if (FHIRPersistence === undefined) throw new Error("Trouble connecting.", { cause: "FHIRPersistence undefined" })

          FHIRPersistence.persistPatient(patientData)
          FHIRPersistence.persistEncounter(encounterData)
          FHIRPersistence.persistSubmitter(practitionerData)

          window.location.replace(organizationLandingPageUrl + buildQueryString(FHIRPersistence.allData()))
        })
        .catch((err) => {
          handleError(err)
        })
    }

    fetchAndLoadFhirData()
  }, [])

  if (error) {
    return (
      <div className="flex flex-col w-full items-center mt-32">
        <div className="mb-4">{error}</div>
        <div>Please try again later or contact your IT team.</div>
      </div>
    )
  }

  return (
    <div id="loader-and-text" className="flex flex-col w-full items-center mt-32">
      <ActivityIndicator className="mb-4" />
      <span>{`Fetching data from ${ehrName} ...`}</span>
    </div>
  )
}

FetchData.propTypes = {
  ehrName: types.string.isRequired,
  organizationLandingPageUrl: types.string.isRequired,
  requestedScopes: types.string.isRequired,
}

export default FetchData
