import React, { useEffect } from "react"
import types from "prop-types"
import Rails from "@rails/ujs"
import Modal, { ModalHeader } from "shared/Modal"
import { PrimaryButton, TertiaryButton } from "shared/buttons"
import { HiddenInput } from "shared/inputs"
import { Checkbox } from "shared/checkboxes"
import { useFormSubmissionExportContext } from "contexts/FormSubmissionExportContext"

const DataExportModal = ({
  closeModal,
  exportFormSubmissionData,
  modalIsOpen,
}) => {
  const {
    canSubmitExport,
    deselectTask,
    deselectFormSubmission,
    formSubmissionHasTasks,
    includeFormSubmission,
    includedTaskIds,
    selectTask,
    selectAllTasks,
    selectFormSubmission,
    taskSelected,
    tasks,
  } = useFormSubmissionExportContext()

  useEffect(() => {
    selectAllTasks()
  }, [selectAllTasks, tasks])

  const close = () => {
    selectFormSubmission()
    selectAllTasks()
    closeModal()
  }

  const submit = (event) => {
    event.preventDefault()
    exportFormSubmissionData(includeFormSubmission, includedTaskIds)()
    selectFormSubmission()
    selectAllTasks()
    closeModal()
  }

  return (
    <Modal isOpen={modalIsOpen}>
      <ModalHeader
        closeModal={close}
        heading="Export"
      />
      <form onSubmit={submit}>
        <HiddenInput name="authenticity_token" value={Rails.csrfToken()} />

        <p className="font-bold mb-2">Form Submission Options</p>
        <div className="mb-4">
          <label htmlFor="form-submission-export-checkbox">
            <Checkbox
              className="mr-2 cursor-pointer"
              uuid="form-submission-export-checkbox"
              value={includeFormSubmission}
              onChange={includeFormSubmission ? deselectFormSubmission : selectFormSubmission}
            />
            <span>Include form submission data</span>
          </label>
        </div>

        <p className="font-bold mb-2">Activities</p>
        <div className="flex flex-col">
          { formSubmissionHasTasks ? (
            tasks.map(({ id, title }) => (
              <label key={id} htmlFor="all-tasks-export-checkbox">
                <Checkbox
                  className="mr-2 cursor-pointer"
                  uuid={id.toString()}
                  value={taskSelected(id)}
                  onChange={taskSelected(id) ? () => deselectTask(id) : () => selectTask(id)}
                />
                <span>{title}</span>
              </label>
            ))
          ) : (
            <p>No exportable activities for submission</p>
          )}
        </div>

        <div className="flex justify-center mt-8 mb-10">
          <TertiaryButton
            text="Cancel"
            onClick={close}
            className="mr-2"
          />
          <PrimaryButton
            text="Export"
            type="submit"
            disabled={!canSubmitExport}
          />
        </div>
      </form>
    </Modal>
  )
}

DataExportModal.propTypes = {
  closeModal: types.func.isRequired,
  exportFormSubmissionData: types.func.isRequired,
  modalIsOpen: types.bool.isRequired,
}

export default DataExportModal
