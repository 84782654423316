import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { currentUserIsPhpAdmin as getCurrentUserIsPhpAdmin } from "reduxSlices/sessionSlice"
import * as API from "services/api"
import ExpandMenu, { ExpandMenuItem } from "shared/ExpandMenu"
import EllipsisIcon from "shared/icons/EllipsisIcon"
import { errorToast, successToast } from "components/shared/toast"

const ReportableFieldMenu = ({
  categorySlug, setReportableFields, reportableFields, reportableFieldId, setFormEditField,
}) => {
  const currentUserIsPhpAdmin = useSelector(getCurrentUserIsPhpAdmin)

  if (!currentUserIsPhpAdmin) return null

  const removeReportableField = async () => {
    // eslint-disable-next-line no-alert
    if (window.confirm("Are you sure you want to delete this field?")) {
      const response = await API.deleteCategoryReportableField({ categorySlug, reportableFieldId })
      if (response.ok) {
        successToast("Reportable field successfully deleted.")
        setReportableFields(reportableFields.filter(({ id }) => id !== reportableFieldId))
      } else {
        errorToast("Something went wrong.  Unable to delete reportable field.")
      }
    }
  }

  return (
    <div className="absolute -right-8 top-4">
      <ExpandMenu expandMenuComponent={(
        <div className="absolute-vertical-center right-0 bg-light-100 rounded py-2.5 px-0.5">
          <EllipsisIcon className="h-1 w-5" />
        </div>
      )}
      >
        <ExpandMenuItem onClick={setFormEditField}>
          <span>Edit</span>
        </ExpandMenuItem>
        <ExpandMenuItem onClick={removeReportableField}>
          <span>Delete</span>
        </ExpandMenuItem>
      </ExpandMenu>
    </div>
  )
}

ReportableFieldMenu.propTypes = {
  categorySlug: types.string.isRequired,
  setReportableFields: types.func.isRequired,
  reportableFields: types.arrayOf(types.object).isRequired,
  reportableFieldId: types.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  setFormEditField: types.func.isRequired,
}

export default ReportableFieldMenu
