import React from "react"
import types from "prop-types"
import { shortMonthDate } from "utils/dateHelpers"
import {
  Tile, TileContent, TileFooter, TileHeader,
} from "shared/tiles"
import FormMenu from "./FormMenu"
import FormTileIcon from "./FormTileIcon"
import ActiveToggle from "./ActiveToggle"

const FormTile = ({ form }) => (
  <Tile>
    <TileHeader>
      <FormMenu formSlug={form.slug} userCanEdit={form.userCanEdit} />
    </TileHeader>
    <TileContent className="px-8">
      <FormTileIcon form={form} />
      <div className="font-bold mb-1">{form.title}</div>
      <div className="font-bold text-sm text-gray-800 mb-2">{form.categoryName}</div>
      <div className="text-sm font-semibold leading-relaxed max-h-60 overflow-y-auto">{form.description}</div>
    </TileContent>
    <TileFooter>
      <div className="font-bold text-sm text-gray-800 mr-3">
        {`Last modified on ${shortMonthDate(form.updatedAt)}`}
      </div>
      <ActiveToggle form={form} />
    </TileFooter>
  </Tile>
)

FormTile.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  form: types.object.isRequired,
}

export default FormTile
