import { useContext } from "react"
import SurveyConfigurationContext from "../SurveyConfigurationContext"

const useSurveyConfigurationContext = () => {
  const providedValue = useContext(SurveyConfigurationContext)

  return { ...providedValue }
}

export default useSurveyConfigurationContext
