export const formAccessLevel = ({ categoryGrant, formGrant }) => {
  const applicableGrant = formGrant || categoryGrant

  return applicableGrant
}

export const formAccessExplanation = ({ categoryGrant, formGrant }) => {
  if (!categoryGrant) return "Granted by Form"

  if (categoryGrant && formGrant) return "Overrides Solution Grant"
  return "Granted by Solution"
}
