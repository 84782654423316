/* eslint-disable camelcase */
import React from "react"
import { accessLevelDescription } from "utils/accessLevelHelpers"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"

const AccessLevelGrantUpdatedAuditItemDetails = ({ audit }) => {
  const { auditedChanges } = audit
  const { access_level = [] } = auditedChanges
  const oldAccessLevel = access_level[0]
  const newAccessLevel = access_level[1]

  // access level for none access would be 0
  const hasOldAccessLevel = oldAccessLevel || oldAccessLevel === 0
  const hasNewAccessLevel = newAccessLevel || newAccessLevel === 0

  return (
    <div>
      <span>
        <AuditedUser audit={audit} /> changed an access level grant made by
        the form submission{ hasOldAccessLevel && ` from ${accessLevelDescription(oldAccessLevel)}` } access
        { hasNewAccessLevel && ` to ${accessLevelDescription(newAccessLevel)}` } access.
      </span>
    </div>
  )
}

AccessLevelGrantUpdatedAuditItemDetails.propTypes = {
  audit: auditShape.isRequired,
}

export default AccessLevelGrantUpdatedAuditItemDetails
