import React from "react"
import types from "prop-types"
import clsx from "clsx"
import Header from "./AccordionHeader"
import Content from "./AccordionContent"

const Accordion = ({
  className = "",
  content,
  hasBorder = true,
  header,
  headerClassName = "",
  headerColor = "white",
  isDisabled = false,
  isExpanded,
  toggleAccordion,
}) => (
  <div className={
    clsx(
      className,
      "rounded-lg p-3",
      hasBorder && "border border-lightgray-3",
    )
  }
  >
    <Header
      className={headerClassName}
      isDisabled={isDisabled}
      isExpanded={isExpanded}
      headerColor={headerColor}
      toggleAccordion={toggleAccordion}
    >
      { header }
    </Header>
    <Content isExpanded={isExpanded}>
      { content }
    </Content>
  </div>
)

Accordion.propTypes = {
  className: types.string,
  headerClassName: types.string,
  headerColor: types.string,
  header: types.node.isRequired,
  content: types.node.isRequired,
  isDisabled: types.bool,
  hasBorder: types.bool,
  isExpanded: types.bool.isRequired,
  toggleAccordion: types.func.isRequired,
}

export default Accordion
