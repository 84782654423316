import React from "react"
import types from "prop-types"
import { FormsyTextInput } from "shared/inputs"

const ExternalIdentifier = ({ department = undefined }) => (
  <>
    <label htmlFor="external_identifier">External Identifier</label>
    <FormsyTextInput
      className="bordered-base-input text-sm"
      name="external_identifier"
      placeholder="External Identifier"
      value={department?.externalIdentifier || ""}
      validations={{ matchRegexp: /^[\w-]+$/ }}
      validationErrors={{ matchRegexp: "Invalid characters are present. Only letters, digits, underscores, and dashes are permitted." }}
    />
  </>
)

ExternalIdentifier.propTypes = {
  department: types.object,
}

export default ExternalIdentifier
