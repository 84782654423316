import React from "react"
import types from "prop-types"
import { dynamicRangeAnswersExample, monthRangeAnswerInclusionDescription } from "utils/scheduledReportExportHelpers"

const MonthsAnswerDescription = ({ startDate, dateAnswerPreviousRangeLength = null }) => {
  const monthCount = dateAnswerPreviousRangeLength || 1

  const beginningDate = startDate.subtract(monthCount, "M").startOf("month")
  const endingDate = startDate.subtract(1, "M").endOf("month")

  return (
    <div className="text-xs text-dark mt-2">
      <p className="mb-4">
        {monthRangeAnswerInclusionDescription}
      </p>
      <p>
        {dynamicRangeAnswersExample({ startDate, beginningDate, endingDate })}
      </p>
    </div>
  )
}

MonthsAnswerDescription.propTypes = {
  startDate: types.object.isRequired,
  dateAnswerPreviousRangeLength: types.number,
}

export default MonthsAnswerDescription
