import React from "react"
import { SolutionLevelScheduledReportExportContextProvider } from "contexts/SolutionLevelScheduledReportExportContext"
import { reportExportSolutionLevelReportShape } from "utils/propTypeShapes"
import NewSolutionLevelScheduledReportExportForm from "./NewSolutionLevelScheduledReportExportForm"

const NewSolutionLevelScheduledReportExport = ({ solutionLevelReport }) => (
  <SolutionLevelScheduledReportExportContextProvider solutionLevelReport={solutionLevelReport}>
    <NewSolutionLevelScheduledReportExportForm />
  </SolutionLevelScheduledReportExportContextProvider>
)

NewSolutionLevelScheduledReportExport.propTypes = {
  solutionLevelReport: reportExportSolutionLevelReportShape.isRequired,
}

export default NewSolutionLevelScheduledReportExport
