import React, { useCallback, useState } from "react"
import Formsy from "formsy-react"
import { useModal } from "hooks"
import * as API from "services/api"
import { useSurveyConfigurationContext } from "contexts/SurveyConfigurationContext"
import { PrimaryButton, Button } from "shared/buttons"
import { XIcon } from "shared/icons"
import { FormsyTextInput } from "shared/inputs"
import Modal from "shared/Modal"
import { successToast, errorToast } from "shared/toast"

const CreateContactForSurveyConfiguration = () => {
  const { modalIsOpen, openModal, closeModal } = useModal()
  const { addSurveyConfigurationContacts, surveyConfiguration } = useSurveyConfigurationContext()
  const { id: surveyConfigurationId } = surveyConfiguration
  const [isFormValid, setIsFormValid] = useState(false)

  const close = useCallback(() => {
    closeModal()
    setIsFormValid(false)
  }, [closeModal, setIsFormValid])

  const enableSubmit = () => setIsFormValid(true)
  const disableSubmit = () => setIsFormValid(false)

  const onSubmit = async (model) => {
    const response = await API.createSurveyConfigurationContact({ surveyConfigurationId, contact: model })

    if (response.ok) {
      successToast("Contact created successfully!")
      closeModal()
      addSurveyConfigurationContacts([response.data])
    } else {
      console.error("Error creating contact: ", response)
      errorToast("Something went wrong. Unable to create contact.", response)
    }
  }

  return (
    <>
      <div>
        <Button
          text="+ Add Contact"
          onClick={openModal}
          className="text-focus border border-light my-4"
        />
      </div>

      <Modal isOpen={modalIsOpen}>
        <div className="text-center text-xl font-semibold mb-8 relative">
          New Contact
          <XIcon
            className="absolute -top-4 right-4 rounded-full bg-primary-light p-2 h-8 w-8"
            onClick={closeModal}
          />
        </div>
        <Formsy
          onSubmit={onSubmit}
          onValid={enableSubmit}
          onInvalid={disableSubmit}
        >
          <div className="w-3/4 mx-auto">
            <div className="flex-center">
              <div className="mr-3">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>First Name</label>
                <FormsyTextInput
                  className="mb-3 mt-1 bordered-base-input text-sm"
                  name="first_name"
                  placeholder="First Name"
                  value=""
                />
              </div>
              <div className="mr-3">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>Last Name</label>
                <FormsyTextInput
                  className="mb-3 mt-1 bordered-base-input text-sm"
                  name="last_name"
                  placeholder="Last Name"
                  value=""
                />
              </div>
            </div>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="required-prompt">Email</label>
            <FormsyTextInput
              className="mb-3 mt-1 bordered-base-input text-sm"
              name="email"
              placeholder="Email"
              value=""
              required
              validations={{ isNotBlankString: true, isEmail: true }}
              validationErrors={{ isDefaultRequiredValue: "required", isEmail: "Invalid email address" }}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Reminder Email Cc:</label>
            <FormsyTextInput
              className="mb-3 mt-1 bordered-base-input text-sm"
              name="reminder_cc_email"
              placeholder="cc:"
              value=""
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Comment</label>
            <FormsyTextInput
              className="mb-3 mt-1 bordered-base-input text-sm"
              name="comment"
              placeholder="location, note, etc."
              value=""
            />
            <div className="text-xs">
              Note: The new contact will be included in future batches but will not be added to any existing batches.
            </div>
            <div className="flex justify-center mt-8">
              <Button
                text="Cancel"
                onClick={close}
                className="border border-light-300 mr-2"
              />
              <PrimaryButton
                text="Add"
                type="submit"
                disabled={!isFormValid}
              />
            </div>
          </div>
        </Formsy>
      </Modal>
    </>
  )
}

export default CreateContactForSurveyConfiguration
