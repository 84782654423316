import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getFormGrouping, setSelectedGrouping } from "reduxSlices/formIndexSlice"
import { RadioButton } from "shared/inputs"
import { SidebarCollapsibleContent, SidebarContentItem } from "shared/Sidebar"
import { GROUPABLE_COLUMNS } from "../formGroupingHelper"

const FormIndexSidebarGroupBySection = () => {
  const dispatch = useDispatch()

  const { selectedGrouping } = useSelector(getFormGrouping)
  const groupByColumnSelect = (column) => () => dispatch(setSelectedGrouping(column))

  return (
    <SidebarCollapsibleContent title="Group By">
      {
        Object.entries(GROUPABLE_COLUMNS).map(([, { label, value }]) => (
          <SidebarContentItem key={value} onClickCapture={groupByColumnSelect(value)}>
            <RadioButton
              className="flex-row-reverse justify-between"
              label={label}
              value={value}
              onChange={groupByColumnSelect(value)}
              checked={selectedGrouping === value}
            />
          </SidebarContentItem>
        ))
      }
    </SidebarCollapsibleContent>
  )
}

export default FormIndexSidebarGroupBySection
