import React from "react"
import types from "prop-types"
import UserInfo from "shared/UserInfo"
import ItemRow from "shared/ItemRow"

const GroupSearchGroupMemberList = ({ className = "", members }) => {
  if (!members.length) {
    return <span className="text-sm text-dark">No Members</span>
  }

  return (
    <div className={className}>
      <span className="text-sm text-dark inline-block mb-1">Members</span>
      {
        members.map((member) => (
          <ItemRow
            key={member.id}
            className="last:mb-0"
            itemRowHeaderContent={<UserInfo user={member} />}
          />
        ))
      }
    </div>
  )
}

GroupSearchGroupMemberList.propTypes = {
  className: types.string,
  members: types.arrayOf(types.object).isRequired,
}

export default GroupSearchGroupMemberList
