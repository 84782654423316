import React from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { getFormSectionName, setFormValueByPath } from "reduxSlices/formBuilderSlice"
import { TextInput } from "shared/inputs"

const SectionName = ({ sectionIndex }) => {
  const dispatch = useDispatch()

  const sectionName = useSelector(getFormSectionName(sectionIndex))

  const updateSectionName = (newValue) => dispatch(setFormValueByPath({
    path: `sections[${sectionIndex}].name`,
    value: newValue,
  }))

  return (
    <TextInput
      className="!text-4xl"
      placeholder="Section Name"
      value={sectionName}
      onChange={updateSectionName}
    />
  )
}

SectionName.propTypes = {
  sectionIndex: types.number.isRequired,
}

export default SectionName
