import React, { useMemo, useState } from "react"
import types from "prop-types"
import activeBeforeInactive from "utils/activeBeforeInactive"
import CreateFacilityButtons from "./CreateFacilityButtons"
import FacilityInfo from "./FacilityInfo"
import FacilityMenu from "./FacilityMenu"
import EmptyFacilities from "./EmptyFacilities"
import FacilityActiveStatusFilter, {
  ACTIVE_STATUS,
  INACTIVE_STATUS,
} from "./FacilityActiveStatusFilter"

const checkIfActive = (facility, activeStatusFilter) => {
  if (activeStatusFilter === ACTIVE_STATUS) {
    return facility.active
  }

  if (activeStatusFilter === INACTIVE_STATUS) {
    return !facility.active
  }

  return true
}

const FacilityList = ({ facilities }) => {
  const [activeStatusFilter, setActiveStatusFilter] = useState(ACTIVE_STATUS)

  const filteredFacilities = useMemo(() => {
    const filtered = facilities.filter((facility) => (
      checkIfActive(facility, activeStatusFilter)
    ))

    return filtered.sort(activeBeforeInactive)
  }, [activeStatusFilter, facilities])

  if (!facilities.length) return <EmptyFacilities />

  return (
    <>
      <div className="flex items-center">
        <CreateFacilityButtons />
        <div className="flex ml-auto">
          <FacilityActiveStatusFilter
            onChange={setActiveStatusFilter}
            value={activeStatusFilter}
          />
        </div>
      </div>
      {
        filteredFacilities.map((facility) => (
          <div key={facility.id} className="list-row flex-center">
            <FacilityInfo facility={facility} />
            <FacilityMenu facility={facility} />
          </div>
        ))
      }
    </>
  )
}

FacilityList.propTypes = {
  facilities: types.arrayOf(types.object).isRequired,
}

export default FacilityList
