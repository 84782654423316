// NOTE: changing these will break existing forms
export const BLANK = "is blank"
export const NOT_BLANK = "not blank"
export const IS = "is"
export const HAS_SELECTION = "has selection"
export const INCLUDES_ANY_OF = "includes any of"
export const NO_SELECTION = "no selection"
export const LESS_THAN = "less than"
export const GREATER_THAN = "greater than"
export const HAS_DEPARTMENT = "has department"
export const NO_DEPARTMENT = "no department"
export const IS_FACILITY = "is facility"
export const IS_NOT_FACILITY = "is not facility"
export const IS_ANY_OF_FACILITIES = "is any of facilities"
export const IS_DEPARTMENT = "is department"
export const IS_ANY_OF_DEPARTMENTS = "is any of departments"

export const ALL = "ALL"
export const ANY = "ANY"
export const CONTROLS = [ALL, ANY]
export const DEFAULT_CONTROL = ANY

const OPERATORS_REQUIRING_A_MATCH_VALUE = [
  IS,
  LESS_THAN,
  GREATER_THAN,
  INCLUDES_ANY_OF,
  IS_FACILITY,
  IS_NOT_FACILITY,
  IS_ANY_OF_FACILITIES,
  IS_DEPARTMENT,
  IS_ANY_OF_DEPARTMENTS,
]

export const operatorRequiresMatchValue = (operator) => (
  OPERATORS_REQUIRING_A_MATCH_VALUE.includes(operator)
)

const isBlankMatcher = (condition, formAnswers) => {
  const answer = formAnswers[condition.questionUuid]

  if (answer === undefined) {
    return false
  }

  if (answer === null) {
    return true
  }

  return answer === ""
}

const isNotBlankMatcher = (condition, formAnswers) => {
  const answer = formAnswers[condition.questionUuid]

  if (answer === undefined) {
    return false
  }

  if (answer === null) {
    return false
  }

  return answer !== ""
}

const isMatcher = (condition, formAnswers) => {
  const answer = formAnswers[condition.questionUuid]

  // eslint-disable-next-line eqeqeq
  return answer == condition.matchValue
}

const includesAnyMatcher = (condition, formAnswers) => {
  const answer = formAnswers[condition.questionUuid]

  if (answer === undefined) {
    return false
  }

  if (answer === null) {
    return false
  }

  // Checks if one of the answer is in the matchValue array
  return condition.matchValue?.some((value) => answer.includes(value))
}

const lessThanMatcher = (condition, formAnswers) => {
  const answer = formAnswers[condition.questionUuid]

  return parseFloat(answer) < parseFloat(condition.matchValue)
}

const greaterThanMatcher = (condition, formAnswers) => {
  const answer = formAnswers[condition.questionUuid]

  return parseFloat(answer) > parseFloat(condition.matchValue)
}

const hasDepartmentMatcher = (condition, formAnswers) => {
  const answer = formAnswers[condition.questionUuid]

  return Boolean(answer?.department_id)
}

const noDepartmentMatcher = (condition, formAnswers) => (
  !hasDepartmentMatcher(condition, formAnswers)
)

const isFacilityMatcher = (condition, formAnswers, operatorData) => {
  const answer = formAnswers[condition.questionUuid]

  return condition.matchValue === operatorData.departmentFacilityMapping[answer?.department_id]
}

const isNotFacilityMatcher = (condition, formAnswers, operatorData) => {
  const answer = formAnswers[condition.questionUuid]

  if (!answer?.department_id) return false

  return !isFacilityMatcher(condition, formAnswers, operatorData)
}

const isAnyOfFacilitiesMatcher = (condition, formAnswers, operatorData) => {
  const answer = formAnswers[condition.questionUuid]

  return condition.matchValue.includes(operatorData.departmentFacilityMapping[answer?.department_id])
}

const isDepartmentMatcher = (condition, formAnswers) => {
  const answer = formAnswers[condition.questionUuid]

  return condition.matchValue === answer?.department_id
}

// Condition match value should be an object with two properties:
// "facilityIds" and "departmentIds".  We use the list of department ids
// to determine if the condition has been met.  The list of facility ids
// is used in the form builder for scoping selectable departments.
const isAnyOfDepartmentsMatcher = (condition, formAnswers) => {
  const answer = formAnswers[condition.questionUuid]
  const { departmentIds: departmentIdMatchValues } = condition.matchValue

  return departmentIdMatchValues.includes(answer?.department_id)
}

export const ConditionMatchers = {
  [BLANK]: isBlankMatcher,
  [NOT_BLANK]: isNotBlankMatcher,
  [IS]: isMatcher,
  [HAS_SELECTION]: isNotBlankMatcher,
  [INCLUDES_ANY_OF]: includesAnyMatcher,
  [NO_SELECTION]: isBlankMatcher,
  [LESS_THAN]: lessThanMatcher,
  [GREATER_THAN]: greaterThanMatcher,
  [HAS_DEPARTMENT]: hasDepartmentMatcher,
  [NO_DEPARTMENT]: noDepartmentMatcher,
  [IS_FACILITY]: isFacilityMatcher,
  [IS_NOT_FACILITY]: isNotFacilityMatcher,
  [IS_ANY_OF_FACILITIES]: isAnyOfFacilitiesMatcher,
  [IS_DEPARTMENT]: isDepartmentMatcher,
  [IS_ANY_OF_DEPARTMENTS]: isAnyOfDepartmentsMatcher,
}

const isConditionMet = (condition, formAnswers, operatorData) => {
  const matcher = ConditionMatchers[condition.operator]
  return matcher(condition, formAnswers, operatorData)
}

export const evaluateBranchingConditionals = ({ conditionals = null, formAnswers, operatorData }) => {
  // If there are no question branching conditions, then the question/section should show
  if (!conditionals) return true

  const { control, conditions } = conditionals

  // When a question/section had question branching conditions
  // but then had them all removed, the conditionals object remains,
  // but it has no control and the conditions list is empty
  if (!control || conditions?.length === 0) return true

  const checkCondition = (condition) => isConditionMet(condition, formAnswers, operatorData)

  if (control === ALL) {
    return conditions.every(checkCondition)
  }

  if (control === ANY) {
    return conditions.some(checkCondition)
  }

  return false
}

export const evaluateSectionBranchingConditionals = ({ formAnswers, operatorData, section }) => {
  const sectionConditionsMet = evaluateBranchingConditionals({
    conditionals: section.conditionals,
    formAnswers,
    operatorData,
  })

  if (!sectionConditionsMet) return false

  return section.questions.some((question) => (
    evaluateBranchingConditionals({
      conditionals: question.conditionals,
      formAnswers,
      operatorData,
    })
  ))
}

export const uniqueConditionOperators = (branchable) => {
  const operators = new Set()

  branchable.conditionals?.conditions?.forEach((condition) => {
    operators.add(condition.operator)
  })

  return Array.from(operators)
}

export const uniqueConditionalOperatorsOnSection = ({ section }) => {
  const operators = new Set()

  const addOperators = (branchable) => (
    uniqueConditionOperators(branchable).forEach((operator) => {
      operators.add(operator)
    })
  )

  addOperators(section)
  section.questions?.forEach(addOperators)

  return Array.from(operators)
}

export const uniqueConditionOperatorsOnWholeForm = ({ form }) => {
  if (!form) return []

  const operators = new Set()

  form.sections.forEach((section) => {
    uniqueConditionalOperatorsOnSection({ section }).forEach((operator) => {
      operators.add(operator)
    })
  })

  return Array.from(operators)
}
