/* eslint-disable react/prop-types, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React from "react"
import types from "prop-types"
import { TaskGeneralIcon, TaskSubformIcon } from "shared/icons"
import { GENERAL_TASK_SUBFORM_ID } from "utils/taskHelpers"
import MatchedOptionText from "shared/Options/Option/MatchedOptionText"

const TaskOption = ({ renderIcon, option, selectOption }) => (
  <div className="px-3 py-1 hover:bg-dropdownHover" onClick={() => selectOption(option)}>
    <div className="flex items-center">
      { renderIcon() }
      <MatchedOptionText value={option.label} search="" />
    </div>
  </div>
)

TaskOption.propTypes = {
  renderIcon: types.func.isRequired,
  option: types.shape({
    label: types.string.isRequired,
    value: types.oneOfType([types.string, types.number]).isRequired,
  }).isRequired,
  selectOption: types.func.isRequired,
}

const GeneralTaskOption = ({ option, selectOption }) => (
  <TaskOption
    renderIcon={() => <TaskGeneralIcon className="mr-2 h-5 w-5" />}
    option={option}
    selectOption={selectOption}
  />
)

GeneralTaskOption.propTypes = {
  option: types.shape({
    label: types.string.isRequired,
    value: types.oneOfType([types.string, types.number]).isRequired,
  }).isRequired,
  selectOption: types.func.isRequired,
}

const SubformOption = ({ option, selectOption }) => (
  <TaskOption
    renderIcon={() => <TaskSubformIcon className="mr-2 h-5 w-5" />}
    option={option}
    selectOption={selectOption}
  />
)

SubformOption.propTypes = {
  option: types.shape({
    label: types.string.isRequired,
    value: types.oneOfType([types.string, types.number]).isRequired,
  }).isRequired,
  selectOption: types.func.isRequired,
}

// eslint-disable-next-line react/display-name
const SubformDropdown = (props) => {
  const {
    methods: { addItem },
    props: { options },
  } = props

  const subformOptions = [...options]
  let generalTaskOption

  const generalTaskOptionIndex = subformOptions.findIndex((option) => option.value === GENERAL_TASK_SUBFORM_ID)

  if (generalTaskOptionIndex !== -1) {
    [generalTaskOption] = subformOptions.splice(generalTaskOptionIndex, 1)
  }

  return (
    <>
      {
        generalTaskOption && (
          <div className="mb-2">
            <GeneralTaskOption option={generalTaskOption} selectOption={addItem} />
          </div>
        )
      }
      <div className="px-3 font-semibold text-gray-800 uppercase">Subforms</div>
      {
        subformOptions.map((option) => (
          <SubformOption
            key={option.value}
            option={option}
            selectOption={addItem}
          />
        ))
      }
    </>
  )
}

export default SubformDropdown
