import React from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import {
  getFormQuestionAttributeIsDropdown, getFormQuestionAttributeMax, getFormQuestionAttributeMin, setQuestionAttributeMax,
} from "reduxSlices/formBuilderSlice"
import FormsyValidation from "shared/FormsyValidation"
import { NumberInput } from "shared/inputs"

const oneAbove = (number) => {
  if (!number && number !== 0) return number

  return number + 1
}

const Max = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const questionMax = useSelector(getFormQuestionAttributeMax({ sectionIndex, questionIndex }))
  const questionMin = useSelector(getFormQuestionAttributeMin({ sectionIndex, questionIndex }))
  const questionIsDropdown = useSelector(getFormQuestionAttributeIsDropdown({ sectionIndex, questionIndex }))

  const updateMax = (newMax) => dispatch(setQuestionAttributeMax({
    sectionIndex, questionIndex, newMax,
  }))

  const isFieldMinimumDefined = Boolean(questionMin || questionMin === 0)
  const lowestAllowableFieldMaximum = isFieldMinimumDefined ? questionMin + 1 : 1

  return (
    <div className="mb-4 flex-center">
      <div className="font-medium">Max</div>
      <div className="w-2/5">
        <NumberInput
          className="bordered-base-input h-8 text-sm"
          value={questionMax}
          min={oneAbove(questionMin)}
          placeholder="Max"
          onChange={updateMax}
        />
        <FormsyValidation
          name={`sections[${sectionIndex}].questions[${questionIndex}].attributes.max`}
          required={questionIsDropdown}
          value={questionMax}
          validations={{
            isNumberGreaterThanOrEqualTo: {
              min: lowestAllowableFieldMaximum,
              errorMessage: `Must be at least ${lowestAllowableFieldMaximum}`,
            },
          }}
          validationErrors={{
            isDefaultRequiredValue: "Max required",
          }}
        />
      </div>
    </div>
  )
}

Max.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default Max
