import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { CheckmarkCircleIcon, XIcon } from "shared/icons"

const PasswordRequirement = ({ children = null, label, requirementMet }) => (
  <div>
    <div className={clsx("flex items-center", children && "mb-1")}>
      <div className="min-w-8">
        {
          requirementMet
            ? <CheckmarkCircleIcon className="w-4 h-4" />
            : <XIcon className="w-4 h-4 stroke-error" />
        }
      </div>
      <div className="grow">
        {label}
      </div>
    </div>
    {
      children && (
        <div className="ml-8">
          {children}
        </div>
      )
    }
  </div>
)

PasswordRequirement.propTypes = {
  children: types.node,
  label: types.string.isRequired,
  requirementMet: types.bool.isRequired,
}

export default PasswordRequirement
