import React from "react"
import types from "prop-types"
import NoBlankQuestionsValidation from "./QuestionsValidations"
import EndTimeQuestionUuid from "./EndTimeQuestionUuid"
import StartTimeQuestionUuid from "./StartTimeQuestionUuid"

const Questions = ({ sectionIndex, questionIndex }) => (
  <>
    <div className="mb-1 font-medium">
      Questions
    </div>
    <p className="text-sm mb-2">Select the questions that will represent the start and end times of the interval.</p>
    <div className="mb-2">
      <StartTimeQuestionUuid sectionIndex={sectionIndex} questionIndex={questionIndex} />
    </div>
    <EndTimeQuestionUuid sectionIndex={sectionIndex} questionIndex={questionIndex} />
    <NoBlankQuestionsValidation
      questionIndex={questionIndex}
      sectionIndex={sectionIndex}
    />
  </>
)

Questions.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default Questions
