import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import * as API from "services/api"
import AddQuestionTag from "./AddQuestionTag"
import QuestionTag from "./QuestionTag"

const listTagNames = ({ tags }) => tags.map((tag) => tag.name)

const QuestionTagList = () => {
  const [tags, setTags] = useState([])

  useEffect(() => {
    const setCurrentTags = async () => {
      const response = await API.questionTags()
      const currentTags = response.data
      setTags(currentTags)
    }

    setCurrentTags()
  }, [])

  const addQuestionTag = useCallback((tag) => {
    setTags([...tags, tag])
  }, [tags, setTags])

  const tagNames = useMemo(() => listTagNames({ tags }), [tags])

  return (
    <>
      <ul className="list-none pl-0 mb-8">
        {
          tags.map((tag) => (
            <li key={tag.id}>
              <QuestionTag tag={tag} />
            </li>
          ))
        }
      </ul>
      <AddQuestionTag addQuestionTag={addQuestionTag} tagNames={tagNames} />
    </>
  )
}

export default QuestionTagList
