import { useContext } from "react"
import FileUploadContext from "../FileUploadContext"

const useFileUploadContext = () => {
  const providedValue = useContext(FileUploadContext)

  return { ...providedValue }
}

export default useFileUploadContext
