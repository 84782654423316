import React from "react"
import types from "prop-types"
import { withFormsy } from "formsy-react"
import InputErrorMessage from "shared/InputErrorMessage"

const DEFAULT_VALUE = ""

const HiddenInput = ({
  errorMessages = [],
  isPristine = true,
  name = "",
  showErrors = false,
  value = DEFAULT_VALUE,
}) => (
  <>
    <input name={name} type="hidden" value={value || DEFAULT_VALUE} />
    {
      showErrors && (
        <InputErrorMessage isPristine={isPristine} errorMessages={errorMessages} />
      )
    }
  </>
)

HiddenInput.propTypes = {
  errorMessages: types.arrayOf(types.string),
  isPristine: types.bool,
  name: types.string,
  showErrors: types.bool,
  // eslint-disable-next-line react/forbid-prop-types
  value: types.any,
}

export const FormsyHiddenInput = withFormsy(HiddenInput)

export default HiddenInput
