import React from "react"
import types from "prop-types"
import { SidebarCollapsibleContent } from "shared/Sidebar"
import AttachmentsList from "./AttachmentsList"

const AttachmentTypeFilter = ({ attachments }) => (
  <SidebarCollapsibleContent title="Filter by File Type">
    <AttachmentsList attachments={attachments} />
  </SidebarCollapsibleContent>
)

AttachmentTypeFilter.propTypes = {
  attachments: types.arrayOf(types.object).isRequired,
}

export default AttachmentTypeFilter
