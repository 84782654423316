import React from "react"
import types from "prop-types"
import { SearchSelectedUser } from "shared/searches"

const UserFormBuilderList = ({ users = [], deleteCategoryAbility }) => {
  if (!users.length) {
    return <div>No Form Builder Users</div>
  }

  return (
    users.map(({ abilityId, grantee }) => (
      <SearchSelectedUser
        key={grantee.id}
        user={grantee}
        deselect={deleteCategoryAbility(abilityId)}
      />
    ))
  )
}

UserFormBuilderList.propTypes = {
  users: types.arrayOf(types.object),
  deleteCategoryAbility: types.func.isRequired,
}

export default UserFormBuilderList
