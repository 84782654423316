import React from "react"
import types from "prop-types"
import { auditShape } from "utils/propTypeShapes"
import AuditItem from "./AuditItem"

const AuditList = ({ audits }) => audits.map((audit) => (
  <AuditItem key={audit.id} audit={audit} />
))

AuditList.propTypes = {
  audits: types.arrayOf(auditShape).isRequired,
}

export default AuditList
