import React, { createRef } from "react"
import { useSFTPSettingsContext } from "contexts/SFTPSettingsContext"
import Formsy from "formsy-react"
import { useSubmit } from "hooks"
import { PrimaryButton, TertiaryButton } from "shared/buttons"
import { FormsyPasswordInput, FormsyTextInput } from "shared/inputs"
import { successToast, errorToast } from "shared/toast"
import FieldLabel from "shared/CopyFormToOrganization/FieldLabel"
import * as API from "services/api"

const PASSWORD_EXISTS_VALUE = "*******************"

const CredentialsForm = () => {
  const {
    canEditCredentials,
    canViewCredentials,
    sftpRemoteServer,
    setFormIsPristine,
    setSftpRemoteServer,
  } = useSFTPSettingsContext()

  const formRef = createRef()

  const { canSubmit, enableSubmit, disableSubmit } = useSubmit()

  if (!canViewCredentials) {
    return null
  }

  const {
    id: sftpRemoteServerId,
    directory,
    hostname,
    passwordExists,
    username,
  } = sftpRemoteServer

  const passwordPlaceholder = passwordExists ? PASSWORD_EXISTS_VALUE : null

  const onSubmit = async (model) => {
    const passwordUnchanged = passwordExists && passwordPlaceholder === model.password
    const passwordIsToBeDeleted = !model.password && !model.password_confirmation

    if (passwordUnchanged) {
      delete model.password
      delete model.password_confirmation
    } else if (passwordIsToBeDeleted) {
      model.password = null
      delete model.password_confirmation
    }

    // convert empty strings to nulls in request
    Object.keys(model).forEach((key) => {
      if (model[key] === "") {
        model[key] = null
      }
    })

    let response

    if (sftpRemoteServerId) {
      // ensure password/password confirmation inputs get set with placeholder values
      setSftpRemoteServer({ ...sftpRemoteServer, passwordExists: null })

      response = await API.updateSftpRemoteServer({ sftpRemoteServer: model })
    } else {
      response = await API.createSftpRemoteServer({ sftpRemoteServer: model })
    }

    if (response.ok) {
      successToast("SFTP Settings updated successfully!")
      setSftpRemoteServer(response.data)
    } else {
      errorToast("Something went wrong. Unable to save settings.", response)
    }
  }

  const handleNullInputs = (formValues) => {
    Object.keys(formValues).forEach((key) => {
      if (formValues[key] == null) {
        formValues[key] = ""
      }
    })

    return formValues
  }

  const resetFormValues = () => {
    let pristineValues = formRef?.current?.getPristineValues()
    pristineValues = handleNullInputs(pristineValues)

    formRef?.current?.updateInputsWithValue(pristineValues)
  }

  const setIsPristine = () => {
    let pristineValues = formRef?.current?.getPristineValues()
    pristineValues = handleNullInputs(pristineValues)

    let currentValues = formRef?.current?.getCurrentValues()
    currentValues = handleNullInputs(currentValues)

    const isPristine = JSON.stringify(pristineValues) === JSON.stringify(currentValues)

    setFormIsPristine(isPristine)
  }

  return (
    <Formsy
      onSubmit={onSubmit}
      onInvalid={disableSubmit}
      onValid={enableSubmit}
      ref={formRef}
      onChange={setIsPristine}
    >
      <FieldLabel text="Hostname" className="!max-w-full" required boldLabel>
        <FormsyTextInput
          className="bordered-base-input text-sm"
          name="hostname"
          placeholder="mysftp.example.domain"
          value={hostname}
          disabled={!canEditCredentials}
          required
        />
      </FieldLabel>
      <FieldLabel text="Remote Directory" className="!max-w-full" boldLabel>
        <FormsyTextInput
          className="bordered-base-input text-sm"
          placeholder="/path/to/upload/reports"
          name="directory"
          value={directory}
          disabled={!canEditCredentials}
        />
      </FieldLabel>
      <FieldLabel text="Username" className="!max-w-full" boldLabel>
        <FormsyTextInput
          className="bordered-base-input text-sm bg-red"
          name="username"
          placeholder="username"
          disabled={!canEditCredentials}
          value={username}
        />
      </FieldLabel>
      <FieldLabel text="Password" className="!max-w-full" boldLabel>
        <FormsyPasswordInput
          className="bordered-base-input text-sm"
          name="password"
          placeholder="password"
          value={passwordPlaceholder}
          disabled={!canEditCredentials}
        />
      </FieldLabel>
      <FieldLabel text="Confirm Password" className="!max-w-full" boldLabel>
        <FormsyPasswordInput
          className="bordered-base-input text-sm"
          name="password_confirmation"
          placeholder="password confirmation"
          validationErrors={{ equalsField: "Password confirmation does not match" }}
          validations="equalsField:password"
          value={passwordPlaceholder}
          disabled={!canEditCredentials}
        />
      </FieldLabel>
      {/* <FieldLabel text="Public Key" className="!max-w-full" boldLabel>
        <FormsyMultilineTextInput
          className="bordered-base-input text-sm"
          name="public_key"
          value={publicKeyPlaceholder}
          disabled={true} // Note: this field will be read-only when implemented
        />
      </FieldLabel> */}
      <div className="flex justify-center mt-8 gap-4">
        <TertiaryButton
          disabled={!canEditCredentials}
          text="Reset"
          onClick={resetFormValues}
        />
        <PrimaryButton
          disabled={!canEditCredentials || !canSubmit}
          text="Save Changes"
          type="submit"
        />
      </div>
    </Formsy>
  )
}

export default CredentialsForm
