import React from "react"
import types from "prop-types"
import clsx from "clsx"

const DEFAULT_CLASSES = "flex justify-end px-4 pt-3 pb-4 min-h-7"

const TileHeader = ({ children, className = "" }) => (
  <div className={clsx(className, DEFAULT_CLASSES)}>
    {children}
  </div>
)

TileHeader.propTypes = {
  children: types.node.isRequired,
  className: types.string,
}

export default TileHeader
