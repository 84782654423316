import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { withFormsy } from "formsy-react"
import { questionShape } from "utils/propTypeShapes"
import { calculate } from "utils/calculationOperators"

const fetchNumber = (formAnswers, uuid) => formAnswers[uuid]

const FormsyDiv = withFormsy(({ name, value, className }) => (
  <div className={clsx("my-1 form-input py-2 px-3 flex items-center", className)} name={name} value={value}>
    { value }
    {/* Keeps the height if there is no value */}
    &nbsp;
  </div>
))

const NumberCalcAnswerField = ({ question, formAnswers, className = "" }) => {
  const { uuid, attributes } = question
  const {
    precision, operator, calcQuestionUuids,
  } = attributes

  const numbers = calcQuestionUuids.map((questionUuid) => fetchNumber(formAnswers, questionUuid))
  const anyNullish = numbers.some((num) => num === undefined || num === null)

  const result = anyNullish ? null : calculate(numbers, operator).toFixed(precision)

  return (
    <FormsyDiv name={uuid} value={result} className={className} />
  )
}

NumberCalcAnswerField.propTypes = {
  question: questionShape.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formAnswers: types.object.isRequired,
  className: types.string,
}

export default NumberCalcAnswerField
