import React, { useState } from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { formSubmissionData } from "reduxSlices/formSubmissionSlice"
import { filteredQuestionList, questionUuidList } from "utils/formDataHelpers"
import { formSubmissionFormShape, formVersionShape } from "utils/propTypeShapes"
import FormSubmissionFormContext from "../FormSubmissionFormContext"

export const CURRENT_VERSION_FOCUS = "current"
export const SUBMITTED_VERSION_FOCUS = "submitted"

const FormSubmissionFormContextProvider = ({
  children,
  currentFormVersion: propCurrentFormVersion,
  form: propForm,
  submittedFormVersion: propSubmittedFormVersion,
}) => {
  const { isClosed } = useSelector(formSubmissionData)

  const [form, setForm] = useState(propForm)

  const [currentFormVersion, setCurrentFormVersion] = useState(propCurrentFormVersion)
  const [submittedFormVersion, setSubmittedFormVersion] = useState(propSubmittedFormVersion)
  const setCurrentFormVersionAsSubmittedVersion = () => setSubmittedFormVersion(currentFormVersion)

  const [formVersionFocus, setFormVersionFocus] = useState(CURRENT_VERSION_FOCUS)
  const setFormVersionFocusToCurrent = () => setFormVersionFocus(CURRENT_VERSION_FOCUS)
  const setFormVersionFocusToSubmitted = () => setFormVersionFocus(SUBMITTED_VERSION_FOCUS)

  const [editMode, setEditMode] = useState(false)
  const closeEditMode = () => setEditMode(false)
  const openEditMode = () => !isClosed && setEditMode(true)

  const currentFormVersionIsTheSubmittedFormVersion = currentFormVersion.id === submittedFormVersion.id

  const submittedFormVersionInFocus = formVersionFocus === SUBMITTED_VERSION_FOCUS

  const inFocusFormVersion = submittedFormVersionInFocus ? submittedFormVersion : currentFormVersion

  const deletedQuestions = submittedFormVersionInFocus
    ? []
    : filteredQuestionList(submittedFormVersion, { exclude: questionUuidList(currentFormVersion) })

  const newQuestions = submittedFormVersionInFocus
    ? []
    : filteredQuestionList(currentFormVersion, { exclude: questionUuidList(submittedFormVersion) })

  // Object exposed to context consumers
  const contextConsumerValue = {
    closeEditMode,
    currentFormVersion,
    currentFormVersionIsTheSubmittedFormVersion,
    deletedQuestions,
    editMode,
    form,
    formVersionFocus,
    inFocusFormVersion,
    newQuestions,
    openEditMode,
    setCurrentFormVersion,
    setCurrentFormVersionAsSubmittedVersion,
    setForm,
    setFormVersionFocusToCurrent,
    setFormVersionFocusToSubmitted,
    setSubmittedFormVersion,
  }

  return (
    <FormSubmissionFormContext.Provider value={contextConsumerValue}>
      {children}
    </FormSubmissionFormContext.Provider>
  )
}

FormSubmissionFormContextProvider.propTypes = {
  children: types.node.isRequired,
  currentFormVersion: formVersionShape.isRequired,
  form: formSubmissionFormShape.isRequired,
  submittedFormVersion: formVersionShape.isRequired,
}

export default FormSubmissionFormContextProvider
