import React from "react"
import { surveyConfigurationForExportReportShape } from "utils/propTypeShapes"
import { useSurveyReportExportContext } from "contexts/SurveyReportExportContext"
import MassSelection from "shared/MassSelection"
import SurveyBatchFilters from "./SurveyBatchFilters"

const SurveyConfigurationFilters = ({ surveyConfiguration }) => {
  const {
    addAllSurveyBatchesForSurveyConfiguration,
    removeAllSurveyBatchesFromSurveyConfiguration,
  } = useSurveyReportExportContext()

  const { id, surveyBatches, name } = surveyConfiguration

  return (
    <div className="border border-lightgray-3 rounded p-4 mb-4">
      <span className="mb-4 font-semibold">{name}</span>
      <MassSelection
        className="mb-4"
        deselectAll={() => removeAllSurveyBatchesFromSurveyConfiguration(id)}
        selectAll={() => addAllSurveyBatchesForSurveyConfiguration(id)}
      />
      {surveyBatches.map((batch) => (
        <SurveyBatchFilters surveyBatch={batch} key={batch.id} />
      ))}
    </div>
  )
}

SurveyConfigurationFilters.propTypes = {
  surveyConfiguration: surveyConfigurationForExportReportShape.isRequired,
}

export default SurveyConfigurationFilters
