import React from "react"
import types from "prop-types"
import { configComponent } from "views/Forms/FormElementTypes"
import QuestionSidebarSection from "./QuestionSidebarSection"

const QuestionAttributesSection = ({
  isFocused,
  questionType,
  questionIndex,
  sectionIndex,
}) => {
  const QuestionTypeConfig = configComponent({ questionType })

  return (
    <QuestionSidebarSection hide={!isFocused} title="Field Options" headerClassName="py-2.5 text-dark">
      {
        QuestionTypeConfig && (
          <QuestionTypeConfig
            sectionIndex={sectionIndex}
            questionIndex={questionIndex}
            visible={isFocused}
          />
        )
      }
    </QuestionSidebarSection>
  )
}

QuestionAttributesSection.propTypes = {
  isFocused: types.bool.isRequired,
  questionIndex: types.number.isRequired,
  questionType: types.string.isRequired,
  sectionIndex: types.number.isRequired,
}

export default QuestionAttributesSection
