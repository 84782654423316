import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { XIcon } from "shared/icons"

const RemoveButton = ({ className, onClick, file }) => {
  const removeFileUpload = () => {
    onClick(file)
  }
  const {
    request,
    signedBlobId,
    errors,
  } = file

  if (!request && !signedBlobId && !errors) return null

  return (
    <button
      className={clsx(className, "bg-light rounded-full p-1 outline:none focus:outline-none")}
      onClick={removeFileUpload}
    >
      <XIcon width={12} height={12} />
    </button>
  )
}

RemoveButton.defaultProps = {
  className: undefined,
}

RemoveButton.propTypes = {
  className: types.string,
  file: types.object.isRequired,
  onClick: types.func.isRequired,
}

export default RemoveButton
