import { useEffect } from "react"
import types from "prop-types"
import { store } from "store"
import { setSubscriptions } from "reduxSlices/addOnFeatureSubscriptionsSlice"

const SetupAddOnFeatureSubscriptionsInRedux = ({ subscriptions }) => {
  useEffect(() => {
    store.dispatch(setSubscriptions(subscriptions))
  }, [subscriptions])

  return null
}

SetupAddOnFeatureSubscriptionsInRedux.propTypes = {
  subscriptions: types.object.isRequired,
}

export default SetupAddOnFeatureSubscriptionsInRedux
