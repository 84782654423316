import React from "react"
import types from "prop-types"
import { questionShape } from "utils/propTypeShapes"
import { QuestionSelect } from "shared/selects"

const NumberQuestionSelect = ({
  questions, selectedQuestionUuid, onChange, ...rest
}) => (
  <QuestionSelect
    questions={questions}
    selectedQuestionUuid={selectedQuestionUuid}
    backspaceDelete={false}
    onChange={onChange}
    {...rest}
  />
)

NumberQuestionSelect.propTypes = {
  questions: types.arrayOf(questionShape).isRequired,
  selectedQuestionUuid: types.string.isRequired,
  onChange: types.func.isRequired,
}

export default NumberQuestionSelect
