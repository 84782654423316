import React from "react"
import types from "prop-types"
import { sortBy } from "lodash-es"
import { useSelector } from "react-redux"
import { getUsers } from "reduxSlices/formBuilderSlice"
import { useTaskFormContext } from "contexts/TaskFormContext"
/* eslint-disable max-len */
import TaskUserList from "views/FormSubmission/FormSubmissionTabs/ActivitiesContent/TaskForms/TaskFields/UI/TaskUserList"
/* eslint-enable max-len */

const TaskMemberList = ({ className = "" }) => {
  const users = useSelector(getUsers)

  const { removeMemberId, taskMemberIds } = useTaskFormContext()

  if (taskMemberIds.length === 0) return null

  const memberUsers = sortBy(
    taskMemberIds.map((assigneeId) => (
      users.find((user) => user.id === assigneeId)
    )),
    ["lastName", "firstName"],
  )

  return (
    <TaskUserList className={className} removeUser={removeMemberId} users={memberUsers} />
  )
}

TaskMemberList.propTypes = {
  className: types.string,
}

export default TaskMemberList
