import React from "react"
import types from "prop-types"
import ExpandMenu, { ExpandMenuItem } from "shared/ExpandMenu"
import EllipsisIcon from "shared/icons/EllipsisIcon"
import * as API from "services/api"
import { useDispatch } from "react-redux"
import { removeWorkflow } from "reduxSlices/formBuilderSlice"

const WorkflowMenu = ({ formSlug, workflowId, openForm }) => {
  const dispatch = useDispatch()

  const deleteWorkflow = async () => {
    // eslint-disable-next-line no-alert
    if (window.confirm("Are you sure you want to delete this workflow?")) {
      const response = await API.deleteWorkflow({ formSlug, workflowId })

      if (response.ok) {
        dispatch(removeWorkflow(workflowId))
      } else {
        console.error("Error deleting workflow: ", response)
      }
    }
  }

  return (
    <ExpandMenu expandMenuComponent={<EllipsisIcon />}>
      <ExpandMenuItem onClick={openForm}>
        <span>Edit</span>
      </ExpandMenuItem>
      <ExpandMenuItem onClick={deleteWorkflow}>
        <span>Delete</span>
      </ExpandMenuItem>
    </ExpandMenu>
  )
}

WorkflowMenu.propTypes = {
  formSlug: types.string.isRequired,
  workflowId: types.number.isRequired,
  openForm: types.func.isRequired,
}

export default WorkflowMenu
