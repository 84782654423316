import React from "react"
import { useSelector } from "react-redux"
import { getDeletedQuestions } from "reduxSlices/formBuilderSlice"
import FormVersionDeletedQuestion from "./FormVersionDeletedQuestion"

const DeletedQuestions = () => {
  const deletedQuestions = useSelector(getDeletedQuestions)

  if (!deletedQuestions.length) return null

  return (
    <div className="flex-col mt-5 p-5 w-full mx-auto border border-lightgray-3 rounded bg-white">
      <div className="flex justify-center p-2 border-b border-lightgray-3 mb-5">
        <span>Questions from previous version of form</span>
      </div>
      {
        deletedQuestions.map((question) => (
          <FormVersionDeletedQuestion question={question} key={question.uuid} />
        ))
      }
    </div>

  )
}

export default DeletedQuestions
