import React from "react"
import { isEqual } from "lodash-es"
import { useSelector } from "react-redux"
import { currentUser as getCurrentUser } from "reduxSlices/sessionSlice"
import { ProfileIcon } from "shared/icons"

const PanelTrigger = () => {
  const currentUser = useSelector(getCurrentUser, isEqual)

  return (
    <ProfileIcon className="cursor-pointer w-8 h-8" user={currentUser} />
  )
}

export default PanelTrigger
