import React from "react"
import { isEqual } from "lodash-es"
import { useSelector } from "react-redux"
import { currentUser as getCurrentUser } from "reduxSlices/sessionSlice"
import { ProfileIcon } from "shared/icons"
import Logout from "./Logout"

const CurrentUser = () => {
  const currentUser = useSelector(getCurrentUser, isEqual)

  return (
    <>
      <p className="pb-1 font-bold">My Account</p>
      <div className="flex flex-col gap-3">
        <div className="bg-white rounded-md flex-grow flex items-center gap-1">
          <ProfileIcon className="w-12 h-12 shrink-0" user={currentUser} />
          <div className="flex flex-col p-3 break-all">
            <span>{ currentUser.fullName }</span>
            <span>{ currentUser.email }</span>
          </div>
        </div>
        <Logout />
      </div>
    </>
  )
}

export default CurrentUser
