import React from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { useSelector } from "react-redux"
import { getFormQuestionAttributeCalcQuestionUuids } from "reduxSlices/formBuilderSlice"
import AddQuestionButton from "./AddQuestionButton"
import QuestionSelect from "./QuestionSelect"
import NoBlankQuestionsValidation from "./QuestionsValidations"

const Questions = ({ sectionIndex, questionIndex }) => {
  const questionCalcQuestionUuids = useSelector(getFormQuestionAttributeCalcQuestionUuids({ sectionIndex, questionIndex }), isEqual)

  return (
    <>
      <div className="mb-1 font-medium">
        Questions
      </div>
      <p className="text-sm mb-2">Pick the questions that will be part of the operation.</p>
      {
        questionCalcQuestionUuids.map((questionUuid, index) => (
          <QuestionSelect
            // eslint-disable-next-line react/no-array-index-key
            key={questionUuid || index}
            sectionIndex={sectionIndex}
            questionIndex={questionIndex}
            calcQuestionUuidIndex={index}
          />
        ))
      }
      <AddQuestionButton sectionIndex={sectionIndex} questionIndex={questionIndex} />
      <NoBlankQuestionsValidation
        sectionIndex={sectionIndex}
        questionIndex={questionIndex}
        value={questionCalcQuestionUuids}
      />
    </>
  )
}

Questions.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default Questions
