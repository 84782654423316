import React from "react"
import types from "prop-types"
import { formOptionShape, categoryOptionShape } from "utils/propTypeShapes"
import AuthenticityTokenField from "./AuthenticityTokenField"
import BaseFormIdField from "./BaseFormIdField"
import TargetCategoryIdField from "./TargetCategoryIdField"

const CopyFormToOrganizationFormFields = ({ sourceForms, targetCategories }) => (
  <>
    <AuthenticityTokenField />
    <BaseFormIdField forms={sourceForms} />
    <TargetCategoryIdField categories={targetCategories} />
  </>
)

CopyFormToOrganizationFormFields.propTypes = {
  sourceForms: types.arrayOf(formOptionShape).isRequired,
  targetCategories: types.arrayOf(categoryOptionShape).isRequired,
}

export default CopyFormToOrganizationFormFields
