import React from "react"
import types from "prop-types"
import { useReportContext } from "contexts/ReportContext"
import { CustomHeaderAccordion, useAccordion } from "shared/accordions"
import GroupingAccordionHeader from "shared/headers/GroupingAccordionHeader"
import ReportList from "./ReportList"

const ReportsSection = ({
  editScheduledReportExportPath, newReportExportPath, newScheduledReportExportPath, className = "", title,
}) => {
  const [isExpanded, toggleAccordion] = useAccordion(true)
  const { reports } = useReportContext()

  if (reports.length === 0) return null

  return (
    <section className={className}>
      <CustomHeaderAccordion
        isExpanded={isExpanded}
        header={(
          <GroupingAccordionHeader
            className="mb-4"
            groupName={title}
            isExpanded={isExpanded}
            toggleAccordion={toggleAccordion}
          />
        )}
        content={(
          <ReportList
            newReportExportPath={newReportExportPath}
            newScheduledReportExportPath={newScheduledReportExportPath}
            editScheduledReportExportPath={editScheduledReportExportPath}
          />
        )}
      />
    </section>
  )
}

ReportsSection.propTypes = {
  newReportExportPath: types.func.isRequired,
  newScheduledReportExportPath: types.func.isRequired,
  editScheduledReportExportPath: types.func.isRequired,
  className: types.string,
  title: types.string.isRequired,
}

export default ReportsSection
