import React, { useCallback } from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { excludeFormSlug, includedFormSlugs, includeFormSlug } from "reduxSlices/reportBuilderSlice"
import { Checkbox } from "shared/checkboxes"

const FormFilterOption = ({ form }) => {
  const dispatch = useDispatch()
  const { slug } = form
  const isSelected = useSelector(includedFormSlugs).includes(form.slug)

  const toggleFormSelection = useCallback(() => {
    const toggleFunction = isSelected ? excludeFormSlug : includeFormSlug
    dispatch(toggleFunction(slug))
  }, [dispatch, isSelected, slug])

  const optionIdentifier = `report-builder-form-filter-selector-${slug}`

  return (
    <div className="flex items-center">
      <Checkbox
        className="mr-4"
        onChange={toggleFormSelection}
        uuid={optionIdentifier}
        value={isSelected}
      />
      <label className="cursor-pointer" htmlFor={optionIdentifier}>
        {form.title}
      </label>
    </div>
  )
}

FormFilterOption.propTypes = {
  form: types.shape({
    slug: types.string.isRequired,
    title: types.string.isRequired,
  }).isRequired,
}

export default FormFilterOption
