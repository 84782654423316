import React from "react"
import types from "prop-types"
import * as API from "services/api"
import { useInterval } from "hooks"
import ActivityIndicator from "shared/ActivityIndicator"
import { errorToast } from "shared/toast"

const CONTACTS_UPLOAD_ERROR_MESSAGE = "Something went wrong.  Unable to get upload status."

const ReportExportProcessingCheck = ({
  contactsUploadId, onError, setContactsUpload, surveyConfigurationId,
}) => {
  useInterval(async () => {
    const response = await API.surveyConfigurationContactsUpload({ surveyConfigurationId, contactsUploadId })

    if (response.ok) {
      setContactsUpload(response.data)
    } else {
      errorToast(CONTACTS_UPLOAD_ERROR_MESSAGE)
      onError()
    }
  }, 2000)

  return (
    <div className="flex flex-col items-center">
      <ActivityIndicator className="mb-8" />
      <p className="text-center">
        Your upload is being processed.<br />
        The page will refresh when processing completes.
      </p>
    </div>
  )
}

ReportExportProcessingCheck.propTypes = {
  contactsUploadId: types.number.isRequired,
  onError: types.func.isRequired,
  setContactsUpload: types.func.isRequired,
  surveyConfigurationId: types.number.isRequired,
}

export default React.memo(ReportExportProcessingCheck)
