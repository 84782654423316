import React from "react"
import types from "prop-types"
import { DEFAULT_SELECT_PLACEHOLDER } from "components/shared/selects/Select"

const ContentRenderer = ({ state }) => {
  const { values } = state
  return (
    values.length > 0 ? (
      <div
        className="truncate"
        style={{
          maxWidth: "9rem",
          minWidth: "9rem",
        }}
      >{ values[0].label }
      </div>
    )
      : <div>{DEFAULT_SELECT_PLACEHOLDER}</div>
  )
}

ContentRenderer.propTypes = {
  state: types.shape({
    values: types.arrayOf(types.shape({
      label: types.string,
    })).isRequired,
  }).isRequired,
}

export default ContentRenderer
