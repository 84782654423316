import React from "react"
import types from "prop-types"
import { adminUserShowDepartmentMembership } from "utils/propTypeShapes"
import { useSCIMResourceManagement } from "hooks"
import * as API from "services/api"
import { DepartmentMembershipSegmentSelect } from "shared/selects"
import { RemoveButton } from "shared/buttons"
import { errorToast, successToast } from "components/shared/toast"

const DepartmentSpan = ({
  departmentMembership, user, removeDepartmentMembership, updateDepartmentMembershipSegment,
}) => {
  const { departmentsManagedBySCIM } = useSCIMResourceManagement()

  if (!departmentMembership) return null

  const { segment, department = {} } = departmentMembership
  const { name: departmentName, id: departmentId, facilityId } = department
  const { id: memberId } = user

  const removeMembership = async () => {
    const response = await API.deleteDepartmentMembership({ facilityId, departmentId, memberId })

    if (response.ok) {
      removeDepartmentMembership(departmentMembership)
      successToast("User successfully removed from department.")
    } else {
      errorToast("Something went wrong. Unable to remove user from department.")
    }
  }

  const updateSegment = async (newSegment) => {
    const response = await API.updateDepartmentMemberSegment({
      facilityId, departmentId, memberId, segmentName: newSegment,
    })

    if (response.ok) {
      updateDepartmentMembershipSegment({
        ...departmentMembership,
        segment: newSegment,
      })
      successToast("Department segment successfully updated")
    } else {
      errorToast("Something went wrong.  Unable to change segment.")
      // Removing then adding back original value to force the re-render and
      // revert input value to original state if we get an error response.
      removeDepartmentMembership(departmentMembership)
      updateDepartmentMembershipSegment(departmentMembership)
    }
  }

  return (
    <div className="flex-grow mb-1 list-row border-0 py-3 bg-light-100">
      <div className="flex gap-4 items-center text-sm font-medium w-full">
        <span className="flex-grow">{departmentName}</span>
        <DepartmentMembershipSegmentSelect onChange={updateSegment} value={segment} />
        {
          !departmentsManagedBySCIM && (
            <RemoveButton onClick={removeMembership} />
          )
        }
      </div>
    </div>
  )
}

DepartmentSpan.propTypes = {
  departmentMembership: adminUserShowDepartmentMembership.isRequired,
  user: types.shape({
    id: types.number,
  }).isRequired,
  removeDepartmentMembership: types.func.isRequired,
  updateDepartmentMembershipSegment: types.func.isRequired,
}

export default DepartmentSpan
