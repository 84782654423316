import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { activityHubSubmissionPath } from "utils/routeHelpers"
import { capitalize } from "utils/stringHelpers"

const classList = ({ className = "" }) => clsx(
  "text-center rounded-md bg-green-100 text-green-1000 py-1",
  className,
)

const SurveyTokenPublicStatusFormSubmissionLink = ({ className, formSubmissionSlug, publicStatus }) => (
  <a
    className={classList({ className })}
    href={activityHubSubmissionPath(formSubmissionSlug)}
  >
    { capitalize(publicStatus) }
  </a>
)

SurveyTokenPublicStatusFormSubmissionLink.propTypes = {
  className: types.string,
  formSubmissionSlug: types.string.isRequired,
  publicStatus: types.string.isRequired,
}

export default SurveyTokenPublicStatusFormSubmissionLink
