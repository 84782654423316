import React from "react"
import types from "prop-types"
import { Portal as LibraryPortal } from "react-portal"

const Portal = ({ children, targetId }) => (
  <LibraryPortal node={document?.getElementById(targetId)}>
    {children}
  </LibraryPortal>
)

Portal.propTypes = {
  children: types.node.isRequired,
  targetId: types.string.isRequired,
}

export default Portal
