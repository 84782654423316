import React from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import {
  formSubmissionSlug as getFormSubmissionSlug,
  setRelatedFormSubmissions,
} from "reduxSlices/formSubmissionSlice"
import * as API from "services/api"
import { activityHubSubmissionPath, redirectTo } from "utils/routeHelpers"
import { errorToast } from "shared/toast"
import FormIcon from "shared/icons/FormIcon"
import DateDataElement from "shared/Table/TableDataElement/DateDataElement"
import ItemRow from "shared/ItemRow"
import CircularIconButton from "shared/buttons/CircularIconButton"

const RelatedFormSubmission = ({ submission }) => {
  const formSubmissionSlug = useSelector(getFormSubmissionSlug)
  const dispatch = useDispatch()

  const redirectToRelatedFormSubmission = () => redirectTo(activityHubSubmissionPath(submission.slug))

  const deleteFormSubmissionRelationship = async (relatedFormSubmissionId) => {
    const response = await API.deleteFormSubmissionRelationship({
      formSubmissionSlug, relatedFormSubmissionId,
    })

    if (response.ok) {
      dispatch(setRelatedFormSubmissions(response.data))
    } else {
      errorToast(response.data.errors.join(", "))
    }
  }

  return (
    <>
      <ItemRow
        itemRowHeaderContentClassName="item-row-header-content-supplemental-related-form-submission"
        itemRowHeaderContent={(
          <div
            className="cursor-pointer"
            onClickCapture={redirectToRelatedFormSubmission}
          >
            <div>{submission.slug}</div>
            <div className="flex items-center mb-4 lg:mb-0">
              <FormIcon className="search-result-icon mr-2" form={submission.form} />
              <p>{submission.form.title}</p>
            </div>
            <DateDataElement value={submission.createdAt} />
            <div>{submission.statusName}</div>
          </div>
        )}
        itemRowHeaderControls={(
          <CircularIconButton icon="close" onClick={() => deleteFormSubmissionRelationship(submission.id)} />
        )}
      />
    </>
  )
}

RelatedFormSubmission.propTypes = {
  submission: types.object.isRequired,
}

export default RelatedFormSubmission
