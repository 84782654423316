import React from "react"
import types from "prop-types"
import { useTaskFormContext } from "contexts/TaskFormContext"
import { CircularIconButton } from "shared/buttons"
import ItemRow from "shared/ItemRow"
import Switch from "shared/Switch"

const TaskGroup = ({ removeFromTask, group }) => {
  const {
    addNotifyGroupId,
    addNotifyOnCompleteGroupId,
    removeNotifyGroupId,
    removeNotifyOnCompleteGroupId,
    taskNotifyGroupIds,
    taskNotifyOnCompleteGroupIds,
  } = useTaskFormContext()

  const groupNotified = taskNotifyGroupIds.includes(group.id)
  const groupNotifiedOnComplete = taskNotifyOnCompleteGroupIds.includes(group.id)

  const toggleNotifyId = () => {
    const toggleFunction = groupNotified ? removeNotifyGroupId : addNotifyGroupId
    toggleFunction(group.id)
  }

  const toggleNotifyOnCompleteId = () => {
    const toggleFunction = groupNotifiedOnComplete ? removeNotifyOnCompleteGroupId : addNotifyOnCompleteGroupId
    toggleFunction(group.id)
  }

  return (
    <ItemRow
      className="dark"
      itemRowHeaderContent={(
        <>
          <div className="mb-6 lg:mb-0">{group.name}</div>
          <div className="lg:flex lg:items-center item-row-header-content-breaker">
            <div className="flex items-center justify-between lg:mr-11 mb-4 lg:mb-0">
              <span className="text-sm block lg:hidden">Notify on Assign</span>
              <Switch
                checked={groupNotified}
                onChange={toggleNotifyId}
              />
            </div>
            <div className="flex items-center justify-between lg:mr-4">
              <span className="text-sm block lg:hidden">Notify on Complete</span>
              <Switch
                checked={groupNotifiedOnComplete}
                onChange={toggleNotifyOnCompleteId}
              />
            </div>
          </div>
        </>
      )}
      itemRowHeaderControls={(
        <CircularIconButton icon="close" onClick={removeFromTask} />
      )}
    />
  )
}

TaskGroup.propTypes = {
  removeFromTask: types.func.isRequired,
  group: types.shape({
    id: types.number.isRequired,
    name: types.string.isRequired,
  }).isRequired,
}

export default TaskGroup
