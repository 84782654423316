import React, { useEffect } from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { useDispatch, useSelector } from "react-redux"
import {
  addSectionBranchingCondition,
  getFormSectionCondition,
  getFormSectionConditionals,
  getFormSectionBranchingConditionUnusedQuestionOptions,
  removeSectionBranchingConditionalsCondition,
  replaceSectionBranchingConditionWithConditionals,
  updateSectionBranchingConditionalsControl,
  updateSectionBranchingConditionMatchValue,
  updateSectionBranchingConditionOperator,
  updateSectionBranchingConditionQuestionUuid,
} from "reduxSlices/formBuilderSlice"
import Conditions from "FormBuilder/QuestionBranching/Conditions"

const SectionCondition = ({ sectionIndex }) => {
  const dispatch = useDispatch()

  const condition = useSelector(getFormSectionCondition(sectionIndex))
  const sectionConditionals = useSelector(getFormSectionConditionals(sectionIndex), isEqual)
  const unusedQuestionOptions = useSelector(getFormSectionBranchingConditionUnusedQuestionOptions(sectionIndex), isEqual)

  // When the form builder was orginially built, only one condition could be specified.
  // Later, we switched to supporting multiple conditions.  This useEffect
  // callback updates any form versions that still have the old condition
  // with the new conditions.
  useEffect(() => {
    if (condition) {
      dispatch(replaceSectionBranchingConditionWithConditionals({ sectionIndex }))
    }
  }, [condition, dispatch])

  const addBranchingCondition = () => dispatch(addSectionBranchingCondition({ sectionIndex }))

  const removeBranchingConditionals = (conditionIndex) => () => dispatch(removeSectionBranchingConditionalsCondition({
    sectionIndex, conditionIndex,
  }))

  const updateBranchingControl = (newControl) => (
    dispatch(updateSectionBranchingConditionalsControl({ sectionIndex, newControl }))
  )

  const updateBranchingConditionQuestionUuid = (conditionIndex) => (newQuestionUuid) => (
    dispatch(updateSectionBranchingConditionQuestionUuid({ sectionIndex, conditionIndex, newQuestionUuid }))
  )

  const updateBranchingConditionOperator = (conditionIndex) => (newOperator) => (
    dispatch(updateSectionBranchingConditionOperator({ sectionIndex, conditionIndex, newOperator }))
  )

  const updateBranchingConditionMatchValue = (conditionIndex) => (newMatchValue) => (
    dispatch(updateSectionBranchingConditionMatchValue({ sectionIndex, conditionIndex, newMatchValue }))
  )

  return (
    <>
      <div className="font-medium mb-3">
        Show this section if...
      </div>
      <Conditions
        fieldPath={`sections[${sectionIndex}].conditionals`}
        conditionals={sectionConditionals}
        addBranchingConditionalsCondition={addBranchingCondition}
        removeBranchingConditionalsCondition={removeBranchingConditionals}
        unusedQuestionOptions={unusedQuestionOptions}
        updateBranchingConditionalsControl={updateBranchingControl}
        updateBranchingConditionalsConditionMatchValue={updateBranchingConditionMatchValue}
        updateBranchingConditionalsConditionOperator={updateBranchingConditionOperator}
        updateBranchingConditionalsConditionQuestionUuid={updateBranchingConditionQuestionUuid}
      />
    </>
  )
}

SectionCondition.propTypes = {
  sectionIndex: types.number.isRequired,
}

export default SectionCondition
