import React from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { getFormSectionDescription, setFormValueByPath } from "reduxSlices/formBuilderSlice"
import { TextInput } from "shared/inputs"

const SectionDescription = ({ sectionIndex }) => {
  const dispatch = useDispatch()

  const sectionDescription = useSelector(getFormSectionDescription(sectionIndex))

  const updateSectionDescription = (newValue) => dispatch(setFormValueByPath({
    path: `sections[${sectionIndex}].description`,
    value: newValue,
  }))

  return (
    <TextInput
      className="w-full !text-lg"
      placeholder="Section description (optional)"
      value={sectionDescription}
      onChange={updateSectionDescription}
    />
  )
}

SectionDescription.propTypes = {
  sectionIndex: types.number.isRequired,
}

export default SectionDescription
