/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"
import types from "prop-types"
import clsx from "clsx"

const IconTapTarget = ({ children, className = "", onClick = undefined }) => (
  <div
    className={clsx(className, "p-4 h-full cursor-pointer")}
    {...(onClick ? { onClick } : {})}
  >
    {children}
  </div>
)

IconTapTarget.propTypes = {
  children: types.node.isRequired,
  className: types.string,
  onClick: types.func,
}

export default IconTapTarget
