import React from "react"
import types from "prop-types"
import * as StatusListHelpers from "utils/statusListHelpers"
import { submissionStatusShape } from "utils/propTypeShapes"
import { TextInput } from "shared/inputs"
import { RemoveButton } from "shared/buttons"

const SubmissionStatus = ({
  savedStatus = undefined,
  status,
  updateName,
  handle = undefined,
  isDiscardable = false,
  discardStatus = () => {},
}) => (
  <div className="flex flex-row w-full justify-between items-center relative">
    <div className="list-row relative bg-primary-light w-full">
      <span className="absolute-vertical-center -left-8">{handle}</span>
      <span className="w-4/5">{savedStatus?.name}</span>
      <TextInput
        className="bordered-base-input !text-sm bg-white"
        value={status.name}
        onChange={updateName}
        isStatusValid={StatusListHelpers.isStatusValid(status)}
        errorMessages={StatusListHelpers.statusErrorMessages(status)}
      />
      {isDiscardable && (
        <div className="absolute-vertical-center -right-8">
          <RemoveButton onClick={discardStatus} />
        </div>
      )}
    </div>
  </div>
)

SubmissionStatus.propTypes = {
  savedStatus: submissionStatusShape,
  status: submissionStatusShape.isRequired,
  updateName: types.func.isRequired,
  handle: types.node,
  isDiscardable: types.bool,
  discardStatus: types.func,
}

export default SubmissionStatus
