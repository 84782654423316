import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { questionShape } from "utils/propTypeShapes"
import { FormsyNumberInput } from "shared/inputs"
import { FormsyListSelect } from "shared/selects"

const numberValidations = ({
  edit,
  max,
  min,
  required,
}) => {
  const validations = {
    isNumberGreaterThanOrEqualTo: { min },
    isNumberLessThanOrEqualTo: { max },
  }

  if (!edit && required) { validations.isNotBlankString = true }

  return validations
}

const buildOptions = ({ min, max }) => Array.from(
  { length: (max - min) + 1 },
  (_val, idx) => idx + min,
)

const NumberAnswerField = ({
  className = "",
  disabled = false,
  edit = false,
  hasBaseInputStyle = false,
  initialValue = null,
  isFullWidth = false,
  placeholder = "",
  question,
  ...rest
}) => {
  const { attributes, required, uuid } = question
  const {
    isDropdown,
    min = 0,
    max,
    precision = 0,
  } = attributes

  const inputProps = {
    className: clsx("form-input", className),
    name: uuid,
    validations: numberValidations({
      min, max, required, edit,
    }),
    value: null,
    disabled,
    hasBaseInputStyle,
    isFullWidth,
    placeholder,
    ...rest,
  }

  if (isDropdown) {
    return (
      <FormsyListSelect
        {...inputProps}
        options={buildOptions({ min, max })}
        value={initialValue}
      />
    )
  }

  return (
    <FormsyNumberInput
      {...inputProps}
      precision={precision}
      value={initialValue}
    />
  )
}

NumberAnswerField.propTypes = {
  edit: types.bool,
  question: questionShape.isRequired,
  initialValue: types.number,
  disabled: types.bool,
  className: types.string,
  placeholder: types.string,
  hasBaseInputStyle: types.bool,
  isFullWidth: types.bool,
}

export default NumberAnswerField
