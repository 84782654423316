import React, { Component } from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { CONTROLS, uniqueConditionOperators } from "utils/QuestionBranching"
import BaseConditionalCheck from "./ConditionalCheck/BaseConditionalCheck"
import ConditionalCheck from "./ConditionalCheck"

class QuestionBranchingConditionalCheck extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checkComponent: ConditionalCheck({
        operators: uniqueConditionOperators(props), baseCheckComponent: BaseConditionalCheck,
      }),
    }
  }

  componentDidUpdate(prevProps) {
    const { conditionals } = this.props

    if (!isEqual(prevProps.conditionals, conditionals)) {
      this.setState({
        checkComponent: ConditionalCheck({
          operators: uniqueConditionOperators(this.props), baseCheckComponent: BaseConditionalCheck,
        }),
      })
    }
  }

  render() {
    const { checkComponent: CheckComponent } = this.state
    const { children, conditionals, formAnswers } = this.props

    return (
      <CheckComponent
        conditionals={conditionals}
        formAnswers={formAnswers}
        operatorData={{}}
      >
        { children }
      </CheckComponent>
    )
  }
}

QuestionBranchingConditionalCheck.defaultProps = {
  conditionals: null,
}

QuestionBranchingConditionalCheck.propTypes = {
  children: types.node.isRequired,
  // Copied from prop-type shapes, the import was throwing an error
  // for this component only
  conditionals: types.shape({
    control: types.oneOf(CONTROLS),
    conditions: types.arrayOf(types.shape({
      questionUuid: types.string,
      operator: types.string,
      matchValue: types.oneOfType([
        types.string,
        types.number,
        types.arrayOf(types.string),
        types.arrayOf(types.number),
      ]),
    })),
  }),
  /* eslint-disable-next-line react/forbid-prop-types */
  formAnswers: types.object.isRequired,
}

export default QuestionBranchingConditionalCheck
