import React from "react"
import types from "prop-types"

const TaskDepartmentLabel = ({ className = "", labelText }) => (
  <span className={className}>{labelText}</span>
)

TaskDepartmentLabel.propTypes = {
  className: types.string,
  labelText: types.string.isRequired,
}

export default TaskDepartmentLabel
