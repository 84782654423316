import { errorToast } from "components/shared/toast"
import React, { useEffect, useState } from "react"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import * as API from "services/api"
import { categoryShape } from "utils/propTypeShapes"
import DraggableFormBar from "./DraggableFormBar"

const FormsOrderTab = ({ category }) => {
  const categorySlug = category.slug
  const [forms, setForms] = useState([])
  const [showError, setShowError] = useState(false)
  const [finishedLoading, setFinishedLoading] = useState(false)

  const getForms = async () => {
    const response = await API.getCategoryForms({ categorySlug })

    if (response.ok) {
      setForms(response.data)
    } else {
      console.error("Error fetching forms: ", response.data)
      setShowError(true)
    }

    setFinishedLoading(true)
  }

  useEffect(() => {
    getForms()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorySlug])

  const reorderForms = ({ fromIndex, toIndex }) => {
    const newForms = [...forms]
    const [formToMove] = newForms.splice(fromIndex, 1)
    newForms.splice(toIndex, 0, formToMove)

    return newForms
  }

  const updateFormsOrder = async (reorderedForms) => {
    const formsOrder = reorderedForms.map((form, index) => ({
      id: form.id,
      display_order: index,
    }))
    const response = await API.updateFormsOrder({ categorySlug, formsOrder })

    if (!response.ok) {
      errorToast("Something went wrong. Unable to save new forms order.")
      getForms()
    }
  }

  const reorder = ({ source: from, destination: to }) => {
    if (!(from && to)) return

    const { index: fromIndex } = from
    const { index: toIndex } = to

    const reorderedForms = reorderForms({ forms, fromIndex, toIndex })

    setForms(reorderedForms)

    updateFormsOrder(reorderedForms)
  }

  if (showError) {
    return (
      <div>Something went wrong; unable to fetch forms</div>
    )
  }

  if (finishedLoading && forms.length === 0) {
    return (
      <div>There are no forms for this Solution.</div>
    )
  }

  return (
    <div>
      <div className="tab-title">Forms Order</div>
      <p className="tab-description mb-8">
        Drag and drop forms into the order in which they should appear on the solution landing page.
      </p>
      <p className="tab-description mb-8">
        Forms that do not appear on the solution landing page are grayed out;
        a form must be both active and listed to appear on the solution landing page.
      </p>
      <DragDropContext onDragEnd={reorder}>
        <Droppable droppableId="form-list">
          {
            (dropProvided) => (
              <div
                className="flex flex-col"
                ref={dropProvided.innerRef}
                {...dropProvided.droppableProps}
              >
                {
                  forms.map((form, index) => (
                    <DraggableFormBar
                      key={form.id}
                      form={form}
                      index={index}
                    />
                  ))
                }
                {dropProvided.placeholder}
              </div>
            )
          }

        </Droppable>
      </DragDropContext>
    </div>
  )
}

FormsOrderTab.propTypes = {
  category: categoryShape.isRequired,
}

export default FormsOrderTab
