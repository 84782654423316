/* eslint-disable camelcase */
import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"

const AccessLevelGrantAccessLevelChangedAuditItemDetails = ({ audit }) => {
  const { auditedChanges, grantee } = audit
  const { access_level = {} } = auditedChanges
  const { from: oldAccessLevel, to: newAccessLevel } = access_level

  return (
    <div>
      <span>
        <AuditedUser audit={audit} /> changed the access level granted
        by the form submission to <span className="font-semibold">{grantee.fullName}</span>
        { oldAccessLevel && ` from ${oldAccessLevel}` }{ newAccessLevel && ` to ${newAccessLevel}` }.
      </span>
    </div>
  )
}

AccessLevelGrantAccessLevelChangedAuditItemDetails.propTypes = {
  audit: auditShape.isRequired,
}

export default AccessLevelGrantAccessLevelChangedAuditItemDetails
