import React from "react"
import types from "prop-types"
import Modal, { DEFAULT_MODAL_STYLE } from "shared/Modal"
import { PrimaryButton } from "shared/buttons"
import useCountdownSeconds from "./useCountdownSeconds"

const FormFillingTimeoutModal = ({ modalIsOpen, onContinue, onTimeout }) => {
  const { secondsToTimeout } = useCountdownSeconds({ onFinishedCountdown: onTimeout })

  return (
    <Modal isOpen={modalIsOpen} style={DEFAULT_MODAL_STYLE}>
      <div className="text-center text-xl font-semibold mb-8">
        Session Timeout
      </div>
      <p>
        You&apos;re being timed out due to inactivity.
        Select &ldquo;Keep Working&rdquo; to continue filling out the form.
        Otherwise, the page will refresh and the form will clear.
      </p>
      <div className="flex justify-center mt-8">
        <PrimaryButton
          text={`Keep Working (${secondsToTimeout})`}
          onClick={onContinue}
        />
      </div>
    </Modal>
  )
}

FormFillingTimeoutModal.propTypes = {
  modalIsOpen: types.bool.isRequired,
  onContinue: types.func.isRequired,
  onTimeout: types.func.isRequired,
}

export default FormFillingTimeoutModal
