import { useContext } from "react"
import FormSubmissionReportExportContext from "../FormSubmissionReportExportContext"

const useFormSubmissionReportExportContext = () => {
  const providedValue = useContext(FormSubmissionReportExportContext)

  return { ...providedValue }
}

export default useFormSubmissionReportExportContext
