/* eslint-disable camelcase */
import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { ACTIVE_STATUS, INACTIVE_STATUS } from "shared/lists/FacilityList/FacilityActiveStatusFilter"
import { AuditedUser } from "shared/audits"
import AuditChangeDetails from "../AuditChangeDetails"

const statusDescription = (auditedChanges) => {
  const { discarded_at = [] } = auditedChanges
  const statusChanged = Boolean(!!discarded_at[0] !== !!discarded_at[1])

  if (!statusChanged) return null

  const status = discarded_at[1] ? INACTIVE_STATUS : ACTIVE_STATUS

  return (
    <>set the facility&apos;s status to <span className="font-semibold">{status}</span></>
  )
}

const FacilityUpdated = ({ audit }) => {
  const { auditedChanges } = audit
  const statusDesc = statusDescription(auditedChanges)

  return (
    <div>
      <span className="block">
        <AuditedUser audit={audit} /> { statusDesc ?? "updated the facility" }
      </span>
      {
        !statusDesc && (
          Object.entries(auditedChanges).map(([attribute, change]) => (
            <AuditChangeDetails key={attribute} attribute={attribute} change={change} />
          ))
        )
      }
    </div>
  )
}

FacilityUpdated.propTypes = {
  audit: auditShape.isRequired,
}

export default FacilityUpdated
