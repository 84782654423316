import React from "react"
import { departmentShape } from "utils/propTypeShapes"
import { useHistoricalAccessToolContext } from "contexts/HistoricalAccessToolContext"
import { Checkbox } from "components/shared/checkboxes"

const HistoricalAccessToolLocationFilterDetailsSelectedFacilityDepartmentCheckbox = ({ department }) => {
  const {
    addDepartmentIdsToReportableFieldLocationAnswerFilters,
    reportableFieldLocationAnswerFilters,
    removeDepartmentIdsFromReportableFieldLocationAnswerFilters,
  } = useHistoricalAccessToolContext()

  const { departmentIds: selectedDepartmentIds } = reportableFieldLocationAnswerFilters[0]

  const selected = selectedDepartmentIds.includes(department.id)

  const toggleDepartment = selected
    ? () => removeDepartmentIdsFromReportableFieldLocationAnswerFilters([department.id])
    : () => addDepartmentIdsToReportableFieldLocationAnswerFilters([department.id])

  return (
    <div className="flex items-center">
      <Checkbox
        className="mr-2"
        onChange={toggleDepartment}
        uuid={`reportable-field-location-filter-department-checkbox-${department.id}`}
        value={selected}
      />
      <label
        className="hover:cursor-pointer"
        htmlFor={`reportable-field-location-filter-department-checkbox-${department.id}`}
      >
        {department.name}
      </label>
    </div>
  )
}

HistoricalAccessToolLocationFilterDetailsSelectedFacilityDepartmentCheckbox.propTypes = {
  department: departmentShape.isRequired,
}

export default HistoricalAccessToolLocationFilterDetailsSelectedFacilityDepartmentCheckbox
