import React, { useState, useEffect, useMemo } from "react"
import { categoryShape } from "utils/propTypeShapes"
import * as API from "services/api"
import { Button } from "shared/buttons"
import { orderBy } from "lodash-es"
import CreateReportableFieldsForm from "./CreateReportableFieldsForm"
import ReportableField from "./ReportableField"

const ReportableFieldsTab = ({ category }) => {
  const categorySlug = category.slug
  const [reportableFields, setReportableFields] = useState([])

  useEffect(() => {
    (async function getCategoryReportableFields() {
      const response = await API.getCategoryReportableFields({ categorySlug })
      if (response.ok) {
        setReportableFields(response.data)
      } else {
        console.error("Error fetching reportable fields: ", response)
      }
    }())
  }, [categorySlug])

  const [isCreateFormVisible, setIsCreateFormVisible] = useState(false)
  const setFormCreateField = () => {
    setIsCreateFormVisible(true)
  }
  const cancelForm = () => {
    setIsCreateFormVisible(false)
  }

  const sortedReportableFields = useMemo(
    () => orderBy(reportableFields, [(reportableField) => reportableField.name.toLowerCase()]),
    [reportableFields],
  )

  return (
    <>
      <div className="tab-title">Reporting Fields</div>
      <p className="tab-description mb-8">
        Associate questions across forms for reports.
      </p>
      {
        sortedReportableFields?.map((field) => (
          <ReportableField
            key={field.id}
            reportableFields={reportableFields}
            field={field}
            setReportableFields={setReportableFields}
            category={category}
          />
        ))
      }
      {
        !(isCreateFormVisible) && (
          <Button
            className="mt-5 w-36 border border-light-300 text-focus"
            text="+ Add Field"
            onClick={setFormCreateField}
          />
        )
      }
      <CreateReportableFieldsForm
        visible={isCreateFormVisible}
        cancelForm={cancelForm}
        categorySlug={categorySlug}
        reportableFields={reportableFields}
        setReportableFields={setReportableFields}
      />
    </>
  )
}

ReportableFieldsTab.propTypes = {
  category: categoryShape.isRequired,
}

export default ReportableFieldsTab
