import React from "react"
import types from "prop-types"
import clsx from "clsx"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const DragGripHandleIcon = ({ dragProvided, className = "" }) => (
  <div className={clsx(className, "flex items-center")} {...dragProvided.dragHandleProps}>
    <SvgSpriteIcon iconName="drag-handle" />
  </div>
)

DragGripHandleIcon.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dragProvided: types.object.isRequired,
  className: types.string,
}

export default DragGripHandleIcon
