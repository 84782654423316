import React from "react"
import clsx from "clsx"
import { sectionShape, taskShape } from "utils/propTypeShapes"
/* eslint-disable-next-line max-len */
import { simplifyAnswers } from "FormSubmission/FormSubmissionTabs/ActivitiesContent/ActivitiesMain/Task/TaskQuestionsValidator"
import types from "prop-types"
import SectionBranchingConditionalCheck from "components/views/Forms/FormElements/SectionBranchingConditionalCheck"
import SectionName from "../SectionName"
import SectionDescription from "../SectionDescription"
import SubformSectionQuestions from "./SubformSectionQuestions"

const SubformSection = ({
  className = "", isFirstSection, section, task,
}) => {
  const { name, description, questions } = section

  return (
    <SectionBranchingConditionalCheck
      formAnswers={simplifyAnswers(task.answers)}
      section={section}
      isFirstSection={isFirstSection}
    >
      <div className={clsx(className, "w-full")}>
        <div className="mb-6">
          <SectionName>{ name }</SectionName>
          <SectionDescription>{ description }</SectionDescription>
        </div>
        <SubformSectionQuestions
          questions={questions}
          task={task}
        />
      </div>
    </SectionBranchingConditionalCheck>
  )
}

SubformSection.propTypes = {
  className: types.string,
  isFirstSection: types.bool.isRequired,
  section: sectionShape.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  task: taskShape.isRequired,
}

export default SubformSection
