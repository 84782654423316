/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import types from "prop-types"
import { workflowActionShape } from "utils/propTypeShapes"
import { TaskFormContextProvider } from "contexts/TaskFormContext"
import CreateTaskWorkflowActionForm from "./CreateTaskWorkflowActionForm"

const mapCreateTaskWorkflowDataToContextData = (actionTaskData) => (
  {
    assigneeDepartments: actionTaskData.assignee_departments ?? [],
    assigneeGroupIds: actionTaskData.assignee_group_ids ?? [],
    assigneeIds: actionTaskData.assignee_ids ?? [],
    description: actionTaskData.description,
    dueDate: actionTaskData.due_date,
    manageAccessTaskIds: actionTaskData.manage_task_access_ids ?? [],
    memberDepartments: actionTaskData.member_departments ?? [],
    memberGroupIds: actionTaskData.member_group_ids ?? [],
    memberIds: actionTaskData.member_ids ?? [],
    notifyGroupIds: actionTaskData.notify_group_ids ?? [],
    notifyOnCompleteGroupIds: actionTaskData.notify_on_complete_group_ids ?? [],
    notifyIds: actionTaskData.notify_ids ?? [],
    notifyOnCompleteIds: actionTaskData.notify_on_complete_ids ?? [],
    relativeDueDate: actionTaskData.relative_due_date,
    subformId: actionTaskData.subform_id,
    title: actionTaskData.title,
  }
)

const DefineCreateTaskData = ({
  action, changeActionData, actionIndex,
}) => {
  const { task } = action.data

  const updateTask = (taskData) => changeActionData({ task: taskData })

  return (
    <TaskFormContextProvider task={mapCreateTaskWorkflowDataToContextData(task)}>
      <CreateTaskWorkflowActionForm
        actionIndex={actionIndex}
        updateTask={updateTask}
      />
    </TaskFormContextProvider>
  )
}

DefineCreateTaskData.propTypes = {
  action: workflowActionShape.isRequired,
  actionIndex: types.number.isRequired,
  changeActionData: types.func.isRequired,
}

export default DefineCreateTaskData
