import React from "react"
import types from "prop-types"
import { FormsyIconSelect } from "shared/selects"
import { reportableFieldFormElementTypeOptions, TEXT_FIELD_HELPER_NOTE } from "utils/reportableFieldHelpers"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const renderContentIcon = ({ icon }) => icon && <SvgSpriteIcon iconName={`${icon}-dark`} />
const renderItemIcon = ({ icon }) => <SvgSpriteIcon iconName={icon} />

const ReportableFieldQuestionType = ({ value = "" }) => (
  <>
    <FormsyIconSelect
      name="question_type"
      placeholder="Select field type..."
      value={value}
      options={reportableFieldFormElementTypeOptions()}
      validations="isNotBlankString"
      className="text-sm"
      renderContentIcon={renderContentIcon}
      renderItemIcon={renderItemIcon}
    />
    <div className="mt-2 ml-2 text-xs">{ TEXT_FIELD_HELPER_NOTE }</div>
  </>
)

ReportableFieldQuestionType.propTypes = {
  value: types.string,
}

export default ReportableFieldQuestionType
