import React from "react"
import LazyLoad from "react-lazyload"
import { sortBy } from "lodash-es"
import { useSurveyConfigurationContext } from "contexts/SurveyConfigurationContext"
import ContactInfo from "./ContactInfo"

const CONTACT_INCLUSIONS_SORT_FUNCTIONS = [
  (contactInclusion) => contactInclusion.lastName?.toLowerCase(),
  (contactInclusion) => contactInclusion.firstName?.toLowerCase(),
  (contactInclusion) => contactInclusion.email.toLowerCase(),
]

const ContactsList = () => {
  const { surveyConfiguration } = useSurveyConfigurationContext()
  const { contactInclusions } = surveyConfiguration

  return (
    sortBy(contactInclusions, CONTACT_INCLUSIONS_SORT_FUNCTIONS).map((contactInclusion) => (
      <LazyLoad key={`contact-row-${contactInclusion.id}`} height={58} offset={100} once resize>
        <ContactInfo contactInclusion={contactInclusion} />
      </LazyLoad>
    ))
  )
}

export default ContactsList
