import React from "react"
import types from "prop-types"
import ExpandMenu, { ExpandMenuItem } from "shared/ExpandMenu"
import EllipsisIcon from "shared/icons/EllipsisIcon"

const CategoryMenu = ({ category, goToCategoryEdit }) => (
  <ExpandMenu expandMenuComponent={<EllipsisIcon />}>
    <ExpandMenuItem onClick={goToCategoryEdit(category)}>
      Edit
    </ExpandMenuItem>
  </ExpandMenu>
)

CategoryMenu.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  category: types.object.isRequired,
  goToCategoryEdit: types.func.isRequired,
}

export default CategoryMenu
