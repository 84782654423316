import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const LockIcon = ({ className = "" }) => <SvgSpriteIcon iconName="lock" className={className} />

LockIcon.propTypes = {
  className: types.string,
}

export default LockIcon
