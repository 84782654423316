/* eslint-disable react/no-unused-state */
import React, { Component, createRef } from "react"
import types from "prop-types"
import { connect } from "react-redux"
import Formsy from "formsy-react"
import FormSection from "views/Forms/FormElements/FormSection"

// We are not submitting the preview form; however,
// we are tracking whether the form could be submitted.
// The reason is that we need the component to rerender
// when answers are changed, so that formAnswers()
// provides the current answers.

class FormPreviewForm extends Component {
  state = { canSubmit: false }

  formPreviewFormRef = createRef()

  componentDidMount() {
    const { setReset } = this.props

    if (setReset) {
      setReset(() => this.resetForm)
    }
  }

  enableSubmit = () => this.setState({ canSubmit: true })

  disableSubmit = () => this.setState({ canSubmit: false })

  formAnswers = () => this.formPreviewFormRef.current?.getModel() || {}

  resetForm = () => {
    this.formPreviewFormRef.current?.reset()
  }

  render() {
    const { form: { sections } } = this.props

    return (
      <div>
        <Formsy
          id="form-preview-form"
          ref={this.formPreviewFormRef}
          autoComplete="off"
          onValidSubmit={null}
          onValid={this.enableSubmit}
          onInvalid={this.disableSubmit}
        >
          {
            sections.map((section, sectionIndex) => (
              <FormSection
                key={section.uuid}
                section={section}
                isFirstSection={sectionIndex === 0}
                isLastSection={sectionIndex === sections.length - 1}
                formAnswers={this.formAnswers()}
              />
            ))
          }
        </Formsy>
      </div>
    )
  }
}

FormPreviewForm.defaultProps = {
  setReset: undefined,
}

FormPreviewForm.propTypes = {
  form: types.shape({
    title: types.string.isRequired,
    description: types.string,
    sections: types.arrayOf(types.object),
    slug: types.string.isRequired,
  }).isRequired,
  setReset: types.func,
}

const mapStateToProps = (state) => ({
  form: state.formBuilder.form,
})

export default connect(mapStateToProps)(FormPreviewForm)
