import React from "react"
import types from "prop-types"

const SubmissionSection = ({ children, section }) => {
  const { description, name } = section

  return (
    <div className="mb-8 last:mb-0">
      <div className="mb-6">
        <p className="font-medium text-2xl leading-9">{name}</p>
        {
          description && <p className="font-normal text-sm">{description}</p>
        }
      </div>
      { children }
    </div>
  )
}

SubmissionSection.defaultProps = {
  children: null,
}

SubmissionSection.propTypes = {
  children: types.node,
  // eslint-disable-next-line react/forbid-prop-types
  section: types.object.isRequired,
}

export default SubmissionSection
