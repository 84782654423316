import React from "react"
import { useSurveyBatchContext } from "contexts/SurveyBatchContext"
import CreateContactForSurveyBatch from "components/shared/modals/CreateContactForSurveyBatch"
import SurveyTokensList from "./SurveyTokensList"

const SurveyBatchSurveyTokens = () => {
  const { surveyBatchCreationDate, surveyBatchAcceptingNewContacts } = useSurveyBatchContext()

  return (
    <div className="side-tabs-container">
      <h2 className="tab-title flex">{surveyBatchCreationDate}</h2>
      <SurveyTokensList />
      {
        surveyBatchAcceptingNewContacts && <CreateContactForSurveyBatch />
      }
    </div>
  )
}

export default SurveyBatchSurveyTokens
