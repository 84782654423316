import React from "react"
import { useSelector } from "react-redux"
import { getFormSectionUuids } from "reduxSlices/formBuilderSlice"
import SectionSidebar from "views/FormBuilder/SectionFields/SectionSidebar"

const DesignSidebar = () => {
  const sectionUuids = useSelector(getFormSectionUuids)

  return (
    <div className="form-builder-sidebar">
      {
        sectionUuids.map((sectionUuid, sectionIndex) => (
          <SectionSidebar key={sectionUuid} sectionIndex={sectionIndex} />
        ))
      }
    </div>
  )
}

export default DesignSidebar
