import React, { useRef } from "react"
import { v4 as makeUuid } from "uuid"
import { PrimaryButton } from "shared/buttons"
import { errorToast } from "components/shared/toast"
import { sanitizeFilename } from "utils/stringHelpers"
import { readFileAsyncAsArrayBuffer } from "utils/fileUploadHelpers"
import { useFileUploadContext } from "contexts/FileUploadContext"
import { jsPDF } from "jspdf"
import * as pdfjs from "pdfjs-dist/webpack"
import Uploader from "./Uploader"

// Using workaround for pdfjs-dist vulnerability (https://github.com/advisories/GHSA-wgrm-67xf-hhpq).
// Historically, we've been unable to get later (patched) version of pdfjs-dist to
// properly load (due to .mjs files).
const EVAL_SUPPORT_BOOL_VALUE_TO_PREVENT_JS_EXECUTION = false

const IMAGE_FILE_TYPES = {
  "image/bmp": [],
  "image/gif": [],
  "image/jpeg": [],
  "image/png": [],
  "image/tiff": [],
}

const PDF_FILE_TYPES = {
  "application/pdf": [],
}

const ACCEPTED_FILE_TYPES = {
  ...IMAGE_FILE_TYPES, ...PDF_FILE_TYPES,
}
const FILE_TYPE_LIST = Object.keys(ACCEPTED_FILE_TYPES)
const MAX_FILE_SIZE = 26214400 // 25 MB

// 70% image quality prevents canvas-generated blob from being 10x original file size,
// while still being high res for small and large files.
// (can tweak this or make conditional as needed.)
// See: https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob#quality
const IMAGE_QUALITY = 0.70

export const MAX_FILE_COUNT = 10

const COMPRESS_PDF = true
const COMPRESSION_SPEED = "MEDIUM"

const FileUploader = () => {
  let currPage = 1
  let numPages = 0
  let originalPdfDoc = null
  let generatedPdfFileFromCanvasData

  const fileInputRef = useRef()
  const canvas = useRef()
  const {
    addFile, files, formSubmissionSlug, updateFile, uploadsToken,
  } = useFileUploadContext()

  const onFileSelect = (selectedFile) => addFile(selectedFile)

  const onFileUpdate = (updatedFile) => updateFile(updatedFile)

  const openFileDialog = () => fileInputRef.current?.click()

  const initiateProgressPreview = (uploadedFile) => {
    const filename = sanitizeFilename(uploadedFile.name)
    const fileKey = `${filename}-${makeUuid()}`
    const fileMetadataObj = { key: fileKey, filename, progress: 2 }
    onFileSelect(fileMetadataObj)

    return fileMetadataObj
  }

  const updateProgressPreview = (fileMetadataObj, processedUrl) => {
    fileMetadataObj = {
      ...fileMetadataObj,
      src: processedUrl,
      progress: 5,
    }
    onFileUpdate(fileMetadataObj)

    return fileMetadataObj
  }

  const drawImgToCanvas = (context, img) => {
    context.clearRect(0, 0, canvas.current.width, canvas.current.height)

    canvas.current.width = img.width
    canvas.current.height = img.height

    context.drawImage(img, 0, 0)
  }

  const railsDirectUpload = (fileData, updatedFile) => {
    // dev note: UPDATE S3 CORS WITH NGROK
    const uploader = new Uploader(
      fileData,
      formSubmissionSlug,
      uploadsToken,
      updateFile,
      updatedFile,
    )
    uploader.upload()
  }

  const processImageFile = (uploadedFile) => {
    const context = canvas.current.getContext("2d")

    const reader = new window.FileReader()
    const img = new window.Image()

    let fileMetadataObj = initiateProgressPreview(uploadedFile)

    // create new file based on image of uploaded file
    reader.onload = () => {
      img.onload = () => {
        drawImgToCanvas(context, img)
        const processedUrl = canvas.current.toDataURL(uploadedFile.type)

        fileMetadataObj = updateProgressPreview(fileMetadataObj, processedUrl)

        canvas.current.toBlob((blob) => {
          blob.name = fileMetadataObj.filename

          railsDirectUpload(blob, fileMetadataObj)
        },
        uploadedFile.type,
        IMAGE_QUALITY)
      }
      img.src = reader.result
    }
    reader.readAsDataURL(uploadedFile)
  }

  async function createAndUploadNewPdfFile(page, fileMetadataObj) {
    const viewport = page.getViewport({ scale: 1.0 })
    const pdfContext = canvas.current.getContext("2d")
    const devicePixelRatio = window.devicePixelRatio || 1;
    const { width } = viewport
    const { height } = viewport

    if (currPage === 1) {
      if (width > height) {
        generatedPdfFileFromCanvasData = new jsPDF("landscape", "px", [width, height], COMPRESS_PDF) // eslint-disable-line new-cap
      } else {
        generatedPdfFileFromCanvasData = new jsPDF("portrait", "px", [height, width], COMPRESS_PDF) // eslint-disable-line new-cap
      }
    }

    canvas.current.width = width * devicePixelRatio
    canvas.current.height = height * devicePixelRatio

    const transform = [devicePixelRatio, 0, 0, devicePixelRatio, 0, 0]

    const renderContext = {
      canvasContext: pdfContext,
      viewport,
      transform,
    }

    await page.render(renderContext).promise

    if (currPage === 1) {
      const processedUrl = canvas.current.toDataURL()

      fileMetadataObj = updateProgressPreview(fileMetadataObj, processedUrl)
    } else if (width > height) {
      generatedPdfFileFromCanvasData.addPage([width, height], "landscape")
    } else {
      generatedPdfFileFromCanvasData.addPage([height, width], "portrait")
    }

    generatedPdfFileFromCanvasData.addImage(
      canvas.current.toDataURL(),
      "PNG",
      0,
      0,
      generatedPdfFileFromCanvasData.internal.pageSize.getWidth(),
      generatedPdfFileFromCanvasData.internal.pageSize.getHeight(),
      undefined,
      COMPRESSION_SPEED,
    )

    currPage += 1

    if (originalPdfDoc !== null && currPage <= numPages) {
      originalPdfDoc.getPage(currPage).then((docPage) => createAndUploadNewPdfFile(docPage, fileMetadataObj))
    }

    if (currPage > numPages) {
      const pdfBlob = generatedPdfFileFromCanvasData.output("blob")
      pdfBlob.name = fileMetadataObj.filename

      railsDirectUpload(pdfBlob, fileMetadataObj)
    }
  }

  const processPdfFile = async (uploadedFile) => {
    const fileMetadataObj = initiateProgressPreview(uploadedFile)

    const data = new Uint8Array(await readFileAsyncAsArrayBuffer(uploadedFile))
    const loadedPdfDocObject = await pdfjs.getDocument({ data, isEvalSupported: EVAL_SUPPORT_BOOL_VALUE_TO_PREVENT_JS_EXECUTION }).promise
    const metadata = await loadedPdfDocObject.getMetadata()

    if (metadata.info.IsCollectionPresent) {
      onFileUpdate(
        {
          ...fileMetadataObj,
          progress: 0,
          errors: "PDF Portfolio files are not supported.",
        },
      )
      return
    }

    originalPdfDoc = loadedPdfDocObject
    numPages = loadedPdfDocObject.numPages
    const firstPage = await loadedPdfDocObject.getPage(1)

    await createAndUploadNewPdfFile(firstPage, fileMetadataObj)
  }

  const handleUploadEvent = (e) => {
    const uploadedFile = e.target.files[0]

    if (!uploadedFile) return

    if (!FILE_TYPE_LIST.includes(uploadedFile.type)) {
      errorToast(`${uploadedFile.type} is not an accepted file type.`)
    } else if (uploadedFile.size > MAX_FILE_SIZE) {
      errorToast("Files cannot exceed 25 MB.")
    } else if (Object.keys(IMAGE_FILE_TYPES).includes(uploadedFile.type)) {
      processImageFile(uploadedFile)
    } else if (Object.keys(PDF_FILE_TYPES).includes(uploadedFile.type)) {
      processPdfFile(uploadedFile)
    }
  }

  return (
    <>
      <input
        name="uploadedFile"
        accept={ACCEPTED_FILE_TYPES}
        type="file"
        onChange={handleUploadEvent}
        hidden
        ref={fileInputRef}
      />
      <PrimaryButton
        className="w-2/3 h-12 font-medium text-2xl my-4"
        onClick={openFileDialog}
        text="Upload Files"
        disabled={files.length >= MAX_FILE_COUNT}
      />
      <ul className="w-2/3 text-sm list-outside">
        <li>Attachment size limit: 25 MB.</li>
        <li>Accepted types: pdf or image (jpeg, png, bmp, tiff or gif).</li>
        <li>{MAX_FILE_COUNT} files maximum.</li>
      </ul>
      <canvas ref={canvas} hidden />
    </>
  )
}

export default FileUploader
