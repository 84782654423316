import React from "react"
import types from "prop-types"
import { TextInput } from "shared/inputs"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { SecondaryButton } from "shared/buttons"

const SurveyCopyLink = ({ url = null }) => {
  if (!url) return null

  const highlightInput = ({ target }) => target.select()

  return (
    <div className="w-7/8">
      <div className="flex">
        <TextInput
          className="text-xs mr-1 text-ellipsis bordered-base-input h-9"
          onClick={highlightInput}
          onFocus={highlightInput}
          readonly
          value={url}
        />
        <CopyToClipboard text={url}>
          <SecondaryButton
            text="Copy Link"
            className="whitespace-nowrap"
          />
        </CopyToClipboard>
      </div>
    </div>
  )
}

SurveyCopyLink.propTypes = {
  url: types.string,
}

export default SurveyCopyLink
