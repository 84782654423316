import React from "react"
import { shortMonthDate } from "utils/dateHelpers"
import { useSurveyConfigurationContext } from "contexts/SurveyConfigurationContext"
import { RotateIcon } from "shared/icons"
import { TileFooter } from "shared/tiles"
import ActiveToggle from "./ActiveToggle"

const SurveyConfigurationTileFooter = () => {
  const { surveyConfiguration } = useSurveyConfigurationContext()
  const { isRecurring, lastSentAt } = surveyConfiguration

  return (
    <TileFooter>
      <div className="flex items-center gap-2">
        {
          isRecurring && <RotateIcon className="w-4 h-4" />
        }
        <p className="font-bold text-sm text-gray-800">
          {
            lastSentAt
              ? `Last sent on ${shortMonthDate(lastSentAt)}`
              : "Survey has not been sent"
          }
        </p>
      </div>
      <ActiveToggle />
    </TileFooter>
  )
}

export default SurveyConfigurationTileFooter
