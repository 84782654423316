import React from "react"
import { WorkflowActionRemoveAllMembersIcon } from "shared/icons"
import ActionHeading from "./ActionHeading"
import ActionLabelContainer from "./ActionLabelContainer"

const RemoveAllMembersActionLabel = () => (
  <ActionLabelContainer>
    <ActionHeading
      iconComponent={WorkflowActionRemoveAllMembersIcon}
      text="Remove all members"
    />
  </ActionLabelContainer>
)

export default RemoveAllMembersActionLabel
