import React from "react"
import types from "prop-types"
import FormAccessList from "./FormAccessList"

const AccessOverviewTab = ({ accessOverviewDisplay }) => (
  <>
    <h2 className="tab-title">Access Overview</h2>
    {
      accessOverviewDisplay.map((category) => (
        <FormAccessList key={category.id} category={category} />
      ))
    }
  </>
)

AccessOverviewTab.propTypes = {
  accessOverviewDisplay: types.arrayOf(types.object).isRequired,
}

export default AccessOverviewTab
