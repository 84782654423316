import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getFormSubmissionTable } from "reduxSlices/formSubmissionTableSlice"
import Switch from "shared/Switch"

const SubmissionClosedFilter = ({ toggleShowClosedSubmissions }) => {
  const { showOnlyClosedSubmissions } = useSelector(getFormSubmissionTable)

  return (
    <div className="p-6 border-b border-lightgray-3">
      <div className="font-semibold flex-center mb-2">
        Show closed submissions (previous month)
        <Switch
          checked={showOnlyClosedSubmissions}
          onChange={toggleShowClosedSubmissions}
        />
      </div>
    </div>
  )
}

SubmissionClosedFilter.propTypes = {
  toggleShowClosedSubmissions: types.func.isRequired,
}

export default SubmissionClosedFilter
