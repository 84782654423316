import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { subscribedToAddOnFeature } from "reduxSlices/addOnFeatureSubscriptionsSlice"
import { currentUserId as getCurrentUserId, currentUserIsInAdminRole as getCurrentUserIsInAdminRole } from "reduxSlices/sessionSlice"
import { CustomHeaderAccordion, useAccordion } from "shared/accordions"
import { ChevronDown, ChevronUp } from "shared/icons"
import { REPORT_SFTP_FEATURE_NAME } from "utils/addOnFeatureHelpers"
import NewReportExportLink from "./ReportElements/NewReportExportLink"
import ReportMenu from "./ReportElements/ReportMenu"
import ExpandedRowContent from "./ExpandedRowContent"

const ReportRow = ({
  editScheduledReportExportPath, newReportExportPath, newScheduledReportExportPath, report,
}) => {
  const currentUserId = useSelector(getCurrentUserId)
  const currentUserIsInAdminRole = useSelector(getCurrentUserIsInAdminRole)

  const {
    shared, creatorId, creatorFullName,
  } = report
  const [isExpanded, toggleAccordion] = useAccordion(false)
  const subscribedToSFTP = useSelector(subscribedToAddOnFeature(REPORT_SFTP_FEATURE_NAME))

  const createdByCurrentUser = currentUserId === creatorId

  const Chevron = isExpanded ? ChevronDown : ChevronUp

  return (
    <div className="form-submission-row last:mb-0">
      <CustomHeaderAccordion
        isExpanded={isExpanded}
        header={(
          <div className="flex relative flex-center">
            <div className="w-1/3 flex">
              {report.name}
            </div>
            <div className="flex">
              {
                shared && !createdByCurrentUser && (
                  <div className="flex items-center text-sm text-gray mr-2">shared by {creatorFullName}</div>
                )
              }
              <div className="flex justify-end items-center">
                <NewReportExportLink location={newReportExportPath(report.id)} />
                {
                  (subscribedToSFTP && currentUserIsInAdminRole)
                  && (
                    <div className="ml-4">
                      <div className="rounded bg-light-100 p-1 w-6">
                        <Chevron
                          className="h-4 w-4 cursor-pointer flex-center"
                          onClick={toggleAccordion}
                        />
                      </div>
                    </div>
                  )
                }
                <div className="-mr-2">
                  <ReportMenu report={report} />
                </div>
              </div>
            </div>
          </div>
        )}
        content={(
          <ExpandedRowContent
            report={report}
            newScheduledReportExportPath={newScheduledReportExportPath}
            editScheduledReportExportPath={editScheduledReportExportPath}
          />
        )}
      />
    </div>
  )
}

ReportRow.propTypes = {
  newReportExportPath: types.func.isRequired,
  newScheduledReportExportPath: types.func.isRequired,
  editScheduledReportExportPath: types.func.isRequired,
  report: types.objectOf(types.any).isRequired,
}

export default ReportRow
