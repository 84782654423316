import React, { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Drawer from "shared/Drawer"
import {
  formSubmissionMembers,
  formSubmissionNonMembersWithAccess,
  formSubmissionSlug,
  updateAccessOverview,
} from "reduxSlices/formSubmissionSlice"
import FormSubmissionAccessUsers from "./FormSubmissionAccessUsers"

const FormSubmissionAccessOverview = () => {
  const dispatch = useDispatch()

  const slug = useSelector(formSubmissionSlug)
  const members = useSelector(formSubmissionMembers)
  const nonMembersWithAccess = useSelector(formSubmissionNonMembersWithAccess)

  const refreshAccessOverview = useCallback(() => {
    dispatch(updateAccessOverview({ formSubmissionSlug: slug }))
  }, [dispatch, slug])

  useEffect(() => { refreshAccessOverview() }, [refreshAccessOverview])

  return (
    <Drawer
      className="hidden lg:flex absolute z-1 right-0 top-0 bottom-0 -mt-0.5 min-w-max max-w-sm"
      onOpen={refreshAccessOverview}
    >
      <FormSubmissionAccessUsers
        headerText="Members"
        membersWithGrant={members}
        description="People that are directly responsible for the submission and will be notified on updates."
        emptyListText="No members with access"
        initiallyExpanded
      />
      <FormSubmissionAccessUsers
        headerText="Other People With Access"
        membersWithGrant={nonMembersWithAccess}
        description="People that have access to the submission, but won&apos;t receive any notification."
        emptyListText="No users with access"
      />
    </Drawer>
  )
}

export default FormSubmissionAccessOverview
