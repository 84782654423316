import React, { useEffect } from "react"
import types from "prop-types"
import withReduxProvider from "shared/withReduxProvider"
import { useDispatch } from "react-redux"
import {
  loadReportData,
  selectAllTaskDefaultColumns,
  SOLUTION_REPORT_TYPE,
  SUBMISSION_REPORT_TYPE,
  SURVEY_REPORT_TYPE,
} from "reduxSlices/reportBuilderSlice"
import { formOptionShape, reportExportBuilderColumnOptionShape } from "utils/propTypeShapes"
import ReportBuilder from "../ReportBuilder"

const ReportEditor = ({
  categories,
  formQuestions = [],
  forms,
  includedFormSlugs = [],
  name,
  orderedSubmissionColumns,
  orderedTaskColumns,
  reportableFields = [],
  reportId,
  reportType,
  selectedCategorySlug,
  selectedFormSlug,
  selectedTaskForms,
  shared,
  submissionColumnOptions,
  surveyColumnOptions = [],
  surveyNonAnonymousColumnOptions = [],
  taskColumnOptions,
  taskFormQuestions,
  taskForms,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadReportData({
      categories,
      formQuestions,
      forms,
      includedFormSlugs,
      name,
      orderedSubmissionColumns,
      orderedTaskColumns,
      reportableFields,
      reportType,
      selectedCategorySlug,
      selectedFormSlug,
      selectedTaskForms,
      shared,
      submissionColumnOptions,
      surveyColumnOptions,
      surveyNonAnonymousColumnOptions,
      taskColumnOptions,
      taskFormQuestions,
      taskForms,
    }))

    // Default all column options to selected when editing report without existing Task Forms
    if (selectedTaskForms.length === 0 && orderedTaskColumns.length === 0) {
      dispatch(selectAllTaskDefaultColumns())
    }
  }, [
    categories,
    dispatch,
    formQuestions,
    forms,
    includedFormSlugs,
    name,
    orderedSubmissionColumns,
    orderedTaskColumns,
    reportableFields,
    reportType,
    selectedCategorySlug,
    selectedFormSlug,
    selectedTaskForms,
    shared,
    submissionColumnOptions,
    surveyColumnOptions,
    surveyNonAnonymousColumnOptions,
    taskColumnOptions,
    taskFormQuestions,
    taskForms,
  ])

  return (<ReportBuilder reportId={reportId} />)
}

ReportEditor.propTypes = {
  categories: types.arrayOf(types.shape({
    name: types.string.isRequired,
    slug: types.string.isRequired,
  })).isRequired,
  formQuestions: types.arrayOf(types.object),
  forms: types.arrayOf(formOptionShape).isRequired,
  includedFormSlugs: types.arrayOf(types.string),
  name: types.string.isRequired,
  orderedSubmissionColumns: types.arrayOf(types.object).isRequired,
  orderedTaskColumns: types.arrayOf(types.object).isRequired,
  reportableFields: types.arrayOf(types.object),
  reportId: types.number.isRequired,
  reportType: types.oneOf([SOLUTION_REPORT_TYPE, SUBMISSION_REPORT_TYPE, SURVEY_REPORT_TYPE]).isRequired,
  selectedCategorySlug: types.string.isRequired,
  selectedFormSlug: types.string.isRequired,
  selectedTaskForms: types.arrayOf(types.object).isRequired,
  shared: types.bool.isRequired,
  submissionColumnOptions: types.arrayOf(reportExportBuilderColumnOptionShape).isRequired,
  surveyColumnOptions: types.arrayOf(reportExportBuilderColumnOptionShape),
  surveyNonAnonymousColumnOptions: types.arrayOf(reportExportBuilderColumnOptionShape),
  taskColumnOptions: types.arrayOf(reportExportBuilderColumnOptionShape).isRequired,
  taskFormQuestions: types.object.isRequired,
  taskForms: types.arrayOf(formOptionShape).isRequired,
}

export default withReduxProvider(ReportEditor)
