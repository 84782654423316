import React from "react"
import { useFormSubmissionReportExportContext } from "contexts/FormSubmissionReportExportContext"
import { yearMonthDayDate } from "utils/dateHelpers"
import { DateInput } from "shared/inputs"

const NewFormSubmissionReportExportSubmissionFilters = () => {
  const {
    earliestSubmissionDate,
    latestSubmissionDate,
    setEarliestSubmissionDate,
    setLatestSubmissionDate,
  } = useFormSubmissionReportExportContext()

  return (
    <>
      <label>
        <span>Earliest Date:</span>
        <DateInput
          className="mb-4"
          clearable
          max={latestSubmissionDate ? yearMonthDayDate(latestSubmissionDate) : yearMonthDayDate()}
          name="form_submission_report_export[earliest_date]"
          onChange={setEarliestSubmissionDate}
          value={earliestSubmissionDate}
        />
      </label>
      <label>
        <span>Latest Date:</span>
        <DateInput
          className="mb-4"
          clearable
          min={earliestSubmissionDate ? yearMonthDayDate(earliestSubmissionDate) : null}
          max={yearMonthDayDate()}
          name="form_submission_report_export[latest_date]"
          onChange={setLatestSubmissionDate}
          value={latestSubmissionDate}
        />
      </label>
    </>
  )
}

export default NewFormSubmissionReportExportSubmissionFilters
