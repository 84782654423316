import React, { useState } from "react"
import types from "prop-types"
import * as API from "services/api"
import GroupTabs from "../GroupTabs"

const ShowGroup = ({ group: initialGroup, groupWorkflows }) => {
  const [group, setGroup] = useState(initialGroup)

  const addMember = (member) => setGroup((prevGroup) => ({
    ...prevGroup,
    members: [...prevGroup.members, member],
  }))

  const removeMember = (memberId) => setGroup((prevGroup) => ({
    ...prevGroup,
    members: [...prevGroup.members.filter(({ id }) => id !== memberId)],
  }))

  const updateGroup = async (model) => {
    const response = await API.updateGroup({ groupId: group.id, group: model })

    if (response.ok) {
      setGroup((prevGroup) => ({ ...prevGroup, ...model }))
    } else {
      console.error("Error updating group: ", response)
    }
  }

  const tabProps = {
    group,
    groupWorkflows,
    addMember,
    removeMember,
    updateGroup,
  }

  return (
    <GroupTabs tabProps={tabProps} />
  )
}

ShowGroup.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  group: types.object.isRequired,
  groupWorkflows: types.arrayOf(types.object).isRequired,
}

export default ShowGroup
