import React from "react"
import Rails from "@rails/ujs"
import types from "prop-types"
import Formsy from "formsy-react"
import { useSubmit } from "hooks"
import { ACTIVITY_HUB_RESET_PASSWORD_PATH } from "utils/routeHelpers"
import FormsyUserPasswordInput from "shared/inputs/UserPasswordInput/FormsyUserPasswordInput"
import { FormsyHiddenInput } from "shared/inputs"
import { PrimaryButton } from "shared/buttons"
import { isMinLength, MIN_PASSWORD_LENGTH } from "utils/validationHelpers"

const ResetPasswordForm = ({ email, token, formSubmitTo = ACTIVITY_HUB_RESET_PASSWORD_PATH }) => {
  const { canSubmit, enableSubmit, disableSubmit } = useSubmit()

  return (
    <Formsy
      action={formSubmitTo}
      method="post"
      onInvalid={disableSubmit}
      onValid={enableSubmit}
      preventDefaultSubmit={false}
    >
      <FormsyUserPasswordInput
        name="password"
        required
        showErrorMessages={false}
        validationErrors={{
          minLength: isMinLength.message,
        }}
        // eslint-disable-next-line max-len
        validations={`isNotBlankString,isStrongPassword,hasLowercaseLetter,hasUppercaseLetter,hasNumber,minLength:${MIN_PASSWORD_LENGTH}`}
      />
      <FormsyUserPasswordInput
        label="Repeat Password"
        name="password_confirmation"
        required
        validationErrors={{
          equalsField: "Password does not match",
        }}
        validations="equalsField:password"
      />
      <FormsyHiddenInput name="token" value={token} />
      <FormsyHiddenInput name="email" value={email} />
      <FormsyHiddenInput name="authenticity_token" value={Rails.csrfToken()} />
      <PrimaryButton
        className="w-full bg-navy"
        disabled={!canSubmit}
        text="Reset Password"
        type="submit"
      />
    </Formsy>
  )
}

ResetPasswordForm.propTypes = {
  email: types.string.isRequired,
  token: types.string.isRequired,
  formSubmitTo: types.string,
}

export default ResetPasswordForm
