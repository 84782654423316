import React from "react"
import types from "prop-types"
import * as SelectAttributes from "./SelectAttributes"
import QuestionRequired from "./QuestionRequired"
import QuestionShowDescription from "./QuestionShowDescription"

const SelectInputConfig = ({ sectionIndex, questionIndex }) => (
  <>
    <QuestionRequired
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <QuestionShowDescription
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <SelectAttributes.DisplayAs
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <SelectAttributes.ColumnCount
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <SelectAttributes.DefaultAnswer
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
  </>
)

SelectInputConfig.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default SelectInputConfig
