import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { withFormsy } from "formsy-react"
import InputErrorMessage from "shared/InputErrorMessage"
import RadioButton from "./RadioButton"

const RadioButtonGroupInput = ({
  className = "",
  columnCount = 1,
  disabled = false,
  errorMessages = [],
  id = "",
  isPristine = true,
  name,
  onChange,
  options,
  radioInputClassName = "",
  value = "",
}) => {
  const shouldHaveGridCol = (window.screen.availWidth > 500)
  return (
    <>
      <div className={clsx(
        className,
        "cursor-pointer radio-button-group-input",
        shouldHaveGridCol && `grid grid-cols-${columnCount}`,
      )}
      >
        {
          options.map((option) => {
            // The id passed to the radio button group input serves as a base
            // from which the individual radio button ids are built.
            // If no id is passed then fallback to using name.
            const radioButtonIdBase = id || name
            const radioButtonId = `${radioButtonIdBase}-${option}-radio-button`

            return (
              <RadioButton
                key={option}
                id={radioButtonId}
                name={name}
                className={clsx("p-4", radioInputClassName)}
                disabled={disabled}
                value={option}
                checked={option === value}
                onChange={onChange}
              />
            )
          })
        }
      </div>
      <InputErrorMessage isPristine={isPristine} errorMessages={errorMessages} />
    </>
  )
}

RadioButtonGroupInput.propTypes = {
  className: types.string,
  columnCount: types.number,
  disabled: types.bool,
  errorMessages: types.arrayOf(types.string),
  id: types.string,
  isPristine: types.bool,
  name: types.string.isRequired,
  onChange: types.func.isRequired,
  options: types.arrayOf(types.string).isRequired,
  radioInputClassName: types.string,
  value: types.string,
}

export const FormsyRadioButtonGroupInput = withFormsy(({ setValue, ...rest }) => (
  <RadioButtonGroupInput
    onChange={setValue}
    {...rest}
  />
))

export default RadioButtonGroupInput
