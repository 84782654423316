import React from "react"
import types from "prop-types"
import { SURVEY_SUBMISSION_CREATED_PATH, surveySubmissionsUploadsPath } from "utils/routeHelpers"
import { SecondaryButton } from "shared/buttons"
import { FileUploadContextProvider } from "contexts/FileUploadContext"
import FileUploader, { MAX_FILE_COUNT } from "./Content/FileUploader"
import FilePreviewList from "./Content/FilePreviewList"

export const FormSubmissionUploadsPage = ({ formSubmissionSlug, uploadsCount, uploadsToken }) => {
  if (uploadsCount > MAX_FILE_COUNT) return null

  const goToFormSubmissionPage = () => {
    const url = window.location.pathname

    if (url === surveySubmissionsUploadsPath()) {
      window.location.pathname = SURVEY_SUBMISSION_CREATED_PATH
    } else {
      window.location.pathname = url.substring(0, url.lastIndexOf("/"))
    }
  }

  return (
    <div className="mx-auto mt-6 md:mb-12">
      <div className="bg-white pb-8 rounded-b-xl">
        <div className="flex flex-col items-center gap-6">
          <FileUploadContextProvider
            formSubmissionSlug={formSubmissionSlug}
            uploadsToken={uploadsToken}
          >
            <FilePreviewList />

            <FileUploader />
          </FileUploadContextProvider>
        </div>
      </div>
      <div className="flex flex-col items-center my-6">
        <SecondaryButton
          text="Done With Uploads"
          onClick={goToFormSubmissionPage}
        />
      </div>
    </div>
  )
}

FormSubmissionUploadsPage.propTypes = {
  formSubmissionSlug: types.string.isRequired,
  uploadsCount: types.number.isRequired,
  uploadsToken: types.string.isRequired,
}

export default FormSubmissionUploadsPage
