import React from "react"
import types from "prop-types"
import { workflowActionShape } from "utils/propTypeShapes"
import ActionSubtypeConnector from "./ActionSubtypeConnector"
import SelectActionSubtype from "./SelectActionSubtype"
import SelectActionType from "./SelectActionType"

const DefineActionType = ({
  action, changeActionSubtype, changeActionType,
}) => (
  <>
    <SelectActionType
      changeActionType={changeActionType}
      value={action.type}
    />
    <ActionSubtypeConnector type={action.type} />
    <SelectActionSubtype
      changeActionSubtype={changeActionSubtype}
      value={action.subtype}
      type={action.type}
    />
  </>
)

DefineActionType.propTypes = {
  action: workflowActionShape.isRequired,
  changeActionSubtype: types.func.isRequired,
  changeActionType: types.func.isRequired,
}

export default DefineActionType
