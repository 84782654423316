import React, { useEffect } from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getFilteredGroupedAttachments } from "reduxSlices/formSubmissionAttachmentsGroupingSlice"
import { useModal } from "hooks"
import { useFormSubmissionAttachmentDropzoneContext } from "contexts/FormSubmissionAttachmentDropzoneContext"
import Modal, { ModalHeader } from "shared/Modal"
import EmptyAttachments from "./EmptyAttachments"
import AttachmentGroupingList from "./AttachmentGroupingList"
import AttachmentUploadInstructions from "./AttachmentUploadInstructions"
import AttachmentDropzone from "./AttachmentDropzone"
import UploadingFormSubmissionAttachments from "./UploadingFormSubmissionAttachments"

const AttachmentsList = ({ attachments }) => {
  const attachmentGroups = useSelector(getFilteredGroupedAttachments)
  const { modalIsOpen, openModal, closeModal } = useModal()
  const { resetDropzone, uploadComplete, uploadInitiated } = useFormSubmissionAttachmentDropzoneContext()

  // Clicking the close-modal button resets the dropzone context.
  // When the context is reset, uploadInitiated is set to false.
  // When uploadInitiated is set to false, we close the modal.
  useEffect(() => {
    uploadInitiated ? openModal() : closeModal()
  }, [closeModal, openModal, uploadInitiated])

  return (
    <>
      { attachments.length === 0
        ? (
          <AttachmentDropzone>
            <EmptyAttachments />
          </AttachmentDropzone>
        )
        : (
          <AttachmentDropzone>
            <AttachmentGroupingList className="mb-16" attachmentGroups={attachmentGroups} />
            <AttachmentUploadInstructions className="md:mb-16 text-center" />
          </AttachmentDropzone>
        )}
      <Modal isOpen={modalIsOpen}>
        <ModalHeader heading="Uploads" closeModal={uploadComplete() ? resetDropzone : undefined} />
        <UploadingFormSubmissionAttachments className="max-h-[60vh] overflow-y-auto" />
      </Modal>
    </>
  )
}

AttachmentsList.propTypes = {
  attachments: types.arrayOf(types.object).isRequired,
}

export default AttachmentsList
