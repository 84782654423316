import React from "react"
import { DataCollectorContainer, DataCollectorStep } from "shared/dataCollector"
import HistoricalAccessToolAction from "./HistoricalAccessToolAction"
import HistoricalAccessToolDataCollectorActionSteps from "./HistoricalAccessToolDataCollectorActionSteps"

const HistoricalAccessToolDataCollector = () => (
  <DataCollectorContainer>
    <DataCollectorStep stepNumber={1} heading="What action would you like to take?">
      <HistoricalAccessToolAction />
    </DataCollectorStep>
    <HistoricalAccessToolDataCollectorActionSteps />
  </DataCollectorContainer>
)

export default HistoricalAccessToolDataCollector
