import React from "react"
import types from "prop-types"
import clsx from "clsx"
import TableDataElement from "./TableDataElement"
import DeletedSubmissionMenuDataElement from "./TableDataElement/DeletedSubmissionMenuDataElement"

const TableRow = ({
  columns,
  rowHrefFunc = undefined,
  rowData,
  onRowClick = null,
  entryClassHelper = undefined,
}) => (
  <tr
    className={clsx(onRowClick && "cursor-pointer")}
    onClick={() => onRowClick?.(rowData)}
  >
    {
      columns.map((column) => (
        <TableDataElement
          key={column.value}
          column={column}
          rowHrefFunc={rowData.isDeleted ? undefined : rowHrefFunc}
          rowData={rowData}
          entryClassHelper={entryClassHelper}
        />
      ))
    }
    {
      rowData.isDeleted && (
        <DeletedSubmissionMenuDataElement
          rowData={rowData}
          rowHrefFunc={rowHrefFunc}
        />
      )
    }
  </tr>
)

TableRow.propTypes = {
  rowHrefFunc: types.func,
  // eslint-disable-next-line react/forbid-prop-types
  rowData: types.object.isRequired,
  columns: types.arrayOf(types.object).isRequired,
  onRowClick: types.func,
  entryClassHelper: types.object,
}

export default TableRow
