/* eslint-disable camelcase */
import React from "react"
import types from "prop-types"
import { categoryShape } from "utils/propTypeShapes"
import * as API from "services/api"
import { GroupAndUserSearch } from "shared/searches"

const FormBuilderSearch = ({
  formBuilderLists, category, setFormBuilderLists, users = [], groups = [],
}) => {
  const createFormBuilder = async (value) => {
    const { slug: categorySlug, id: category_id } = category
    const [grantee_type, grantee_id] = value.split("-")
    const category_ability = { grantee_id, grantee_type, category_id }

    const response = await API.createFormBuilder({ categorySlug, category_ability })
    if (response.ok) {
      setFormBuilderLists(response.data)
    } else {
      console.error("Error creating form builder ability", response)
    }
  }

  return (
    <GroupAndUserSearch
      selectedUsers={formBuilderLists.users}
      selectedGroups={formBuilderLists.groups}
      onSearchSelected={createFormBuilder}
      users={users}
      groups={groups}
    />
  )
}

FormBuilderSearch.propTypes = {
  category: categoryShape.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formBuilderLists: types.object.isRequired,
  setFormBuilderLists: types.func.isRequired,
  groups: types.arrayOf(types.object),
  users: types.arrayOf(types.object),
}

export default FormBuilderSearch
