import React from "react"
import types from "prop-types"

const FormTitle = ({ children }) => (
  <div className="mt-16 text-xl sm:text-2xl md:text-3xl font-medium">{children}</div>
)

FormTitle.propTypes = {
  children: types.string.isRequired,
}

export default FormTitle
