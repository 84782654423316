/* eslint-disable camelcase */
import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"

const RelatedFormSubmissionUpdatedAuditItemDetails = ({ audit }) => {
  const { auditedChanges, relatedFormSubmissionSlug } = audit
  const { discarded_at = [] } = auditedChanges

  const discarded = Boolean(discarded_at[1])

  if (discarded) {
    return (
      <div>
        <span>
          <AuditedUser audit={audit} /> removed {relatedFormSubmissionSlug} as
          a related form submission.
        </span>
      </div>
    )
  }

  return (
    <div>
      <span>
        <AuditedUser audit={audit} /> updated the form submission
        relationship with {relatedFormSubmissionSlug}.
      </span>
    </div>
  )
}

RelatedFormSubmissionUpdatedAuditItemDetails.propTypes = {
  audit: auditShape.isRequired,
}

export default RelatedFormSubmissionUpdatedAuditItemDetails
