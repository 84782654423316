import React from "react"
import types from "prop-types"
import PublicCategoryFormList from "./PublicCategoryFormList"

const PublicCategory = ({ category, forms }) => (
  <>
    <div className="text-2xl sm:text-3xl font-medium text-center">{ category.name }</div>
    <div className="mx-2 text-center opacity-60">{ category.description }</div>
    <PublicCategoryFormList forms={forms} />
  </>
)

PublicCategory.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  category: types.object.isRequired,
  forms: types.arrayOf(types.object).isRequired,
}

export default PublicCategory
