import React from "react"
import { useSelector } from "react-redux"
import { getTaskNotifySettings } from "reduxSlices/currentSettingsSlice"
import ContentBar from "shared/lists/ContentBar"
import { notificationSettingLabel } from "utils/generalSettingsHelpers"
import NotificationSetting from "./NotificationSetting"

const TaskNotificationSettings = () => {
  const taskNotifySettings = useSelector(getTaskNotifySettings)

  if (taskNotifySettings?.size === 0) return null

  return (
    <ContentBar
      title="Task Notification Defaults"
      description="These defaults only affect initial settings when creating a Task or Workflow"
      descriptionClassName="text-xs"
    >
      <div className="flex flex-col space-y-2 w-full">
        {
          taskNotifySettings.map(({ settingType }) => (
            <NotificationSetting
              key={settingType}
              settingType={settingType}
              label={notificationSettingLabel(settingType)}
            />
          ))
        }
      </div>
    </ContentBar>
  )
}

export default TaskNotificationSettings
