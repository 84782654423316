import React from "react"
import types from "prop-types"

const LongTextDataElement = ({ value = "" }) => (
  <span>
    { value }
  </span>
)

LongTextDataElement.propTypes = {
  value: types.string,
}

export default LongTextDataElement
