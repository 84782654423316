import React from "react"
import { useSurveyReportExportContext } from "contexts/SurveyReportExportContext"
import { LOCATION_TYPE } from "views/Forms/FormElementTypes"
import { QuestionHistoryQuestionSelect } from "shared/selects"

const LocationAnswerFiltersQuestionSelect = () => {
  const {
    locationAnswerFilters,
    questions,
    setLocationAnswerFilterQuestionUuid,
  } = useSurveyReportExportContext()

  const locationQuestions = questions.filter((question) => question.type === LOCATION_TYPE)

  if (locationQuestions.length === 0) {
    return (
      <p>There are no location questions on the form associated with this report.</p>
    )
  }

  const locationAnswerFilter = locationAnswerFilters[0] ?? {}
  const { questionUuid } = locationAnswerFilter

  return (
    <QuestionHistoryQuestionSelect
      className="!w-full bordered-base-input mb-4"
      clearable
      onChange={setLocationAnswerFilterQuestionUuid}
      questions={locationQuestions}
      value={questionUuid}
    />
  )
}

export default LocationAnswerFiltersQuestionSelect
