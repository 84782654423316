import React from "react"
import types from "prop-types"
import { Draggable } from "react-beautiful-dnd"
import { useDispatch, useSelector } from "react-redux"
import {
  isFocusInSection,
  getFormSectionHasConditionals,
  getFormSectionIsLastSection,
  getFormSectionUuid,
  moveFormSection,
  setQuestionFocus,
} from "reduxSlices/formBuilderSlice"
import DragHandle from "shared/DragHandle"
import AddQuestionButton from "FormBuilder/UIElements/AddQuestionButton"
import ConditionalTag from "FormBuilder/UIElements/ConditionalTag"
import SectionName from "./SectionName"
import SectionDescription from "./SectionDescription"
import SectionQuestions from "./SectionQuestions"
import DeleteSectionButton from "../UIElements/DeleteSectionButton"

// The first section of a form is rendered with the MainSection component.
// Other sections are rendered with this component.
const Section = ({ sectionIndex }) => {
  const dispatch = useDispatch()

  const focusInSection = useSelector(isFocusInSection(sectionIndex))
  const isLastSection = useSelector(getFormSectionIsLastSection(sectionIndex))
  const sectionHasConditionals = useSelector(getFormSectionHasConditionals(sectionIndex))
  const sectionUuid = useSelector(getFormSectionUuid(sectionIndex))

  const setFocusOnSection = () => {
    dispatch(setQuestionFocus({ sectionIndex, questionIndex: null }))
  }

  const moveSection = (action) => dispatch(moveFormSection(action))

  const moveSectionUp = () => moveSection({
    from: { currentIndex: sectionIndex },
    to: { newIndex: sectionIndex - 1 },
  })

  const moveSectionDown = isLastSection
    ? undefined
    : () => moveSection({
      from: {
        currentIndex: sectionIndex,
      },
      to: {
        newIndex: sectionIndex + 1,
      },
    })

  return (
    <Draggable
      key={sectionUuid}
      draggableId={sectionUuid}
      index={sectionIndex}
    >
      {
        (dragProvided) => (
          <div
            className="relative mt-24"
            ref={dragProvided.innerRef}
            {...dragProvided.draggableProps}
          >
            <DragHandle
              className="absolute-vertical-center -left-32 bg-white"
              dragProvided={dragProvided}
              moveUp={moveSectionUp}
              moveDown={moveSectionDown}
              isFocused={focusInSection}
            />
            <div className="section-border" />
            <div className="absolute top-2 -right-12 flex items-center gap-2">
              {
                sectionHasConditionals && (
                  <ConditionalTag className="flex" />
                )
              }
              <DeleteSectionButton sectionIndex={sectionIndex} />
            </div>
            <div onClickCapture={setFocusOnSection}>
              <SectionName sectionIndex={sectionIndex} />
              <SectionDescription sectionIndex={sectionIndex} />
            </div>
            <SectionQuestions sectionIndex={sectionIndex} />
            <AddQuestionButton sectionIndex={sectionIndex} />
          </div>
        )
      }
    </Draggable>
  )
}

Section.propTypes = {
  sectionIndex: types.number.isRequired,
}

export default Section
