import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { FormIcon } from "shared/icons"

const SelectableIcon = ({ isSelected, icon, setSelectedIcon }) => (
  <div
    className={clsx(
      "flex items-center justify-center rounded-xl border-4",
      !isSelected && "border-transparent",
      isSelected && "border-lightgray-3",
    )}
  >
    <FormIcon
      className="w-9 h-9 m-px"
      form={{ icon }}
      onClick={() => setSelectedIcon(icon)}
    />
  </div>
)

SelectableIcon.propTypes = {
  isSelected: types.bool.isRequired,
  icon: types.string.isRequired,
  setSelectedIcon: types.func.isRequired,
}

export default SelectableIcon
