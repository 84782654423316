import React from "react"
import types from "prop-types"
import { useTaskFormContext } from "contexts/TaskFormContext"
/* eslint-disable-next-line max-len */
import TaskDepartmentList from "views/FormSubmission/FormSubmissionTabs/ActivitiesContent/TaskForms/TaskFields/UI/TaskDepartmentList"
import { departmentIdentifier } from "utils/taskHelpers"
import TaskAssigneeDepartmentSegments from "./TaskAssigneeDepartmentSegments"
import TaskDepartmentByIdLabel from "../UI/TaskDepartmentLabel/TaskDepartmentByIdLabel"

const TaskAssigneeDepartmentList = ({ className }) => {
  const {
    addAssigneeDepartmentNotification,
    addAssigneeDepartmentNotificationOnComplete,
    removeAssigneeDepartment,
    removeAssigneeDepartmentNotification,
    removeAssigneeDepartmentNotificationOnComplete,
    taskAssigneeDepartments,
  } = useTaskFormContext()

  if (taskAssigneeDepartments.length === 0) return null

  const toggleNotify = (dept) => () => {
    const toggleFunction = dept.notify ? removeAssigneeDepartmentNotification : addAssigneeDepartmentNotification
    toggleFunction(departmentIdentifier(dept))
  }

  const toggleNotifyOnComplete = (dept) => () => {
    const toggleFunction = dept.notifyOnComplete
      ? removeAssigneeDepartmentNotificationOnComplete
      : addAssigneeDepartmentNotificationOnComplete

    toggleFunction(departmentIdentifier(dept))
  }

  return (
    <div className={className}>
      <TaskDepartmentList
        className="mb-2"
        departments={taskAssigneeDepartments}
        removeDepartment={removeAssigneeDepartment}
        toggleNotify={toggleNotify}
        toggleNotifyOnComplete={toggleNotifyOnComplete}
        departmentLabelComponent={TaskDepartmentByIdLabel}
        segmentsComponent={TaskAssigneeDepartmentSegments}
      />
      <p className="text-xs text-dark md:w-4/5">
        Each assignee department will have each of its members in the selected segments
        assigned to the task individually.  Each department member in the selected segments
        will also be granted individual access to the form submission and be made a member
        of any tasks selected under &quot;Access to Other Tasks&quot;.
      </p>
    </div>
  )
}

TaskAssigneeDepartmentList.defaultProps = {
  className: "",
}

TaskAssigneeDepartmentList.propTypes = {
  className: types.string,
}

export default TaskAssigneeDepartmentList
