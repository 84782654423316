import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import pluralize from "pluralize"
import findById from "utils/findById"
import { getCategorySubforms, getUsers } from "reduxSlices/formBuilderSlice"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import { WorkflowActionTaskCreateIcon } from "shared/icons"
import ActionDetails from "./ActionDetails"
import ActionAbbreviatedUserList from "./ActionAbbreviatedUserList"
import ActionHeading from "./ActionHeading"
import ActionLabelContainer from "./ActionLabelContainer"

const RelativeDueDate = ({ task }) => (
  <div className="flex flex-row items-center mb-2">
    <span className="workflow-label mr-2">{ task.relative_due_date.number }</span>
    <span className="workflow-label mr-2">{ task.relative_due_date.period }</span>
    <span className="mr-2">after</span>
    <span className="workflow-label">@Submission Date</span>
  </div>
)

RelativeDueDate.propTypes = {
  task: types.shape({
    relative_due_date: types.shape({
      number: types.number.isRequired,
      period: types.string.isRequired,
    }).isRequired,
  }).isRequired,
}

const CreateTaskActionLabel = ({ action }) => {
  const subforms = useSelector(getCategorySubforms)

  const assignees = useSelector(getUsers).filter((potentialAssignee) => (
    action.data.task.assignee_ids.includes(potentialAssignee.id)
  ))

  const subform = findById(action.data.task.subform_id, subforms)
  const selectedDepartments = action.data?.task?.assignee_departments?.filter((dept) => dept.questionUUID) ?? []

  return (
    <ActionLabelContainer>
      <ActionHeading
        iconComponent={WorkflowActionTaskCreateIcon}
        text="Create this task:"
      />
      <ActionDetails>
        <span className="font-medium mb-2">{action.data.task.title}</span>
        <RelativeDueDate task={action.data.task} />
        {
          subform && (
            <div className="flex items-center mb-2 workflow-label">
              <SvgSpriteIcon iconName="task-form" width={20} height={20} className="mr-1" />
              <span>{ subform.title }</span>
            </div>
          )
        }
        assigned to: <ActionAbbreviatedUserList users={assignees} />
        {
          selectedDepartments.length > 0 && (
            <span className="mt-1 text-token p-1.5">
              Selected Location {pluralize("Department", selectedDepartments.length)}
            </span>
          )
        }
      </ActionDetails>
    </ActionLabelContainer>
  )
}

CreateTaskActionLabel.propTypes = {
  action: types.shape({
    data: types.shape({
      task: types.shape({
        title: types.string.isRequired,
        assignee_ids: types.arrayOf(types.number).isRequired,
        subform_id: types.oneOfType([types.number, types.string]),
        // eslint-disable-next-line react/forbid-prop-types
        relative_due_date: types.object.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        assignee_departments: types.arrayOf(types.object),
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export default CreateTaskActionLabel
