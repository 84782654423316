import React from "react"
import types from "prop-types"
import { Button } from "shared/buttons"

const AddQuestionTagButton = ({ onClick }) => (
  <Button
    className="border border-light-300 text-focus"
    text="+ Add Question Tag"
    onClick={onClick}
  />
)

AddQuestionTagButton.propTypes = {
  onClick: types.func.isRequired,
}

export default AddQuestionTagButton
