import React, { useCallback, useState } from "react"
import types from "prop-types"
import AddFacilityGroupCodeButton from "./AddFacilityGroupCodeButton"
import NewFacilityGroupCodeForm from "./NewFacilityGroupCodeForm"

const AddFacilityGroupCode = ({ addFacilityGroupCode, facilityGroupCodeNames }) => {
  const [showForm, setShowForm] = useState(false)

  const openForm = useCallback(() => setShowForm(true), [setShowForm])

  const closeForm = useCallback(() => setShowForm(false), [setShowForm])

  return (
    showForm
      ? (
        <NewFacilityGroupCodeForm
          addFacilityGroupCode={addFacilityGroupCode}
          className="solution-container-dark"
          onCancel={closeForm}
          facilityGroupCodeNames={facilityGroupCodeNames}
        />
      )
      : <AddFacilityGroupCodeButton onClick={openForm} />
  )
}

AddFacilityGroupCode.propTypes = {
  addFacilityGroupCode: types.func.isRequired,
  facilityGroupCodeNames: types.arrayOf(types.string).isRequired,
}

export default AddFacilityGroupCode
