/* eslint-disable camelcase */
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { categoryShape } from "utils/propTypeShapes"
import * as API from "services/api"
import { GroupAndUserSearch } from "shared/searches"
import StrikeAround from "shared/StrikeAround"
import {
  groups as getGroups,
  users as getUsers,
} from "reduxSlices/categorySlice"
import GroupGranteeList from "./GroupGranteeList"
import UserGranteeList from "./UserGranteeList"

const AccessTab = ({ category }) => {
  const categorySlug = category.slug
  const [granteeLists, setGranteeLists] = useState({
    groups: [],
    users: [],
  })
  const updateAccessGranteeLists = (data) => setGranteeLists(data)

  const groups = useSelector(getGroups)
  const users = useSelector(getUsers)

  useEffect(() => {
    (async function fetchAccessGrantees() {
      const response = await API.getCategoryAccessGrantList({ categorySlug })

      if (response.ok) {
        setGranteeLists(response.data)
      } else {
        console.error("Error fetching category access grants: ", response)
      }
    }())
  }, [categorySlug, setGranteeLists])

  const createAccessLevelGrant = async (value) => {
    const [grantee_type, grantee_id] = value.split("-")
    const access_level_grant = { grantee_id, grantee_type }

    const response = await API.createCategoryAccessGrant({
      categorySlug,
      access_level_grant,
    })
    if (response.ok) {
      setGranteeLists(response.data)
    } else {
      console.error("Error creating access level grant", response)
    }
  }

  const deleteAccessLevelGrant = (grantId) => async () => {
    const response = await API.deleteCategoryAccessGrant({
      categorySlug: category.slug,
      grantId,
    })
    if (response.ok) {
      updateAccessGranteeLists(response.data)
    } else {
      console.error("Error deleting category grant: ", response);
    }
  }

  return (
    <>
      <h2 className="tab-title">Access</h2>
      <GroupAndUserSearch
        selectedGroups={granteeLists.groups}
        selectedUsers={granteeLists.users}
        onSearchSelected={createAccessLevelGrant}
        users={users}
        groups={groups}
      />
      <StrikeAround>
        <h3 className="font-secondary font-medium">Groups</h3>
      </StrikeAround>
      <GroupGranteeList
        category={category}
        updateAccessGranteeLists={updateAccessGranteeLists}
        deleteAccessLevelGrant={deleteAccessLevelGrant}
        groups={granteeLists.groups}
      />
      <StrikeAround>
        <h3 className="font-secondary font-medium">Individuals</h3>
      </StrikeAround>
      <UserGranteeList
        category={category}
        updateAccessGranteeLists={updateAccessGranteeLists}
        deleteAccessLevelGrant={deleteAccessLevelGrant}
        users={granteeLists.users}
      />
    </>
  )
}

AccessTab.propTypes = {
  category: categoryShape.isRequired,
}

export default AccessTab
