import React from "react"
import types from "prop-types"
import WorkflowsTab from "components/shared/WorkflowsTab"

const GroupWorkflowsTab = ({ groupWorkflows }) => (
  <WorkflowsTab associatedWorkflows={groupWorkflows} groupOrUser="group" />
)

GroupWorkflowsTab.propTypes = {
  groupWorkflows: types.arrayOf(types.object).isRequired,
}

export default GroupWorkflowsTab
