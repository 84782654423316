import React from "react";
import types from "prop-types"
import { withFormsy } from "formsy-react"
import { questionShape } from "utils/propTypeShapes"
import { Select } from "shared/selects"

const NO_PROMPT = "< no prompt >"

const optionsFromQuestions = (questions) => questions.map((question) => ({
  label: question.prompt || NO_PROMPT,
  value: question.uuid,
}))

const QuestionSelect = ({
  selectedQuestionUuid = undefined,
  questions = [],
  onChange,
  ...rest
}) => (
  <Select
    options={optionsFromQuestions(questions)}
    value={selectedQuestionUuid}
    onChange={onChange}
    isFullHeight
    {...rest}
  />
)

QuestionSelect.propTypes = {
  onChange: types.func.isRequired,
  questions: types.arrayOf(questionShape),
  selectedQuestionUuid: types.string,
}

export const FormsyQuestionSelect = withFormsy(({ setValue, ...rest }) => (
  <QuestionSelect {...rest} onChange={setValue} />
))

FormsyQuestionSelect.displayName = "FormsyQuestionSelect"

export default QuestionSelect
