import React, { useEffect, useState } from "react"
import types from "prop-types"
import * as API from "services/api"
import { errorToast } from "shared/toast"
import { groupAudit } from "utils/auditHelper"
import FacilityAuditTrailContext from "../FacilityAuditTrailContext"

const FacilityAuditTrailContextProvider = ({ children, facilityId }) => {
  // For loading the facility's audits
  const [auditList, setAuditList] = useState([])
  const [page, setPage] = useState(1)
  const [allAuditsLoaded, setAllAuditsLoaded] = useState(false)

  const fetchAudits = async () => {
    if (!facilityId) return

    const response = await API.getFacilityAudits({ facilityId, page })

    if (response.ok) {
      if (response.data.length === 0) {
        setAllAuditsLoaded(true)
      } else {
        setPage(page + 1)
        setAuditList(auditList.concat(response.data))
      }
    } else {
      errorToast("Something went wrong. Unable to load the facility's audit trail.")
    }
  }

  // If the facility id changes, we need to reset the state of the component,
  // fetching the first page of audits for the facility.
  useEffect(() => {
    const initializeStateForFacility = async () => {
      const auditsResponse = await API.getFacilityAudits({ facilityId, page: 1 })

      if (auditsResponse.ok) {
        setAllAuditsLoaded(auditsResponse.data.length === 0)
        setAuditList(auditsResponse.data)
        setPage(2)
      } else {
        errorToast("Something went wrong. Unable to load the facility's audit trail.")
      }
    }

    initializeStateForFacility()
  }, [facilityId])

  const contextConsumerValue = {
    allAuditsLoaded,
    auditGroups: groupAudit(auditList),
    fetchAudits,
  }

  return (
    <FacilityAuditTrailContext.Provider value={contextConsumerValue}>
      {children}
    </FacilityAuditTrailContext.Provider>
  )
}

FacilityAuditTrailContextProvider.propTypes = {
  children: types.node.isRequired,
  facilityId: types.number.isRequired,
}

export default FacilityAuditTrailContextProvider
