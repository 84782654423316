import React from "react"
import types from "prop-types"
import AcceptedFileList from "./AcceptedFileList"
import RejectedFileList from "./RejectedFileList"

const UploadingFormSubmissionAttachments = ({ className = "" }) => (
  <div className={className}>
    <AcceptedFileList />
    <RejectedFileList />
  </div>
)

UploadingFormSubmissionAttachments.propTypes = {
  className: types.string,
}

export default UploadingFormSubmissionAttachments
