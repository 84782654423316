import React from "react"
import types from "prop-types"
import clsx from "clsx"
import FormIcon from "components/shared/icons/FormIcon"
import { formShape } from "utils/propTypeShapes"

const notOnLandingPageDescription = (form) => {
  if (form.active && form.listed) return ""
  if (form.active && !form.listed) return "unlisted"
  if (!form.active && form.listed) return "inactive"

  return "inactive, unlisted"
}

const FormBar = ({ form, handle = undefined }) => {
  const notOnLandingPage = !(form.active && form.listed)

  return (
    <div className="flex flex-row w-full items-center relative">
      <div className="list-row relative bg-primary-light w-full">
        <span className="absolute-vertical-center -left-8">{handle}</span>
        <div className={clsx("flex w-full items-center justify-between", notOnLandingPage && "text-gray")}>
          <div className="flex items-center">
            <FormIcon form={form} className={clsx("w-8 h-8", notOnLandingPage && "opacity-20")} />
            <p className="ml-2">{form.title}</p>
          </div>
          {
            notOnLandingPage && (
              <span>{notOnLandingPageDescription(form)}</span>
            )
          }
        </div>
      </div>
    </div>
  )
}

FormBar.propTypes = {
  form: formShape.isRequired,
  handle: types.node,
}

export default FormBar
