import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"

const TaskEmailSentAuditItemDetails = ({ audit }) => (
  <div>
    <span>
      <AuditedUser audit={audit} /> {audit.comment}.
    </span>
  </div>
)

TaskEmailSentAuditItemDetails.propTypes = {
  audit: auditShape.isRequired,
}

export default TaskEmailSentAuditItemDetails
