import React from "react"
import types from "prop-types"
import { reportExportSolutionLevelReportShape } from "utils/propTypeShapes"
import { SolutionLevelScheduledReportExportContextProvider } from "contexts/SolutionLevelScheduledReportExportContext"
import NewSolutionLevelScheduledReportExportForm from "../NewSolutionLevelScheduledReportExport/NewSolutionLevelScheduledReportExportForm"

const EditSolutionLevelScheduledReportExport = ({ solutionLevelReport, solutionLevelScheduledReportExport }) => (
  <SolutionLevelScheduledReportExportContextProvider
    solutionLevelReport={solutionLevelReport}
    solutionLevelScheduledReportExport={solutionLevelScheduledReportExport}
  >
    <NewSolutionLevelScheduledReportExportForm />
  </SolutionLevelScheduledReportExportContextProvider>
)

EditSolutionLevelScheduledReportExport.propTypes = {
  solutionLevelReport: reportExportSolutionLevelReportShape.isRequired,
  solutionLevelScheduledReportExport: types.object.isRequired,
}

export default EditSolutionLevelScheduledReportExport
