import React from "react"
import types from "prop-types"
import clsx from "clsx"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import {
  ACTIVITY_HUB_DASHBOARD_PATH,
  ACTIVITY_HUB_SUBMISSIONS_PATH,
  ACTIVITY_HUB_TASKS_PATH,
  ACTIVITY_HUB_FORMS_PATH,
  ACTIVITY_HUB_REPORTS_PATH,
  ACTIVITY_HUB_SURVEYS_PATH,
  ADMIN_PATH,
} from "utils/routeHelpers"

const DEFAULT_TABS = [{
  title: "Home",
  icon: "home",
  path: ACTIVITY_HUB_DASHBOARD_PATH,
}, {
  title: "Submissions",
  icon: "submissions",
  path: ACTIVITY_HUB_SUBMISSIONS_PATH,
}, {
  title: "Tasks",
  icon: "tasks",
  path: ACTIVITY_HUB_TASKS_PATH,
}, {
  title: "Forms",
  icon: "forms",
  path: ACTIVITY_HUB_FORMS_PATH,
}, {
  title: "Reports",
  icon: "reports",
  path: ACTIVITY_HUB_REPORTS_PATH,
},
]

const ADDITIONAL_TABS = {
  surveys: {
    title: "Surveys",
    icon: "surveys",
    path: ACTIVITY_HUB_SURVEYS_PATH,
  },
  admin: {
    title: "Admin",
    icon: "admin",
    path: ADMIN_PATH,
  },
}

const GlobalHeaderTabs = ({ currentTab = "", additionalTabs = [], mobileMenuVisible = false }) => {
  const allTabs = [...DEFAULT_TABS]

  if (additionalTabs.includes("surveys")) {
    allTabs.splice(4, 0, ADDITIONAL_TABS.surveys)
  }

  if (additionalTabs.includes("admin")) {
    allTabs.push(ADDITIONAL_TABS.admin)
  }

  const outerStyles = clsx(
    "flex justify-end overflow-hidden mr-auto z-1",
    "absolute top-[57px] right-0 w-full items-start h-[calc(100vh-57px)]",
    "lg:relative lg:top-0 lg:h-auto lg:w-auto lg:justify-start lg:overflow-visible",
    !mobileMenuVisible && "hidden",
  )

  const innerStyles = clsx(
    "flex flex-col bg-white items-start p-6 w-11/12 max-w-sm h-full shadow-php-xl",
    "lg:flex-row lg:bg-primary-light lg:p-0 lg:w-max lg:max-w-full lg:shadow-none",
  )

  return (
    <div className={outerStyles}>
      <div className={innerStyles}>
        {
          allTabs.map(({ title, icon, path }) => (
            <a
              key={title}
              className={clsx("header-tab w-full flex justify-start items-center", currentTab === icon && "active")}
              href={path}
            >
              <SvgSpriteIcon
                iconName={icon}
                svgIdAttribute={currentTab === icon ? "active" : ""}
                className="header-icon"
              />
              <span>{ title }</span>
            </a>
          ))
        }
      </div>
    </div>
  )
}

GlobalHeaderTabs.propTypes = {
  currentTab: types.string,
  additionalTabs: types.arrayOf(types.string),
  mobileMenuVisible: types.bool,
}

export default GlobalHeaderTabs
