import React from "react"
import types from "prop-types"

const StatusDataElement = ({ value }) => (
  <div className="w-max p-1.5 rounded flex items-center bg-light-100">
    <div className="text-sm font-medium tracking-tight">
      { value }
    </div>
  </div>
)

StatusDataElement.propTypes = {
  value: types.string.isRequired,
}

export default StatusDataElement
