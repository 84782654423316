import { useState } from "react"

const useHiddenSidebar = (hidden = true) => {
  const [hiddenSidebar, setHiddenSidebar] = useState(hidden)
  const toggleSidebar = () => setHiddenSidebar(!hiddenSidebar)

  return {
    hiddenSidebar,
    toggleSidebar,
  }
}

export default useHiddenSidebar
