import React from "react"
import { useSelector } from "react-redux"
import { hasUnread } from "reduxSlices/featureAnnouncementSlice"
import { BellIcon, IndicatorIcon } from "shared/icons"
import IconTapTarget from "shared/IconTapTarget"

const PanelTrigger = () => {
  const unread = useSelector(hasUnread)

  return (
    <IconTapTarget className="flex justify-center items-center relative">
      <BellIcon className="cursor-pointer" />
      {
        unread && (
          <IndicatorIcon className="absolute top-[1.125rem] right-3 cursor-pointer" />
        )
      }
    </IconTapTarget>
  )
}

export default PanelTrigger
