import { createSlice } from "@reduxjs/toolkit";

const SLICE_NAME = "addOnFeatureSubscriptions"

const INITIAL_STATE = {}

export const addOnFeatureSubscriptionsSlice = createSlice({
  name: SLICE_NAME,
  initialState: { ...INITIAL_STATE },
  reducers: {
    setSubscriptions: (state, action) => {
      Object.assign(state, action.payload)
    },
  },
})

export const { setSubscriptions } = addOnFeatureSubscriptionsSlice.actions

export const subscribedToAddOnFeature = (featureName) => (state) => state[SLICE_NAME][featureName].subscribed

export const addOnFeatureConfigurationEnabled = (featureName, property) => (state) => {
  const subscribed = subscribedToAddOnFeature(featureName)(state)

  if (!subscribed) return false

  const configuration = state[SLICE_NAME][featureName].configurations.find((c) => c.property === property)

  if (!configuration) return false

  return configuration.enabled
}

export default addOnFeatureSubscriptionsSlice.reducer
