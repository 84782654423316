import React from "react"
import { useSurveyConfigurationContext } from "contexts/SurveyConfigurationContext"
import BatchesList from "./BatchesList"
import BatchesEmpty from "./BatchesEmpty"

export const BATCHES_HEADING = "Batches"

const SurveyConfigurationBatchesTab = () => {
  const { surveyConfigurationHasBatches } = useSurveyConfigurationContext()

  return (
    <>
      <h2 className="tab-title">{ BATCHES_HEADING }</h2>
      {
        surveyConfigurationHasBatches
          ? <BatchesList />
          : <BatchesEmpty />
      }
    </>
  )
}

export default SurveyConfigurationBatchesTab
