import React from "react"
import types from "prop-types"
import { SearchSelectedUser } from "shared/searches"

const MemberList = ({ members, deleteGroupMembership }) => (
  members.map((member) => (
    <SearchSelectedUser
      key={member.id}
      user={member}
      deselect={() => deleteGroupMembership(member.id)}
    />
  ))
)

MemberList.propTypes = {
  members: types.arrayOf(types.object).isRequired,
  deleteGroupMembership: types.func.isRequired,
}

export default MemberList
