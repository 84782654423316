import { useContext } from "react"
import SurveyScheduledReportExportContext from "../SurveyScheduledReportExportContext"

const useSurveyScheduledReportExportContext = () => {
  const providedValue = useContext(SurveyScheduledReportExportContext)

  return { ...providedValue }
}

export default useSurveyScheduledReportExportContext
