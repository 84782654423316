import React from "react"
import types from "prop-types"
import Modal, { DEFAULT_MODAL_STYLE } from "shared/Modal"
import { PrimaryButton, Button } from "shared/buttons"
import useCountdownSeconds from "./useCountdownSeconds"

const SessionTimeoutModal = ({ modalIsOpen, onContinue, onTimeout }) => {
  const { secondsToTimeout } = useCountdownSeconds({ onFinishedCountdown: onTimeout })

  return (
    <Modal isOpen={modalIsOpen} style={DEFAULT_MODAL_STYLE}>
      <div className="text-center text-xl font-semibold mb-8">
        Session Timeout
      </div>
      <p>
        You&apos;re being timed out due to inactivity. Select &ldquo;Stay Logged In&rdquo; to stay logged in.
        Otherwise, you will be logged off automatically.
      </p>
      <div className="flex justify-center mt-8">
        <Button
          text="Log Off"
          onClick={onTimeout}
          className="border border-light-300 mr-2"
        />
        <PrimaryButton
          text={`Stay Logged In (${secondsToTimeout})`}
          onClick={onContinue}
        />
      </div>
    </Modal>
  )
}

SessionTimeoutModal.propTypes = {
  modalIsOpen: types.bool.isRequired,
  onContinue: types.func.isRequired,
  onTimeout: types.func.isRequired,
}

export default SessionTimeoutModal
