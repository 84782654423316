import { useContext } from "react"
import ReportContext from "../ReportContext"

const useReportContext = () => {
  const providedValue = useContext(ReportContext)

  return { ...providedValue }
}

export default useReportContext
