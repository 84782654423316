/* eslint-disable camelcase */
import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"

const externalIdDescription = ({ external_identifier }) => {
  if (!external_identifier) return null

  return (
    <>with external identifier <span className="italic">{external_identifier}</span></>
  )
}

const DepartmentCreated = ({ audit }) => {
  const { auditedChanges, comment } = audit
  const { name } = auditedChanges

  return (
    <div>
      <span>
        <AuditedUser audit={audit} /> added department <span className="font-semibold">{name}</span> to
        the facility {externalIdDescription(auditedChanges)} {comment}
      </span>
    </div>
  )
}

DepartmentCreated.propTypes = {
  audit: auditShape.isRequired,
}

export default DepartmentCreated
