import React from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { addQuestionFlagCondition, getFormQuestionHasFlagCondition } from "reduxSlices/formBuilderSlice"
import ConditionDefiner from "views/FormBuilder/QuestionFlagging/ConditionDefiner"
import AddConditionButton from "views/FormBuilder/UIElements/AddConditionButton"

const QuestionFlagCondition = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const questionHasFlagCondition = useSelector(getFormQuestionHasFlagCondition({ sectionIndex, questionIndex }))

  const addFlagConditionToQuestion = () => dispatch(addQuestionFlagCondition({ sectionIndex, questionIndex }))

  return (
    <>
      <div className="font-medium mb-3">
        Flag the submission if question...
      </div>
      <ConditionDefiner sectionIndex={sectionIndex} questionIndex={questionIndex} />
      {
        !questionHasFlagCondition
          ? <AddConditionButton addCondition={addFlagConditionToQuestion} />
          : null
      }
    </>
  )
}

QuestionFlagCondition.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default QuestionFlagCondition
