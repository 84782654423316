import React from "react"
import types from "prop-types"
import clsx from "clsx"

const ContentBar = ({
  title,
  description = "",
  descriptionClassName = "",
  children = null,
  footer = null,
}) => (
  <div className="w-full p-4 mb-2 rounded-lg bg-primary-light">
    <div className={clsx(
      footer && "mb-2",
      "flex justify-between items-start",
      !description && "items-center",
    )}
    >
      <div>
        <p className={clsx(description && "mb-2", "font-bold")}>
          { title }
        </p>
        {
          description && <p className={clsx(descriptionClassName, "mb-2 text-dark whitespace-pre-wrap")}>{ description }</p>
        }
      </div>
      { children }
    </div>
    {
      footer && <div>{ footer }</div>
    }
  </div>
)

ContentBar.propTypes = {
  title: types.string.isRequired,
  description: types.string,
  descriptionClassName: types.string,
  children: types.node,
  footer: types.node,
}

export default ContentBar
