import React from "react"
import types from "prop-types"
import { workflowActionShape } from "utils/propTypeShapes"
import { RemoveButton } from "shared/buttons"
import DefineActionData from "./DefineActionData"
import DefineActionType from "./DefineActionType"
import ActionLabel from "../ActionLabel"

const Action = ({
  action,
  formSlug,
  changeActionData,
  changeActionSubtype,
  changeActionType,
  actionIndex,
  removeAction,
}) => (
  <div className="flex justify-between items-center mb-4">
    <div className="border border-lightgray-3 rounded p-4 flex-grow mr-4 flex flex-wrap items-center justify-between">
      <DefineActionType
        action={action}
        actionIndex={actionIndex}
        changeActionType={changeActionType}
        changeActionSubtype={changeActionSubtype}
      />
      <ActionLabel
        action={action}
      />
      <DefineActionData
        action={action}
        formSlug={formSlug}
        changeActionData={changeActionData}
        actionIndex={actionIndex}
      />
    </div>
    <RemoveButton onClick={removeAction} />
  </div>
)

Action.propTypes = {
  action: workflowActionShape.isRequired,
  changeActionData: types.func.isRequired,
  changeActionSubtype: types.func.isRequired,
  changeActionType: types.func.isRequired,
  removeAction: types.func.isRequired,
  actionIndex: types.number.isRequired,
  formSlug: types.string.isRequired,
}

export default Action
