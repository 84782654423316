import React from "react"
import types from "prop-types"
import { TextInput } from "shared/inputs"
import FormsyValidation from "shared/FormsyValidation"

const WorkflowName = ({ name = "", setWorkflow }) => (
  <div>
    <TextInput
      className="bordered-base-input border-none p-0 m-0"
      required
      hasBaseInputStyle={false}
      placeholder="Workflow name..."
      value={name}
      onChange={(value) => setWorkflow((wf) => ({ ...wf, name: value }))}
    />
    <FormsyValidation
      name="name"
      value={name}
      validations={{ isNotBlankString: true }}
      validationErrors={{ isDefaultRequiredValue: "required" }}
    />
  </div>
)

WorkflowName.propTypes = {
  name: types.string,
  setWorkflow: types.func.isRequired,
}

export default WorkflowName
