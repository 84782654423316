import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { FormIcon, TaskFormsIcon } from "shared/icons"
import { SURVEY_USAGE } from "utils/formHelpers"

const ICON_STYLES = "w-12 h-12 mb-3"

const FormTileIcon = ({ form }) => {
  if (form.isSubform) {
    return <TaskFormsIcon className={clsx(ICON_STYLES)} />
  }

  if (!form.active || form.usage === SURVEY_USAGE) {
    return <FormIcon className={ICON_STYLES} form={form} />
  }

  return (
    <a href={`/forms/${form.slug}`} className="inline-block w-min" target="_blank" rel="noopener noreferrer">
      <FormIcon className={ICON_STYLES} form={form} />
    </a>
  )
}

FormTileIcon.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  form: types.object.isRequired,
}

export default FormTileIcon
