import React from "react"
import IconSetting from "./IconSetting"
import FormTooltipDescription from "./FormTooltipDescription"
import FormSolutionSetting from "./FormSolutionSetting"
import FormAcceptsSubmittedFilesSetting from "./FormAcceptsSubmittedFilesSetting"
import FormActiveSetting from "./FormActiveSetting"
import FormListedSetting from "./FormListedSetting"
import FormPublicSubmissionStatusSetting from "./FormPublicSubmissionStatusSetting"

const FormSettingsTab = () => (
  <div className="inner-container w-full max-w-screen-lg mx-auto">
    <h1 className="header-title !mb-8">Form Settings</h1>
    <div className="p-12 bg-white border rounded-lg border-lightgray-3">
      <IconSetting />
      <FormSolutionSetting />
      <FormActiveSetting />
      <FormListedSetting />
      <FormAcceptsSubmittedFilesSetting />
      <FormPublicSubmissionStatusSetting />
      <FormTooltipDescription />
    </div>
  </div>
)

export default FormSettingsTab
