import React from "react"
import types from "prop-types"
import { Select } from "shared/selects"
import { useSelector } from "react-redux"
import { workflowConditionShape, questionShape } from "utils/propTypeShapes"
import { getFormQuestions } from "reduxSlices/formBuilderSlice"
import {
  SELECT_TYPE,
  SELECT_CSV_TYPE,
  MULTI_SELECT_TYPE,
  LOCATION_TYPE,
} from "views/Forms/FormElementTypes"
import {
  IS_EMPTY_PROPERTY_OP,
  IS_NOT_EMPTY_PROPERTY_OP,
} from "utils/workflowHelpers"
import LocationQuestionType from "./LocationQuestionType"

const AnswerSelect = ({ condition, question, changeConditionValue }) => {
  const options = question?.attributes?.options || []
  const optionsList = options.map((option) => ({
    value: option,
    label: option,
  }))

  const updateValue = (option) => changeConditionValue({ option })

  return (
    <Select
      options={optionsList}
      value={condition.value?.option}
      placeholder="Select option"
      style={{ height: "32px" }}
      onChange={updateValue}
    />
  )
}

AnswerSelect.propTypes = {
  condition: workflowConditionShape.isRequired,
  question: questionShape.isRequired,
  changeConditionValue: types.func.isRequired,
}

const DefineQuestionAnswerValue = ({ condition, changeConditionValue }) => {
  const questions = useSelector(getFormQuestions)
  const question = questions.find(({ uuid }) => uuid === condition.subProperty?.questionUuid)

  if ([IS_EMPTY_PROPERTY_OP, IS_NOT_EMPTY_PROPERTY_OP].includes(condition.operator)) {
    return null
  }

  switch (question?.type) {
  case SELECT_TYPE:
  case SELECT_CSV_TYPE:
  case MULTI_SELECT_TYPE:
    return (
      <AnswerSelect
        condition={condition}
        question={question}
        changeConditionValue={changeConditionValue}
      />
    )
  case LOCATION_TYPE:
    return (
      <LocationQuestionType condition={condition} changeConditionValue={changeConditionValue} />
    )
  // Earlier cases return, shouldn't fall through
  // eslint-disable-next-line no-fallthrough
  default:
    return null
  }
}

DefineQuestionAnswerValue.propTypes = {
  condition: workflowConditionShape.isRequired,
  changeConditionValue: types.func.isRequired,
}

export default DefineQuestionAnswerValue
