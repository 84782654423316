import React from "react"
import types from "prop-types"
import clsx from "clsx"
import Content from "./AccordionContent"

const CustomHeaderAccordion = ({
  className = "", header, content, isExpanded,
}) => (
  <div className={clsx(className)}>
    { header }
    <Content isExpanded={isExpanded}>
      { content }
    </Content>
  </div>
)

CustomHeaderAccordion.propTypes = {
  className: types.string,
  header: types.node.isRequired,
  content: types.node.isRequired,
  isExpanded: types.bool.isRequired,
}

export default CustomHeaderAccordion
