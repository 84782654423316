import React from "react";
import types from "prop-types"
import { withFormsy } from "formsy-react"
import { userOptionShape } from "utils/propTypeShapes"
import { Select } from "shared/selects"
import UserDropdown from "./UserDropdown"
import SearchInput from "../SearchInput"

// value is prefixed with model name to differentiate the ids
const usersToOptions = (users) => users.map(({
  firstName, lastName, fullName, id,
}) => ({
  label: fullName, firstName, lastName, value: `${id}`,
}))

const UserSelect = ({
  placeholder = "Search for users",
  className = "",
  onChange,
  users = [],
  value = undefined,
  ...rest
}) => {
  const userOptions = usersToOptions(users)

  return (
    <Select
      className={className}
      isFullWidth
      contentRenderer={SearchInput}
      dropdownRenderer={UserDropdown(userOptions)}
      placeholder={placeholder}
      onChange={onChange}
      dropdownHandle={false}
      options={userOptions}
      searchable
      backspaceDelete={false}
      value={value}
      {...rest}
    />
  )
}

UserSelect.propTypes = {
  className: types.string,
  onChange: types.func.isRequired,
  placeholder: types.string,
  users: types.arrayOf(userOptionShape),
  value: types.arrayOf(types.number),
}

export default UserSelect

export const FormsyUserSelect = withFormsy(({ setValue, ...rest }) => (
  <UserSelect onChange={setValue} {...rest} />
))

FormsyUserSelect.displayName = "FormsyUserSelect"
