import React from "react"
import types from "prop-types"
import { dynamicRangeAnswersExample, dayRangeAnswerInclusionDescription } from "utils/scheduledReportExportHelpers"

const DaysAnswerDescription = ({ startDate, dateAnswerPreviousRangeLength = null }) => {
  const daysCount = dateAnswerPreviousRangeLength || 1
  const beginningDate = startDate.subtract(daysCount, "d")
  const endingDate = startDate.subtract(1, "d")

  return (
    <div className="text-xs text-dark mt-2">
      <p className="mb-4">
        {dayRangeAnswerInclusionDescription}
      </p>
      <p>
        {dynamicRangeAnswersExample({ startDate, beginningDate, endingDate })}
      </p>
    </div>
  )
}

DaysAnswerDescription.propTypes = {
  startDate: types.object.isRequired,
  dateAnswerPreviousRangeLength: types.number,
}

export default DaysAnswerDescription
