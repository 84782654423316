import React from "react"
import types from "prop-types"
import Select from "react-dropdown-select"
import { capitalize } from "utils/stringHelpers"
import IconSelectorDropdown from "./IconSelectorDropdown"
import { FORM_ICONS, FORM_ICON_COLORS } from "./constants"

const iconOptions = FORM_ICONS.map((opt) => ({ label: "", value: opt, icon: opt }))
const colorOptions = FORM_ICON_COLORS.map((opt) => ({ label: capitalize(opt), value: opt, iconColor: opt }))

const IconSelector = ({ updateIcon, icon, iconColor }) => (
  <div className="component-border-thin my-3">
    <Select
      className="base-input icon-selector"
      backspaceDelete={false}
      iconOptions={iconOptions}
      colorOptions={colorOptions}
      dropdownHeight="800px"
      contentRenderer={() => (<div className="ml-2">Change Form Icon</div>)}
      dropdownRenderer={IconSelectorDropdown}
      style={{ paddingLeft: 0 }}
      dropdownGap={0}
      multi
      icon={icon}
      iconColor={iconColor}
      updateIcon={updateIcon}
    />
  </div>
)

IconSelector.propTypes = {
  updateIcon: types.func.isRequired,
  icon: types.string.isRequired,
  iconColor: types.string.isRequired,
}

export default IconSelector
