import React from "react"
import UsersAssignedList from "./UsersAssignedList"

const DisplayOnlyMembers = () => (
  <>
    <p className="text-sm text-dark my-2">Current Department Members:</p>
    <UsersAssignedList />
    <div className="text-xs text-dark">
      <p>Instructions:</p>
      <p className="mb-4">
        Your organization manages department memberships via SCIM user provisioning.
        Therefore, you may not add users to or remove users from a department.
      </p>
      <p className="mb-4">
        SCIM user provisioning places new department members in Segment A.
        You may move a user from one segment to another by dragging the user&apos;s avatar
        to the desired segment.
      </p>
      <p>
        If any department memberships appear incorrect, contact your internal support.
      </p>
    </div>
  </>
)

export default DisplayOnlyMembers
