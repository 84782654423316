import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { getFormQuestions } from "reduxSlices/formBuilderSlice"
import { useTaskFormContext } from "contexts/TaskFormContext"
import { LOCATION_TYPE } from "views/Forms/FormElementTypes"
/* eslint-disable max-len */
import TaskDepartmentByQuestionSelector from "views/FormSubmission/FormSubmissionTabs/ActivitiesContent/TaskForms/TaskFields/UI/TaskDepartmentByQuestionSelector"
/* eslint-enable max-len */

const TaskAssigneeDepartmentSelector = () => {
  const {
    addAssigneeDepartmentByQuestionUUID,
    taskAssigneeDepartments,
  } = useTaskFormContext()

  const selectedQuestionUUIDs = new Set(taskAssigneeDepartments?.map((dept) => dept.questionUUID))
  const allQuestions = useSelector(getFormQuestions)

  const selectableQuestions = allQuestions.filter((question) => (
    question.type === LOCATION_TYPE && !selectedQuestionUUIDs.has(question.uuid)
  ))

  useEffect(() => {
    window.dispatchEvent(new window.Event("resize"))
  }, [taskAssigneeDepartments])

  if (selectableQuestions.length === 0) return null

  return (
    <TaskDepartmentByQuestionSelector
      onChange={addAssigneeDepartmentByQuestionUUID}
      questions={selectableQuestions}
    />
  )
}

export default TaskAssigneeDepartmentSelector
