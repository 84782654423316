import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { isFeatureEnabled } from "reduxSlices/featuresSlice"
import { FORM_FILE_UPLOADS_FEATURE } from "utils/featuresHelpers"
import { Checkbox } from "shared/checkboxes"

const FilesToUploadCheckbox = ({ checked, onChange }) => {
  const formFileUploadsFeatureEnabled = useSelector(isFeatureEnabled(FORM_FILE_UPLOADS_FEATURE))

  if (!formFileUploadsFeatureEnabled) return null

  return (
    <div className="bg-white pb-8">
      <div className="public-form-content">
        <Checkbox
          className="mr-2 cursor-pointer"
          uuid="add-uploads-checkbox"
          value={checked}
          onChange={onChange}
        />
        <label htmlFor="add-uploads-checkbox" className="cursor-pointer">
          I have files to attach.
        </label>
      </div>
    </div>
  )
}

FilesToUploadCheckbox.propTypes = {
  checked: types.bool.isRequired,
  onChange: types.func.isRequired,
}

export default FilesToUploadCheckbox
