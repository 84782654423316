import React from "react"
import types from "prop-types"
import { store } from "store"
import { useSelector } from "react-redux"
import Table from "shared/Table"
import { surveyBatchPath } from "utils/routeHelpers"
import {
  getColumns,
  getSurveyBatchTable,
  setCurrentSort,
  ENTRY_CLASS_FUNCTIONS,
} from "reduxSlices/surveyBatchTableSlice"

const rowHrefFunc = (rowData) => surveyBatchPath(rowData.id)

const BatchesList = ({ batches }) => {
  const columns = useSelector(getColumns)
  const { currentSort } = useSelector(getSurveyBatchTable)
  const onSort = (column) => store.dispatch(setCurrentSort(column))

  return (
    <div>
      <Table
        className="submissions" // Using submissions styling as a starting point. Potentially rename to something more generic?
        rowKey="id"
        columns={columns}
        currentSort={currentSort}
        onSort={onSort}
        rowHrefFunc={rowHrefFunc}
        tableData={batches}
        entryClassHelper={ENTRY_CLASS_FUNCTIONS}
      />
    </div>
  )
}

BatchesList.propTypes = {
  batches: types.arrayOf(types.object).isRequired,
}

export default BatchesList
