import React from "react"
import types from "prop-types"
import { shortMonthDate } from "utils/dateHelpers"
import { useDispatch, useSelector } from "react-redux"
import { getFormSlug, setSelectedWorkflow, showWorkflowForm } from "reduxSlices/formBuilderSlice"
import Tooltip from "components/shared/Tooltip"
import { getInitials } from "shared/icons/ProfileIcon"
import {
  Tile, TileContent, TileFooter, TileHeader,
} from "shared/tiles"
import ActiveToggle from "./ActiveToggle"
import WorkflowMenu from "./WorkflowMenu"
import TriggerLabel from "./TriggerLabel"
import ActionLabels from "./ActionLabels"
import ConditionsLabel from "./ConditionsLabel"
import WorkflowTileSection from "./WorkflowTileSection"

const tooltipText = (editor) => {
  if (!editor) return null

  const editorName = editor.isPhpAdmin
    ? `${editor.role} - ${getInitials(editor)}`
    : editor.fullName

  return `Last edited by ${editorName}`
}

const WorkflowTile = ({ workflow }) => {
  const dispatch = useDispatch()

  const formSlug = useSelector(getFormSlug)

  const { editor } = workflow

  const openForm = () => {
    dispatch(setSelectedWorkflow(workflow))
    dispatch(showWorkflowForm())
  }

  return (
    <Tile className="!bg-lightgray text-gray-800">
      <TileHeader>
        <WorkflowMenu formSlug={formSlug} workflowId={workflow.id} openForm={openForm} />
      </TileHeader>
      <TileContent className="max-h-52 overflow-y-scroll">
        <WorkflowTileSection title="Trigger">
          <TriggerLabel workflow={workflow} />
        </WorkflowTileSection>
        <WorkflowTileSection title="If">
          <ConditionsLabel
            conditions={workflow.conditions}
            onetime={workflow.onetime}
            trigger={workflow.trigger}
          />
        </WorkflowTileSection>
        <WorkflowTileSection title="Then">
          <ActionLabels actions={workflow.actions} />
        </WorkflowTileSection>
      </TileContent>
      <TileFooter className="bg-white">
        <div>
          <div className="font-semibold">{ workflow.name }</div>
          <div className="text-sm font-medium cursor-default">
            <Tooltip text={tooltipText(editor)}>{ shortMonthDate(workflow.updatedAt) }</Tooltip>
          </div>
        </div>
        <ActiveToggle workflow={workflow} formSlug={formSlug} />
      </TileFooter>
    </Tile>
  )
}

WorkflowTile.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  workflow: types.object.isRequired,
}

export default WorkflowTile
