import React, { useRef, useState } from "react"
import types from "prop-types"
import clsx from "clsx"
import useResizeObserver from "@react-hook/resize-observer"

const TruncateText = ({ children, truncateOn = "", className = "" }) => {
  const [isOverflowing, setIsOverflowing] = useState(false)

  const truncateRef = useRef(null)

  const checkOverflow = (resizeObserverEntry) => {
    const { target: element } = resizeObserverEntry

    if (element?.firstElementChild) {
      const {
        firstElementChild: { scrollWidth, clientWidth },
      } = element

      setIsOverflowing(scrollWidth > clientWidth)
    } else {
      setIsOverflowing(false)
    }
  }

  useResizeObserver(truncateRef, checkOverflow)

  return (
    <span
      ref={truncateRef}
      className={clsx(className, "truncate-middle")}
      data-truncate-on={isOverflowing ? `...${truncateOn}` : ""}
    >
      {children}
    </span>
  )
}

TruncateText.propTypes = {
  children: types.node.isRequired,
  truncateOn: types.string,
  className: types.string,
}

export default TruncateText
