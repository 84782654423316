import React from "react"
import { SurveyScheduledReportExportContextProvider } from "contexts/SurveyScheduledReportExportContext"
import { reportExportSurveyReportShape } from "utils/propTypeShapes"
import NewSurveyScheduledReportExportForm from "./NewSurveyScheduledReportExportForm"

const NewSurveyScheduledReportExport = ({ surveyReport }) => (
  <SurveyScheduledReportExportContextProvider surveyReport={surveyReport}>
    <NewSurveyScheduledReportExportForm />
  </SurveyScheduledReportExportContextProvider>
)

NewSurveyScheduledReportExport.propTypes = {
  surveyReport: reportExportSurveyReportShape.isRequired,
}

export default NewSurveyScheduledReportExport
