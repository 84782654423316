import { useState, useCallback } from "react"

const useModal = (isOpen = false) => {
  const [modalIsOpen, setModalIsOpen] = useState(isOpen)

  const openModal = useCallback(() => setModalIsOpen(true), [setModalIsOpen])
  const closeModal = useCallback(() => setModalIsOpen(false), [setModalIsOpen])

  return {
    modalIsOpen,
    openModal,
    closeModal,
  }
}

export default useModal
