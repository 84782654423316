import React from "react"
import { useHistoricalAccessToolContext } from "contexts/HistoricalAccessToolContext"
import { GRANTABLE_ACCESS_LEVELS } from "contexts/HistoricalAccessToolContext/HistoricalAccessToolContextProvider"
import { snakeToCapitalCase } from "utils/stringHelpers"
import { RadioButton } from "shared/inputs"

const HistoricalAccessToolAccessLevel = () => {
  const { selectedAccessLevel, setSelectedAccessLevel } = useHistoricalAccessToolContext()

  return (
    <>
      {
        GRANTABLE_ACCESS_LEVELS.map((accessLevel) => (
          <RadioButton
            key={accessLevel}
            checked={selectedAccessLevel === accessLevel}
            className="mb-4 last:mb-0"
            label={snakeToCapitalCase(accessLevel)}
            name="historical-access-tool-access-level"
            onChange={setSelectedAccessLevel}
            value={accessLevel}
          />
        ))
      }
    </>
  )
}

export default HistoricalAccessToolAccessLevel
