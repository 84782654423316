import React from "react"
import withReduxProvider from "shared/withReduxProvider"
import GlobalHeaderDropdown from "shared/headers/GlobalHeader/GlobalHeaderDropdown"
import Panel from "./Panel"
import PanelTrigger from "./PanelTrigger"

const FeatureAnnouncementPanel = () => (
  <GlobalHeaderDropdown
    dropdownStyles="lg:right-[68px] p-6"
    trigger={<PanelTrigger />}
  >
    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
    <div className="outline-none" tabIndex={0}>
      <Panel />
    </div>
  </GlobalHeaderDropdown>
)

export default withReduxProvider(FeatureAnnouncementPanel)
