import React from "react"
import types from "prop-types"
import { TileGrid } from "shared/tiles"
import FormTile from "./FormTile"

const FormList = ({ forms }) => (
  <TileGrid>
    {
      forms.map((form) => (
        <FormTile key={form.id} form={form} />
      ))
    }
  </TileGrid>
)

FormList.propTypes = {
  forms: types.arrayOf(types.object).isRequired,
}

export default FormList
