import React from "react"
import types from "prop-types"
import clsx from "clsx"

const IconContainer = ({ className = "w-8 h-8", children }) => (
  <div className={clsx(className, "grid place-items-center icon")}>
    { children }
  </div>
)

IconContainer.propTypes = {
  className: types.string,
  children: types.node.isRequired,
}

export default IconContainer
