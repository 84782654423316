import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  groups: [],
  users: [],
}

export const categorySlice = createSlice({
  name: "category",
  initialState: INITIAL_STATE,
  reducers: {
    setGroups: (state, action) => {
      state.groups = action.payload
    },
    setUsers: (state, action) => {
      state.users = action.payload
    },
  },
})

export const {
  setGroups,
  setUsers,
} = categorySlice.actions

export const groups = (state) => state.category.groups

export const users = (state) => state.category.users

export default categorySlice.reducer
