import React from "react"
import { facilityGroupCodeShape } from "utils/propTypeShapes"

const FacilityGroupCode = ({ facilityGroupCode }) => (
  <p className="list-row dark">{ facilityGroupCode.name }</p>
)

FacilityGroupCode.propTypes = {
  facilityGroupCode: facilityGroupCodeShape.isRequired,
}

export default FacilityGroupCode
