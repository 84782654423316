import React from "react"
import ActivityIndicator from "shared/ActivityIndicator"

const LoadingFormSubmissions = () => (
  <div className="flex flex-col w-full items-center">
    <ActivityIndicator className="mb-4" />
    <span>Loading form submissions ...</span>
  </div>
)

export default LoadingFormSubmissions
