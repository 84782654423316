import React, { useCallback, useMemo } from "react"
import { useSelector } from "react-redux"
import { getFormBuilder } from "reduxSlices/formBuilderSlice"
import { FormIcon, TaskSubformIcon } from "shared/icons"
import { DataCollectorContainer } from "shared/dataCollector"
import NewWorkflowForm from "./NewWorkflowForm"
import EditWorkflowForm from "./EditWorkflowForm"

const WorkflowForm = () => {
  const { form, selectedWorkflow } = useSelector(getFormBuilder)

  const Icon = useMemo(() => (
    form.isSubform ? TaskSubformIcon : FormIcon
  ), [form])

  const WorkflowFormBody = useMemo(() => (
    selectedWorkflow ? EditWorkflowForm : NewWorkflowForm
  ), [selectedWorkflow])

  const renderDataCollectorHeaderContent = useCallback(() => (
    <>
      <Icon form={form} className="h-6 w-6 mr-1.5" />
      <span className="font-medium text-xl">{form.title}</span>
      {
        selectedWorkflow?.active
          ? <span className="workflow-active absolute right-6 p-3 font-semibold">Active</span>
          : <span className="workflow-inactive absolute right-6 p-3 font-semibold">Inactive</span>
      }
    </>
  ), [form, selectedWorkflow])

  return (
    <div className="inner-container max-w-screen-md mx-auto">
      <DataCollectorContainer renderHeaderContent={renderDataCollectorHeaderContent}>
        <WorkflowFormBody />
      </DataCollectorContainer>
    </div>
  )
}

export default WorkflowForm
