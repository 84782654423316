import React from "react"
import types from "prop-types"
import { Draggable } from "react-beautiful-dnd"
import { formShape } from "utils/propTypeShapes"
import { DragHandle } from "shared/icons"
import FormBar from "./FormBar"

const DraggableFormBar = ({
  index,
  form,
  ...rest
}) => (
  <Draggable key={form.id} draggableId={form.id.toString()} index={index}>
    {
      (dragProvided) => (
        <div
          ref={dragProvided.innerRef}
          {...dragProvided.draggableProps}
        >
          <FormBar
            handle={<DragHandle dragProvided={dragProvided} />}
            form={form}
            {...rest}
          />
        </div>
      )
    }
  </Draggable>
)

DraggableFormBar.propTypes = {
  index: types.number.isRequired,
  form: formShape.isRequired,
}

export default DraggableFormBar
