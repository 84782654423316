import React from "react"
import types from "prop-types"

const StrikeAround = ({ children, className = "" }) => (
  <div className={`${className} flex items-center`}>
    <hr className="flex-grow mr-4 text-light" />
    { children }
    <hr className="flex-grow ml-4 text-light" />
  </div>
)

StrikeAround.propTypes = {
  children: types.node.isRequired,
  className: types.string,
}

export default StrikeAround
