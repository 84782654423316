import {
  INITIAL_DATE_BY_WEEK_GROUPING, INITIAL_DATE_BY_DAYS_GROUPING, lastModifiedByDayGrouping, submittedDateGrouping,
} from "utils/groupingHelpers"

export const GROUP_BY_STATUS = "statusName"
export const GROUP_BY_FORM = "form"
export const GROUP_BY_LAST_MODIFIED = "updatedAt"
export const GROUP_BY_DATE_SUBMITTED = "createdAt"

export const groupByStatusName = (submissions, { categorySubmissionStatuses }) => {
  const initialStatusGrouping = Object.fromEntries(
    (categorySubmissionStatuses || []).map((status) => [status.name, []]),
  )

  return submissions.reduce((acc, submission) => {
    const { statusName } = submission
    if (acc[statusName]) {
      acc[statusName].push(submission)
    } else {
      acc[statusName] = [submission]
    }
    return acc
  }, initialStatusGrouping)
}

export const groupByFormTitle = (submissions) => {
  const initialFormGrouping = Object.fromEntries(
    submissions.map(({ form }) => [form.title, []]).sort(),
  )

  return submissions.reduce((acc, submission) => {
    const { title } = submission.form
    acc[title].push(submission)
    return acc
  }, initialFormGrouping)
}

export const groupByLastModified = (submissions) => submissions.reduce((acc, submission) => {
  const { updatedAt } = submission
  const groupName = lastModifiedByDayGrouping(updatedAt)
  acc[groupName].push(submission)

  return acc
}, INITIAL_DATE_BY_DAYS_GROUPING())

export const groupByDateSubmitted = (submissions) => submissions.reduce((acc, submission) => {
  const { createdAt } = submission
  const groupName = submittedDateGrouping(createdAt)
  acc[groupName].push(submission)

  return acc
}, INITIAL_DATE_BY_WEEK_GROUPING())

export const GROUPABLE_COLUMNS = {
  [GROUP_BY_STATUS]: {
    label: "Status",
    value: GROUP_BY_STATUS,
    groupingMethod: groupByStatusName,
  },
  [GROUP_BY_FORM]: {
    label: "Form",
    value: GROUP_BY_FORM,
    groupingMethod: groupByFormTitle,
  },
  [GROUP_BY_LAST_MODIFIED]: {
    label: "Last Modified",
    value: GROUP_BY_LAST_MODIFIED,
    groupingMethod: groupByLastModified,
  },
  [GROUP_BY_DATE_SUBMITTED]: {
    label: "Date Submitted",
    value: GROUP_BY_DATE_SUBMITTED,
    groupingMethod: groupByDateSubmitted,
  },
}
export const getGroupingMethod = ({ value }) => GROUPABLE_COLUMNS[value].groupingMethod
