import React from "react"
import { endSession } from "utils/endSession"
import { TertiaryButton } from "shared/buttons"

const Logout = () => (
  <TertiaryButton
    className=""
    onClick={endSession}
    type="submit"
    text="Logout"
  />
)

export default Logout
