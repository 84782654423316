import { useContext } from "react"
import FacilityAuditTrailContext from "../FacilityAuditTrailContext"

const useFacilityAuditTrailContext = () => {
  const providedValue = useContext(FacilityAuditTrailContext)

  return { ...providedValue }
}

export default useFacilityAuditTrailContext
