import React from "react"
import types from "prop-types"
import TaskGroup from "../TaskGroup"

const TaskGroupList = ({ className, removeGroup, groups }) => {
  const removeFromTask = (id) => () => removeGroup(id)

  return (
    <div className={className}>
      <div className="flex gap-2 w-full font-semibold text-sm items-end pr-16 mb-2">
        <span className="grow">Groups</span>
        <div className="hidden lg:block w-20 text-center">Notify on Assign</div>
        <div className="hidden lg:block w-20 text-center">Notify on Complete</div>
      </div>
      {
        groups.map((group) => (
          <TaskGroup
            key={`task-group-${group.id}`}
            className="mb-2 last:mb-0"
            removeFromTask={removeFromTask(group.id)}
            group={group}
          />
        ))
      }
    </div>
  )
}

TaskGroupList.defaultProps = {
  className: "",
}

TaskGroupList.propTypes = {
  className: types.string,
  removeGroup: types.func.isRequired,
  groups: types.arrayOf(types.object).isRequired,
}

export default TaskGroupList
