import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import FormTypeCard from "./FormTypeCard"

const SurveyFormCard = ({ onClick }) => (
  <FormTypeCard
    footerColor="bg-survey-form-color"
    description="Survey form. Surveys can be sent to contacts once or on a recurring basis."
    icon={<SvgSpriteIcon iconName="surveys" className="w-10 h-10" />}
    title="Survey"
    onClick={onClick}
  />
)

SurveyFormCard.propTypes = {
  onClick: types.func.isRequired,
}

export default SurveyFormCard
