import React from "react"
import types from "prop-types"
import { useReportContext } from "contexts/ReportContext"
import ReportRow from "./ReportRow"

const ReportList = ({ editScheduledReportExportPath, newReportExportPath, newScheduledReportExportPath }) => {
  const { reports } = useReportContext()

  return (
    <div className="flex flex-col items-center justify-center">
      {
        reports.map((report) => (
          <ReportRow
            newReportExportPath={newReportExportPath}
            newScheduledReportExportPath={newScheduledReportExportPath}
            editScheduledReportExportPath={editScheduledReportExportPath}
            report={report}
            key={report.id}
          />
        ))
      }
    </div>
  )
}

ReportList.propTypes = {
  newReportExportPath: types.func.isRequired,
  newScheduledReportExportPath: types.func.isRequired,
  editScheduledReportExportPath: types.func.isRequired,
}

export default ReportList
