/* eslint-disable camelcase */
import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"

// There's no unique audit action for a task being closed, reopened,
// discarded, or recovered.  All of these use the same "update" action.
// To determine what type of change occurred, we must
// investigate the audited changes.
const changeDescription = (audit) => {
  const { auditedChanges } = audit
  const { closed_at = [], discarded_at = [] } = auditedChanges

  const taskClosed = Boolean(!closed_at[0] && closed_at[1])
  const taskReopened = Boolean(closed_at[0] && !closed_at[1])
  const taskDiscarded = Boolean(!discarded_at[0] && discarded_at[1])
  const taskRecovered = Boolean(discarded_at[0] && !discarded_at[1])

  let updateDescription = "modified"

  if (taskClosed) {
    updateDescription = "completed"
  } else if (taskReopened) {
    updateDescription = "reopened"
  } else if (taskDiscarded) {
    updateDescription = "deleted"
  } else if (taskRecovered) {
    updateDescription = "recovered"
  }

  return updateDescription
}

const TaskUpdatedAuditItemDetails = ({ audit }) => {
  const { task } = audit

  return (
    <div>
      <span>
        <AuditedUser audit={audit} /> {changeDescription(audit)} the
        task titled <span className="font-semibold">{task.title}</span>.
      </span>
    </div>
  )
}

TaskUpdatedAuditItemDetails.propTypes = {
  audit: auditShape.isRequired,
}

export default TaskUpdatedAuditItemDetails
