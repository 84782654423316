import React from "react"
import IdleTracker from "./IdleTracker"
import FormFillingTimeoutModal from "./FormFillingTimeoutModal"

// The form is available for 60 minutes before it refreshes.
// To accommodate the 1 minute countdown the user has to click "Keep Working"
// setting the default idle time to 59 minutes.
const DEFAULT_IDLE_TIME_IN_MINUTES = 59
const MILLISECONDS_IN_ONE_MINUTE = 60000

const FormFillingIdleTracker = () => {
  // Reset timer when user clicks "Keep Working" button
  const onContinue = (resetTimer) => {
    resetTimer()
  }

  // Refresh page when user is idle on public form
  const refreshPage = () => {
    window.location.reload()
  }

  return (
    <IdleTracker
      idleTime={DEFAULT_IDLE_TIME_IN_MINUTES * MILLISECONDS_IN_ONE_MINUTE}
      onContinue={onContinue}
      onTimeout={refreshPage}
      modal={FormFillingTimeoutModal}
    />
  )
}

export default FormFillingIdleTracker
