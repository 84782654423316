import React from "react"
import { surveyBatchForExportReportShape } from "utils/propTypeShapes"
import { shortMonthDate } from "utils/dateHelpers"
import { useSurveyReportExportContext } from "contexts/SurveyReportExportContext"
import { Checkbox } from "components/shared/checkboxes"

const SurveyBatchCheckbox = ({ surveyBatch }) => {
  const {
    surveyBatchIds,
    addSurveyBatchIds,
    removeSurveyBatchIds,
  } = useSurveyReportExportContext()

  const { id } = surveyBatch

  const selected = surveyBatchIds.includes(id)

  const toggleBatch = selected
    ? () => removeSurveyBatchIds([id])
    : () => addSurveyBatchIds([id])

  return (
    <div className="flex items-center" key={`surveyBatch-checkbox-${id}`}>
      <Checkbox
        className="mr-2"
        onChange={toggleBatch}
        uuid={`surveyBatch-checkbox-${id}`}
        value={selected}
      />
      <label className="hover:cursor-pointer" htmlFor={`surveyBatch-checkbox-${surveyBatch.id}`}>
        {surveyBatch.name} ({shortMonthDate(surveyBatch.createdAt)})
      </label>
    </div>
  )
}

SurveyBatchCheckbox.propTypes = {
  surveyBatch: surveyBatchForExportReportShape.isRequired,
}

export default SurveyBatchCheckbox
