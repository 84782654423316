import React from "react"
import types from "prop-types"
import ReportableFieldList from "views/FormBuilder/ReportableFields/ReportableFieldList"
import QuestionSidebarSection from "./QuestionSidebarSection"

const ReportableFieldsSection = ({ sectionIndex, questionIndex, isFocused }) => (
  <QuestionSidebarSection
    description="Select a field from the list below to group the question&apos;s answers for reporting"
    hide={!isFocused}
    title="Reportable Field"
    headerClassName="py-2.5 text-dark"
  >
    <ReportableFieldList sectionIndex={sectionIndex} questionIndex={questionIndex} />
  </QuestionSidebarSection>
)

ReportableFieldsSection.propTypes = {
  isFocused: types.bool.isRequired,
  questionIndex: types.number.isRequired,
  sectionIndex: types.number.isRequired,
}

export default ReportableFieldsSection
