import React from "react"
import types from "prop-types"
import { FormsyTextInput } from "shared/inputs"

const GroupName = ({ group }) => (
  <>
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label>Name</label>
    <FormsyTextInput
      className="bordered-base-input text-sm"
      name="name"
      placeholder="Name"
      value={group.name || ""}
      required
      validations={{ isNotBlankString: true }}
      validationErrors={{ isDefaultRequiredValue: "required" }}
    />
  </>
)

GroupName.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  group: types.object.isRequired,
}

export default GroupName
