import React from "react";
import types from "prop-types"
import ReactTextFormat from "react-text-format"

const FormattedNote = ({
  name,
  value = "",
}) => {
  const customLinkDecorator = (
    decoratedHref,
    decoratedText,
    key,
  ) => (
    <a
      href={decoratedHref}
      key={key}
      target="_blank"
      rel="noreferrer"
      className="customLink"
    >
      {decoratedText}
    </a>
  )
  return (
    <ReactTextFormat
      allowedFormats={["URL"]}
      linkDecorator={customLinkDecorator}
    >
      <pre name={name} value={value} className="formatted-note formatted-note-contained">
        {value}
      </pre>
    </ReactTextFormat>
  )
}

FormattedNote.propTypes = {
  name: types.string.isRequired,
  value: types.string,
}

export default FormattedNote
