import React from "react"
import types from "prop-types"
import { SEGMENTS } from "utils/departmentSegmentHelpers"
import { useTaskFormContext } from "contexts/TaskFormContext"
import { departmentIdentifier } from "utils/taskHelpers"
/* eslint-disable-next-line max-len */
import TaskDepartmentSegmentCheckbox from "views/FormSubmission/FormSubmissionTabs/ActivitiesContent/TaskForms/TaskFields/UI/TaskDepartmentSegmentCheckbox"

const TaskMemberDepartmentSegments = ({ department }) => {
  const {
    deselectMemberDepartmentSegment,
    selectMemberDepartmentSegment,
    selectedAssigneeDepartmentSegments,
  } = useTaskFormContext()

  const segmentSelected = (segment) => department.segments?.includes(segment)

  const segmentUnavailable = (segment) => (
    (selectedAssigneeDepartmentSegments[department.questionUUID] ?? []).includes(segment)
  )

  const toggleSelection = (segment) => () => {
    const toggleFunction = segmentSelected(segment)
      ? deselectMemberDepartmentSegment
      : selectMemberDepartmentSegment

    toggleFunction(departmentIdentifier(department), segment)
  }

  return (
    <div className="w-full flex gap-2 items-center justify-between">
      {
        SEGMENTS.map((segment) => (
          <TaskDepartmentSegmentCheckbox
            className="flex-grow text-sm"
            key={segment}
            segment={segment}
            toggleSelection={toggleSelection}
            disabled={segmentUnavailable(segment)}
            selected={segmentSelected(segment)}
            groupIdentifier="member"
          />
        ))
      }
    </div>
  )
}

TaskMemberDepartmentSegments.propTypes = {
  department: types.object.isRequired,
}

export default TaskMemberDepartmentSegments
