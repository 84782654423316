import React from "react"
import types from "prop-types"
import clsx from "clsx"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const ChevronDown = ({
  className = "",
  width = undefined,
  height = undefined,
  onClick = () => {},
}) => (
  <SvgSpriteIcon
    className={clsx(className, "text-dark")}
    iconName="chevron-down"
    width={width}
    height={height}
    onClick={onClick}
  />
)

ChevronDown.propTypes = {
  className: types.string,
  width: types.number,
  height: types.number,
  onClick: types.func,
}

export default ChevronDown
