import React from "react"
import { useSolutionLevelScheduledReportExportContext } from "contexts/SolutionLevelScheduledReportExportContext"
import { ListSelect, ReportableFieldSelect } from "shared/selects"
import {
  dayjsOrgTz, DAYS, MONTHS, WEEKS,
} from "utils/dateHelpers"
import DaysReportableFieldDescription from "./DateFilterDescriptions/ReportableFieldDescription/DaysReportableFieldDescription"
import WeeksReportableFieldDescription from "./DateFilterDescriptions/ReportableFieldDescription/WeeksReportableFieldDescription"
import MonthsReportableFieldDescription from "./DateFilterDescriptions/ReportableFieldDescription/MonthsReportableFieldDescription"

const NewSolutionLevelScheduledReportExportFormReportableFieldDateAnswerFilters = () => {
  const {
    dateReportableFields,
    lengthOptions,
    reportableFieldDateId,
    reportableFieldAnswerPreviousRangeLength,
    reportableFieldAnswerPreviousRangeUnit,
    setReportableFieldAnswerPreviousRangeLength,
    setReportableFieldDateId,
    startDate,
  } = useSolutionLevelScheduledReportExportContext()

  return (
    <>
      <ReportableFieldSelect
        className="!w-full bordered-base-input mb-4"
        backspaceDelete={false}
        onChange={setReportableFieldDateId}
        reportableFields={dateReportableFields}
        value={reportableFieldDateId}
      />
      {
        reportableFieldDateId && (
          <>
            <div className="flex justify-start items-center my-4">
              <span className="min-w-min">Answer value is in the previous</span>
              <ListSelect
                className="bordered-base-input ml-2 max-w-max"
                clearable={false}
                backspaceDelete={false}
                placeholder="1"
                name="submission_previous_range_length"
                required
                options={lengthOptions}
                value={reportableFieldAnswerPreviousRangeLength}
                onChange={setReportableFieldAnswerPreviousRangeLength}
              />
              <div className="ml-2">{reportableFieldAnswerPreviousRangeUnit}</div>
            </div>

            {
              reportableFieldAnswerPreviousRangeUnit === DAYS
              && (
                <DaysReportableFieldDescription
                  // DateInput sets value as a string
                  startDate={dayjsOrgTz(startDate, "YYYY-MM-DD")}
                  reportableFieldAnswerPreviousRangeLength={reportableFieldAnswerPreviousRangeLength}
                />
              )
            }
            {
              reportableFieldAnswerPreviousRangeUnit === WEEKS
              && (
                <WeeksReportableFieldDescription
                  startDate={startDate}
                  reportableFieldAnswerPreviousRangeLength={reportableFieldAnswerPreviousRangeLength}
                />
              )
            }
            {
              reportableFieldAnswerPreviousRangeUnit === MONTHS
              && (
                <MonthsReportableFieldDescription
                  startDate={startDate}
                  reportableFieldAnswerPreviousRangeLength={reportableFieldAnswerPreviousRangeLength}
                />
              )
            }
          </>
        )
      }
    </>
  )
}

export default NewSolutionLevelScheduledReportExportFormReportableFieldDateAnswerFilters
