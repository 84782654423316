import React, { Component } from "react"
import types from "prop-types"
import Modal, { DEFAULT_MODAL_STYLE } from "shared/Modal"
import { LightXIcon } from "shared/icons"
import { Button, PrimaryButton } from "shared/buttons"
import * as API from "services/api"
import { CategorySelect } from "shared/selects"
import CreateFormCardSection from "./CreateFormCardSection"

const MODAL_STYLE = {
  content: {
    ...DEFAULT_MODAL_STYLE.content,
    minWidth: "100vw",
    minHeight: "100vh",
    padding: 0,
    background: "#272946",
  },
}

class CreateFormModal extends Component {
  state = {
    modalIsOpen: false,
    selectedCategoryId: null,
  }

  async componentDidMount() {
    const response = await API.getBuildableCategories()
    if (response.ok) {
      const categoryOptions = response.data
      this.setState({ categoryOptions })
    } else {
      console.error("Error fetching categories: ", response)
    }
  }

  closeModal = () => this.setState(() => (
    { modalIsOpen: false, selectedCategoryId: null }
  ))

  openModal = () => this.setState({ modalIsOpen: true })

  // Dropdown does not close on select, so this will
  // focus elsewhere to programatically close dropdown.
  // eslint-disable-next-line class-methods-use-this
  closeCategorySelectDropdown = () => {
    document.getElementsByTagName("h3")[0].click()
  }

  setSelectedCategoryId = (selectedCategoryId) => {
    this.setState({ selectedCategoryId })

    // delay needed after setState to close dropdown
    setTimeout(this.closeCategorySelectDropdown, 10)
  }

  render() {
    const { modalIsOpen, categoryOptions, selectedCategoryId } = this.state
    const { formBuilderPermissions } = this.props
    let selectedCategoryOption

    if (categoryOptions?.length > 0) {
      selectedCategoryOption = categoryOptions.find((category) => category.id === selectedCategoryId)
    }

    const selectedCategoryUsage = selectedCategoryOption?.usage || ""

    return (
      <>
        {
          formBuilderPermissions?.formBuilder
            ? (<PrimaryButton text="+ Add Form" onClick={this.openModal} />)
            : (<div className="w-36" />)
        }
        <Modal isOpen={modalIsOpen} style={MODAL_STYLE}>
          <LightXIcon className="absolute cursor-pointer" onClick={this.closeModal} />
          <div className="pt-40">
            <h3 className="flex justify-center text-4xl font-medium text-white tracking-tight">
              Pick a Solution and Form Type
            </h3>
            <label className="max-w-max mx-auto flex flex-col items-center mb-8">
              <CategorySelect
                className="min-w-max max-w-max !bg-white text-md"
                style={{ minWidth: "300px" }}
                options={categoryOptions}
                value={selectedCategoryId}
                onChange={this.setSelectedCategoryId}
                placeholder="Select a Solution..."
              />
            </label>
            {
              selectedCategoryOption && (
                <>
                  <p className="text-white text-center text-md mb-16">
                    Your form will be created in the {selectedCategoryOption.name} solution.<br />
                    This cannot be changed.
                  </p>
                  <div className="flex space-x-6 justify-center">
                    <CreateFormCardSection
                      categoryId={selectedCategoryId}
                      categoryUsage={selectedCategoryUsage}
                    />
                  </div>
                </>
              )
            }
            <div className="flex justify-center mt-8">
              <Button
                text="Cancel"
                onClick={this.closeModal}
                className="text-white border border-white"
              />
            </div>
          </div>
        </Modal>
      </>
    )
  }
}

CreateFormModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formBuilderPermissions: types.object.isRequired,
}

export default CreateFormModal
