import React from "react"
import { isEmpty } from "lodash-es"
import { useFormSubmissionAuditTrailContext } from "contexts/FormSubmissionAuditTrailContext"
import StrikeAround from "shared/StrikeAround"
import AuditList from "./AuditList"

const FormSubmissionAuditTrailGroups = () => {
  const { auditGroups } = useFormSubmissionAuditTrailContext()

  if (isEmpty(auditGroups)) {
    return (
      <p className="text-center mb-12">
        There are no audits with the selected options.
      </p>
    )
  }

  return (
    <>
      {
        Object.entries(auditGroups).map(([formattedDate, audits]) => (
          <div key={formattedDate} className="mb-8">
            <StrikeAround className="mb-9">
              <span className="font-medium text-xl">{ formattedDate }</span>
            </StrikeAround>
            <AuditList audits={audits} />
          </div>
        ))
      }
    </>
  )
}

export default FormSubmissionAuditTrailGroups
