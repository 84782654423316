import React from "react"
import types from "prop-types"
import clsx from "clsx"

const Sidebar = ({ hiddenSidebar, children, className = "" }) => (
  <div
    className={clsx(className,
      "border-r border-lightgray-3 bg-light-100",
      "fixed top-0 left-0",
      "h-full mt-14 pb-12 w-1/4",
      "overflow-y-auto",
      hiddenSidebar && "hidden")}
  >
    { children }
  </div>
)

Sidebar.propTypes = {
  hiddenSidebar: types.bool.isRequired,
  children: types.node.isRequired,
  className: types.string,
}

export default Sidebar
