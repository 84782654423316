// Based on https://designcode.io/react-hooks-handbook-useonclickoutside-hook
import { useEffect } from "react"

const useOnClickOutside = (ref, callback) => {
  useEffect(() => {
    const eventHandler = (event) => {
      if (!ref.current || ref.current.contains(event.target)) return

      callback(event)
    }

    document.addEventListener("mousedown", eventHandler)
    document.addEventListener("touchstart", eventHandler)

    return () => {
      document.removeEventListener("mousedown", eventHandler)
      document.removeEventListener("touchstart", eventHandler)
    };
  }, [ref, callback]);
};

export default useOnClickOutside;
