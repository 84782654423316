import React, { useCallback, useEffect } from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { useDispatch, useSelector } from "react-redux"
import {
  getFormIsSubform, getFormQuestionAttributeDefaultAnswers, getFormQuestionOptions, setQuestionAttributeDefaultAnswers,
} from "reduxSlices/formBuilderSlice"
import { Checkbox } from "shared/checkboxes"
import { ListMultiSelect } from "shared/multiSelects"
import { XIcon } from "shared/icons"

const contentRenderer = ({ props, state, methods }) => {
  const { labelField, valueField } = props
  const { values } = state
  const { removeItem } = methods

  return values.map((option) => {
    const label = option[labelField]
    const value = option[valueField]

    return (
      <div
        key={value}
        className="rounded bg-white p-1 mr-0.5 mt-0.5 text-xs font-semibold inline-flex items-center"
      >
        {label}
        <XIcon
          className="h-4 w-4 ml-0.5 bg-primary-light rounded-full p-0.5"
          onClick={() => removeItem(null, option)}
        />
      </div>
    )
  })
}

const dropdownRenderer = ({ props, state, methods }) => {
  const { labelField, valueField, options } = props
  const { addItem, removeItem } = methods

  const values = state.values.map((option) => option[valueField])
  const updateCheckbox = (option) => {
    if (values.includes(option[valueField])) {
      removeItem(null, option)
    } else {
      addItem(option)
    }
  }

  return (
    <div className="p-4">
      <div className="text-dark text-sm font-semibold tracking-wider">Options</div>
      {
        options.map((option) => {
          const label = option[labelField]
          const value = option[valueField]

          return (
            <div
              key={value}
              className="text-sm font-semibold mt-3 flex-center"
              onClickCapture={() => updateCheckbox(option)}
            >
              {label}
              <Checkbox
                value={values.includes(value)}
                onChange={() => updateCheckbox(option)}
              />
            </div>
          )
        })
      }
    </div>
  )
}

const EMPTY_DEFAULT_ANSWERS = []

const DefaultAnswers = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const isSubform = useSelector(getFormIsSubform)
  const questionDefaultAnswers = useSelector(getFormQuestionAttributeDefaultAnswers({ sectionIndex, questionIndex }), isEqual)
  const questionOptions = useSelector(getFormQuestionOptions({ sectionIndex, questionIndex }), isEqual)

  const updateDefaultAnswers = useCallback((newDefaultAnswers) => dispatch(setQuestionAttributeDefaultAnswers({
    sectionIndex, questionIndex, newDefaultAnswers,
  })), [sectionIndex, questionIndex, dispatch])

  useEffect(() => {
    if (!questionDefaultAnswers || (isSubform && !isEqual(questionDefaultAnswers, EMPTY_DEFAULT_ANSWERS))) {
      updateDefaultAnswers(EMPTY_DEFAULT_ANSWERS)
    }
  }, [isSubform, questionDefaultAnswers, updateDefaultAnswers])

  if (isSubform) return null

  return (
    <div className="flex flex-col mb-4">
      <div className="mb-1 font-medium">Default(s)</div>
      <ListMultiSelect
        className="text-sm"
        options={questionOptions}
        contentRenderer={contentRenderer}
        dropdownRenderer={dropdownRenderer}
        values={questionDefaultAnswers}
        onChange={updateDefaultAnswers}
      />
    </div>
  )
}

DefaultAnswers.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default DefaultAnswers
