import React from "react"
import { SidebarCollapsibleContent } from "shared/Sidebar"
import BatchFormsList from "./BatchFormsList"

const BatchFormsFilter = () => (
  <SidebarCollapsibleContent title="Forms Shown">
    <BatchFormsList />
  </SidebarCollapsibleContent>
)

export default BatchFormsFilter
