import React from "react"
import types from "prop-types"
import { snakeToCapitalCase } from "utils/stringHelpers"

const AuditChangeDetails = ({ attribute, change }) => (
  <div className="mb-4 last:mb-0">
    <p>{snakeToCapitalCase(attribute)}:</p>
    <p>{change[0]} → {change[1]}</p>
  </div>
)

AuditChangeDetails.propTypes = {
  attribute: types.string.isRequired,
  change: types.arrayOf(types.string).isRequired,
}

export default AuditChangeDetails
