import React from "react"
import types from "prop-types"
import { FlagIcon } from "shared/icons"

const FlaggedDataElement = ({ value }) => (value ? <FlagIcon /> : null)

FlaggedDataElement.propTypes = {
  value: types.bool.isRequired,
}

export default FlaggedDataElement
