import React from "react"
import types from "prop-types"
import TaskUser from "../TaskUser"

const TaskUserList = ({ className, removeUser, users }) => {
  const removeFromTask = (id) => () => removeUser(id)

  return (
    <div className={className}>
      <div className="flex gap-2 w-full font-semibold text-sm items-end pr-16 mb-2">
        <span className="grow">Users</span>
        <div className="hidden lg:block w-20 text-center">Notify on Assign</div>
        <div className="hidden lg:block w-20 text-center">Notify on Complete</div>
      </div>
      {
        users.map((user) => (
          <TaskUser
            key={`assignee-${user.id}`}
            className="mb-2 last:mb-0"
            removeFromTask={removeFromTask(user.id)}
            user={user}
          />
        ))
      }
    </div>
  )
}

TaskUserList.defaultProps = {
  className: "",
}

TaskUserList.propTypes = {
  className: types.string,
  removeUser: types.func.isRequired,
  users: types.arrayOf(types.object).isRequired,
}

export default TaskUserList
