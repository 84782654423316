import React from "react"
import types from "prop-types"
import { dynamicRangeReportableFieldsExample, weekRangeReportableFieldInclusionDescription } from "utils/scheduledReportExportHelpers"

const WeeksReportableFieldDescription = ({ startDate, reportableFieldAnswerPreviousRangeLength = null }) => {
  const weekCount = reportableFieldAnswerPreviousRangeLength || 1
  const beginningWeek = startDate.subtract(weekCount, "w")
  const beginningDate = beginningWeek.weekday(0)

  // endingDate is n weeks from start date minus one day
  const endingDate = beginningDate.add(weekCount, "w").subtract(1, "d")

  return (
    <div className="text-xs text-dark mt-2">
      <p className="mb-4">
        {weekRangeReportableFieldInclusionDescription}
      </p>
      <p>
        {dynamicRangeReportableFieldsExample({ startDate, beginningDate, endingDate })}
      </p>
    </div>
  )
}

WeeksReportableFieldDescription.propTypes = {
  startDate: types.object.isRequired,
  reportableFieldAnswerPreviousRangeLength: types.number,
}

export default WeeksReportableFieldDescription
