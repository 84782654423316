import React from "react"
import { useTaskFormContext } from "contexts/TaskFormContext"
import { FormsyHiddenInput } from "shared/inputs"

const TaskNotifyOnCompleteIds = () => {
  const { taskNotifyOnCompleteIds } = useTaskFormContext()

  return (
    <FormsyHiddenInput name="task.notify_on_complete_ids" value={taskNotifyOnCompleteIds} />
  )
}

export default TaskNotifyOnCompleteIds
