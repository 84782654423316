import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const FormLightIcon = ({ className = "w-8 h-8" }) => <SvgSpriteIcon iconName="form-light" className={className} />

FormLightIcon.propTypes = {
  className: types.string,
}

export default FormLightIcon
