import React, { forwardRef } from "react"
import clsx from "clsx"
import buttonPropTypes from "./buttonPropTypes"
import { LEFT } from "./constants"

const Button = forwardRef((
  {
    background = "currentcolor",
    className = "",
    color = "currentcolor",
    cursor = "pointer",
    disabled = false,
    form = undefined,
    icon = undefined,
    iconPosition = LEFT,
    noTab = undefined,
    onClick = () => {},
    text = undefined,
    type = "button",
  },
  ref,
) => {
  // If is iconButton then make it a square otherwise rectangle padding
  const isIconButton = (!text && icon)

  let iconClasses = iconPosition === LEFT ? "mr-2" : "ml-2"
  if (!text) iconClasses = ""

  return (
    <button
      className={clsx(
        className,
        `bg-${background}`,
        `text-${color}`,
        "button",
        isIconButton && "p-2",
        !disabled && `cursor-${cursor}`,
        disabled && "opacity-75",
        {
          "cursor-not-allowed": disabled,
          "flex-row-reverse": iconPosition === LEFT,
        },
      )}
      ref={ref}
      disabled={disabled}
      form={form}
      type={type}
      onClick={onClick}
      tabIndex={noTab ? "-1" : undefined}
    >
      {
        text && text
      }
      {
        icon && <i className={clsx(icon, iconClasses)} />
      }
    </button>
  )
})

Button.displayName = "Button"

Button.propTypes = buttonPropTypes

export default Button
