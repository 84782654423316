import React from "react"
import types from "prop-types"
import { dynamicRangeSubmissionsExample, dayRangeSubmissionInclusionDescription } from "utils/scheduledReportExportHelpers"

const DaysSubmissionDescription = ({ startDate, submissionPreviousRangeLength = null }) => {
  const daysCount = submissionPreviousRangeLength || 1

  const beginningDate = startDate.subtract(daysCount, "d")
  const endingDate = startDate.subtract(1, "d")

  return (
    <div className="text-xs text-dark mt-2">
      <p className="mb-4">
        {dayRangeSubmissionInclusionDescription}
      </p>
      <p>
        {dynamicRangeSubmissionsExample({ startDate, beginningDate, endingDate })}
      </p>
    </div>
  )
}

DaysSubmissionDescription.propTypes = {
  startDate: types.object.isRequired,
  submissionPreviousRangeLength: types.number,
}

export default DaysSubmissionDescription
