import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const RecurringSurveyConfigurationIcon = ({ className = "w-8 h-8", ...rest }) => (
  <SvgSpriteIcon iconName="survey-recurring" className={className} {...rest} />
)

RecurringSurveyConfigurationIcon.propTypes = {
  className: types.string,
}

export default RecurringSurveyConfigurationIcon
