import React from "react"
import types from "prop-types"
import { useDispatch } from "react-redux"
import { addFormQuestion } from "reduxSlices/formBuilderSlice"
import { Button, RIGHT } from "shared/buttons"

const AddQuestionButton = ({ sectionIndex }) => {
  const dispatch = useDispatch()

  const addQuestion = () => dispatch(addFormQuestion(sectionIndex))

  return (
    <div className="flex justify-center w-full">
      <Button
        className="text-focus font-medium border border-lightgray-3 mt-3"
        onClick={addQuestion}
        icon="fas fa-plus"
        background="white"
        iconPosition={RIGHT}
        text="Add Question"
      />
    </div>
  )
}

AddQuestionButton.propTypes = {
  sectionIndex: types.number.isRequired,
}

export default AddQuestionButton
