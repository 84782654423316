import React, { useRef } from "react"
import types from "prop-types"
import { useDrag, useDrop } from "react-dnd"
import { getSortableOptionType, SURVEY_NON_ANONYMOUS_TYPE } from "reduxSlices/reportBuilderSlice"

const SelectedColumn = ({
  canDropPredicate = (item) => (item),
  canMove = ((dragged, hovered) => dragged && hovered),
  column,
  moveColumn,
}) => {
  const dragRef = useRef(null)
  const dropRef = useRef(null)
  const selectedType = getSortableOptionType(column.type)

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: selectedType,
    item: { ...column, startingIndex: column.index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (draggedColumn, monitor) => {
      const didDrop = monitor.didDrop()

      if (!didDrop) {
        moveColumn(draggedColumn, draggedColumn.startingIndex)
      }
    },
  }), [column, selectedType, moveColumn])

  const [, drop] = useDrop(() => ({
    accept: [selectedType],
    canDrop: (item) => canDropPredicate(item),
    hover(draggedColumn, monitor) {
      const draggedIndex = draggedColumn.index
      const hoverIndex = column.index

      if (!dropRef.current || !canMove(draggedColumn, column) || draggedIndex === hoverIndex) return

      // Determine rectangle on screen
      const hoverTargetBoundingRect = dropRef.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY = (hoverTargetBoundingRect.bottom - hoverTargetBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverTargetBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging upwards, but mouse position not high enough
      if (draggedIndex < hoverIndex && hoverClientY < hoverMiddleY) return

      // Dragging downwards, but mouse position is not low enough
      if (draggedIndex > hoverIndex && hoverClientY > hoverMiddleY) return

      moveColumn(draggedColumn, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      draggedColumn.index = hoverIndex
    },
  }), [column, moveColumn])

  const opacity = isDragging ? 0.3 : 1

  drop(dropRef)
  drag(preview(dragRef))

  return (
    // Nesting drag inside of drop with padding allows for smooth scroll on drag
    // Using margins or space between SelectedColumns makes the animation stutter
    <div ref={dropRef} className="px-2 py-1 first:pt-2 last:pb-2">
      <div ref={dragRef}>
        <div className="flex items-center px-4 py-2 bg-light-300 rounded space-x-4 cursor-move" style={{ opacity }}>
          <i className="fas fa-arrows-alt-v" />
          <div className="grow">{column.label}{column.type === SURVEY_NON_ANONYMOUS_TYPE && " *"}</div>
        </div>
      </div>
    </div>
  )
}

SelectedColumn.propTypes = {
  canDropPredicate: types.func,
  canMove: types.func,
  column: types.shape({
    id: types.oneOfType([types.string, types.number]).isRequired,
    index: types.number.isRequired,
    label: types.string.isRequired,
    type: types.string.isRequired,
    formSlug: types.string,
  }).isRequired,
  moveColumn: types.func.isRequired,
}

export default React.memo(SelectedColumn)
