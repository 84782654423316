import React from "react"
import types from "prop-types"
import {
  useDispatch,
  useSelector,
} from "react-redux"
import {
  showTaskCategory,
  hideTaskCategory,
  isTaskCategoryVisible,
} from "reduxSlices/taskTableSlice"
import { Checkbox } from "shared/checkboxes"

const TaskCategoryCheckbox = ({ category }) => {
  const dispatch = useDispatch()

  const checked = useSelector(isTaskCategoryVisible(category.id))

  const toggleCategoryVisibility = (id) => () => {
    const updateFunction = checked ? hideTaskCategory : showTaskCategory

    dispatch(updateFunction(id))
  }

  return (
    <div>
      <Checkbox
        key={category.id}
        value={checked}
        onChange={toggleCategoryVisibility(category.id)}
      />
    </div>
  )
}

TaskCategoryCheckbox.propTypes = {
  category: types.objectOf(types.any).isRequired,
}

export default TaskCategoryCheckbox
