/* eslint-disable no-nested-ternary */
import React from "react"
import { useSelector } from "react-redux"
import {
  getFilteredSortedGroupedSubmissions,
  getIsLoading,
  getVisibleColumns,
} from "reduxSlices/formSubmissionTableSlice"
import SubmissionGrouping from "./SubmissionGrouping"
import AllSolutionGroupingsAreEmpty from "./AllSolutionGroupingsAreEmpty"
import LoadingFormSubmissions from "./LoadingFormSubmissions"

const SubmissionGroupingList = () => {
  const loading = useSelector(getIsLoading)
  const submissionGroups = useSelector(getFilteredSortedGroupedSubmissions)
  const visibleColumns = useSelector(getVisibleColumns)

  if (loading) {
    return (
      <div className="pt-16">
        <LoadingFormSubmissions />
      </div>
    )
  }

  const noSubmissionsToShow = Object.keys(submissionGroups).every((group) => (
    submissionGroups[group].length === 0
  ))

  if (noSubmissionsToShow) {
    return (
      <div className="pt-16">
        <AllSolutionGroupingsAreEmpty />
      </div>
    )
  }

  return (
    <div>
      {
        Object.entries(submissionGroups).map(([groupName, tableData]) => (
          <SubmissionGrouping
            key={groupName}
            columns={visibleColumns}
            groupName={groupName}
            tableData={tableData}
          />
        ))
      }
    </div>
  )
}

export default SubmissionGroupingList
