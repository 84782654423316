import React from "react"
import types from "prop-types"
import { ReportContextProvider } from "contexts/ReportContext"
import * as API from "services/api"
import {
  editSolutionLevelScheduledReportExportPath, newSolutionLevelReportExportPath, newSolutionLevelScheduledReportExportPath, solutionLevelReportEditPath,
} from "utils/routeHelpers"
import ReportsSection from "./ReportsSection"

const SolutionLevelReportsSection = ({ className = "", reports = [] }) => (
  <ReportContextProvider
    reports={reports}
    apiCallToDeleteReport={API.deleteSolutionLevelReport}
    editPath={solutionLevelReportEditPath}
  >
    <ReportsSection
      className={className}
      newReportExportPath={newSolutionLevelReportExportPath}
      newScheduledReportExportPath={newSolutionLevelScheduledReportExportPath}
      editScheduledReportExportPath={editSolutionLevelScheduledReportExportPath}
      title="Solution Level Reports"
    />
  </ReportContextProvider>
)

SolutionLevelReportsSection.propTypes = {
  className: types.string,
  reports: types.arrayOf(types.object),
}

export default SolutionLevelReportsSection
