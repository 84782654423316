import React from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { getFormQuestionAttributeIsDropdown, getFormQuestionAttributePrecision, setQuestionAttributePrecision } from "reduxSlices/formBuilderSlice"
import { NumberInput } from "shared/inputs"

const Precision = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const questionPrecision = useSelector(getFormQuestionAttributePrecision({ sectionIndex, questionIndex }))
  const questionIsDropdown = useSelector(getFormQuestionAttributeIsDropdown({ sectionIndex, questionIndex }))

  const updatePrecision = (newPrecision) => dispatch(setQuestionAttributePrecision({
    sectionIndex, questionIndex, newPrecision,
  }))

  if (questionIsDropdown) return null

  return (
    <div className="mb-4 flex-center">
      <div className="font-medium">Precision</div>
      <div className="w-2/5">
        <NumberInput
          className="bordered-base-input h-8 text-sm"
          value={questionPrecision}
          min={0}
          disabled={questionIsDropdown}
          placeholder="Precision"
          onChange={updatePrecision}
        />
      </div>
    </div>
  )
}

Precision.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default Precision
