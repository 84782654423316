import React from "react"
import { useFormSubmissionAuditTrailContext } from "contexts/FormSubmissionAuditTrailContext"
import { taskShape } from "utils/propTypeShapes"
import { Checkbox } from "shared/checkboxes"
import { TaskGeneralIcon, TaskSubformIcon } from "shared/icons"
// eslint-disable-next-line max-len
import { taskTextIdentifier } from "views/FormSubmission/FormSubmissionTabs/ActivitiesContent/ActivitiesSidebar/TaskShortcut"

const ShowTaskAuditsCheckbox = ({ task }) => {
  const {
    auditsForTaskShown,
    hideAuditsForTask,
    showAuditsForTask,
  } = useFormSubmissionAuditTrailContext()

  const { id: taskId, subform: isSubform } = task

  const auditsShown = auditsForTaskShown({ taskId })

  const toggleAuditVisibility = () => (
    auditsShown ? hideAuditsForTask({ taskId }) : showAuditsForTask({ taskId })
  )

  const TaskIconComponent = isSubform ? TaskSubformIcon : TaskGeneralIcon

  return (
    <div
      className="mb-2 rounded-md cursor-pointer p-2 border border-lightgray-3
                 text-sm font-medium w-full flex items-center"
      onClickCapture={toggleAuditVisibility}
    >
      <TaskIconComponent className="mr-1.5 w-6 h-6 flex-shrink-0" />
      <span className="flex-grow mr-4">{taskTextIdentifier(task)}</span>
      <Checkbox value={auditsShown} />
    </div>
  )
}

ShowTaskAuditsCheckbox.propTypes = {
  task: taskShape.isRequired,
}

export default ShowTaskAuditsCheckbox
