import React, { useState, useCallback } from "react"
import types from "prop-types"
import AddQuestionTagButton from "./AddQuestionTagButton"
import NewQuestionTagForm from "./NewQuestionTagForm"

const AddQuestionTag = ({ addQuestionTag, tagNames }) => {
  const [showForm, setShowForm] = useState(false)

  const openForm = useCallback(() => setShowForm(true), [setShowForm])

  const closeForm = useCallback(() => setShowForm(false), [setShowForm])

  return (
    showForm
      ? (
        <NewQuestionTagForm
          addQuestionTag={addQuestionTag}
          className="solution-container-dark"
          onCancel={closeForm}
          tagNames={tagNames}
        />
      )
      : <AddQuestionTagButton onClick={openForm} />
  )
}

AddQuestionTag.propTypes = {
  addQuestionTag: types.func.isRequired,
  tagNames: types.arrayOf(types.string).isRequired,
}

export default AddQuestionTag
