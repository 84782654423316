import React from "react"
import { auditItemDetailsComponent } from "utils/auditHelper"
import { orgLocalDateTime } from "utils/dateHelpers"
import { auditShape } from "utils/propTypeShapes"
import { ProfileIcon } from "shared/icons"

const AuditItem = ({ audit }) => {
  const AuditItemDetailsComponent = auditItemDetailsComponent(audit)

  return (
    <div
      className="border rounded-lg border-light-300 bg-primary-light mb-6 p-3 text-sm grid"
      style={{ gridTemplateColumns: "44px 1fr 170px" }}
    >
      <ProfileIcon className="w-8 h-8 mr-3" user={audit.user} />
      <AuditItemDetailsComponent audit={audit} />
      <span className="font-medium tracking-tight justify-self-end">
        { orgLocalDateTime(audit.createdAt) }
      </span>
    </div>
  )
}

AuditItem.propTypes = {
  audit: auditShape.isRequired,
}

export default AuditItem
