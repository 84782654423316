import React, { useRef } from "react"
import { isEqual } from "lodash-es"
import { useSelector, useDispatch } from "react-redux"
import { moveTaskDefaultColumn, orderedTaskColumns } from "reduxSlices/reportBuilderSlice"
import { DraggableColumnTypes } from "./DragAndDrop/DraggableColumnTypes"
import SelectedColumn from "./SelectedColumn"
import useScrollToAdded from "./DragAndDrop/useScrollToAdded"
import SelectionDropZone from "./DragAndDrop/SelectionDropZone"

const TaskDefaultSelections = () => {
  const dispatch = useDispatch()
  const defaultTaskColumns = useSelector(orderedTaskColumns, isEqual)

  const ref = useRef(null)

  useScrollToAdded(ref, defaultTaskColumns)

  const moveColumn = (column, toIndex) => (
    dispatch(moveTaskDefaultColumn({ column, toIndex }))
  )

  return (
    <SelectionDropZone
      acceptableTypes={DraggableColumnTypes.TASK_DEFAULT_SELECTION}
      ref={ref}
    >
      {
        defaultTaskColumns.map((column, index) => (
          <SelectedColumn
            key={`${column.type}-${column.id}`}
            column={{ ...column, index }}
            moveColumn={moveColumn}
          />
        ))
      }
    </SelectionDropZone>
  )
}

export default TaskDefaultSelections
