import React from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import * as API from "services/api"
import { canFormActiveStatusChangeInIndex } from "utils/formDataHelpers"
import { setFormActiveStatus } from "reduxSlices/formIndexSlice"
import { getWorkflows } from "reduxSlices/workflowsSlice"
import { errorToast } from "components/shared/toast"
import Switch from "shared/Switch"

const ActiveToggle = ({ form }) => {
  const dispatch = useDispatch()

  const workflows = useSelector(getWorkflows)

  const [ableToChangeActiveStatus, messages] = canFormActiveStatusChangeInIndex({ form, workflows })

  const toggleActive = async (active) => {
    const response = await API.updateFormActive({ formSlug: form.slug, active })

    if (response.ok) {
      dispatch(setFormActiveStatus({ id: form.id, active: !form.active }))
    } else {
      errorToast("Something went wrong. Unable to change the form's active status.")
    }
  }

  return (
    <Switch
      checked={form.active}
      disabled={!ableToChangeActiveStatus}
      onChange={(checked) => toggleActive(checked)}
      tooltipText={messages.join(" ")}
    />
  )
}

ActiveToggle.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  form: types.object.isRequired,
}

export default ActiveToggle
