import React from "react"
import types from "prop-types"

const TableHead = ({ column }) => {
  const { width = "inherit" } = column
  return (
    <th className="text-dark" style={{ width }}>
      { column.label }
    </th>
  )
}

TableHead.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  column: types.object.isRequired,
}

export default TableHead
