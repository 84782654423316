import React, { useCallback, useEffect } from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { useDispatch, useSelector } from "react-redux"
import {
  getFormIsSubform,
  getFormQuestionAttributeDefaultAnswer,
  getFormQuestionOptions,
  setQuestionAttributeDefaultAnswer,
} from "reduxSlices/formBuilderSlice"
import { ListSelect } from "shared/selects"

const EMPTY_ANSWER = ""

const SelectCSVDefaultAnswer = ({ questionIndex, sectionIndex }) => {
  const dispatch = useDispatch()

  const isSubform = useSelector(getFormIsSubform)
  const questionDefaultAnswer = useSelector(getFormQuestionAttributeDefaultAnswer({ sectionIndex, questionIndex }))
  const questionOptions = useSelector(getFormQuestionOptions({ sectionIndex, questionIndex }), isEqual)

  const updateDefaultAnswer = useCallback((newDefaultAnswer) => dispatch(setQuestionAttributeDefaultAnswer({
    sectionIndex, questionIndex, newDefaultAnswer,
  })), [sectionIndex, questionIndex, dispatch])

  // Task forms (i.e., subforms) do not permit default answers
  // Ensure the default answer is an empty string
  useEffect(() => {
    if (isSubform && questionDefaultAnswer !== EMPTY_ANSWER) {
      updateDefaultAnswer(EMPTY_ANSWER)
    }
  }, [isSubform, questionDefaultAnswer, updateDefaultAnswer])

  // Do not render anything for a task form
  if (isSubform) return null

  return (
    <div className="flex-center mb-4">
      <div className="font-medium">Default</div>
      <ListSelect
        className="text-sm"
        style={{ height: "32px" }}
        options={questionOptions}
        value={questionDefaultAnswer}
        onChange={updateDefaultAnswer}
        clearable={Boolean(questionDefaultAnswer)}
      />
    </div>
  )
}

SelectCSVDefaultAnswer.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default SelectCSVDefaultAnswer
