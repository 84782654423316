import React from "react"
import types from "prop-types"
import Tooltip, { LEFT_CENTER } from "components/shared/Tooltip"
import { CountBadge } from "components/shared/badges"

const UserCountBadge = ({ count, userNames, className = "" }) => (
  <div className={className}>
    <Tooltip
      className="overflow-y-auto"
      maxHeight="max-h-60"
      position={LEFT_CENTER}
      text={userNames.join("\n")}
    >
      <CountBadge className="relative w-8 h-8" count={count} />
    </Tooltip>
  </div>
)

UserCountBadge.propTypes = {
  className: types.string,
  count: types.number.isRequired,
  userNames: types.arrayOf(types.string).isRequired,
}

export default UserCountBadge
