import { DirectUpload } from "@rails/activestorage"
import * as API from "services/api"

class Uploader {
  constructor(blob, formSubmissionSlug, uploadsToken, updateFile, updatedFile) {
    this.updatedFile = updatedFile
    this.formSubmissionSlug = formSubmissionSlug
    this.uploadsToken = uploadsToken
    this.updateFile = updateFile
    this.directUpload = new DirectUpload(blob, "/rails/active_storage/direct_uploads", this)
  }

  upload() {
    this.directUpload.create(async (error, blob) => {
      if (error) {
        this.updateFile(
          {
            ...this.updatedFile,
            progress: 0,
            errors: `${error}. Delete and try again.`,
          },
        )
        return
      }

      this.updateFile(
        {
          ...this.updatedFile,
          progress: 98,
          signedBlobId: blob.signed_id,
        },
      )

      const response = await API.attachFormSubmissionFiles({
        signedBlobId: blob.signed_id,
        formSubmissionSlug: this.formSubmissionSlug,
        uploadsToken: this.uploadsToken,
      })

      if (response.ok) {
        this.updateFile(
          {
            ...this.updatedFile,
            progress: 100,
            attached: true,
            signedBlobId: blob.signed_id,
          },
        )
      } else {
        this.updateFile(
          {
            ...this.updatedFile,
            progress: 0,
            attached: false,
            signedBlobId: blob.signed_id,
            errors: response.data.errors?.join(", "),
          },
        )
      }
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress",
      (event) => this.directUploadDidProgress(event, request))
  }

  // From https://edgeguides.rubyonrails.org/active_storage_overview.html#track-the-progress-of-the-file-upload
  // eslint-disable-next-line class-methods-use-this
  directUploadDidProgress(event, request) {
    const percentCompleted = ((event.loaded / event.total) * 100).toFixed(1)

    if (percentCompleted > 5 && percentCompleted < 98) {
      this.updateFile(
        {
          ...this.updatedFile,
          progress: percentCompleted,
          request,
        },
      )
    }
  }
}

export default Uploader
