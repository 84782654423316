/* eslint-disable react/forbid-prop-types, camelcase */
import React, { useEffect } from "react"
import types from "prop-types"
import { useTaskFormContext } from "contexts/TaskFormContext"
import * as TaskFields from "./TaskFields"

const mapContextTaskDataToCreateTaskWorkflowData = (contextTaskData) => (
  {
    assignee_departments: contextTaskData.assigneeDepartments,
    assignee_group_ids: contextTaskData.assigneeGroupIds,
    assignee_ids: contextTaskData.assigneeIds,
    description: contextTaskData.description,
    due_date: contextTaskData.dueDate,
    manage_access_task_ids: contextTaskData.manageAccessTaskIds,
    member_departments: contextTaskData.memberDepartments,
    member_group_ids: contextTaskData.memberGroupIds,
    member_ids: contextTaskData.memberIds,
    notify_group_ids: contextTaskData.notifyGroupIds,
    notify_on_complete_group_ids: contextTaskData.notifyOnCompleteGroupIds,
    notify_ids: contextTaskData.notifyIds,
    notify_on_complete_ids: contextTaskData.notifyOnCompleteIds,
    relative_due_date: contextTaskData.relativeDueDate,
    subform_id: contextTaskData.subformId,
    title: contextTaskData.title,
  }
)

const CreateTaskWorkflowActionForm = ({ actionIndex, updateTask }) => {
  const { task: contextTask } = useTaskFormContext()

  useEffect(() => {
    updateTask(mapContextTaskDataToCreateTaskWorkflowData(contextTask))
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [contextTask])

  return (
    <div className="pt-6 w-full">
      <TaskFields.Title actionIndex={actionIndex} className="mb-8" />
      <TaskFields.Description className="mb-8" />
      <TaskFields.RelativeDueDate actionIndex={actionIndex} className="mb-8" />
      <TaskFields.Subform actionIndex={actionIndex} className="mb-8" />
      <TaskFields.TaskAssignees actionIndex={actionIndex} className="mb-8" />
      <TaskFields.TaskMembers actionIndex={actionIndex} />
    </div>
  )
}

CreateTaskWorkflowActionForm.propTypes = {
  actionIndex: types.number.isRequired,
  updateTask: types.func.isRequired,
}

export default CreateTaskWorkflowActionForm
