import React from "react"
import types from "prop-types"
import clsx from "clsx"

const QuestionButton = ({
  children, className, disabled, onClick,
}) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={clsx(className, "form-builder-question-button", disabled && "opacity-75 cursor-not-allowed")}
    form=""
  >
    {children}
  </button>
)

QuestionButton.defaultProps = {
  className: undefined,
  disabled: false,
}

QuestionButton.propTypes = {
  children: types.node.isRequired,
  className: types.string,
  disabled: types.bool,
  onClick: types.func.isRequired,
}

export default QuestionButton
