import React, { useState } from "react"
import types from "prop-types"
import SurveyConfigurationContext from "../SurveyConfigurationContext"

const SurveyConfigurationContextProvider = ({ children, surveyConfiguration: propsSurveyConfiguration }) => {
  const [surveyConfiguration, setSurveyConfiguration] = useState(propsSurveyConfiguration)

  // For managing the survey configuration's contacts
  const surveyConfigurationContactsCount = surveyConfiguration.contactInclusions?.length
  const surveyConfigurationHasContacts = surveyConfigurationContactsCount > 0

  const addSurveyConfigurationContacts = (newContactInclusions) => setSurveyConfiguration(
    {
      ...surveyConfiguration,
      contactInclusions: [
        ...surveyConfiguration.contactInclusions,
        ...newContactInclusions,
      ],
    },
  )

  const removeSurveyConfigurationContact = (contactInclusionId) => setSurveyConfiguration(
    {
      ...surveyConfiguration,
      contactInclusions: surveyConfiguration.contactInclusions.filter((item) => item.id !== contactInclusionId),
    },
  )

  // For managing the survey configuration's batches
  const surveyConfigurationHasBatches = surveyConfiguration.surveyBatches?.length > 0
  const addSurveyBatch = (newBatch) => setSurveyConfiguration(
    {
      ...surveyConfiguration,
      surveyBatches: [
        ...surveyConfiguration.surveyBatches,
        newBatch,
      ],
    },
  )

  // For managing whether survey configuration is active/inactive
  const updateSurveyConfigurationActive = (active) => setSurveyConfiguration(
    {
      ...surveyConfiguration,
      active,
    },
  )

  // Object exposed to context consumers
  const contextConsumerValue = {
    addSurveyBatch,
    addSurveyConfigurationContacts,
    removeSurveyConfigurationContact,
    surveyConfiguration,
    surveyConfigurationHasBatches,
    surveyConfigurationHasContacts,
    updateSurveyConfigurationActive,
  }

  return (
    <SurveyConfigurationContext.Provider value={contextConsumerValue}>
      {children}
    </SurveyConfigurationContext.Provider>
  )
}

SurveyConfigurationContextProvider.propTypes = {
  children: types.node.isRequired,
  surveyConfiguration: types.object.isRequired,
}

export default SurveyConfigurationContextProvider
