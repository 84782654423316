import React, { useRef, useState } from "react"
import types from "prop-types"
import * as API from "services/api"
import { MAX_FILE_COUNT } from "components/views/FormSubmissionUploads/Content/FileUploader"
import { errorToast } from "components/shared/toast"
import FileUploadContext from "../FileUploadContext"

const FileUploadContextProvider = ({ formSubmissionSlug, uploadsToken, children }) => {
  const [files, setFiles] = useState([])
  const stateRef = useRef()
  stateRef.current = files

  const removeFile = (file) => {
    const { key, request, signedBlobId } = file

    if (request) {
      request.abort()
    } else if (signedBlobId) {
      API.removeFormSubmissionFile({
        signedBlobId,
        formSubmissionSlug,
        uploadsToken,
      })
    }

    const updatedFiles = stateRef.current.filter(((item) => item.key !== key))

    setFiles(updatedFiles)
  }

  const updateFile = (updatedFile) => {
    const {
      key, src, progress, attached, signedBlobId, errors, request,
    } = updatedFile

    const matchingKey = stateRef.current.find((file) => file.key === key)

    if (matchingKey) {
      const allFiles = stateRef.current.map(
        (file) => (file.key === key ? {
          ...file, src, progress, attached, signedBlobId, errors, request,
        } : file),
      )

      setFiles(allFiles)
    }
  }

  const addFile = (newFile) => {
    if (files.length >= MAX_FILE_COUNT) {
      errorToast(`No more than ${MAX_FILE_COUNT} files can be attached.`)
    }

    const allFiles = [...stateRef.current, newFile]

    setFiles(allFiles)
  }

  // Object exposed to context consumers
  const contextConsumerValue = {
    addFile,
    files,
    formSubmissionSlug,
    removeFile,
    updateFile,
    uploadsToken,
  }

  return (
    <FileUploadContext.Provider value={contextConsumerValue}>
      {children}
    </FileUploadContext.Provider>
  )
}

FileUploadContextProvider.propTypes = {
  formSubmissionSlug: types.string.isRequired,
  uploadsToken: types.string.isRequired,
  children: types.node.isRequired,
}

export default FileUploadContextProvider
