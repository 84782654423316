import React, { useState, useEffect, useCallback } from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import consumer from "channels/consumer"
import { currentUserId as getCurrentUserId } from "reduxSlices/sessionSlice"
import * as API from "services/api"
import TaskAnswerEditorsContext from "../TaskAnswerEditorsContext"

const NO_EDITORS = {}

const TaskAnswerEditorsContextProvider = ({ children, taskId }) => {
  const [editors, setEditors] = useState(NO_EDITORS)

  const currentUserId = useSelector(getCurrentUserId)

  // Load existing editors
  useEffect(() => {
    const loadAnswerEditingSessions = async () => {
      const response = await API.taskAnswerEditingSessions({ taskId })
      setEditors(response.ok ? response.data : NO_EDITORS)
    }

    loadAnswerEditingSessions()
  }, [taskId])

  // Subscribe for updates to the answer editors
  useEffect(() => {
    const subscription = consumer.subscriptions.create(
      { channel: "TaskAnswerEditorsChannel", task_id: taskId },
      {
        connected() {
          // Called when the subscription is ready for use on the server
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
        },

        received: async (data) => {
          setEditors(data)
        },
      },
    )

    return () => {
      subscription.unsubscribe()

      if (consumer.subscriptions.subscriptions.length === 0) {
        consumer.disconnect()
      }
    }
  }, [taskId])

  // Internal functions
  const currentUserEditingQuestion = useCallback((questionUuid) => {
    const editor = editors[questionUuid]
    if (!editor) return false

    return editor.id === currentUserId
  }, [currentUserId, editors])

  // Functions to expose to context consumers
  const anotherUserEditingQuestion = useCallback((questionUuid) => {
    const editor = editors[questionUuid]
    if (!editor) return false

    return editor.id !== currentUserId
  }, [currentUserId, editors])

  const answerEditorName = useCallback((questionUuid) => {
    const editor = editors[questionUuid]
    return editor?.name
  }, [editors])

  const beginEditingSession = useCallback((questionUuid) => {
    API.beginAnswerEditingSession({ taskId, questionUuid })
  }, [taskId])

  const endEditingSession = useCallback((questionUuid) => {
    if (currentUserEditingQuestion(questionUuid)) {
      API.endAnswerEditingSession({ taskId, questionUuid })
    }
  }, [currentUserEditingQuestion, taskId])

  // Object exposed to context consumers
  const contextConsumerValue = {
    anotherUserEditingQuestion,
    answerEditorName,
    beginEditingSession,
    endEditingSession,
  }

  return (
    <TaskAnswerEditorsContext.Provider value={contextConsumerValue}>
      {children}
    </TaskAnswerEditorsContext.Provider>
  )
}

TaskAnswerEditorsContextProvider.propTypes = {
  children: types.node.isRequired,
  taskId: types.number.isRequired,
}

export default TaskAnswerEditorsContextProvider
