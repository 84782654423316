import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getFacilityAndDepartmentFromDepartmentId } from "reduxSlices/facilitiesSlice"

const formattedAnswer = (facility, department) => {
  if (!facility && !department) return null

  return (
    <>
      <span className="block mb-4">
        Facility:<br />{facility?.name}
      </span>
      <span className="block">
        Department:<br />{department?.name}
      </span>
    </>
  )
}

const LocationAnswer = ({ rawAnswer, isNewQuestion }) => {
  const facilityAndDepartment = useSelector(getFacilityAndDepartmentFromDepartmentId(rawAnswer?.department_id))
  if (isNewQuestion) return null

  return (
    <div className="answer">
      { formattedAnswer(...facilityAndDepartment) }
    </div>
  )
}

LocationAnswer.defaultProps = {
  rawAnswer: undefined,
  isNewQuestion: false,
}

LocationAnswer.propTypes = {
  rawAnswer: types.shape({ department_id: types.number }),
  isNewQuestion: types.bool,
}

export default LocationAnswer
