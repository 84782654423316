import React from "react"
import { FormSubmissionScheduledReportExportContextProvider } from "contexts/FormSubmissionScheduledReportExportContext"
import { reportExportFormSubmissionReportShape } from "utils/propTypeShapes"
import NewFormSubmissionScheduledReportExportForm from "./NewFormSubmissionScheduledReportExportForm"

const NewFormSubmissionScheduledReportExport = ({ formSubmissionReport }) => (
  <FormSubmissionScheduledReportExportContextProvider formSubmissionReport={formSubmissionReport}>
    <NewFormSubmissionScheduledReportExportForm />
  </FormSubmissionScheduledReportExportContextProvider>
)

NewFormSubmissionScheduledReportExport.propTypes = {
  formSubmissionReport: reportExportFormSubmissionReportShape.isRequired,
}

export default NewFormSubmissionScheduledReportExport
