import React from "react"
import ReactMarkdown from "react-markdown"
import { featureAnnouncementShape } from "utils/propTypeShapes"

const FeatureAnnouncement = ({ featureAnnouncement }) => (
  <div className="feature-announcement-panel">
    <p className="font-bold">{featureAnnouncement.title}</p>
    <ReactMarkdown
      className="feature-announcement-markdown"
      linkTarget="_blank"
    >
      {featureAnnouncement.message}
    </ReactMarkdown>
  </div>
)

FeatureAnnouncement.propTypes = {
  featureAnnouncement: featureAnnouncementShape.isRequired,
}

export default FeatureAnnouncement
