import React from "react"
import types from "prop-types"
import { ADMIN_ROLE } from "utils/userRoleHelpers"
import HistoricalAccessTool from "shared/HistoricalAccessTool"

const BASE_EXPLANATION = "The historical access tool may not be used"

const noHistoricalAccessExplanation = ({
  firstName, lastName, userIsActive, userIsAdmin,
}) => {
  if (!userIsActive) {
    return `${BASE_EXPLANATION} because the account for ${firstName} ${lastName} has been deactivated.`
  }

  if (userIsAdmin) {
    return `${BASE_EXPLANATION} because ${firstName} ${lastName} is an admin with access to all form submissions.`
  }

  return `${BASE_EXPLANATION} for this user.`
}

const HistoricalAccessToolTab = ({ user }) => {
  const {
    active: userIsActive,
    first_name: firstName,
    last_name: lastName,
    role,
  } = user

  const userIsAdmin = role === ADMIN_ROLE

  return (
    <>
      <div className="tab-title">Historical Access Tool</div>
      {
        userIsActive && !userIsAdmin
          ? (
            <HistoricalAccessTool user={user} />
          )
          : (
            <p>
              {
                noHistoricalAccessExplanation({
                  firstName, lastName, userIsActive, userIsAdmin,
                })
              }
            </p>
          )
      }
    </>
  )
}

HistoricalAccessToolTab.propTypes = {
  user: types.object.isRequired,
}

export default HistoricalAccessToolTab
