import React from "react"
import types from "prop-types"
import { TertiaryButton } from "shared/buttons"

const AddStatusButton = ({ addStatus, ...rest }) => (
  <TertiaryButton
    text="Add Status"
    onClick={addStatus}
    {...rest}
  />
)

AddStatusButton.propTypes = {
  addStatus: types.func.isRequired,
}

export default AddStatusButton
