import React from "react"
import types from "prop-types"

const NumberDataElement = ({ className = "", value = undefined }) => (
  <span className={className}>{ value }</span>
)

NumberDataElement.propTypes = {
  value: types.number,
  className: types.string,
}

export default NumberDataElement
