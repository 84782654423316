import React from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { useDispatch, useSelector } from "react-redux"
import {
  getFormQuestionFlagCondition,
  getFormQuestionHasFlagCondition,
  getFormQuestionUuid,
  removeQuestionFlagCondition,
  setQuestionFlagConditionOperator,
  setQuestionFlagConditionMatchValue,
} from "reduxSlices/formBuilderSlice"
import { operatorRequiresMatchValue } from "utils/QuestionBranching"
import DeleteConditionButton from "views/FormBuilder/UIElements/DeleteConditionButton"
import FormsyValidation from "shared/FormsyValidation"
import ConditionMatchValueField from "../QuestionBranching/ConditionMatchValueField"
import ConditionOperatorField from "../QuestionBranching/ConditionOperatorField"

const validations = {
  questionFlagCondition: (_model, condition) => {
    const { operator, matchValue } = condition

    if (!operator) return "Must select operator"
    if (!operatorRequiresMatchValue(operator)) return true
    if (!matchValue && matchValue !== 0) return "Must enter match value"
    if (matchValue?.length === 0) return "Must enter match value"

    return true
  },
}

const ConditionDefiner = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const questionUuid = useSelector(getFormQuestionUuid({ sectionIndex, questionIndex }))
  const questionHasFlagCondition = useSelector(getFormQuestionHasFlagCondition({ sectionIndex, questionIndex }))
  const questionFlagCondition = useSelector(getFormQuestionFlagCondition({ sectionIndex, questionIndex }), isEqual)

  if (!questionHasFlagCondition) return null

  const { operator, matchValue } = questionFlagCondition

  const removeFlagCondition = () => dispatch(removeQuestionFlagCondition({ sectionIndex, questionIndex }))

  const updateFlagConditionOperator = (newOperator) => dispatch(setQuestionFlagConditionOperator({
    sectionIndex, questionIndex, newOperator,
  }))

  const updateFlagConditionMatchValue = (newMatchValue) => dispatch(setQuestionFlagConditionMatchValue({
    sectionIndex, questionIndex, newMatchValue,
  }))

  const matchValueRequired = operator && operatorRequiresMatchValue(operator)

  return (
    <div className="flex flex-col border border-light p-3 rounded-lg relative mr-6 mb-3">
      <DeleteConditionButton
        deleteCondition={removeFlagCondition}
        className="absolute -right-8 top-3 h-5 w-5 bg-white rounded-full p-1"
      />
      <ConditionOperatorField
        conditionQuestionUuid={questionUuid}
        setConditionOperator={updateFlagConditionOperator}
        value={operator}
      />
      {
        matchValueRequired && (
          <ConditionMatchValueField
            setConditionMatchValue={updateFlagConditionMatchValue}
            conditionQuestionUuid={questionUuid}
            conditionOperator={operator}
            matchValue={matchValue}
          />
        )
      }
      <FormsyValidation
        name={`sections[${sectionIndex}].questions[${questionIndex}].flagCondition`}
        validations={validations}
        value={questionFlagCondition}
      />
    </div>
  )
}

ConditionDefiner.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default ConditionDefiner
