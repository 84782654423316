import React from "react"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import CreateGroupModal from "shared/modals/CreateGroupModal"

const EmptyGroups = () => (
  <div className="flex flex-col items-center pt-4">
    <SvgSpriteIcon iconName="empty-groups" className="h-10 w-10 mb-4" />
    <div className="font-medium text-sm">
      You do not have any groups yet
    </div>
    <CreateGroupModal />
  </div>
)

export default EmptyGroups
