/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getGroups, getUsers } from "reduxSlices/formBuilderSlice"
import { workflowActionShape } from "utils/propTypeShapes"
import { GroupAndUserSearch } from "shared/searches"
import StrikeAround from "components/shared/StrikeAround"
import { actionValidations } from "utils/workflowHelpers"
import FormsyValidation from "shared/FormsyValidation"
import SelectedGroups from "../DefineNotifySelectedUsersData/SelectedGroups"
import SelectedUsers from "../DefineNotifySelectedUsersData/SelectedUsers"

const EMPTY_MEMBERS = {
  members: {
    groups: [],
    users: [],
  },
}

const DefineAddMembersSelectedUsersData = ({ action, actionIndex, changeActionData }) => {
  const { data } = action
  const groups = useSelector(getGroups)
  const users = useSelector(getUsers)

  useEffect(() => {
    if (data.members) return
    changeActionData(EMPTY_MEMBERS)
  }, [])

  const addGroup = (groupId) => {
    const newGroup = { id: Number(groupId), access_level: "standard_access" }
    const updatedData = {
      ...data,
      members: {
        ...(data.members),
        groups: [...(data.members.groups), newGroup],
      },
    }

    changeActionData(updatedData)
  }

  const addUser = (userId) => {
    const newUser = { id: Number(userId), access_level: "standard_access" }
    const updatedData = {
      ...data,
      members: {
        ...(data.members),
        users: [...(data.members.users), newUser],
      },
    }

    changeActionData(updatedData)
  }

  const addRecipient = (value) => {
    const [type, id] = value.split("-")
    const addFunction = type === "Group" ? addGroup : addUser

    addFunction(id)
  }

  const setUserAccessLevel = (value, userId) => {
    const userIndex = data.members.users.findIndex((user) => user.id === userId)
    const newUsers = [...data.members.users]
    newUsers[userIndex] = { id: Number(userId), access_level: value }

    const updatedData = {
      ...data,
      members: {
        ...(data.members),
        users: newUsers,
      },
    }

    changeActionData(updatedData)
  }

  const setGroupAccessLevel = (value, groupId) => {
    const groupIndex = data.members.groups.findIndex((group) => group.id === groupId)
    const newGroups = [...data.members.groups]
    newGroups[groupIndex] = { id: Number(groupId), access_level: value }

    const updatedData = {
      ...data,
      members: {
        ...(data.members),
        groups: newGroups,
      },
    }

    changeActionData(updatedData)
  }

  const removeGroup = (groupId) => {
    const newGroups = data.members.groups.filter((group) => group.id !== groupId)
    const updatedData = {
      ...data,
      members: {
        ...(data.members),
        groups: newGroups,
      },
    }

    changeActionData(updatedData)
  }

  const removeUser = (userId) => {
    const newUsers = data.members.users.filter((user) => user.id !== userId)
    const updatedData = {
      ...data,
      members: {
        ...(data.members),
        users: newUsers,
      },
    }

    changeActionData(updatedData)
  }

  const selectedGroups = groups.filter((group) => data.members?.groups?.map((g) => g.id).includes(group.id))
  const selectedUsers = users.filter((user) => data.members?.users?.map((u) => u.id).includes(user.id))
  const validations = actionValidations(action.type)

  return (
    <div className="w-full mt-4">
      <div className="relative">
        <GroupAndUserSearch
          className="h-8"
          onSearchSelected={addRecipient}
          selectedGroups={selectedGroups}
          selectedUsers={selectedUsers}
          groups={groups}
          users={users}
        />
        <FormsyValidation
          name={`actions[${actionIndex}]`}
          value={action}
          validations={{ validations }}
        />
      </div>
      <StrikeAround>
        <span className="my-3 font-medium">Groups</span>
      </StrikeAround>
      <SelectedGroups
        groups={selectedGroups}
        removeGroup={removeGroup}
        setGroupAccessLevel={setGroupAccessLevel}
        workflowData={data.members?.groups}
      />
      <StrikeAround>
        <span className="my-3 font-medium">Individuals</span>
      </StrikeAround>
      <SelectedUsers
        users={selectedUsers}
        removeUser={removeUser}
        setUserAccessLevel={setUserAccessLevel}
        workflowData={data.members?.users}
      />
    </div>
  )
}

DefineAddMembersSelectedUsersData.propTypes = {
  action: workflowActionShape.isRequired,
  changeActionData: types.func.isRequired,
  actionIndex: types.number.isRequired,
}

export default DefineAddMembersSelectedUsersData
