import React from "react"
import { useSelector } from "react-redux"
import { formSubmissionData } from "reduxSlices/formSubmissionSlice"
import { useFormSubmissionFormContext } from "contexts/FormSubmissionFormContext"
import { useFormSubmissionAuditTrailContext } from "contexts/FormSubmissionAuditTrailContext"
import { FormIcon } from "shared/icons"
import { Checkbox } from "components/shared/checkboxes"

const ShowFormSubmissionAuditsCheckbox = () => {
  const { slug } = useSelector(formSubmissionData)
  const { form } = useFormSubmissionFormContext()

  const {
    formSubmissionAuditsShown,
    showFormSubmissionAudits,
    hideFormSubmissionAudits,
  } = useFormSubmissionAuditTrailContext()

  return (
    <div
      className="mb-2 rounded-md cursor-pointer p-2 border border-lightgray-3
                 text-sm font-medium w-full flex items-center"
      onClickCapture={formSubmissionAuditsShown ? hideFormSubmissionAudits : showFormSubmissionAudits}
    >
      <FormIcon className="mr-1.5 w-6 h-6 flex-shrink-0" form={form} />
      <span className="flex-grow mr-4">{slug}</span>
      <Checkbox value={formSubmissionAuditsShown} />
    </div>
  )
}

export default ShowFormSubmissionAuditsCheckbox
