import React from "react"
import types from "prop-types"
import {
  FLAGGED_CONDITION_PROPERTY,
  QUESTION_ANSWER_CONDITION_PROPERTY,
  SUBFORM_ANSWER_CONDITION_PROPERTY,
  STATUS_CONDITION_PROPERTY,
  SUBMISSION_AGE_CONDITION_PROPERTY,
  SUBMITTED_FORM_PROPERTY,
} from "utils/workflowHelpers"
import { workflowConditionShape } from "utils/propTypeShapes"
import * as OperatorDefiners from "./ConditionOperatorDefiners/index"

const DefineConditionOperator = ({
  condition,
  changeConditionOperator,
  conditionIndex,
}) => {
  switch (condition.property) {
  case FLAGGED_CONDITION_PROPERTY:
    return (
      <OperatorDefiners.DefineFlaggedOperator
        condition={condition}
        conditionIndex={conditionIndex}
        changeConditionOperator={changeConditionOperator}
      />
    )
  case STATUS_CONDITION_PROPERTY:
    return (
      <OperatorDefiners.DefineStatusOperator
        condition={condition}
        conditionIndex={conditionIndex}
        changeConditionOperator={changeConditionOperator}
      />
    )
  case QUESTION_ANSWER_CONDITION_PROPERTY:
  case SUBFORM_ANSWER_CONDITION_PROPERTY:
    return (
      <OperatorDefiners.DefineQuestionAnswerOperator
        condition={condition}
        conditionIndex={conditionIndex}
        changeConditionOperator={changeConditionOperator}
      />
    )
  case SUBMISSION_AGE_CONDITION_PROPERTY:
    return (
      <OperatorDefiners.DefineSubmissionAgeOperator
        condition={condition}
        changeConditionOperator={changeConditionOperator}
      />
    )
  case SUBMITTED_FORM_PROPERTY:
    return (
      <OperatorDefiners.DefineSubmittedFormOperator
        condition={condition}
        changeConditionOperator={changeConditionOperator}
      />
    )
  default:
    return null
  }
}

DefineConditionOperator.propTypes = {
  condition: workflowConditionShape.isRequired,
  conditionIndex: types.number.isRequired,
  changeConditionOperator: types.func.isRequired,
}

export default DefineConditionOperator
