import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { taskShape } from "utils/propTypeShapes"
import { CheckmarkCircleIcon } from "shared/icons"
import TaskHeaderAssigneesOverview from "./TaskHeaderAssigneesOverview"
import TaskDueDate from "./TaskDueDate"
import TaskTokenTitle from "./TaskTokenTitle"

const TaskItemRowHeaderContent = ({ showAssignees = true, task, toggleTaskVisibility }) => {
  const {
    assigneeIds: taskAssigneeIds,
    dueDate: taskDueDate,
    isDeleted: taskIsDeleted,
    isOpen: taskIsOpen,
    isOverdue: taskIsOverdue,
    subform: taskSubform,
    title: taskTitle,
    userTaskPermissions,
  } = task

  const { canSeeDetails: userCanSeeDetails } = userTaskPermissions
  const userPermittedToSeeDetails = userCanSeeDetails && !taskIsDeleted

  const itemRowHeaderContentBreakerClass = userPermittedToSeeDetails
    ? "item-row-header-content-breaker-double"
    : "item-row-header-content-breaker"

  return (
    <div className="w-full flex flex-col lg:flex-row gap-4 lg:items-center justify-between">
      <div className="flex flex-col lg:flex-row gap-4 lg:items-center">
        <div className="flex items-center gap-2 shrink-0 mb-2 lg:mb-0">
          {
            taskIsOpen
              ? <div className="w-5 h-5 shrink-0 border rounded-full" />
              : <CheckmarkCircleIcon className="w-5 h-5 shrink-0" />
          }
          <TaskDueDate dueDate={taskDueDate} isOverdue={taskIsOverdue} />
        </div>
        <button
          className={clsx(itemRowHeaderContentBreakerClass, "font-medium")}
          disabled={!userPermittedToSeeDetails}
          onClick={toggleTaskVisibility}
        >
          {taskTitle}
        </button>
      </div>
      <div className={clsx(itemRowHeaderContentBreakerClass, "flex items-center gap-4")}>
        <TaskTokenTitle subform={taskSubform} />
        {
          showAssignees && (
            <TaskHeaderAssigneesOverview assigneeIds={taskAssigneeIds} />
          )
        }
      </div>
    </div>
  )
}

TaskItemRowHeaderContent.propTypes = {
  showAssignees: types.bool,
  task: taskShape.isRequired,
  toggleTaskVisibility: types.func.isRequired,
}

export default TaskItemRowHeaderContent
