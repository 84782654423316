import React from "react"
import types from "prop-types"
import { SEGMENTS } from "utils/departmentSegmentHelpers"
import { useTaskFormContext } from "contexts/TaskFormContext"
import { departmentIdentifier } from "utils/taskHelpers"
import TaskDepartmentSegmentCheckbox from "../UI/TaskDepartmentSegmentCheckbox"

const TaskAssigneeDepartmentSegments = ({ department }) => {
  const {
    deselectAssigneeDepartmentSegment,
    selectAssigneeDepartmentSegment,
    selectedMemberDepartmentSegments,
  } = useTaskFormContext()

  const segmentSelected = (segment) => department.segments?.includes(segment)

  const segmentUnavailable = (segment) => (
    (selectedMemberDepartmentSegments[department.departmentId] ?? []).includes(segment)
  )

  const toggleSelection = (segment) => () => {
    const toggleFunction = segmentSelected(segment)
      ? deselectAssigneeDepartmentSegment
      : selectAssigneeDepartmentSegment

    toggleFunction(departmentIdentifier(department), segment)
  }

  return (
    <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-1 lg:gap-4 items-center justify-between">
      {
        SEGMENTS.map((segment) => (
          <TaskDepartmentSegmentCheckbox
            className="text-sm"
            key={segment}
            segment={segment}
            toggleSelection={toggleSelection}
            disabled={segmentUnavailable(segment)}
            selected={segmentSelected(segment)}
            groupIdentifier="assignee"
          />
        ))
      }
    </div>
  )
}

TaskAssigneeDepartmentSegments.propTypes = {
  department: types.object.isRequired,
}

export default TaskAssigneeDepartmentSegments
