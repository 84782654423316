import React from "react"
import types from "prop-types"
import { withFormsy } from "formsy-react"
import InputErrorMessage from "shared/InputErrorMessage"

const FormsyValidationField = withFormsy(({ errorMessages, isPristine }) => (
  <InputErrorMessage isPristine={isPristine} errorMessages={errorMessages} />
))

// This will register a field with Formsy.
// Value will be undefined, so it will not be included in the form data
// used in submissions.
// It allows us to perform validations on the form data that are not tied
// to a specific registered field.
const FormsyValidation = ({
  name,
  required = false,
  validationErrors = {},
  validations = {},
  value = undefined,
}) => (
  <FormsyValidationField
    name={name}
    required={required}
    value={value}
    validations={validations}
    validationErrors={validationErrors}
  />
)

FormsyValidation.propTypes = {
  name: types.string.isRequired,
  required: types.bool,
  validations: types.oneOfType([types.string, types.object]),
  validationErrors: types.oneOfType([types.string, types.object]),
  // eslint-disable-next-line react/forbid-prop-types
  value: types.any,
}

export default FormsyValidation
