import React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getFormActive, getFormId, getFormIsSubform, getFormSlug, getFormUsage, setFormActive,
} from "reduxSlices/formBuilderSlice"
import { getWorkflows } from "reduxSlices/workflowsSlice"
import * as API from "services/api"
import { canFormActiveStatusChange } from "utils/formDataHelpers"
import { SURVEY_USAGE, TASK_USAGE } from "utils/formHelpers"
import ContentBar from "shared/lists/ContentBar"
import Switch from "shared/Switch"
import { errorToast } from "components/shared/toast"
import FormCopyLink from "./FormCopyLink"

const TITLE = "Show Form as Active"
const DEFAULT_DESCRIPTION = "When active, the form will be available to anyone that has the link."
const SURVEY_DESCRIPTION = "When active, the form can be added to a survey configuration."
const TASK_DESCRIPTION = "When active, the form can be attached to a task."

const FormActiveSetting = () => {
  const dispatch = useDispatch()

  const formIsActive = useSelector(getFormActive)
  const formId = useSelector(getFormId)
  const formIsSubform = useSelector(getFormIsSubform)
  const formSlug = useSelector(getFormSlug)
  const formUsage = useSelector(getFormUsage)

  const workflows = useSelector(getWorkflows)

  const updateActive = async (active) => {
    const response = await API.updateFormActive({ formSlug, active })
    if (response.ok) {
      dispatch(setFormActive(active))
    } else {
      errorToast("Something went wrong. Unable to change the form's active status.")
    }
  }

  let description = DEFAULT_DESCRIPTION

  if (formUsage === SURVEY_USAGE) {
    description = SURVEY_DESCRIPTION
  } else if (formUsage === TASK_USAGE) {
    description = TASK_DESCRIPTION
  }

  const [ableToChangeActiveStatus, messages] = canFormActiveStatusChange({
    form: { active: formIsActive, id: formId, isSubform: formIsSubform },
    workflows,
  })

  return (
    <ContentBar
      title={TITLE}
      description={description}
      footer={<FormCopyLink formSlug={formSlug} active={formIsActive} usage={formUsage} />}
    >
      <Switch
        checked={formIsActive}
        disabled={!ableToChangeActiveStatus}
        onChange={updateActive}
        tooltipText={messages.join(" ")}
      />
    </ContentBar>
  )
}

export default FormActiveSetting
