import React from "react"
import types from "prop-types"
import PencilIcon from "shared/icons/PencilIcon"
import { shortMonthDate } from "utils/dateHelpers"
import {
  DAILY,
  DAILY_DESC,
  MONTHLY,
  monthlyDesc,
  WEEKLY,
  weeklyDesc,
} from "utils/recurrenceDetailsHelpers"
import ScheduleActive from "./ScheduleActive"

const lastUploadDesc = (date) => `(last uploaded on ${shortMonthDate(date)})`
const lastFailedDesc = (date) => `(last attempt failed on ${shortMonthDate(date)})`

const scheduleDetails = (scheduledReportExportSchedule) => {
  switch (scheduledReportExportSchedule.frequency) {
  case DAILY:
    return DAILY_DESC
  case WEEKLY:
    return weeklyDesc(scheduledReportExportSchedule.recurrenceDayOfWeek)
  case MONTHLY:
    return monthlyDesc(scheduledReportExportSchedule.recurrenceDayOfMonth)
  default:
    return null
  }
}

const lastUploadDetails = (lastSuccessfulUpload, lastFailedUpload) => {
  if (!lastSuccessfulUpload && !lastFailedUpload) return "(no uploads yet)"

  if (lastSuccessfulUpload && !lastFailedUpload) {
    return lastUploadDesc(lastSuccessfulUpload)
  }

  if (!lastSuccessfulUpload && lastFailedUpload) {
    return lastFailedDesc(lastFailedUpload)
  }

  return lastSuccessfulUpload > lastFailedUpload ? lastUploadDesc(lastSuccessfulUpload) : lastFailedDesc(lastFailedUpload)
}

const ScheduledReportExportDetails = ({ editScheduledReportExportPath, scheduledReportExport, reportId = null }) => {
  const {
    id: exportId, name, scheduledReportExportSchedule, lastFailedUpload, lastSuccessfulUpload,
  } = scheduledReportExport

  const { id: scheduleId } = scheduledReportExportSchedule

  const goToSFTPSettingsEdit = () => {
    window.location.pathname = editScheduledReportExportPath(reportId, exportId)
  }

  return (
    <>
      <div className="w-full flex-col p-1">
        <div className="flex-center">
          <div className="justify-start text-base font-semibold">
            {name}
          </div>
          <div className="mx-2 items-center justify-end">
            <button onClick={goToSFTPSettingsEdit}>
              <PencilIcon className="w-4 h-4 cursor-pointer" />
            </button>
          </div>
        </div>
        <div className="flex pt-2">
          {scheduleDetails(scheduledReportExportSchedule)} {lastUploadDetails(lastSuccessfulUpload, lastFailedUpload)}
        </div>
      </div>
      <div className="flex justify-end w-full p-4">
        <div className="mr-2">SFTP Uploads Active</div>
        <ScheduleActive
          scheduleId={scheduleId}
        />
      </div>
    </>
  )
}

ScheduledReportExportDetails.propTypes = {
  reportId: types.number,
  scheduledReportExport: types.objectOf(types.any).isRequired,
  editScheduledReportExportPath: types.objectOf(types.any).isRequired,
}

export default ScheduledReportExportDetails
