import React from "react"
import { useSurveyReportExportContext } from "contexts/SurveyReportExportContext"
import * as API from "services/api"
import { SmallWrap } from "shared/wraps"
import { DataCollectorContainer, DataCollectorStep } from "shared/dataCollector"
import { errorToast } from "shared/toast"
import NewSurveyReportExportFormActions from "./NewSurveyReportExportFormActions"
import NewSurveyReportExportFormDateAnswerFilters from "./NewSurveyReportExportFormDateAnswerFilters"
import NewSurveyReportExportFormLocationAnswerFilters from "./NewSurveyReportExportFormLocationAnswerFilters"
import NewSurveyReportExportSurveyFilters from "./NewSurveyReportExportSurveyFilters"
import NewSurveyReportExportFileTypeSelect from "./NewSurveyReportExportFileTypeSelect"

const CREATE_REPORT_EXPORT_ERROR_MESSAGE = "Something went wrong.  Unable to initiate export."

const NewSurveyReportExportForm = () => {
  const {
    dateAnswerFilters,
    surveyReport,
    surveyBatchIds,
    locationAnswerFilters,
    reportExportFileType,
    setReportExport,
  } = useSurveyReportExportContext()

  const {
    name: surveyReportName,
    id: surveyReportId,
  } = surveyReport

  const renderDataCollectorHeaderContent = () => (
    <span className="font-medium text-xl">{surveyReportName}</span>
  )

  const exportSurveyReport = async () => {
    const response = await API.createSurveyReportExport({
      dateAnswerFilters,
      surveyBatchIds,
      locationAnswerFilters,
      reportFileType: reportExportFileType,
      reportId: surveyReportId,
    })

    if (response.ok) {
      setReportExport(response.data)
    } else {
      errorToast(CREATE_REPORT_EXPORT_ERROR_MESSAGE)
    }
  }

  return (
    <SmallWrap>
      <DataCollectorContainer renderHeaderContent={renderDataCollectorHeaderContent}>
        <div className="mb-6">
          <DataCollectorStep stepNumber={1} heading="Filter by Survey">
            <NewSurveyReportExportSurveyFilters />
          </DataCollectorStep>
          <DataCollectorStep stepNumber={2} heading="Filter by the Answer to a Date Question">
            <NewSurveyReportExportFormDateAnswerFilters />
          </DataCollectorStep>
          <DataCollectorStep stepNumber={3} heading="Filter by the Answer to a Location Question">
            <NewSurveyReportExportFormLocationAnswerFilters />
          </DataCollectorStep>
          <DataCollectorStep stepNumber={4} heading="Select File Type">
            <NewSurveyReportExportFileTypeSelect />
          </DataCollectorStep>
        </div>
        <NewSurveyReportExportFormActions exportSurveyReport={exportSurveyReport} />
      </DataCollectorContainer>
    </SmallWrap>
  )
}

export default NewSurveyReportExportForm
