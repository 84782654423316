import React from "react"
import types from "prop-types"
import { thisYear } from "utils/dateHelpers"

const Copyright = ({ className = "text-center" }) => (
  <div className={className}>
    &copy; {thisYear()} Performance Health Partners. All rights reserved.
  </div>
)

Copyright.propTypes = {
  className: types.string,
}

export default Copyright
