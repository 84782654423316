import React from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { useDispatch, useSelector } from "react-redux"
import { getFormSectionDeletable, deleteFormSection } from "reduxSlices/formBuilderSlice"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import Tooltip from "shared/Tooltip"
import QuestionButton from "./QuestionButton"

const DeleteSectionButton = ({ className, sectionIndex }) => {
  const dispatch = useDispatch()

  const [deletable, descriptions] = useSelector(getFormSectionDeletable(sectionIndex), isEqual)

  const deleteSection = () => dispatch(deleteFormSection(sectionIndex))

  const tooltipText = deletable ? "" : `Cannot delete section: ${descriptions.join(", ")}`

  return (
    <Tooltip text={tooltipText}>
      <QuestionButton disabled={!deletable} onClick={deleteSection} className={className}>
        <SvgSpriteIcon iconName="trash-can" width={18} height={18} />
      </QuestionButton>
    </Tooltip>
  )
}

DeleteSectionButton.defaultProps = {
  className: undefined,
}

DeleteSectionButton.propTypes = {
  className: types.string,
  sectionIndex: types.number.isRequired,
}

export default DeleteSectionButton
