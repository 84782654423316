import React from "react"
import types from "prop-types"
import { Button, RIGHT } from "shared/buttons"

const AddConditionButton = ({ addCondition }) => (
  <Button
    onClick={addCondition}
    text="Add a condition +"
    className="border border-light"
    iconPosition={RIGHT}
  />
)

AddConditionButton.propTypes = {
  addCondition: types.func.isRequired,
}

export default AddConditionButton
