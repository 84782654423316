import React from "react"
import types from "prop-types"
import { FormsyDateInput } from "shared/inputs"
import { tomorrowDate } from "utils/dateHelpers"

const DailyRecurrenceDetails = ({ startDate = undefined }) => (
  <div className="flex items-center mr-2">
    <div className="mr-2">starting on</div>
    <FormsyDateInput
      min={tomorrowDate()}
      required
      name="survey_schedule_attributes.start_date"
      value={startDate}
    />
  </div>
)

DailyRecurrenceDetails.propTypes = {
  startDate: types.string,
}
export default DailyRecurrenceDetails
