import React, { useEffect, useState } from "react"
import types from "prop-types"
import * as API from "services/api"
import { auditForATask, auditForFormSubmission, groupAudit } from "utils/auditHelper"
import { errorToast } from "shared/toast"
import FormSubmissionAuditTrailContext from "../FormSubmissionAuditTrailContext"

const FormSubmissionAuditTrailContextProvider = ({ children, formSubmissionSlug }) => {
  // For loading the form submission's audits
  const [auditList, setAuditList] = useState([])
  const [page, setPage] = useState(1)
  const [allAuditsLoaded, setAllAuditsLoaded] = useState(false)

  const fetchAudits = async () => {
    if (!formSubmissionSlug) return

    const response = await API.getFormSubmissionAudits({ formSubmissionSlug, page })
    if (response.ok) {
      if (response.data.length === 0) {
        setAllAuditsLoaded(true)
      } else {
        setPage(page + 1)
        setAuditList(auditList.concat(response.data))
      }
    } else {
      errorToast("Something went wrong. Unable to load the form submission's audit trail.")
    }
  }

  // If the form submission slug changes, we need to reset the state of the component,
  // fetching the first page of audits for the form submission.
  useEffect(() => {
    const initializeStateForFormSubmission = async () => {
      const auditsResponse = await API.getFormSubmissionAudits({ formSubmissionSlug, page: 1 })

      if (auditsResponse.ok) {
        setAllAuditsLoaded(auditsResponse.data.length === 0)
        setAuditList(auditsResponse.data)
        setPage(2)
      } else {
        errorToast("Something went wrong. Unable to load the form submission's audit trail.")
      }
    }

    initializeStateForFormSubmission()
  }, [formSubmissionSlug])

  // For filtering audits
  const [formSubmissionAuditsShown, setFormSubmissionAuditsShown] = useState(true)
  const showFormSubmissionAudits = () => setFormSubmissionAuditsShown(true)
  const hideFormSubmissionAudits = () => setFormSubmissionAuditsShown(false)

  const [hiddenTaskIds, setHiddenTaskIds] = useState([])
  const hideAuditsForTask = ({ taskId }) => setHiddenTaskIds([...hiddenTaskIds, taskId])
  const showAuditsForTask = ({ taskId }) => setHiddenTaskIds(hiddenTaskIds.filter((id) => id !== taskId))
  const auditsForTaskShown = ({ taskId }) => !hiddenTaskIds.includes(taskId)

  const filteredAuditList = auditList.filter((audit) => {
    if (auditForFormSubmission(audit)) {
      return formSubmissionAuditsShown
    }

    if (auditForATask(audit)) {
      return auditsForTaskShown({ taskId: audit.task.id })
    }

    return true
  })

  const contextConsumerValue = {
    allAuditsLoaded,
    auditsForTaskShown,
    auditGroups: groupAudit(filteredAuditList),
    fetchAudits,
    formSubmissionAuditsShown,
    hideAuditsForTask,
    hideFormSubmissionAudits,
    showAuditsForTask,
    showFormSubmissionAudits,
  }

  return (
    <FormSubmissionAuditTrailContext.Provider value={contextConsumerValue}>
      {children}
    </FormSubmissionAuditTrailContext.Provider>
  )
}

FormSubmissionAuditTrailContextProvider.propTypes = {
  children: types.node.isRequired,
  formSubmissionSlug: types.string.isRequired,
}

export default FormSubmissionAuditTrailContextProvider
