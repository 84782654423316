import React from "react"
import types from "prop-types"
import { CustomHeaderAccordion, useAccordion } from "shared/accordions"
import GroupingAccordionHeader from "shared/headers/GroupingAccordionHeader"
import FormList from "./FormList"

const FormGrouping = ({ groupName, forms }) => {
  const [isExpanded, toggleAccordion] = useAccordion(true)

  if (!forms.length) return null

  return (
    <CustomHeaderAccordion
      className="my-10"
      isExpanded={isExpanded}
      header={(
        <GroupingAccordionHeader
          numberOfSubmissions={forms.length}
          groupName={groupName}
          isExpanded={isExpanded}
          toggleAccordion={toggleAccordion}
          className="mb-6"
        />
      )}
      content={(
        <FormList forms={forms} />
      )}
    />
  )
}

FormGrouping.propTypes = {
  groupName: types.string.isRequired,
  forms: types.arrayOf(types.object).isRequired,
}

export default FormGrouping
