import React, { useEffect } from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { useDispatch, useSelector } from "react-redux"
import {
  addQuestionBranchingCondition,
  getFormQuestionBranchingConditionUnusedQuestionOptions,
  getFormQuestionCondition,
  getFormQuestionConditionals,
  removeQuestionBranchingConditionalsCondition,
  replaceQuestionBranchingConditionWithConditionals,
  updateQuestionBranchingConditionalsControl,
  updateQuestionBranchingConditionMatchValue,
  updateQuestionBranchingConditionOperator,
  updateQuestionBranchingConditionQuestionUuid,
} from "reduxSlices/formBuilderSlice"
import Conditions from "FormBuilder/QuestionBranching/Conditions"

const QuestionCondition = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const condition = useSelector(getFormQuestionCondition({ sectionIndex, questionIndex }))
  const questionConditionals = useSelector(getFormQuestionConditionals({ sectionIndex, questionIndex }), isEqual)
  const unusedQuestionOptions = useSelector(getFormQuestionBranchingConditionUnusedQuestionOptions({ sectionIndex, questionIndex }), isEqual)

  // When the form builder was orginially built, only one condition could be specified.
  // Later, we switched to supporting multiple conditions.  This useEffect
  // callback updates any form versions that still have the old condition
  // with the new conditions.
  useEffect(() => {
    if (condition) {
      dispatch(replaceQuestionBranchingConditionWithConditionals({ sectionIndex, questionIndex }))
    }
  }, [condition, dispatch])

  const addBranchingCondition = () => dispatch(addQuestionBranchingCondition({ sectionIndex, questionIndex }))

  const removeBranchingConditionals = (conditionIndex) => () => dispatch(removeQuestionBranchingConditionalsCondition({
    sectionIndex, questionIndex, conditionIndex,
  }))

  const updateBranchingControl = (newControl) => (
    dispatch(updateQuestionBranchingConditionalsControl({ sectionIndex, questionIndex, newControl }))
  )

  const updateBranchingConditionQuestionUuid = (conditionIndex) => (newQuestionUuid) => (
    dispatch(updateQuestionBranchingConditionQuestionUuid({
      sectionIndex, questionIndex, conditionIndex, newQuestionUuid,
    }))
  )

  const updateBranchingConditionOperator = (conditionIndex) => (newOperator) => (
    dispatch(updateQuestionBranchingConditionOperator({
      sectionIndex, questionIndex, conditionIndex, newOperator,
    }))
  )

  const updateBranchingConditionMatchValue = (conditionIndex) => (newMatchValue) => (
    dispatch(updateQuestionBranchingConditionMatchValue({
      sectionIndex, questionIndex, conditionIndex, newMatchValue,
    }))
  )

  return (
    <>
      <div className="font-medium mb-3">
        Show this question if...
      </div>
      <Conditions
        fieldPath={`sections[${sectionIndex}].questions[${questionIndex}].conditionals`}
        conditionals={questionConditionals}
        addBranchingConditionalsCondition={addBranchingCondition}
        removeBranchingConditionalsCondition={removeBranchingConditionals}
        unusedQuestionOptions={unusedQuestionOptions}
        updateBranchingConditionalsControl={updateBranchingControl}
        updateBranchingConditionalsConditionMatchValue={updateBranchingConditionMatchValue}
        updateBranchingConditionalsConditionOperator={updateBranchingConditionOperator}
        updateBranchingConditionalsConditionQuestionUuid={updateBranchingConditionQuestionUuid}
      />
    </>
  )
}

QuestionCondition.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default QuestionCondition
