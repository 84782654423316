import { useContext } from "react"
import SFTPSettingsContext from "../SFTPSettingsContext"

const useSFTPSettingsContext = () => {
  const providedValue = useContext(SFTPSettingsContext)

  return { ...providedValue }
}

export default useSFTPSettingsContext
