import React, { useMemo } from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import Rails from "@rails/ujs"
import { useSelector } from "react-redux"
import {
  includedFormSlugs,
  isMissingRequiredReportBuilderData,
  name,
  orderedSubmissionColumns,
  orderedTaskColumns,
  selectedCategorySlug,
  selectedFormSlug,
  selectedReportType,
  selectedTaskForms,
  shared,
  SOLUTION_REPORT_TYPE,
  SUBMISSION_REPORT_TYPE,
  SURVEY_REPORT_TYPE,
} from "reduxSlices/reportBuilderSlice"
import {
  ACTIVITY_HUB_FORM_SUBMISSION_REPORTS_PATH,
  ACTIVITY_HUB_SOLUTION_LEVEL_REPORTS_PATH,
  ACTIVITY_HUB_SURVEY_REPORTS_PATH,
  formSubmissionReportUpdatePath,
  solutionLevelReportUpdatePath,
  surveyReportUpdatePath,
  redirectTo,
} from "utils/routeHelpers"
import { HiddenInput } from "shared/inputs"
import { PrimaryButton, SecondaryButton } from "shared/buttons"

const cancel = () => {
  redirectTo(ACTIVITY_HUB_FORM_SUBMISSION_REPORTS_PATH)
}

const reportUpdatePath = (reportType, reportId) => {
  switch (reportType) {
  case SUBMISSION_REPORT_TYPE:
    return formSubmissionReportUpdatePath(reportId)
  case SOLUTION_REPORT_TYPE:
    return solutionLevelReportUpdatePath(reportId)
  case SURVEY_REPORT_TYPE:
    return surveyReportUpdatePath(reportId)
  default:
    return ""
  }
}

const reportCreatePath = (reportType) => {
  switch (reportType) {
  case SUBMISSION_REPORT_TYPE:
    return ACTIVITY_HUB_FORM_SUBMISSION_REPORTS_PATH
  case SOLUTION_REPORT_TYPE:
    return ACTIVITY_HUB_SOLUTION_LEVEL_REPORTS_PATH
  case SURVEY_REPORT_TYPE:
    return ACTIVITY_HUB_SURVEY_REPORTS_PATH
  default:
    return ""
  }
}

const ReportBuilderActions = ({ reportId = null }) => {
  const reportType = useSelector(selectedReportType)
  const reportName = useSelector(name)
  const sharedFlag = useSelector(shared)
  const categorySlug = useSelector(selectedCategorySlug)
  const formSlug = useSelector(selectedFormSlug)
  const solutionFormSlugs = useSelector(includedFormSlugs)
  const submissionColumns = useSelector(orderedSubmissionColumns, isEqual)
  const taskForms = useSelector(selectedTaskForms, isEqual)
  const taskColumns = useSelector(orderedTaskColumns, isEqual)

  const disabled = useSelector(isMissingRequiredReportBuilderData)

  const submitLocation = useMemo(() => (
    reportId
      ? reportUpdatePath(reportType, reportId)
      : reportCreatePath(reportType)
  ), [reportId, reportType])

  return (
    <div className="mt-6">
      <form action={submitLocation} method="post">
        <HiddenInput name="authenticity_token" value={Rails.csrfToken()} />
        {
          reportId && (
            <HiddenInput name="_method" value="put" />
          )
        }
        <HiddenInput name="report[name]" value={reportName} />
        <HiddenInput name="report[shared]" value={sharedFlag.toString()} />
        <HiddenInput name="report[report_setting_attributes][category_slug]" value={categorySlug} />
        {
          formSlug && <HiddenInput name="report[report_setting_attributes][form_slug]" value={formSlug} />
        }
        {
          reportType === SOLUTION_REPORT_TYPE && solutionFormSlugs.map((slug) => (
            <HiddenInput
              key={`form-slug-${slug}`}
              name="report[report_setting_attributes][included_form_slugs][]"
              value={slug}
            />
          ))
        }

        {
          submissionColumns.flatMap(({ type, id }) => (
            [
              <HiddenInput
                key={`submission-columns-type-${type}-${id}`}
                name="report[report_setting_attributes][submission_columns][][type]"
                value={type}
              />,
              <HiddenInput
                key={`submission-columns-key-${type}-${id}`}
                name="report[report_setting_attributes][submission_columns][][key]"
                value={id}
              />,
            ]
          ))
        }

        {
          taskForms.flatMap(({ id: slug, questions }) => (
            [
              <HiddenInput
                key={`task-form-slug-${slug}`}
                name="report[report_setting_attributes][task_forms_data][][slug]"
                value={slug}
              />,
            ].concat(
              questions.flatMap(({ type, id }) => (
                [
                  <HiddenInput
                    key={`task-question-columns-type-${type}-${id}`}
                    name="report[report_setting_attributes][task_forms_data][][question_columns][][type]"
                    value={type}
                  />,
                  <HiddenInput
                    key={`task-question-columns-key-${type}-${id}`}
                    name="report[report_setting_attributes][task_forms_data][][question_columns][][key]"
                    value={id}
                  />,
                ]
              )),
            )
          ))
        }

        {
          taskForms.length > 0
            && taskColumns.flatMap(({ type, id }) => (
              [
                <HiddenInput
                  key={`task-columns-type-${type}-${id}`}
                  name="report[report_setting_attributes][task_column_defaults][][type]"
                  value={type}
                />,
                <HiddenInput
                  key={`task-columns-key-${type}-${id}`}
                  name="report[report_setting_attributes][task_column_defaults][][key]"
                  value={id}
                />,
              ]
            ))
        }

        <div className="flex justify-center">
          <SecondaryButton
            className="mr-4"
            onClick={cancel}
            text="Cancel"
          />
          <PrimaryButton
            disabled={disabled}
            text="Save"
            type="submit"
          />
        </div>
      </form>
    </div>
  )
}

ReportBuilderActions.propTypes = {
  reportId: types.number,
}

export default ReportBuilderActions
