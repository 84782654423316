export const ADD_OPERATOR = "+"
export const SUBTRACT_OPERATOR = "-"
export const DIVIDE_OPERATOR = "/"

export const MAX_QUESTION_COUNT_FOR_NUMBER_CALCULATION_NON_ADDITION_OPERATIONS = 2

const calculateExpression = (value1, operator, value2) => {
  switch (operator) {
  case ADD_OPERATOR:
    return value1 + value2
  case SUBTRACT_OPERATOR:
    return value1 - value2
  case DIVIDE_OPERATOR:
    if (value2 === 0) return 0
    return value1 / value2
  default:
    return value1
  }
}

export const calculate = (numbers, operator) => (
  numbers.reduce((acc, number) => calculateExpression(acc, operator, number))
)
