import React from "react"
import types from "prop-types"
import { useSurveyReportExportContext } from "contexts/SurveyReportExportContext"
import { MultiSelect } from "shared/multiSelects"

// Facilities with no departments are not included in the options list.
// Filtering is done by department, not facility.  If a facility
// has no departments, then there are no departments by which to filter.
const facilitiesToOptions = (facilities, facilityDepartments) => facilities.reduce((options, facility) => {
  if (facilityDepartments(facility.id).length === 0) return options

  options.push({
    label: facility.name,
    value: facility.id,
  })

  return options
}, [])

const LocationAnswerFiltersLocationDetailsFacilitySelect = ({ className = "", onChange, values }) => {
  const { facilities, facilityDepartments } = useSurveyReportExportContext()

  return (
    <label>
      <span>Facilities:</span>
      <MultiSelect
        className={className}
        onChange={onChange}
        options={facilitiesToOptions(facilities, facilityDepartments)}
        values={values}
      />
    </label>
  )
}

LocationAnswerFiltersLocationDetailsFacilitySelect.propTypes = {
  className: types.string,
  onChange: types.func.isRequired,
  values: types.arrayOf(types.number).isRequired,
}

export default LocationAnswerFiltersLocationDetailsFacilitySelect
