import React, { useEffect } from "react"
import types from "prop-types"
import { useSelector } from "react-redux";
import { groups as getGroups } from "reduxSlices/formSubmissionSlice"
import { useTaskFormContext } from "contexts/TaskFormContext"
/* eslint-disable max-len */
import TaskGroupSelector from "views/FormSubmission/FormSubmissionTabs/ActivitiesContent/TaskForms/TaskFields/UI/TaskGroupSelector"
/* eslint-enable max-len */

const TaskAssigneeGroupSelector = ({ className }) => {
  const groups = useSelector(getGroups)

  const { addAssigneeGroupId, taskAssigneeGroupIds, taskMemberGroupIds } = useTaskFormContext()

  const selectableGroups = groups.reduce((groupList, group) => {
    if (!taskAssigneeGroupIds.includes(group.id)) {
      groupList.push(
        {
          ...group,
          ...(taskMemberGroupIds.includes(group.id) && { marker: "Member" }),
        },
      )
    }
    return groupList
  }, [])

  useEffect(() => {
    window.dispatchEvent(new window.Event("resize"))
  }, [taskAssigneeGroupIds.length])

  return (
    <TaskGroupSelector
      groups={selectableGroups}
      onChange={addAssigneeGroupId}
      className={className}
    />
  )
}

TaskAssigneeGroupSelector.defaultProps = {
  className: "",
}

TaskAssigneeGroupSelector.propTypes = {
  className: types.string,
}

export default TaskAssigneeGroupSelector
