import React from "react"
import { useSolutionLevelScheduledReportExportContext } from "contexts/SolutionLevelScheduledReportExportContext"
import { LOCATION_REPORTABLE_FIELD_TYPE } from "utils/reportableFieldHelpers"
import { ReportableFieldSelect } from "shared/selects"

const ReportableFieldLocationAnswerFiltersReportableFieldSelect = () => {
  const {
    reportableFieldLocationAnswerFilters,
    reportableFields,
    setReportableFieldLocationAnswerFilterReportableFieldId,
  } = useSolutionLevelScheduledReportExportContext()

  const locationReportableFields = reportableFields.filter(
    (reportableField) => (
      reportableField.questionType === LOCATION_REPORTABLE_FIELD_TYPE
    ),
  )

  if (locationReportableFields.length === 0) {
    return (
      <p>There are no location reportable fields in the solution associated with this report.</p>
    )
  }

  const reportableFieldLocationAnswerFilter = reportableFieldLocationAnswerFilters[0] ?? {}
  const { reportableFieldId } = reportableFieldLocationAnswerFilter

  return (
    <ReportableFieldSelect
      className="!w-full bordered-base-input mb-4"
      clearable
      onChange={setReportableFieldLocationAnswerFilterReportableFieldId}
      reportableFields={locationReportableFields}
      value={reportableFieldId}
    />
  )
}

export default ReportableFieldLocationAnswerFiltersReportableFieldSelect
