/* eslint-disable no-nested-ternary */
import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { isEqual } from "lodash-es"
import { Draggable } from "react-beautiful-dnd"
import { useDispatch, useSelector } from "react-redux"
import {
  getFormQuestionOption,
  getFormQuestionOptionCount,
  getFormQuestionOptionLocked,
  updateFormQuestionOption,
} from "reduxSlices/formBuilderSlice"
import Tooltip from "shared/Tooltip"
import FormsyValidation from "shared/FormsyValidation"
import { TextInput } from "shared/inputs"
import { LockIcon } from "shared/icons"
import OptionDeleteButton from "./OptionDeleteButton"
import OptionDragHandle from "./OptionDragHandle"

const Option = ({
  draggableId,
  isNewOptionPlaceholder,
  optionIndex,
  questionIndex,
  sectionIndex,
}) => {
  const dispatch = useDispatch()

  const option = useSelector(getFormQuestionOption({ sectionIndex, questionIndex, optionIndex }))
  const optionCount = useSelector(getFormQuestionOptionCount({ sectionIndex, questionIndex, optionIndex }))
  const [optionLocked, optionUsageDescriptions] = useSelector(getFormQuestionOptionLocked({
    sectionIndex, questionIndex, optionIndex,
  }), isEqual)

  const updateOption = (newOption) => dispatch(updateFormQuestionOption({
    sectionIndex, questionIndex, optionIndex, newOption,
  }))

  return (
    <Draggable key={draggableId} draggableId={draggableId} index={optionIndex}>
      {
        (dragProvided) => (
          <div
            className="flex items-center space-between relative w-3/5"
            ref={dragProvided.innerRef}
            {...dragProvided.draggableProps}
          >
            <OptionDragHandle
              isHidden={isNewOptionPlaceholder}
              dragProvided={dragProvided}
            />
            <div className="flex-grow">
              <TextInput
                placeholder="Option"
                className={clsx("px-0", optionLocked && "pointer-events-none")}
                onChange={optionLocked ? () => {} : updateOption}
                value={option}
                tabIndex="0"
              />
              {
                !isNewOptionPlaceholder && (
                  <FormsyValidation
                    name={`sections[${sectionIndex}].questions[${questionIndex}].options[${optionIndex}]`}
                    value={option}
                    validations={{
                      isNotBlankString: true,
                      isUniqueFormQuestionOption: { count: optionCount },
                      noExcelMetacharacters: true,
                      noHTML: true,
                    }}
                    validationErrors={{ isDefaultRequiredValue: "required" }}
                  />
                )
              }
            </div>
            {
              isNewOptionPlaceholder
                ? null
                : (
                  optionLocked
                    ? (
                      <Tooltip text={`Cannot edit option: ${optionUsageDescriptions.join(", ")}.`}>
                        <LockIcon height="20" width="20" />
                      </Tooltip>
                    )
                    : (
                      <OptionDeleteButton
                        sectionIndex={sectionIndex}
                        questionIndex={questionIndex}
                        optionIndex={optionIndex}
                      />
                    )
                )
            }
          </div>
        )
      }
    </Draggable>
  )
}

Option.propTypes = {
  draggableId: types.string.isRequired,
  isNewOptionPlaceholder: types.bool.isRequired,
  optionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
  sectionIndex: types.number.isRequired,
}

export default Option
