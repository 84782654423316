import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { questionShape } from "utils/propTypeShapes"
import { FormsyMultilineTextInput } from "shared/inputs"

const LongAnswerField = ({
  className,
  edit,
  initialValue,
  question,
  ...rest
}) => {
  const { required, uuid } = question

  const validations = {
    noExcelMetacharacters: true,
    noHTML: true,
  }

  if (required && !edit) {
    validations.isNotBlankString = true
  }

  return (
    <FormsyMultilineTextInput
      className={clsx("form-input", className)}
      name={uuid}
      validations={validations}
      value={initialValue || ""}
      {...rest}
    />
  )
}

LongAnswerField.defaultProps = {
  edit: false,
  initialValue: null,
  disabled: false,
  className: "",
  placeholder: "",
  hasBaseInputStyle: false,
}

LongAnswerField.propTypes = {
  edit: types.bool,
  question: questionShape.isRequired,
  initialValue: types.string,
  disabled: types.bool,
  className: types.string,
  placeholder: types.string,
  hasBaseInputStyle: types.bool,
}

export default LongAnswerField
