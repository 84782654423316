import React, { useState } from "react"
import NameFilter from "shared/NameFilter"
import UserSourceList from "./UserSourceList"

const SearchableMembers = () => {
  const [nameFilter, setNameFilter] = useState("")

  return (
    <>
      <NameFilter
        className="mb-6"
        onChange={setNameFilter}
        value={nameFilter}
      />
      <UserSourceList className="mb-4 overflow-y-auto no-scroll-bar" nameFilter={nameFilter} />
      <p className="text-xs text-dark">
        Instructions: Drag users from the list into their departments.
        Remove users by dragging their icons to the trash can.
      </p>
    </>
  )
}

export default SearchableMembers
