import React from "react"
import * as API from "services/api"
import { useSurveyConfigurationContext } from "contexts/SurveyConfigurationContext"
import { errorToast } from "components/shared/toast"
import Switch from "shared/Switch"

const ActiveToggle = () => {
  const { surveyConfiguration, updateSurveyConfigurationActive } = useSurveyConfigurationContext()
  const { id, active } = surveyConfiguration

  const activate = async () => {
    const response = await API.activateSurveyConfiguration(id)

    if (response.ok) {
      updateSurveyConfigurationActive(true)
    } else {
      errorToast("Something went wrong. Unable to activate configuration.")
    }
  }

  const deactivate = async () => {
    const response = await API.deactivateSurveyConfiguration(id)

    if (response.ok) {
      updateSurveyConfigurationActive(false)
    } else {
      errorToast("Something went wrong. Unable to deactivate configuration.")
    }
  }

  return (
    <Switch
      checked={active}
      onChange={active ? deactivate : activate}
    />
  )
}

export default ActiveToggle
