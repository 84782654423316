import React from "react"
import types from "prop-types"
import { UserRoleSelect } from "shared/selects"
import ContentBar from "shared/lists/ContentBar"
import * as API from "services/api"

const UserRole = ({ user, updateRole }) => {
  const updateUserRole = async (role) => {
    const response = await API.updateUserRole({ userId: user.id, role })
    if (response.ok) {
      updateRole(role)
    } else {
      console.error("Error updating user role: ", response);
    }
  }

  return (
    <ContentBar
      description="Is this user an admin or not?"
      title="Role"
    >
      <UserRoleSelect
        placeholder="Role"
        value={user.role}
        onChange={updateUserRole}
      />
    </ContentBar>
  )
}

UserRole.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: types.object.isRequired,
  updateRole: types.func.isRequired,
}

export default UserRole
