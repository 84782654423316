import { useContext } from "react"
import TaskAnswerEditorsContext from "../TaskAnswerEditorsContext"

const useTaskAnswerEditorsContext = () => {
  const providedValue = useContext(TaskAnswerEditorsContext)

  return { ...providedValue }
}

export default useTaskAnswerEditorsContext
