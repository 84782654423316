import React from "react";
import types from "prop-types"
import { withFormsy } from "formsy-react"
import { Select } from "shared/selects"

const optionsFromList = (options) => options.map((opt) => ({ label: opt.title, value: opt.id }))

const FormSelect = ({
  name = "",
  className = "",
  value = undefined,
  options = [],
  onChange,
  ...rest
}) => (
  <Select
    className={className}
    name={name}
    options={optionsFromList(options)}
    value={value}
    onChange={onChange}
    {...rest}
  />
)

FormSelect.propTypes = {
  name: types.string,
  onChange: types.func.isRequired,
  options: types.arrayOf(types.object),
  value: types.number,
  className: types.string,
}

export const FormsyFormSelect = withFormsy(({ setValue, ...rest }) => (
  <FormSelect onChange={setValue} {...rest} />
))

FormsyFormSelect.displayName = "FormsyFormSelect"

export default FormSelect
