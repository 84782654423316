import React, { useState } from "react"
import Formsy from "formsy-react"
import types from "prop-types"
import { categoryShape } from "utils/propTypeShapes"
import { PrimaryButton } from "shared/buttons"
import { successToast, errorToast } from "shared/toast"
import * as API from "services/api"
import CategoryActions from "./CategoryActions"

const ActionsTab = ({ category, accessLevelOptions, setCategory }) => {
  const [canSubmit, setCanSubmit] = useState(false)
  const enableSubmit = () => setCanSubmit(true)
  const disableSubmit = () => setCanSubmit(false)

  const updateCategory = async (model) => {
    const response = await API.updateCategoryAccessLevelRequirements({
      categorySlug: category.slug,
      category: model,
    })
    if (response.ok) {
      setCategory(response.data)
      successToast("Solution updated successfully!")
    } else {
      console.error("Error updating category: ", response)
      errorToast("Something went wrong. Unable to update solution.", response)
    }
  }

  return (
    <Formsy
      onSubmit={updateCategory}
      onValid={enableSubmit}
      onInvalid={disableSubmit}
      preventDefaultSubmit
    >
      <div className="tab-title">Actions</div>
      <CategoryActions
        category={category}
        accessLevelOptions={accessLevelOptions}
      />
      <PrimaryButton
        className="absolute-horizontal-center bottom-4"
        text="Save Changes"
        type="submit"
        disabled={!canSubmit}
      />
    </Formsy>
  )
}

ActionsTab.propTypes = {
  category: categoryShape.isRequired,
  accessLevelOptions: types.arrayOf(types.string).isRequired,
  setCategory: types.func.isRequired,
}

export default ActionsTab
