import React from "react"
import { SideTabs } from "shared/tabs"
import SubmissionsContent from "./SubmissionsContent"
import SettingsContent from "./SettingsContent"

const SUBMISSIONS_TAB = {
  title: "Submissions",
  TabContent: SubmissionsContent,
}

const SETTINGS_TAB = {
  title: "Settings",
  TabContent: SettingsContent,
}

const DEFAULT_SURVEY_BATCH_TABS = [
  SUBMISSIONS_TAB,
  SETTINGS_TAB,
]

const SurveyBatchTabs = () => (
  <SideTabs tabs={DEFAULT_SURVEY_BATCH_TABS} />
)

export default SurveyBatchTabs
