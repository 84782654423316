import React from "react"
import types from "prop-types"
import AttachmentGrouping from "./AttachmentGrouping"

const AttachmentGroupingList = ({ attachmentGroups, className = "" }) => {
  if (!Object.keys(attachmentGroups).length) {
    return <div>No Attachments</div>
  }

  return (
    <div className={className}>
      {
        Object.entries(attachmentGroups).map(([groupName, attachments]) => (
          <AttachmentGrouping
            key={groupName}
            groupName={groupName}
            attachments={attachments}
          />
        ))
      }
    </div>
  )
}

AttachmentGroupingList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  attachmentGroups: types.object.isRequired,
  className: types.string,
}

export default AttachmentGroupingList
