import React, { useCallback, useState } from "react"
import { PrimaryButton, TertiaryButton } from "shared/buttons"
import { useModal } from "hooks"
import { useSelector } from "react-redux"
import { subscribedToAddOnFeature } from "reduxSlices/addOnFeatureSubscriptionsSlice"
import { SURVEY_FEATURE_NAME } from "utils/addOnFeatureHelpers"
import {
  redirectTo,
  ACTIVITY_HUB_NEW_FORM_SUBMISSION_REPORTS_PATH,
  ACTIVITY_HUB_NEW_SOLUTION_LEVEL_REPORT_PATH,
  ACTIVITY_HUB_NEW_SURVEY_REPORT_PATH,
} from "utils/routeHelpers"
import { TextInput } from "shared/inputs"
import { Select } from "shared/selects"
import Modal, { ModalHeader } from "shared/Modal"
import withReduxProvider from "shared/withReduxProvider"

const EMPTY_NAME = ""
const FORM_LEVEL = "Form level"
const SOLUTION_LEVEL = "Solution level"
const SURVEY_LEVEL = "Survey level"

const FORM_LEVEL_DESCRIPTION = "Report will contain form submissions from one form."
                               + " Data includes submission answers and task form answers."

const SOLUTION_LEVEL_DESCRIPTION = "Report will contain form submissions from one solution."
                                   + " Data includes reportable fields and task form answers."

const SURVEY_LEVEL_DESCRIPTION = "Report will contain submissions from one survey form."
                     + " Data includes submission answers, task form answers, and batch details."

const REPORT_TYPE_PATHS = {
  [FORM_LEVEL]: ACTIVITY_HUB_NEW_FORM_SUBMISSION_REPORTS_PATH,
  [SOLUTION_LEVEL]: ACTIVITY_HUB_NEW_SOLUTION_LEVEL_REPORT_PATH,
  [SURVEY_LEVEL]: ACTIVITY_HUB_NEW_SURVEY_REPORT_PATH,
}

const REPORT_TYPE_DESCRIPTIONS = {
  [FORM_LEVEL]: FORM_LEVEL_DESCRIPTION,
  [SOLUTION_LEVEL]: SOLUTION_LEVEL_DESCRIPTION,
  [SURVEY_LEVEL]: SURVEY_LEVEL_DESCRIPTION,
}

const NewReportModal = () => {
  const { modalIsOpen, openModal, closeModal } = useModal()
  const [name, setName] = useState(EMPTY_NAME)
  const [reportType, setReportType] = useState(FORM_LEVEL)

  const surveyFeatureEnabled = useSelector(subscribedToAddOnFeature(SURVEY_FEATURE_NAME))

  const reportTypeOptions = [
    { label: FORM_LEVEL, value: FORM_LEVEL },
    { label: SOLUTION_LEVEL, value: SOLUTION_LEVEL },
    ...(surveyFeatureEnabled ? [{ label: SURVEY_LEVEL, value: SURVEY_LEVEL }] : []),
  ]

  const close = useCallback(() => {
    closeModal()
    setName(EMPTY_NAME)
  }, [closeModal, setName])

  const goToNewFormSubmissionReport = () => {
    const path = REPORT_TYPE_PATHS[reportType] || ACTIVITY_HUB_NEW_SOLUTION_LEVEL_REPORT_PATH

    redirectTo(path, { name })
  }

  return (
    <>
      <PrimaryButton text="Add Report +" onClick={openModal} />
      <Modal isOpen={modalIsOpen}>
        <ModalHeader
          closeModal={close}
          heading="New Form Submission Report"
        />
        <div className="w-3/4 mx-auto">
          <TextInput
            className="bordered-base-input !text-sm mb-4"
            onChange={setName}
            placeholder="Name this report *"
            value={name}
          />
          <Select
            className="bordered-base-input text-sm mb-4"
            backspaceDelete={false}
            name="new-report-report-type-select"
            isFullWidth
            onChange={setReportType}
            options={reportTypeOptions}
            value={reportType}
          />
          <p className="text-xs text-dark">
            { REPORT_TYPE_DESCRIPTIONS[reportType] || SOLUTION_LEVEL_DESCRIPTION }
          </p>
        </div>
        <div className="flex justify-center mt-8 mb-10">
          <TertiaryButton
            text="Cancel"
            onClick={close}
            className="mr-2"
          />
          <PrimaryButton
            text="Submit"
            type="submit"
            disabled={!name}
            onClick={goToNewFormSubmissionReport}
          />
        </div>
      </Modal>
    </>
  )
}

export default withReduxProvider(NewReportModal)
