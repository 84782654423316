import { tableDataElementTypeForQuestionType } from "shared/Table/TableDataElement/tableDataType"

export const createReportableFieldColumns = (reportableFields) => reportableFields.map(({
  name,
  id,
  questionType,
}) => ({
  label: name,
  value: `reportableField-${id}`,
  isReportable: true,
  dataType: tableDataElementTypeForQuestionType(questionType),
}))

export const setReportableAnswersOnSubmissions = (formSubmissions) => formSubmissions.map((sub) => {
  if (sub?.reportableQuestions.length) {
    sub.reportableQuestions.forEach(({ reportableFieldId, uuid }) => {
      sub[`reportableField-${reportableFieldId}`] = sub.answers[uuid]
    })
  }
  return sub
})

export const removeReportableColumns = (columns) => columns.filter(({ isReportable }) => !isReportable)
