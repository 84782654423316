import React from "react"
import types from "prop-types"
import FormSubmissionResult from "./FormSubmissionResult"
import TaskResult from "./TaskResult"

const Result = ({ result }) => {
  let ResultItem = TaskResult
  if (result.type === "formSubmission") {
    ResultItem = FormSubmissionResult
  }

  return (
    <ResultItem result={result} />
  )
}

Result.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  result: types.object.isRequired,
}

export default Result
