import {
  DATE_TYPE, LOCATION_TYPE, MULTI_SELECT_TYPE, NOTE_ANSWER_TYPE,
} from "views/Forms/FormElementTypes"
import Answer from "./Answer"
import DateAnswer from "./DateAnswer"
import LocationAnswer from "./LocationAnswer"
import MultiSelectAnswer from "./MultiSelectAnswer"
import NoteAnswer from "./NoteAnswer/NoteAnswer"

const answerComponents = {
  [DATE_TYPE]: DateAnswer,
  [LOCATION_TYPE]: LocationAnswer,
  [MULTI_SELECT_TYPE]: MultiSelectAnswer,
  [NOTE_ANSWER_TYPE]: NoteAnswer,
}

export const answerComponent = (questionType) => answerComponents[questionType] || Answer
