import React from "react"
import ContentBar from "shared/lists/ContentBar"
import { useSelector } from "react-redux"
import { getCategoryName } from "reduxSlices/formBuilderSlice"

const TITLE = "Form Solution"
const description = (categoryName) => (
  `This form is published in the ${categoryName} solution. A form's solution is chosen when the form is created and cannot be changed.`
)

const FormSolutionSetting = () => {
  const name = useSelector(getCategoryName)

  return (
    <ContentBar
      title={TITLE}
      description={description(name)}
    >
      <div className="pl-6 whitespace-nowrap select-none">
        <p className="rounded bg-white p-2 border border-lightgray-3">
          {name}
        </p>
      </div>
    </ContentBar>
  )
}

export default FormSolutionSetting
