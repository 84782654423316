import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const TableHeadSortIcon = ({ column, currentSort }) => {
  const {
    isAsc,
    column: currentSortColumn,
  } = currentSort

  // Placeholder to prevent column from resizing horizontally
  if (currentSortColumn.value !== column.value) {
    return <span />
  }

  if (isAsc) {
    return <SvgSpriteIcon className="inline" iconName="sort-asc" />
  }

  return <SvgSpriteIcon className="inline" iconName="sort-desc" />
}

TableHeadSortIcon.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  column: types.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  currentSort: types.object.isRequired,
}

export default TableHeadSortIcon
