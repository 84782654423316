import React from "react"
import types from "prop-types"
import { EllipsisIcon } from "shared/icons"
import ExpandMenu, { ExpandMenuItem } from "shared/ExpandMenu"
import * as API from "services/api"
import { errorToast, successToast } from "shared/toast"
import Tooltip from "shared/Tooltip"

const goToGroupEdit = (group) => () => {
  window.location.pathname = `/admin/groups/${group.id}`
}

const GroupMenu = ({ group, removeGroup }) => {
  const deleteGroup = async () => {
    const response = await API.deleteGroup({ groupId: group.id })

    if (response.ok) {
      removeGroup(group.id)
      successToast("Group deleted successfully!")
    } else {
      console.error("Error deleting group: ", response)
      errorToast("Something went wrong. Unable to delete group.", response)
    }
  }

  const deletable = !group.hasWorkflows
  const message = deletable ? "" : "Groups used in workflows cannot be deleted."

  return (
    <ExpandMenu expandMenuComponent={(
      <div className="absolute-vertical-center -right-10 bg-light-100 rounded py-2.5 px-0.5">
        <EllipsisIcon className="h-1 w-5" />
      </div>
    )}
    >
      <ExpandMenuItem onClick={goToGroupEdit(group)}>
        Edit
      </ExpandMenuItem>
      <ExpandMenuItem
        disabled={!deletable}
        onClick={deleteGroup}
      >
        <Tooltip text={message}>
          Delete
        </Tooltip>
      </ExpandMenuItem>
    </ExpandMenu>
  )
}

GroupMenu.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  group: types.object.isRequired,
  removeGroup: types.func.isRequired,
}

export default GroupMenu
