import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { LargeWrap } from "shared/wraps"
import { useSelector } from "react-redux"
import { getGroupedTasks } from "reduxSlices/taskTableSlice"
import TaskGroupingList from "./TaskGroupingList"
import TaskHeader from "./TaskHeader"

const TaskContent = ({ hiddenSidebar, toggleSidebar }) => {
  const taskGroups = useSelector(getGroupedTasks)

  return (
    <LargeWrap className={clsx(!hiddenSidebar && "pl-80")}>
      <TaskHeader hiddenSidebar={hiddenSidebar} toggleSidebar={toggleSidebar} />
      <TaskGroupingList taskGroups={taskGroups} />
    </LargeWrap>
  )
}

TaskContent.propTypes = {
  hiddenSidebar: types.bool.isRequired,
  toggleSidebar: types.func.isRequired,
}

export default TaskContent
