import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const TwoPersonGroupIcon = ({ className = "w-8 h-8", ...rest }) => (
  <SvgSpriteIcon iconName="two-person-group" className={className} {...rest} />
)

TwoPersonGroupIcon.propTypes = {
  className: types.string,
}

export default TwoPersonGroupIcon
