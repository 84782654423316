import React from "react"
import types from "prop-types"
import { dynamicRangeReportableFieldsExample, monthRangeReportableFieldInclusionDescription } from "utils/scheduledReportExportHelpers"

const MonthsReportableFieldDescription = ({ startDate, reportableFieldAnswerPreviousRangeLength = null }) => {
  const monthCount = reportableFieldAnswerPreviousRangeLength || 1

  const beginningDate = startDate.subtract(monthCount, "M").startOf("month")
  const endingMonth = startDate.subtract(1, "M")
  const endingDate = endingMonth.endOf("month")

  return (
    <div className="text-xs text-dark mt-2">
      <p className="mb-4">
        {monthRangeReportableFieldInclusionDescription}
      </p>
      <p>
        {dynamicRangeReportableFieldsExample({ startDate, beginningDate, endingDate })}
      </p>
    </div>
  )
}

MonthsReportableFieldDescription.propTypes = {
  startDate: types.object.isRequired,
  reportableFieldAnswerPreviousRangeLength: types.number,
}

export default MonthsReportableFieldDescription
