import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { selectedReportType, SOLUTION_REPORT_TYPE, SURVEY_REPORT_TYPE } from "reduxSlices/reportBuilderSlice"
import SubmissionRelatedColumnOptions from "./SubmissionRelatedColumnOptions"
import SubmissionRelatedColumnSelections from "./SubmissionRelatedColumnSelections"
import SelectorSection from "../SelectorSection"

// Dynamic list of option types user could choose from for a given report type
const buildHeaderForReport = (reportType) => {
  const submissionDescription = "form submission column defaults"
  const surveyDescription = (reportType === SURVEY_REPORT_TYPE) ? ", survey column defaults," : ""
  const answerDescription = (reportType !== SOLUTION_REPORT_TYPE) ? "form questions" : "reportable fields"

  return (
    <p>{ `Which ${submissionDescription}${surveyDescription} and ${answerDescription} would you like the report to include?` }</p>
  )
}

// Dynamic additional notes dictated by report type
const buildNotesForReport = (reportType) => (
  <>
    { (reportType === SURVEY_REPORT_TYPE) && (
      <p className="text-xs text-dark">* Will only be populated for survey submissions that are not anonymous.</p>
    ) }
    <p className="text-xs text-dark">Your selections can be custom ordered by dragging and dropping</p>
  </>
)

const buildSelectorInstructions = (reportType) => (
  <div className="space-y-2">
    { buildHeaderForReport(reportType) }
    { buildNotesForReport(reportType) }
  </div>
)

const SubmissionRelatedColumnSelector = () => {
  const reportType = useSelector(selectedReportType)

  const selectorInstructions = useMemo(() => buildSelectorInstructions(reportType), [reportType])

  return (
    <SelectorSection
      instructionComponent={selectorInstructions}
      optionsComponent={<SubmissionRelatedColumnOptions />}
      selectionsComponent={<SubmissionRelatedColumnSelections />}
      largeDisplay
    />
  )
}

export default SubmissionRelatedColumnSelector
