import React from "react"
import types from "prop-types"
import { RadioButton } from "shared/inputs"
import { EXPORT_FILE_TYPE_OPTIONS } from "utils/reportExportHelpers"

const FileTypeSelect = ({ selectedFileType, onChange }) => (
  <>
    {
      EXPORT_FILE_TYPE_OPTIONS.map(({ type, note = "" }) => (
        <RadioButton
          key={type}
          checked={selectedFileType === type}
          className="mb-4 last:mb-0"
          label={`${type.toUpperCase()} ${note}`}
          name="report-export-file-type"
          onChange={onChange}
          value={type}
        />
      ))
    }
  </>
)

FileTypeSelect.propTypes = {
  selectedFileType: types.string.isRequired,
  onChange: types.func.isRequired,
}

export default FileTypeSelect
