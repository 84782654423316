import React from "react"
import { auditItemDetailsComponent } from "utils/auditHelper"
import { orgLocalDateTime } from "utils/dateHelpers"
import { auditShape } from "utils/propTypeShapes"
import { ProfileIcon } from "shared/icons"
import ItemRow from "shared/ItemRow"

const AuditItem = ({ audit }) => {
  const AuditItemDetailsComponent = auditItemDetailsComponent(audit)

  return (
    <>
      <ItemRow
        itemRowHeaderContentClassName="item-row-header-content-audit-trail"
        itemRowHeaderContent={(
          <>
            <div>
              <ProfileIcon className="hidden lg:flex w-8 h-8 mr-3" user={audit.user} />
              <AuditItemDetailsComponent audit={audit} />
            </div>
            <div className="text-dark text-xs shrink-0 lg:justify-self-end">
              { orgLocalDateTime(audit.createdAt) }
            </div>
          </>
        )}
      />
    </>
  )
}

AuditItem.propTypes = {
  audit: auditShape.isRequired,
}

export default AuditItem
