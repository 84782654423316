import React from "react"
import types from "prop-types"
import { getInitialActiveIndexFromQueryParams } from "utils/tabHelper"
import SideTabsContainer from "./SideTabsContainer"

const isActive = (index, activeIndex) => (index === activeIndex)

const SideTabContents = ({ activeIndex, tabs }) => (
  tabs.map(({ title, TabContent, props = {} }, index) => {
    if (!isActive(index, activeIndex)) return null

    return (
      <div key={title}>
        <TabContent {...props} />
      </div>
    )
  })
)

const SideTabs = ({ className = "", tabs }) => (
  <SideTabsContainer
    className={className}
    tabs={tabs}
    initialActiveIndex={getInitialActiveIndexFromQueryParams(tabs)}
    SideTabContents={SideTabContents}
  />
)

SideTabs.propTypes = {
  className: types.string,
  tabs: types.arrayOf(types.object).isRequired,
}

export default SideTabs
