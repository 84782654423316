/* eslint-disable camelcase */
import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { FormIcon } from "shared/icons"
import { monthDate } from "utils/dateHelpers"
import rawHtmlToReact from "services/rawHtmlToReact"
import { capitalize } from "utils/stringHelpers"
import { activityHubSubmissionPath, redirectTo } from "utils/routeHelpers"

const redirectToSubmission = (result) => redirectTo(activityHubSubmissionPath(result.slug))

const FormSubmissionResult = ({ onClickResult = redirectToSubmission, result }) => {
  const {
    form, slug, es_highlight, createdAt,
  } = result
  // eslint-disable-next-line prefer-const
  let [[field, highlight]] = Object.entries(es_highlight)

  if (field.toLocaleLowerCase() === "slug") {
    field = "ID"
  }

  return (
    <div
      className={clsx(
        "border border-light-300",
        "rounded-lg p-3 mb-2 last:mb-0 text-sm cursor-pointer",
        "flex justify-between space-x-3",
      )}
      onClickCapture={() => onClickResult(result)}
    >
      <FormIcon className="search-result-icon" form={form} />
      <div className="flex-grow">
        <div className="font-semibold text-normal">
          { `${capitalize(field)}` }
        </div>
        <div className="mt-1">
          { rawHtmlToReact(highlight) }
        </div>
        <div className="font-semibold tracking-tight text-dark">
          { form.title }
          <span className="text-dark text-lg opacity-60 align-middle">
            { " > " }
          </span>
          { slug }
        </div>
      </div>
      <div className="text-dark font-medium">
        { monthDate(createdAt) }
      </div>
    </div>
  )
}

FormSubmissionResult.propTypes = {
  onClickResult: types.func,
  // eslint-disable-next-line react/forbid-prop-types
  result: types.object.isRequired,
}

export default FormSubmissionResult
