import React from "react"
import { useFormSubmissionReportExportContext } from "contexts/FormSubmissionReportExportContext"
import FileTypeSelect from "../ReportExport/FileTypeSelect"

const NewFormSubmissionReportExportFileTypeSelect = () => {
  const {
    reportExportFileType,
    setReportExportFileType,
  } = useFormSubmissionReportExportContext()

  return (
    <FileTypeSelect
      selectedFileType={reportExportFileType}
      onChange={setReportExportFileType}
    />
  )
}

export default NewFormSubmissionReportExportFileTypeSelect
