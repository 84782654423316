import React from "react"
import { departmentShape } from "utils/propTypeShapes"
import { useFormSubmissionScheduledReportExportContext } from "contexts/FormSubmissionScheduledReportExportContext"
import { Checkbox } from "components/shared/checkboxes"

const LocationAnswerFiltersLocationDetailsSelectedFacilityDepartmentCheckbox = ({ department }) => {
  const {
    addDepartmentIdsToLocationAnswerFilters,
    locationAnswerFilters,
    removeDepartmentIdsFromLocationAnswerFilters,
  } = useFormSubmissionScheduledReportExportContext()

  const { departmentIds: selectedDepartmentIds } = locationAnswerFilters[0]

  const selected = selectedDepartmentIds.includes(department.id)

  const toggleDepartment = selected
    ? () => removeDepartmentIdsFromLocationAnswerFilters([department.id])
    : () => addDepartmentIdsToLocationAnswerFilters([department.id])

  return (
    <div className="flex items-center">
      <Checkbox
        className="mr-2"
        onChange={toggleDepartment}
        uuid={`department-checkbox-${department.id}`}
        value={selected}
      />
      <label className="hover:cursor-pointer" htmlFor={`department-checkbox-${department.id}`}>
        {department?.name}
      </label>
    </div>
  )
}

LocationAnswerFiltersLocationDetailsSelectedFacilityDepartmentCheckbox.propTypes = {
  department: departmentShape.isRequired,
}

export default LocationAnswerFiltersLocationDetailsSelectedFacilityDepartmentCheckbox
