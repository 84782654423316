import React from "react"
import types from "prop-types"
import { SideTabs } from "shared/tabs"
import GeneralTab from "./GeneralTab"
import AccessOverviewTab from "./AccessOverviewTab"
import AttributesTab from "./AttributesTab"
import HistoricalAccessToolTab from "./HistoricalAccessToolTab"
import SignInHistoryTab from "./SignInHistoryTab"
import LocationTab from "./LocationTab"
import GroupsTab from "./GroupsTab"
import UserWorkflowsTab from "./UserWorkflowsTab"

const TABS = (tabProps) => [
  {
    title: "General",
    TabContent: GeneralTab,
    props: tabProps,
  },
  {
    title: "Attributes",
    TabContent: AttributesTab,
    props: tabProps,
  },
  {
    title: "Access Overview",
    TabContent: AccessOverviewTab,
    props: tabProps,
  },
  {
    title: "Historical Access Tool",
    TabContent: HistoricalAccessToolTab,
    props: tabProps,

  },
  {
    title: "Sign-in History",
    TabContent: SignInHistoryTab,
    props: tabProps,
  },
  {
    title: "Departments",
    TabContent: LocationTab,
    props: tabProps,
  },
  {
    title: "Groups",
    TabContent: GroupsTab,
    props: tabProps,
  },
  {
    title: "Workflows",
    TabContent: UserWorkflowsTab,
    props: tabProps,
  },
]

const UserTabs = ({ tabProps }) => (
  <SideTabs tabs={TABS(tabProps)} />
)

UserTabs.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tabProps: types.object.isRequired,
}

export default UserTabs
