import React from "react"
import types from "prop-types"
import AllFormGroupingsAreEmpty from "./AllFormGroupingsAreEmpty"
import FormGrouping from "./FormGrouping"

const FormGroupingList = ({ formGroups }) => {
  if (!Object.keys(formGroups).length) {
    return (
      <div className="pt-16">
        <AllFormGroupingsAreEmpty />
      </div>
    )
  }

  return (
    Object.entries(formGroups).map(([groupName, forms]) => (
      <FormGrouping
        key={groupName}
        groupName={groupName}
        forms={forms}
      />
    ))
  )
}

FormGroupingList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formGroups: types.object.isRequired,
}

export default FormGroupingList
