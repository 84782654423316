import React from "react"
import types from "prop-types"
import { store } from "store"
import Sidebar from "shared/Sidebar"
import { setShowClosedSubmissions, setShowDeletedSubmissions } from "reduxSlices/formSubmissionTableSlice"
import SubmissionCategoryFilter from "./SubmissionCategoryFilter"
import SubmissionFormFilter from "./SubmissionFormFilter"
import SubmissionGroupBy from "./SubmissionGroupBy"
import SubmissionColumnSelector from "./SubmissionColumnSelector"
import SubmissionClosedFilter from "./SubmissionClosedFilter"
import SubmissionDeletedFilter from "./SubmissionDeletedFilter"

const SubmissionSidebar = ({ hiddenSidebar }) => {
  const toggleClosed = (value) => store.dispatch(setShowClosedSubmissions(value))
  const toggleDeleted = (value) => store.dispatch(setShowDeletedSubmissions(value))

  const toggleShowClosedSubmissions = (value) => {
    toggleClosed(value)
    if (value === true) { toggleDeleted(false) }
  }

  const toggleShowDeletedSubmissions = (value) => {
    toggleDeleted(value)
    if (value === true) { toggleClosed(false) }
  }

  return (
    <Sidebar hiddenSidebar={hiddenSidebar}>
      <SubmissionCategoryFilter />
      <SubmissionClosedFilter toggleShowClosedSubmissions={toggleShowClosedSubmissions} />
      <SubmissionDeletedFilter toggleShowDeletedSubmissions={toggleShowDeletedSubmissions} />
      <SubmissionFormFilter />
      <SubmissionGroupBy />
      <SubmissionColumnSelector />
    </Sidebar>
  )
}

SubmissionSidebar.propTypes = {
  hiddenSidebar: types.bool.isRequired,
}

export default SubmissionSidebar
