import React from "react"
import { SurveyReportExportContextProvider } from "contexts/SurveyReportExportContext"
import { reportExportSurveyReportShape } from "utils/propTypeShapes"
import NewSurveyReportExportForm from "./NewSurveyReportExportForm"
import NewSurveyReportExportProcessingCheck from "./NewSurveyReportExportProcessingCheck"

const NewSurveyReportExport = ({ surveyReport }) => (
  <SurveyReportExportContextProvider surveyReport={surveyReport}>
    <NewSurveyReportExportForm />
    <NewSurveyReportExportProcessingCheck />
  </SurveyReportExportContextProvider>
)

NewSurveyReportExport.propTypes = {
  surveyReport: reportExportSurveyReportShape.isRequired,
}

export default NewSurveyReportExport
