import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"

const AttachmentRemovedAuditItemDetails = ({ audit }) => {
  const { attachmentName } = audit

  return (
    <div>
      <span>
        <AuditedUser audit={audit} /> removed
        { attachmentName ? ` the attachment ${attachmentName}` : " an attachment"}.
      </span>
    </div>
  )
}

AttachmentRemovedAuditItemDetails.propTypes = {
  audit: auditShape.isRequired,
}

export default AttachmentRemovedAuditItemDetails
