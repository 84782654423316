import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  id: null,
  name: null,
  departments: [],
  facilityGroupCodeIds: [],
}

export const adminFacilitySlice = createSlice({
  name: "adminFacility",
  initialState: INITIAL_STATE,
  reducers: {
    activateDepartment: (state, action) => {
      const { departmentId } = action.payload
      const departmentIndex = state.departments.findIndex((department) => department.id === departmentId)
      state.departments[departmentIndex].active = true
    },
    activateFacility: (state) => {
      state.active = true
    },
    addDepartment: (state, action) => {
      state.departments.push(action.payload)
    },
    addMemberToDepartmentSegment: (state, action) => {
      const { departmentId, member, segmentName } = action.payload
      const departmentIndex = state.departments.findIndex((department) => department.id === departmentId)

      const updatedSegments = state.departments[departmentIndex].segments.map((segment) => (
        {
          ...segment,
          members: segment.name === segmentName
            ? segment.members.concat([member])
            : segment.members.filter((segmentMember) => segmentMember.id !== member.id),
        }
      ))

      state.departments[departmentIndex].segments = updatedSegments
    },
    deactivateDepartment: (state, action) => {
      const { departmentId } = action.payload
      const departmentIndex = state.departments.findIndex((department) => department.id === departmentId)
      state.departments[departmentIndex].active = false
    },
    deactivateFacility: (state) => {
      state.active = false
    },
    removeMemberFromDepartment: (state, action) => {
      const { departmentId, memberId } = action.payload
      const departmentIndex = state.departments.findIndex((department) => department.id === departmentId)

      const updatedSegments = state.departments[departmentIndex].segments.map((segment) => (
        {
          ...segment,
          members: segment.members.filter((member) => member.id !== memberId),
        }
      ))

      state.departments[departmentIndex].segments = updatedSegments
    },
    setDepartmentName: (state, action) => {
      const { departmentId, name } = action.payload
      const departmentIndex = state.departments.findIndex((department) => department.id === departmentId)
      state.departments[departmentIndex].name = name
    },
    setFacility: (state, action) => {
      Object.assign(state, action.payload)
    },
    setAssignedFacilityGroupCodeIds: (state, action) => {
      state.facilityGroupCodeIds = action.payload
    },
    updateDepartment: (state, action) => {
      const { departmentId, name, externalIdentifier } = action.payload
      const departmentIndex = state.departments.findIndex((department) => department.id === departmentId)
      const updatedDepartment = {
        ...state.departments[departmentIndex],
        ...{ name, externalIdentifier },
      }
      state.departments[departmentIndex] = updatedDepartment
    },
  },
})

export const {
  activateDepartment,
  activateFacility,
  addDepartment,
  addMemberToDepartmentSegment,
  deactivateDepartment,
  deactivateFacility,
  removeMemberFromDepartment,
  setDepartmentName,
  setFacility,
  setAssignedFacilityGroupCodeIds,
  updateDepartment,
} = adminFacilitySlice.actions

export const facilityActiveStatus = (state) => state.adminFacility.active

export const facilityDepartmentMembers = (departmentId) => (state) => {
  const facilityDepartment = state.adminFacility.departments.find((department) => department.id === departmentId)

  return facilityDepartment.segments.reduce((members, segment) => (
    members.concat(segment.members)
  ), [])
}

export const uniqueFacilityMembers = (state) => {
  const encounteredIds = new Set()

  return state.adminFacility.departments.reduce((departmentAcc, department) => (
    departmentAcc.concat(
      department.segments.reduce((segmentAcc, segment) => (
        segmentAcc.concat(
          segment.members.reduce((memberAcc, member) => {
            if (!encounteredIds.has(member.id)) {
              encounteredIds.add(member.id)
              return memberAcc.concat(member)
            }
            return memberAcc
          }, []),
        )
      ), []),
    )
  ), []).sort((member1, member2) => (
    member1.lastName.localeCompare(member2.lastName) || member1.firstName.localeCompare(member2.firstName)
  ))
}

export const facilityDepartments = (state) => state.adminFacility.departments

export const facilityId = (state) => state.adminFacility.id

export const facilityName = (state) => state.adminFacility.name

export const facilityGroupCodeIds = (state) => state.adminFacility.facilityGroupCodeIds

export default adminFacilitySlice.reducer
