import { IS_FACILITY, IS_NOT_FACILITY, IS_ANY_OF_FACILITIES } from "utils/QuestionBranching"
import withIsFacilityOperatorData from "./withIsFacilityOperatorData"

const OPERATOR_DATA_PROVIDERS = {
  [IS_FACILITY]: withIsFacilityOperatorData,
  [IS_NOT_FACILITY]: withIsFacilityOperatorData,
  [IS_ANY_OF_FACILITIES]: withIsFacilityOperatorData,
}

export default OPERATOR_DATA_PROVIDERS
