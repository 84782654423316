import addOnFeatureSubscriptionsReducer from "reduxSlices/addOnFeatureSubscriptionsSlice"
import adminFacilityReducer from "reduxSlices/adminFacilitySlice"
import categoryReducer from "reduxSlices/categorySlice"
import currentSettingsReducer from "reduxSlices/currentSettingsSlice"
import facilitiesReducer from "reduxSlices/facilitiesSlice"
import featureAnnouncementReducer from "reduxSlices/featureAnnouncementSlice"
import featuresReducer from "reduxSlices/featuresSlice"
import formBuilderReducer from "reduxSlices/formBuilderSlice"
import formIndexReducer from "reduxSlices/formIndexSlice"
import formSubmissionAttachmentsGroupingReducer from "reduxSlices/formSubmissionAttachmentsGroupingSlice"
import formSubmissionReducer from "reduxSlices/formSubmissionSlice"
import formSubmissionTableReducer from "reduxSlices/formSubmissionTableSlice"
import questionTagsReducer from "reduxSlices/questionTagsSlice"
import reportBuilderReducer from "reduxSlices/reportBuilderSlice"
import sessionReducer from "reduxSlices/sessionSlice"
import surveyBatchTableReducer from "reduxSlices/surveyBatchTableSlice"
import tasksReducer from "reduxSlices/taskDraftAnswersSlice"
import taskTableReducer from "reduxSlices/taskTableSlice"
import workflowsReducer from "reduxSlices/workflowsSlice"
import { configureStore } from "@reduxjs/toolkit";

export const store = configureStore({
  reducer: {
    addOnFeatureSubscriptions: addOnFeatureSubscriptionsReducer,
    adminFacility: adminFacilityReducer,
    category: categoryReducer,
    currentSettings: currentSettingsReducer,
    facilities: facilitiesReducer,
    featureAnnouncement: featureAnnouncementReducer,
    features: featuresReducer,
    formBuilder: formBuilderReducer,
    formIndex: formIndexReducer,
    formSubmission: formSubmissionReducer,
    formSubmissionAttachmentsGrouping: formSubmissionAttachmentsGroupingReducer,
    formSubmissionTable: formSubmissionTableReducer,
    questionTags: questionTagsReducer,
    reportBuilder: reportBuilderReducer,
    session: sessionReducer,
    surveyBatchTable: surveyBatchTableReducer,
    tasks: tasksReducer,
    taskTable: taskTableReducer,
    workflows: workflowsReducer,
  },
});
