import { v4 as makeUuid } from "uuid"
import { cloneDeep, get, isEmpty } from "lodash-es"
import FHIRPersistence from "utils/FHIRPersistence"
import queryParameter from "utils/queryParameter"
import { DEFAULT_CONTROL } from "utils/QuestionBranching"
import {
  isQuestionAnswerOrSubformAnswerConditionProperty,
  isSubformConditionProperty,
} from "utils/workflowHelpers"
import { initialAttributes, answerMayBeRequired } from "Forms/FormElementTypes"
import { CREATE_TASK } from "./workflowHelpers"

export const DEFAULT_CONDITIONALS = { control: DEFAULT_CONTROL, conditions: [] }

export { makeUuid }

export const getFormValueByPath = (formData, path) => get(formData, path)

export const makeQuestion = ({ uuid = makeUuid(), type = "text" } = {}) => ({
  uuid,
  type,
  prompt: "",
  description: "",
  attributes: initialAttributes({ questionType: type }),
  conditionals: cloneDeep(DEFAULT_CONDITIONALS),
  required: answerMayBeRequired({ questionType: type }),
  showDescription: false,
})

export const makeSection = (uuid = makeUuid()) => ({
  uuid,
  name: "",
  description: "",
  conditionals: cloneDeep(DEFAULT_CONDITIONALS),
  questions: [makeQuestion()],
})

const flattenQuestionsInSections = (sections) => (
  sections.flatMap((section) => section.questions || [])
)

export const questionList = (formData) => (
  flattenQuestionsInSections(formData.sections)
)

export const questionUuidList = (formData) => (
  questionList(formData).map((question) => question.uuid)
)

export const filteredQuestionList = (formData, { exclude = [], excludeTypes = [], type }) => {
  let questions = questionList(formData)

  questions = questions.filter((question) => (exclude.length === 0 || !exclude.includes(question.uuid))
    && (excludeTypes.length === 0 || !excludeTypes.includes(question.type))
    && (!type || question.type === type))

  return questions
}

export const questionListFromOutsideSection = (formData, sectionUuid, { excludeTypes = [] }) => {
  let questions = flattenQuestionsInSections(formData.sections.filter((section) => (
    section.uuid !== sectionUuid
  )))

  if (excludeTypes.length > 0) {
    questions = questions.filter((question) => !excludeTypes.includes(question.type))
  }

  return questions
}

export const questionWithUuid = (formData, uuid) => (
  questionList(formData).find((question) => question.uuid === uuid)
)

export const sectionAt = ({ formData, sectionIndex }) => (
  formData.sections[sectionIndex]
)

export const questionAt = ({ formData, sectionIndex, questionIndex }) => (
  sectionAt({ formData, sectionIndex })?.questions?.[questionIndex]
)

export const questionAttributesAt = ({ formData, sectionIndex, questionIndex }) => (
  questionAt({ formData, sectionIndex, questionIndex })?.attributes ?? {}
)

export const questionIsReportableField = ({ question }) => Boolean(question.reportableFieldId)

export const questionHasQuestionTagApplied = ({ question }) => Boolean(question.attributes?.questionTagId)

export const questionOptions = (question = {}) => {
  const { attributes = {} } = question
  const { options = [] } = attributes
  return options
}

export const sectionWithUuid = (formData, uuid) => (
  formData.sections.find((section) => section.uuid === uuid)
)

export const undeleteInsertIndexes = (formData, desiredSectionIndex, desiredQuestionIndex) => {
  const { sections } = formData

  const sectionIndex = (
    desiredSectionIndex >= sections.length
      ? sections.length - 1
      : desiredSectionIndex
  )

  const { questions: insertSectionQuestions = [] } = sections[sectionIndex]

  const questionIndex = (
    desiredQuestionIndex >= insertSectionQuestions.length
      ? insertSectionQuestions.length
      : desiredQuestionIndex
  )

  return { sectionIndex, questionIndex }
}

export const getQuestionAttributes = (formData, uuid) => questionWithUuid(formData, uuid)?.attributes || {}

export const reportableFieldQuestions = (formData) => (
  questionList(formData).filter((q) => q.reportableFieldId)
)

export const usedReportableFieldIds = (formData) => (
  reportableFieldQuestions(formData).map((q) => q.reportableFieldId)
)

export const questionInConditions = (conditions, questionUuid, option) => {
  const findMatches = (conditionSet) => conditionSet.filter((condition) => {
    if (condition.questionUuid !== questionUuid) return false
    if (Array.isArray(condition.matchValue)) return condition.matchValue.includes(option)

    return condition.matchValue === option
  })

  const conditionsUsingQuestion = conditions.filter((conditionSet) => {
    const matches = findMatches(conditionSet)
    return matches.length !== 0
  })

  return conditionsUsingQuestion
}

export const getUnusedQuestionTags = (form, questionTags, currentQuestionTagId) => {
  const questions = questionList(form)
  const usedQuestionTags = questions
    .map(({ attributes }) => attributes?.questionTagId)
    .filter((questionTagId) => questionTagId !== undefined)

  // Keeps the question tag in the list if it is the current question tag
  return questionTags
    .filter(({ id }) => id === currentQuestionTagId || !usedQuestionTags.includes(id))
}

// Look up the answer for a question tag in this order ...
// 1. FHIR data stored on page /smart-on-fhir/data
// 2. query parameters
export const questionTagAnswer = (questionTagName) => (
  FHIRPersistence.lookup(questionTagName) || queryParameter(questionTagName)
)

export const questionTagAnswers = (form, questionTags) => (
  questionList(form).reduce((answers, question) => {
    const { uuid, attributes } = question

    if (attributes?.questionTagId) {
      const questionTag = questionTags.find((tag) => tag.id === attributes.questionTagId)
      const answer = questionTagAnswer(questionTag?.name)

      if (answer) {
        answers[uuid] = answer
      }
    }

    return answers
  }, {})
)

const workflowDependsOnForm = ({ form, workflow }) => (
  workflow.conditions?.conditions?.some((condition) => (
    isSubformConditionProperty(condition.property)
    && condition.value.form_id === form.id
  ))
)

const getFormDependencies = ({ form, workflows }) => {
  const formDependencyMessages = []

  workflows.forEach((workflow) => {
    if (workflowDependsOnForm({ form, workflow })) {
      formDependencyMessages.push(
        `The "${workflow.name}" workflow in the "${workflow.form.title}" form uses this form.`,
      )
    }
  })

  return formDependencyMessages
}

// Check if any actions in a
// workflow depend on a given subform
const workflowDependsOnSubform = ({ form, workflow }) => (
  workflow.actions?.some((action) => (
    action.type === CREATE_TASK
    && action.data.task.subform_id === form.id
  ))
)

// Identify the workflow(s) and form(s)
// that depend on a given subform
const getSubformDependencies = ({ form, workflows }) => {
  const subformDependencyMessages = []

  workflows.forEach((workflow) => {
    const hasADependency = workflowDependsOnSubform({ form, workflow })

    if (hasADependency) {
      subformDependencyMessages
        .push(`The "${workflow.name}" workflow in the "${workflow.form.title}" form uses this task form.`)
    }
  })

  return subformDependencyMessages
}

// A form cannot be deactivated if
// it is an active form that
// is being used in a workflow
export const canFormActiveStatusChange = ({ form, workflows }) => {
  if (!form.active) {
    return [true, []]
  }

  let messages

  if (form.isSubform) {
    messages = getSubformDependencies({ form, workflows })
  } else {
    messages = getFormDependencies({ form, workflows })
  }

  return [messages.length === 0, messages]
}

// A form's active status toggle cannot be
// changed from the form index if
// the user does not have editing permissions
// or it falls under anything else covered by
// canFormActiveStatusChange
export const canFormActiveStatusChangeInIndex = ({ form, workflows }) => {
  if (!form.userCanEdit) {
    return [false, ["You do not have permission to edit forms."]]
  }

  return canFormActiveStatusChange({ form, workflows })
}

// Provide a count for each unique question prompt
export const canonicalPromptForPromptCount = (prompt) => (
  prompt.trim().toLowerCase()
)

export const questionPromptCounts = ({ form }) => (
  form.sections.reduce((counts, section) => {
    section.questions.forEach(({ prompt }) => {
      const canonicalPrompt = canonicalPromptForPromptCount(prompt)

      if (counts[canonicalPrompt]) {
        counts[canonicalPrompt] += 1
      } else {
        counts[canonicalPrompt] = 1
      }
    })

    return counts
  }, {})
)

// Provide a count for each option for each question
export const canonicalOptionforOptionCount = (option) => (
  option.trim().toLowerCase()
)

export const optionCounts = (options) => (
  options.reduce((counts, option) => {
    const canonicalPrompt = canonicalOptionforOptionCount(option)

    if (counts[canonicalPrompt]) {
      counts[canonicalPrompt] += 1
    } else {
      counts[canonicalPrompt] = 1
    }

    return counts
  }, {})
)

export const formQuestionOptionCounts = ({ form }) => (
  form.sections.reduce((questionOptionCounts, section) => {
    section.questions.forEach((question) => {
      const { uuid, attributes = {} } = question
      const { options = [] } = attributes

      questionOptionCounts[uuid] = optionCounts(options)
    })

    return questionOptionCounts
  }, {})
)

// Provide a list of all reportable fields that have been applied
// to a question on a form.  Builds an object with reportable field ids
// as properties.  Each value is the uuid for the question
// to which the reportable field was applied.
export const appliedReportableFields = ({ form }) => (
  form.sections.reduce((reportableFieldIds, section) => {
    section.questions.forEach((question) => {
      const { reportableFieldId, uuid: questionUuid } = question

      if (reportableFieldId) {
        reportableFieldIds[reportableFieldId] = questionUuid
      }
    })

    return reportableFieldIds
  }, {})
)

export const appliedQuestionTags = ({ form }) => (
  form.sections.reduce((questionTagIds, section) => {
    section.questions.forEach((question) => {
      const { attributes: questionAttributes = {}, uuid: questionUuid } = question
      const { questionTagId } = questionAttributes

      if (questionTagId) {
        questionTagIds[questionTagId] = questionUuid
      }
    })

    return questionTagIds
  }, {})
)

// Provide question options used in flagging conditions for each question.
// Currently only select, CSV select, and multi-select question are taggable.
// If that changes, this might need to be updated.
const canonicalOptionForFlagCondition = (option) => (
  option.trim().toLowerCase()
)

export const optionsInFlagCondition = (flagCondition) => {
  const { matchValue } = flagCondition
  const options = {}

  if (matchValue) {
    if (Array.isArray(matchValue)) {
      matchValue.forEach((option) => {
        options[canonicalOptionForFlagCondition(option)] = true
      })
    } else {
      options[canonicalOptionForFlagCondition(matchValue)] = true
    }
  }

  return options
}

export const formQuestionOptionsInFlagConditions = ({ form }) => (
  form.sections.reduce((questionOptionsInFlagConditions, section) => {
    section.questions.forEach((question) => {
      const { flagCondition, uuid } = question

      if (flagCondition && !isEmpty(flagCondition)) {
        questionOptionsInFlagConditions[uuid] = optionsInFlagCondition(flagCondition)
      }
    })

    return questionOptionsInFlagConditions
  }, {})
)

// Identify questions used in workflow actions.
// Builds an object with question uuids as properties.
// Every question used in a workflow action will be represented.
export const formQuestionsInWorkflowActions = ({ workflows }) => (
  workflows.reduce((questionsInWorkflowActions, workflow) => {
    const { actions = [], id: workflowId } = workflow

    actions.forEach((workflowAction) => {
      const { data: workflowActionData } = workflowAction

      const { questionUUID: questionUuid, task } = workflowActionData

      if (questionUuid) {
        if (!questionsInWorkflowActions[questionUuid]) {
          questionsInWorkflowActions[questionUuid] = [workflowId]
        } else {
          const uniqueWorkflowIds = new Set([...questionsInWorkflowActions[questionUuid], workflowId])
          questionsInWorkflowActions[questionUuid] = Array.from(uniqueWorkflowIds).sort()
        }
      }

      if (task) {
        const { assignee_departments: assigneeDepartments = [], member_departments: memberDepartments = [] } = task
        const taskDepartments = [...assigneeDepartments, ...memberDepartments]

        taskDepartments.forEach((taskDepartmentData) => {
          const { questionUUID: taskDepartmentQuestionUuid } = taskDepartmentData

          if (taskDepartmentQuestionUuid) {
            if (!questionsInWorkflowActions[taskDepartmentQuestionUuid]) {
              questionsInWorkflowActions[taskDepartmentQuestionUuid] = [workflowId]
            } else {
              const uniqueWorkflowIds = new Set([...questionsInWorkflowActions[taskDepartmentQuestionUuid], workflowId])
              questionsInWorkflowActions[taskDepartmentQuestionUuid] = Array.from(uniqueWorkflowIds).sort()
            }
          }
        })
      }
    })

    return questionsInWorkflowActions
  }, {})
)

// Identify questions used in workflow conditions.
// Builds an object with question uuids as properties.
// Every question used in a workflow condition will be represented.
const canonicalOptionforWorkflowConditionsInclusion = (option) => (
  option.trim().toLowerCase()
)

export const formQuestionOptionsInWorkflowConditions = ({ workflows }) => (
  workflows.reduce((questionOptionsInWorkflowConditions, workflow) => {
    const { conditions: workflowConditions = {}, id: workflowId } = workflow
    const { conditions = [] } = workflowConditions

    conditions.forEach((condition) => {
      const { property, subProperty = {}, value = {} } = condition

      if (!isQuestionAnswerOrSubformAnswerConditionProperty(property)) return

      const { questionUuid } = subProperty

      if (!questionUuid) return

      if (!questionOptionsInWorkflowConditions[questionUuid]) {
        questionOptionsInWorkflowConditions[questionUuid] = {
          workflowIds: [workflowId],
          options: {},
        }
      } else {
        const uniqueWorkflowIds = new Set([...questionOptionsInWorkflowConditions[questionUuid].workflowIds, workflowId])
        questionOptionsInWorkflowConditions[questionUuid].workflowIds = Array.from(uniqueWorkflowIds).sort()
      }

      const { option } = value

      if (!option) return

      const canonicalOption = canonicalOptionforWorkflowConditionsInclusion(option)

      if (!questionOptionsInWorkflowConditions[questionUuid].options[canonicalOption]) {
        questionOptionsInWorkflowConditions[questionUuid].options[canonicalOption] = [workflowId]
      } else {
        const uniqueOptionWorkflowIds = new Set([...questionOptionsInWorkflowConditions[questionUuid].options[canonicalOption], workflowId])
        questionOptionsInWorkflowConditions[questionUuid].options[canonicalOption] = Array.from(uniqueOptionWorkflowIds).sort()
      }
    })

    return questionOptionsInWorkflowConditions
  }, {})
)

// Identify questions used in section branching conditions.
// Builds an object with question uuids used as properties.
// Every question used in a section branching condition will be represented.
//
// Example:
// {
//   abc123: {
//     matchValuesUsedBySections: {
//       red: [1],
//       blue: [2],
//     },
//     sectionIndexes: [1, 2],
//   },
//   def345: {
//     matchValuesUsedBySections: {},
//     sectionIndexes: [1],
//   },
// }
//
// In the example, two questions are used in section branching conditions:
// the questions with uuids "abc123" and "def345".
//
// Question "abc123" is used in section branching conditions by the sections
// at indexes 1 and 2.  The section at index 1 uses the "red" option
// from Question "abc123" as a condition's match value.  Similarly,
// the section at index 2 uses the "blue" option as a match value.
//
// Question "def345" is used in section branching conditions but only
// by the section at index 1.  The section at index 1 does not use a match value
// in the condition.
//
// The match-value properties may represent select question options,
// facility ids, department ids, or any other value used as a match value.
const canonicalSectionConditionMatchValue = (value) => (
  String(value).trim().toLowerCase()
)

const addSectionCondtionMatchValue = ({
  matchValue, questionUuid, sectionConditionQuestions, sectionIndex,
}) => {
  const canonicalMatchValue = canonicalSectionConditionMatchValue(matchValue)

  const { matchValuesUsedBySections } = sectionConditionQuestions[questionUuid]

  if (!matchValuesUsedBySections[canonicalMatchValue]) {
    matchValuesUsedBySections[canonicalMatchValue] = [sectionIndex]
  } else {
    const uniqueSectionIndexes = new Set([...matchValuesUsedBySections[canonicalMatchValue], sectionIndex])
    matchValuesUsedBySections[canonicalMatchValue] = Array.from(uniqueSectionIndexes).sort()
  }
}

export const formSectionConditionQuestions = ({ form }) => (
  form.sections.reduce((sectionConditionQuestions, section, sectionIndex) => {
    const { conditionals = {} } = section
    const { conditions = [] } = conditionals

    conditions.forEach((condition) => {
      const { questionUuid: conditionQuestionUuid, matchValue: conditionMatchValue } = condition

      if (!sectionConditionQuestions[conditionQuestionUuid]) {
        sectionConditionQuestions[conditionQuestionUuid] = {
          matchValuesUsedBySections: {},
          sectionIndexes: [sectionIndex],
        }
      } else {
        const uniqueSectionIndexes = new Set([...sectionConditionQuestions[conditionQuestionUuid].sectionIndexes, sectionIndex])
        sectionConditionQuestions[conditionQuestionUuid].sectionIndexes = Array.from(uniqueSectionIndexes)
      }

      const matchValuePresent = conditionMatchValue || conditionMatchValue === 0

      if (!matchValuePresent) return

      if (Array.isArray(conditionMatchValue)) {
        conditionMatchValue.forEach((matchValue) => (
          addSectionCondtionMatchValue({
            matchValue,
            questionUuid: conditionQuestionUuid,
            sectionConditionQuestions,
            sectionIndex,
          })
        ))
      } else {
        addSectionCondtionMatchValue({
          matchValue: conditionMatchValue,
          questionUuid: conditionQuestionUuid,
          sectionConditionQuestions,
          sectionIndex,
        })
      }
    })

    return sectionConditionQuestions
  }, {})
)

// Identify questions used in question branching conditions.
// Builds an object with question uuids used as properties.
// Every question used in a question branching condition will be represented.
//
// Example:
// {
//   abc123: {
//     matchValuesUsedByQuestions: {
//       red: {
//         0: [1]
//       },
//       blue: {
//         0: [2]
//       },
//     },
//     questionIndexes: {
//       0: [1, 2]
//     },
//   },
//   def345: {
//     matchValuesUsedByQuestions: {},
//     questionIndexes: {
//       1: [3]
//     },
//   },
// }
//
// In the example, two questions are used in question branching conditions:
// the questions with uuids "abc123" and "def345".
//
// Question "abc123" is used in question branching conditions by two questions.
// Both questions are in the section at index 0.  The questions are at indexes 1 and 2
// in this section.  The question at index 1 uses the "red" option from Question "abc123"
// as a condition's match value.  Similarly, the section at index 2 uses the "blue" option
// as a match value.
//
// Question "def345" is used in question branching conditions but only by the question
// at index 3 in the section at index 1.  The question at index 3 in the section at index 1
//  does not use a match value in the condition.
//
// The match-value properties may represent select question options,
// facility ids, department ids, or any other value used as a match value.
const canonicalQuestionConditionMatchValue = (value) => (
  String(value).trim().toLowerCase()
)

const addQuestionCondtionMatchValue = ({
  matchValue, questionConditionQuestions, questionUuid, sectionIndex, questionIndex,
}) => {
  const canonicalMatchValue = canonicalQuestionConditionMatchValue(matchValue)

  const { matchValuesUsedByQuestions } = questionConditionQuestions[questionUuid]

  if (!matchValuesUsedByQuestions[canonicalMatchValue]) {
    matchValuesUsedByQuestions[canonicalMatchValue] = {
      [sectionIndex]: [questionIndex],
    }
  } else if (!matchValuesUsedByQuestions[canonicalMatchValue][sectionIndex]) {
    matchValuesUsedByQuestions[canonicalMatchValue][sectionIndex] = [questionIndex]
  } else {
    const uniqueQuestionIndexes = new Set([...matchValuesUsedByQuestions[canonicalMatchValue][sectionIndex], questionIndex])
    matchValuesUsedByQuestions[canonicalMatchValue][sectionIndex] = Array.from(uniqueQuestionIndexes).sort()
  }
}

export const formQuestionConditionQuestions = ({ form }) => (
  form.sections.reduce((questionConditionQuestions, section, sectionIndex) => {
    section.questions.forEach((question, questionIndex) => {
      const { conditionals = {} } = question
      const { conditions = [] } = conditionals

      conditions.forEach((condition) => {
        const { questionUuid: conditionQuestionUuid, matchValue: conditionMatchValue } = condition

        if (!questionConditionQuestions[conditionQuestionUuid]) {
          questionConditionQuestions[conditionQuestionUuid] = {
            matchValuesUsedByQuestions: {},
            questionIndexes: {
              [sectionIndex]: [questionIndex],
            },
          }
        } else {
          const { questionIndexes } = questionConditionQuestions[conditionQuestionUuid]

          if (!questionIndexes[sectionIndex]) {
            questionIndexes[sectionIndex] = [questionIndex]
          } else {
            const uniqueQuestionIndexes = new Set([...questionIndexes[sectionIndex], questionIndex])
            questionIndexes[sectionIndex] = Array.from(uniqueQuestionIndexes).sort()
          }
        }

        const matchValuePresent = conditionMatchValue || conditionMatchValue === 0

        if (!matchValuePresent) return

        if (Array.isArray(conditionMatchValue)) {
          conditionMatchValue.forEach((matchValue) => (
            addQuestionCondtionMatchValue({
              matchValue,
              questionConditionQuestions,
              questionUuid: conditionQuestionUuid,
              sectionIndex,
              questionIndex,
            })
          ))
        } else {
          addQuestionCondtionMatchValue({
            matchValue: conditionMatchValue,
            questionConditionQuestions,
            questionUuid: conditionQuestionUuid,
            sectionIndex,
            questionIndex,
          })
        }
      })
    })

    return questionConditionQuestions
  }, {})
)

// Provide a map of question locations for each question by uuid:
// section index and question index
export const formQuestionLocations = ({ form }) => (
  form.sections.reduce((questionLocations, section, sectionIndex) => {
    section.questions.forEach((question, questionIndex) => {
      const { uuid } = question

      questionLocations[uuid] = { sectionIndex, questionIndex }
    })

    return questionLocations
  }, {})
)

// Provide a list of question options usages.  An option could be used
// in a question's own flag conditions, another question's branching conditions,
// a section's branching conditions, or a workflow's conditions.
// The list describes how the option is being used.
//
// The list is built from data passed from the form builder redux slice.
// Options uses are cached in the data, but the descriptions must be built.
const questionOptionQuestionBranchingUsageDescriptions = ({
  form, option, questionConditionQuestions, questionUuid,
}) => {
  const canonicalOption = canonicalQuestionConditionMatchValue(option)
  const optionUsageSectionAndQuestionIndexes = questionConditionQuestions[questionUuid]?.matchValuesUsedByQuestions?.[canonicalOption] ?? {}

  return Object.entries(optionUsageSectionAndQuestionIndexes).reduce((descriptions, [sectionIndex, questionIndexes]) => {
    questionIndexes.forEach((questionIndex) => {
      const question = form.sections[sectionIndex].questions[questionIndex]
      const { prompt: questionPrompt } = question

      descriptions.push(`the "${questionPrompt}" question branches on this option`)
    })

    return descriptions
  }, [])
}

const questionOptionSectionBranchingUsageDescriptions = ({
  form, option, sectionConditionQuestions, questionUuid,
}) => {
  const canonicalOption = canonicalSectionConditionMatchValue(option)
  const optionUsageSectionIndexes = sectionConditionQuestions[questionUuid]?.matchValuesUsedBySections?.[canonicalOption] ?? []

  return optionUsageSectionIndexes.reduce((descriptions, sectionIndex) => {
    const section = form.sections[sectionIndex]
    const { name: sectionName } = section

    descriptions.push(`the "${sectionName}" section branches on this option`)

    return descriptions
  }, [])
}

const questionOptionWorkflowConditionUsageDescriptions = ({
  option, questionOptionsInWorkflowConditions, questionUuid, workflows,
}) => {
  const canonicalOption = canonicalOptionforWorkflowConditionsInclusion(option)
  const optionUsages = questionOptionsInWorkflowConditions[questionUuid]?.options?.[canonicalOption] ?? []

  return optionUsages.map((workflowId) => {
    const workflow = workflows.find(({ id }) => id === workflowId)
    const { name: workflowName } = workflow

    return `the "${workflowName}" workflow uses this option in its "If" section`
  })
}

const questionOptionFlagConditionUsageDescriptions = ({
  option, questionOptionsInFlagConditions, questionUuid,
}) => {
  const canonicalOption = canonicalOptionForFlagCondition(option)
  const isUsedInFlagCondition = questionOptionsInFlagConditions[questionUuid]?.[canonicalOption]

  return isUsedInFlagCondition ? ["this question's flag condition uses this option"] : []
}

export const questionOptionUsageDescriptions = ({ formBuilderReduxSlice, option, questionUuid }) => {
  const {
    form,
    questionConditionQuestions,
    questionOptionsInFlagConditions,
    questionOptionsInWorkflowConditions,
    sectionConditionQuestions,
    workflows,
  } = formBuilderReduxSlice

  return (
    [
      ...questionOptionQuestionBranchingUsageDescriptions({
        form, option, questionConditionQuestions, questionUuid,
      }),
      ...questionOptionSectionBranchingUsageDescriptions({
        form, option, sectionConditionQuestions, questionUuid,
      }),
      ...questionOptionWorkflowConditionUsageDescriptions({
        option, questionOptionsInWorkflowConditions, questionUuid, workflows,
      }),
      ...questionOptionFlagConditionUsageDescriptions({
        option, questionOptionsInFlagConditions, questionUuid,
      }),
    ]
  )
}

// Provide a list of reasons why a question may not be deleted.
// A question may be deleted if ...
// - the question is the only question in its section
// - the question is a reportable field
// - the question has a question tag applied
// - the question is used by a section for section branching
// - the question is used by a question for question branching
// - the question is used in a workflow condition
// - the question is used in a workflow action
const preventQuestionDeletionOnlyQuestionInSectionDescriptions = ({ section }) => (
  section.questions.length === 1 ? ["cannot delete the last question in a section"] : []
)

const preventQuestionDeletionReportableFieldDescriptions = ({ question }) => (
  question.reportableFieldId ? ["cannot delete a question used as a reportable field"] : []
)

const preventQuestionDeletionQuestionTagDescriptions = ({ question }) => (
  question.attributes?.questionTagId ? ["cannot delete a question with a question tag"] : []
)

export const preventQuestionDeletionSectionBranchingDescriptions = ({ questionUuid, sectionConditionQuestions, sections }) => {
  const { sectionIndexes = [] } = sectionConditionQuestions[questionUuid] ?? {}

  return sectionIndexes.map((sectionIndex) => {
    const { name: sectionName } = sections[sectionIndex]
    return `the "${sectionName}" section branches on this question`
  })
}

export const preventQuestionDeletionQuestionBranchingDescriptions = ({ questionUuid, questionConditionQuestions, sections }) => {
  const { questionIndexes = [] } = questionConditionQuestions[questionUuid] ?? {}

  return Object.entries(questionIndexes).flatMap(([sectionIndex, questIndexes]) => (
    questIndexes.map((questionIndex) => {
      const { prompt: questionPrompt } = sections[sectionIndex].questions[questionIndex]
      return `the "${questionPrompt}" question branches on this question`
    })
  ))
}

export const preventQuestionDeletionWorkflowConditionDescriptions = ({ questionUuid, questionOptionsInWorkflowConditions, workflows }) => {
  const { workflowIds = [] } = questionOptionsInWorkflowConditions[questionUuid] ?? {}

  return workflowIds.map((workflowId) => {
    const workflow = workflows.find(({ id }) => id === workflowId)
    const { name: workflowName } = workflow

    return `the "${workflowName}" workflow uses this question in its "If" section`
  })
}

export const preventQuestionDeletionWorkflowActionDescriptions = ({ questionUuid, questionsInWorkflowActions, workflows }) => {
  const workflowIds = questionsInWorkflowActions[questionUuid] ?? []

  return workflowIds.map((workflowId) => {
    const workflow = workflows.find(({ id }) => id === workflowId)
    const { name: workflowName } = workflow

    return `the "${workflowName}" workflow uses this question in its "Then" section`
  })
}

export const preventQuestionDeletionDescriptions = ({
  formBuilderReduxSlice, sectionIndex, questionIndex, ignoreOnlyQuestionInSection = false,
}) => {
  const {
    form,
    questionConditionQuestions,
    questionsInWorkflowActions,
    questionOptionsInWorkflowConditions,
    sectionConditionQuestions,
    workflows,
  } = formBuilderReduxSlice

  const { sections } = form
  const section = sections[sectionIndex]
  const question = section.questions[questionIndex]
  const { uuid: questionUuid } = question

  return (
    [
      ...(ignoreOnlyQuestionInSection ? [] : preventQuestionDeletionOnlyQuestionInSectionDescriptions({ section })),
      ...preventQuestionDeletionReportableFieldDescriptions({ question }),
      ...preventQuestionDeletionQuestionTagDescriptions({ question }),
      ...preventQuestionDeletionSectionBranchingDescriptions({ questionUuid, sectionConditionQuestions, sections }),
      ...preventQuestionDeletionQuestionBranchingDescriptions({ questionUuid, questionConditionQuestions, sections }),
      ...preventQuestionDeletionWorkflowConditionDescriptions({ questionUuid, questionOptionsInWorkflowConditions, workflows }),
      ...preventQuestionDeletionWorkflowActionDescriptions({ questionUuid, questionsInWorkflowActions, workflows }),
    ]
  )
}

// Provide a list of reasons why a section may not be deleted.
// A section may not be deleted if any of its questions may not be deleted.
export const preventSectionDeletionDescriptions = ({ formBuilderReduxSlice, sectionIndex }) => {
  const { form } = formBuilderReduxSlice
  const { sections } = form
  const section = sections[sectionIndex]

  return section.questions.reduce((descriptions, question, questionIndex) => {
    const questionDeletable = preventQuestionDeletionDescriptions({
      formBuilderReduxSlice, sectionIndex, questionIndex, ignoreOnlyQuestionInSection: true,
    }).length === 0

    if (!questionDeletable) {
      const { prompt: questionPrompt } = question
      descriptions.push(`the "${questionPrompt}" question cannot be deleted`)
    }

    return descriptions
  }, [])
}
