import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { useFormSubmissionAttachmentDropzoneContext } from "contexts/FormSubmissionAttachmentDropzoneContext"
import { CheckmarkCircleIcon } from "shared/icons"

const AcceptedFileList = ({ className = "" }) => {
  const { acceptedFiles } = useFormSubmissionAttachmentDropzoneContext()

  return (
    <div className={className}>
      {
        acceptedFiles.map((acceptedFile) => {
          const {
            attached, error, filename, percentUploaded, uuid,
          } = acceptedFile

          let status

          if (error) {
            status = "error"
          } else if (attached) {
            status = "complete"
          } else {
            status = "in-progress"
          }

          return (
            <div key={uuid} className={clsx("attachment-upload", status)}>
              <div className="progress-bar" style={{ width: `${percentUploaded}%` }} />
              <div className="w-full flex gap-4 justify-between items-center relative">
                <span className="grow truncate">
                  {filename}
                </span>
                {
                  attached
                    ? <CheckmarkCircleIcon className="w-5 h-5 shrink-0" />
                    : <div className="w-5 h-5 shrink-0 border rounded-full bg-white" />
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

AcceptedFileList.propTypes = {
  className: types.string,
}

export default AcceptedFileList
