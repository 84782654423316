import React from "react"
import types from "prop-types"
import { useTaskFormContext } from "contexts/TaskFormContext"
import { TextInput } from "shared/inputs"
import FormsyValidation from "shared/FormsyValidation"

const TaskTitle = ({ actionIndex, className = "" }) => {
  const { taskTitle, updateTaskTitle } = useTaskFormContext()

  return (
    <div className={className}>
      <span className="block mb-1 font-semibold required-prompt">Task Name</span>
      <TextInput
        className="rounded-lg bordered-base-input !text-sm"
        placeholder="Task name ..."
        value={taskTitle}
        onChange={(value) => updateTaskTitle(value.trimStart())}
      />
      <FormsyValidation
        name={`actions[${actionIndex}].data.task.title`}
        validationErrors={{ isDefaultRequiredValue: "required" }}
        validations={{ isNotBlankString: true }}
        value={taskTitle || ""}
      />
    </div>
  )
}

TaskTitle.propTypes = {
  actionIndex: types.number.isRequired,
  className: types.string,
}

export default TaskTitle
