import React from "react"
import { useSolutionLevelReportExportContext } from "contexts/SolutionLevelReportExportContext"
import ReportableFieldLocationAnswerFiltersDetails from "./ReportableFieldLocationAnswerFiltersDetails"
import ReportableFieldLocationAnswerFiltersReportableFieldSelect from "./ReportableFieldLocationAnswerFiltersReportableFieldSelect"

const NewSolutionLevelReportExportFormReportableFieldLocationAnswerFilters = () => {
  const { reportableFieldLocationAnswerFilters } = useSolutionLevelReportExportContext()

  const reportableFieldLocationAnswerFilter = reportableFieldLocationAnswerFilters[0] ?? {}
  const { reportableFieldId } = reportableFieldLocationAnswerFilter

  return (
    <>
      <ReportableFieldLocationAnswerFiltersReportableFieldSelect />
      {
        reportableFieldId && (
          <ReportableFieldLocationAnswerFiltersDetails />
        )
      }
    </>
  )
}

export default NewSolutionLevelReportExportFormReportableFieldLocationAnswerFilters
