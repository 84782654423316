import React from "react"
import { useSelector } from "react-redux"
import { getCategorySubforms } from "reduxSlices/formBuilderSlice"
import { workflowActionShape } from "utils/propTypeShapes"
import { GENERAL_TASK_SUBFORM_ID } from "utils/taskHelpers"

const CreateTaskActionLabel = ({ action }) => {
  const subforms = useSelector(getCategorySubforms)
  const selectedSubformId = action.data.task.subform_id

  if (!selectedSubformId || selectedSubformId === GENERAL_TASK_SUBFORM_ID) return null

  const selectedSubform = subforms.find((subform) => subform.id === selectedSubformId)

  return (
    <span className="task-subform-name">{ selectedSubform.title }</span>
  )
}

CreateTaskActionLabel.propTypes = {
  action: workflowActionShape.isRequired,
}

export default CreateTaskActionLabel
