import React from "react"
import { useSelector } from "react-redux"
import { getGroups, getUsers } from "reduxSlices/formBuilderSlice"
import { workflowActionShape } from "utils/propTypeShapes"
import { WorkflowActionAddMembersIcon } from "shared/icons"
import ActionDetails from "./ActionDetails"
import ActionAbbreviatedUserList from "./ActionAbbreviatedUserList"
import ActionHeading from "./ActionHeading"
import ActionLabelContainer from "./ActionLabelContainer"

const AddMembersActionLabel = ({ action }) => {
  const { groups, users } = action.data.members

  const groupsToAdd = useSelector(getGroups).filter((potentialGroup) => (
    groups?.map((group) => group.id).includes(potentialGroup.id)
  ))

  const usersToAdd = useSelector(getUsers).filter((potentialUser) => (
    users?.map((group) => group.id).includes(potentialUser.id)
  ))

  return (
    <ActionLabelContainer>
      <ActionHeading
        iconComponent={WorkflowActionAddMembersIcon}
        text="Add members:"
      />
      <ActionDetails>
        <span>
          {
            groupsToAdd.map((group) => (
              <span key={group.id} className="workflow-label mr-2 mb-2">{group.name}</span>
            ))
          }
        </span>
        <ActionAbbreviatedUserList users={usersToAdd} />
      </ActionDetails>
    </ActionLabelContainer>
  )
}

AddMembersActionLabel.propTypes = {
  action: workflowActionShape.isRequired,
}

export default AddMembersActionLabel
