import React from "react"
import types from "prop-types"
import { dynamicRangeSubmissionsExample, weekRangeSubmissionInclusionDescription } from "utils/scheduledReportExportHelpers"

const WeeksSubmissionDescription = ({ startDate, submissionPreviousRangeLength = null }) => {
  const weekCount = submissionPreviousRangeLength || 1

  const beginningWeek = startDate.subtract(weekCount, "w")
  const beginningDate = beginningWeek.weekday(0)

  // endingDate is n weeks from start date minus one day
  const endingDate = beginningDate.add(weekCount, "w").subtract(1, "d")

  return (
    <div className="text-xs text-dark mt-2">
      <p className="mb-4">
        {weekRangeSubmissionInclusionDescription}
      </p>
      <p>
        {dynamicRangeSubmissionsExample({ startDate, beginningDate, endingDate })}
      </p>
    </div>
  )
}

WeeksSubmissionDescription.propTypes = {
  startDate: types.object.isRequired,
  submissionPreviousRangeLength: types.number,
}

export default WeeksSubmissionDescription
