import React, { useEffect } from "react"
import clsx from "clsx"
import { Provider, useSelector } from "react-redux"
import { store } from "store"
import { useHiddenSidebar } from "hooks"
import Sidebar from "shared/Sidebar"
import { attachments as getAttachments } from "reduxSlices/formSubmissionSlice"
import { setAttachments } from "reduxSlices/formSubmissionAttachmentsGroupingSlice"
import { FormSubmissionAttachmentDropzoneContextProvider } from "contexts/FormSubmissionAttachmentDropzoneContext"
import AttachmentGroupBy from "./Sidebar/AttachmentGroupBy"
import AttachmentTypeFilter from "./Sidebar/AttachmentTypeFilter"
import AttachmentsHeader from "./Content/AttachmentsHeader"
import AttachmentList from "./Content/AttachmentList"

const AttachmentsContent = () => {
  const attachments = useSelector(getAttachments)
  const { hiddenSidebar, toggleSidebar } = useHiddenSidebar()

  useEffect(() => {
    store.dispatch(setAttachments(attachments))
  }, [attachments])

  return (
    <Provider store={store}>
      <FormSubmissionAttachmentDropzoneContextProvider>
        <div className="relative grow">
          <Sidebar
            hiddenSidebar={hiddenSidebar}
            className="absolute !mt-0 left-0 bottom-0 min-w-max max-w-sm z-10"
          >
            <AttachmentTypeFilter attachments={attachments} />
            <AttachmentGroupBy />
          </Sidebar>
          <div className={clsx(!hiddenSidebar && "lg:!pl-16 lg:!pr-16 ml-96", "inner-container mx-auto max-w-screen-xl")}>
            <AttachmentsHeader
              hasAttachments={attachments.length > 0}
              hiddenSidebar={hiddenSidebar}
              toggleSidebar={toggleSidebar}
            />
            <AttachmentList attachments={attachments} />
          </div>
        </div>
      </FormSubmissionAttachmentDropzoneContextProvider>
    </Provider>
  )
}

export default AttachmentsContent
