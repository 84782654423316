import React from "react"
import clsx from "clsx"
import { isEqual } from "lodash-es"
import { useSelector } from "react-redux"
import { useModal } from "hooks"
import {
  formSubmissionData,
  questionUuidsIdentifyingTaskDepartments as getQuestionUuidsIdentifyingTaskDepartments,
} from "reduxSlices/formSubmissionSlice"
import { useFormSubmissionFormContext } from "contexts/FormSubmissionFormContext"
import { CircularIconButton } from "shared/buttons"
import { FormIcon } from "shared/icons"
import ItemRow from "shared/ItemRow"
import ChangeSubmissionFormType from "./ChangeSubmissionFormType"
import EditSubmissionButton from "./EditSubmissionButton"
import FormSubmissionAccessOverview from "./FormSubmissionAccessOverview"
import SubmissionEdit from "./SubmissionEdit"
import SubmissionOverview from "./SubmissionOverview"
import TaskDepartmentWarning from "./TaskDepartmentWarning"

const SubmissionContent = () => {
  // If a question is used to identify an assignee/member department
  // in a create-task action on one of the form's workflows,
  // then we want to warn the user that task assigments might need to be updated.
  const {
    modalIsOpen: taskDepartmentWarningIsVisible,
    openModal: showTaskDepartmentWarning,
    closeModal: hideTaskDepartmentWarning,
  } = useModal()

  const { answers: currentAnswers, slug } = useSelector(formSubmissionData)
  const questionUuidsIdentifyingTaskDepartments = useSelector(getQuestionUuidsIdentifyingTaskDepartments)

  const {
    form, editMode, closeEditMode, openEditMode,
  } = useFormSubmissionFormContext()
  if (!form.id) return null

  const checkUpdatedAnswers = ({ answers: updatedAnswers }) => {
    const taskDepartmentAnswerChanged = questionUuidsIdentifyingTaskDepartments.some((questionUuid) => (
      !isEqual(updatedAnswers[questionUuid], currentAnswers[questionUuid])
    ))

    if (taskDepartmentAnswerChanged) {
      showTaskDepartmentWarning()
    }
  }

  // Ensure that the form submission data is in Redux
  // before rendering
  if (!slug) return null

  return (
    <div className="relative grow">
      <FormSubmissionAccessOverview />
      <div className="relative inner-container max-w-screen-lg mx-auto">
        <ItemRow
          itemRowHeaderContentClassName={clsx("item-row-header-content-submission-content", editMode && "edit")}
          itemRowHeaderContent={(
            <div className="flex items-center gap-2">
              <FormIcon form={form} />
              <span className={clsx("font-medium text-xl")}>
                { form?.title }
              </span>
            </div>
          )}
          itemRowHeaderControls={
            editMode
              ? (
                <>
                  <ChangeSubmissionFormType onFormTypeChange={closeEditMode} />
                  <CircularIconButton icon="close" onClick={closeEditMode} />
                </>
              )
              : <EditSubmissionButton onClick={openEditMode} />
          }
          itemRowBodyClassName="item-row-body-submission-content"
          itemRowBody={
            editMode
              ? <SubmissionEdit onCancel={closeEditMode} onSuccess={checkUpdatedAnswers} />
              : <SubmissionOverview />
          }
        />
        <TaskDepartmentWarning isOpen={taskDepartmentWarningIsVisible} close={hideTaskDepartmentWarning} />
      </div>
    </div>

  )
}

export default SubmissionContent
