import { isEmpty, isMatch, pick } from "lodash-es"

export const GENERAL_TASK_SUBFORM_ID = "general-task"
export const GENERAL_TASK_OPTION = { label: "General Task", value: GENERAL_TASK_SUBFORM_ID }

export const departmentIdentifier = (department) => pick(department, ["departmentId", "questionUUID"])

export const isMatchingDepartment = (department, lookupProperties) => (
  // isMatch will always return true when lookup properties is falsey or empty
  !isEmpty(lookupProperties) && isMatch(department, lookupProperties)
)

export const updateDepartmentInCollection = (departmentCollection, identifier, updatePropertiesFunction) => (
  departmentCollection.map((dept) => (
    isMatchingDepartment(dept, identifier)
      ? { ...dept, ...updatePropertiesFunction(dept) }
      : dept
  ))
)
