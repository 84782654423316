import React from "react"
import ReactIsCapsLockActive from "@matsun/reactiscapslockactive"

const CapsLockMessage = () => (
  <ReactIsCapsLockActive>
    {
      (active) => (
        <div> {
          active
          && (
            <p
              className="-mb-7 text-gray-400 text-s capitalize"
            >
              Caps Lock Is On
            </p>
          )
        }
        </div>
      )
    }
  </ReactIsCapsLockActive>
)

export default CapsLockMessage
