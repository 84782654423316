import React from "react"
import types from "prop-types"
import { categoryShape } from "utils/propTypeShapes"
import CategoryAction from "./CategoryAction"

const CategoryActions = ({ category, accessLevelOptions }) => (
  <table className="w-full mb-6 border-separate" style={{ borderSpacing: "0 0.5rem" }}>
    <thead>
      <tr className="text-dark text-sm">
        <th className="pl-5 text-left">Name</th>
        <th className="text-left">Access Needed</th>
      </tr>
    </thead>
    <tbody>
      {
        category.accessLevelRequirements.map(({ id, action, accessLevel }, index) => (
          <CategoryAction
            key={id}
            id={id}
            index={index}
            action={action}
            accessLevel={accessLevel}
            accessLevelOptions={accessLevelOptions}
          />
        ))
      }
    </tbody>
  </table>
)

CategoryActions.propTypes = {
  category: categoryShape.isRequired,
  accessLevelOptions: types.arrayOf(types.string).isRequired,
}

export default CategoryActions
