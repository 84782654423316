import React, { useState } from "react"
import { isEmpty } from "lodash-es"
import { useModal } from "hooks"
import * as API from "services/api"
import { useHistoricalAccessToolContext } from "contexts/HistoricalAccessToolContext"
import { PrimaryButton, TertiaryButton } from "shared/buttons"
import Modal, { DEFAULT_MODAL_STYLE, ModalHeader } from "shared/Modal"
import { errorToast, successToast } from "shared/toast"
import HistoricalAccessToolPreviewList from "shared/HistoricalAccessTool/HistoricalAccessToolPreviewList"

const HistoricalAccessToolAddAccessPreview = () => {
  const { modalIsOpen, openModal, closeModal } = useModal()
  const [formSubmissionCounts, setFormSubmissionCounts] = useState([])

  const {
    allRequiredAddAccessInstructionsDataEntered,
    earliestSubmissionDate,
    latestSubmissionDate,
    reportableFieldLocationAnswerFilters,
    resetHistoricalAccessToolData,
    selectedAccessLevel,
    selectedFormsForSubmittingIds,
    selectedTaskFormIds,
    userId,
  } = useHistoricalAccessToolContext()

  const loadFormSubmissionCounts = async () => {
    const response = await API.historicalAccessToolAddAccessPreviews({
      accessLevel: selectedAccessLevel,
      earliestSubmissionDate,
      formsForSubmittingIds: selectedFormsForSubmittingIds,
      latestSubmissionDate,
      reportableFieldLocationAnswerFilters,
      taskFormIds: selectedTaskFormIds,
      userId,
    })

    if (response.ok) {
      setFormSubmissionCounts(response.data.formSubmissionCounts)
    } else {
      errorToast("Something went wrong.  Unable to generate preview.")
    }
  }

  const addAccess = async () => {
    const response = await API.historicalAccessToolAddAccess({
      accessLevel: selectedAccessLevel,
      earliestSubmissionDate,
      formsForSubmittingIds: selectedFormsForSubmittingIds,
      latestSubmissionDate,
      reportableFieldLocationAnswerFilters,
      taskFormIds: selectedTaskFormIds,
      userId,
    })

    if (response.ok) {
      successToast("Add access request received.  We'll email you with results once your request has been processed.")
      resetHistoricalAccessToolData()
      closeModal()
      setFormSubmissionCounts([])
    } else {
      errorToast("Something went wrong.  Unable to add access.")
    }
  }

  const generatePreview = () => {
    openModal()
    loadFormSubmissionCounts()
  }

  const cancel = () => {
    closeModal()
    setFormSubmissionCounts([])
  }

  return (
    <>
      <div className="flex justify-center">
        <PrimaryButton text="Preview" disabled={!allRequiredAddAccessInstructionsDataEntered} onClick={generatePreview} />
      </div>
      <Modal isOpen={modalIsOpen} style={DEFAULT_MODAL_STYLE}>
        <ModalHeader
          closeModal={cancel}
          heading="Add Access Preview"
        />
        {
          !isEmpty(formSubmissionCounts) && (
            <HistoricalAccessToolPreviewList formSubmissionCounts={formSubmissionCounts} className="mb-4" />
          )
        }
        <p className="text-sm text-gray mb-8 mx-auto">
          If the user is not currently setup to gain access to future form submissions,
          there may be new submissions that arrive during or after this add-access request that the user will not have access to.
        </p>
        <div className="flex justify-center gap-4">
          <TertiaryButton text="Cancel" onClick={cancel} />
          <PrimaryButton text="Confirm" onClick={addAccess} />
        </div>
      </Modal>
    </>
  )
}

export default HistoricalAccessToolAddAccessPreview
