import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getFormHasLocationQuestions, getFormIsSubform } from "reduxSlices/formBuilderSlice"
import { snakeToCapitalCase } from "utils/stringHelpers"
import { subtypes as subtypesForType, NOTIFY_ALL_MEMBERS } from "utils/workflowHelpers"
import { Select } from "shared/selects"

const SUBTYPE_LABELS = {
  [NOTIFY_ALL_MEMBERS]: "All Submission Members",
}

const subtypeOptions = (subtypes) => subtypes.map((subtype) => ({
  value: subtype,
  label: SUBTYPE_LABELS[subtype] || snakeToCapitalCase(subtype),
}))

const SelectActionSubtype = ({ changeActionSubtype, type = undefined, value = null }) => {
  const formIsSubform = useSelector(getFormIsSubform)
  const formHasLocationQuestions = useSelector(getFormHasLocationQuestions)

  const subtypes = subtypesForType(type, { formHasLocationQuestions, formIsSubform })
  if (!subtypes || subtypes.length < 2) return null

  const options = subtypeOptions(subtypes)
  return (
    <Select
      backspaceDelete={false}
      onChange={changeActionSubtype}
      options={options}
      required
      value={value}
      style={{ height: "32px" }}
    />
  )
}

SelectActionSubtype.propTypes = {
  changeActionSubtype: types.func.isRequired,
  type: types.string,
  value: types.string,
}

export default SelectActionSubtype
