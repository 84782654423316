import React, { useRef } from "react"
import { isEqual } from "lodash-es"
import { useSelector, useDispatch } from "react-redux"
import {
  deselectAllTaskForms,
  deselectTaskForm,
  taskForms,
  selectAllTaskForms,
  selectTaskForm,
  selectedTaskForms,
} from "reduxSlices/reportBuilderSlice"
import MassSelection from "shared/MassSelection"
import { DraggableColumnTypes } from "./DragAndDrop/DraggableColumnTypes"
import ColumnOption from "./ColumnOption"
import SelectableOptions from "./SelectableOptions"

const TaskFormOptions = () => {
  const taskFormOptions = useSelector(taskForms, isEqual)
  const taskFormSelections = useSelector(selectedTaskForms, isEqual)

  const dispatch = useDispatch()
  const deselectForm = (form) => dispatch(deselectTaskForm(form))
  const selectForm = (form) => dispatch(selectTaskForm(form))

  const deselectAll = () => dispatch(deselectAllTaskForms())
  const selectAll = () => dispatch(selectAllTaskForms())

  const ref = useRef(null)

  return (
    <SelectableOptions
      acceptableTypes={DraggableColumnTypes.TASK_FORM_SELECTION}
      dropFunction={deselectForm}
      ref={ref}
    >
      <div>
        <div className="mb-1 text-xl">Task Forms</div>
        <MassSelection
          deselectAll={deselectAll}
          selectAll={selectAll}
        />
        <div className="w-full mt-4 space-y-2">
          {
            taskFormOptions.map((v) => (
              <ColumnOption
                key={`${v.type}-${v.id}`}
                option={v}
                selectedColumns={taskFormSelections}
                selectOption={selectForm}
                deselectOption={deselectForm}
              />
            ))
          }
        </div>
      </div>
    </SelectableOptions>
  )
}

export default TaskFormOptions
