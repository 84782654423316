import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { XIcon } from "shared/icons"

const ModalHeader = ({ closeModal = undefined, heading }) => (
  <div className={clsx(closeModal && "pt-10", "mb-8 relative")}>
    <span className="block text-center text-xl font-semibold">{heading}</span>
    {
      closeModal && (
        <XIcon
          className="absolute -top-4 right-4 rounded-full bg-primary-light p-2 h-8 w-8"
          onClick={closeModal}
        />
      )
    }
  </div>
)

ModalHeader.propTypes = {
  closeModal: types.func,
  heading: types.string.isRequired,
}

export default ModalHeader
