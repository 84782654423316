import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"

const TaskViewedAuditItemDetails = ({ audit }) => {
  const { task } = audit

  return (
    <div>
      <span>
        <AuditedUser audit={audit} /> viewed the
        task titled <span className="font-semibold">{task.title}</span>.
      </span>
    </div>
  )
}

TaskViewedAuditItemDetails.propTypes = {
  audit: auditShape.isRequired,
}

export default TaskViewedAuditItemDetails
