import React from "react"
import clsx from "clsx"
import Button from "./Button"
import buttonPropTypes from "./buttonPropTypes"

const TertiaryButton = ({ className, isDark = false, ...rest }) => (
  <Button
    className={clsx(className, "tertiary-button", isDark && "dark")}
    {...rest}
  />
)

TertiaryButton.propTypes = buttonPropTypes

export default TertiaryButton
