import React from "react"
import types from "prop-types"
import { publicFormUrl } from "utils/routeHelpers"
import { USAGES_TO_EXCLUDE_FORM_COPY_LINK } from "utils/formHelpers"
import { TextInput } from "shared/inputs"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { SecondaryButton } from "shared/buttons"

const FormCopyLink = ({ formSlug, active, usage }) => {
  if (!active) return null
  if (USAGES_TO_EXCLUDE_FORM_COPY_LINK.includes(usage)) return null

  const highlightInput = ({ target }) => target.select()

  return (
    <div>
      <span className="text-sm font-bold">LINK</span>
      <div className="flex items-center">
        <TextInput
          className="flex-grow mr-1 text-ellipsis bordered-base-input h-9"
          onClick={highlightInput}
          onFocus={highlightInput}
          readonly
          value={publicFormUrl(formSlug)}
        />
        <CopyToClipboard text={publicFormUrl(formSlug)}>
          <SecondaryButton
            text="Copy Link"
            className="whitespace-nowrap"
          />
        </CopyToClipboard>
      </div>
    </div>
  )
}

FormCopyLink.propTypes = {
  formSlug: types.string.isRequired,
  active: types.bool.isRequired,
  usage: types.string.isRequired,
}

export default FormCopyLink
