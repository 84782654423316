import React from "react"
import types from "prop-types"
import { RemoveButton } from "shared/buttons"
import { AccessLevelSelect } from "shared/selects"

const SelectedGroups = ({
  groups, removeGroup, setGroupAccessLevel = null, workflowData = [],
}) => {
  if (groups.length === 0) {
    return <div className="ml-4">No Selected Group</div>
  }

  const selectedAccessLevel = (groupId) => workflowData.find((group) => group.id === groupId).access_level

  return (
    groups.map((group) => (
      <div key={group.id} className="relative p-3 list-row w-11/12">
        <div className="flex items-center">
          <span>{ group.name }</span>
        </div>
        {
          setGroupAccessLevel && !!workflowData.length && (
            <AccessLevelSelect
              onChange={(value) => setGroupAccessLevel(value, group.id)}
              value={selectedAccessLevel(group.id)}
            />
          )
        }
        <RemoveButton
          className="absolute -right-8"
          onClick={() => removeGroup(group.id)}
        />
      </div>
    ))
  )
}

SelectedGroups.propTypes = {
  groups: types.arrayOf(types.object).isRequired,
  removeGroup: types.func.isRequired,
  setGroupAccessLevel: types.func,
  workflowData: types.arrayOf(types.object),
}

export default SelectedGroups
