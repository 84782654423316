import React from "react"
import types from "prop-types"
import ExpandMenu, { ExpandMenuItem } from "shared/ExpandMenu"
import { EllipsisIcon } from "shared/icons"

const goToFormEdit = (formSlug) => {
  window.location.pathname = `/activity-hub/forms/${formSlug}/edit`
}

const FormMenu = ({ formSlug, userCanEdit }) => {
  if (!userCanEdit) return null

  return (
    <ExpandMenu expandMenuComponent={<EllipsisIcon />}>
      <ExpandMenuItem onClick={() => goToFormEdit(formSlug)}>
        <span>Edit</span>
      </ExpandMenuItem>
    </ExpandMenu>
  )
}

FormMenu.propTypes = {
  formSlug: types.string.isRequired,
  userCanEdit: types.bool.isRequired,
}

export default FormMenu
