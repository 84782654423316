import React from "react"
import types from "prop-types"
import Switch from "shared/Switch"
import ContentBar from "shared/lists/ContentBar"
import * as API from "services/api"

const LOCKED_DESCRIPTION = "LOCKED after too many failed sign-in attempts. It must be unlocked by an admin."
const UNLOCKED_DESCRIPTION = "UNLOCKED"

const UserLocked = ({ user, updateLocked }) => {
  const updateUserLocked = async (locked) => {
    const response = await API.updateUserLocked({ userId: user.id, locked })
    if (response.ok) {
      updateLocked(locked)
    } else {
      console.error("Error updating user's locked status: ", response);
    }
  }

  const descriptionClassName = user.locked ? "text-error" : "text-success"
  const statusDescription = user.locked ? LOCKED_DESCRIPTION : UNLOCKED_DESCRIPTION

  return (
    <ContentBar
      description={`Account is currently ${statusDescription}`}
      descriptionClassName={descriptionClassName}
      title="Locked Status"
    >
      <Switch
        checked={user.locked}
        disabled={!user.locked}
        onChange={updateUserLocked}
      />
    </ContentBar>
  )
}

UserLocked.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: types.object.isRequired,
  updateLocked: types.func.isRequired,
}

export default UserLocked
