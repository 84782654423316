import { useEffect } from "react"
import types from "prop-types"
import { errorToast, successToast } from "shared/toast"

const FlashMessage = ({ alert = undefined, notice = undefined }) => {
  useEffect(() => {
    if (alert) errorToast(alert)
    if (notice) successToast(notice)
  }, [alert, notice])

  return null
}

FlashMessage.propTypes = {
  alert: types.string,
  notice: types.string,
}

export default FlashMessage
