import React, { useEffect } from "react"
import types from "prop-types"
import { store } from "store"
import { useSelector } from "react-redux"
import { AttachmentTypeIcon } from "shared/icons"
import { Checkbox } from "shared/checkboxes"
import { getAttachmentGrouping, setFileTypesToFilterBy } from "reduxSlices/formSubmissionAttachmentsGroupingSlice"

const AttachmentsList = ({ attachments }) => {
  const { fileTypesToFilterBy } = useSelector(getAttachmentGrouping)

  const allFileTypes = () => {
    const fileTypes = attachments.map(({ fileType }) => fileType)
    return [...new Set(fileTypes)]
  }

  const numberOfFormsByType = (currentType) => attachments.filter(({ fileType }) => fileType === currentType).length

  useEffect(() => {
    store.dispatch(setFileTypesToFilterBy(allFileTypes()))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments])

  const isFileTypeChecked = (fileType) => fileTypesToFilterBy.includes(fileType)
  const updateFileTypesToFilterBy = (value) => store.dispatch(setFileTypesToFilterBy(value))

  const toggleFileTypesToFilterBy = (fileType) => () => {
    if (isFileTypeChecked(fileType)) {
      updateFileTypesToFilterBy(fileTypesToFilterBy.filter((currentFileType) => currentFileType !== fileType))
    } else {
      updateFileTypesToFilterBy([...fileTypesToFilterBy, fileType])
    }
  }

  return allFileTypes().map((fileType) => (
    <div
      key={fileType}
      className="flex justify-center items-center p-3 my-2 rounded-md cursor-pointer"
      onClickCapture={toggleFileTypesToFilterBy(fileType)}
    >
      <AttachmentTypeIcon fileType={fileType} />
      <div className="flex-grow ml-2 uppercase">
        { `${fileType} (${numberOfFormsByType(fileType)})` }
      </div>
      <Checkbox
        value={isFileTypeChecked(fileType)}
        onChange={toggleFileTypesToFilterBy(fileType)}
      />
    </div>
  ))
}

AttachmentsList.propTypes = {
  attachments: types.arrayOf(types.object).isRequired,
}

export default AttachmentsList
