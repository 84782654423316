import React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getFormCounts,
  getForms,
  getSurveyBatchTable,
  hideForm,
  showForm,
} from "reduxSlices/surveyBatchTableSlice"
import { FormIcon } from "shared/icons"
import { Checkbox } from "shared/checkboxes"

const BatchFormsList = () => {
  const dispatch = useDispatch()
  const forms = useSelector(getForms)
  const formCounts = useSelector(getFormCounts)
  const { formIdsToHide } = useSelector(getSurveyBatchTable)

  const isFormVisible = (id) => !formIdsToHide.includes(id)

  const toggleFormVisibility = (id) => () => {
    const updateFunction = isFormVisible(id) ? hideForm : showForm

    dispatch(updateFunction(id))
  }

  return forms.map((form) => (
    <div
      key={form.id}
      className="mb-2 rounded-md cursor-pointer p-2 border border-lightgray-3 text-sm font-medium"
      style={{
        display: "grid",
        gridTemplateColumns: "36px auto 24px",
        alignItems: "center",
      }}
      onClickCapture={toggleFormVisibility(form.id)}
    >
      <FormIcon form={form} className="h-6 w-6 justify-self-start" />
      <div className="justify-self-start">
        { form.title }
        <span className="text-gray-800 opacity-70"> ({ formCounts[form.id] ?? 0 })</span>
      </div>
      <Checkbox
        className="justify-self-end"
        value={isFormVisible(form.id)}
        onChange={toggleFormVisibility(form.id)}
      />
    </div>
  ))
}

export default BatchFormsList
