import React from "react"
import types from "prop-types"
import * as TimeDifferenceAttributes from "./TimeDifferenceAttributes"
import QuestionShowDescription from "./QuestionShowDescription"

const TimeDifferenceInputConfig = ({ sectionIndex, questionIndex }) => (
  <>
    <QuestionShowDescription
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <TimeDifferenceAttributes.Questions
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
  </>
)

TimeDifferenceInputConfig.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default TimeDifferenceInputConfig
