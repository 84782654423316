import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { RemoveButton } from "shared/buttons"
import UserInfo from "shared/UserInfo"

const SearchSelectedUser = ({
  className = "",
  children = null,
  user,
  deselect,
  showDeselectButton = true,
}) => (
  <div className={clsx(className, "relative p-3 mb-2 last:mb-0 bg-white rounded-lg flex-center border border-light-300")}>
    <UserInfo user={user} showContentIfInactive={false}>
      {children}
    </UserInfo>
    {
      showDeselectButton && (
        <RemoveButton
          className="absolute -right-8"
          onClick={deselect}
        />
      )
    }
  </div>
)

SearchSelectedUser.propTypes = {
  className: types.string,
  children: types.node,
  showDeselectButton: types.bool,
  deselect: types.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  user: types.object.isRequired,
}

export default SearchSelectedUser
