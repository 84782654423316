import React from "react"
import types from "prop-types"
import { daysOfTheWeek, todayDay, dayjsOrgTz } from "utils/dateHelpers"
import { ListSelect } from "shared/selects"

const WeeklyRecurrenceDetails = ({ recurrenceDayOfWeek, setRecurrenceDayOfWeek, setStartDate }) => {
  const setRecurrenceDetails = (selectedRecurrenceDayOfWeek) => {
    setRecurrenceDayOfWeek(selectedRecurrenceDayOfWeek)

    const today = dayjsOrgTz()
    let startingDate = today.weekday(daysOfTheWeek.indexOf(selectedRecurrenceDayOfWeek))

    // If start day has occurred this week, start the following week on that day of the week.
    // If the start day has not occurred, then start this week (e.g. today is Mon, selected Fri)
    if (dayjsOrgTz(startingDate) <= today) {
      startingDate = startingDate.add(1, "w")
    }

    setStartDate(startingDate)
  }

  return (
    <div className="flex items-center mr-2">
      <div className="mr-2">every</div>
      <ListSelect
        backspaceDelete={false}
        required
        min={1}
        name="recurrence_day_of_week"
        options={daysOfTheWeek}
        value={recurrenceDayOfWeek}
        onChange={setRecurrenceDetails}
      />
      {
        (recurrenceDayOfWeek === todayDay())
        && <div className="ml-2">starting next week.</div>
      }
    </div>
  )
}

WeeklyRecurrenceDetails.propTypes = {
  recurrenceDayOfWeek: types.string.isRequired,
  setRecurrenceDayOfWeek: types.func.isRequired,
  setStartDate: types.func.isRequired,
}
export default WeeklyRecurrenceDetails
