import React from "react"
import types from "prop-types"
import { FormsyTextInput } from "shared/inputs"

const UserLastName = ({ user }) => (
  <div className="ml-3">
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label>Last Name</label>
    <FormsyTextInput
      className="mb-3 mt-1 bordered-base-input text-sm"
      name="last_name"
      placeholder="Last Name"
      value={user.last_name || ""}
      required
      validations={{ isNotBlankString: true }}
      validationErrors={{ isDefaultRequiredValue: "required" }}
    />
  </div>
)

UserLastName.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: types.object.isRequired,
}

export default UserLastName
