import { BroadcastChannel } from "broadcast-channel"
import { ACTIVITY_HUB_DASHBOARD_PATH } from "utils/routeHelpers"

export const IMPERSONATION = "impersonation"
export const START_IMPERSONATION = "start_impersonation"
export const END_IMPERSONATION = "end_impersonation"

const impersonationChannel = new BroadcastChannel(IMPERSONATION)

export const allTabImpersonationsListener = () => {
  impersonationChannel.onmessage = () => {
    window.location.pathname = ACTIVITY_HUB_DASHBOARD_PATH
    impersonationChannel.close()
  }
}
