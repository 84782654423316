import React, { useCallback, useEffect } from "react"
import { useSolutionLevelReportExportContext } from "contexts/SolutionLevelReportExportContext"
import { useModal } from "hooks"
import Modal, { DEFAULT_MODAL_STYLE, ModalHeader } from "shared/Modal"
import { errorToast } from "shared/toast"
import ReportExportProcessingCheck from "views/FormSubmissionReport/ReportElements/ExportReport/ReportExportProcessingCheck"

const REPORT_EXPORT_ERROR_MESSAGE = "Something went wrong.  Exporting report failed."

const MODAL_STYLE = {
  content: {
    ...DEFAULT_MODAL_STYLE.content,
    top: "35%",
    overflow: "visible",
  },
}

const NewSolutionLevelReportExportProcessingCheck = () => {
  const { modalIsOpen, openModal, closeModal } = useModal()

  const { reportExport, setReportExport } = useSolutionLevelReportExportContext()
  const { id: reportExportId, path, processingStatus } = reportExport

  const close = useCallback(() => {
    setReportExport({})
    closeModal()
  }, [closeModal, setReportExport])

  useEffect(() => {
    if (reportExportId) openModal()
  }, [reportExportId])

  useEffect(() => {
    if (processingStatus === "failed") {
      errorToast(REPORT_EXPORT_ERROR_MESSAGE)
      close()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processingStatus])

  useEffect(() => {
    if (path) {
      window.open(path, "_self")
      close()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path])

  return (
    <Modal isOpen={modalIsOpen} style={MODAL_STYLE}>
      <ModalHeader
        closeModal={close}
        heading="Export"
      />
      {
        reportExportId && (
          <ReportExportProcessingCheck
            reportExportId={reportExportId}
            setReportExport={setReportExport}
            onError={close}
          />
        )
      }
    </Modal>
  )
}

export default NewSolutionLevelReportExportProcessingCheck
