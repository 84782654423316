import React from "react"
import { useTaskFormContext } from "contexts/TaskFormContext"
import { FormsyHiddenInput } from "shared/inputs"

const TaskNotifyOnCompleteGroupIds = () => {
  const { taskNotifyOnCompleteGroupIds } = useTaskFormContext()

  return (
    <FormsyHiddenInput name="task.notify_on_complete_group_ids" value={taskNotifyOnCompleteGroupIds} />
  )
}

export default TaskNotifyOnCompleteGroupIds
