import React from "react"
import { RadioButton } from "shared/inputs"
import { useSelector } from "react-redux"
import { store } from "store"
import { getFormSubmissionTable, setSelectedGrouping } from "reduxSlices/formSubmissionTableSlice"
import { SidebarCollapsibleContent } from "shared/Sidebar"
import { GROUPABLE_COLUMNS } from "../submissionGroupingHelper"

const SubmissionGroupBy = () => {
  const { groupBy } = useSelector(getFormSubmissionTable)
  const groupByColumnSelect = (column) => () => store.dispatch(setSelectedGrouping(column))

  return (
    <SidebarCollapsibleContent title="Group By">
      {
        Object.entries(GROUPABLE_COLUMNS).map(([, { label, value }]) => (
          <div
            key={value}
            className="mb-2 rounded-md cursor-pointer p-2 border border-lightgray-3 text-sm font-medium"
            onClickCapture={groupByColumnSelect(value)}
          >
            <RadioButton
              className="flex-row-reverse justify-between"
              label={label}
              value={value}
              onChange={groupByColumnSelect(value)}
              checked={value === groupBy}
            />
          </div>
        ))
      }
    </SidebarCollapsibleContent>
  )
}

export default SubmissionGroupBy
