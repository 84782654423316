import React from "react"
import types from "prop-types"
import { useFormSubmissionAttachmentDropzoneContext } from "contexts/FormSubmissionAttachmentDropzoneContext"

const RejectedFileList = ({ className = "" }) => {
  const { rejectedFiles } = useFormSubmissionAttachmentDropzoneContext()

  return (
    <div className={className}>
      {
        rejectedFiles.map((rejectedFile) => {
          const { filename, errorMessages, uuid } = rejectedFile

          return (
            <div key={uuid} className="attachment-upload error">
              <div className="progress-bar w-full" />
              <div className="w-full flex gap-4 justify-between items-center">
                <span className="grow truncate">
                  {filename}
                </span>
                <span className="shrink-0 text-xs font-semibold text-error">
                  {errorMessages.join(", ")}
                </span>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

RejectedFileList.propTypes = {
  className: types.string,
}

export default RejectedFileList
