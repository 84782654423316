import React from "react"
import types from "prop-types"
import { useSurveyBatchContext } from "contexts/SurveyBatchContext"
import * as API from "services/api"
import { CustomHeaderAccordion, useAccordion } from "shared/accordions"
import { RemoveButton } from "shared/buttons"
import { ChevronDown, ChevronUp } from "shared/icons"
import { errorToast, successToast } from "shared/toast"
import SurveyTokenHeader from "./SurveyTokenHeader"
import SurveyTokenPublicStatus from "./SurveyTokenPublicStatus"
import SurveyCopyLink from "./SurveyCopyLink"

const DELETE_CONFIRMATION_MESSAGE = "This contact (and all of their survey submissions) will be deleted. \n\nTo prevent this contact from receiving future surveys, delete them from the configuration."

const SurveyToken = ({ surveyToken }) => {
  const { surveyBatch, removeSurveyToken } = useSurveyBatchContext()
  const { id: surveyBatchId } = surveyBatch
  const [isExpanded, toggleAccordion] = useAccordion(false)

  const removeSurveyTokenFromBatch = async () => {
    // eslint-disable-next-line no-alert
    if (!window.confirm(DELETE_CONFIRMATION_MESSAGE)) return

    const surveyTokenId = surveyToken.id

    const response = await API.destroySurveyBatchContact({ surveyBatchId, surveyTokenId })

    if (response.ok) {
      successToast("Contact removed")
      removeSurveyToken(surveyTokenId)
    } else {
      errorToast("Something went wrong. Unable to remove contact.")
    }
  }

  const Chevron = isExpanded ? ChevronDown : ChevronUp

  return (
    <div className="list-row py-3 bg-primary-light block">
      <CustomHeaderAccordion
        isExpanded={isExpanded}
        header={(
          <div className="flex relative flex-center">
            <SurveyTokenHeader contact={surveyToken.contact} />
            <SurveyTokenPublicStatus
              className="w-[6rem] shrink-0"
              formSubmissionSlug={surveyToken.formSubmissionSlug}
              publicStatus={surveyToken.publicStatus}
            />
            <div className="rounded bg-light-100 mx-2 p-1 w-6">
              <Chevron
                className="h-4 w-4 cursor-pointer"
                onClick={toggleAccordion}
              />
            </div>
            <div className="ml-5 -mr-10">
              <RemoveButton onClick={removeSurveyTokenFromBatch} />
            </div>
          </div>
        )}
        content={(
          <div className="flex text-left w-full">
            <div className="my-3 font-normal w-full">
              <div className="mb-3">
                <div className="font-semibold">
                  Comment
                </div>
                <span>
                  {surveyToken.comment}
                </span>
              </div>
              <div className="mb-3">
                <div className="font-semibold">
                  Reminder cc
                </div>
                <div className="break-all">
                  {surveyToken.reminderCcEmail}
                </div>
              </div>
              <div className="mb-3">
                <div className="font-semibold">
                  Survey Link
                </div>
                <SurveyCopyLink url={surveyToken.url} />
              </div>
            </div>
          </div>
        )}
      />
    </div>
  )
}

SurveyToken.propTypes = {
  surveyToken: types.object.isRequired,
}

export default SurveyToken
