import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getFormTitle, setFormTitle } from "reduxSlices/formBuilderSlice"
import FormsyValidation from "shared/FormsyValidation"
import { TextInput } from "shared/inputs"

const FIELD_PATH = "title"

const FormTitle = () => {
  const dispatch = useDispatch()
  const value = useSelector(getFormTitle)

  const updateTitle = (newValue) => dispatch(setFormTitle(newValue))

  return (
    <>
      <TextInput
        placeholder="Form name..."
        className="!text-5xl tracking-tighter"
        value={value}
        onChange={updateTitle}
      />
      <FormsyValidation
        name={FIELD_PATH}
        value={value}
        validations={{ isNotBlankString: true }}
        validationErrors={{ isDefaultRequiredValue: "required" }}
      />
    </>
  )
}

export default FormTitle
