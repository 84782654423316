import React from "react"
import types from "prop-types"
import { dayjsOrgTz } from "utils/dateHelpers"

const DateDataElement = ({ value = "" }) => {
  const date = dayjsOrgTz(value)

  return (
    <span>
      {
        date.isValid()
          ? date.format("M/D/YY")
          : ""
      }
    </span>
  )
}

DateDataElement.propTypes = {
  value: types.string,
}

export default DateDataElement
