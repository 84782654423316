import React, { useState } from "react"
import types from "prop-types"
import * as API from "services/api"
import { RemoveButton, PrimaryButton, Button } from "shared/buttons"
import { TextInput } from "shared/inputs"
import Modal, { DEFAULT_MODAL_STYLE } from "shared/Modal"
import { errorToast, successToast } from "shared/toast"

const MODAL_STYLE = {
  content: {
    ...DEFAULT_MODAL_STYLE.content,
    minWidth: "650px",
    maxWidth: "1000px",
    maxHeight: "100vh",
    padding: "1em",
    overflow: "auto",
  },
}

const RESET_SUCCESS_MESSAGE = "has been reset."

const RESET_ERROR_MESSAGE = "Unable to reset solution"

const CategoryResetModal = ({
  closeForm, isOpen, category, subdomain, onResetCategory,
}) => {
  const { name, id } = category

  const [otp, setOtp] = useState("")

  const onSubmit = async () => {
    const categoryId = id

    const response = await API.resetCategory({ categoryId, subdomain, otp })

    if (response.ok) {
      successToast(`${category.name} ${RESET_SUCCESS_MESSAGE}`)
      onResetCategory(category)
    } else {
      const errorsString = response.data.errors.join(", ")
      errorToast(`${RESET_ERROR_MESSAGE}: ${errorsString}`)
    }
  }

  const closeModal = () => {
    setOtp("")
    closeForm()
  }

  return (
    <Modal isOpen={isOpen} style={MODAL_STYLE}>
      <RemoveButton
        className="absolute right-4"
        onClick={closeModal}
      />
      <div className="text-xl text-center mt-8">
        Confirm Solution Reset:
        <div className="font-semibold">{name}</div>
      </div>
      <div className="text-center my-6 w-3/4 mx-auto">
        Enter the One-Time Passcode generated by your authenticator app to confirm reset.
      </div>
      <div />
      <div className="w-3/4 mx-auto">
        <TextInput
          className="bordered-base-input"
          id="otp"
          name="otp"
          placeholder="One-Time Passcode"
          value={otp}
          onChange={setOtp}
        />
        <div className="text-center mt-6 w-3/4 mx-auto text-red text-sm">
          This action will permanently delete all existing form submissions and tasks for this solution.
        </div>
        <div className="flex justify-center mt-4 mb-10">
          <Button
            text="Cancel"
            onClick={closeModal}
            className="border border-light-300 mr-2"
          />
          <PrimaryButton
            text="Clear Solution"
            type="submit"
            onClick={onSubmit}
          />
        </div>
      </div>
    </Modal>
  )
}

CategoryResetModal.propTypes = {
  closeForm: types.func.isRequired,
  isOpen: types.bool.isRequired,
  category: types.object.isRequired,
  onResetCategory: types.func.isRequired,
  subdomain: types.string.isRequired,
}

export default CategoryResetModal
