import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import IconContainer from "./IconContainer"

const TaskGeneralIcon = ({ className = "w-8 h-8" }) => (
  <IconContainer className={className}>
    <SvgSpriteIcon iconName="task-general" className="h-4/5 w-4/5" />
  </IconContainer>
)

TaskGeneralIcon.propTypes = {
  className: types.string,
}

export default TaskGeneralIcon
