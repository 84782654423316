import React from "react"
import types from "prop-types"
import { sortBy } from "lodash-es"
import { useSelector } from "react-redux"
import { getGroups } from "reduxSlices/formBuilderSlice"
import { useTaskFormContext } from "contexts/TaskFormContext"
/* eslint-disable max-len */
import TaskGroupList from "views/FormSubmission/FormSubmissionTabs/ActivitiesContent/TaskForms/TaskFields/UI/TaskGroupList"
/* eslint-enable max-len */

const TaskAssigneeGroupList = ({ className = "" }) => {
  const groups = useSelector(getGroups)

  const { removeAssigneeGroupId, taskAssigneeGroupIds } = useTaskFormContext()

  if (taskAssigneeGroupIds.length === 0) return null

  const assignedGroups = sortBy(
    taskAssigneeGroupIds.map((assignedGroupId) => (
      groups.find((group) => group.id === assignedGroupId)
    )),
    ["name"],
  )

  return (
    <div className={className}>
      <TaskGroupList className="mb-2" removeGroup={removeAssigneeGroupId} groups={assignedGroups} />
      <p className="text-xs text-dark">
        Each assignee group will have each of its members assigned to the task individually.
        Each group member will also be granted individual access to the form submission.
      </p>
    </div>
  )
}

TaskAssigneeGroupList.propTypes = {
  className: types.string,
}

export default TaskAssigneeGroupList
