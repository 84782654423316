import React from "react"
import types from "prop-types"
import pluralize from "pluralize"

const goToGroupShow = (group) => () => {
  window.location.pathname = `/admin/groups/${group.id}`
}

const GroupInfo = ({ group }) => (
  <div
    className="cursor-pointer flex-center flex-grow"
    onClickCapture={goToGroupShow(group)}
  >
    <span className="font-bold">{ group.name }</span>
    <span className="text-dark">
      { pluralize("Member", group.members.length, true) }
    </span>
  </div>
)

GroupInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  group: types.object.isRequired,
}

export default GroupInfo
