import React from "react"
import { surveyConfigurationsForTileGridShape } from "utils/propTypeShapes"
import SurveyConfigurationTile from "./SurveyConfigurationTile"

const SurveyConfigurationTileGrid = ({ surveyConfigurations }) => (
  <div className="grid gap-5 grid-cols-4-tile auto-rows-fr">
    {
      surveyConfigurations.map((surveyConfiguration) => (
        <SurveyConfigurationTile
          key={surveyConfiguration.id}
          surveyConfiguration={surveyConfiguration}
        />
      ))
    }
  </div>
)

SurveyConfigurationTileGrid.propTypes = {
  surveyConfigurations: surveyConfigurationsForTileGridShape.isRequired,
}

export default SurveyConfigurationTileGrid
