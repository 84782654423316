import React from "react"
import types from "prop-types"
import * as NumberCalcAttributes from "./NumberCalcAttributes"
import QuestionShowDescription from "./QuestionShowDescription"

const NumberCalcInputConfig = ({ sectionIndex, questionIndex }) => (
  <>
    <QuestionShowDescription
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <NumberCalcAttributes.Precision
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <NumberCalcAttributes.Operator
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <NumberCalcAttributes.Questions
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
  </>
)

NumberCalcInputConfig.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default NumberCalcInputConfig
