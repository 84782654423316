import { useState } from "react"

function useSubmissionDateFilter({
  earliestSubmissionDate: initialEarliestSubmissionDate = "",
  latestSubmissionDate: initialLatestSubmissionDate = "",
} = {}) {
  const [earliestSubmissionDate, setEarliestSubmissionDate] = useState(initialEarliestSubmissionDate)
  const [latestSubmissionDate, setLatestSubmissionDate] = useState(initialLatestSubmissionDate)

  return {
    earliestSubmissionDate,
    latestSubmissionDate,
    setEarliestSubmissionDate,
    setLatestSubmissionDate,
  }
}

export default useSubmissionDateFilter
