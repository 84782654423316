import React, { useEffect } from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getActiveOrMatchingFacilities } from "reduxSlices/formBuilderSlice"
import InactiveOptionsItemRenderer from "shared/selects/InactiveOptionsItemRenderer"
import { ListSelect } from "shared/selects"

const facilityOptions = (facilities) => facilities.map((facility) => (
  { label: facility.name, value: facility.id, active: facility.active }
))

const LocationIsFacilityMatchValueInput = ({
  className = "",
  isFullWidth,
  onChange,
  value = null,
}) => {
  const facilities = useSelector(getActiveOrMatchingFacilities(value))

  useEffect(() => {
    if (!value && facilities.length !== 0) {
      onChange(facilities[0].id)
    }
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [value, facilities])

  return (
    <ListSelect
      backspaceDelete={false}
      className={className}
      isFullWidth={isFullWidth}
      itemRenderer={InactiveOptionsItemRenderer}
      onChange={onChange}
      options={facilities}
      optionsFromList={facilityOptions}
      required
      value={value}
    />
  )
}

LocationIsFacilityMatchValueInput.propTypes = {
  className: types.string,
  isFullWidth: types.bool.isRequired,
  onChange: types.func.isRequired,
  value: types.number,
}

export default LocationIsFacilityMatchValueInput
