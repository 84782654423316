import React, { useEffect, useState } from "react"
import types from "prop-types"
import { isEmpty, sortBy } from "lodash-es"
import { useDispatch, useSelector } from "react-redux"
import { category, formSubmissionData, updateFormSubmissionAnswers } from "reduxSlices/formSubmissionSlice"
import { useFormSubmissionFormContext } from "contexts/FormSubmissionFormContext"
import * as API from "services/api"
import { reportableFieldQuestions } from "utils/formDataHelpers"
import { PrimaryButton, TertiaryButton } from "shared/buttons"
import { errorToast } from "shared/toast"
import {
  MULTI_SELECT_TYPE,
  NOTE_ANSWER_TYPE,
  NUMBER_CALCULATION_TYPE,
  SELECT_CSV_TYPE,
  SELECT_TYPE,
  TIME_DIFFERENCE_TYPE,
} from "views/Forms/FormElementTypes"
import RetainedAnswersOverview from "./RetainedAnswersOverview"
import SubmissionFormTypeSelector from "./SubmissionFormTypeSelector"

const CONFIRM_CHANGE_MESSAGE = "Are you sure you want to change the form type?  Any unretained answers will be lost."

const canRetainAnswer = ({ answer, newFormQuestion }) => {
  if (isEmpty(answer) && typeof answer !== "number") return false

  switch (newFormQuestion.type) {
  case NUMBER_CALCULATION_TYPE:
  case TIME_DIFFERENCE_TYPE:
  case NOTE_ANSWER_TYPE:
    return false
  case SELECT_TYPE:
  case SELECT_CSV_TYPE:
    return newFormQuestion.attributes.options.includes(answer)
  case MULTI_SELECT_TYPE:
    return answer.every((selectedOption) => newFormQuestion.attributes.options.includes(selectedOption))
  default:
    return true
  }
}

const convertReportableFieldAnswersToNewFormAnswers = ({ newForm, originalAnswers, originalForm }) => {
  const newFormReportableFieldQuestions = reportableFieldQuestions(newForm)
  const originalFormReportableFieldQuestions = reportableFieldQuestions(originalForm)

  return originalFormReportableFieldQuestions.reduce((newAnswers, originalFormQuestion) => {
    const answer = originalAnswers[originalFormQuestion.uuid]

    if (!answer && answer !== 0) return newAnswers

    const matchingNewFormQuestion = newFormReportableFieldQuestions.find((newFormQuestion) => (
      newFormQuestion.reportableFieldId === originalFormQuestion.reportableFieldId
    ))

    if (!matchingNewFormQuestion) return newAnswers
    if (!canRetainAnswer({ answer, newFormQuestion: matchingNewFormQuestion })) return newAnswers

    newAnswers[matchingNewFormQuestion.uuid] = answer

    return newAnswers
  }, {})
}

const SubmissionFormTypeConverter = ({ onCancel, onFormTypeChange }) => {
  const dispatch = useDispatch()

  const [publicForms, setPublicForms] = useState([])
  const [selectedFormSlug, setSelectedFormSlug] = useState()
  const [newForm, setNewForm] = useState()
  const [newAnswers, setNewAnswers] = useState({})

  const { slug: categorySlug } = useSelector(category)
  const { answers: originalAnswers, slug: formSubmissionSlug } = useSelector(formSubmissionData)

  const {
    currentFormVersion: originalForm,
    form,
    setCurrentFormVersion,
    setForm,
    setSubmittedFormVersion,
  } = useFormSubmissionFormContext()
  const { slug: originalFormSlug } = form

  // If categorySlug or originalFormSlug changes,
  // update the list of public forms the user may choose.
  useEffect(() => {
    const updatePublicForms = async () => {
      const response = await API.categoryPublicForms({ categorySlug })

      if (response.ok) {
        setPublicForms(
          sortBy(response.data.filter((publicForm) => publicForm.slug !== originalFormSlug), ["title"]),
        )
      } else {
        errorToast("Something went wrong.  Unable to load forms.")
      }
    }

    updatePublicForms()
  }, [categorySlug, originalFormSlug])

  // If selectedFormSlug changes,
  // update the new form to be the form that the user selected.
  useEffect(() => {
    const updateNewForm = async () => {
      const response = await API.formForFormSubmission({ formSlug: selectedFormSlug })

      if (response.ok) {
        setNewAnswers({})
        setNewForm(response.data)
      } else {
        errorToast("Something went wrong.  Unable to load selected form.")
      }
    }

    if (!selectedFormSlug) {
      setNewForm(null)
    } else {
      updateNewForm()
    }
  }, [selectedFormSlug])

  // If newForm changes, update newAnswers
  useEffect(() => {
    let updatedAnswers = {}

    if (newForm) {
      updatedAnswers = convertReportableFieldAnswersToNewFormAnswers({
        newForm,
        originalAnswers,
        originalForm,
      })
    }

    setNewAnswers(updatedAnswers)
  }, [newForm, originalAnswers, originalForm])

  const confirmNewForm = async () => {
    // eslint-disable-next-line no-alert
    if (window.confirm(CONFIRM_CHANGE_MESSAGE)) {
      const response = await API.changeFormSubmissionFormType({
        formSubmissionSlug,
        newAnswers,
        newFormSlug: selectedFormSlug,
      })

      if (response.ok) {
        const newFormVersion = {
          id: newForm.currentVersionId,
          sections: newForm.sections,
        }

        // If the form type is successfully changed,
        // update the form submission form context ...
        setForm(newForm)
        setCurrentFormVersion(newFormVersion)
        setSubmittedFormVersion(newFormVersion)

        // ... and update the form submission answers in redux
        dispatch(updateFormSubmissionAnswers(newAnswers))

        onFormTypeChange()
      } else {
        errorToast("Something went wrong.  Unable to change form type.")
      }
    }
  }

  return (
    <>
      <div className="mb-8 w-full flex flex-col items-center">
        <SubmissionFormTypeSelector
          onChange={setSelectedFormSlug}
          publicForms={publicForms}
          value={selectedFormSlug}
        />
      </div>
      {
        newForm && (
          <RetainedAnswersOverview
            answers={newAnswers}
            className="mb-8"
            questions={reportableFieldQuestions(newForm)}
          />
        )
      }
      <div className="flex flex-col items-center">
        <div className="flex">
          <TertiaryButton className="mr-4" onClick={onCancel} text="Cancel" />
          <PrimaryButton disabled={!newForm} onClick={confirmNewForm} text="Change Form Type" />
        </div>
      </div>
    </>
  )
}

SubmissionFormTypeConverter.propTypes = {
  onCancel: types.func.isRequired,
  onFormTypeChange: types.func.isRequired,
}

export default SubmissionFormTypeConverter
