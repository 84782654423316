import React, { useCallback, useState } from "react"
import types from "prop-types"
import { useModal } from "hooks"
import Modal, { ModalHeader } from "shared/Modal"
import { PrimaryButton, TertiaryButton } from "shared/buttons"
import {
  redirectTo,
} from "utils/routeHelpers"
import {
  DAILY, WEEKLY, MONTHLY, RECURRENCE_OPTIONS,
} from "utils/recurrenceDetailsHelpers"
import { ListSelect } from "shared/selects"
import { TextInput } from "shared/inputs"

const EMPTY_NAME = ""
const REPORT_SETTING_TYPE_DESCRIPTIONS = {
  [DAILY]: `Reports will be uploaded ${DAILY}, containing submissions from a date range of up to two weeks.`,
  [WEEKLY]: `Reports will be uploaded ${WEEKLY}, containing submissions from a date range of up to ten weeks.`,
  [MONTHLY]: `Reports will be uploaded ${MONTHLY}, containing submissions from a date range of up to twelve months.`,
}
const NewScheduledReportExportModal = ({ report, newScheduledReportExportPath }) => {
  const { modalIsOpen, openModal, closeModal } = useModal()
  const [name, setName] = useState(EMPTY_NAME)
  const [frequency, setFrequency] = useState(DAILY)

  const { id } = report

  const close = useCallback(() => {
    closeModal()
    setName(EMPTY_NAME)
  }, [closeModal, setName])

  const goToNewScheduleReportExport = () => {
    const path = newScheduledReportExportPath(id)

    redirectTo(path, { name, frequency })
  }

  return (
    <div className="flex justify-start">
      <TertiaryButton text="Schedule SFTP Upload" onClick={openModal} />
      <Modal isOpen={modalIsOpen}>
        <ModalHeader
          closeModal={close}
          heading="Scheduled Report Uploads"
        />
        <div className="required-prompt mb-2">Name</div>
        <TextInput
          placeholder="Name this scheduled report export..."
          className="rounded-lg bordered-base-input"
          name="name"
          value={name}
          onChange={setName}
        />
        <div className="flex flex-wrap items-center my-4">
          <span className="block mr-2 required-prompt">Upload reports</span>
          <ListSelect
            className="mr-2"
            required
            name="export_frequency"
            options={RECURRENCE_OPTIONS}
            value={frequency}
            onChange={setFrequency}
          />
        </div>
        <p className="text-xs text-dark">
          {REPORT_SETTING_TYPE_DESCRIPTIONS[frequency]}
        </p>
        <div className="flex justify-center mt-8 mb-10 gap-2">
          <TertiaryButton
            text="Cancel"
            onClick={close}
          />
          <PrimaryButton
            text="Create"
            type="submit"
            disabled={!name}
            onClick={goToNewScheduleReportExport}
          />
        </div>
      </Modal>
    </div>
  )
}

NewScheduledReportExportModal.propTypes = {
  report: types.objectOf(types.any).isRequired,
  newScheduledReportExportPath: types.func.isRequired,
}

export default NewScheduledReportExportModal
