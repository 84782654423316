import React from "react"
import types from "prop-types"
import { Button } from "components/shared/buttons"

const AddRelatedFormSubmissionButton = ({ onClick }) => (
  <Button
    className="border border-light-300 text-focus"
    onClick={onClick}
    text="+ Add Related Form Submission"
  />
)

AddRelatedFormSubmissionButton.propTypes = {
  onClick: types.func.isRequired,
}

export default AddRelatedFormSubmissionButton
