import React, { useState, useEffect } from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { errorToast, successToast } from "shared/toast"
import * as API from "services/api"
import * as StatusListHelpers from "utils/statusListHelpers"
import { addKeys, keyless } from "utils/keyHelpers"
import AddStatusButton from "./AddStatusButton"
import DiscardChangesButton from "./DiscardChangesButton"
import SaveStatusListButton from "./SaveStatusListButton"
import SubmissionStatus from "./SubmissionStatus"
import DraggableSubmissionStatus from "./DraggableSubmissionStatus"

const StatusList = ({ categorySlug }) => {
  const [savedStatuses, setSavedStatuses] = useState([])
  const [statuses, setStatuses] = useState([])
  const [statusesToDiscard, setStatusesToDiscard] = useState([])

  const activeStatuses = statuses?.filter((status) => !status.discarded_at)

  const updateStateWithSavedStatuses = (submissionStatuses) => {
    setSavedStatuses(submissionStatuses)
    setStatuses(addKeys(submissionStatuses))
    setStatusesToDiscard([])
  }

  useEffect(() => {
    const loadStatusList = async () => {
      const response = await API.statusList({ categorySlug })

      if (response.ok) {
        const { data: { submissionStatuses } } = response

        updateStateWithSavedStatuses(submissionStatuses)
      } else {
        console.error("Error fetching status list: ", response.data)
      }
    }

    loadStatusList()
  }, [categorySlug])

  const rename = (statusIndex) => (newName) => setStatuses(
    StatusListHelpers.renameStatus({ statuses, statusIndex, newName }),
  )

  const discard = (statusIndex) => () => {
    setStatusesToDiscard([...statusesToDiscard, statuses[statusIndex]])
    setStatuses(
      StatusListHelpers.discardStatus({ statuses, statusIndex }),
    )
  }

  const add = () => setStatuses(
    addKeys(StatusListHelpers.addStatus({ statuses })),
  )

  const reorder = ({ source: from, destination: to }) => {
    if (!(from && to)) return

    const { index: fromIndex } = from
    const { index: toIndex } = to

    setStatuses(StatusListHelpers.reorderStatuses({ statuses, fromIndex, toIndex }))
  }

  const saveChanges = async () => {
    if (!StatusListHelpers.allStatusesValid(statuses)) return

    const response = await API.updateStatuses({ categorySlug, statuses, statusesToDiscard })

    if (!response.ok) {
      errorToast("Something went wrong. Unable to update status. ", response)
      return
    }

    const { data: { submissionStatuses } } = response

    updateStateWithSavedStatuses(submissionStatuses)
    successToast("Statuses have been updated")
  }

  const discardChanges = () => {
    setStatuses(addKeys(savedStatuses))
    setStatusesToDiscard([])
  }

  const changesPersisted = () => isEqual(keyless(statuses), savedStatuses)

  if (!statuses.length) return null

  const open = StatusListHelpers.openStatus({ statuses })
  const closed = StatusListHelpers.closedStatus({ statuses })

  return (
    <>
      <SubmissionStatus
        savedStatus={StatusListHelpers.openStatus({ statuses: savedStatuses })}
        status={open}
        updateName={rename(statuses.indexOf(open))}
      />
      <DragDropContext onDragEnd={reorder}>
        <Droppable droppableId="submission-status-list">
          {
            (dropProvided) => (
              <div
                className="flex flex-col"
                ref={dropProvided.innerRef}
                {...dropProvided.droppableProps}
              >
                {
                  StatusListHelpers.reorderableStatuses({ statuses: activeStatuses }).map((status) => {
                    const savedStatus = savedStatuses.find((submissionStatus) => (
                      submissionStatus.id === status.id
                    ))
                    const indexInStatuses = statuses.indexOf(status)
                    return (
                      <DraggableSubmissionStatus
                        key={status.key}
                        index={indexInStatuses}
                        savedStatus={savedStatus}
                        status={status}
                        updateName={rename(indexInStatuses)}
                        discardStatus={discard(indexInStatuses)}
                        categorySlug={categorySlug}
                      />
                    )
                  })
                }
                {dropProvided.placeholder}
              </div>
            )
          }
        </Droppable>
      </DragDropContext>
      <SubmissionStatus
        savedStatus={StatusListHelpers.closedStatus({ statuses: savedStatuses })}
        status={closed}
        updateName={rename(statuses.indexOf(closed))}
      />
      <AddStatusButton addStatus={add} className="mb-4" />
      {
        !changesPersisted() && (
          <div>
            <SaveStatusListButton
              disabled={!StatusListHelpers.allStatusesValid(statuses)}
              saveStatusList={saveChanges}
              className="mr-4"
            />
            <DiscardChangesButton discardChanges={discardChanges} />
          </div>
        )
      }
    </>
  )
}

StatusList.propTypes = {
  categorySlug: types.string.isRequired,
}

export default StatusList
