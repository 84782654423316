import React from "react"
import types from "prop-types"
import ItemRow from "shared/ItemRow"
import CircularIconButton from "shared/buttons/CircularIconButton"
import GroupSearchGroupMemberList from "shared/searches/SearchSelectedGroup/GroupSearchGroupMemberList"
import AccessLevelIdentifier from "./AccessLevelIdentifier"

const GroupGranteeList = ({
  className = "", groups, formSubmissionSlug, updateAccessGranteeLists, deleteFormSubmissionGrant, isDisabled,
}) => {
  if (!groups.length) {
    return <span>No Group with Grants</span>
  }

  return (
    <div className={className}>
      {
        groups.map(({
          grantee: group, formGrant, categoryGrant, formSubmissionGrant,
        }) => {
          const grantIsDeletable = !isDisabled && !!formSubmissionGrant

          return (
            <ItemRow
              key={`group-item-row-${group.id}`}
              collapsable
              itemRowHeaderContentClassName="item-row-header-content-submission-access"
              itemRowHeaderContent={(
                <>
                  <span className="font-semibold">{group.name}</span>
                  <AccessLevelIdentifier
                    className={
                      grantIsDeletable
                        ? "item-row-header-content-breaker-double"
                        : "item-row-header-content-breaker"
                    }
                    formSubmissionSlug={formSubmissionSlug}
                    formSubmissionGrant={formSubmissionGrant}
                    formGrant={formGrant}
                    categoryGrant={categoryGrant}
                    updateAccessGranteeLists={updateAccessGranteeLists}
                    granteeType="Group"
                    granteeId={group.id}
                    isDisabled={isDisabled}
                  />
                </>
              )}
              itemRowHeaderControls={(
                <>
                  {
                    grantIsDeletable && (
                      <CircularIconButton icon="close" onClick={deleteFormSubmissionGrant(formSubmissionGrant?.id)} />
                    )
                  }
                </>
              )}
              itemRowBody={
                <GroupSearchGroupMemberList members={group.members} />
              }
            />
          )
        })
      }
    </div>
  )
}

GroupGranteeList.propTypes = {
  className: types.string,
  groups: types.arrayOf(types.object).isRequired,
  formSubmissionSlug: types.string.isRequired,
  updateAccessGranteeLists: types.func.isRequired,
  deleteFormSubmissionGrant: types.func.isRequired,
  isDisabled: types.bool.isRequired,
}

export default GroupGranteeList
