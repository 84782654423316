import React from "react"
import types from "prop-types"

const TextDataElement = ({ className = "", value = "" }) => (
  <span className={className}>{ value }</span>
)

TextDataElement.propTypes = {
  value: types.string,
  className: types.string,
}

export default TextDataElement
