import React from "react"
import Formsy from "formsy-react"
import types from "prop-types"
import { useSubmit } from "hooks"
import * as API from "services/api"
import { FormsyTextInput } from "shared/inputs"
import { PrimaryButton } from "shared/buttons"
import { errorToast, successToast } from "shared/toast"

const USER_UPDATE_SUCCESS_MESSAGE = "User updated successfully!"

const USER_UPDATE_ERROR_MESSAGE = "Something went wrong.  Unable to update user."

const EditForm = ({ user, setUser }) => {
  const { canSubmit, enableSubmit, disableSubmit } = useSubmit()

  const updateUser = async (userAttrs) => {
    const response = await API.updateUser({ userId: user.id, user: userAttrs })
    if (response.ok) {
      setUser({ ...user, ...userAttrs })
      successToast(USER_UPDATE_SUCCESS_MESSAGE)
    } else {
      console.error("Error updating user: ", response)
      errorToast(USER_UPDATE_ERROR_MESSAGE)
    }
  }

  return (
    <Formsy
      onSubmit={updateUser}
      onValid={enableSubmit}
      onInvalid={disableSubmit}
      preventDefaultSubmit
    >
      <div className="flex-center gap-x-6">
        <div className="flex-grow">
          <label htmlFor="user-first_name-input">First Name</label>
          <FormsyTextInput
            id="user-first_name-input"
            className="bordered-base-input text-sm mb-4"
            name="first_name"
            placeholder="First Name..."
            value={user.first_name}
            required
            validations={{ isNotBlankString: true }}
            validationErrors={{ isDefaultRequiredValue: "required" }}
          />
        </div>

        <div className="flex-grow">
          <label htmlFor="user-last_name-input">Last Name</label>
          <FormsyTextInput
            id="user-last_name-input"
            className="bordered-base-input text-sm mb-4"
            name="last_name"
            placeholder="Last Name..."
            value={user.last_name}
            required
            validations={{ isNotBlankString: true }}
            validationErrors={{ isDefaultRequiredValue: "required" }}
          />
        </div>
      </div>

      <label htmlFor="user-email-input">Email</label>
      <FormsyTextInput
        id="user-email-input"
        className="bordered-base-input text-sm mb-4"
        name="email"
        placeholder="Email..."
        value={user.email}
        required
        validations={{ isNotBlankString: true, isEmail: true }}
        validationErrors={{ isDefaultRequiredValue: "required", isEmail: "Invalid email address" }}
      />
      <PrimaryButton
        className="absolute-horizontal-center bottom-16"
        text="Save changes"
        type="submit"
        disabled={!canSubmit}
      />
    </Formsy>
  )
}

EditForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: types.object.isRequired,
  setUser: types.func.isRequired,
}

export default EditForm
