import React from "react"
import types from "prop-types"
import StrikeAround from "shared/StrikeAround"
import { Button } from "shared/buttons"
import { ACTIVITY_HUB_SEARCH_PATH, redirectTo } from "utils/routeHelpers"
import Result from "./Result"

const SearchResults = ({ results, searchQuery, hasCompletedSearch }) => {
  if (!hasCompletedSearch) return null

  const truncatedResults = results.slice(0, 3)

  const redirectToSearch = () => {
    redirectTo(ACTIVITY_HUB_SEARCH_PATH, { searchQuery })
  }

  return (
    <div>
      <StrikeAround>
        <span className="py-3 flex items-center text-dark font-medium text-sm">
          <div className="rounded-full bg-light h-6 w-6 mr-2.5 flex items-center justify-center">
            { results.length }
          </div>
          Search Results
        </span>
      </StrikeAround>

      {
        truncatedResults.map((result) => (
          <Result key={result.id} result={result} />
        ))
      }

      <div className="flex justify-center">
        <Button
          text="View All Results"
          className="border border-light-300 text-focus mt-5"
          onClick={redirectToSearch}
        />
      </div>
    </div>
  )
}

SearchResults.propTypes = {
  results: types.arrayOf(types.object).isRequired,
  searchQuery: types.string.isRequired,
  hasCompletedSearch: types.bool.isRequired,
}

export default SearchResults
