import React from "react"
import types from "prop-types"
import SelectCSVInvalidOptions from "./SelectCSVInvalidOptions"
import SelectCSVOptionsPreview from "./SelectCSVOptionsPreview"

const SelectCSVUploadOverview = ({ filename, options, invalidOverview }) => (
  invalidOverview.length === 0
    ? (
      <>
        <span className="block mb-4">{options.length} options were detected in the file <span className="font-medium">{filename}</span></span>
        <SelectCSVOptionsPreview options={options} />
      </>
    )
    : (
      <>
        <span className="block mb-4 text-error">
          The file <span className="font-medium">{filename}</span> contains invalid options.
          Correct the errors listed below and upload the file again.
        </span>
        <SelectCSVInvalidOptions invalidOverview={invalidOverview} />
      </>
    )
)

SelectCSVUploadOverview.propTypes = {
  filename: types.string.isRequired,
  options: types.arrayOf(types.string).isRequired,
  invalidOverview: types.arrayOf(types.shape({
    option: types.string.isRequired,
    errorMessages: types.arrayOf(types.string).isRequired,
  })).isRequired,
}

export default SelectCSVUploadOverview
