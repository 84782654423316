import React from "react"
import clsx from "clsx"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getGroups, getUsers } from "reduxSlices/formBuilderSlice"
import { GroupAndUserSelect } from "shared/selects"

const unselectedList = (list, selectedList) => {
  const selectedIds = selectedList.map((entry) => entry.grantee?.id || entry.id)
  return list.filter((item) => !selectedIds.includes(item.id))
}

const GroupAndUserSearch = ({
  className = "",
  onSearchSelected,
  groups = undefined,
  users = undefined,
  selectedGroups = [],
  selectedUsers = [],
}) => {
  const fetchGroups = useSelector(getGroups)
  const fetchUsers = useSelector(getUsers)

  return (
    <GroupAndUserSelect
      className={clsx(className, "py-4 text-left")}
      users={unselectedList(users || fetchUsers, selectedUsers)}
      groups={unselectedList(groups || fetchGroups, selectedGroups)}
      onChange={onSearchSelected}
    />
  )
}

GroupAndUserSearch.propTypes = {
  className: types.string,
  onSearchSelected: types.func.isRequired,
  selectedGroups: types.arrayOf(types.object),
  selectedUsers: types.arrayOf(types.object),
  groups: types.arrayOf(types.object),
  users: types.arrayOf(types.object),
}

export default GroupAndUserSearch
