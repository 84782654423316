import React from "react"
import { RadioButton } from "shared/inputs"
import { useSelector } from "react-redux"
import { store } from "store"
import { getAttachmentGrouping, setSelectedGrouping } from "reduxSlices/formSubmissionAttachmentsGroupingSlice"
import { SidebarCollapsibleContent } from "shared/Sidebar"
import { GROUPABLE_COLUMNS } from "../attachmentGroupingHelper"

const AttachmentGroupBy = () => {
  const { selectedGrouping } = useSelector(getAttachmentGrouping)
  const groupByColumnSelect = (column) => () => store.dispatch(setSelectedGrouping(column))

  return (
    <SidebarCollapsibleContent title="Group By">
      {
        Object.entries(GROUPABLE_COLUMNS).map(([, { label, value }]) => (
          <div
            key={value}
            className="p-3 my-2 bg-white rounded-md cursor-pointer"
            onClickCapture={groupByColumnSelect(value)}
          >
            <RadioButton
              className="flex-row-reverse justify-between"
              label={label}
              value={value}
              onChange={groupByColumnSelect(value)}
              checked={selectedGrouping === value}
            />
          </div>
        ))
      }
    </SidebarCollapsibleContent>
  )
}

export default AttachmentGroupBy
