import React from "react"
import types from "prop-types"
import { FormsyTextInput } from "shared/inputs"

const ReportableFieldName = ({ value = "" }) => (
  <FormsyTextInput
    name="name"
    placeholder="Name this field..."
    value={value}
    required
    className="bordered-base-input bg-white text-sm"
    validations={{ isNotBlankString: true }}
    validationErrors={{ isDefaultRequiredValue: "required" }}
  />
)

ReportableFieldName.propTypes = {
  value: types.string,
}

export default ReportableFieldName
