/* eslint-disable camelcase */
import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import * as API from "services/api"
import StrikeAround from "shared/StrikeAround"
import {
  formSubmissionSlug as getFormSubmissionSlug,
  submissionPermissions as getSubmissionPermissions,
} from "reduxSlices/formSubmissionSlice"
import ContentHeader from "shared/ContentHeader"
import GroupAndUserAndDepartmentSearch from "./GroupAndUserAndDepartmentSearch"
import GroupGranteeList from "./GroupGranteeList"
import UserGranteeList from "./UserGranteeList"
import DepartmentGranteeList from "./DepartmentGranteeList"

const SubmissionAccess = () => {
  const [groupGranteeList, setGroupGranteeList] = useState([])
  const [userGranteeList, setUserGranteeList] = useState([])
  const [departmentGranteeList, setDepartmentGranteeList] = useState([])

  const formSubmissionSlug = useSelector(getFormSubmissionSlug)

  const updateAccessGranteeLists = (data) => {
    setGroupGranteeList(data.groups)
    setUserGranteeList(data.users)
    setDepartmentGranteeList(data.departments)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    let mounted = true

    /* eslint-disable consistent-return */
    const fetchAccessGrantees = async () => {
      if (!formSubmissionSlug) return null

      const response = await API.getFormSubmissionAccessGrantList({ formSubmissionSlug })

      if (!mounted) return

      if (response.ok) {
        updateAccessGranteeLists(response.data)
      } else {
        console.error("Error fetching form submission grants: ", response)
      }
    }
    /* eslint-enable consistent-return */

    fetchAccessGrantees()

    return () => { mounted = false }
  }, [formSubmissionSlug])

  const createAccessLevelGrant = async (value) => {
    const [grantee_type, grantee_id] = value.split("-")
    const access_level_grant = { grantee_id, grantee_type }

    const response = await API.createFormSubmissionAccessGrant({
      formSubmissionSlug, access_level_grant,
    })
    if (response.ok) {
      updateAccessGranteeLists(response.data)
    } else {
      console.error("Error creating access level grant", response)
    }
  }

  const deleteFormSubmissionGrant = (grantId) => async () => {
    const response = await API.deleteFormSubmissionAccessGrant({ formSubmissionSlug, grantId })
    if (response.ok) {
      updateAccessGranteeLists(response.data)
    } else {
      console.error("Error deleting submission grant: ", response);
    }
  }

  const { canManageSubmissionGrants } = useSelector(getSubmissionPermissions)

  if (!formSubmissionSlug) return null

  return (
    <div className="relative grow">
      <div className="inner-container mx-auto max-w-screen-lg">
        <ContentHeader
          heading={
            <h1 className="header-title">Submission Access</h1>
          }
        />
        {
          canManageSubmissionGrants && (
            <GroupAndUserAndDepartmentSearch
              className="mb-8"
              selectedGroups={groupGranteeList}
              selectedUsers={userGranteeList}
              selectedDepartments={departmentGranteeList}
              onSearchSelected={createAccessLevelGrant}
            />
          )
        }
        <StrikeAround className="mb-2">
          <h3>Groups</h3>
        </StrikeAround>
        <GroupGranteeList
          className="mb-8"
          formSubmissionSlug={formSubmissionSlug}
          groups={groupGranteeList}
          deleteFormSubmissionGrant={deleteFormSubmissionGrant}
          updateAccessGranteeLists={updateAccessGranteeLists}
          isDisabled={!canManageSubmissionGrants}
        />
        <StrikeAround className="mb-2">
          <h3>Individuals</h3>
        </StrikeAround>
        <UserGranteeList
          className="mb-8"
          formSubmissionSlug={formSubmissionSlug}
          users={userGranteeList}
          deleteFormSubmissionGrant={deleteFormSubmissionGrant}
          updateAccessGranteeLists={updateAccessGranteeLists}
          isDisabled={!canManageSubmissionGrants}
        />
        <StrikeAround className="mb-2">
          <h3>Departments</h3>
        </StrikeAround>
        <DepartmentGranteeList
          formSubmissionSlug={formSubmissionSlug}
          departments={departmentGranteeList}
          deleteFormSubmissionGrant={deleteFormSubmissionGrant}
          updateAccessGranteeLists={updateAccessGranteeLists}
          isDisabled={!canManageSubmissionGrants}
        />
      </div>
    </div>
  )
}

export default SubmissionAccess
