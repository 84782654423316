/* eslint-disable max-len */
import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { ProfileIcon } from "shared/icons"

const ActionAbbreviatedUserList = ({ className = "", limit = 3, users }) => {
  const usersToList = users.slice(0, limit)
  const additionalUserCount = users.length - limit

  return (
    <div className={clsx(className, "flex flex-row")}>
      {
        usersToList.map((user) => (
          <ProfileIcon key={user.id} className="w-6 h-6 mr-2" user={user} />
        ))
      }
      {
        additionalUserCount > 0 && (
          <div className="w-6 h-6 rounded-full bg-lightgray text-gray-800 font-semibold tracking-wide flex items-center justify-center">
            <span>+{additionalUserCount}</span>
          </div>
        )
      }
    </div>
  )
}

ActionAbbreviatedUserList.propTypes = {
  className: types.string,
  limit: types.number,
  users: types.arrayOf(types.object).isRequired,
}

export default ActionAbbreviatedUserList
