import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"

const AttachmentCreatedAuditItemDetails = ({ audit }) => {
  const { attachmentName } = audit

  return (
    <div>
      <span>
        <AuditedUser audit={audit} /> attached
        { attachmentName ? ` the file ${attachmentName}` : " a file"}.
      </span>
    </div>
  )
}

AttachmentCreatedAuditItemDetails.propTypes = {
  audit: auditShape.isRequired,
}

export default AttachmentCreatedAuditItemDetails
