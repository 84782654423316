import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"

const FormSubmissionViewedAuditItemDetails = ({ audit }) => (
  <div>
    <span>
      <AuditedUser audit={audit} /> viewed the form submission.
    </span>
  </div>
)

FormSubmissionViewedAuditItemDetails.propTypes = {
  audit: auditShape.isRequired,
}

export default FormSubmissionViewedAuditItemDetails
