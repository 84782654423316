import React from "react"
import types from "prop-types"
import { openInNewTab } from "utils/routeHelpers"
import CustomIconButton from "./CustomIconButton"
import Tooltip, { TOP_RIGHT } from "../Tooltip"

const openHelpDocs = () => openInNewTab("https://performancehealth.helpdocsite.com/");

const NeedHelpButton = ({ className = "" }) => (
  <div className={className}>
    <Tooltip text="Password: Training" position={TOP_RIGHT}>
      <div className="pt-1">
        <CustomIconButton
          background="white"
          className="tertiary-button"
          icon="need-help"
          text="Need Help?"
          onClick={openHelpDocs}
        />
      </div>
    </Tooltip>
  </div>
)

NeedHelpButton.propTypes = {
  className: types.string,
}

export default NeedHelpButton
