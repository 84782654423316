import React from "react"
import { useSurveyReportExportContext } from "contexts/SurveyReportExportContext"
import { DATE_TYPE } from "views/Forms/FormElementTypes"
import { DateInput } from "shared/inputs"
import { QuestionHistoryQuestionSelect } from "shared/selects"

const NewSurveyReportExportFormDateAnswerFilters = () => {
  const {
    dateAnswerFilters,
    questions,
    setDateAnswerFilterEarliestDate,
    setDateAnswerFilterLatestDate,
    setDateAnswerFilterQuestionUuid,
  } = useSurveyReportExportContext()

  const dateQuestions = questions.filter((question) => question.type === DATE_TYPE)

  if (dateQuestions.length === 0) {
    return (
      <p>There are no date questions on the form associated with this report.</p>
    )
  }

  const dateAnswerFilter = dateAnswerFilters[0] ?? {}
  const { earliestDate, latestDate, questionUuid } = dateAnswerFilter

  return (
    <div className="w-full">
      <QuestionHistoryQuestionSelect
        className="!w-full bordered-base-input mb-4"
        clearable
        onChange={setDateAnswerFilterQuestionUuid}
        questions={dateQuestions}
        value={questionUuid}
      />
      {
        questionUuid && (
          <>
            <label>
              <span>Earliest Date:</span>
              <DateInput
                className="mb-4"
                clearable
                onChange={setDateAnswerFilterEarliestDate}
                value={earliestDate}
              />
            </label>
            <label>
              <span>Latest Date:</span>
              <DateInput
                className="mb-4"
                clearable
                onChange={setDateAnswerFilterLatestDate}
                value={latestDate}
              />
            </label>
          </>
        )
      }
    </div>
  )
}

export default NewSurveyReportExportFormDateAnswerFilters
