import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"

const TaskExportedInFormSubmissionAuditItemDetails = ({ audit }) => {
  const { task } = audit

  return (
    <div>
      <span>
        <AuditedUser audit={audit} /> included
        the task titled <span className="font-semibold">{task.title}</span> in
        an export of the form submission{ audit.comment ? ` (${audit.comment}).` : "." }
      </span>
    </div>
  )
}

TaskExportedInFormSubmissionAuditItemDetails.propTypes = {
  audit: auditShape.isRequired,
}

export default TaskExportedInFormSubmissionAuditItemDetails
