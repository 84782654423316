import React, { useEffect, useState } from "react"
import types from "prop-types"
import { store } from "store"
import { setFormBuilderPermissions } from "reduxSlices/formIndexSlice"
import { initializeWorkflowsState } from "reduxSlices/workflowsSlice"
import { useFormIndexSettings } from "hooks"
import Sidebar from "shared/Sidebar"
import withReduxProvider from "shared/withReduxProvider"
import FormIndexSidebarCategorySection from "views/Forms/FormIndex/Sidebar/FormIndexSidebarCategorySection"
import FormIndexSidebarFormTypeSection from "views/Forms/FormIndex/Sidebar/FormIndexSidebarFormTypeSection"
import FormIndexSidebarActiveFilter from "views/Forms/FormIndex/Sidebar/FormIndexSidebarActiveFilter"
import FormIndexSidebarGroupBySection from "views/Forms/FormIndex/Sidebar/FormIndexSidebarGroupBySection"
import FormContent from "./Content/FormContent"

const FormIndex = ({ forms, workflows, formBuilderPermissions }) => {
  useEffect(
    () => {
      store.dispatch(initializeWorkflowsState({ workflows }))
    },
    [forms, workflows],
  )

  useEffect(
    () => {
      store.dispatch(setFormBuilderPermissions(formBuilderPermissions))
    },
    [formBuilderPermissions],
  )

  useFormIndexSettings({ forms })

  const [hiddenSidebar, setHiddenSidebar] = useState(true)
  const toggleSidebar = () => setHiddenSidebar(!hiddenSidebar)

  return (
    <>
      <Sidebar hiddenSidebar={hiddenSidebar}>
        <FormIndexSidebarActiveFilter />
        <FormIndexSidebarCategorySection />
        <FormIndexSidebarFormTypeSection />
        <FormIndexSidebarGroupBySection />
      </Sidebar>
      <FormContent
        hiddenSidebar={hiddenSidebar}
        toggleSidebar={toggleSidebar}
      />
    </>
  )
}

FormIndex.propTypes = {
  forms: types.arrayOf(types.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formBuilderPermissions: types.object.isRequired,
  workflows: types.arrayOf(types.object).isRequired,
}

export default withReduxProvider(FormIndex)
