import React, { useEffect, useState } from "react"
import types from "prop-types"
import { isEmpty, sortBy } from "lodash-es"
import * as API from "services/api"
import { useReportableFieldLocationAnswerFilter, useSubmissionDateFilter } from "hooks"
import { STANDARD_ACCESS, SUPER_ACCESS } from "utils/accessLevelHelpers"
import { GENERAL_TASK_SUBFORM_ID } from "utils/taskHelpers"
import { errorToast } from "shared/toast"
import HistoricalAccessToolContext from "../HistoricalAccessToolContext"

export const ADD_HISTORICAL_ACCESS = "add_historical_access"
export const REMOVE_HISTORICAL_ACCESS = "remove_historical_access"

const GENERAL_TASK_TASK_FORM = { id: GENERAL_TASK_SUBFORM_ID, title: "General Task (no task form)" }

export const GRANTABLE_ACCESS_LEVELS = [STANDARD_ACCESS, SUPER_ACCESS]

const HistoricalAccessToolContextProvider = ({ children, user }) => {
  const { id: userId } = user

  const {
    earliestSubmissionDate, latestSubmissionDate, setEarliestSubmissionDate, setLatestSubmissionDate,
  } = useSubmissionDateFilter()

  const {
    addAllFacilityDepartmentsToReportableFieldLocationAnswerFilters,
    addDepartmentIdsToReportableFieldLocationAnswerFilters,
    departments,
    facilities,
    facilityDepartments,
    removeAllFacilityDepartmentsFromReportableFieldLocationAnswerFilters,
    removeDepartmentIdsFromReportableFieldLocationAnswerFilters,
    reportableFieldLocationAnswerFilters,
    setReportableFieldLocationAnswerFilterReportableFieldId,
  } = useReportableFieldLocationAnswerFilter()

  const [historicalAccessAction, setHistoricalAccessAction] = useState(null)
  const [categories, setCategories] = useState([])
  const [selectedCategorySlug, setSelectedCategorySlug] = useState(null)
  const [formsForSubmitting, setFormsForSubmitting] = useState([])
  const [selectedFormsForSubmittingIds, setSelectedFormsForSubmittingIds] = useState([])
  const [taskForms, setTaskForms] = useState([])
  const [selectedTaskFormIds, setSelectedTaskFormIds] = useState([])
  const [reportableFields, setReportableFields] = useState([])
  const [selectedAccessLevel, setSelectedAccessLevel] = useState(null)

  const resetHistoricalAccessToolData = () => {
    setEarliestSubmissionDate("")
    setLatestSubmissionDate("")
    setReportableFieldLocationAnswerFilterReportableFieldId(null)
    setHistoricalAccessAction(null)
    setSelectedCategorySlug(null)
    setFormsForSubmitting([])
    setSelectedFormsForSubmittingIds([])
    setTaskForms([])
    setSelectedTaskFormIds([])
    setReportableFields([])
    setSelectedAccessLevel(null)
  }

  // When the component mounts load all organization solutions.
  // User will select one solution.
  useEffect(() => {
    const loadCategories = async () => {
      const response = await API.categories()

      if (response.ok) {
        setCategories(sortBy(response.data, ["name"]))
      } else {
        errorToast("Something went wrong.  Unable to load data for the historical access tool.")
      }
    }

    loadCategories()
  }, [])

  // When the solutions change, if there's only on solution,
  // then select that solution.
  useEffect(() => {
    if (categories.length === 1) {
      setSelectedCategorySlug(categories[0].slug)
    }
  }, [categories])

  // When a solution is selected,
  // (1) load forms, task forms, and reportable fields;
  // (2) reset selected forms for submitting;
  // (3) reset selected task forms.
  useEffect(() => {
    if (!selectedCategorySlug) return

    const setSolutionSpecificData = async () => {
      const responses = await Promise.all([
        API.getCategoryFormsForSubmitting({ categorySlug: selectedCategorySlug }),
        API.getCategorySubforms({ categorySlug: selectedCategorySlug }),
        API.getCategoryReportableFields({ categorySlug: selectedCategorySlug }),
      ])

      if (responses.every((response) => response.ok)) {
        const [formsForSubmittingResponse, taskFormsResponse, reportableFieldsResponse] = responses

        setSelectedFormsForSubmittingIds([])
        setFormsForSubmitting(sortBy(formsForSubmittingResponse.data, ["title"]))
        setSelectedTaskFormIds([])
        setTaskForms(sortBy([...taskFormsResponse.data, GENERAL_TASK_TASK_FORM], ["title"]))
        setReportableFields(sortBy(reportableFieldsResponse.data, ["name"]))
      } else {
        errorToast("Something went wrong.  Unable to load solution-specific data for the historical access tool.")
      }
    }

    setSolutionSpecificData()
  }, [selectedCategorySlug])

  // Functions for working with selected forms for submitting
  const selectAllFormsForSubmitting = () => setSelectedFormsForSubmittingIds(formsForSubmitting.map((form) => form.id))
  const deselectAllFormsForSubmitting = () => setSelectedFormsForSubmittingIds([])
  const isFormForSubmittingSelected = (id) => selectedFormsForSubmittingIds.includes(id)
  const selectFormForSubmitting = (id) => setSelectedFormsForSubmittingIds(Array.from(new Set([...selectedFormsForSubmittingIds, id])))
  const deselectFormForSubmitting = (id) => setSelectedFormsForSubmittingIds(selectedFormsForSubmittingIds.filter((formId) => formId !== id))

  // Functions for working with selected task forms
  const selectAllTaskForms = () => setSelectedTaskFormIds(taskForms.map((form) => form.id))
  const deselectAllTaskForms = () => setSelectedTaskFormIds([])
  const isTaskFormSelected = (id) => selectedTaskFormIds.includes(id)
  const selectTaskForm = (id) => setSelectedTaskFormIds(Array.from(new Set([...selectedTaskFormIds, id])))
  const deselectTaskForm = (id) => setSelectedTaskFormIds(selectedTaskFormIds.filter((formId) => formId !== id))

  const allRequiredAddAccessInstructionsDataEntered = selectedAccessLevel && !isEmpty(selectedFormsForSubmittingIds)

  const allRequiredRemoveAccessInstructionsDataEntered = !isEmpty(selectedFormsForSubmittingIds)

  // Object exposed to context consumers
  const contextConsumerValue = {
    addAllFacilityDepartmentsToReportableFieldLocationAnswerFilters,
    addDepartmentIdsToReportableFieldLocationAnswerFilters,
    allRequiredAddAccessInstructionsDataEntered,
    allRequiredRemoveAccessInstructionsDataEntered,
    categories,
    categoryIsSelected: Boolean(selectedCategorySlug),
    departments,
    deselectAllFormsForSubmitting,
    deselectAllTaskForms,
    deselectFormForSubmitting,
    deselectTaskForm,
    earliestSubmissionDate,
    facilities,
    facilityDepartments,
    formsForSubmitting,
    historicalAccessAction,
    isFormForSubmittingSelected,
    isTaskFormSelected,
    latestSubmissionDate,
    removeAllFacilityDepartmentsFromReportableFieldLocationAnswerFilters,
    removeDepartmentIdsFromReportableFieldLocationAnswerFilters,
    reportableFieldLocationAnswerFilters,
    reportableFields,
    resetHistoricalAccessToolData,
    selectAllFormsForSubmitting,
    selectAllTaskForms,
    selectedAccessLevel,
    selectedCategorySlug,
    selectedFormsForSubmittingIds,
    selectedTaskFormIds,
    selectFormForSubmitting,
    selectTaskForm,
    setEarliestSubmissionDate,
    setHistoricalAccessAction,
    setLatestSubmissionDate,
    setReportableFieldLocationAnswerFilterReportableFieldId,
    setSelectedAccessLevel,
    setSelectedCategorySlug,
    taskForms,
    userId,
  }

  return (
    <HistoricalAccessToolContext.Provider displayName="Historical Access Tool Context" value={contextConsumerValue}>
      {children}
    </HistoricalAccessToolContext.Provider>
  )
}

HistoricalAccessToolContextProvider.propTypes = {
  children: types.node.isRequired,
  user: types.object.isRequired,
}

export default HistoricalAccessToolContextProvider
