import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const FlagIcon = ({ className = "w-5 h-5", ...rest }) => <SvgSpriteIcon iconName="flag" className={className} {...rest} />

FlagIcon.propTypes = {
  className: types.string,
}

export default FlagIcon
