import React, { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { shared, setShared } from "reduxSlices/reportBuilderSlice"
import { currentUserIsInAdminRole } from "reduxSlices/sessionSlice"
import { Checkbox } from "shared/checkboxes"

const SharedInput = () => {
  const dispatch = useDispatch()
  const sharedFlag = useSelector(shared)
  const currentUserIsAdmin = useSelector(currentUserIsInAdminRole)

  const setSharedFlag = useCallback((value) => {
    dispatch(setShared(value))
  }, [dispatch])

  if (!currentUserIsAdmin) return null

  return (
    <>
      <div className="mt-4 mb-2 text-sm flex">
        <Checkbox
          className="mr-2 cursor-pointer"
          uuid="form-submission-report-shared-flag-checkbox"
          value={sharedFlag}
          onChange={setSharedFlag}
        />
        <label htmlFor="form-submission-report-shared-flag-checkbox" className="cursor-pointer">
          Share with your organization
        </label>
      </div>
      <span className="text-xs text-dark">If the report is shared, all admins will be able to edit the report.</span>
    </>
  )
}

export default SharedInput
