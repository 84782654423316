import React from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { useSelector } from "react-redux"
import { taskForms as taskFormOptions } from "reduxSlices/reportBuilderSlice"
import TaskFormSelections from "./TaskFormSelections"
import TaskFormOptions from "./TaskFormOptions"
import SelectorSection from "./SelectorSection"

const TaskFormSelector = ({ className = "" }) => {
  const taskForms = useSelector(taskFormOptions, isEqual)

  if (taskForms.length === 0) {
    return (
      <div className="text-lg text-gray-400">
        There aren&apos;t any Task Forms for the selected Solution
      </div>
    )
  }

  const instructions = (
    <div className="space-y-2">
      <p>Which task forms would you like to include?</p>
      <p className="text-xs text-dark">The forms you select can be custom ordered by dragging and dropping</p>
    </div>
  )

  return (
    <div className={className}>
      <SelectorSection
        instructionComponent={instructions}
        optionsComponent={<TaskFormOptions />}
        selectionsComponent={<TaskFormSelections />}
      />
    </div>
  )
}

TaskFormSelector.propTypes = {
  className: types.string,
}

export default TaskFormSelector
