import React from "react"
import types from "prop-types"

const goToFacilityShow = (facility) => () => {
  window.location.pathname = `/admin/facilities/${facility.id}`
}

const FacilityInfo = ({ facility }) => (
  <div
    className="flex-grow cursor-pointer"
    onClickCapture={goToFacilityShow(facility)}
  >
    <span>{ facility.name }</span>
  </div>
)

FacilityInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  facility: types.object.isRequired,
}

export default FacilityInfo
