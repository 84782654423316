import { useSelector } from "react-redux"
import { subscribedToAddOnFeature, addOnFeatureConfigurationEnabled } from "reduxSlices/addOnFeatureSubscriptionsSlice"
import { SCIM_FEATURE_NAME, SCIM_DEPARTMENTS_MANAGED_PROPERTY } from "utils/addOnFeatureHelpers"

const useSCIMResourceManagement = () => {
  const isSubscribedToSCIM = useSelector(subscribedToAddOnFeature(SCIM_FEATURE_NAME))
  const isSCIMDepartmentManagementEnabled = useSelector(addOnFeatureConfigurationEnabled(SCIM_FEATURE_NAME, SCIM_DEPARTMENTS_MANAGED_PROPERTY))

  return (
    {
      usersManagedBySCIM: isSubscribedToSCIM,
      departmentsManagedBySCIM: isSubscribedToSCIM && isSCIMDepartmentManagementEnabled,
    }
  )
}

export default useSCIMResourceManagement
