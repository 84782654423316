import React from "react"
import { useFormSubmissionReportExportContext } from "contexts/FormSubmissionReportExportContext"
import LocationAnswerFiltersQuestionSelect from "./LocationAnswerFiltersQuestionSelect"
import LocationAnswerFiltersLocationDetails from "./LocationAnswerFiltersLocationDetails"

const NewFormSubmissionReportExportFormLocationAnswerFilters = () => {
  const { locationAnswerFilters } = useFormSubmissionReportExportContext()

  const locationAnswerFilter = locationAnswerFilters[0] ?? {}
  const { questionUuid } = locationAnswerFilter

  return (
    <div className="w-full">
      <LocationAnswerFiltersQuestionSelect />
      {
        questionUuid && (
          <LocationAnswerFiltersLocationDetails />
        )
      }
    </div>
  )
}

export default NewFormSubmissionReportExportFormLocationAnswerFilters
