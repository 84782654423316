import React, { useEffect } from "react"
import types from "prop-types"
import { useDispatch } from "react-redux"
import { setQuestionTags } from "reduxSlices/questionTagsSlice"
import displayName from "utils/displayName"
import { questionTagShape } from "utils/propTypeShapes"

const withStoredQuestionTagsHOC = (WrappedComponent) => {
  const WithStoredQuestionTags = ({ questionTags = [], ...rest }) => {
    const dispatch = useDispatch()

    useEffect(() => {
      dispatch(setQuestionTags({ questionTags }))
    }, [dispatch, questionTags])

    return (
      <WrappedComponent {...rest} />
    )
  }

  WithStoredQuestionTags.displayName = `withStoredQuestionTags(${displayName(WrappedComponent)})`

  WithStoredQuestionTags.propTypes = {
    questionTags: types.arrayOf(questionTagShape),
  }

  return WithStoredQuestionTags
}

export default withStoredQuestionTagsHOC
