import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const CategoryIcon = ({ className = "w-8 h-8" }) => <SvgSpriteIcon iconName="solutions" className={className} />

CategoryIcon.propTypes = {
  className: types.string,
}

export default CategoryIcon
