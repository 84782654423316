import React from "react"
import types from "prop-types"
import TruncateText from "shared/TruncateText"
import { useFileUploadContext } from "contexts/FileUploadContext"
import { CheckmarkCircleIcon, FormLightIcon } from "shared/icons"
import RemoveButton from "./RemoveButton"

const FilePreviewCard = ({ file }) => {
  const { removeFile } = useFileUploadContext()
  const {
    attached,
    filename,
    key,
    progress,
    src,
    errors,
  } = file

  const onFileRemove = () => {
    removeFile(file)
  }

  return (
    <div
      key={key}
      className="bg-primary-light border border-lightgray-2 h-80 rounded-2xl light-shadow-lg relative m-2"
    >
      <RemoveButton
        className="absolute right-2 mt-2"
        onClick={onFileRemove}
        file={file}
      />
      <div className="flex justify-center h-2/3">
        {
          src
            ? <img className="pt-2 px-2 object-contain" src={src} alt={filename} />
            : <FormLightIcon className="w-full h-full" />
        }
      </div>
      <div className="bg-white pt-2 px-8 pb-6 h-1/3 min-h-fit rounded-b-2xl border-t border-lightgray-2">
        <progress
          className="[&::-webkit-progress-bar]:rounded w-full [&::-webkit-progress-value]:rounded [&::-webkit-progress-bar]:bg-gray [&::-webkit-progress-value]:bg-blue [&::-moz-progress-bar]:bg-blue"
          id={key}
          value={progress}
          max={100}
        />
        <TruncateText truncateOn={filename.split(".").pop()} className="mr-5">
          <div>{ filename }</div>
        </TruncateText>
        <div className="min-h-min font-semibold">
          { attached && !errors
            && (
              <div className="flex">
                <CheckmarkCircleIcon />
                <div className="ml-2 self-center text-green">
                  Success!
                </div>
              </div>
            )}
          { errors
              && (
                <div className="text-red">
                  Error: {errors}
                </div>
              )}
        </div>
      </div>
    </div>
  )
}

FilePreviewCard.propTypes = {
  file: types.object.isRequired,
}

export default FilePreviewCard
