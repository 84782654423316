import React from "react"
import types from "prop-types"
import clsx from "clsx"

const DEFAULT_STYLE = "flex items-center justify-center w-6 h-6 text-sm font-bold rounded-full bg-light-300"

const CountBadge = ({ count, className = "" }) => (<span className={clsx(DEFAULT_STYLE, className)}>{count}</span>)

CountBadge.propTypes = {
  className: types.string,
  count: types.number.isRequired,
}

export default CountBadge
