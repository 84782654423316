import React, { useReducer } from "react"
import types from "prop-types"
import Category from "./Category"

const RESET_CATEGORY = "RESET_CATEGORY"

function reducer(state, action) {
  switch (action.type) {
  case RESET_CATEGORY:
    return state.map((category) => (
      category.id === action.id
        ? { ...category, hasSetupReset: true }
        : category
    ))
  default:
    return state
  }
}

const CategoryList = ({ categories: propCategories, className = "", subdomain }) => {
  const [categories, dispatch] = useReducer(reducer, propCategories)

  const onResetCategory = (category) => {
    dispatch({ type: RESET_CATEGORY, id: category.id })
  }

  return (
    <ul className={className}>
      {
        categories.map((category) => (
          <li key={category.id} className="mb-2">
            <Category
              category={category}
              subdomain={subdomain}
              onResetCategory={onResetCategory}
            />
          </li>
        ))
      }
    </ul>
  )
}

CategoryList.propTypes = {
  className: types.string,
  categories: types.arrayOf(types.object).isRequired,
  subdomain: types.string.isRequired,
}

export default CategoryList
