import React from "react"
import { useFormSubmissionScheduledReportExportContext } from "contexts/FormSubmissionScheduledReportExportContext"
import { DATE_FILTER_TYPE_OPTIONS } from "contexts/FormSubmissionScheduledReportExportContext/FormSubmissionScheduledReportExportContextProvider"
import { SmallWrap } from "shared/wraps"
import { DataCollectorContainer, DataCollectorStep } from "shared/dataCollector"
import ActiveStatus from "shared/ScheduledReportExport/ActiveStatus"
import FormActions from "shared/ScheduledReportExport/FormActions"
import FilterActions from "shared/ScheduledReportExport/FilterActions"
import { DATE_QUESTION_FILTER_HEADER, SUBMISSION_DATE_FILTER_HEADER } from "utils/scheduledReportExportHelpers"
import NewFormSubmissionScheduledReportExportFormDateAnswerFilters from "./NewFormSubmissionScheduledReportExportFormDateAnswerFilters"
import NewFormSubmissionScheduledReportExportFormLocationAnswerFilters from "./NewFormSubmissionScheduledReportExportFormLocationAnswerFilters"
import NewFormSubmissionScheduledReportExportSubmissionFilters from "./NewFormSubmissionScheduledReportExportSubmissionFilters"
import NewFormSubmissionScheduledReportExportDetailFields from "./NewFormSubmissionScheduledReportExportDetailFields"

const NewFormSubmissionScheduledReportExportForm = () => {
  const {
    activeStatus,
    cancelForm,
    dateAnswerPreviousRangeLength,
    dateFilterType,
    dateAnswerFilters,
    formSubmissionReport,
    recurrenceDayOfMonth,
    recurrenceDayOfWeek,
    saveScheduledReportExport,
    setActiveStatus,
    setDateFilterTypeAndClearValues,
    startDate,
    submissionPreviousRangeLength,
  } = useFormSubmissionScheduledReportExportContext()

  const {
    name: formSubmissionReportName,
  } = formSubmissionReport

  const renderDataCollectorHeaderContent = () => (
    <span className="font-medium text-xl">{formSubmissionReportName}</span>
  )

  const recurrenceDataCollected = startDate || recurrenceDayOfWeek || recurrenceDayOfMonth
  const dateFilterRangesCollected = dateAnswerPreviousRangeLength || submissionPreviousRangeLength
  const isFormValid = startDate && dateFilterRangesCollected

  return (
    <SmallWrap>
      <DataCollectorContainer renderHeaderContent={renderDataCollectorHeaderContent}>
        <div className="mb-6">
          <DataCollectorStep stepNumber={1}>
            <NewFormSubmissionScheduledReportExportDetailFields />
          </DataCollectorStep>

          {
            recurrenceDataCollected && (
              <>
                {dateAnswerFilters.length > 0 && (
                  <DataCollectorStep stepNumber={2} heading="Select Date Filter Type">
                    <FilterActions
                      dateFilterType={dateFilterType}
                      setDateFilterTypeAndClearValues={setDateFilterTypeAndClearValues}
                      filterOptions={DATE_FILTER_TYPE_OPTIONS}
                    />
                  </DataCollectorStep>
                )}

                {(dateFilterType === SUBMISSION_DATE_FILTER_HEADER) && (
                  <DataCollectorStep stepNumber={2} heading="Filter by When the Submission was Received">
                    <NewFormSubmissionScheduledReportExportSubmissionFilters />
                  </DataCollectorStep>
                )}

                {dateFilterType === DATE_QUESTION_FILTER_HEADER && (
                  <DataCollectorStep stepNumber={3} heading="Filter by the Answer to a Date Question">
                    <NewFormSubmissionScheduledReportExportFormDateAnswerFilters />
                  </DataCollectorStep>
                )}

                {dateFilterRangesCollected && (
                  <>
                    <DataCollectorStep stepNumber={4} heading="Filter by the Answer to a Location Question">
                      <NewFormSubmissionScheduledReportExportFormLocationAnswerFilters />
                    </DataCollectorStep>
                    <DataCollectorStep stepNumber={5}>
                      <ActiveStatus
                        activeStatus={activeStatus}
                        setActiveStatus={setActiveStatus}
                      />
                    </DataCollectorStep>
                  </>
                )}
              </>
            )
          }

        </div>
        <FormActions
          cancelForm={cancelForm}
          isFormValid={!!isFormValid}
          saveScheduledReportExport={saveScheduledReportExport}
        />
      </DataCollectorContainer>
    </SmallWrap>
  )
}

export default NewFormSubmissionScheduledReportExportForm
