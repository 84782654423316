export const submissionAccessLevel = ({ categoryGrant, formGrant, formSubmissionGrant }) => {
  if (formSubmissionGrant) {
    formSubmissionGrant.grantor = "formSubmissionGrant"
    return formSubmissionGrant
  } if (formGrant) {
    formGrant.grantor = "formGrant"
    return formGrant
  } if (categoryGrant) {
    categoryGrant.grantor = "categoryGrant"
    return categoryGrant
  }
  return {}
}

export const formAccessExplanation = ({ categoryGrant, formGrant, formSubmissionGrant }) => {
  if (formSubmissionGrant) {
    if (categoryGrant) return "Overrides Solution grant"
    if (formGrant) return "Overrides Form grant"

    return "Granted by Submission"
  }

  if (formGrant) { return "Granted by Form" }

  return "Granted by Solution"
}
