import React from "react"
import { useFormSubmissionScheduledReportExportContext } from "contexts/FormSubmissionScheduledReportExportContext"
import { ListSelect, QuestionHistoryQuestionSelect } from "shared/selects"
import {
  dayjsOrgTz, DAYS, MONTHS, WEEKS,
} from "utils/dateHelpers"
import DaysAnswerDescription from "shared/ScheduledReportExport/DateFilterDescriptions/AnswerDescription/DaysAnswerDescription"
import WeeksAnswerDescription from "shared/ScheduledReportExport/DateFilterDescriptions/AnswerDescription/WeeksAnswerDescription"
import MonthsAnswerDescription from "shared/ScheduledReportExport/DateFilterDescriptions/AnswerDescription/MonthsAnswerDescription"

const NewFormSubmissionScheduledReportExportFormDateAnswerFilters = () => {
  const {
    dateAnswerFilters,
    lengthOptions,
    dateAnswerPreviousRangeLength,
    dateAnswerPreviousRangeUnit,
    dateAnswerQuestionUuid,
    setDateAnswerQuestionUuid,
    setDateAnswerPreviousRangeLength,
    startDate,
  } = useFormSubmissionScheduledReportExportContext()

  return (
    <div className="w-full">
      <QuestionHistoryQuestionSelect
        className="!w-full bordered-base-input mb-4"
        clearable={false}
        backspaceDelete={false}
        onChange={setDateAnswerQuestionUuid}
        questions={dateAnswerFilters}
        value={dateAnswerQuestionUuid}
      />
      {
        dateAnswerQuestionUuid && (
          <>
            <div className="flex justify-start items-center my-4">
              <span className="min-w-min">Answer value is in the previous</span>
              <ListSelect
                className="bordered-base-input ml-2 max-w-max"
                clearable={false}
                backspaceDelete={false}
                placeholder="1"
                name="date_answer_previous_range_length"
                required
                options={lengthOptions}
                value={dateAnswerPreviousRangeLength}
                onChange={setDateAnswerPreviousRangeLength}
              />
              <div className="ml-2">{dateAnswerPreviousRangeUnit}</div>
            </div>

            {
              dateAnswerPreviousRangeUnit === DAYS
              && (
                <DaysAnswerDescription
                  // DateInput sets value as a string
                  startDate={dayjsOrgTz(startDate, "YYYY-MM-DD")}
                  dateAnswerPreviousRangeLength={dateAnswerPreviousRangeLength}
                />
              )
            }
            {
              dateAnswerPreviousRangeUnit === WEEKS
              && (
                <WeeksAnswerDescription
                  startDate={startDate}
                  dateAnswerPreviousRangeLength={dateAnswerPreviousRangeLength}
                />
              )
            }
            {
              dateAnswerPreviousRangeUnit === MONTHS
              && (
                <MonthsAnswerDescription
                  startDate={startDate}
                  dateAnswerPreviousRangeLength={dateAnswerPreviousRangeLength}
                />
              )
            }
          </>
        )
      }
    </div>
  )
}

export default NewFormSubmissionScheduledReportExportFormDateAnswerFilters
