/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react"
import { categoryShape } from "utils/propTypeShapes"
import { FormsyMultilineTextInput } from "shared/inputs"

const CategoryDescription = ({ category }) => (
  <>
    <label className="field-name">Description (Optional)</label>
    <FormsyMultilineTextInput
      className="bordered-base-input text-sm"
      name="description"
      placeholder="Briefly describe this solution"
      rows="3"
      value={category.description}
    />
  </>
)

CategoryDescription.propTypes = {
  category: categoryShape.isRequired,
}

export default CategoryDescription
