import React from "react"
import types from "prop-types"
import SortableTableHead from "./SortableTableHead"
import TableHead from "./TableHead"

const TableHeader = ({ columns, currentSort, onSort = null }) => (
  <thead>
    <tr>
      {
        columns.map((column) => (
          (column.sortable) ? (
            <SortableTableHead
              key={column.value}
              column={column}
              currentSort={currentSort}
              onSort={onSort}
            />
          ) : (
            <TableHead key={column.value} column={column} />
          )
        ))
      }
    </tr>
  </thead>
)

TableHeader.propTypes = {
  columns: types.arrayOf(types.object).isRequired,
  onSort: types.func,
  // eslint-disable-next-line react/forbid-prop-types
  currentSort: types.object.isRequired,
}

export default TableHeader
