import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { questionShape } from "utils/propTypeShapes"
import { FormsyDateInput } from "shared/inputs"

const dateValidations = ({
  edit,
  max,
  min,
  required,
}) => {
  const dateRegex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/
  const validations = { matchRegexp: dateRegex }

  if (edit) { return validations }

  if (required) { validations.isNotBlankString = true }

  if (min && max) {
    validations.isBetween = [min, max]
    return validations
  }

  if (min) { validations.isGreaterThanOrEqualTo = { min } }

  if (max) { validations.isLessThanOrEqualTo = { max } }

  return validations
}

const DateAnswerField = ({
  className,
  disabled,
  edit,
  initialValue,
  isTaskForm,
  question,
  ...rest
}) => {
  const { attributes, required, uuid } = question
  const { preventPastDate, preventFutureDate, setCurrentDate } = attributes

  const today = new Date()
  const year = today.getFullYear().toString()
  const month = (today.getMonth() + 1).toString().padStart(2, 0)
  const day = today.getDate().toString().padStart(2, 0)

  const currentDate = `${year}-${month}-${day}`
  const dateValue = (setCurrentDate ? currentDate : "")

  const min = (preventPastDate) ? currentDate : ""
  const max = (preventFutureDate) ? currentDate : ""

  const isNewFormSubmission = !edit && !isTaskForm
  const defaultAnswer = disabled ? "" : dateValue

  if (!initialValue) {
    initialValue = isNewFormSubmission ? defaultAnswer : ""
  }

  const validations = dateValidations({
    edit, required, min, max,
  })

  return (
    <FormsyDateInput
      className={clsx("form-input-date-time", className)}
      disabled={disabled}
      clearable
      name={uuid}
      min={edit ? null : min} // see sc-4412. This prevents <input min={...} />
      max={edit ? null : max} // and by extension HTML validations
      validations={validations}
      validationErrors={{ matchRegexp: "Invalid date format: must be MM/DD/YYYY" }}
      value={initialValue}
      {...rest}
    />
  )
}

DateAnswerField.defaultProps = {
  initialValue: "",
  disabled: false,
  className: "",
  placeholder: "",
  hasBaseInputStyle: false,
  edit: false,
  isTaskForm: false,
}

DateAnswerField.propTypes = {
  question: questionShape.isRequired,
  initialValue: types.string,
  disabled: types.bool,
  className: types.string,
  placeholder: types.string,
  hasBaseInputStyle: types.bool,
  edit: types.bool,
  isTaskForm: types.bool,
}

export default DateAnswerField
