import React from "react"
import { useHistoricalAccessToolContext } from "contexts/HistoricalAccessToolContext"
import { DataCollectorStep } from "shared/dataCollector"
import HistoricalAccessToolFormsForSubmitting from "shared/HistoricalAccessTool/HistoricalAccessToolFormsForSubmitting"
import HistoricalAccessToolLocationFilter from "shared/HistoricalAccessTool/HistoricalAccessToolLocationFilter"
import HistoricalAccessToolSolution from "shared/HistoricalAccessTool/HistoricalAccessToolSolution"
import HistoricalAccessToolSubmissionDateFilter from "shared/HistoricalAccessTool/HistoricalAccessToolSubmissionDateFilter"
import HistoricalAccessToolRemoveAccessPreview from "./HistoricalAccessToolRemoveAccessPreview"

const HistoricalAccessToolRemoveSteps = () => {
  const { categoryIsSelected } = useHistoricalAccessToolContext()

  return (
    <>
      <div className="mb-6">
        <DataCollectorStep stepNumber={2} heading="Solution">
          <HistoricalAccessToolSolution />
        </DataCollectorStep>
        {
          categoryIsSelected && (
            <>
              <DataCollectorStep stepNumber={3} heading="Forms">
                <p className="mb-2 required-prompt">Select forms:</p>
                <p className="text-sm text-gray mb-8">
                  The user will only lose access to submissions from the selected forms.
                  When access to a submission is lost, the user will also be removed
                  from all of the submission&apos;s tasks.
                </p>
                <HistoricalAccessToolFormsForSubmitting />
              </DataCollectorStep>
              <DataCollectorStep stepNumber={4} heading="Filter by the Date the Submission was Received">
                <HistoricalAccessToolSubmissionDateFilter />
              </DataCollectorStep>
              <DataCollectorStep stepNumber={5} heading="Filter by the Answer to a Reportable Field Location Question">
                <HistoricalAccessToolLocationFilter />
              </DataCollectorStep>
            </>
          )
        }
      </div>
      <HistoricalAccessToolRemoveAccessPreview />
    </>
  )
}

export default HistoricalAccessToolRemoveSteps
