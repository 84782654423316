import { addValidationRule } from "formsy-react"
import { humanFileSize } from "utils/fileSizeHelpers"
import {
  includesLowercaseLetter,
  includesNumber,
  includesUppercaseLetter,
  meetsPasswordStrengthRequirement,
} from "utils/validationHelpers"

export const isNotBlankString = (_model, value, { errorMessage } = {}) => (
  value?.toString().trim().length > 0 || errorMessage || "Cannot be blank"
)

addValidationRule("isNotBlankString", isNotBlankString);

function hasLowercaseLetter(_model, value) {
  return includesLowercaseLetter.test(value) ? true : includesLowercaseLetter.message
}

addValidationRule("hasLowercaseLetter", hasLowercaseLetter);

function hasUppercaseLetter(_model, value) {
  return includesUppercaseLetter.test(value) ? true : includesUppercaseLetter.message
}

addValidationRule("hasUppercaseLetter", hasUppercaseLetter);

function hasNumber(_model, value) {
  return includesNumber.test(value) ? true : includesNumber.message
}

addValidationRule("hasNumber", hasNumber)

function isStrongPassword(_model, value) {
  return meetsPasswordStrengthRequirement.test(value) ? true : meetsPasswordStrengthRequirement.message
}

addValidationRule("isStrongPassword", isStrongPassword);

const isNotEmptyArray = (_model, value, { errorMessage }) => (
  value?.length > 0
    ? true
    : errorMessage || "Must select at least 1 answer"
)

addValidationRule("isNotEmptyArray", isNotEmptyArray);

const isBetween = (_model, value, [min, max]) => {
  const errorMessage = (min === max) ? `Must be ${min}` : `Must be between ${min} and ${max}`
  const isValid = min <= value && value <= max
  return isValid || errorMessage
}

addValidationRule("isBetween", isBetween);

const skipNumberComparison = (value, comparisonValue) => (
  (!value && value !== 0)
  || (!comparisonValue && comparisonValue !== 0)
  || Number.isNaN(comparisonValue)
)

const isNumberGreaterThanOrEqualTo = (_model, value, { min, errorMessage }) => (
  skipNumberComparison(value, min) || (Number(value) >= Number(min))
    ? true
    : errorMessage || `Must be at least ${min}`
)

addValidationRule("isNumberGreaterThanOrEqualTo", isNumberGreaterThanOrEqualTo);

const isNumberLessThanOrEqualTo = (_model, value, { max, errorMessage }) => (
  skipNumberComparison(value, max) || (Number(value) <= Number(max))
    ? true
    : errorMessage || `Must be no greater than ${max}`
)

addValidationRule("isNumberLessThanOrEqualTo", isNumberLessThanOrEqualTo);

const isGreaterThanOrEqualTo = (_model, value, { min, errorMessage }) => (
  (value === min) || (value >= min)
    ? true
    : errorMessage || `Must be on or after ${min}`
)

addValidationRule("isGreaterThanOrEqualTo", isGreaterThanOrEqualTo);

const isLessThanOrEqualTo = (_model, value, { max, errorMessage }) => (
  (value === max) || (value <= max)
    ? true
    : errorMessage || `Must be on or before ${max}`
)

addValidationRule("isLessThanOrEqualTo", isLessThanOrEqualTo);

// Value should be a FormData
const allAttachmentsSmallerThan = (_model, value, { max, errorMessage }) => {
  if (!value) return true

  const attachments = value.getAll("attachments[]")

  for (let i = 0; i < attachments.length; i += 1) {
    if (attachments[i].size > max) {
      return errorMessage || `All files must be smaller than ${humanFileSize(max)}`
    }
  }

  return true
}

addValidationRule("allAttachmentsSmallerThan", allAttachmentsSmallerThan);

// Value should be a FormData
const allAttachmentsValidType = (_model, value, { types, errorMessage }) => {
  if (!value) return true

  const attachmentTypes = value.getAll("attachments[]").map((file) => file.type)

  return attachmentTypes.some((attachmentType) => !types.includes(attachmentType))
    ? errorMessage || "Unsupported file type"
    : true
}

addValidationRule("allAttachmentsValidType", allAttachmentsValidType);

export const mayNotBeIncludedIn = (_model, value, { denyList, errorMessage }) => {
  if (!value) return true

  return denyList.includes(value)
    ? errorMessage || "Already exists"
    : true
}

addValidationRule("mayNotBeIncludedIn", mayNotBeIncludedIn);

const hasSufficientDigits = (_model, value, { qty }) => {
  if (!value) return true

  const errorMessage = `Must contain at least ${qty} digits`
  return value.toString().replace(/[^\d]/g, "").length >= qty || errorMessage
}

addValidationRule("hasSufficientDigits", hasSufficientDigits);

// CSV Injection
// See app/models/abstract_export_instructions.rb
export const EXCEL_FORMULA_METACHARACTER_START_OF_STRING_MATCHER = /^[-+=@\t\r]/
export const EXCEL_FORMULA_METACHARACTER_POST_DELIMITER_MATCHER = /[,;'"][-+=@\t\r]/

export const EXCEL_FORMULA_METACHARACTER_START_OF_STRING_ERROR_MESSAGE = "Cannot start with a tab, carriage return, or @ - + = "

export const EXCEL_FORMULA_METACHARACTER_POST_DELIMITER_ERROR_MESSAGE = "Cannot follow , ; ' or \" with a tab, carriage return, or @ - + = "

export const noExcelMetacharacters = (_model, value, { errorMessage } = {}) => {
  if (!value) return true

  if (EXCEL_FORMULA_METACHARACTER_START_OF_STRING_MATCHER.test(value)) {
    return errorMessage || EXCEL_FORMULA_METACHARACTER_START_OF_STRING_ERROR_MESSAGE
  }

  if (EXCEL_FORMULA_METACHARACTER_POST_DELIMITER_MATCHER.test(value)) {
    return errorMessage || EXCEL_FORMULA_METACHARACTER_POST_DELIMITER_ERROR_MESSAGE
  }

  return true
}

addValidationRule("noExcelMetacharacters", noExcelMetacharacters)

// HTML Injection
// Reject all values that contain HTML (e.g., <span>, <a>, etc.)
// Users do copy and paste emails as answers; in such cases email addresses
// are sometimes represented within angle brackets: <john@example.com>.
// The JavaScript DOMParser treats these email address representations
// as HTML elements, so we need to make an exception for this case and
// other potential uses of angle brackets not representing valid HTML elements.
// Our approach is to ignore unknown, invalid HTML elements that DOMParser
// represents as HTMLUnknownElement objects.
//
// Additionally, in the case of the email examples, these elements are not closed,
// so any subsequent HTML elements are descendents of the email elements,
// meaning the whole tree under the document's body element needs to be searched.
//
// Valid examples:
// - My answer
// - John Smith <john.smith@example.com> wrote:
// - <unknown>My answer</unknown>
//
// Invalid examples:
// - <span>My answer</span>
// - John Smith <john.smith@example.com> wrote: <a href="https://google.com">Click</a>
// - <unknown><input type="text" /></unknown>
export const findValidHTMLElement = (element, { checkElement = true } = {}) => {
  if (checkElement && !(element instanceof window.HTMLUnknownElement)) return element

  let validElement

  for (let i = 0; i < element.children.length; i += 1) {
    validElement = findValidHTMLElement(element.children[i])
    if (validElement) break
  }

  return validElement
}

export const noHTML = (_model, value, { errorMessage } = {}) => {
  const parser = new window.DOMParser()
  const document = parser.parseFromString(value, "text/html")
  const validHTMLElement = findValidHTMLElement(document.body, { checkElement: false })

  if (validHTMLElement) {
    return errorMessage || `HTML not permitted: <${validHTMLElement.tagName}>`
  }

  return true
}

addValidationRule("noHTML", noHTML)

// Form Builder validation to prevent duplicating a question prompt.
// Question prompt counts are cached in redux, and the count for the value
// is passed to the validator.
const isUniqueFormQuestionPrompt = (_model, value, { count }) => {
  if (!value || count <= 1) return true

  return "Prompt is repeated"
}

addValidationRule("isUniqueFormQuestionPrompt", isUniqueFormQuestionPrompt)

// Form Builder validation to prevent duplicating options for one question.
// Question options counts are cached in redux, and the count for the value
// is passed to the validator.
const isUniqueFormQuestionOption = (_model, value, { count }) => {
  if (!value || count <= 1) return true

  return "Duplicate options prohibited"
}

addValidationRule("isUniqueFormQuestionOption", isUniqueFormQuestionOption)
