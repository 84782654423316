import React from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { getFormQuestionRequired, setQuestionRequired } from "reduxSlices/formBuilderSlice"
import { SwitchInput } from "shared/inputs"

const QuestionRequired = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const questionRequired = useSelector(getFormQuestionRequired({ sectionIndex, questionIndex }))

  const updateQuestionRequired = (newRequired) => dispatch(setQuestionRequired({
    sectionIndex, questionIndex, newRequired,
  }))

  return (
    <div className="mb-4 flex-center">
      <div className="font-medium">Mark as required</div>
      <SwitchInput
        value={questionRequired}
        onChange={updateQuestionRequired}
      />
    </div>
  )
}

QuestionRequired.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default QuestionRequired
