import React from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { deselectUsage, isUsageSelected, selectUsage } from "reduxSlices/formIndexSlice"
import { FORM_USAGES, formUsageFormTypeDescription } from "utils/formHelpers"
import { Checkbox } from "shared/checkboxes"
import { SidebarContentItem } from "shared/Sidebar"

const FormIndexSidebarFormTypeSectionFormTypeCheckbox = ({ formUsage }) => {
  const dispatch = useDispatch()

  const usageIsSelected = useSelector(isUsageSelected(formUsage))

  const toggleReducer = usageIsSelected ? deselectUsage : selectUsage

  const toggleSelection = () => dispatch(toggleReducer({ usage: formUsage }))

  return (
    <SidebarContentItem onClickCapture={toggleSelection}>
      <div className="flex justify-between">
        <span className="capitalize">{formUsageFormTypeDescription(formUsage)}</span>
        <Checkbox onChange={toggleSelection} value={usageIsSelected} />
      </div>
    </SidebarContentItem>
  )
}

FormIndexSidebarFormTypeSectionFormTypeCheckbox.propTypes = {
  formUsage: types.oneOf(FORM_USAGES).isRequired,
}

export default FormIndexSidebarFormTypeSectionFormTypeCheckbox
