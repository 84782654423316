import React from "react"
import types from "prop-types"

const SidebarContentItem = ({ children = null, onClickCapture = undefined }) => (
  <div
    className="mb-2 rounded-md cursor-pointer p-2 border border-lightgray-3 text-sm font-medium"
    {...(onClickCapture && { onClickCapture })}
  >
    {children}
  </div>
)

SidebarContentItem.propTypes = {
  children: types.node,
  onClickCapture: types.func,
}

export default SidebarContentItem
