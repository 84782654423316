import React from "react"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const EmptyCategories = () => (
  <div className="flex flex-col items-center pt-4">
    <SvgSpriteIcon iconName="empty-categories" className="h-10 w-10 mb-4" />
    <div className="font-medium text-sm">
      You do not have any solutions yet
    </div>
  </div>
)

export default EmptyCategories
