/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import types from "prop-types"

const NO_DATA = {}

const NoDataToDefine = ({ changeActionData }) => {
  useEffect(() => {
    changeActionData(NO_DATA)
  }, [])

  return null
}

NoDataToDefine.propTypes = {
  changeActionData: types.func.isRequired,
}

export default NoDataToDefine
