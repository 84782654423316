import React from "react"
import types from "prop-types"
import { TextInput } from "shared/inputs"
import { SearchIcon } from "shared/icons"

const isSelected = (selectedList, label) => selectedList.find((selected) => selected.label === label)
const doesMatchSearch = (search, label) => label.toLowerCase().includes(search.toLowerCase())

const SearchInput = ({
  props: {
    options,
    placeholder,
  },
  state: {
    search,
    values: selectedList,
  },
  methods: { setSearch, addItem },
}) => {
  const filteredOptions = options
    .filter(({ label }) => !isSelected(selectedList, label) && doesMatchSearch(search, label))

  const onSearchInputEnter = ({ key }) => {
    if (key === "Enter" && filteredOptions.length === 1) {
      addItem(filteredOptions[0])
    }
  }

  return (
    <div className="w-full flex-center">
      <TextInput
        className="focus:border-0"
        value={search}
        onChange={(value) => setSearch({ target: { value } })}
        onKeyDown={onSearchInputEnter}
        placeholder={placeholder}
      />
      <SearchIcon className="h-5 w-5" />
    </div>
  )
}

SearchInput.propTypes = {
  props: types.shape({
    options: types.arrayOf(types.object).isRequired,
    placeholder: types.string.isRequired,
  }).isRequired,
  state: types.shape({
    search: types.string.isRequired,
    values: types.arrayOf(types.object).isRequired,
  }).isRequired,
  methods: types.shape({
    setSearch: types.func.isRequired,
    addItem: types.func.isRequired,
  }).isRequired,
}

export default SearchInput
