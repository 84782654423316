import React, { useEffect, useState } from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { impersonationInProgress } from "reduxSlices/sessionSlice"
import withReduxProvider from "shared/withReduxProvider"
import { PHPLogo } from "shared/icons"
import GlobalHeaderTabs from "./GlobalHeaderTabs"
import FeatureAnnouncementsPanel from "../../FeatureAnnouncementPanel"
import UsersPanel from "../UsersPanel"
import TasksAndSubmissionsSearch from "./TasksAndSubmissionsSearch"

import { GLOBAL_HEADER_CHILDREN_PORTAL_TARGET_ID } from "./GlobalHeaderChildrenPortal"
import ImpersonationBanner from "../ImpersonationBanner"
import MobileMenuButton from "./MobileMenuButton"

const GlobalHeader = ({ currentTab = "", additionalTabs = [] }) => {
  const showImpersonationBanner = useSelector(impersonationInProgress)

  const [searchVisible, setSearchVisible] = useState(false)
  const [mobileMenuVisible, setMobileMenuVisible] = useState(window.innerWidth > 1024)

  const showMobileMenu = () => setMobileMenuVisible(true)
  const hideMobileMenu = () => setMobileMenuVisible(false)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        showMobileMenu()
      } else {
        hideMobileMenu()
      }
    }

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <>
      {
        showImpersonationBanner && (
          <div className="w-full bg-impersonation text-white">
            <ImpersonationBanner />
          </div>
        )
      }
      <nav className="w-full flex-center border-b border-lightgray-3 bg-primary-light relative">
        <PHPLogo />

        <GlobalHeaderTabs currentTab={currentTab} additionalTabs={additionalTabs} mobileMenuVisible={mobileMenuVisible} />
        <div id={GLOBAL_HEADER_CHILDREN_PORTAL_TARGET_ID} />
        <div className="grid grid-cols-2-one-half gap-2 lg:grid-cols-1 lg:mr-4">
          <div className="grid grid-cols-3">
            <TasksAndSubmissionsSearch
              searchVisible={searchVisible}
              setSearchVisible={setSearchVisible}
            />
            <FeatureAnnouncementsPanel />
            <UsersPanel />
          </div>

          <MobileMenuButton menuIsOpen={mobileMenuVisible} onClick={mobileMenuVisible ? hideMobileMenu : showMobileMenu} />
        </div>
      </nav>
    </>
  )
}

GlobalHeader.propTypes = {
  currentTab: types.node,
  additionalTabs: types.arrayOf(types.string),
}

export default withReduxProvider(GlobalHeader)
