import React from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { useSelector } from "react-redux"
import { getFormQuestionOptions, getFormQuestionPrompt } from "reduxSlices/formBuilderSlice"
import { useModal } from "hooks"
import { TertiaryButton } from "shared/buttons"
import Modal, { ModalHeader } from "shared/Modal"
import SelectCSVOptionsPreview from "./SelectCSVOptionsPreview"

const SelectCSVViewOptions = ({ questionIndex, sectionIndex }) => {
  const { modalIsOpen, openModal, closeModal } = useModal()

  const questionPrompt = useSelector(getFormQuestionPrompt({ sectionIndex, questionIndex }))
  const questionOptions = useSelector(getFormQuestionOptions({ sectionIndex, questionIndex }), isEqual)

  return (
    <>
      <TertiaryButton
        text="View Options"
        onClick={openModal}
      />
      <Modal isOpen={modalIsOpen}>
        <ModalHeader
          closeModal={closeModal}
          heading={`Options for Question: "${questionPrompt}"`}
        />
        <SelectCSVOptionsPreview options={questionOptions} />
      </Modal>
    </>
  )
}

SelectCSVViewOptions.propTypes = {
  questionIndex: types.number.isRequired,
  sectionIndex: types.number.isRequired,
}

export default SelectCSVViewOptions
