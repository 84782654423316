import React from "react"
import types from "prop-types"
import * as UserFields from "./UserFields"

const AttributesTab = ({
  user,
  updateRole,
  updateLocked,
  updateActive,
  updateOtpConfirmed,
}) => (
  <>
    <h2 className="tab-title">Attributes</h2>
    <UserFields.Role user={user} updateRole={updateRole} />
    <UserFields.Locked user={user} updateLocked={updateLocked} />
    <UserFields.Active user={user} updateActive={updateActive} />
    <UserFields.OtpConfirmed user={user} updateOtpConfirmed={updateOtpConfirmed} />
    <UserFields.ExternalId user={user} />
  </>
)

AttributesTab.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: types.object.isRequired,
  updateRole: types.func.isRequired,
  updateLocked: types.func.isRequired,
  updateActive: types.func.isRequired,
  updateOtpConfirmed: types.func.isRequired,
}

export default AttributesTab
