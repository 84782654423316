import React, { Component } from "react"
import { featureAnnouncementShape } from "utils/propTypeShapes"
import FeatureAnnouncement from "./FeatureAnnouncement"

const getTitleInputElement = () => document.getElementById("feature_announcement_title")

const getMessageInputElement = () => document.getElementById("feature_announcement_message")

class FeatureAnnouncementPreview extends Component {
  state = {
    title: this.props.featureAnnouncement.title,
    message: this.props.featureAnnouncement.message,
  }

  componentDidMount() {
    const title = getTitleInputElement()
    title.addEventListener("input", this.updateTitle)

    const message = getMessageInputElement()
    message.addEventListener("input", this.updateMessage)
  }

  componentWillUnmount() {
    const title = getTitleInputElement()
    title.removeEventListener("input", this.updateTitle)

    const message = getMessageInputElement()
    message.removeEventListener("input", this.updateMessage)
  }

  updateTitle = (event) => {
    this.setState({ title: event.target.value })
  }

  updateMessage = (event) => {
    this.setState({ message: event.target.value })
  }

  render() {
    const { title, message } = this.state

    return <FeatureAnnouncement featureAnnouncement={{ title, message }} />
  }
}

FeatureAnnouncementPreview.defaultProps = {
  featureAnnouncement: {},
}

FeatureAnnouncementPreview.propTypes = {
  featureAnnouncement: featureAnnouncementShape,
}

export default FeatureAnnouncementPreview
