import React from "react"
import { useSelector } from "react-redux"
import { allTasks } from "reduxSlices/formSubmissionSlice"
import TaskAnswersSync from "./TaskAnswersSync"

const SyncAllTaskAnswers = () => {
  const tasks = useSelector(allTasks)

  return (
    <>
      {
        tasks.map((task) => {
          const { id, userTaskPermissions } = task
          const { canSeeDetails } = userTaskPermissions

          return (
            canSeeDetails ? <TaskAnswersSync key={`task-answers-sync-${id}`} id={id} /> : null
          )
        })
      }
    </>
  )
}

export default SyncAllTaskAnswers
