import React from "react"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import AddWorkflowButton from "./AddWorkflowButton"

const EmptyWorkflows = () => (
  <div className="flex flex-col items-center">
    <SvgSpriteIcon iconName="empty-workflows" className="w-10 h-10 mt-24 mb-4" />
    <span className="text-sm text-dark font-medium">You do not have any workflows yet</span>
    <AddWorkflowButton className="mt-4" />
  </div>
)

export default EmptyWorkflows
