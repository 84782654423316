import React from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { useDispatch, useSelector } from "react-redux"
import {
  getFormQuestionAttributeStartTimeQuestionUuid,
  getFormQuestionAttributeStartTimeQuestionUuidOptions,
  setQuestionAttributeStartTimeQuestionUuid,
} from "reduxSlices/formBuilderSlice"
import { QuestionSelect } from "shared/selects"

const StartTimeQuestionUuid = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const questionStartTimeQuestionUuid = useSelector(getFormQuestionAttributeStartTimeQuestionUuid({ sectionIndex, questionIndex }))
  const questionStartTimeQuestionOptions = useSelector(getFormQuestionAttributeStartTimeQuestionUuidOptions({ sectionIndex, questionIndex }), isEqual)

  const updateStartTimeQuestionUuid = (newStartTimeQuesitonUuid) => dispatch(setQuestionAttributeStartTimeQuestionUuid({
    sectionIndex, questionIndex, newStartTimeQuesitonUuid,
  }))

  return (
    <QuestionSelect
      backspaceDelete={false}
      className="text-sm"
      isFullWidth
      onChange={updateStartTimeQuestionUuid}
      placeholder="Start time"
      questions={questionStartTimeQuestionOptions}
      selectedQuestionUuid={questionStartTimeQuestionUuid}
      style={{ height: "32px" }}
    />
  )
}

StartTimeQuestionUuid.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default StartTimeQuestionUuid
