import React from "react"
import types from "prop-types"
import { FormsyHiddenInput } from "shared/inputs"

const TaskId = ({ initialValue, formsyNamePrefix }) => (
  <FormsyHiddenInput
    name={`${formsyNamePrefix}task.id`}
    value={initialValue}
  />
)

TaskId.defaultProps = {
  formsyNamePrefix: "",
}

TaskId.propTypes = {
  initialValue: types.number.isRequired,
  formsyNamePrefix: types.string,
}

export default TaskId
