import React, { Component } from "react"
import types from "prop-types"
import Formsy from "formsy-react"
import Modal from "shared/Modal"
import { XIcon } from "shared/icons"
import { PrimaryButton, Button } from "shared/buttons"
import * as API from "services/api"
import { successToast, errorToast } from "shared/toast"
import * as UserFields from "./UserFields"

class CreateUserModal extends Component {
  state = { isFormValid: false, modalIsOpen: false }

  static onSubmit = async (model) => {
    const response = await API.createUser({ user: model })
    if (response.ok) {
      window.location = `/admin/users/${response.data.id}`
      successToast("User created successfully!")
    } else {
      console.error("Error updating user: ", response)
      errorToast("Something went wrong. Unable to create user.", response)
    }
  }

  closeModal = () => this.setState({ modalIsOpen: false })

  openModal = () => this.setState({ modalIsOpen: true })

  enableSubmit = () => this.setState({ isFormValid: true })

  disableSubmit = () => this.setState({ isFormValid: false })

  render() {
    const { user } = this.props
    const { isFormValid, modalIsOpen } = this.state

    return (
      <>
        <div className="flex justify-center">
          <Button
            text="+ Add User"
            onClick={this.openModal}
            className="text-focus border border-light my-4"
          />
        </div>

        <Modal isOpen={modalIsOpen}>
          <div className="text-center text-xl font-semibold mb-8 relative">
            New User
            <XIcon
              className="absolute -top-4 right-4 rounded-full bg-primary-light p-2 h-8 w-8"
              onClick={this.closeModal}
            />
          </div>
          <Formsy
            onSubmit={CreateUserModal.onSubmit}
            onValid={this.enableSubmit}
            onInvalid={this.disableSubmit}
          >
            <div className="w-3/4 mx-auto">
              <div className="flex-center">
                <UserFields.FirstName user={user} />
                <UserFields.LastName user={user} />
              </div>
              <UserFields.Email user={user} />
              <UserFields.Role user={user} />
              <div className="flex justify-center mt-8">
                <Button
                  text="Cancel"
                  onClick={this.closeModal}
                  className="border border-light-300 mr-2"
                />
                <PrimaryButton
                  text="Create"
                  type="submit"
                  disabled={!isFormValid}
                />
              </div>
            </div>
          </Formsy>
        </Modal>
      </>
    )
  }
}

CreateUserModal.defaultProps = {
  user: {},
}

CreateUserModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: types.object,
}

export default CreateUserModal
