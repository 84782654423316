import React from "react"
import types from "prop-types"
import clsx from "clsx"

const AccordionContent = ({ children, className = "", isExpanded }) => (
  <div className={clsx(className, !isExpanded && "hidden")}>
    { children }
  </div>
)

AccordionContent.propTypes = {
  children: types.node.isRequired,
  isExpanded: types.bool.isRequired,
  className: types.string,
}

export default AccordionContent
