import React from "react"
import types from "prop-types"
import { numberDate } from "utils/dateHelpers"

const FormSubmissionCreatedDate = ({ createdAt }) => (
  <span>{numberDate(createdAt)}</span>
)

FormSubmissionCreatedDate.propTypes = {
  createdAt: types.string.isRequired,
}

export default FormSubmissionCreatedDate
