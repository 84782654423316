import React, { useEffect } from "react"
import types from "prop-types"
import { workflowConditionShape } from "utils/propTypeShapes"
import { IS_PROPERTY_OP } from "utils/workflowHelpers"

const DefineSubmittedFormOperator = ({ changeConditionOperator, condition }) => {
  useEffect(() => {
    if (condition.operator !== IS_PROPERTY_OP) {
      changeConditionOperator(IS_PROPERTY_OP)
    }
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  return (
    <span>is</span>
  )
}

DefineSubmittedFormOperator.propTypes = {
  changeConditionOperator: types.func.isRequired,
  condition: workflowConditionShape.isRequired,
}

export default DefineSubmittedFormOperator
