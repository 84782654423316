import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const PHPLogo = ({
  className = "", height = 32, width = 49,
}) => (
  <div className="logo-container h-14 p-4 border-r border-lightgray-3 flex items-center">
    <SvgSpriteIcon
      height={height}
      width={width}
      iconName="php-logo"
      className={className}
    />
  </div>
)

PHPLogo.propTypes = {
  className: types.string,
  height: types.number,
  width: types.number,
}

export default PHPLogo
