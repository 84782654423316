import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { withFormsy } from "formsy-react"

const Checkbox = ({
  className = "",
  disabled = false,
  // errorMessages, // TODO: Implement later
  name = "",
  onChange = () => {},
  uuid = undefined,
  value = false,
}) => (
  <input
    className={clsx(className, disabled ? "!cursor-not-allowed" : "cursor-pointer")}
    id={uuid}
    name={name || uuid}
    type="checkbox"
    disabled={disabled}
    checked={value}
    onChange={({ target }) => onChange(target.checked)}
  />
  // errorMessages={errorMessages}
)

Checkbox.propTypes = {
  uuid: types.oneOfType([types.string, types.number]),
  name: types.string,
  value: types.bool,
  disabled: types.bool,
  onChange: types.func,
  // errorMessages: types.arrayOf(types.string),
  className: types.string,
}

export const FormsyCheckbox = withFormsy(({ setValue, ...rest }) => (
  <Checkbox onChange={setValue} {...rest} />
))

FormsyCheckbox.displayName = "FormsyCheckbox"

export default Checkbox
