import { createSlice } from "@reduxjs/toolkit"

const INITIAL_STATE = {
  currentAnnouncement: null,
}

export const featureAnnouncementSlice = createSlice({
  name: "featureAnnouncement",
  initialState: INITIAL_STATE,
  reducers: {
    setFeatureAnnouncement: (state, action) => {
      Object.assign(state, { currentAnnouncement: action.payload })
    },
    updateLocalReadStatus: (state) => {
      state.currentAnnouncement.read = true
    },
  },
})

export const { setFeatureAnnouncement, updateLocalReadStatus } = featureAnnouncementSlice.actions

export const currentAnnouncement = (state) => state.featureAnnouncement.currentAnnouncement

export const hasUnread = (state) => state.featureAnnouncement.currentAnnouncement
  && !state.featureAnnouncement.currentAnnouncement.read

export default featureAnnouncementSlice.reducer
