import React from "react"
import { workflowActionShape } from "utils/propTypeShapes"
import { CREATE_TASK } from "utils/workflowHelpers"
import CreateTaskActionLabel from "./CreateTaskActionLabel"

const ACTION_TYPE_LABELS = {
  [CREATE_TASK]: CreateTaskActionLabel,
}

const ActionLabel = ({ action }) => {
  const ActionTypeLabel = ACTION_TYPE_LABELS[action.type]

  if (!ActionTypeLabel) return null

  return (
    <ActionTypeLabel action={action} />
  )
}

ActionLabel.propTypes = {
  action: workflowActionShape.isRequired,
}

export default ActionLabel
