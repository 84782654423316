import React from "react"
import types from "prop-types"
import { CustomHeaderAccordion, ExpansionControl, useAccordion } from "shared/accordions"
import { FormIcon } from "shared/icons"

const FormAccessList = ({ category }) => {
  const [isExpanded, toggleAccordion] = useAccordion(true)

  return (
    <div className="list-row block bg-white">
      <CustomHeaderAccordion
        isExpanded={isExpanded}
        header={(
          <div className="flex-center">
            { category.categoryName } Solution
            <ExpansionControl toggleAccordion={toggleAccordion} isExpanded={isExpanded} />
          </div>
        )}
        content={(
          <>
            <div className="py-5" />
            {
              category.forms.map((form) => (
                <div
                  key={form.id}
                  className="flex-grow mb-1 list-row border-0 py-3 bg-light-100"
                >
                  <div className="flex items-center text-sm font-medium w-full">
                    <FormIcon className="mr-3 w-8 h-8" form={form} />
                    <span className="flex-grow">{ form.title }</span>
                    <div className="w-max p-1.5 bg-white tracking-tight self-end">
                      { form.resolved_access_level }
                    </div>
                  </div>
                </div>
              ))
            }
          </>
        )}
      />
    </div>
  )
}

FormAccessList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  category: types.object.isRequired,
}

export default FormAccessList
