import React from "react"
import { useSurveyConfigurationContext } from "contexts/SurveyConfigurationContext"
import CreateContactForSurveyConfiguration from "components/shared/modals/CreateContactForSurveyConfiguration"
import ContactsList from "./ContactsList"
import ContactsUploader from "./ContactsUploader"

export const CONTACTS_TAB_HEADING = "Contacts"

const SurveyConfigurationContactsTab = () => {
  const { surveyConfigurationHasContacts } = useSurveyConfigurationContext()

  return (
    <>
      <h2 className="tab-title">{ CONTACTS_TAB_HEADING }</h2>
      {
        surveyConfigurationHasContacts
          ? <ContactsList />
          : <ContactsUploader />
      }

      <CreateContactForSurveyConfiguration />
    </>
  )
}

export default SurveyConfigurationContactsTab
