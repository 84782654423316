import React from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { getFormQuestionAttributeNote, setQuestionAttributeNote } from "reduxSlices/formBuilderSlice"
import FormsyValidation from "shared/FormsyValidation"
import { MultilineTextInput } from "shared/inputs"

const QuestionNote = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const questionNote = useSelector(getFormQuestionAttributeNote({ sectionIndex, questionIndex }))

  const updateQuestionNote = (newNote) => dispatch(setQuestionAttributeNote({
    sectionIndex, questionIndex, newNote,
  }))

  return (
    <>
      <MultilineTextInput
        className="px-0"
        placeholder="Write note here"
        value={questionNote}
        onChange={updateQuestionNote}
      />
      <FormsyValidation
        name={`sections[${sectionIndex}].questions[${questionIndex}].attributes.note`}
        value={questionNote}
        validations={{
          isNotBlankString: true,
        }}
        validationErrors={{ isDefaultRequiredValue: "required" }}
      />
    </>
  )
}

QuestionNote.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default QuestionNote
