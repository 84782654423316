import React from "react"
import types from "prop-types"
import { CustomHeaderAccordion, useAccordion } from "shared/accordions"
import UserInfo from "shared/UserInfo"
import ToggleGroupAccordion from "./ToggleGroupAccordion"
import GroupInfo from "./GroupInfo"
import GroupMenu from "./GroupMenu"

const GroupWithMembers = ({ group, removeGroup }) => {
  const [isExpanded, toggleAccordion] = useAccordion(false)

  return (
    <div className="list-row block bg-white">
      <CustomHeaderAccordion
        isExpanded={isExpanded}
        header={(
          <div className="relative flex-center">
            <GroupInfo group={group} />
            <ToggleGroupAccordion
              group={group}
              isExpanded={isExpanded}
              toggleAccordion={toggleAccordion}
            />
            <GroupMenu group={group} removeGroup={removeGroup} />
          </div>
        )}
        content={(
          <>
            <div className="py-5" />
            {
              group.members.map((member) => (
                <UserInfo
                  user={member}
                  key={member.id}
                  className="mb-1 list-row border-0 py-3 bg-light-100"
                />
              ))
            }
          </>
        )}
      />
    </div>
  )
}

GroupWithMembers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  group: types.object.isRequired,
  removeGroup: types.func.isRequired,
}

export default GroupWithMembers
