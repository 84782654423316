import React from "react";
import types from "prop-types"
import { withFormsy } from "formsy-react"
import { Select } from "shared/selects"

const optionsFromList = (options) => options.map((opt) => ({ label: opt.name, value: (opt.slug || opt.id) }))

const CategorySelect = ({
  className = "",
  value = null,
  options = [],
  onChange,
  ...rest
}) => (
  <Select
    className={className}
    options={optionsFromList(options)}
    value={value}
    onChange={onChange}
    {...rest}
  />
)

CategorySelect.propTypes = {
  onChange: types.func.isRequired,
  options: types.arrayOf(types.object),
  value: types.oneOfType([types.string, types.number]),
  className: types.string,
}

export const FormsyCategorySelect = withFormsy(({ setValue, ...rest }) => (
  <CategorySelect onChange={setValue} {...rest} />
))

FormsyCategorySelect.displayName = "FormsyCategorySelect"

export default CategorySelect
