import React, { useState } from "react"
import types from "prop-types"
import clsx from "clsx"
import { useDispatch } from "react-redux"
import { setNewWorkflowName, showWorkflowForm } from "reduxSlices/formBuilderSlice"
import { Button } from "shared/buttons"
import CreateWorkflowModal from "shared/modals/CreateWorkflowModal"

const AddWorkflowButton = ({ className = "" }) => {
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(false)
  const openForm = () => setIsOpen(true)
  const closeForm = () => setIsOpen(false)

  const onSubmit = ({ name }) => {
    dispatch(setNewWorkflowName(name))
    dispatch(showWorkflowForm())
    closeForm()
  }

  return (
    <>
      <Button
        className={clsx(className, "text-focus border border-light")}
        text="+ Create a Workflow"
        onClick={openForm}
      />
      <CreateWorkflowModal
        onSubmit={onSubmit}
        closeForm={closeForm}
        isOpen={isOpen}
      />
    </>
  )
}

AddWorkflowButton.propTypes = {
  className: types.string,
}

export default AddWorkflowButton
