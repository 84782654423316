import React from "react"
import types from "prop-types"
import { workflowActionShape } from "utils/propTypeShapes"
import { dataDefiner } from "utils/workflowHelpers"

const DefineActionData = ({
  action, changeActionData, actionIndex, formSlug,
}) => {
  const { subtype, type } = action
  const DataDefiner = dataDefiner({ subtype, type })

  if (!DataDefiner) return null

  return (
    <DataDefiner
      action={action}
      changeActionData={changeActionData}
      actionIndex={actionIndex}
      formSlug={formSlug}
    />
  )
}

DefineActionData.propTypes = {
  action: workflowActionShape.isRequired,
  changeActionData: types.func.isRequired,
  actionIndex: types.number.isRequired,
  formSlug: types.string.isRequired,
}

export default DefineActionData
