import React, { forwardRef } from "react"
import { withFormsy } from "formsy-react"
import types from "prop-types"
import clsx from "clsx"
import InputErrorMessage from "shared/InputErrorMessage"

const PasswordInput = forwardRef((
  {
    hasBaseInputStyle,
    className,
    disabled,
    errorMessages,
    isPristine,
    isStatusValid,
    name,
    onBlur,
    onClick,
    onFocus,
    onChange,
    onKeyDown,
    placeholder,
    readonly,
    required,
    tabIndex,
    uuid,
    value,
  },
  ref,
) => (
  <>
    <input
      autoComplete="off"
      className={clsx(className, hasBaseInputStyle && "base-input")}
      disabled={disabled}
      id={uuid}
      name={name || uuid}
      onBlur={onBlur}
      onChange={({ target }) => onChange(target.value)}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onClick={onClick}
      placeholder={`${placeholder} ${required ? "*" : ""}`}
      ref={ref}
      tabIndex={tabIndex}
      readOnly={readonly}
      type="password"
      value={value}
    />
    <InputErrorMessage isStatusValid={isStatusValid} isPristine={isPristine} errorMessages={errorMessages} />
  </>
))

PasswordInput.defaultProps = {
  hasBaseInputStyle: true,
  className: undefined,
  disabled: false,
  errorMessages: [],
  isPristine: true,
  isStatusValid: true,
  name: undefined,
  onBlur: () => {},
  onClick: () => {},
  onFocus: () => {},
  onKeyDown: () => {},
  onChange: () => {},
  placeholder: "",
  readonly: false,
  required: false,
  tabIndex: null,
  uuid: undefined,
  value: "",
}

PasswordInput.propTypes = {
  hasBaseInputStyle: types.bool,
  className: types.string,
  disabled: types.bool,
  errorMessages: types.arrayOf(types.string),
  isPristine: types.bool,
  isStatusValid: types.bool,
  name: types.string,
  onBlur: types.func,
  onClick: types.func,
  onFocus: types.func,
  onChange: types.func,
  onKeyDown: types.func,
  placeholder: types.string,
  readonly: types.bool,
  required: types.bool,
  tabIndex: types.string,
  uuid: types.string,
  value: types.string,
}

PasswordInput.displayName = "PasswordInput"

export const FormsyPasswordInput = withFormsy(({ setValue, inputRef, ...rest }) => (
  <PasswordInput
    ref={inputRef}
    onChange={(value) => setValue(value.trimStart())}
    {...rest}
  />
))

FormsyPasswordInput.displayName = "FormsyPasswordInput"

export default PasswordInput
