import React, { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { name, setName } from "reduxSlices/reportBuilderSlice"
import queryParameter from "utils/queryParameter"
import { TextInput } from "shared/inputs"

const NAME_PARAM = "name"

const NameInput = () => {
  const dispatch = useDispatch()
  const reportName = useSelector(name)

  const storeName = useCallback((value) => {
    dispatch(setName(value))
  }, [dispatch])

  // When the component is registered, check the query params for a name
  // and if it's there update the store with the name
  useEffect(() => {
    if (queryParameter(NAME_PARAM)) {
      storeName(queryParameter(NAME_PARAM))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TextInput
      className="bordered-base-input border-none p-0 m-0"
      onChange={storeName}
      value={reportName}
    />
  )
}

export default NameInput
