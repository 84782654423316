import React from "react"
import { isEmpty } from "lodash-es"
import { useModal } from "hooks"
import * as API from "services/api"
import { SecondaryButton } from "shared/buttons"
import Modal, { DEFAULT_MODAL_STYLE, ModalHeader } from "shared/Modal"
import { successToast, errorToast } from "shared/toast"
import Tooltip from "shared/Tooltip"
import { useSFTPSettingsContext } from "contexts/SFTPSettingsContext"
import ConnectionTestingCheck from "./ConnectionTestingCheck"

const MODAL_STYLE = {
  content: {
    ...DEFAULT_MODAL_STYLE.content,
    top: "35%",
    overflow: "visible",
  },
}

const ConnectionTestModal = () => {
  const { modalIsOpen, openModal, closeModal } = useModal()
  const {
    canViewCredentials,
    formIsPristine,
    sftpRemoteServer,
  } = useSFTPSettingsContext()

  if (!canViewCredentials) {
    return null
  }

  const testConnection = async () => {
    openModal()
    const response = await API.testConnectionSftpRemoteServer()

    if (response.ok) {
      successToast("Connection verified!")
    } else {
      errorToast("Unable to connect.", response)
    }
    closeModal()
  }

  const tooltipText = (isEmpty(sftpRemoteServer) || !formIsPristine) ? "Save settings to test the connection." : ""

  return (
    <>
      <Tooltip text={tooltipText}>
        <div className="my-4">
          <SecondaryButton
            className="h-10 w-full"
            text="Test Connection"
            onClick={testConnection}
            disabled={isEmpty(sftpRemoteServer) || !formIsPristine}
          />
        </div>
      </Tooltip>
      <Modal isOpen={modalIsOpen} style={MODAL_STYLE}>
        <ModalHeader
          closeModal={close}
          heading="Testing SFTP Connection..."
        />
        <ConnectionTestingCheck />
      </Modal>
    </>
  )
}

export default ConnectionTestModal
