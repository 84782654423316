import { useContext } from "react"
import TaskFormContext from "../TaskFormContext"

const useTaskFormContext = () => {
  const providedValue = useContext(TaskFormContext)

  return { ...providedValue }
}

export default useTaskFormContext
