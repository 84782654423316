import React from "react"
import types from "prop-types"
import { Draggable } from "react-beautiful-dnd"
import { submissionStatusShape } from "utils/propTypeShapes"
import { DragHandle } from "shared/icons"
import SubmissionStatus from "./SubmissionStatus"

const DraggableSubmissionStatus = ({
  index,
  status,
  discardStatus = () => {},
  ...rest
}) => (
  <Draggable key={status.key} draggableId={status.key} index={index}>
    {
      (dragProvided) => (
        <div
          ref={dragProvided.innerRef}
          {...dragProvided.draggableProps}
        >
          <SubmissionStatus
            handle={<DragHandle dragProvided={dragProvided} />}
            status={status}
            isDiscardable
            discardStatus={discardStatus}
            {...rest}
          />
        </div>
      )
    }
  </Draggable>
)

DraggableSubmissionStatus.propTypes = {
  index: types.number.isRequired,
  status: submissionStatusShape.isRequired,
  discardStatus: types.func,
}

export default DraggableSubmissionStatus
