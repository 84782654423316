import React from "react"
import types from "prop-types"
import { reportExportSurveyReportShape } from "utils/propTypeShapes"
import { SurveyScheduledReportExportContextProvider } from "contexts/SurveyScheduledReportExportContext"
import NewSurveyScheduledReportExportForm from "../NewSurveyScheduledReportExport/NewSurveyScheduledReportExportForm"

const EditSurveyScheduledReportExport = ({ surveyReport, surveyScheduledReportExport }) => (
  <SurveyScheduledReportExportContextProvider
    surveyReport={surveyReport}
    surveyScheduledReportExport={surveyScheduledReportExport}
  >
    <NewSurveyScheduledReportExportForm />
  </SurveyScheduledReportExportContextProvider>
)

EditSurveyScheduledReportExport.propTypes = {
  surveyReport: reportExportSurveyReportShape.isRequired,
  surveyScheduledReportExport: types.object.isRequired,
}

export default EditSurveyScheduledReportExport
