import React from "react"
import { surveyBatchForExportReportShape } from "utils/propTypeShapes"
import SurveyBatchCheckbox from "./SurveyBatchCheckbox"

const SurveyBatchFilters = ({ surveyBatch }) => (
  <SurveyBatchCheckbox
    key={surveyBatch.id}
    surveyBatch={surveyBatch}
  />
)

SurveyBatchFilters.propTypes = {
  surveyBatch: surveyBatchForExportReportShape.isRequired,
}

export default SurveyBatchFilters
