import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getTimezone, setTimezone } from "reduxSlices/currentSettingsSlice"
import * as API from "services/api"
import ContentBar from "shared/lists/ContentBar"
import { ListSelect } from "shared/selects"
import { STYLE, US_TIMEZONES } from "utils/generalSettingsHelpers"

const TimezoneSetting = () => {
  const dispatch = useDispatch()
  const timezone = useSelector(getTimezone)

  const updateTimezone = async (newTimezone) => {
    const response = await API.updateTimezoneSetting({ timezone: newTimezone })

    if (response.ok) {
      dispatch(setTimezone(newTimezone))
    } else {
      console.error("Error updating timezone: ", response);
    }
  }

  return (
    <ContentBar title="Timezone">
      <div>
        <ListSelect
          style={STYLE}
          options={US_TIMEZONES}
          value={timezone}
          onChange={updateTimezone}
        />
      </div>
    </ContentBar>
  )
}

export default TimezoneSetting
