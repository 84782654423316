import React from "react"
import Formsy from "formsy-react"
import { useSubmit } from "hooks"
import { useDispatch } from "react-redux"
import { SLICE_NAME as FORM_BUILDER_SLICE_NAME, setFormQuestions } from "reduxSlices/formBuilderSlice"
import * as FormFields from "views/FormBuilder/FormFields"
import * as API from "services/api"
import { errorToast, successToast } from "components/shared/toast"
import { store } from "store"
import DesignSidebar from "./DesignSidebar"

const DesignTab = () => {
  const dispatch = useDispatch()

  const { enableSubmit, disableSubmit } = useSubmit()

  // Grab the form data directly from the store when saving the form.
  // The component only needs the form data for this purpose.
  // This should limit any rerenders that would be caused
  // by form data changes if we were using useSelector().
  const saveForm = async () => {
    const { form } = store.getState()[FORM_BUILDER_SLICE_NAME]

    const response = await API.saveForm({ form })
    if (response.ok) {
      dispatch(setFormQuestions(form.sections.flatMap((section) => section.questions)))
      successToast("Form saved successfully!")
    } else {
      console.error("ERROR CREATING FORM: ", response.data)
      errorToast("Something went wrong. Unable to save form.", response)
    }
  }

  const saveFormFailure = () => errorToast("Could not save form!")

  return (
    <div className="form-builder pt-8 pb-20">
      <Formsy
        id="form-builder"
        className="relative flex flex-col w-4/5"
        onValidSubmit={saveForm}
        onValid={enableSubmit}
        onInvalid={disableSubmit}
        onInvalidSubmit={saveFormFailure}
      >
        <div className="flex-col px-10 w-4/5 mx-auto">
          <FormFields.FormTitle />
          <FormFields.FormDescription />
          <FormFields.FormSections />
        </div>
        <DesignSidebar />
      </Formsy>
    </div>
  )
}

export default DesignTab
