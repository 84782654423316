import React from "react"
import types from "prop-types"
import { FormsyHiddenInput } from "shared/inputs"

const RequirementId = ({ index, value }) => (
  <FormsyHiddenInput
    name={`access_level_requirements_attributes[${index}].id`}
    value={value}
  />
)

RequirementId.propTypes = {
  index: types.number.isRequired,
  value: types.number.isRequired,
}

export default RequirementId
