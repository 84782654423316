// Matches Form::ICONS in app/models/form.rb
// The form-abuse-2 icon was removed, leaving form-abuse and form-abuse-3.
export const FORM_ICONS = [
  "form-compliance",
  "form-employee-safety",
  "form-fall",
  "form-pi-and-quality-solutions",
  "form-mother-and-baby",
  "form-diagnostics",
  "form-transfers",
  "form-security",
  "form-surgery-procedure",
  "form-surgery-procedure-2",
  "form-surgery-procedure-3",
  "form-patient-relations",
  "form-patient-relations-2",
  "form-infection-control",
  "form-unanticipated-event",
  "form-medication",
  "form-medication-2",
  "form-medication-3",
  "form-patient-safety-solutions",
  "form-environmental",
  "form-injury",
  "form-ambulance",
  "form-supply-management",
  "form-supply-management-2",
  "form-supply-management-3",
  "form-anesthesia",
  "form-covid",
  "form-self-harm",
  "form-neonate",
  "form-abuse",
  "form-abuse-3",
  "form-thermometer",
  "form-equipment",
  "form-equipment-2",
  "form-equipment-3",
  "form-audit",
  "form-policy",
  "form-pressure-ulcer",
  "form-bio-med",
  "form-generic",
  "form-generic-2",
  "form-generic-3",
  "form-behavioral-health",
  "form-behavioral-health-2",
  "form-immunizations",
  "form-computer",
  "form-dietary",
  "form-anonymous-reporting",
  "form-facilities",
  "form-good-catch",
  "form-other-health",
  "form-leave-ama",
  "form-abuse-self-harm",
  "form-abuse-self-harm-2",
  "form-accident-hazard-emergency",
  "form-housekeeping",
  "form-weather",
  "form-house",
  "form-star",
]

// Matches Form::ICON_COLORS in app/models/form.rb
export const FORM_ICON_COLORS = [
  "red",
  "gold",
  "green",
  "navy",
  "blue",
  "indigo",
  "gray",
  "pink",
  "mint",
  "orange",
  "teal",
  "purple",
]

export const FORM_ICON_COLOR_MAP = {
  red: "bg-icon-red",
  gold: "bg-icon-gold",
  green: "bg-icon-green",
  navy: "bg-icon-navy",
  blue: "bg-icon-blue",
  indigo: "bg-icon-indigo",
  gray: "bg-icon-gray",
  pink: "bg-icon-pink",
  mint: "bg-icon-mint",
  orange: "bg-icon-orange",
  teal: "bg-icon-teal",
  purple: "bg-icon-purple",
}
