import React from "react"
import types from "prop-types"
import * as API from "services/api"
import { SURVEY_USAGE } from "utils/formHelpers"
import RegularFormCard from "./RegularFormCard"
import SurveyFormCard from "./SurveyFormCard"
import TaskFormCard from "./TaskFormCard"

const createRegularForm = async (categoryId) => {
  const response = await API.createRegularForm({ categoryId })
  if (response.ok) {
    window.location = `/activity-hub/forms/${response.data.slug}/edit`
  } else {
    console.error("Error creating form: ", response)
  }
}

const createTaskForm = async (categoryId) => {
  const response = await API.createTaskForm({ categoryId })
  if (response.ok) {
    window.location = `/activity-hub/forms/${response.data.slug}/edit`
  } else {
    console.error("Error creating form: ", response)
  }
}

const createSurveyForm = async (categoryId) => {
  const response = await API.createSurveyForm({ categoryId })
  if (response.ok) {
    window.location = `/activity-hub/forms/${response.data.slug}/edit`
  } else {
    console.error("Error creating form: ", response)
  }
}

const CreateFormCardSection = ({ categoryId = null, categoryUsage = null }) => (
  <>
    {
      categoryUsage === SURVEY_USAGE
        ? <SurveyFormCard onClick={() => createSurveyForm(categoryId)} />
        : <RegularFormCard onClick={() => createRegularForm(categoryId)} />
    }
    <TaskFormCard onClick={() => createTaskForm(categoryId)} />
  </>
)

CreateFormCardSection.propTypes = {
  categoryId: types.oneOfType([types.string, types.number]),
  categoryUsage: types.string,
}

export default CreateFormCardSection
