import React, { useState } from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const UserEmailInput = ({ label = "Email" }) => {
  const [value, setValue] = useState("")
  const updateValue = ({ target }) => setValue(target.value)

  return (
    <>
      <label className="text-left block text-sm font-semibold mb-1" htmlFor="email">
        { label }
      </label>
      <div className="mb-5 relative">
        <input
          id="email"
          type="email"
          placeholder="Email..."
          name="email"
          required
          value={value}
          onChange={updateValue}
          className="bordered-base-input !text-sm h-12 login-email-input"
        />
        <SvgSpriteIcon iconName="user" className="absolute-vertical-center right-3" />
      </div>
    </>
  )
}

UserEmailInput.propTypes = {
  label: types.string,
}

export default UserEmailInput
