import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import * as API from "services/api"
import AddFacilityGroupCode from "./AddFacilityGroupCode"
import FacilityGroupCode from "./FacilityGroupCode"

// eslint-disable-next-line max-len
const listFacilityGroupCodes = ({ facilityGroupCodes }) => facilityGroupCodes.map((facilityGroupCode) => facilityGroupCode.name)

const FacilityGroupCodeList = () => {
  const [facilityGroupCodes, setFacilityGroupCodes] = useState([])

  useEffect(() => {
    const setCurrentFacilityGroupCodes = async () => {
      const response = await API.facilityGroupCodes()
      const currentFacilityGroupCodes = response.data

      setFacilityGroupCodes(currentFacilityGroupCodes)
    }

    setCurrentFacilityGroupCodes()
  }, [])

  const addFacilityGroupCode = useCallback((facilityGroupCode) => {
    setFacilityGroupCodes([...facilityGroupCodes, facilityGroupCode])
  }, [facilityGroupCodes, setFacilityGroupCodes])

  const facilityGroupCodeNames = useMemo(() => listFacilityGroupCodes({ facilityGroupCodes }), [facilityGroupCodes])

  return (
    <>
      <ul className="list-none pl-0 mb-8">
        {
          facilityGroupCodes.map((facilityGroupCode) => (
            <li key={facilityGroupCode.id}>
              <FacilityGroupCode facilityGroupCode={facilityGroupCode} />
            </li>
          ))
        }
      </ul>
      <AddFacilityGroupCode
        addFacilityGroupCode={addFacilityGroupCode}
        facilityGroupCodeNames={facilityGroupCodeNames}
      />
    </>
  )
}

export default FacilityGroupCodeList
