import { useState } from "react"

function useSurveySentRelativeDateFilter({
  surveySentPreviousRangeLength: initialPreviousRangeLength = "",
  surveySentPreviousRangeUnit: initialPreviousRangeUnit = "",
} = {}) {
  const [surveySentPreviousRangeLength, setSurveySentPreviousRangeLength] = useState(initialPreviousRangeLength)
  const [surveySentPreviousRangeUnit, setSurveySentPreviousRangeUnit] = useState(initialPreviousRangeUnit)

  return {
    surveySentPreviousRangeLength,
    surveySentPreviousRangeUnit,
    setSurveySentPreviousRangeLength,
    setSurveySentPreviousRangeUnit,
  }
}

export default useSurveySentRelativeDateFilter
