import React from "react"
import types from "prop-types"
import { workflowConditionsShape, workflowConditionShape } from "utils/propTypeShapes"
import { snakeToCapitalCase } from "utils/stringHelpers"
import { useSelector } from "react-redux"
import findById from "utils/findById"
import {
  ALL_CONDITION_OPERATOR,
  ALWAYS_CONDITION_OPERATOR,
  ANY_CONDITION_OPERATOR,
  QUESTION_ANSWER_CONDITION_PROPERTY,
  SUBFORM_ANSWER_CONDITION_PROPERTY,
  FLAGGED_CONDITION_PROPERTY,
  STATUS_CONDITION_PROPERTY,
  SUBMISSION_AGE_CONDITION_PROPERTY,
  SUBMITTED_FORM_PROPERTY,
  TASK_IS_CLOSED,
  EQUAL_OR_OLDER_PROPERTY_OP,
} from "utils/workflowHelpers"
import {
  getCategoryStatuses,
  getFormQuestions,
  getDepartments,
  getFacilities,
  getFacilityGroupCodes,
} from "reduxSlices/formBuilderSlice"
import { getCategoryPublicForms } from "reduxSlices/workflowsSlice"

const facilityNames = (facilityIds, facilities) => {
  const names = []

  facilities.forEach((facility) => {
    if (facilityIds.includes(facility.id)) {
      names.push(facility.name)
    }
  })

  return names
}

const facilityGroupCodeNames = (facilityGroupCodeIds, facilityGroupCodes) => {
  const names = []

  facilityGroupCodes.forEach((facilityGroupCode) => {
    if (facilityGroupCodeIds.includes(facilityGroupCode.id)) {
      names.push(facilityGroupCode.name)
    }
  })

  return names
}

const CONDITION_OPERATOR_DESCRIPTIONS = {
  [ALL_CONDITION_OPERATOR]: "All these conditions are met:",
  [ALWAYS_CONDITION_OPERATOR]: "This workflow will always run",
  [ANY_CONDITION_OPERATOR]: "One of these conditions is met:",
}

const ConditionsOperatorDescription = ({ operator }) => (
  <p className="mb-4">
    {CONDITION_OPERATOR_DESCRIPTIONS[operator]}
  </p>
)

ConditionsOperatorDescription.propTypes = {
  operator: types.string.isRequired,
}

const ValueLabel = ({ predicate }) => {
  let value
  let extra

  const statuses = useSelector(getCategoryStatuses)
  const facilities = useSelector(getFacilities) || []
  const facilityGroupCodes = useSelector(getFacilityGroupCodes) || []
  const departments = useSelector(getDepartments) || []
  const categoryPublicForms = useSelector(getCategoryPublicForms)

  const {
    statusId, option, facilityId, facilityIds, facilityGroupCodeIds, departmentId, form_id: formId,
  } = predicate.value

  switch (predicate.property) {
  case FLAGGED_CONDITION_PROPERTY: {
    value = ""
    extra = "set"
    break;
  }
  case STATUS_CONDITION_PROPERTY: {
    value = findById(statusId, statuses)?.name
    extra = ""
    break;
  }
  case SUBMISSION_AGE_CONDITION_PROPERTY: {
    value = predicate.value.days
    extra = " days old, or older"
    break;
  }
  case QUESTION_ANSWER_CONDITION_PROPERTY:
  case SUBFORM_ANSWER_CONDITION_PROPERTY:
  {
    if (option) {
      value = option
      extra = ""
    } else if (facilityId) {
      value = findById(facilityId, facilities)?.name
      extra = ""
    } else if (departmentId) {
      value = findById(departmentId, departments)?.name
      extra = ""
    } else if (facilityIds) {
      value = facilityNames(facilityIds, facilities).join(", ")
      extra = ""
    } else if (facilityGroupCodeIds) {
      value = facilityGroupCodeNames(facilityGroupCodeIds, facilityGroupCodes).join(", ")
      extra = ""
    } else {
      value = ""
      extra = ""
    }
    break;
  }
  case SUBMITTED_FORM_PROPERTY: {
    value = findById(formId, categoryPublicForms)?.title
    extra = ""
    break;
  }
  default: {
    value = ""
    extra = ""
  }
  }

  return (
    <>
      { value !== "" && <span className="workflow-white-label mr-2">{value}</span> }
      { extra && <span>{extra}</span> }
    </>
  )
}

ValueLabel.propTypes = {
  predicate: workflowConditionShape.isRequired,
}

const OperatorLabel = ({ predicate }) => {
  const { operator } = predicate

  if (operator === EQUAL_OR_OLDER_PROPERTY_OP) return null

  return (
    <span className="mr-2">{ snakeToCapitalCase(operator) }</span>
  )
}

OperatorLabel.propTypes = {
  predicate: workflowConditionShape.isRequired,
}

const PropertyLabel = ({ predicate }) => {
  const questions = useSelector(getFormQuestions)

  switch (predicate.property) {
  case QUESTION_ANSWER_CONDITION_PROPERTY:
  case SUBFORM_ANSWER_CONDITION_PROPERTY:
  {
    const question = questions.find(({ uuid }) => uuid === predicate.subProperty?.questionUuid)
    return (
      <span className="workflow-white-label mr-2">
        Answer to: &ldquo;{question.prompt}&rdquo;
      </span>
    )
  }
  default: {
    return (
      <span className="workflow-white-label mr-2">
        {snakeToCapitalCase(predicate.property)}
      </span>
    )
  }
  }
}

PropertyLabel.propTypes = {
  predicate: workflowConditionShape.isRequired,
}

const ConditionsLabel = ({ conditions, onetime, trigger }) => {
  const { operator } = conditions
  const predicates = conditions.conditions

  return (
    <>
      <ConditionsOperatorDescription operator={operator} />
      {
        predicates.map((predicate) => (
          <div key={predicate.uuid} className="my-1">
            <PropertyLabel predicate={predicate} />
            <OperatorLabel predicate={predicate} />
            <ValueLabel predicate={predicate} />
          </div>
        ))
      }
      {
        onetime && trigger === TASK_IS_CLOSED && (
          <span className="text-xs">* Only runs once per task</span>
        )
      }
    </>
  )
}

ConditionsLabel.propTypes = {
  conditions: workflowConditionsShape.isRequired,
  onetime: types.bool.isRequired,
  trigger: types.string.isRequired,
}

export default ConditionsLabel
