import { useEffect } from "react"
import usePrevious from "hooks/usePrevious"

const useScrollToAdded = (scrollRef, list) => {
  const previousListLength = usePrevious(list.length)

  useEffect(() => {
    if (previousListLength < list.length) {
      const element = scrollRef.current
      if (element) {
        element.scrollTop = element.scrollHeight
      }
    }
  }, [list, previousListLength, scrollRef])
}

export default useScrollToAdded
