import React from "react"
import { useSolutionLevelScheduledReportExportContext } from "contexts/SolutionLevelScheduledReportExportContext"
import ReportableFieldLocationAnswerFiltersDetails from "./ReportableFieldLocationAnswerFiltersDetails"
import ReportableFieldLocationAnswerFiltersReportableFieldSelect from "./ReportableFieldLocationAnswerFiltersReportableFieldSelect"

const NewSolutionLevelScheduledReportExportFormReportableFieldLocationAnswerFilters = () => {
  const { reportableFieldLocationAnswerFilters } = useSolutionLevelScheduledReportExportContext()

  const reportableFieldLocationAnswerFilter = reportableFieldLocationAnswerFilters[0] ?? {}
  const { reportableFieldId } = reportableFieldLocationAnswerFilter

  return (
    <>
      <ReportableFieldLocationAnswerFiltersReportableFieldSelect />
      {
        reportableFieldId && (
          <ReportableFieldLocationAnswerFiltersDetails />
        )
      }
    </>
  )
}

export default NewSolutionLevelScheduledReportExportFormReportableFieldLocationAnswerFilters
