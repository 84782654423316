import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { useSelector } from "react-redux"
import { showingDeletedTasks as getShowingDeleted } from "reduxSlices/formSubmissionSlice"
import ContentHeader from "shared/ContentHeader"
import AddTask from "FormSubmission/FormSubmissionTabs/ActivitiesContent/AddTask"
import { SidebarVisibilityControl } from "shared/Sidebar"
import OptionsSidebar from "./OptionsSidebar"

const TasksHeader = ({ openForm, hiddenSidebar, toggleSidebar }) => {
  const showingDeletedTasks = useSelector(getShowingDeleted)

  const header = showingDeletedTasks ? "Deleted Tasks" : "Tasks"

  return (
    <>
      <OptionsSidebar
        hiddenSidebar={hiddenSidebar}
        showingDeletedTasks={showingDeletedTasks}
      />
      <ContentHeader
        className={clsx(!hiddenSidebar && "ml-80")}
        leftElements={(
          <SidebarVisibilityControl
            className="hidden lg:flex"
            hiddenSidebar={hiddenSidebar}
            toggleSidebar={toggleSidebar}
          />
        )}
        heading={
          <h1 className="header-title">{ header }</h1>
        }
        rightElements={
          <AddTask openForm={openForm} />
        }
      />
    </>
  )
}

TasksHeader.propTypes = {
  openForm: types.func.isRequired,
  hiddenSidebar: types.bool.isRequired,
  toggleSidebar: types.func.isRequired,
}

export default TasksHeader
