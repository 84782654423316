import React, { useMemo } from "react"
import types from "prop-types"
import clsx from "clsx"
import { isEqual } from "lodash-es"
import { useSelector } from "react-redux"
import {
  selectedCategorySlug,
  selectedFormSlug,
  selectedReportType,
  selectedTaskForms,
  SOLUTION_REPORT_TYPE,
  SURVEY_REPORT_TYPE,
} from "reduxSlices/reportBuilderSlice"
import { LargeWrap } from "shared/wraps"
import { DataCollectorContainer, DataCollectorStep } from "components/shared/dataCollector"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import NameInput from "./NameInput"
import SharedInput from "./SharedInput"
import SolutionSelector from "./SolutionSelector"
import FormSelector from "./FormSelector"
import SubmissionRelatedColumnSelector from "./SubmissionRelatedColumnSelector"
import TaskFormSelector from "./TaskFormSelector"
import TaskDefaultColumnSelector from "./TaskDefaultColumnSelector"
import ReportBuilderActions from "./ReportBuilderActions"
import TaskFormQuestionSelector from "./TaskFormQuestionSelector"
import SolutionReportFormFilters from "./SolutionReportFormFilters"

const isSolutionReport = (reportType) => reportType === SOLUTION_REPORT_TYPE
const isSurveyReport = (reportType) => reportType === SURVEY_REPORT_TYPE

const renderDataCollectorHeaderContent = (reportId, reportType) => {
  let title = "Which form would you like to export?"

  if (reportId) {
    title = "Edit"
  } else if (isSolutionReport(reportType)) {
    title = "Which reportable fields would you like to export?"
  }

  return (<span className="font-medium text-xl">{ title }</span>)
}

const renderStepThree = (formSlug, reportType) => {
  if (isSolutionReport(reportType)) {
    return (
      <DataCollectorStep stepNumber={3} heading="Reportable Fields">
        <SubmissionRelatedColumnSelector />
      </DataCollectorStep>
    )
  }

  const heading = (isSurveyReport(reportType)) ? "Surveys" : "Form"
  return (
    <DataCollectorStep stepNumber={3} heading={heading}>
      <FormSelector className={clsx(formSlug && "mb-8")} />
      { formSlug && <SubmissionRelatedColumnSelector /> }
    </DataCollectorStep>
  )
}

const ReportBuilder = ({ reportId = null }) => {
  const categorySlug = useSelector(selectedCategorySlug)
  const formSlug = useSelector(selectedFormSlug)
  const reportType = useSelector(selectedReportType)
  const taskForms = useSelector(selectedTaskForms, isEqual)

  const showStepFour = useMemo(() => (
    formSlug || (isSolutionReport(reportType) && categorySlug)
  ), [formSlug, reportType, categorySlug])

  const anyTaskFormsSelected = useMemo(() => (
    taskForms.length > 0
  ), [taskForms])

  return (
    <DndProvider backend={HTML5Backend}>
      <LargeWrap>
        <DataCollectorContainer renderHeaderContent={() => renderDataCollectorHeaderContent(reportId, reportType)}>
          <DataCollectorStep stepNumber={1}>
            <NameInput />
            <SharedInput />
          </DataCollectorStep>
          <DataCollectorStep stepNumber={2} heading="Solution">
            <SolutionSelector />
            { categorySlug && isSolutionReport(reportType) && <SolutionReportFormFilters /> }
          </DataCollectorStep>
          {
            categorySlug && renderStepThree(formSlug, reportType)
          }
          {
            showStepFour && (
              <DataCollectorStep stepNumber={4} heading="Task Forms">
                <TaskFormSelector className={clsx(anyTaskFormsSelected && "mb-12")} />
                <div className="space-y-12">
                  <TaskDefaultColumnSelector />
                  <TaskFormQuestionSelector />
                </div>
              </DataCollectorStep>
            )
          }
          <ReportBuilderActions reportId={reportId} />
        </DataCollectorContainer>
      </LargeWrap>
    </DndProvider>
  )
}

ReportBuilder.propTypes = {
  reportId: types.number,
}

export default ReportBuilder
