import React from "react"
import types from "prop-types"
import { ReportContextProvider } from "contexts/ReportContext"
import * as API from "services/api"
import {
  editFormSubmissionScheduledReportExportPath, formSubmissionReportEditPath, newFormSubmissionReportExportPath, newFormSubmissionScheduledReportExportPath,
} from "utils/routeHelpers"
import ReportsSection from "./ReportsSection"

const FormLevelReportsSection = ({ className = "", reports = [] }) => (
  <ReportContextProvider
    reports={reports}
    apiCallToDeleteReport={API.deleteFormSubmissionReport}
    editPath={formSubmissionReportEditPath}
  >
    <ReportsSection
      className={className}
      newReportExportPath={newFormSubmissionReportExportPath}
      newScheduledReportExportPath={newFormSubmissionScheduledReportExportPath}
      editScheduledReportExportPath={editFormSubmissionScheduledReportExportPath}
      title="Form Level Reports"
    />
  </ReportContextProvider>
)

FormLevelReportsSection.propTypes = {
  className: types.string,
  reports: types.arrayOf(types.object),
}

export default FormLevelReportsSection
