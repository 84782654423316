import React, { forwardRef } from "react"
import types from "prop-types"
import clsx from "clsx"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const EllipsisIcon = forwardRef((
  {
    className, width, height, onClick,
  },
  ref,
) => (
  <span ref={ref}>
    <SvgSpriteIcon
      className={clsx(className, "cursor-pointer")}
      iconName="ellipsis"
      width={width}
      height={height}
      onClick={onClick}
    />
  </span>
))

EllipsisIcon.defaultProps = {
  className: "",
  width: undefined,
  height: undefined,
  onClick: () => {},
}

EllipsisIcon.propTypes = {
  className: types.string,
  width: types.number,
  height: types.number,
  onClick: types.func,
}

EllipsisIcon.displayName = "EllipsisIcon"

export default EllipsisIcon
