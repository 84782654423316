import React, { Component } from "react"
import types from "prop-types"
import Formsy from "formsy-react"
import { copyFormToOrganizationPath } from "utils/routeHelpers"
import { copyFormSourceOrganizationShape, categoryOptionShape } from "utils/propTypeShapes"
import { TertiaryButton } from "shared/buttons"
import { OrganizationSelect } from "shared/selects"
import FieldLabel from "./FieldLabel"
import CopyFormToOrganizationFormFields from "./CopyFormToOrganizationFormFields"

const organizationWithId = (organizations, organizationId) => (
  organizations.find((organization) => organization.id === organizationId)
)

const organizationForms = (organizations, organizationId) => (
  organizationWithId(organizations, organizationId)?.forms
)

class CopyFormToOrganization extends Component {
  state = {
    canSubmit: false,
    sourceOrganizationId: this.props.copyFormSourceOrgs[0]?.id,
  }

  disableSubmit = () => this.setState({ canSubmit: false })

  enableSubmit = () => this.setState({ canSubmit: true })

  disabled = () => !this.state.canSubmit

  setSourceOrganizationId = (sourceOrganizationId) => this.setState({ sourceOrganizationId })

  render() {
    const { copyFormSourceOrgs, organizationSubdomain, targetCategories } = this.props
    const { sourceOrganizationId } = this.state

    return (
      <Formsy
        action={copyFormToOrganizationPath(organizationSubdomain)}
        id="copy-form-to-organization-form"
        method="post"
        onInvalid={this.disableSubmit}
        onValid={this.enableSubmit}
        preventDefaultSubmit={false}
      >
        <FieldLabel text="Source organization">
          <OrganizationSelect
            backspaceDelete={false}
            onChange={this.setSourceOrganizationId}
            options={copyFormSourceOrgs}
            value={sourceOrganizationId}
          />
        </FieldLabel>
        <CopyFormToOrganizationFormFields
          sourceForms={organizationForms(copyFormSourceOrgs, sourceOrganizationId)}
          targetCategories={targetCategories}
        />
        <TertiaryButton
          form="copy-form-to-organization-form"
          text="Submit"
          type="submit"
          disabled={this.disabled()}
        />
      </Formsy>
    )
  }
}

CopyFormToOrganization.propTypes = {
  organizationSubdomain: types.string.isRequired,
  copyFormSourceOrgs: types.arrayOf(copyFormSourceOrganizationShape).isRequired,
  targetCategories: types.arrayOf(categoryOptionShape).isRequired,
}

export default CopyFormToOrganization
