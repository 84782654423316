import React from "react"
import Rails from "@rails/ujs"
import Formsy from "formsy-react"
import { useSubmit } from "hooks"
import { ACTIVITY_HUB_REQUIRED_PASSWORD_RESET_PATH, SIGN_OUT_PATH } from "utils/routeHelpers"
import FormsyUserPasswordInput from "shared/inputs/UserPasswordInput/FormsyUserPasswordInput"
import { FormsyHiddenInput } from "shared/inputs"
import { PrimaryButton } from "shared/buttons"
import { isMinLength, MIN_PASSWORD_LENGTH } from "utils/validationHelpers"

const signOutWithoutResettingPassword = () => {
  window.location = SIGN_OUT_PATH
}

const RequiredPasswordResetForm = () => {
  const { canSubmit, enableSubmit, disableSubmit } = useSubmit()

  // Include any query parameters in the path to which the POST request is made.
  // Supports forwarding the user to the page they want to access
  // when the user must first complete a password reset.
  const submitToPath = `${ACTIVITY_HUB_REQUIRED_PASSWORD_RESET_PATH}${window.location.search}`

  return (
    <>
      <Formsy
        action={submitToPath}
        className="mb-8"
        method="post"
        onInvalid={disableSubmit}
        onValid={enableSubmit}
        preventDefaultSubmit={false}
      >
        <FormsyUserPasswordInput
          name="password"
          required
          showErrorMessages={false}
          validationErrors={{ minLength: isMinLength.message }}
          // eslint-disable-next-line max-len
          validations={`isNotBlankString,isStrongPassword,hasLowercaseLetter,hasUppercaseLetter,hasNumber,minLength:${MIN_PASSWORD_LENGTH}`}
        />
        <FormsyUserPasswordInput
          label="Repeat Password"
          name="password_confirmation"
          required
          validationErrors={{ equalsField: "Password does not match" }}
          validations="equalsField:password"
        />
        <FormsyHiddenInput name="authenticity_token" value={Rails.csrfToken()} />
        <PrimaryButton
          className="w-full bg-navy"
          disabled={!canSubmit}
          text="Reset Password"
          type="submit"
        />
      </Formsy>
      <div className="flex flex-col">
        <button
          className="underline text-sm md:text-base cursor-pointer mb-2 text-dark"
          onClick={signOutWithoutResettingPassword}
        >
          I&apos;ll do it later
        </button>
        <span className="text-xs text-dark">
          You will be unable to access the Activity Hub<br />
          until your password has been reset.
        </span>
      </div>
    </>
  )
}

export default RequiredPasswordResetForm
