import React from "react"
import { useFormSubmissionReportExportContext } from "contexts/FormSubmissionReportExportContext"
import * as API from "services/api"
import { SmallWrap } from "shared/wraps"
import { DataCollectorContainer, DataCollectorStep } from "shared/dataCollector"
import { errorToast } from "shared/toast"
import NewFormSubmissionReportExportFormActions from "./NewFormSubmissionReportExportFormActions"
import NewFormSubmissionReportExportFormDateAnswerFilters from "./NewFormSubmissionReportExportFormDateAnswerFilters"
import NewFormSubmissionReportExportFormLocationAnswerFilters from "./NewFormSubmissionReportExportFormLocationAnswerFilters"
import NewFormSubmissionReportExportSubmissionFilters from "./NewFormSubmissionReportExportSubmissionFilters"
import NewFormSubmissionReportExportFileTypeSelect from "./NewFormSubmissionReportExportFileTypeSelect"

const CREATE_REPORT_EXPORT_ERROR_MESSAGE = "Something went wrong.  Unable to initiate export."

const NewFormSubmissionReportExportForm = () => {
  const {
    dateAnswerFilters,
    earliestSubmissionDate,
    formSubmissionReport,
    latestSubmissionDate,
    locationAnswerFilters,
    reportExportFileType,
    setReportExport,
  } = useFormSubmissionReportExportContext()

  const {
    name: formSubmissionReportName,
    id: formSubmissionReportId,
  } = formSubmissionReport

  const renderDataCollectorHeaderContent = () => (
    <span className="font-medium text-xl">{formSubmissionReportName}</span>
  )

  const exportFormSubmissionReport = async () => {
    const response = await API.createFormSubmissionReportReportExport({
      dateAnswerFilters,
      earliestDate: earliestSubmissionDate,
      latestDate: latestSubmissionDate,
      locationAnswerFilters,
      reportFileType: reportExportFileType,
      reportId: formSubmissionReportId,
    })

    if (response.ok) {
      setReportExport(response.data)
    } else {
      errorToast(CREATE_REPORT_EXPORT_ERROR_MESSAGE)
    }
  }

  return (
    <SmallWrap>
      <DataCollectorContainer renderHeaderContent={renderDataCollectorHeaderContent}>
        <div className="mb-6">
          <DataCollectorStep stepNumber={1} heading="Filter by the Date the Submission was Received">
            <NewFormSubmissionReportExportSubmissionFilters />
          </DataCollectorStep>
          <DataCollectorStep stepNumber={2} heading="Filter by the Answer to a Date Question">
            <NewFormSubmissionReportExportFormDateAnswerFilters />
          </DataCollectorStep>
          <DataCollectorStep stepNumber={3} heading="Filter by the Answer to a Location Question">
            <NewFormSubmissionReportExportFormLocationAnswerFilters />
          </DataCollectorStep>
          <DataCollectorStep stepNumber={4} heading="Select File Type">
            <NewFormSubmissionReportExportFileTypeSelect />
          </DataCollectorStep>
        </div>
        <NewFormSubmissionReportExportFormActions exportFormSubmissionReport={exportFormSubmissionReport} />
      </DataCollectorContainer>
    </SmallWrap>
  )
}

export default NewFormSubmissionReportExportForm
