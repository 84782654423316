import { shortMonthDate } from "utils/dateHelpers"

const batchDescription = ({ surveyBatchName, surveyBatchCreatedAt }) => (
  `${surveyBatchName} from ${shortMonthDate(surveyBatchCreatedAt)}`
)

const contactDescription = ({ contactEmail, contactFullName }) => (
  contactFullName
    ? `${contactFullName} (${contactEmail})`
    : contactEmail
)

const reminderRecipient = ({ contactEmail, reminderCCEmail }) => (
  reminderCCEmail
    ? `both ${contactEmail} and ${reminderCCEmail}`
    : contactEmail
)

export const commentCopy = ({ comment }) => (
  comment || "N/A"
)

// Presented to Activity Hub users when viewing the submission
export const activityHubSurveyDetailsCopy = ({
  contactFullName, contactEmail, surveyBatchAnonymous, surveyBatchName, surveyBatchCreatedAt,
}) => (
  surveyBatchAnonymous
    ? (
      `This submission is for ${batchDescription({ surveyBatchName, surveyBatchCreatedAt })}. `
      + "The survey is anonymous, so no further details are available."
    )
    : (
      `This form was sent to ${contactDescription({ contactEmail, contactFullName })} `
      + `for ${batchDescription({ surveyBatchName, surveyBatchCreatedAt })}.`
    )
)

export const activityHubRemindersCopy = ({ contactEmail, reminderCCEmail }) => (
  `Any reminder emails were sent to ${reminderRecipient({ contactEmail, reminderCCEmail })}.`
)

// Presented to the contact when filling out the survey
export const formFillingSurveyDetailsCopy = ({
  contactFullName, contactEmail, surveyBatchAnonymous, surveyBatchName, surveyBatchCreatedAt,
}) => (
  surveyBatchAnonymous
    ? (
      `Please complete this form for ${batchDescription({ surveyBatchName, surveyBatchCreatedAt })}.`
    )
    : (
      `This form was sent to ${contactDescription({ contactEmail, contactFullName })} to complete `
    + `for ${batchDescription({ surveyBatchName, surveyBatchCreatedAt })}.`
    )
)

export const formFillingRemindersCopy = ({ contactEmail, reminderCCEmail }) => (
  `Any reminder emails will be sent to ${reminderRecipient({ contactEmail, reminderCCEmail })}.`
)
