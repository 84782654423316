import React from "react"
import { useSelector } from "react-redux"
import { facilityName } from "reduxSlices/adminFacilitySlice"
import { FormsyTextInput } from "shared/inputs"

const FacilityName = () => {
  const name = useSelector(facilityName)

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="field-name">Name</label>
      <FormsyTextInput
        className="bordered-base-input text-sm"
        name="name"
        placeholder="Name"
        value={name || ""}
        required
        validations={{ isNotBlankString: true }}
        validationErrors={{ isDefaultRequiredValue: "required" }}
      />
    </>
  )
}

export default FacilityName
