import React from "react"
import types from "prop-types"
import { Button } from "shared/buttons"

const CancelButton = ({ cancel }) => (
  <Button
    className="w-50 border border-light"
    form="task-form"
    text="Cancel"
    type="reset"
    onClick={cancel}
  />
)

CancelButton.propTypes = {
  cancel: types.func.isRequired,
}

export default CancelButton
