import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"
import AuditAnswerDetails from "../AuditAnswerDetails"

const TaskAnsweredAuditItemDetails = ({ audit }) => {
  const { auditedChanges, task } = audit

  return (
    <div>
      <span className="block mb-6">
        <AuditedUser audit={audit} /> answered a question
        on the task titled <span className="font-semibold">{task.title}</span>.
      </span>
      {
        Object.keys(auditedChanges).map((prompt) => (
          <AuditAnswerDetails key={prompt} prompt={prompt} audit={audit} />
        ))
      }
    </div>
  )
}

TaskAnsweredAuditItemDetails.propTypes = {
  audit: auditShape.isRequired,
}

export default TaskAnsweredAuditItemDetails
