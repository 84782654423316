import React from "react"
import types from "prop-types"

const showErrors = (isPristine, errorMessages, isStatusValid) => {
  if (!isPristine && !!errorMessages.length) {
    return true
  }
  if (!isStatusValid && !!errorMessages.length) {
    return true
  }

  return false
}

const ErrorArrow = () => (
  <div className="bg-error h-3 w-3 transform rotate-45 -mb-[8.485px]" />
)

const ErrorMessageText = ({ children }) => (
  <p className="py-2 px-5 bg-error rounded-md text-center text-xs text-white capitalize pointer-events-auto">
    {children}
  </p>
)

ErrorMessageText.propTypes = {
  children: types.node.isRequired,
}

const InputErrorMessage = ({ isPristine = false, errorMessages, isStatusValid = true }) => (
  showErrors(isPristine, errorMessages, isStatusValid) && (
    <div className="relative">
      <div className="absolute-horizontal-center w-[80vw] max-w-2xl pt-3 z-1 pointer-events-none">
        <div className="flex flex-col items-center w-full">
          <ErrorArrow />
          <ErrorMessageText>
            { errorMessages[0] }
          </ErrorMessageText>
        </div>
      </div>
    </div>
  )
)

InputErrorMessage.propTypes = {
  isStatusValid: types.bool,
  isPristine: types.bool,
  errorMessages: types.arrayOf(types.string).isRequired,
}

export default InputErrorMessage
