import React from "react"
import types from "prop-types"
import AttachmentCard from "./AttachmentCard"

const Attachments = ({ attachments }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
    {
      attachments.map((attachment) => (
        <AttachmentCard key={attachment.id} attachment={attachment} />
      ))
    }
  </div>
)

Attachments.propTypes = {
  attachments: types.arrayOf(types.object).isRequired,
}

export default Attachments
