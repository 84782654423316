import React, { forwardRef } from "react"
import Button from "./Button"
import buttonPropTypes from "./buttonPropTypes"

const IconButton = forwardRef((
  {
    className,
    icon,
    onClick,
    background,
    color,
    text,
    ...rest
  },
  ref,
) => (
  <Button
    className={className}
    ref={ref}
    background={background}
    text={text}
    color={color}
    icon={icon}
    onClick={onClick}
    {...rest}
  />
))

IconButton.displayName = "IconButton"

IconButton.propTypes = buttonPropTypes

export default IconButton
