/* eslint-disable camelcase */
import React from "react"
import types from "prop-types"
import { AccessLevelSelect } from "shared/selects"
import * as API from "services/api"
import { formAccessLevel } from "./utils"
import AccessExplanation from "./AccessExplanation"

const AccessLevelIdentifier = ({
  categoryGrant = null,
  formGrant = null,
  updateAccessGranteeLists,
  formSlug,
  granteeType: grantee_type,
  granteeId: grantee_id,
}) => {
  const applicableGrant = formAccessLevel({ categoryGrant, formGrant })
  const { accessLevel } = applicableGrant

  const manageFormAccessGrant = async (access_level) => {
    const access_level_grant = { access_level, grantee_type, grantee_id }
    const response = await API.manageFormAccessGrant({ access_level_grant, formSlug })
    if (!response.ok) {
      console.error("Error updating access level grant: ", response);
    } else {
      updateAccessGranteeLists(response.data)
    }
  }

  return (
    <div className="flex-center">
      <AccessExplanation categoryGrant={categoryGrant} formGrant={formGrant} />
      <AccessLevelSelect
        value={accessLevel}
        onChange={manageFormAccessGrant}
      />
    </div>
  )
}

/* eslint-disable react/forbid-prop-types */
AccessLevelIdentifier.propTypes = {
  categoryGrant: types.object,
  formGrant: types.object,
  updateAccessGranteeLists: types.func.isRequired,
  formSlug: types.string.isRequired,
  granteeType: types.string.isRequired,
  granteeId: types.number.isRequired,
}
/* eslint-enable react/forbid-prop-types */

export default AccessLevelIdentifier
