import React from "react"
import { taskShape } from "utils/propTypeShapes"
import TaskGeneralResponse from "./TaskGeneralResponse"
import TaskSubform from "./TaskSubform"

const TaskResponse = ({ task }) => {
  const { subform } = task
  const ResponseComponent = subform ? TaskSubform : TaskGeneralResponse

  return (
    <ResponseComponent task={task} />
  )
}

TaskResponse.propTypes = {
  task: taskShape.isRequired,
}

export default TaskResponse
