/* eslint-disable react/prop-types,react-hooks/rules-of-hooks */
import React, { useRef } from "react"
import clsx from "clsx"
import { useSelector } from "react-redux"
import { getFacilities } from "reduxSlices/facilitiesSlice"
import Options from "shared/Options"
import SearchInput from "shared/multiSelects/SearchInput"

const doesMatchSearch = (search, label) => label.toLowerCase().includes(search.toLowerCase())

// eslint-disable-next-line react/display-name
const FacilityDepartmentDropdown = ({
  props: { options, placeholder },
  state: {
    search,
  },
  methods: { setSearch, addItem },
}) => {
  const facilities = useSelector(getFacilities)
  const searchInputRef = useRef()

  const selectOption = (option) => {
    addItem(option)
    searchInputRef?.current?.focus()
  }

  const filteredOptions = options.filter(({ label }) => doesMatchSearch(search, label))

  const onSearchInputEnter = ({ key }) => {
    if (key === "Enter" && filteredOptions.length === 1) {
      selectOption(filteredOptions[0])
    }
  }

  const optionsGroupedByFacility = filteredOptions.reduce((group, option) => {
    const { facilityId } = option
    group[facilityId] = group[facilityId] ?? []
    group[facilityId].push(option)
    return group
  }, {})

  return (
    <div className={clsx("top-0 visible bg-white border w-full rounded-md border-lightgray-3 overflow-y-auto")}>
      <SearchInput
        ref={searchInputRef}
        isSelectorHidden={false}
        search={search}
        setSearch={setSearch}
        onSearchInputEnter={onSearchInputEnter}
        placeholder={placeholder}
      />
      <>
        {
          facilities.map((facility) => {
            const optionsForFacility = optionsGroupedByFacility[facility.id]

            return (optionsForFacility
              && (
                <Options
                  key={facility.id}
                  title={`${facility.name}${!(facility.active ?? true) ? " -- (Inactive)" : ""}`}
                  selectOption={selectOption}
                  options={optionsForFacility}
                  search={search}
                />
              )
            )
          })
        }
      </>
    </div>
  )
}

export default FacilityDepartmentDropdown
