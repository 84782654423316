const BYTES_PER_MB = 1048576

export const convertMegabytesToBytes = (mb) => mb * BYTES_PER_MB

const BYTE_UNITS = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

// Modified from https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
export const humanFileSize = (bytes) => {
  let i = -1

  do {
    bytes /= 1024
    i += 1
  } while (bytes > 1024)

  return `${Math.max(bytes, 0.1).toFixed(1)} ${BYTE_UNITS[i]}`
}
