import React from "react"
import types from "prop-types"

const DragHandle = ({ dragProvided }) => (
  <div className="flex items-center" {...dragProvided.dragHandleProps}>
    <i className="m-2 bg-white fas fa-arrows-alt-v" />
  </div>
)

DragHandle.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dragProvided: types.object.isRequired,
}

export default DragHandle
