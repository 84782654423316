import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import FormTypeCard from "./FormTypeCard"

const RegularFormCard = ({ onClick }) => (
  <FormTypeCard
    footerColor="bg-regular-form-color"
    description="Public intake form. Submitted forms become “Submissions” in the Activity Hub."
    icon={<SvgSpriteIcon iconName="form" className="w-10 h-10" />}
    title="Form"
    onClick={onClick}
  />
)

RegularFormCard.propTypes = {
  onClick: types.func.isRequired,
}

export default RegularFormCard
