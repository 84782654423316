import React from "react"
import { useHistoricalAccessToolContext } from "contexts/HistoricalAccessToolContext"
import { RadioButton } from "shared/inputs"

const HistoricalAccessToolSolution = () => {
  const { categories, selectedCategorySlug, setSelectedCategorySlug } = useHistoricalAccessToolContext()

  return (
    <>
      {
        categories.map(({ slug, name }) => (
          <RadioButton
            key={slug}
            checked={slug === selectedCategorySlug}
            className="mb-4 last:mb-0"
            label={name}
            name="historical-access-tool-solution-slug"
            onChange={setSelectedCategorySlug}
            value={slug}
          />
        ))
      }
    </>
  )
}

export default HistoricalAccessToolSolution
