import React from "react"
import { useSelector } from "react-redux"
import { facilityId } from "reduxSlices/adminFacilitySlice"
import * as FacilityFields from "./FacilityFields"

const AttributesTab = () => {
  const id = useSelector(facilityId)

  return (
    <>
      <h2 className="tab-title">Attributes</h2>
      {
        id && (
          <>
            <FacilityFields.Active />
            <FacilityFields.FacilityGroupCodes />
          </>
        )
      }
    </>
  )
}

export default AttributesTab
