import React, { useState } from "react"
import types from "prop-types"
import { store } from "store"
import { SidebarCollapsibleContent } from "shared/Sidebar"
import Switch from "shared/Switch"
import { setShowDeletedTasks } from "reduxSlices/formSubmissionSlice"

const TasksDeletedFilter = ({ showingDeletedTasks }) => {
  const [isChecked, setIsChecked] = useState(showingDeletedTasks)

  const toggleShowDeletedTasks = (value) => {
    store.dispatch(setShowDeletedTasks(value))
  }

  const toggleFilter = () => {
    setIsChecked(!isChecked)
    toggleShowDeletedTasks(!isChecked)
  }

  return (
    <SidebarCollapsibleContent title="Options">
      <div className="flex-center text-sm font-semibold">
        <span>Show deleted tasks</span>
        <Switch
          checked={isChecked}
          onChange={toggleFilter}
        />
      </div>
      <div className="mt-3 text-sm">Will show only the deleted tasks</div>
    </SidebarCollapsibleContent>
  )
}

TasksDeletedFilter.propTypes = {
  showingDeletedTasks: types.bool.isRequired,
}

export default TasksDeletedFilter
