import {
  SUBMITTER_FIRST_NAME_QUESTION_TAG,
  SUBMITTER_FULL_NAME_QUESTION_TAG,
  SUBMITTER_LAST_NAME_QUESTION_TAG,
} from "./submitterQuestionTags"

// The data used to populate the submitter-related question tags
// is the current user data received from the SMART on FHIR EHR system.
// The data is expected to be a FHIR Practioner resource.
class SubmitterDataReader {
  constructor(fhirPractitionerData) {
    this.fhirPractitionerData = fhirPractitionerData
  }

  // Names arrive as a list of HumanName resources.
  // We identify the appropriate name based on the recommendation
  // to select the name whose "use" value is "usual". If it's not found,
  // then we fall back to the first name received.
  //
  // https://www.hl7.org/fhir/practitioner-definitions.html#Practitioner.name
  practitionerNameNode() {
    return this.fhirPractitionerData?.name?.find((node) => (
      node.use?.match(/usual/i)
    )) || this.fhirPractitionerData?.name?.[0]
  }

  get [SUBMITTER_FIRST_NAME_QUESTION_TAG]() {
    return this.practitionerNameNode()?.given?.join(" ") ?? ""
  }

  get [SUBMITTER_FULL_NAME_QUESTION_TAG]() {
    return this.practitionerNameNode()?.text ?? ""
  }

  get [SUBMITTER_LAST_NAME_QUESTION_TAG]() {
    return this.practitionerNameNode()?.family ?? ""
  }
}

export default SubmitterDataReader
