import React from "react"
import { SideTabs } from "shared/tabs"
import SurveyConfigurationBatchesTab, { BATCHES_HEADING } from "./SurveyConfigurationBatchesTab"
import SurveyConfigurationContactsTab, { CONTACTS_TAB_HEADING } from "./SurveyConfigurationContactsTab"
import SurveyConfigurationSettingsTab, { SETTINGS_HEADING } from "./SurveyConfigurationSettingsTab"

const TABS = [
  {
    title: BATCHES_HEADING,
    TabContent: SurveyConfigurationBatchesTab,
  },
  {
    title: CONTACTS_TAB_HEADING,
    TabContent: SurveyConfigurationContactsTab,
  },
  {
    title: SETTINGS_HEADING,
    TabContent: SurveyConfigurationSettingsTab,
  },
]

const SurveyConfigurationTabs = () => (
  <SideTabs tabs={TABS} />
)

export default SurveyConfigurationTabs
