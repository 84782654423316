import React from "react"
import types from "prop-types"
import { adminSolutionPath } from "utils/routeHelpers"
import EmptyCategories from "./EmptyCategories"
import CategoryInfo from "./CategoryInfo"
import CategoryMenu from "./CategoryMenu"

const goToCategoryEdit = (category) => () => {
  window.location.pathname = adminSolutionPath(category.slug)
}

const CategoryList = ({ categories }) => {
  if (categories.length === 0) return <EmptyCategories />

  return (
    <>
      {
        categories.map((category) => (
          <div key={category.id} className="list-row flex-center">
            <CategoryInfo category={category} goToCategoryEdit={goToCategoryEdit} />
            <CategoryMenu category={category} goToCategoryEdit={goToCategoryEdit} />
          </div>
        ))
      }
    </>
  )
}

CategoryList.propTypes = {
  categories: types.arrayOf(types.object).isRequired,
}

export default CategoryList
