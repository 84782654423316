import React from "react"
import types from "prop-types"
import Popup from "reactjs-popup"
import "./tooltipStyle.scss"
import clsx from "clsx"

const POPUP_COLOR = "#EBEBF5";
const arrowStyle = { color: POPUP_COLOR }

export const TOP_LEFT = "top left"
export const TOP_CENTER = "top center"
export const TOP_RIGHT = "top right"
export const RIGHT_TOP = "right top"
export const RIGHT_CENTER = "right center"
export const RIGHT_BOTTOM = "right bottom"
export const BOTTOM_LEFT = "bottom left"
export const BOTTOM_CENTER = "bottom center"
export const BOTTOM_RIGHT = "bottom right"
export const LEFT_TOP = "left top"
export const LEFT_CENTER = "left center"
export const LEFT_BOTTOM = "left bottom"
export const CENTER_CENTER = "center center"

const Tooltip = ({
  maxHeight = "max-h-full",
  className = "",
  text = "",
  position = BOTTOM_CENTER,
  children,
}) => {
  if (!text) {
    return children
  }

  return (
    <Popup
      trigger={<span>{children}</span>}
      on={["hover", "focus"]}
      position={position}
      arrowStyle={arrowStyle}
      keepTooltipInside
    >
      <div
        className={clsx("max-w-xs px-5 py-3 text-sm leading-7 rounded-lg shadow", maxHeight, className)}
        style={{
          backgroundColor: POPUP_COLOR,
        }}
      >
        <span className="opacity-80 whitespace-pre-wrap">
          {text}
        </span>
      </div>
    </Popup>
  )
}

Tooltip.propTypes = {
  children: types.node.isRequired,
  className: types.string,
  maxHeight: types.string,
  text: types.string,
  position: types.string,
}

export default Tooltip
