import React from "react"
import types from "prop-types"
import { store } from "store"
import { useSelector } from "react-redux"
import { getTaskTable, setCurrentSort } from "reduxSlices/taskTableSlice"
import { activityHubSubmissionAndTaskPath } from "utils/routeHelpers"
import { CustomHeaderAccordion, useAccordion } from "shared/accordions"
import GroupingAccordionHeader from "shared/headers/GroupingAccordionHeader"
import Table from "shared/Table"

const rowHrefFunc = (rowData) => activityHubSubmissionAndTaskPath(rowData.submissionSlug, rowData.id)

const TaskGrouping = ({ columns, groupName, tableData }) => {
  const [isExpanded, toggleAccordion] = useAccordion(true)
  const { currentSort } = useSelector(getTaskTable)
  const onSort = (column) => store.dispatch(setCurrentSort(column))

  if (!tableData.length) return null

  return (
    <CustomHeaderAccordion
      className="mb-12"
      isExpanded={isExpanded}
      header={(
        <GroupingAccordionHeader
          numberOfSubmissions={tableData.length}
          groupName={groupName}
          isExpanded={isExpanded}
          toggleAccordion={toggleAccordion}
        />
      )}
      content={(
        <Table
          className="tasks"
          rowKey="id"
          columns={columns}
          currentSort={currentSort}
          rowHrefFunc={rowHrefFunc}
          onSort={onSort}
          tableData={tableData}
        />
      )}
    />
  )
}

TaskGrouping.propTypes = {
  groupName: types.string.isRequired,
  columns: types.arrayOf(types.object).isRequired,
  tableData: types.arrayOf(types.object).isRequired,
}

export default TaskGrouping
