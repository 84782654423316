import React from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { useSelector } from "react-redux"
import { getFormSectionQuestionUuids } from "reduxSlices/formBuilderSlice"
import QuestionSidebar from "../../QuestionFields/QuestionSidebar"
import SectionBranching from "./SectionBranching"

const SectionSidebar = ({ sectionIndex }) => {
  const questionUuids = useSelector(getFormSectionQuestionUuids(sectionIndex), isEqual)

  return (
    <>
      <SectionBranching sectionIndex={sectionIndex} />
      {
        questionUuids.map((questionUuid, questionIndex) => (
          <QuestionSidebar key={questionUuid} sectionIndex={sectionIndex} questionIndex={questionIndex} />
        ))
      }
    </>
  )
}

SectionSidebar.propTypes = {
  sectionIndex: types.number.isRequired,
}

export default SectionSidebar
