import { useEffect } from "react"
import types from "prop-types"
import { usePrevious } from "hooks"

const bulkAddEventListener = (object, events, callback) => {
  events.forEach((event) => {
    object.addEventListener(event, callback)
  })
}

const bulkRemoveEventListener = (object, events, callback) => {
  events.forEach((event) => {
    object.removeEventListener(event, callback)
  })
}

const UserEventMonitor = ({ events = undefined, onEvent }) => {
  const prevEvents = usePrevious(events)
  const prevCallback = usePrevious(onEvent)

  useEffect(() => {
    if (prevEvents) {
      bulkRemoveEventListener(window, prevEvents, prevCallback)
    }

    if (events) {
      bulkAddEventListener(window, events, onEvent)
    }

    return () => {
      if (events) {
        bulkRemoveEventListener(window, events, onEvent)
      }
    }
  }, [events, onEvent, prevCallback, prevEvents])

  return null
}

UserEventMonitor.propTypes = {
  events: types.arrayOf(types.string),
  onEvent: types.func.isRequired,
}

export default UserEventMonitor
