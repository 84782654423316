/* eslint-disable camelcase */
import React, { useState, useEffect } from "react"
import types from "prop-types"
import * as API from "services/api"
import { UserSelect } from "shared/selects"

const filteredSelectedUsers = (allUsers, selectedUsers) => {
  const userIds = selectedUsers.map((user) => user.id)
  return allUsers.filter((item) => !userIds.includes(item.id))
}

const UserSearch = ({ onUserSelected, userList = [] }) => {
  const [allUsers, setAllUsers] = useState([])

  useEffect(() => {
    (async function fetchUsers() {
      const response = await API.getUsers({ active: true })
      if (response.ok) {
        setAllUsers(response.data)
      } else {
        console.error("Error fetching users: ", response)
      }
    }())
  }, [])

  return (
    <UserSelect
      className="my-5 text-left"
      users={filteredSelectedUsers(allUsers, userList)}
      onChange={onUserSelected}
    />
  )
}

UserSearch.propTypes = {
  onUserSelected: types.func.isRequired,
  userList: types.arrayOf(types.object),
}

export default UserSearch
