import React from "react"
import types from "prop-types"
import SelectCSVUploadCSV from "../SelectCSVUploadCSV"
import SelectCSVUploadDetailsPreviousUpload from "./SelectCSVUploadDetailsPreviousUpload"

const SelectCSVUploadDetails = ({ questionIndex, sectionIndex }) => (
  <div className="mb-4">
    <div className="flex flex-center mb-4">
      <div className="font-medium">CSV Details</div>
      <SelectCSVUploadCSV questionIndex={questionIndex} sectionIndex={sectionIndex} />
    </div>
    <SelectCSVUploadDetailsPreviousUpload questionIndex={questionIndex} sectionIndex={sectionIndex} />
  </div>
)

SelectCSVUploadDetails.propTypes = {
  questionIndex: types.number.isRequired,
  sectionIndex: types.number.isRequired,
}

export default SelectCSVUploadDetails
