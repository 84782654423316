import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { useSolutionLevelScheduledReportExportContext } from "contexts/SolutionLevelScheduledReportExportContext"
import MassSelection from "shared/MassSelection"
import ReportableFieldLocationAnswerFiltersLocationDetailsSelectedFacilityDepartmentCheckbox from "./ReportableFieldLocationAnswerFiltersLocationDetailsSelectedFacilityDepartmentCheckbox"

const ReportableFieldLocationAnswerFiltersLocationDetailsSelectedFacilityDepartments = ({ className = "", selectedFacilityId }) => {
  const {
    addAllFacilityDepartmentsToReportableFieldLocationAnswerFilters,
    facilities,
    facilityDepartments,
    removeAllFacilityDepartmentsFromReportableFieldLocationAnswerFilters,
  } = useSolutionLevelScheduledReportExportContext()

  const selectedFacility = facilities.find((facility) => facility.id === selectedFacilityId)

  const departments = facilityDepartments(selectedFacilityId)

  return (
    <div className={clsx("border border-lightgray-3 rounded p-4 mb-4", className)}>
      <p className="mb-4">{selectedFacility?.name}</p>
      <MassSelection
        className="mb-4"
        deselectAll={() => removeAllFacilityDepartmentsFromReportableFieldLocationAnswerFilters(selectedFacilityId)}
        selectAll={() => addAllFacilityDepartmentsToReportableFieldLocationAnswerFilters(selectedFacilityId)}
      />
      {
        departments.map((department) => (
          <ReportableFieldLocationAnswerFiltersLocationDetailsSelectedFacilityDepartmentCheckbox
            key={department.id}
            department={department}
          />
        ))
      }
    </div>
  )
}

ReportableFieldLocationAnswerFiltersLocationDetailsSelectedFacilityDepartments.propTypes = {
  className: types.string,
  selectedFacilityId: types.number.isRequired,
}

export default ReportableFieldLocationAnswerFiltersLocationDetailsSelectedFacilityDepartments
