const folderContext = require.context("./", false, /\.jsx$/)

const moduleExports = folderContext
  .keys()
  .reduce((components, fileName) => {
    const folderName = fileName.replace(/(\.\/|\.jsx)/g, "")
    const context = folderContext(fileName)

    Object.keys(context)
      .filter((val) => val !== "default")
      .forEach((exportName) => {
        components[exportName] = context[exportName]
      })
    components[folderName] = context.default

    return components
  }, {})

module.exports = moduleExports
