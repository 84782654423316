import React from "react"
import types from "prop-types"

const WorkflowTileSection = ({ children, title }) => (
  <div className="pb-4">
    <div className="font-semibold pb-1">{title}</div>
    { children }
  </div>
)

WorkflowTileSection.propTypes = {
  children: types.node.isRequired,
  title: types.string.isRequired,
}

export default WorkflowTileSection
