import { SessionStoragePersistence } from "utils/Persistence"
import EncounterDataReader from "./EncounterDataReader"
import PatientDataReader from "./PatientDataReader"
import SubmitterDataReader from "./SubmitterDataReader"
import { ENCOUNTER_QUESTION_TAGS } from "./encounterQuestionTags"
import { PATIENT_QUESTION_TAGS } from "./patientQuestionTags"
import { SUBMITTER_QUESTION_TAGS } from "./submitterQuestionTags"

const KEY = "fhir"

function persistValue(fhirResourceReader, questionTag) {
  SessionStoragePersistence.persist({
    key: KEY,
    property: questionTag,
    value: fhirResourceReader[questionTag],
  })
}

function persistFhirResourceForQuestionTags(fhirResourceReader, questionTags) {
  questionTags.forEach((questionTag) => {
    persistValue(fhirResourceReader, questionTag)
  })
}

const FHIRPersistence = {
  allData() {
    return SessionStoragePersistence.getValue({ key: KEY })
  },

  clear() {
    SessionStoragePersistence.clear({ key: KEY })
  },

  lookup(property) {
    return SessionStoragePersistence.lookup({ key: KEY, property })
  },

  persistEncounter(fhirEncounterData) {
    persistFhirResourceForQuestionTags(
      new EncounterDataReader(fhirEncounterData),
      ENCOUNTER_QUESTION_TAGS,
    )
  },

  persistPatient(fhirPatientData) {
    persistFhirResourceForQuestionTags(
      new PatientDataReader(fhirPatientData),
      PATIENT_QUESTION_TAGS,
    )
  },

  persistSubmitter(fhirPractitionerData) {
    persistFhirResourceForQuestionTags(
      new SubmitterDataReader(fhirPractitionerData),
      SUBMITTER_QUESTION_TAGS,
    )
  },
}

export default FHIRPersistence
