import React from "react"
import { taskShape } from "utils/propTypeShapes"
import TaskPanelSection from "./TaskPanelSection"

const TaskPanelNotes = ({ task }) => {
  const { description } = task

  if (!description) return null

  return (
    <TaskPanelSection name="Notes">
      <p className="whitespace-pre-wrap">
        { description }
      </p>
    </TaskPanelSection>
  )
}

TaskPanelNotes.propTypes = {
  task: taskShape.isRequired,
}

export default TaskPanelNotes
