/* eslint-disable no-unused-vars */
import React, { useCallback } from "react"
import clsx from "clsx"
import { useDrop } from "react-dnd"
import { useDispatch, useSelector } from "react-redux"
import {
  facilityId as getFacilityId,
  addMemberToDepartmentSegment,
  removeMemberFromDepartment,
} from "reduxSlices/adminFacilitySlice"
import { useOncePerPageLoadMessage } from "hooks"
import * as API from "services/api"
import { adminFacilityDepartmentShape } from "utils/propTypeShapes"
import { TrashCanIcon } from "shared/icons"
import { errorToast } from "shared/toast"
import { DraggableItemTypes } from "../DraggableItemTypes"

const DEPARTMENT_REMOVAL_MESSAGE = "Removing a user from a department does not remove the user "
                                   + "from any existing tasks or form submissions. Removal from existing tasks "
                                   + "or form submissions must be done manually or by using "
                                   + "the Historical Access Tool under the user's profile in the Admin area."

const RemoveMemberDropzone = ({ department }) => {
  const { displayMessage: displayRemovalMessage } = useOncePerPageLoadMessage(DEPARTMENT_REMOVAL_MESSAGE, "department_removal_message")

  const { id: departmentId, segments } = department

  const dispatch = useDispatch()

  const facilityId = useSelector(getFacilityId)

  const memberSegment = (memberId) => segments.find((segment) => (
    segment.members.some((member) => member.id === memberId)
  ))

  const canRemoveMember = useCallback((deptId) => (
    deptId === departmentId
  ), [departmentId])

  const removeDepartmentMember = useCallback(async ({ departmentId: deptId, memberId, user }) => {
    const currentSegment = memberSegment(memberId)
    dispatch(removeMemberFromDepartment({ departmentId: deptId, memberId }))

    const response = await API.deleteDepartmentMembership({
      facilityId,
      departmentId: deptId,
      memberId,
    })

    if (!response.ok) {
      dispatch(addMemberToDepartmentSegment({ departmentId: deptId, member: user, segmentName: currentSegment.name }))
      errorToast("Error removing member from department")
    } else {
      displayRemovalMessage()
    }
  }, [dispatch, facilityId])

  const [collected, drop] = useDrop(() => ({
    accept: DraggableItemTypes.MEMBER_PROFILE_ICON,
    canDrop: (item, monitor) => (
      canRemoveMember(item.departmentId)
    ),
    collect: (monitor, props) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: (item, monitor) => {
      removeDepartmentMember(item)
    },
  }), [canRemoveMember, removeDepartmentMember])

  const className = clsx(
    "rounded p-1 border",
    (
      collected.isOver && collected.canDrop
        ? "bg-green-200 border-green-800"
        : "bg-primary-light border-light"
    ),
  )

  return (
    <div ref={drop} className={className}>
      <TrashCanIcon className="w-4 h-4" />
    </div>
  )
}

RemoveMemberDropzone.propTypes = {
  department: adminFacilityDepartmentShape.isRequired,
}

export default RemoveMemberDropzone
