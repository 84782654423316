import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { useFormSubmissionAttachmentDropzoneContext } from "contexts/FormSubmissionAttachmentDropzoneContext"

const AttachmentDropzone = ({ children }) => {
  const {
    getAttachmentDropzoneRootProps, getAttachmentDropzoneInputProps, isOverAttachmentDropzone,
  } = useFormSubmissionAttachmentDropzoneContext()

  return (
    <div {...getAttachmentDropzoneRootProps({
      className: "w-full",
    })}
    >
      <input {...getAttachmentDropzoneInputProps()} />
      <div className={clsx(isOverAttachmentDropzone && "bg-green-200 rounded-lg border border-green-800", "w-full")}>
        <div className={clsx(isOverAttachmentDropzone && "opacity-20")}>
          {children}
        </div>
      </div>
    </div>
  )
}

AttachmentDropzone.propTypes = {
  children: types.node.isRequired,
}

export default AttachmentDropzone
