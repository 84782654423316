import { useContext } from "react"
import FormSubmissionScheduledReportExportContext from "../FormSubmissionScheduledReportExportContext"

const useFormSubmissionScheduledReportExportContext = () => {
  const providedValue = useContext(FormSubmissionScheduledReportExportContext)

  return { ...providedValue }
}

export default useFormSubmissionScheduledReportExportContext
