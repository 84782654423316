import React from "react"
import types from "prop-types"

const Answer = ({ rawAnswer, isNewQuestion }) => {
  if (isNewQuestion) return null

  return (
    <div className="answer">
      { rawAnswer }
    </div>
  )
}

Answer.defaultProps = {
  rawAnswer: "",
  isNewQuestion: false,
}

Answer.propTypes = {
  rawAnswer: types.oneOfType([types.string, types.number]),
  isNewQuestion: types.bool,
}

export default Answer
