import React from "react"
import { isEmpty } from "lodash-es"
import { useFacilityAuditTrailContext } from "contexts/FacilityAuditTrailContext"
import StrikeAround from "shared/StrikeAround"
import AuditItem from "./AuditItem"

const FacilityAuditTrailGroups = () => {
  const { auditGroups } = useFacilityAuditTrailContext()

  if (isEmpty(auditGroups)) {
    return (
      <p className="text-center mb-12">
        There are no audits for this facility.
      </p>
    )
  }

  return (
    <>
      {
        Object.entries(auditGroups).map(([formattedDate, audits]) => (
          <div key={formattedDate}>
            <StrikeAround className="mb-9">
              <span className="font-medium text-xl">{ formattedDate }</span>
            </StrikeAround>
            {
              audits.map((audit) => (
                <AuditItem key={audit.id} audit={audit} />
              ))
            }
          </div>
        ))
      }
    </>
  )
}

export default FacilityAuditTrailGroups
