import React from "react"
import types from "prop-types"
import {
  Menu,
  MenuItem,
  MenuButton,
  MenuDivider,
  SubMenu,
} from "@szhsin/react-menu"
import "@szhsin/react-menu/dist/index.css"

// https://szhsin.github.io/react-menu/

const ExpandMenu = ({
  children,
  expandMenuComponent = ({ open }) => <MenuButton>{open ? "Close" : "Open"}</MenuButton>,
}) => (
  <Menu menuButton={expandMenuComponent}>
    {
      children
    }
  </Menu>
)

ExpandMenu.propTypes = {
  expandMenuComponent: types.oneOfType([types.node, types.func]),
  children: types.node.isRequired,
}

export default ExpandMenu
export { MenuItem as ExpandMenuItem }
export { MenuDivider as ExpandMenuDivider }
export { SubMenu as ExpandSubMenu }
