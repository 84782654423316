import React, { useEffect, useState } from "react"
import types from "prop-types"
import { cloneDeep, isEmpty } from "lodash-es"
import * as API from "services/api"
import { DEFAULT_SEGMENTS, SEGMENTS } from "utils/departmentSegmentHelpers"
import { workflowActionShape } from "utils/propTypeShapes"
import { actionValidations } from "utils/workflowHelpers"
import { LOCATION_TYPE } from "views/Forms/FormElementTypes"
import { Checkbox } from "shared/checkboxes"
import { Select } from "shared/selects"
import FormsyValidation from "shared/FormsyValidation"

const EMPTY_DATA = {
  questionUUID: null,
  segments: cloneDeep(DEFAULT_SEGMENTS),
}

const DefineNotifyDepartmentMembersData = ({
  action, actionIndex, changeActionData, formSlug,
}) => {
  const { data = {} } = action
  const {
    questionUUID: selectedQuestionUuid = null,
    segments: selectedSegments = [],
  } = data

  const [locationQuestions, setLocationQuestions] = useState([])

  // Set appropriate data when the component mounts
  useEffect(() => {
    changeActionData(cloneDeep(EMPTY_DATA))
  }, [])

  // Load the form's location questions when the form slug changes.
  // Loading the questions ensures that the workflow references persisted
  // questions and not question that have not been saved.
  useEffect(() => {
    (async function fetchFormQuestions() {
      if (formSlug) {
        const response = await API.getFormQuestions({ formSlug, type: LOCATION_TYPE })

        if (response.ok) {
          setLocationQuestions(response.data)
        } else {
          console.error("Error fetching location questions: ", response)
        }
      }
    }())
  }, [formSlug])

  const updateQuestionUuid = (newQuestionUuid) => {
    const updatedData = {
      ...data,
      questionUUID: newQuestionUuid,
    }

    changeActionData(updatedData)
  }

  // When the location questions change, ensure that one of them is selected
  useEffect(() => {
    if (!isEmpty(locationQuestions)) {
      updateQuestionUuid(locationQuestions[0].uuid)
      return
    }

    if (selectedQuestionUuid) {
      updateQuestionUuid(null)
    }
  }, [locationQuestions])

  const selectSegment = (segmentToAdd) => () => {
    const selectedSegmentSet = new Set(selectedSegments)
    selectedSegmentSet.add(segmentToAdd)

    const updatedData = {
      ...data,
      segments: Array.from(selectedSegmentSet),
    }

    changeActionData(updatedData)
  }

  const deselectSegment = (segmentToRemove) => () => {
    const filteredSegments = selectedSegments.filter((selectedSegment) => selectedSegment !== segmentToRemove)

    const updatedData = {
      ...data,
      segments: filteredSegments,
    }

    changeActionData(updatedData)
  }

  const segmentSelected = (segment) => selectedSegments.includes(segment)

  const questionOptions = locationQuestions.map((question) => ({
    value: question.uuid,
    label: question.prompt,
  }))

  const validations = actionValidations(action.type)

  if (isEmpty(locationQuestions)) return null

  return (
    <div className="mt-8">
      <div className="relative mb-4">
        <label>
          <span className="font-semibold required-prompt inline-block mb-1">
            Location question
          </span>
          <Select
            backspaceDelete={false}
            options={questionOptions}
            value={selectedQuestionUuid}
            style={{ height: "32px" }}
            placeholder="Select question from the form"
            onChange={updateQuestionUuid}
          />
        </label>
      </div>
      <span className="font-semibold required-prompt inline-block mb-1">
        Department Segments
      </span>
      <div className="flex flex-col gap-1">
        {
          SEGMENTS.map((segment) => {
            const checkBoxId = `workflow-action-${actionIndex}-notify-department-members-segment-${segment}-checkbox`
            const label = `Segment ${segment.toUpperCase()}`
            const selected = segmentSelected(segment)
            const toggleSelection = selected ? deselectSegment(segment) : selectSegment(segment)

            return (
              <div key={segment} className="flex gap-2 mb-1">
                <Checkbox
                  key={segment}
                  uuid={checkBoxId}
                  className="cursor-pointer"
                  value={selected}
                  onChange={toggleSelection}
                />
                <label htmlFor={checkBoxId} className="cursor-pointer">
                  {label}
                </label>
              </div>
            )
          })
        }
      </div>
      <FormsyValidation
        name={`actions[${actionIndex}]`}
        value={action}
        validations={{ validations }}
      />
    </div>
  )
}

DefineNotifyDepartmentMembersData.propTypes = {
  action: workflowActionShape.isRequired,
  changeActionData: types.func.isRequired,
  formSlug: types.string.isRequired,
  actionIndex: types.number.isRequired,
}

export default DefineNotifyDepartmentMembersData
