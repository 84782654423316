import React from "react"
import { useSolutionLevelScheduledReportExportContext } from "contexts/SolutionLevelScheduledReportExportContext"
import { DATE_FILTER_TYPE_OPTIONS } from "contexts/SolutionLevelScheduledReportExportContext/SolutionLevelScheduledReportExportContextProvider"
import { SmallWrap } from "shared/wraps"
import { DataCollectorContainer, DataCollectorStep } from "shared/dataCollector"
import ActiveStatus from "shared/ScheduledReportExport/ActiveStatus"
import FormActions from "shared/ScheduledReportExport/FormActions"
import FilterActions from "shared/ScheduledReportExport/FilterActions"
import { REPORTABLE_FIELD_DATE_FILTER_HEADER, SUBMISSION_DATE_FILTER_HEADER } from "utils/scheduledReportExportHelpers"
import NewSolutionLevelScheduledReportExportFormReportableFieldDateAnswerFilters from "./NewSolutionLevelScheduledReportExportFormReportableFieldDateAnswerFilters"
import NewSolutionLevelScheduledReportExportFormReportableFieldLocationAnswerFilters from "./NewSolutionLevelScheduledReportExportFormReportableFieldLocationAnswerFilters"
import NewSolutionLevelScheduledReportExportSubmissionFilters from "./NewSolutionLevelScheduledReportExportSubmissionFilters"
import NewSolutionLevelScheduledReportExportDetailFields from "./NewSolutionLevelScheduledReportExportDetailFields"

const NewSolutionLevelScheduledReportExportForm = () => {
  const {
    activeStatus,
    cancelForm,
    dateFilterType,
    dateReportableFields,
    recurrenceDayOfMonth,
    recurrenceDayOfWeek,
    reportableFieldAnswerPreviousRangeLength,
    saveScheduledReportExport,
    setActiveStatus,
    setDateFilterTypeAndClearValues,
    solutionLevelReport,
    startDate,
    submissionPreviousRangeLength,
  } = useSolutionLevelScheduledReportExportContext()

  const {
    name: solutionLeveLReportName,
  } = solutionLevelReport

  const renderDataCollectorHeaderContent = () => (
    <span className="font-medium text-xl">{solutionLeveLReportName}</span>
  )

  const recurrenceDataCollected = startDate || recurrenceDayOfWeek || recurrenceDayOfMonth
  const dateFilterRangesCollected = reportableFieldAnswerPreviousRangeLength || submissionPreviousRangeLength
  const isFormValid = startDate && dateFilterRangesCollected

  return (
    <SmallWrap>
      <DataCollectorContainer renderHeaderContent={renderDataCollectorHeaderContent}>
        <div className="mb-6">
          <DataCollectorStep stepNumber={1}>
            <NewSolutionLevelScheduledReportExportDetailFields />
          </DataCollectorStep>

          {
            recurrenceDataCollected && (
              <>
                {dateReportableFields.length > 0 && (
                  <DataCollectorStep stepNumber={2} heading="Select Date Filter Type">
                    <FilterActions
                      dateFilterType={dateFilterType}
                      setDateFilterTypeAndClearValues={setDateFilterTypeAndClearValues}
                      filterOptions={DATE_FILTER_TYPE_OPTIONS}
                    />
                  </DataCollectorStep>
                )}

                {(dateFilterType === SUBMISSION_DATE_FILTER_HEADER) && (
                  <DataCollectorStep stepNumber={2} heading="Filter by When the Submission was Received">
                    <NewSolutionLevelScheduledReportExportSubmissionFilters />
                  </DataCollectorStep>
                )}

                {dateFilterType === REPORTABLE_FIELD_DATE_FILTER_HEADER && (
                  <DataCollectorStep stepNumber={3} heading="Filter by the Answer to a Reportable Field Date Question">
                    <NewSolutionLevelScheduledReportExportFormReportableFieldDateAnswerFilters />
                  </DataCollectorStep>
                )}

                {dateFilterRangesCollected && (
                  <>
                    <DataCollectorStep stepNumber={4} heading="Filter by the Answer to a Reportable Field Location Question">
                      <NewSolutionLevelScheduledReportExportFormReportableFieldLocationAnswerFilters />
                    </DataCollectorStep>
                    <DataCollectorStep stepNumber={5}>
                      <ActiveStatus
                        activeStatus={activeStatus}
                        setActiveStatus={setActiveStatus}
                      />
                    </DataCollectorStep>
                  </>
                )}
              </>
            )
          }

        </div>
        <FormActions
          cancelForm={cancelForm}
          isFormValid={!!isFormValid}
          saveScheduledReportExport={saveScheduledReportExport}
        />
      </DataCollectorContainer>
    </SmallWrap>
  )
}

export default NewSolutionLevelScheduledReportExportForm
