import React from "react"
import types from "prop-types"
import { useModal } from "hooks"
import { BroomIcon } from "shared/icons"
import "stylesheets/application.scss"
import CategoryResetModal from "shared/modals/CategoryResetModal"
import Tooltip from "../Tooltip"

const CategoryReset = ({ category, subdomain, onResetCategory }) => {
  const { modalIsOpen, openModal, closeModal } = useModal()

  if (category.hasSetupReset) return null

  return (
    <>
      <Tooltip text="Reset solution">
        <BroomIcon className="php-subdomain-list-button" onClick={openModal} />
      </Tooltip>
      <CategoryResetModal
        closeForm={closeModal}
        isOpen={modalIsOpen}
        category={category}
        subdomain={subdomain}
        onResetCategory={onResetCategory}
      />

    </>
  )
}

CategoryReset.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  category: types.object.isRequired,
  onResetCategory: types.func.isRequired,
  subdomain: types.string.isRequired,
}

export default CategoryReset
