import React from "react"
import types from "prop-types"
import { FormsyMultilineTextInput } from "shared/inputs"

const ReportableFieldDescription = ({ value = "" }) => (
  <FormsyMultilineTextInput
    name="description"
    placeholder="Add a description..."
    className="bordered-base-input text-sm bg-white"
    value={value}
    rows="3"
  />
)

ReportableFieldDescription.propTypes = {
  value: types.string,
}

export default ReportableFieldDescription
