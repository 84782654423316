/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import * as API from "services/api"
import types from "prop-types"
import { Select } from "shared/selects"
import { workflowActionShape } from "utils/propTypeShapes"
import { actionValidations } from "utils/workflowHelpers"
import FormsyValidation from "shared/FormsyValidation"

const DefineAddDepartmentData = ({
  action, actionIndex, changeActionData, formSlug,
}) => {
  const [locationQuestions, setLocationQuestions] = useState([])

  useEffect(() => {
    (async function fetchFormQuestions() {
      if (formSlug) {
        const response = await API.getFormQuestions({ formSlug, type: "location" })
        if (response.ok) {
          setLocationQuestions(response.data)
        } else {
          console.error("Error fetching location questions: ", response)
        }
      }
    }())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSlug])

  const questionOptions = locationQuestions.map((question) => ({
    value: question.uuid,
    label: question.prompt,
  }))

  const updateQuestion = (questionUUID) => changeActionData({ questionUUID })
  const validations = actionValidations(action.type)

  return (
    <div className="relative">
      <Select
        options={questionOptions}
        value={action.data?.questionUUID}
        style={{ height: "32px" }}
        placeholder="Select question to get department"
        onChange={updateQuestion}
      />
      <FormsyValidation
        name={`actions[${actionIndex}]`}
        value={action}
        validations={{ validations }}
      />
    </div>
  )
}

DefineAddDepartmentData.propTypes = {
  action: workflowActionShape.isRequired,
  changeActionData: types.func.isRequired,
  formSlug: types.string.isRequired,
  actionIndex: types.number.isRequired,
}

export default DefineAddDepartmentData
