import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { LargeWrap } from "shared/wraps"
import SubmissionGroupingList from "./SubmissionGroupingList"
import SubmissionHeader from "./SubmissionHeader"

const SubmissionContent = ({ hiddenSidebar, toggleSidebar }) => (
  <LargeWrap className={clsx(!hiddenSidebar && "pl-80")}>
    <SubmissionHeader hiddenSidebar={hiddenSidebar} toggleSidebar={toggleSidebar} />
    <SubmissionGroupingList />
  </LargeWrap>
)

SubmissionContent.propTypes = {
  hiddenSidebar: types.bool.isRequired,
  toggleSidebar: types.func.isRequired,
}

export default SubmissionContent
