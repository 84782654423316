/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { ProfileIcon } from "shared/icons"
import MatchedOptionText from "./MatchedOptionText"
import OptionMarker from "./OptionMarker"

const Option = ({
  className = "",
  option,
  search,
  selectOption,
}) => (
  <div className={clsx("py-2 flex-center", className)} onClick={() => selectOption(option)}>
    <div className="flex">
      {
        (option.firstName && option.lastName) && <ProfileIcon className="mr-2 w-8 h-8" user={option} />
      }
      <MatchedOptionText value={option.label} search={search} />
    </div>
    <div className="space-x-2">
      {
        option.marker && [].concat(option.marker).map((marker) => <OptionMarker key={marker} value={marker} />)
      }
    </div>
  </div>
)

Option.propTypes = {
  className: types.string,
  // eslint-disable-next-line react/forbid-prop-types
  option: types.object.isRequired,
  selectOption: types.func.isRequired,
  search: types.string.isRequired,
}

export default Option
