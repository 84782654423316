import React, { useCallback, useEffect } from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { useDispatch, useSelector } from "react-redux"
import {
  getFormQuestionReportableFieldId, getFormQuestionReportableFieldOptions, setQuestionReportableField,
} from "reduxSlices/formBuilderSlice"
import ReportableField from "./ReportableField"

const ReportableFieldList = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const questionReportableFieldId = useSelector(getFormQuestionReportableFieldId({ sectionIndex, questionIndex }))
  const questionReportableFieldOptions = useSelector(getFormQuestionReportableFieldOptions({ sectionIndex, questionIndex }), isEqual)

  const setReportableFieldId = useCallback((newReportableFieldId) => dispatch(setQuestionReportableField({
    sectionIndex, questionIndex, newReportableFieldId,
  })), [dispatch, sectionIndex, questionIndex])

  // If the question's options for reportable field change, then we need to evaluate
  // whether an applied reportable field is still valid.  For example,
  // changing a question's type could change the options for reportable field.
  // If an applied reportable field is no longer valid, then it needs to be removed.
  useEffect(() => {
    if (!questionReportableFieldId) return

    const appliedReportableField = questionReportableFieldOptions.find((reportableField) => (
      reportableField.id === questionReportableFieldId
    ))

    if (!appliedReportableField) {
      setReportableFieldId(null)
    }
  }, [questionReportableFieldId, questionReportableFieldOptions, setReportableFieldId])

  return (
    !questionReportableFieldOptions.length ? (
      <div>No reportable fields available for question type</div>
    ) : (
      questionReportableFieldOptions.map((field) => (
        <ReportableField
          key={field.id}
          field={field}
          checked={field.id === questionReportableFieldId}
          setReportableFieldId={setReportableFieldId}
        />
      ))
    )
  )
}

ReportableFieldList.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default ReportableFieldList
