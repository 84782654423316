import React from "react"
import types from "prop-types"
import { BroadcastChannel } from "broadcast-channel"
import { useSelector } from "react-redux"
import {
  currentUserId as getCurrentUserId,
  currentUserIsInAdminRole as getCurrentUserIsInAdminRole,
  currentUserIsPhpAdmin as getCurrentUserIsPhpAdmin,
  impersonationInProgress as getImpersonationInProgress,
} from "reduxSlices/sessionSlice"
import { subscribedToAddOnFeature } from "reduxSlices/addOnFeatureSubscriptionsSlice"
import * as API from "services/api"
import { IMPERSONATION_BY_ORGANIZATION_ADMINS_FEATURE_NAME } from "utils/addOnFeatureHelpers"
import { IMPERSONATION, START_IMPERSONATION } from "utils/impersonation"
import ExpandMenu, { ExpandMenuItem } from "shared/ExpandMenu"
import { EllipsisIcon } from "shared/icons"
import { errorToast, successToast } from "shared/toast"

const goToViewUser = (user) => () => {
  window.location.pathname = `/admin/users/${user.id}`
}

const impersonationsChannel = new BroadcastChannel(IMPERSONATION)

const impersonateUser = async (user) => {
  const response = await API.startImpersonation({ impersonateeId: user.id })

  if (response.ok) {
    impersonationsChannel.postMessage(START_IMPERSONATION)
    successToast(`${user.fullName} successfully impersonated!`)
  } else {
    console.error("Error impersonating: ", response)
    errorToast("Something went wrong. Unable to impersonate user.", response)
  }
}

const currentUserMayImpersonateUser = ({
  currentUserId,
  currentUserIsInAdminRole,
  currentUserIsPhpAdmin,
  impersonationInProgress,
  organizationSubscribedToImpersonationByOrganizationAdminsFeature,
  userId,
}) => {
  if (impersonationInProgress) return false
  if (currentUserId === userId) return false
  if (currentUserIsPhpAdmin) return true

  return organizationSubscribedToImpersonationByOrganizationAdminsFeature && currentUserIsInAdminRole
}

const UserMenu = ({ user }) => {
  const currentUserId = useSelector(getCurrentUserId)
  const currentUserIsInAdminRole = useSelector(getCurrentUserIsInAdminRole)
  const currentUserIsPhpAdmin = useSelector(getCurrentUserIsPhpAdmin)
  const impersonationInProgress = useSelector(getImpersonationInProgress)
  const organizationSubscribedToImpersonationByOrganizationAdminsFeature = useSelector(
    subscribedToAddOnFeature(IMPERSONATION_BY_ORGANIZATION_ADMINS_FEATURE_NAME),
  )

  const impersonationOfUserPermitted = currentUserMayImpersonateUser({
    currentUserId,
    currentUserIsInAdminRole,
    currentUserIsPhpAdmin,
    impersonationInProgress,
    organizationSubscribedToImpersonationByOrganizationAdminsFeature,
    userId: user.id,
  })

  return (
    <ExpandMenu expandMenuComponent={(
      <div className="absolute-vertical-center -right-10 bg-light-100 rounded py-2.5 px-0.5">
        <EllipsisIcon className="h-1 w-5" />
      </div>
    )}
    >
      <ExpandMenuItem onClick={goToViewUser(user)}>
        View
      </ExpandMenuItem>
      {
        impersonationOfUserPermitted && (
          <ExpandMenuItem onClick={() => impersonateUser(user)}>
            Impersonate
          </ExpandMenuItem>
        )
      }
    </ExpandMenu>
  )
}
UserMenu.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: types.object.isRequired,
}

export default UserMenu
