/* eslint-disable max-len */
import React from "react"
import types from "prop-types"
import { workflowConditionShape } from "utils/propTypeShapes"
import { RemoveButton } from "shared/buttons"
import { OPERATOR_CONJUNCTIONS } from "utils/workflowHelpers"
import DefineConditionOperator from "./DefineConditionOperator"
import DefineConditionProperty from "./DefineConditionProperty"
import DefineConditionValue from "./DefineConditionValue"

const Condition = ({
  isSubform,
  condition,
  conditionIndex,
  operator,
  isLastCondition,
  removeCondition,
  changeConditionProperty,
  changeConditionSubProperty,
  changeConditionOperator,
  changeConditionValue,
}) => (
  <>
    <div className="flex justify-between items-center flex-grow mt-3">
      <div className="flex-grow border border-light-300 rounded-md mr-4">
        <div className="flex gap-2 flex-wrap items-center p-3">
          <DefineConditionProperty
            condition={condition}
            isSubform={isSubform}
            conditionIndex={conditionIndex}
            changeConditionProperty={changeConditionProperty}
            changeConditionSubProperty={changeConditionSubProperty}
          />
          <DefineConditionOperator
            condition={condition}
            conditionIndex={conditionIndex}
            changeConditionOperator={changeConditionOperator}
          />
          <DefineConditionValue
            condition={condition}
            conditionIndex={conditionIndex}
            changeConditionValue={changeConditionValue}
          />
        </div>
      </div>
      <RemoveButton onClick={removeCondition} />
    </div>
    {
      !isLastCondition && (
        <div className="mt-3 text-center font-medium text-dark">
          { OPERATOR_CONJUNCTIONS(operator) }
        </div>
      )
    }
  </>
)

Condition.propTypes = {
  condition: workflowConditionShape.isRequired,
  isSubform: types.bool.isRequired,
  operator: types.string.isRequired,
  isLastCondition: types.bool.isRequired,
  conditionIndex: types.number.isRequired,
  changeConditionProperty: types.func.isRequired,
  changeConditionSubProperty: types.func.isRequired,
  changeConditionOperator: types.func.isRequired,
  changeConditionValue: types.func.isRequired,
  removeCondition: types.func.isRequired,
}

export default Condition
