import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import relativeTimePlugin from "dayjs/plugin/relativeTime"
import localeData from "dayjs/plugin/localeData"
import utcPlugin from "dayjs/plugin/utc"
import timezonePlugin from "dayjs/plugin/timezone"
import weekday from "dayjs/plugin/weekday"

dayjs.extend(localeData)
dayjs.extend(utcPlugin)
dayjs.extend(timezonePlugin)
dayjs.extend(relativeTimePlugin)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)

export const dayjsOrgTz = (dateTime) => dayjs(dateTime).tz(dayjs.tz().$x.$timezone)
export const dayjsWithTz = (dateTime, timezone) => dayjs(dateTime).tz(timezone)

export const YEAR_UNIT = "year"
export const QUARTER_UNIT = "quarter"
export const MONTH_UNIT = "month"
export const WEEK_UNIT = "week"
export const DAY_UNIT = "day"
export const HOUR_UNIT = "hour"
export const MINUTE_UNIT = "minute"
export const SECOND_UNIT = "second"

export const DAYS = "days"
export const WEEKS = "weeks"
export const MONTHS = "months"
export const RANGE_UNIT_OPTIONS = [DAYS, WEEKS, MONTHS]

export const daysOfTheWeek = dayjs.weekdays()
// ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const tomorrowDate = () => dayjsOrgTz().add(1, "d").format("MM/DD/YY")
export const yesterdayDateWithTimezone = (timezone) => dayjs().add(-1, "d").tz(timezone).format("YYYY-MM-DD")

export const numberDate = (dateInput) => dayjsOrgTz(dateInput).format("MM/DD/YY")
// e.g. 3/8/21
export const shortMonthDate = (dateInput) => dayjsOrgTz(dateInput).format("MMM D, YYYY")
// e.g. Mar 8, 2021

export const weekDayMonthDate = (dateInput) => dayjsOrgTz(dateInput).format("dddd, MMM DD")
// e.g. Tuesday, Mar 08

export const shortDayMonthDateYear = (dateInput) => dayjsOrgTz(dateInput).format("ddd, MMMM D, YYYY")

export const monthDate = (dateInput) => dayjsOrgTz(dateInput).format("MMM D")
// e.g. Mar 8

export const fullMonthDate = (dateInput) => dayjsOrgTz(dateInput).format("MMMM D")
// e.g. March 8

export const yearMonthDayDate = (dateInput) => dayjsOrgTz(dateInput).format("YYYY-MM-DD")
export const yearMonthDayDateWithTz = (dateInput, timezone) => dayjsWithTz(dateInput, timezone).format("YYYY-MM-DD")
// e.g. 2022-06-15

export const yearMonthDayDatetime = (dateTime) => dayjsOrgTz(dateTime).format("YYYY-MM-DD HH:mm ZZ")
// e.g. 2022-06-15 14:33 -0400

export const thisYear = () => new Date().getUTCFullYear()

export const isToday = (date) => dayjsOrgTz().isSame(date, DAY_UNIT)

export const todayDay = () => dayjsOrgTz().format("dddd")

export const todayDayOfMonth = () => dayjsOrgTz().format("D")

const HOUR_FORMAT = "HH"

export const orgLocalDateTime = (dateInput) => dayjsOrgTz(dateInput).format(`ddd, MMM D, YYYY [at] ${HOUR_FORMAT}:mm`)
// e.g. Mon, Jun 13 2023 at 17:19

// '01' because January has 31 days.
const maxMonthDateRange = Array.from({ length: dayjs("01").daysInMonth() }, (_, index) => index + 1);

export const dateOrdinal = (number) => dayjs(`1-${number}`).format("Do")

export const dateOrdinalOptions = () => maxMonthDateRange.map((number) => (
  { label: dateOrdinal(number), value: number }
))
