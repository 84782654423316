import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { FormLightIcon } from "shared/icons"
import { NewTaskForm } from "FormSubmission/FormSubmissionTabs/ActivitiesContent/TaskForms"
import TasksHeader from "./TasksHeader"

const EmptyActivities = ({
  taskFormIsOpen, openForm, closeForm, hiddenSidebar, toggleSidebar,
}) => (
  <div className="relative grow">
    <div className="inner-container mx-auto max-w-screen-xl">
      <TasksHeader
        openForm={openForm}
        hiddenSidebar={hiddenSidebar}
        toggleSidebar={toggleSidebar}
      />
      <div className={clsx("flex flex-col items-center pb-48 w-full lg:w-3/5 mt-0 mb-0 m-auto", !hiddenSidebar && "ml-80")}>
        <FormLightIcon className="w-16 h-16" />
        <span className="my-8 text-center">You do not have any activities yet</span>
        <NewTaskForm
          taskFormIsOpen={taskFormIsOpen}
          onCancel={closeForm}
          onCreate={closeForm}
        />
      </div>
    </div>
  </div>
)

EmptyActivities.propTypes = {
  taskFormIsOpen: types.bool.isRequired,
  openForm: types.func.isRequired,
  closeForm: types.func.isRequired,
  hiddenSidebar: types.bool.isRequired,
  toggleSidebar: types.func.isRequired,
}

export default EmptyActivities
