import React, { useState } from "react"
import types from "prop-types"
import clsx from "clsx"
import { PrimaryButton } from "shared/buttons"

const SubmitFormButton = ({ canSubmit, submitting }) => {
  const [clicked, setClicked] = useState(false)

  const markClicked = () => {
    if (!clicked) setClicked(true)
  }

  return (
    <>
      <PrimaryButton
        className={clsx(
          "flex items-center",
          "w-1/2 sm:w-1/3 mx-auto h-14 rounded-full",
          "text-lg sm:text-xl font-medium tracking-wide",
        )}
        text="Submit"
        type="submit"
        onClick={markClicked}
        disabled={submitting}
      />
      {
        clicked && !canSubmit && (
          <div className="mt-4 text-center">Please scroll up and fix errors</div>
        )
      }
    </>
  )
}

SubmitFormButton.propTypes = {
  canSubmit: types.bool.isRequired,
  submitting: types.bool.isRequired,
}

export default SubmitFormButton
