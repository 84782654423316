import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { isEqual } from "lodash-es"
import { useDispatch, useSelector } from "react-redux"
import {
  getFormQuestionAttributeCalcQuestionUuid,
  getFormQuestionAttributeCalcQuestionUuidsCount,
  getFormQuestionAttributeCalcQuestionUuidOptions,
  setQuestionAttributeCalcQuestionUuid,
} from "reduxSlices/formBuilderSlice"
import RemoveQuestionButton from "./RemoveQuestionButton"
import NumberQuestionSelect from "./NumberQuestionSelect"

const MINIMUM_QUESTION_COUNT = 2

const QuestionSelect = ({ sectionIndex, questionIndex, calcQuestionUuidIndex }) => {
  const dispatch = useDispatch()

  const calcQuestionsCount = useSelector(getFormQuestionAttributeCalcQuestionUuidsCount({ sectionIndex, questionIndex }))
  const calcQuestionUuid = useSelector(getFormQuestionAttributeCalcQuestionUuid({ sectionIndex, questionIndex, calcQuestionUuidIndex }))
  const calcQuestionOptions = useSelector(getFormQuestionAttributeCalcQuestionUuidOptions({ sectionIndex, questionIndex, calcQuestionUuidIndex }), isEqual)

  const changeCalcQuestionUuid = (newCalcQuestionUuid) => dispatch(setQuestionAttributeCalcQuestionUuid({
    sectionIndex, questionIndex, calcQuestionUuidIndex, newCalcQuestionUuid,
  }))

  const isRemoveCalcButtonVisible = (calcQuestionsCount > MINIMUM_QUESTION_COUNT)

  return (
    <>
      <div className="mb-2 flex-center">
        <div className={clsx(isRemoveCalcButtonVisible ? "w-5/6" : "w-full")}>
          <NumberQuestionSelect
            className="text-sm"
            style={{ height: "32px" }}
            placeholder="Select question..."
            questions={calcQuestionOptions}
            selectedQuestionUuid={calcQuestionUuid}
            isFullWidth
            onChange={changeCalcQuestionUuid}
          />
        </div>
        {
          isRemoveCalcButtonVisible && (
            <RemoveQuestionButton
              sectionIndex={sectionIndex}
              questionIndex={questionIndex}
              calcQuestionUuidIndex={calcQuestionUuidIndex}
            />
          )
        }
      </div>
    </>
  )
}

QuestionSelect.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
  calcQuestionUuidIndex: types.number.isRequired,
}

export default QuestionSelect
