import { useContext } from "react"
import FormSubmissionAuditTrailContext from "../FormSubmissionAuditTrailContext"

const useFormSubmissionAuditTrailContext = () => {
  const providedValue = useContext(FormSubmissionAuditTrailContext)

  return { ...providedValue }
}

export default useFormSubmissionAuditTrailContext
