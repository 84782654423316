import React from "react"
import types from "prop-types"
import { useDispatch } from "react-redux"
import { deleteFormQuestionOption } from "reduxSlices/formBuilderSlice"
import { XIcon } from "shared/icons"

const OptionDeleteButton = ({ sectionIndex, questionIndex, optionIndex }) => {
  const dispatch = useDispatch()

  const deleteOption = () => dispatch(deleteFormQuestionOption({ sectionIndex, questionIndex, optionIndex }))

  return (
    <XIcon
      className="ml-1"
      onClick={deleteOption}
    />
  )
}

OptionDeleteButton.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
  optionIndex: types.number.isRequired,
}

export default OptionDeleteButton
