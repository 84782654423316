import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as API from "services/api"

const SLICE_NAME = "workflows"

const INITIAL_STATE = {
  categoryPublicForms: [],
  workflows: [],
}

export const initializeWorkflowsState = createAsyncThunk(
  `${SLICE_NAME}/initializeWorkflowsState`,
  async ({ categorySlug, workflows }, thunkAPI) => {
    const updatedData = { categoryPublicForms: [], workflows }

    if (categorySlug) {
      const categoryFormsResponse = await API.getCategoryForms({ categorySlug })

      if (categoryFormsResponse.ok) {
        updatedData.categoryPublicForms = categoryFormsResponse.data
      } else {
        throw thunkAPI.rejectWithValue(categoryFormsResponse.data)
      }
    }

    return updatedData
  },
)

export const workflowsSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initializeWorkflowsState.rejected, () => {
        // Not implemented
      })
      .addCase(initializeWorkflowsState.pending, () => {
        // Not implemented
      })
      .addCase(initializeWorkflowsState.fulfilled, (state, action) => {
        Object.assign(state, action.payload)
      })
  },
})

export const getCategoryPublicForms = (state) => state[SLICE_NAME].categoryPublicForms
export const getWorkflows = (state) => state[SLICE_NAME].workflows

export default workflowsSlice.reducer
