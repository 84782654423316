import React from "react"
import types from "prop-types"
import Option from "./Option"

const Options = ({
  search, options, selectOption, title = "",
}) => {
  if (!options.length) {
    return <div className="my-3 font-semibold ml-3">No Results</div>
  }

  return (
    <div className="px-3 py-2">
      <div className="font-semibold text-gray-800">{ title }</div>
      {
        options.map((option) => (
          <Option
            key={option.value}
            option={option}
            selectOption={selectOption}
            search={search}
          />
        ))
      }
    </div>
  )
}

Options.propTypes = {
  options: types.arrayOf(types.object).isRequired,
  selectOption: types.func.isRequired,
  search: types.string.isRequired,
  title: types.string,
}

export default Options
