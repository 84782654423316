import React, { useEffect, useState } from "react"
import types from "prop-types"
import { sortBy } from "lodash-es"
import * as API from "services/api"
import { useReportableFieldLocationAnswerFilter, useSubmissionRelativeDateFilter } from "hooks"
import { reportExportSolutionLevelReportShape } from "utils/propTypeShapes"
import { errorToast, successToast } from "components/shared/toast"
import {
  dayjsOrgTz,
  DAYS, MONTHS, WEEKS,
} from "utils/dateHelpers"
import { DATE_REPORTABLE_FIELD_TYPE } from "utils/reportableFieldHelpers"
import { ACTIVITY_HUB_FORM_SUBMISSION_REPORTS_PATH, redirectTo } from "utils/routeHelpers"
import {
  CREATE_SCHEDULED_REPORT_EXPORT_ERROR_MESSAGE, MAX_DAYS, MAX_WEEKS, MAX_MONTHS, REPORTABLE_FIELD_DATE_FILTER_HEADER, SUBMISSION_DATE_FILTER_HEADER,
} from "utils/scheduledReportExportHelpers"
import SolutionLevelScheduledReportExportContext from "../SolutionLevelScheduledReportExportContext"

const LOAD_REPORTABLE_FIELDS_ERROR_MESSAGE = "Something went wrong.  Unable to load reportable fields."

export const DATE_FILTER_TYPE_OPTIONS = [SUBMISSION_DATE_FILTER_HEADER, REPORTABLE_FIELD_DATE_FILTER_HEADER]

const SolutionLevelScheduledReportExportContextProvider = ({ children, solutionLevelReport: propSolutionLevelReport, solutionLevelScheduledReportExport: propSolutionLevelScheduledReportExport }) => {
  const {
    submissionPreviousRangeLength, submissionPreviousRangeUnit, setSubmissionPreviousRangeLength, setSubmissionPreviousRangeUnit,
  } = useSubmissionRelativeDateFilter()

  const updatingExistingRecord = !!propSolutionLevelScheduledReportExport

  const {
    addAllFacilityDepartmentsToReportableFieldLocationAnswerFilters,
    addDepartmentIdsToReportableFieldLocationAnswerFilters,
    departments,
    facilities,
    facilityDepartments,
    removeAllFacilityDepartmentsFromReportableFieldLocationAnswerFilters,
    removeDepartmentIdsFromReportableFieldLocationAnswerFilters,
    reportableFieldLocationAnswerFilters,
    selectedFacilityIds,
    setReportableFieldLocationAnswerFilterReportableFieldId,
    setReportableFieldLocationAnswerFilters,
    setSelectedFacilityIds,
  } = useReportableFieldLocationAnswerFilter()

  const [solutionLevelReport] = useState(propSolutionLevelReport)
  const { id: solutionLevelReportId, categorySlug } = solutionLevelReport

  const [scheduledReportExport, setScheduledReportExport] = useState({})

  // For filtering by answers
  const [reportableFields, setReportableFields] = useState([])

  const [dateReportableFields, setDateReportableFields] = useState([])

  const [dateFilterType, setDateFilterType] = useState("")

  useEffect(() => {
    const loadReportableFields = async () => {
      const response = await API.getCategoryReportableFields({ categorySlug })

      if (response.ok) {
        const sortedReportableFields = sortBy(response.data, "name")

        setReportableFields(sortedReportableFields)

        const filteredReportableFields = sortedReportableFields.filter((reportableField) => (
          reportableField.questionType === DATE_REPORTABLE_FIELD_TYPE
        ))

        setDateReportableFields(filteredReportableFields)

        if (!updatingExistingRecord) {
          setDateFilterType(filteredReportableFields.length < 1 ? SUBMISSION_DATE_FILTER_HEADER : "")
        }
      } else {
        errorToast(LOAD_REPORTABLE_FIELDS_ERROR_MESSAGE)
      }
    }

    loadReportableFields()
  }, [categorySlug])

  const [reportableFieldDateId, setReportableFieldDateId] = useState()
  const [reportableFieldAnswerPreviousRangeLength, setReportableFieldAnswerPreviousRangeLength] = useState()
  const [reportableFieldAnswerPreviousRangeUnit, setReportableFieldAnswerPreviousRangeUnit] = useState()

  const [scheduledReportExportName, setScheduledReportExportName] = useState("")
  const [activeStatus, setActiveStatus] = useState(false)
  const [frequency, setReportFrequency] = useState("")
  const [recurrenceDayOfWeek, setRecurrenceDayOfWeek] = useState("")
  const [recurrenceDayOfMonth, setRecurrenceDayOfMonth] = useState(null)
  const [startDate, setStartDate] = useState(null)

  const setDateFilterTypeAndClearValues = (selectedDateFilterType) => {
    setReportableFieldAnswerPreviousRangeLength()
    setSubmissionPreviousRangeLength()

    setDateFilterType(selectedDateFilterType)
  }

  const saveScheduledReportExport = async () => {
    let payload = {
      activeStatus,
      frequency,
      recurrenceDayOfWeek,
      recurrenceDayOfMonth,
      reportableFieldLocationAnswerFilters,
      scheduledReportExportName,
      startDate,
      reportId: solutionLevelReportId,
    }

    if (dateFilterType === SUBMISSION_DATE_FILTER_HEADER) {
      payload = {
        ...payload,
        reportableFieldDateAnswerFilters: [],
        submissionPreviousRangeLength,
        submissionPreviousRangeUnit,
      }
    } else if (dateFilterType === REPORTABLE_FIELD_DATE_FILTER_HEADER) {
      payload = {
        ...payload,
        reportableFieldDateAnswerFilters: [{
          previous_range_length: reportableFieldAnswerPreviousRangeLength,
          previous_range_unit: reportableFieldAnswerPreviousRangeUnit,
          reportable_field_id: reportableFieldDateId,
        }],
        submissionPreviousRangeLength: null,
        submissionPreviousRangeUnit: null,
      }
    }

    let response
    if (updatingExistingRecord) {
      const { id: scheduledReportExportId } = propSolutionLevelScheduledReportExport

      response = await API.updateSolutionLevelScheduledReportExport({ ...payload, scheduledReportExportId })
    } else {
      response = await API.createSolutionLevelScheduledReportExport(payload)
    }

    if (response.ok) {
      setScheduledReportExport(response.data)
      successToast(`Scheduled report export ${scheduledReportExportName} has been saved.`)

      setTimeout(() => { redirectTo(ACTIVITY_HUB_FORM_SUBMISSION_REPORTS_PATH) }, 1000)
    } else {
      errorToast(CREATE_SCHEDULED_REPORT_EXPORT_ERROR_MESSAGE)
    }
  }

  const cancelForm = () => { redirectTo(ACTIVITY_HUB_FORM_SUBMISSION_REPORTS_PATH) }

  const maxBasedOnUnit = () => {
    if (submissionPreviousRangeUnit === DAYS) {
      return MAX_DAYS
    } if (submissionPreviousRangeUnit === WEEKS) {
      return MAX_WEEKS
    } if (submissionPreviousRangeUnit === MONTHS) {
      return MAX_MONTHS
    }
    return 10
  }

  const lengthOptions = Array.from({ length: maxBasedOnUnit() }, (_, index) => index + 1)

  // if editing existing scheduled report export:
  useEffect(() => {
    if (updatingExistingRecord) {
      const {
        reportableFieldFacilityIds,
        reportableFieldPreviousRangeLength: propReportableFieldPreviousRangeLength,
        reportableFieldPreviousRangeUnit: propReportableFieldPreviousRangeUnit,

        reportableFieldDateAnswerId: propReportableFieldDateAnswerId,
        reportableFieldLocationAnswerId: propReportableFieldLocationAnswerId,
        reportableFieldDepartmentIds: propReportableFieldDepartmentIds,

        formSubmissionPreviousRangeLength: propFormSubmissionPreviousRangeLength,
        formSubmissionPreviousRangeUnit: propFormSubmissionPreviousRangeUnit,
        name: propName,
        scheduledReportExportSchedule: propScheduledReportExportSchedule,
      } = propSolutionLevelScheduledReportExport

      const {
        active: propActive,
        frequency: propFrequency,
        recurrenceDayOfMonth: propRecurrenceDayOfMonth,
        recurrenceDayOfWeek: propRecurrenceDayOfWeek,
        startDate: propStartDate,
      } = propScheduledReportExportSchedule

      setSubmissionPreviousRangeLength(propFormSubmissionPreviousRangeLength)
      setScheduledReportExportName(propName)
      setActiveStatus(propActive)
      setReportFrequency(propFrequency)
      setRecurrenceDayOfMonth(propRecurrenceDayOfMonth)
      setRecurrenceDayOfWeek(propRecurrenceDayOfWeek)
      setStartDate(dayjsOrgTz(propStartDate))

      setReportableFieldAnswerPreviousRangeLength(propReportableFieldPreviousRangeLength)
      setReportableFieldDateId(propReportableFieldDateAnswerId)

      // unit will be the same for both, but only one gets stored on record
      if (propFormSubmissionPreviousRangeUnit) {
        setSubmissionPreviousRangeUnit(propFormSubmissionPreviousRangeUnit)
        setReportableFieldAnswerPreviousRangeUnit(propFormSubmissionPreviousRangeUnit)
      } else {
        setSubmissionPreviousRangeUnit(propReportableFieldPreviousRangeUnit)
        setReportableFieldAnswerPreviousRangeUnit(propReportableFieldPreviousRangeUnit)
      }

      (propFormSubmissionPreviousRangeLength) ? setDateFilterType(SUBMISSION_DATE_FILTER_HEADER) : setDateFilterType(REPORTABLE_FIELD_DATE_FILTER_HEADER)

      if (propReportableFieldLocationAnswerId) {
        setReportableFieldLocationAnswerFilters([
          {
            reportableFieldId: propReportableFieldLocationAnswerId,
            departmentIds: propReportableFieldDepartmentIds,
          },
        ])
        setSelectedFacilityIds(reportableFieldFacilityIds)
      }
    }
  }, [])

  // Object exposed to context consumers
  const contextConsumerValue = {
    activeStatus,
    addAllFacilityDepartmentsToReportableFieldLocationAnswerFilters,
    addDepartmentIdsToReportableFieldLocationAnswerFilters,
    scheduledReportExport,
    cancelForm,
    dateFilterType,
    dateReportableFields,
    departments,
    frequency,
    facilities,
    facilityDepartments,
    lengthOptions,
    recurrenceDayOfMonth,
    recurrenceDayOfWeek,
    removeAllFacilityDepartmentsFromReportableFieldLocationAnswerFilters,
    removeDepartmentIdsFromReportableFieldLocationAnswerFilters,
    reportableFieldDateId,
    reportableFieldAnswerPreviousRangeLength,
    reportableFieldAnswerPreviousRangeUnit,
    reportableFieldLocationAnswerFilters,
    reportableFields,
    saveScheduledReportExport,
    selectedFacilityIds,
    setActiveStatus,
    setScheduledReportExport,
    setDateFilterTypeAndClearValues,
    setReportFrequency,
    setRecurrenceDayOfMonth,
    setRecurrenceDayOfWeek,
    setReportableFieldAnswerPreviousRangeLength,
    setReportableFieldAnswerPreviousRangeUnit,
    setReportableFieldDateId,
    setReportableFieldLocationAnswerFilterReportableFieldId,
    setScheduledReportExportName,
    setSelectedFacilityIds,
    setStartDate,
    setSubmissionPreviousRangeLength,
    setSubmissionPreviousRangeUnit,
    scheduledReportExportName,
    solutionLevelReport,
    startDate,
    submissionPreviousRangeLength,
    submissionPreviousRangeUnit,
  }

  return (
    <SolutionLevelScheduledReportExportContext.Provider value={contextConsumerValue}>
      {children}
    </SolutionLevelScheduledReportExportContext.Provider>
  )
}

SolutionLevelScheduledReportExportContextProvider.propTypes = {
  children: types.node.isRequired,
  solutionLevelReport: reportExportSolutionLevelReportShape.isRequired,
  solutionLevelScheduledReportExport: types.object.isRequired,
}

export default SolutionLevelScheduledReportExportContextProvider
