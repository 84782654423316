import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { addOnFeatureConfigurationPropertyDisplayName } from "utils/addOnFeatureHelpers"
import { Checkbox } from "shared/checkboxes"

const OrganizationAddOnConfigurationCheckbox = ({
  className = "", enabled, onChange, property,
}) => (
  <div className={clsx("flex gap-2 items-center", className)}>
    <Checkbox
      className="cursor-pointer"
      uuid={`organization-add-on-configuration-${property}`}
      value={enabled}
      onChange={onChange}
    />
    <label htmlFor={`organization-add-on-configuration-${property}`} className="cursor-pointer">
      {addOnFeatureConfigurationPropertyDisplayName(property)}
    </label>
  </div>
)

OrganizationAddOnConfigurationCheckbox.propTypes = {
  className: types.string,
  enabled: types.bool.isRequired,
  onChange: types.func.isRequired,
  property: types.string.isRequired,
}

export default OrganizationAddOnConfigurationCheckbox
