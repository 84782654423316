import React from "react"
import types from "prop-types"
import clsx from "clsx"
import ReactSwitch from "react-switch"
import Tooltip from "components/shared/Tooltip"

const Switch = ({
  activeBoxShadow = "0 2px 4px gray",
  borderRadius = undefined,
  boxShadow = "0 2px 4px gray",
  checkedHandleIcon = undefined,
  checkedIcon = false,
  className = "",
  disabled = false,
  handleDiameter = 16,
  height = 20,
  id = undefined,
  offColor = "#c5c7d5",
  offHandleColor = "#ffffff",
  onColor = "#5A5D83",
  onHandleColor = "#ffffff",
  tooltipText = "",
  uncheckedHandleIcon = undefined,
  uncheckedIcon = false,
  width = 40,
  ...rest
}) => {
  const switchClassName = clsx(className, "full-opacity", disabled && "disabled-toggle")

  const renderSwitch = () => (
    <ReactSwitch
      activeBoxShadow={activeBoxShadow}
      borderRadius={borderRadius}
      boxShadow={boxShadow}
      checkedHandleIcon={checkedHandleIcon}
      checkedIcon={checkedIcon}
      className={switchClassName}
      disabled={disabled}
      handleDiameter={handleDiameter}
      height={height}
      id={id}
      offColor={offColor}
      offHandleColor={offHandleColor}
      onColor={onColor}
      onHandleColor={onHandleColor}
      uncheckedHandleIcon={uncheckedHandleIcon}
      uncheckedIcon={uncheckedIcon}
      width={width}
      {...rest}
    />
  )

  return (
    tooltipText
      ? (
        <Tooltip text={tooltipText}>
          {renderSwitch()}
        </Tooltip>
      )
      : renderSwitch()
  )
}

Switch.propTypes = {
  checked: types.bool.isRequired,
  onChange: types.func.isRequired,
  disabled: types.bool,
  offColor: types.string,
  onColor: types.string,
  offHandleColor: types.string,
  onHandleColor: types.string,
  handleDiameter: types.number,
  uncheckedIcon: types.oneOfType([types.node, types.bool]),
  checkedIcon: types.oneOfType([types.node, types.bool]),
  uncheckedHandleIcon: types.node,
  checkedHandleIcon: types.node,
  boxShadow: types.string,
  activeBoxShadow: types.string,
  height: types.number,
  width: types.number,
  className: types.string,
  borderRadius: types.number,
  id: types.string,
  tooltipText: types.string,
}

export default Switch
