import React from "react"
import types from "prop-types"

const SectionName = ({ children: name }) => (
  <div className="text-2xl md:text-3xl font-medium">{name}</div>
)

SectionName.propTypes = {
  children: types.string.isRequired,
}

export default SectionName
