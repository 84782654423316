/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React from "react"
import types from "prop-types"

const FormTypeCard = ({
  footerColor,
  description,
  icon,
  title,
  onClick,
}) => (
  <div className="form-type-card" onClick={onClick}>
    <div className="w-72 bg-white flex flex-col items-center pt-8 px-8 pb-6">
      <div className="mb-6">
        { icon }
      </div>
      <p className="font-bold text-xl mb-1">{ title }</p>
      <p className="text-sm leading-6 text-center max-h-60 overflow-y-auto mb-1">
        { description }
      </p>
    </div>
    <div className={`${footerColor} h-12 flex justify-center items-center`}>
      <span className="text-white text-4xl">+</span>
    </div>
  </div>
)

FormTypeCard.propTypes = {
  footerColor: types.string.isRequired,
  description: types.string.isRequired,
  icon: types.node.isRequired,
  title: types.string.isRequired,
  onClick: types.func.isRequired,
}

export default FormTypeCard
