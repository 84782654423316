import React from "react"
import types from "prop-types"
import { questionShape } from "utils/propTypeShapes"
import { QuestionSelect } from "shared/selects"

const ConditionQuestionUuidField = ({
  conditionQuestionOptions,
  setConditionQuestionUuid,
  selectedQuestionUuid = null,
}) => (
  <div className="mb-2 flex-grow">
    <QuestionSelect
      className="text-sm form-builder-condition-question-select"
      style={{ height: "32px" }}
      placeholder="Select question..."
      questions={conditionQuestionOptions}
      onChange={setConditionQuestionUuid}
      selectedQuestionUuid={selectedQuestionUuid}
      isFullWidth
      backspaceDelete={false}
    />
  </div>
)

ConditionQuestionUuidField.propTypes = {
  conditionQuestionOptions: types.arrayOf(questionShape).isRequired,
  setConditionQuestionUuid: types.func.isRequired,
  selectedQuestionUuid: types.string,
}

export default ConditionQuestionUuidField
