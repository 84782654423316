import React from "react"
import types from "prop-types"
import clsx from "clsx"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import { ChevronDown, ChevronUp } from "shared/icons"
import "./DragHandle.scss"

const ICON_WIDTH = 10

const HandleBlock = ({ children, className = "", onClick = null }) => (
  <div
    className={clsx("flex items-center justify-center flex-col drag-and-drop-handle-block", className)}
    onClickCapture={onClick}
  >
    {children}
  </div>
)

HandleBlock.propTypes = {
  children: types.node.isRequired,
  className: types.string,
  onClick: types.func,
}

const MoveUp = ({ moveUp = null }) => (
  <HandleBlock onClick={moveUp} className={moveUp ? "cursor-pointer" : "cursor-not-allowed"}>
    <ChevronUp height={5} width={ICON_WIDTH} />
  </HandleBlock>
)

MoveUp.propTypes = {
  moveUp: types.func,
}

const MoveDown = ({ moveDown = null }) => (
  <HandleBlock onClick={moveDown} className={moveDown ? "cursor-pointer" : "cursor-not-allowed"}>
    <ChevronDown height={5} width={ICON_WIDTH} />
  </HandleBlock>
)

MoveDown.propTypes = {
  moveDown: types.func,
}

const Handle = ({ dragProvided }) => (
  <div {...dragProvided.dragHandleProps}>
    <HandleBlock>
      <SvgSpriteIcon iconName="drag-and-drop-horizontal" width={ICON_WIDTH} />
    </HandleBlock>
  </div>
)

Handle.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dragProvided: types.object.isRequired,
}

const DragHandle = ({
  className = "",
  dragProvided,
  moveDown = undefined,
  moveUp = undefined,
  isFocused = false,
}) => (
  <div className={clsx(!isFocused && "invisible", "flex items-center", className)}>
    <div className="drag-and-drop-handle">
      <MoveUp moveUp={moveUp} />
      <Handle dragProvided={dragProvided} />
      <MoveDown moveDown={moveDown} />
    </div>
  </div>
)

DragHandle.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dragProvided: types.object.isRequired,
  moveDown: types.func,
  className: types.string,
  moveUp: types.func,
  isFocused: types.bool,
}

export default DragHandle
