import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { useDispatch, useSelector } from "react-redux"
import {
  setFormQuestionType,
  isFocusOnQuestion,
  getFormIsSubform,
  getFormQuestionType,
  hasQuestionBeenAnsweredByIndex,
} from "reduxSlices/formBuilderSlice"
import { IconSelect } from "shared/selects"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import { formElementTypeOptions } from "Forms/FormElementTypes"
import * as styles from "./QuestionType.module.scss"

const renderContentIcon = ({ icon }) => <SvgSpriteIcon iconName={`${icon}-dark`} />
const renderItemIcon = ({ icon }) => <SvgSpriteIcon iconName={icon} />

const QuestionType = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const formIsSubform = useSelector(getFormIsSubform)
  const focusIsOnQuestion = useSelector(isFocusOnQuestion({ sectionIndex, questionIndex }))
  const questionHasBeenAnswered = useSelector(hasQuestionBeenAnsweredByIndex({ sectionIndex, questionIndex }))
  const questionType = useSelector(getFormQuestionType({ sectionIndex, questionIndex }))

  const updateQuestionType = (newType) => {
    dispatch(setFormQuestionType({ sectionIndex, questionIndex, newType }))
  }

  return (
    <div className={clsx(!focusIsOnQuestion && "hidden")}>
      <IconSelect
        className={styles.questionTypeStyle}
        hasBaseInputStyle={false}
        options={formElementTypeOptions(formIsSubform)}
        value={questionType}
        onChange={updateQuestionType}
        renderContentIcon={renderContentIcon}
        renderItemIcon={renderItemIcon}
        disabled={questionHasBeenAnswered}
        backspaceDelete={false}
      />
    </div>
  )
}

QuestionType.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default QuestionType
