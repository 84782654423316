import React from "react"
import types from "prop-types"
import * as SelectCSVAttributes from "./SelectCSVAttributes"
import QuestionRequired from "./QuestionRequired"
import QuestionShowDescription from "./QuestionShowDescription"

const SelectCSVInputConfig = ({ sectionIndex, questionIndex }) => (
  <>
    <QuestionRequired
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <QuestionShowDescription
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <SelectCSVAttributes.SelectCSVDefaultAnswer
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <SelectCSVAttributes.SelectCSVUploadDetails
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
  </>
)

SelectCSVInputConfig.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default SelectCSVInputConfig
