import React, { useEffect, useRef, useState } from "react"
import types from "prop-types"
import clsx from "clsx"
import { withFormsy } from "formsy-react"
import InputErrorMessage from "shared/InputErrorMessage"

import "./MultilineTextInput.scss"

const MultilineTextInput = ({
  className = "",
  disabled = false,
  errorMessages = [],
  hasBaseInputStyle = true,
  isPristine = true,
  name = undefined,
  onBlur = () => {},
  onChange,
  onChangeSideEffect = () => {},
  onFocus = () => {},
  onKeyDown = () => {},
  placeholder = "",
  required = false,
  rows = "1",
  uuid = undefined,
  value = "",
}) => {
  const textareaRef = useRef()

  const [cursorPosition, setCursorPosition] = useState(value?.length || 0)

  const updateValue = ({ target }) => {
    setCursorPosition(textareaRef.current?.selectionStart)
    onChangeSideEffect(target.value)
    onChange(target.value)
  }

  useEffect(() => {
    if (!textareaRef.current) return
    if (document.activeElement !== textareaRef.current) return

    textareaRef.current.setSelectionRange(cursorPosition, cursorPosition)
  }, [cursorPosition, value])

  return (
    <>
      <div
        className={clsx(className, "grow-wrap multiline-text-input")}
        data-replicated-value={value}
      >
        <textarea
          className={clsx(hasBaseInputStyle && "base-input")}
          style={{ fontSize: "inherit", lineHeight: "inherit", backgroundColor: "inherit" }}
          id={uuid}
          name={name || uuid}
          type="text"
          disabled={disabled}
          value={value}
          rows={rows}
          placeholder={`${placeholder} ${required ? "*" : ""}`}
          ref={textareaRef}
          onChange={updateValue}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
        />
      </div>
      <InputErrorMessage isPristine={isPristine} errorMessages={errorMessages} />
    </>
  )
}

MultilineTextInput.propTypes = {
  className: types.string,
  disabled: types.bool,
  errorMessages: types.arrayOf(types.string),
  hasBaseInputStyle: types.bool,
  isPristine: types.bool,
  name: types.string,
  onBlur: types.func,
  onChange: types.func.isRequired,
  onChangeSideEffect: types.func,
  onFocus: types.func,
  onKeyDown: types.func,
  placeholder: types.string,
  required: types.bool,
  rows: types.string,
  uuid: types.string,
  value: types.string,
}

export const FormsyMultilineTextInput = withFormsy(({ setValue, ...rest }) => (
  <MultilineTextInput
    onChange={(value) => setValue(value.trimStart())}
    {...rest}
  />
))

FormsyMultilineTextInput.displayName = "FormsyMultilineTextInput"

export default MultilineTextInput
