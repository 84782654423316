import React from "react"
import { useSelector } from "react-redux"
import { uniqueFacilityMembers } from "reduxSlices/adminFacilitySlice"
import UserInfo from "shared/UserInfo"

const UsersAssignedList = () => {
  const uniqueMembers = useSelector(uniqueFacilityMembers)

  return (
    <div className="mb-6 overflow-y-auto no-scroll-bar">
      <ol>
        {
          uniqueMembers.map((user) => (
            <li key={user.id} className="mb-4 last:mb-0">
              <div className="select-none">
                <span className="flex items-center max-w-max">
                  <UserInfo user={user} className="mr-2" />
                </span>
              </div>
            </li>
          ))
        }
      </ol>
    </div>
  )
}

export default UsersAssignedList
