import React, { useCallback, useEffect } from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { getFormQuestionAttributeColumnCount, getFormQuestionAttributeDisplayAs, setQuestionAttributeColumnCount } from "reduxSlices/formBuilderSlice"
import FormsyValidation from "shared/FormsyValidation"
import { NumberInput } from "shared/inputs"
import { DROPDOWN } from "./LocationDisplayAs"

export const DEFAULT_COLUMN_COUNT = 1
const MIN_COLUMN_COUNT = 1

const LocationColumnCount = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const questionColumnCount = useSelector(getFormQuestionAttributeColumnCount({ sectionIndex, questionIndex }))
  const questionDisplayAs = useSelector(getFormQuestionAttributeDisplayAs({ sectionIndex, questionIndex }))

  const updateColumnCount = useCallback((newColumnCount) => dispatch(setQuestionAttributeColumnCount({
    sectionIndex, questionIndex, newColumnCount,
  })), [sectionIndex, questionIndex])

  // If the column count is set to undefined, then set it to the default count.
  useEffect(() => {
    if (questionColumnCount || questionColumnCount === 0) return

    updateColumnCount(DEFAULT_COLUMN_COUNT)
  }, [questionColumnCount, updateColumnCount])

  // A location question can display as radio buttons or a dropdown.
  // In the case of a dropdown, the column count is not applicable,
  // so we can ignore it.
  if (questionDisplayAs === DROPDOWN) return null

  return (
    <div className="mb-4 flex-center">
      <div className="font-medium">Columns</div>
      <div className="w-1/2">
        <NumberInput
          className="bordered-base-input"
          value={questionColumnCount}
          min={MIN_COLUMN_COUNT}
          onChange={updateColumnCount}
          required
        />
        <FormsyValidation
          name={`sections[${sectionIndex}].questions[${questionIndex}].attributes.columnCount`}
          value={questionColumnCount}
          validations={{
            isNumberGreaterThanOrEqualTo: { min: MIN_COLUMN_COUNT },
          }}
          validationErrors={{
            isNumberGreaterThanOrEqualTo: "One column required",
          }}
        />
      </div>
    </div>
  )
}

LocationColumnCount.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default LocationColumnCount
