import React from "react"
import types from "prop-types"
import clsx from "clsx"

const DEFAULT_CLASSES = "px-4 pb-4 flex-grow border-b border-lightgray-3"

const TileContent = ({ children, className = "" }) => (
  <div className={clsx(className, DEFAULT_CLASSES)}>
    {children}
  </div>
)

TileContent.propTypes = {
  children: types.node.isRequired,
  className: types.string,
}

export default TileContent
