/* eslint-disable react/prop-types */
import React from "react"
import Options from "shared/Options"

const doesMatchSearch = (search, label) => label.toLowerCase().includes(search.toLowerCase())

// eslint-disable-next-line react/display-name
const UserDropdown = (users) => (props) => {
  const {
    state: {
      search,
    },
    methods: { addItem },
  } = props

  const filteredUsers = users.filter(({ label }) => doesMatchSearch(search, label))

  return (
    <Options
      title="Users"
      selectOption={addItem}
      options={filteredUsers}
      search={search}
    />
  )
}

export default UserDropdown
