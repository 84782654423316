import React from "react"
import types from "prop-types"
import { SearchSelectedGroup } from "shared/searches"

const GroupFormBuilderList = ({ formBuilderGroups = [], deleteCategoryAbility }) => {
  if (!formBuilderGroups) {
    return <div>No Form Builder Groups</div>
  }

  return (
    formBuilderGroups.map((formBuilder) => (
      <SearchSelectedGroup
        key={formBuilder.abilityId}
        className="mb-2"
        group={formBuilder.grantee}
        deselect={deleteCategoryAbility(formBuilder.abilityId)}
      />
    ))
  )
}

GroupFormBuilderList.propTypes = {
  formBuilderGroups: types.arrayOf(types.object),
  deleteCategoryAbility: types.func.isRequired,
}

export default GroupFormBuilderList
