import React from "react"
import types from "prop-types"
import clsx from "clsx"

const statClassList = ({ error, success }) => clsx(
  "text-2xl mb-2",
  error && "text-error",
  success && "text-success",
)

const SurveyBatchStat = ({
  className = "",
  error = false,
  stat,
  success = false,
  text,
}) => (
  <div className={clsx(className, "flex flex-col items-start bg-white rounded border border-light-300 p-4")}>
    <span className={statClassList({ error, success })}>{stat}</span>
    <span>{text}</span>
  </div>
)

SurveyBatchStat.propTypes = {
  className: types.string,
  error: types.bool,
  stat: types.oneOfType([types.number, types.string]).isRequired,
  success: types.bool,
  text: types.string.isRequired,
}

export default SurveyBatchStat
