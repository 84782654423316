import { useEffect, useState } from "react"
import { sortBy } from "lodash-es"
import * as API from "services/api"
import { errorToast } from "components/shared/toast"

const LOAD_FACILITIES_ERROR_MESSAGE = "Something went wrong.  Unable to load facilities."
const LOAD_DEPARTMENTS_ERROR_MESSAGE = "Something went wrong.  Unable to load departments."

const REPORTABLE_FIELD_LOCATION_ANSWER_FILTER_TEMPLATE = {
  reportableFieldId: null,
  departmentIds: [],
}

function useReportableFieldLocationAnswerFilter() {
  // ... location answers
  const [facilities, setFacilities] = useState([])
  const [departments, setDepartments] = useState({})
  const [reportableFieldLocationAnswerFilters, setReportableFieldLocationAnswerFilters] = useState([])
  const [selectedFacilityIds, setSelectedFacilityIds] = useState([])

  useEffect(() => {
    const loadFacilities = async () => {
      const response = await API.getFacilities()

      if (response.ok) {
        setFacilities(sortBy(response.data, "name"))
      } else {
        errorToast(LOAD_FACILITIES_ERROR_MESSAGE)
      }
    }

    loadFacilities()
  }, [])

  useEffect(() => {
    const loadDepartments = async () => {
      const response = await API.getDepartments()

      if (response.ok) {
        const departmentsByFacility = sortBy(response.data, "name").reduce((aggregator, department) => {
          if (aggregator[department.facilityId]) {
            aggregator[department.facilityId].push(department)
          } else {
            aggregator[department.facilityId] = [department]
          }

          return aggregator
        }, {})

        setDepartments(departmentsByFacility)
      } else {
        errorToast(LOAD_DEPARTMENTS_ERROR_MESSAGE)
      }
    }

    loadDepartments()
  }, [])

  const facilityDepartments = (facilityId) => departments[facilityId] ?? []

  const setReportableFieldLocationAnswerFilterReportableFieldId = (reportableFieldId) => {
    let newReportableFieldLocationFilters = []

    if (reportableFieldId) {
      const currentReportableFieldLocationFilter = reportableFieldLocationAnswerFilters[0] ?? { ...REPORTABLE_FIELD_LOCATION_ANSWER_FILTER_TEMPLATE }
      newReportableFieldLocationFilters = [{ ...currentReportableFieldLocationFilter, reportableFieldId }]
    }

    setReportableFieldLocationAnswerFilters(newReportableFieldLocationFilters)
  }

  const updateReportableFieldLocationAnswerFiltersDepartmentIds = (departmentIds) => {
    const currentReportableFieldLocationFilter = reportableFieldLocationAnswerFilters[0]

    setReportableFieldLocationAnswerFilters(
      [
        {
          ...currentReportableFieldLocationFilter,
          departmentIds,
        },
      ],
    )
  }

  const addDepartmentIdsToReportableFieldLocationAnswerFilters = (departmentIds) => {
    const currentReportableFieldLocationFilter = reportableFieldLocationAnswerFilters[0]
    const updatedDepartmentIds = Array.from(new Set([...currentReportableFieldLocationFilter.departmentIds, ...departmentIds]))
    updateReportableFieldLocationAnswerFiltersDepartmentIds(updatedDepartmentIds)
  }

  const addAllFacilityDepartmentsToReportableFieldLocationAnswerFilters = (facilityId) => {
    addDepartmentIdsToReportableFieldLocationAnswerFilters(
      facilityDepartments(facilityId).map((department) => department.id),
    )
  }

  const removeDepartmentIdsFromReportableFieldLocationAnswerFilters = (departmentIds) => {
    const currentReportableFieldLocationFilter = reportableFieldLocationAnswerFilters[0]
    const updatedDepartmentIds = currentReportableFieldLocationFilter.departmentIds.filter((id) => !departmentIds.includes(id))
    updateReportableFieldLocationAnswerFiltersDepartmentIds(updatedDepartmentIds)
  }

  const removeAllFacilityDepartmentsFromReportableFieldLocationAnswerFilters = (facilityId) => {
    removeDepartmentIdsFromReportableFieldLocationAnswerFilters(
      facilityDepartments(facilityId).map((department) => department.id),
    )
  }

  return {
    addAllFacilityDepartmentsToReportableFieldLocationAnswerFilters,
    addDepartmentIdsToReportableFieldLocationAnswerFilters,
    departments,
    facilities,
    facilityDepartments,
    removeAllFacilityDepartmentsFromReportableFieldLocationAnswerFilters,
    removeDepartmentIdsFromReportableFieldLocationAnswerFilters,
    reportableFieldLocationAnswerFilters,
    selectedFacilityIds,
    setReportableFieldLocationAnswerFilterReportableFieldId,
    setReportableFieldLocationAnswerFilters,
    setSelectedFacilityIds,
  }
}

export default useReportableFieldLocationAnswerFilter
