import * as AnswerFields from "Forms/FormElements/AnswerFields"
import * as Config from "FormBuilder/QuestionFields/QuestionTypeAttributes"
import { TextInput, NumberInput } from "shared/inputs"
import { ListSelect } from "shared/selects"
import { ListMultiSelect } from "shared/multiSelects"
import { RADIO as LOCATION_QUESTION_DEFAULT_DISPLAY_AS } from "FormBuilder/QuestionFields/QuestionTypeAttributes/LocationAttributes/LocationDisplayAs"
import { DEFAULT_COLUMN_COUNT as LOCATION_QUESTION_DEFAULT_COLUMN_COUNT } from "FormBuilder/QuestionFields/QuestionTypeAttributes/LocationAttributes/LocationColumnCount"
import { DROPDOWN, RADIO } from "FormBuilder/QuestionFields/QuestionTypeAttributes/SelectAttributes/DisplayAs"
import { DEFAULT_COLUMN_COUNT } from "FormBuilder/QuestionFields/QuestionTypeAttributes/SelectAttributes/ColumnCount"
import { ADD_OPERATOR } from "utils/calculationOperators"
import { Options as SelectOptions } from "FormBuilder/QuestionFields/QuestionTypeAttributes/SelectAttributes"
import { SelectCSVOptions } from "FormBuilder/QuestionFields/QuestionTypeAttributes/SelectCSVAttributes"
import {
  LocationIsAnyOfDepartmentsMatchValueInput,
  LocationIsAnyOfFacilitiesMatchValueInput,
  LocationIsDepartmentMatchValueInput,
  LocationIsFacilityMatchValueInput,
} from "FormBuilder/QuestionBranching/ConditionMatchValueInputs"
import {
  BLANK,
  NOT_BLANK,
  IS,
  HAS_SELECTION,
  INCLUDES_ANY_OF,
  NO_SELECTION,
  LESS_THAN,
  GREATER_THAN,
  HAS_DEPARTMENT,
  NO_DEPARTMENT,
  IS_FACILITY,
  IS_NOT_FACILITY,
  IS_ANY_OF_FACILITIES,
  IS_DEPARTMENT,
  IS_ANY_OF_DEPARTMENTS,
} from "utils/QuestionBranching"
import {
  DATE_REPORTABLE_FIELD_TYPE,
  EMAIL_REPORTABLE_FIELD_TYPE,
  LOCATION_REPORTABLE_FIELD_TYPE,
  MULTI_ENTRIES_REPORTABLE_FIELD_TYPE,
  MULTI_SELECT_REPORTABLE_FIELD_TYPE,
  NOTE_ANSWER_REPORTABLE_FIELD_TYPE,
  NUMBER_CALCULATION_REPORTABLE_FIELD_TYPE,
  NUMBER_REPORTABLE_FIELD_TYPE,
  PHONE_REPORTABLE_FIELD_TYPE,
  SELECT_CSV_REPORTABLE_FIELD_TYPE,
  SELECT_REPORTABLE_FIELD_TYPE,
  TEXT_FIELD_REPORTABLE_FIELD_TYPE,
  TIME_DIFFERENCE_REPORTABLE_FIELD_TYPE,
  TIME_REPORTABLE_FIELD_TYPE,
} from "utils/reportableFieldHelpers"

const defaultOperators = [NOT_BLANK, BLANK, IS]

export const DATE_TYPE = "date"
export const EMAIL_TYPE = "email"
export const NUMBER_TYPE = "number"
export const NUMBER_CALCULATION_TYPE = "numberCalculation"
export const SELECT_TYPE = "select"
export const SELECT_CSV_TYPE = "select_csv"
export const MULTI_SELECT_TYPE = "multiSelect"
export const PHONE_TYPE = "phone"
export const TEXT_TYPE = "text"
export const LONG_ANSWER_TYPE = "longAnswer"
export const TIME_TYPE = "time"
export const TIME_DIFFERENCE_TYPE = "timeDifference"
export const FULL_NAME_TYPE = "fullName"
export const LOCATION_TYPE = "location"
export const MULTI_ENTRIES_TYPE = "multiEntries"
export const NOTE_ANSWER_TYPE = "note"

const FORM_ELEMENT_TYPES = {
  [DATE_TYPE]: {
    label: "Date",
    description: "Date in MM/DD/YYYY format.",
    icon: "date",
    newSubmissionAnswerFieldClassName: "w-2/3 min-w-max",
    editSubmissionAnswerFieldClassName: "",
    configComponent: Config.DateInputConfig,
    answerField: AnswerFields.DateAnswerField,
    conditionOperators: defaultOperators,
    matchValueInput: TextInput,
    getInitialAttributes: () => ({
      currentDateValue: false,
      preventPastDateValue: false,
      preventFutureDateValue: false,
    }),
    requiresTaskAnswerEditingSessions: true,
    questionTaggable: true,
    reportableFieldType: DATE_REPORTABLE_FIELD_TYPE,
    answerRequirable: true,
  },
  [EMAIL_TYPE]: {
    label: "Email",
    description: "Valid email address.",
    icon: "email",
    editSubmissionAnswerFieldClassName: "",
    answerField: AnswerFields.EmailAnswerField,
    configComponent: Config.DefaultConfig,
    conditionOperators: [NOT_BLANK, BLANK],
    requiresTaskAnswerEditingSessions: true,
    questionTaggable: true,
    reportableFieldType: EMAIL_REPORTABLE_FIELD_TYPE,
    answerRequirable: true,
  },
  [NUMBER_TYPE]: {
    label: "Number",
    description: "Number answers.",
    icon: "number",
    editSubmissionAnswerFieldClassName: "",
    configComponent: Config.NumberInputConfig,
    answerField: AnswerFields.NumberAnswerField,
    conditionOperators: [NOT_BLANK, BLANK, IS, LESS_THAN, GREATER_THAN],
    matchValueInput: NumberInput,
    getInitialAttributes: () => ({
      isDropdown: false,
      precision: 0,
    }),
    requiresTaskAnswerEditingSessions: true,
    questionTaggable: true,
    reportableFieldType: NUMBER_REPORTABLE_FIELD_TYPE,
    answerRequirable: true,
  },
  [NUMBER_CALCULATION_TYPE]: {
    label: "Number Calculation",
    description: "Calculates value from other number inputs.",
    icon: "number-calc",
    editSubmissionAnswerFieldClassName: "",
    configComponent: Config.NumberCalcInputConfig,
    answerField: AnswerFields.NumberCalcAnswerField,
    getInitialAttributes: () => ({
      calcQuestionUuids: ["", ""],
      operator: ADD_OPERATOR,
      precision: 0,
    }),
    requiresTaskAnswerEditingSessions: false,
    reportableFieldType: NUMBER_CALCULATION_REPORTABLE_FIELD_TYPE,
    answerRequirable: false,
  },
  [SELECT_TYPE]: {
    label: "Single Select",
    description: "Users can pick 1 option from a list.",
    icon: "select",
    editSubmissionAnswerFieldClassName: "",
    configComponent: Config.SelectInputConfig,
    optionsComponent: SelectOptions,
    answerField: AnswerFields.SelectAnswerField,
    hasBaseInputStyle: false,
    conditionOperators: [HAS_SELECTION, NO_SELECTION, IS, INCLUDES_ANY_OF],
    matchValueInput: ListSelect,
    getInitialAttributes: () => ({
      displayAs: RADIO,
      options: ["Option 1"],
      columnCount: DEFAULT_COLUMN_COUNT,
    }),
    requiresTaskAnswerEditingSessions: true,
    questionTaggable: true,
    questionFlaggable: true,
    reportableFieldType: SELECT_REPORTABLE_FIELD_TYPE,
    answerRequirable: true,
  },
  [SELECT_CSV_TYPE]: {
    label: "Single Select CSV",
    description: "Users can pick 1 option from a list. Options managed via CSV upload.",
    icon: "select",
    editSubmissionAnswerFieldClassName: "",
    configComponent: Config.SelectCSVInputConfig,
    optionsComponent: SelectCSVOptions,
    answerField: AnswerFields.SelectAnswerField,
    hasBaseInputStyle: false,
    conditionOperators: [HAS_SELECTION, NO_SELECTION, IS, INCLUDES_ANY_OF],
    matchValueInput: ListSelect,
    getInitialAttributes: () => ({
      csv: null,
      displayAs: DROPDOWN,
      options: [],
    }),
    requiresTaskAnswerEditingSessions: true,
    questionTaggable: true,
    questionFlaggable: true,
    reportableFieldType: SELECT_CSV_REPORTABLE_FIELD_TYPE,
    answerRequirable: true,
  },
  [MULTI_SELECT_TYPE]: {
    label: "Multiple Select",
    description: "Users can pick multiple options from a list.",
    editSubmissionAnswerFieldClassName: "",
    icon: "multi-select",
    configComponent: Config.MultiSelectConfig,
    optionsComponent: SelectOptions,
    answerField: AnswerFields.MultiSelectAnswerField,
    conditionOperators: [HAS_SELECTION, NO_SELECTION, INCLUDES_ANY_OF],
    matchValueInput: ListMultiSelect,
    getInitialAttributes: () => ({
      defaultAnswers: [],
      displayAs: DROPDOWN,
      options: ["Option 1"],
      columnCount: DEFAULT_COLUMN_COUNT,
    }),
    requiresTaskAnswerEditingSessions: true,
    questionFlaggable: true,
    reportableFieldType: MULTI_SELECT_REPORTABLE_FIELD_TYPE,
    answerRequirable: true,
  },
  [PHONE_TYPE]: {
    label: "Phone",
    description: "Valid phone number.",
    icon: "phone",
    editSubmissionAnswerFieldClassName: "",
    answerField: AnswerFields.PhoneAnswerField,
    configComponent: Config.DefaultConfig,
    conditionOperators: [NOT_BLANK, BLANK],
    requiresTaskAnswerEditingSessions: true,
    questionTaggable: true,
    reportableFieldType: PHONE_REPORTABLE_FIELD_TYPE,
    answerRequirable: true,
  },
  [TEXT_TYPE]: {
    label: "Short Answer",
    description: "These answers should be no more than one line.",
    icon: "short-answer",
    editSubmissionAnswerFieldClassName: "",
    answerField: AnswerFields.LongAnswerField, //  display full value of longer answers. Future work: all text types will be "long answer" format.
    configComponent: Config.DefaultConfig,
    conditionOperators: [NOT_BLANK, BLANK],
    requiresTaskAnswerEditingSessions: true,
    questionTaggable: true,
    reportableFieldType: TEXT_FIELD_REPORTABLE_FIELD_TYPE,
    answerRequirable: true,
  },
  [LONG_ANSWER_TYPE]: {
    label: "Long Answer",
    description: "These answers can be more than one line.",
    icon: "long-answer",
    editSubmissionAnswerFieldClassName: "",
    answerField: AnswerFields.LongAnswerField,
    configComponent: Config.DefaultConfig,
    conditionOperators: defaultOperators,
    requiresTaskAnswerEditingSessions: true,
    questionTaggable: true,
    reportableFieldType: TEXT_FIELD_REPORTABLE_FIELD_TYPE,
    answerRequirable: true,
  },
  [TIME_TYPE]: {
    label: "Time",
    description: "To collect answers in time format.",
    icon: "time",
    editSubmissionAnswerFieldClassName: "",
    newSubmissionAnswerFieldClassName: "w-2/3 min-w-max",
    configComponent: Config.TimeInputConfig,
    answerField: AnswerFields.TimeAnswerField,
    conditionOperators: defaultOperators,
    matchValueInput: TextInput,
    getInitialAttributes: () => ({
      setCurrentTime: false,
    }),
    requiresTaskAnswerEditingSessions: true,
    reportableFieldType: TIME_REPORTABLE_FIELD_TYPE,
    answerRequirable: true,
  },
  [TIME_DIFFERENCE_TYPE]: {
    label: "Time Difference",
    description: "Calculates difference between two times.",
    icon: "number-calc",
    editSubmissionAnswerFieldClassName: "",
    configComponent: Config.TimeDifferenceInputConfig,
    answerField: AnswerFields.TimeDifferenceAnswerField,
    getInitialAttributes: () => ({
      startTimeQuestionUuid: "",
      endTimeQuestionUuid: "",
    }),
    requiresTaskAnswerEditingSessions: false,
    reportableFieldType: TIME_DIFFERENCE_REPORTABLE_FIELD_TYPE,
    answerRequirable: false,
  },
  [FULL_NAME_TYPE]: {
    label: "Full Name",
    description: "First and last name combination.",
    icon: "full-name",
    editSubmissionAnswerFieldClassName: "",
    configComponent: Config.DefaultConfig,
    answerField: AnswerFields.FullNameField,
    conditionOperators: defaultOperators,
    matchValueInput: TextInput,
    requiresTaskAnswerEditingSessions: true,
    questionTaggable: true,
    reportableFieldType: TEXT_FIELD_REPORTABLE_FIELD_TYPE,
    answerRequirable: true,
  },
  [LOCATION_TYPE]: {
    label: "Location",
    description: "Facility and Department.",
    icon: "location",
    editSubmissionAnswerFieldClassName: "",
    configComponent: Config.LocationConfig,
    conditionOperators: [
      HAS_DEPARTMENT,
      NO_DEPARTMENT,
      IS_FACILITY,
      IS_NOT_FACILITY,
      IS_ANY_OF_FACILITIES,
      IS_DEPARTMENT,
      IS_ANY_OF_DEPARTMENTS,
    ],
    answerField: AnswerFields.LocationAnswerField,
    getInitialAttributes: () => ({
      columnCount: LOCATION_QUESTION_DEFAULT_COLUMN_COUNT,
      displayAs: LOCATION_QUESTION_DEFAULT_DISPLAY_AS,
    }),
    requiresTaskAnswerEditingSessions: true,
    reportableFieldType: LOCATION_REPORTABLE_FIELD_TYPE,
    answerRequirable: true,
  },
  [MULTI_ENTRIES_TYPE]: {
    isSubformField: true,
    label: "Multiple Entries",
    description: "Collect multiple answers and timestamps from different users (for task forms only).",
    icon: "fas fa-stream",
    configComponent: Config.DefaultConfig,
    answerField: AnswerFields.MultiEntriesAnswerField,
    requiresTaskAnswerEditingSessions: false,
    reportableFieldType: MULTI_ENTRIES_REPORTABLE_FIELD_TYPE,
    answerRequirable: true,
  },
  [NOTE_ANSWER_TYPE]: {
    label: "Note Answer",
    description: "Leave helpful notes about forms and questions. Links are permitted.",
    icon: "long-answer",
    editSubmissionAnswerFieldClassName: "",
    answerField: AnswerFields.NoteAnswerField,
    configComponent: Config.NoteConfig,
    conditionOperators: defaultOperators,
    getInitialAttributes: () => ({
      note: "",
    }),
    reportableFieldType: NOTE_ANSWER_REPORTABLE_FIELD_TYPE,
    answerRequirable: false,
  },
}

const DEFAULT_FORM_ELEMENT = FORM_ELEMENT_TYPES[TEXT_TYPE]

export const formElementTypeOptions = (isSubform) => Object.entries(FORM_ELEMENT_TYPES)
  .filter(([, { isSubformField }]) => {
    const isRegularField = (isSubformField === undefined)

    if (isRegularField) return true

    return isSubform && isSubformField
  })
  .map(([value, { label, icon, description }]) => ({
    label,
    value,
    icon,
    description,
  }))

const formElementType = (questionType) => (
  FORM_ELEMENT_TYPES[questionType] || DEFAULT_FORM_ELEMENT
)

export const answerField = ({ questionType }) => (
  formElementType(questionType).answerField
)

export const optionsComponent = ({ questionType }) => (
  formElementType(questionType).optionsComponent
)

export const conditionOperators = ({ questionType }) => (
  formElementType(questionType).conditionOperators || []
)

export const configComponent = ({ questionType }) => (
  formElementType(questionType).configComponent
)

export const matchValueInput = ({ questionType, conditionOperator }) => {
  // Overridden by conditionOperator because the INCLUDES_ANY_OF operator will always be a multi-select
  if (conditionOperator === INCLUDES_ANY_OF) {
    return ListMultiSelect
  }

  if (questionType === LOCATION_TYPE && conditionOperator === IS_ANY_OF_FACILITIES) {
    return LocationIsAnyOfFacilitiesMatchValueInput
  }

  if (questionType === LOCATION_TYPE && [IS_FACILITY, IS_NOT_FACILITY].includes(conditionOperator)) {
    return LocationIsFacilityMatchValueInput
  }

  if (questionType === LOCATION_TYPE && conditionOperator === IS_DEPARTMENT) {
    return LocationIsDepartmentMatchValueInput
  }

  if (questionType === LOCATION_TYPE && conditionOperator === IS_ANY_OF_DEPARTMENTS) {
    return LocationIsAnyOfDepartmentsMatchValueInput
  }

  return formElementType(questionType).matchValueInput
}

export const questionTypeLabel = ({ questionType }) => (
  formElementType(questionType).label
)

export const questionTypeIcon = ({ questionType }) => (
  formElementType(questionType).icon
)

export const answerMayBeRequired = ({ questionType }) => (
  formElementType(questionType).answerRequirable
)

export const reportableFieldQuestionType = ({ questionType }) => {
  if (questionType === undefined) return null

  return formElementType(questionType).reportableFieldType
}

export const initialAttributes = ({ questionType }) => (
  formElementType(questionType).getInitialAttributes?.() || {}
)

export const newSubmissionAnswerFieldClassName = ({ questionType }) => (
  formElementType(questionType).newSubmissionAnswerFieldClassName
)

export const editSubmissionAnswerFieldClassName = ({ questionType }) => (
  formElementType(questionType).editSubmissionAnswerFieldClassName
)

export const requiresTaskAnswerEditingSession = ({ questionType }) => (
  formElementType(questionType).requiresTaskAnswerEditingSessions
)

export const questionTypeSupportsOptions = ({ questionType }) => (
  Boolean(FORM_ELEMENT_TYPES[questionType].optionsComponent)
)

export const questionTypeSupportsNote = ({ questionType }) => (
  questionType === NOTE_ANSWER_TYPE
)

export const getIsQuestionTaggable = ({ questionType }) => (
  formElementType(questionType)?.questionTaggable ?? false
)

export const getIsQuestionFlaggable = ({ questionType }) => (
  formElementType(questionType)?.questionFlaggable ?? false
)
