import React, { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getFormSubmissionTable, switchSolution } from "reduxSlices/formSubmissionTableSlice"
import { CategorySelect } from "shared/selects"
import { SidebarCollapsibleContent } from "shared/Sidebar"

const SubmissionCategoryFilter = () => {
  const dispatch = useDispatch()

  const { categories, selectedCategorySlug, surveyCategories } = useSelector(getFormSubmissionTable)

  const filteredCategories = useMemo(() => (
    categories.filter((category) => category.notClosedSubmissionCount !== 0)
  ), [categories])

  const changeSelectedCategory = (categorySlug) => {
    if (categorySlug !== selectedCategorySlug) {
      const selectedCategoryUsedForSurveys = surveyCategories.some((category) => category === categorySlug)

      dispatch(switchSolution({ categorySlug, usedForSurveys: selectedCategoryUsedForSurveys }))
    }
  }

  return (
    <SidebarCollapsibleContent title="Solution">
      <CategorySelect
        className="bg-white text-sm"
        isFullWidth
        options={filteredCategories}
        value={selectedCategorySlug}
        onChange={changeSelectedCategory}
        backspaceDelete={false}
      />
    </SidebarCollapsibleContent>
  )
}

export default SubmissionCategoryFilter
