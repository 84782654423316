import React from "react"
import { useHistoricalAccessToolContext } from "contexts/HistoricalAccessToolContext"
import { ADD_HISTORICAL_ACCESS, REMOVE_HISTORICAL_ACCESS } from "contexts/HistoricalAccessToolContext/HistoricalAccessToolContextProvider"
import { snakeToCapitalCase } from "utils/stringHelpers"
import { RadioButton } from "shared/inputs"

const HISTORICAL_ACCESS_TOOL_ACTION_OPTIONS = [ADD_HISTORICAL_ACCESS, REMOVE_HISTORICAL_ACCESS]

const HistoricalAccessToolAction = () => {
  const { historicalAccessAction, setHistoricalAccessAction } = useHistoricalAccessToolContext()

  return (
    <>
      {
        HISTORICAL_ACCESS_TOOL_ACTION_OPTIONS.map((option) => (
          <RadioButton
            key={option}
            checked={historicalAccessAction === option}
            className="mb-4 last:mb-0"
            label={snakeToCapitalCase(option)}
            name="historical-access-tool-action"
            onChange={setHistoricalAccessAction}
            value={option}
          />
        ))
      }
    </>
  )
}

export default HistoricalAccessToolAction
