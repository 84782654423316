import React from "react"
import types from "prop-types"
import Formsy from "formsy-react"
import useSubmit from "hooks/useSubmit"
import { PrimaryButton } from "shared/buttons"
import DepartmentName from "./DepartmentName"

const GeneralTab = ({ department, updateDepartment }) => {
  const { canSubmit, enableSubmit, disableSubmit } = useSubmit()

  return (
    <>
      <h2 className="tab-title">General</h2>
      <Formsy
        onSubmit={updateDepartment}
        onValid={enableSubmit}
        onInvalid={disableSubmit}
        preventDefaultSubmit
      >
        <DepartmentName department={department} />
        <PrimaryButton
          className="absolute-horizontal-center bottom-16"
          text="Save changes"
          type="submit"
          disabled={!canSubmit}
        />
      </Formsy>
    </>
  )
}

GeneralTab.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  department: types.object.isRequired,
  updateDepartment: types.func.isRequired,
}

export default GeneralTab
