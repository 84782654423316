// Match value in User model password validation
export const MIN_PASSWORD_LENGTH = 10
export const MIN_PHONE_DIGITS = 10

export const isMinLength = {
  test: (value) => value?.toString().trim().length >= 10,
  message: `Must be at least ${MIN_PASSWORD_LENGTH} characters`,
}

export const includesLowercaseLetter = {
  test: (value) => value?.toString().toUpperCase() !== value,
  message: "Must include at least 1 lowercase letter",
}

export const includesUppercaseLetter = {
  test: (value) => value?.toString().toLowerCase() !== value,
  message: "Must include at least 1 uppercase letter",
}

export const includesNumber = {
  test: (value) => /\d/.test(value?.toString()),
  message: "Must include at least 1 number",
}

// Password Strength
// Variables and implementation match those in PasswordStrengthValidator
export const MINIMUM_REQUIRED_PASSWORD_STRENGTH_SCORE = 3

const WEAK_WORDS = [
  "health",
  "incident",
  "performance",
  "patient",
  "report",
  "safety",
]

export const passwordStrength = (value) => window.zxcvbn(value, WEAK_WORDS).score

export const meetsPasswordStrengthRequirement = {
  test: (value) => {
    if (!value) return false

    return passwordStrength(value) >= MINIMUM_REQUIRED_PASSWORD_STRENGTH_SCORE
  },
  message: "Must be difficult to guess",
}
