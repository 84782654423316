/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from "react"
import types from "prop-types"
import { newCondition, isAlwaysOperator } from "utils/workflowHelpers"
import { workflowConditionsShape } from "utils/propTypeShapes"
import { Button } from "shared/buttons"
import { Checkbox } from "shared/checkboxes"
import FormsyValidation from "shared/FormsyValidation"
import SelectOperator from "./SelectOperator"
import Condition from "./Condition"

const Conditions = ({
  conditions: { operator, conditions },
  isSubform,
  onetime,
  setWorkflow,
}) => {
  const isAlways = isAlwaysOperator(operator)

  const updateOperator = (newOperator) => {
    const newConditions = { operator: newOperator, conditions }
    setWorkflow((wf) => ({ ...wf, conditions: newConditions }))
  }

  const updateConditions = (updatedConditions) => {
    const newConditions = { operator, conditions: updatedConditions }
    setWorkflow((wf) => ({ ...wf, conditions: newConditions }))
  }

  const addCondition = () => updateConditions([...conditions, newCondition()])

  const removeCondition = (index) => () => {
    const newConditions = [...conditions]
    newConditions.splice(index, 1)
    updateConditions(newConditions)
  }

  const changeConditionProperty = (index) => (property) => {
    const newConditions = [...conditions]
    newConditions[index] = { ...newConditions[index], property }
    updateConditions(newConditions)
  }

  const changeConditionSubProperty = (index) => (subProperty) => {
    const newConditions = [...conditions]
    const updatedCondition = {
      ...conditions[index],
      subProperty,
    }

    newConditions.splice(index, 1, updatedCondition)
    updateConditions(newConditions)
  }

  const changeConditionOperator = (index) => (op) => {
    const newConditions = [...conditions]

    const updatedCondition = {
      ...conditions[index],
      operator: op,
    }

    newConditions.splice(index, 1, updatedCondition)
    updateConditions(newConditions)
  }

  const changeConditionValue = (index) => (value) => {
    const newConditions = [...conditions]

    const updatedCondition = {
      ...conditions[index],
      value,
    }

    newConditions.splice(index, 1, updatedCondition)
    updateConditions(newConditions)
  }

  useEffect(() => {
    if (isAlways) {
      updateConditions([])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAlways])

  const conditionsValidations = isAlways
    ? {}
    : { isNotEmptyArray: { errorMessage: "Must select at least one condition" } }

  const conditionDescription = isAlways
    ? "run this workflow"
    : "of the following conditions are met:"

  return (
    <>
      <div className="flex items-center">
        <SelectOperator operator={operator} updateOperator={updateOperator} />
        <span className="ml-2">{conditionDescription}</span>
      </div>
      {
        conditions.map((condition, index) => (
          <Condition
            key={condition.uuid}
            condition={condition}
            conditionIndex={index}
            operator={operator}
            isSubform={isSubform}
            isLastCondition={index === conditions.length - 1}
            removeCondition={removeCondition(index)}
            changeConditionProperty={changeConditionProperty(index)}
            changeConditionSubProperty={changeConditionSubProperty(index)}
            changeConditionOperator={changeConditionOperator(index)}
            changeConditionValue={changeConditionValue(index)}
          />
        ))
      }
      <FormsyValidation
        name="conditions.conditions[]"
        value={conditions}
        validations={conditionsValidations}
      />
      {
        !isAlways && (
          <Button
            className="mt-3 text-focus border border-light"
            onClick={addCondition}
            text="+ Add a condition"
          />
        )
      }
      <div className="mt-8 text-sm flex items-center">
        <Checkbox
          className="mr-2 cursor-pointer"
          uuid="workflow-onetime-checkbox"
          value={onetime}
          onChange={(value) => setWorkflow((wf) => ({ ...wf, onetime: value }))}
        />
        <label htmlFor="workflow-onetime-checkbox" className="cursor-pointer">
          Only run this workflow once per { isSubform ? "task" : "form submission" }
        </label>
      </div>
    </>
  )
}

Conditions.propTypes = {
  conditions: workflowConditionsShape.isRequired,
  isSubform: types.bool.isRequired,
  onetime: types.bool.isRequired,
  setWorkflow: types.func.isRequired,
}

export default Conditions
