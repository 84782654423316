import React from "react"
import ContentHeader from "shared/ContentHeader"
import RelatedFormSubmissions from "./RelatedFormSubmissions"
import SurveyDetails from "./SurveyDetails"

const SupplementalContent = () => (
  <div className="relative grow">
    <div className="inner-container mx-auto max-w-screen-lg">
      <ContentHeader
        heading={<h1 className="text-center header-title !mb-3">Supplemental</h1>}
      />
      <SurveyDetails className="mb-16" />
      <RelatedFormSubmissions />
    </div>
  </div>
)

export default SupplementalContent
