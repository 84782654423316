import { createSlice } from "@reduxjs/toolkit"

const emptyDraftAnswers = () => ({})

const INITIAL_STATE = {
  draftAnswers: emptyDraftAnswers(),
}

export const taskDraftAnswersSlice = createSlice({
  name: "tasks",
  initialState: INITIAL_STATE,
  reducers: {
    clearAllDraftAnswers: (state) => {
      state.draftAnswers = emptyDraftAnswers()
    },
    deleteDraftAnswer: (state, action) => {
      const { questionUuid, taskId } = action.payload

      if (!state.draftAnswers[taskId]) return

      delete state.draftAnswers[taskId][questionUuid]

      if (Object.keys(state.draftAnswers[taskId]).length === 0) {
        delete state.draftAnswers[taskId]
      }
    },
    setDraftAnswer: (state, action) => {
      const { answer, questionUuid, taskId } = action.payload
      state.draftAnswers[taskId] = {
        ...state.draftAnswers[taskId],
        [questionUuid]: answer,
      }
    },
  },
})

export const {
  clearAllDraftAnswers,
  setDraftAnswer,
  deleteDraftAnswer,
} = taskDraftAnswersSlice.actions

export const getDraftAnswer = ({ questionUuid, taskId }) => (state) => state.tasks.draftAnswers[taskId]?.[questionUuid]

export default taskDraftAnswersSlice.reducer
