export const capitalize = (text) => text.charAt(0).toUpperCase() + text.slice(1)

export const snakeToCapitalCase = (text) => text
  .split("_")
  .map(capitalize)
  .join(" ")

export const snakeTolowerCase = (text) => text
  .split("_")
  .join(" ")

const FILENAME_ILLEGAL_CHARS = /[/\\?%*:|"<>]/g
export const sanitizeFilename = (filename) => filename.replaceAll(" ", "").replace(FILENAME_ILLEGAL_CHARS, "-")
