export const DATE_REPORTABLE_FIELD_TYPE = "date"
export const EMAIL_REPORTABLE_FIELD_TYPE = "email"
export const NUMBER_REPORTABLE_FIELD_TYPE = "number"
export const NUMBER_CALCULATION_REPORTABLE_FIELD_TYPE = "numberCalculation"
export const SELECT_REPORTABLE_FIELD_TYPE = "select"
export const SELECT_CSV_REPORTABLE_FIELD_TYPE = "select_csv"
export const MULTI_SELECT_REPORTABLE_FIELD_TYPE = "multiSelect"
export const PHONE_REPORTABLE_FIELD_TYPE = "phone"
export const TIME_REPORTABLE_FIELD_TYPE = "time"
export const TIME_DIFFERENCE_REPORTABLE_FIELD_TYPE = "timeDifference"
export const LOCATION_REPORTABLE_FIELD_TYPE = "location"
export const MULTI_ENTRIES_REPORTABLE_FIELD_TYPE = "multiEntries"
export const NOTE_ANSWER_REPORTABLE_FIELD_TYPE = "note"

// Used when question is FULL_NAME, LONG_ANSWER, or TEXT (appears as Short Answer in UI)
export const TEXT_FIELD_REPORTABLE_FIELD_TYPE = "textField"

const REPORTABLE_FIELD_TYPES = {
  [TEXT_FIELD_REPORTABLE_FIELD_TYPE]: {
    label: "Text Field",
    icon: "long-answer",
  },
  [DATE_REPORTABLE_FIELD_TYPE]: {
    label: "Date",
    icon: "date",
  },
  [EMAIL_REPORTABLE_FIELD_TYPE]: {
    label: "Email",
    icon: "email",
  },
  [NUMBER_REPORTABLE_FIELD_TYPE]: {
    label: "Number",
    icon: "number",
  },
  [NUMBER_CALCULATION_REPORTABLE_FIELD_TYPE]: {
    label: "Number Calculation",
    icon: "number-calc",
  },
  [SELECT_REPORTABLE_FIELD_TYPE]: {
    label: "Single Select",
    icon: "select",
  },
  [SELECT_CSV_REPORTABLE_FIELD_TYPE]: {
    label: "Single Select CSV",
    icon: "select",
  },
  [MULTI_SELECT_REPORTABLE_FIELD_TYPE]: {
    label: "Multiple Select",
    icon: "multi-select",
  },
  [PHONE_REPORTABLE_FIELD_TYPE]: {
    label: "Phone",
    icon: "phone",
  },
  [TIME_REPORTABLE_FIELD_TYPE]: {
    label: "Time",
    icon: "time",
  },
  [TIME_DIFFERENCE_REPORTABLE_FIELD_TYPE]: {
    label: "Time Difference",
    icon: "number-calc",
  },
  [LOCATION_REPORTABLE_FIELD_TYPE]: {
    label: "Location",
    icon: "location",
  },
  [MULTI_ENTRIES_REPORTABLE_FIELD_TYPE]: {
    label: "Multiple Entries",
    icon: "fas fa-stream",
  },
  [NOTE_ANSWER_REPORTABLE_FIELD_TYPE]: {
    label: "Note Answer",
    icon: "long-answer",
  },
}

const DEFAULT_REPORTABLE_FIELD_TYPE = REPORTABLE_FIELD_TYPES[TEXT_FIELD_REPORTABLE_FIELD_TYPE]

export const CREATION_SUCCESS_MESSAGE = "Reporting field created successfully!"
export const UPDATE_SUCCESS_MESSAGE = "Reporting field updated successfully!"
export const TEXT_FIELD_HELPER_NOTE = "Use Text Field for Full Name, Short Answer, and Long Answer questions"

export const formatReportableField = (reportableField) => ({ ...reportableField, name: reportableField.name.trim() })

export const reportableFieldFormElementTypeOptions = () => Object.entries(REPORTABLE_FIELD_TYPES)
  .map(([value, { label, icon }]) => ({
    label,
    value,
    icon,
  }))

const reportableFieldTypeElement = (reportableFieldType) => (
  REPORTABLE_FIELD_TYPES[reportableFieldType] || DEFAULT_REPORTABLE_FIELD_TYPE
)

export const questionTypeLabel = ({ questionType }) => (
  reportableFieldTypeElement(questionType).label
)

export const questionTypeIcon = ({ questionType }) => (
  reportableFieldTypeElement(questionType).icon
)

// Provide a list of reportable fields organized by their types
export const reportableFieldsByType = ({ reportableFields = [] }) => (
  reportableFields.reduce((byType, reportableField) => {
    const { questionType } = reportableField

    if (!byType[questionType]) {
      byType[questionType] = [reportableField]
    } else {
      byType[questionType].push(reportableField)
    }

    return byType
  }, {})
)
