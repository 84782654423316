import React from "react"
import types from "prop-types"
import { FormsyMultilineTextInput } from "shared/inputs"

const EntryValue = ({ name, initialValue }) => (
  <div className="p-8 bg-white rounded-lg">
    <FormsyMultilineTextInput
      name={name}
      className="max-h-64 overflow-y-auto"
      value={initialValue}
      required
      validations={{
        isNotBlankString: true,
        noExcelMetacharacters: true,
        noHTML: true,
      }}
      validationErrors={{ isDefaultRequiredValue: "required" }}
    />
  </div>
)

EntryValue.propTypes = {
  name: types.string.isRequired,
  initialValue: types.string.isRequired,
}

export default EntryValue
