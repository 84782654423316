import React from "react"
import types from "prop-types"
import Switch from "shared/Switch"

const REPORTABLE_FIELD_REMOVAL_CONFIRMATION_MESSAGE = "Are you sure? Removing a reportable field "
+ "association to a question will result in future answers to the question not being available "
+ "in solution-level reports. If you're unsure about this action please contact Support."

// eslint-disable-next-line no-alert
const confirmReportableFieldRemoval = () => window.confirm(REPORTABLE_FIELD_REMOVAL_CONFIRMATION_MESSAGE)

const ReportableField = ({ field, checked, setReportableFieldId }) => {
  const addReportableField = (fieldId) => setReportableFieldId(fieldId)
  const removeReportableField = () => setReportableFieldId(null)

  const fieldSwitchToggle = (fieldId) => (fieldChecked) => {
    if (fieldChecked) {
      addReportableField(fieldId)
    } else if (confirmReportableFieldRemoval()) {
      removeReportableField()
    }
  }

  return (
    <div className="px-4 py-2 bg-white rounded-lg flex-center mb-2">
      <div>{ field.name }</div>
      <Switch
        checked={checked}
        onChange={fieldSwitchToggle(field.id)}
      />
    </div>
  )
}

ReportableField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  field: types.object.isRequired,
  checked: types.bool.isRequired,
  setReportableFieldId: types.func.isRequired,
}

export default ReportableField
