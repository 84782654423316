import React from "react"
import types from "prop-types"
import WorkflowsTab from "components/shared/WorkflowsTab"

const UserWorkflowsTab = ({ userWorkflows }) => (
  <WorkflowsTab associatedWorkflows={userWorkflows} groupOrUser="user" />
)

UserWorkflowsTab.propTypes = {
  userWorkflows: types.arrayOf(types.object).isRequired,
}

export default UserWorkflowsTab
