import React from "react"
import { store } from "store"
import { useSelector } from "react-redux"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { getTaskTable, reorderColumns } from "reduxSlices/taskTableSlice"
import Column from "./Column"

const ColumnList = () => {
  const { columns } = useSelector(getTaskTable)

  const changeColumns = ({ source, destination }) => store.dispatch(
    reorderColumns({
      startIndex: source.index,
      endIndex: destination.index,
    }),
  )

  return (
    <DragDropContext onDragEnd={changeColumns}>
      <Droppable droppableId="droppable">
        {
          (dropProvided) => (
            <div ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
              {
                columns.map(({ label, value }, index) => (
                  <Column
                    key={value}
                    label={label}
                    value={value}
                    index={index}
                    columns={columns}
                  />
                ))
              }
              { dropProvided.placeholder }
            </div>
          )
        }
      </Droppable>
    </DragDropContext>
  )
}

export default ColumnList
