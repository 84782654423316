import React from "react"
import types from "prop-types"
import clsx from "clsx"
import CreateBatchButton from "./CreateBatchButton"

const SurveyConfigurationButtons = ({ className = "" }) => (
  <div className={clsx("flex justify-center gap-3", className)}>
    <CreateBatchButton className="w-50" />
  </div>
)

SurveyConfigurationButtons.propTypes = {
  className: types.string,
}

export default SurveyConfigurationButtons
