import React, { useCallback } from "react"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { useDispatch } from "react-redux"
import { moveFormSection, moveFormQuestion } from "reduxSlices/formBuilderSlice"
import AddSectionButton from "FormBuilder/UIElements/AddSectionButton"
import DeletedQuestions from "FormBuilder/DeletedQuestions"
import { AdditionalSections, MainSection } from "FormBuilder/SectionFields"

const FormSections = () => {
  const dispatch = useDispatch()

  const reorderItems = useCallback(({
    draggableId, source, destination, type,
  }) => {
    if (!destination) return

    if (type === "SECTION") {
      dispatch(moveFormSection({
        from: {
          currentIndex: source.index,
        },
        to: {
          newIndex: destination.index,
        },
      }))
    } else {
      dispatch(moveFormQuestion({
        questionUuid: draggableId,
        from: {
          sectionUuid: source.droppableId,
          currentIndex: source.index,
        },
        to: {
          sectionUuid: destination.droppableId,
          newIndex: destination.index,
        },
      }))
    }
  }, [dispatch])

  return (
    <DragDropContext onDragEnd={reorderItems}>
      <Droppable droppableId="FORM" type="SECTION">
        {
          (dropProvided) => (
            <div
              ref={dropProvided.innerRef}
              {...dropProvided.droppableProps}
            >
              <MainSection />
              <AdditionalSections />
              { dropProvided.placeholder }
            </div>
          )
        }
      </Droppable>
      <AddSectionButton />
      <DeletedQuestions />
    </DragDropContext>
  )
}

export default FormSections
