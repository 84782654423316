import React from "react";
import types from "prop-types"
import { withFormsy } from "formsy-react"
import { userOptionShape } from "utils/propTypeShapes"
import { Select } from "shared/selects"
import GroupAndUserDropdown from "./GroupAndUserDropdown"
import SearchInput from "../SearchInput"

// value is prefixed with model name to differentiate the ids
const usersToOptions = (users) => users.map(({
  fullName, id, firstName, lastName, active,
}) => ({
  label: fullName, value: `User-${id}`, firstName, lastName, active,
}))

const groupsToOptions = (groups) => groups.map(({ name, id }) => (
  { label: name, value: `Group-${id}` }
))

const GroupAndUserSelect = ({
  placeholder = "Search for users and groups",
  className = "",
  onChange,
  users = [],
  groups = [],
  value = undefined,
  ...rest
}) => {
  const userOptions = usersToOptions(users)
  const groupOptions = groupsToOptions(groups)

  return (
    <Select
      className={className}
      isFullWidth
      contentRenderer={SearchInput}
      dropdownRenderer={GroupAndUserDropdown(userOptions, groupOptions)}
      placeholder={placeholder}
      onChange={onChange}
      dropdownHandle={false}
      options={[...userOptions, ...groupOptions]}
      searchable
      backspaceDelete={false}
      value={value}
      {...rest}
    />
  )
}

GroupAndUserSelect.propTypes = {
  className: types.string,
  onChange: types.func.isRequired,
  placeholder: types.string,
  users: types.arrayOf(userOptionShape),
  groups: types.arrayOf(types.object),
  value: types.arrayOf(types.number),
}

export default GroupAndUserSelect

export const FormsyGroupAndUserSelect = withFormsy(({ setValue, ...rest }) => (
  <GroupAndUserSelect onChange={setValue} {...rest} />
))

FormsyGroupAndUserSelect.displayName = "FormsyGroupAndUserSelect"
