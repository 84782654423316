import React, { useState } from "react"
import types from "prop-types"
import { Provider } from "react-redux"
import { store } from "store"
import { categoryShape } from "utils/propTypeShapes"
import CategoryTabs from "./CategoryTabs"

const CategoryForm = ({ category: categoryProp, accessLevelOptions }) => {
  const [category, setCategory] = useState(categoryProp)

  const tabProps = {
    category,
    setCategory,
    accessLevelOptions,
  }

  return (
    <Provider store={store}>
      <CategoryTabs tabProps={tabProps} />
    </Provider>
  )
}

CategoryForm.propTypes = {
  category: categoryShape.isRequired,
  accessLevelOptions: types.arrayOf(types.string).isRequired,
  // redirect: types.string,
}

export default CategoryForm
