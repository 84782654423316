import React from "react"
import { surveyConfigurationsForTileGridShape } from "utils/propTypeShapes"
import { LargeWrap } from "components/shared/wraps"
import SurveyHeader from "./Content/SurveyHeader"
import SurveyConfigurationTileGrid from "./Content/SurveyConfigurationTileGrid"

const SurveyConfigurationIndex = ({ surveyConfigurations }) => (
  <LargeWrap>
    <SurveyHeader className="mb-8" />
    <SurveyConfigurationTileGrid surveyConfigurations={surveyConfigurations} />
  </LargeWrap>
)

SurveyConfigurationIndex.propTypes = {
  surveyConfigurations: surveyConfigurationsForTileGridShape.isRequired,
}

export default SurveyConfigurationIndex
