import React from "react"
import { useSelector } from "react-redux"
import { attachments as getAttachments } from "reduxSlices/formSubmissionSlice"
import { CountBadge } from "components/shared/badges"

const AttachmentsCountBadge = () => {
  const attachments = useSelector(getAttachments)
  const count = attachments.length

  return count ? <CountBadge count={count} /> : null
}

export default AttachmentsCountBadge
