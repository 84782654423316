import React, { useEffect, useState } from "react"
import * as API from "services/api"
import ContentBar from "components/shared/lists/ContentBar"
import { errorToast } from "components/shared/toast"
import FacilityGroupCodesSelect from "./FacilityGroupCodesSelect"

// eslint-disable-next-line max-len
const GET_FAILURE_MESSAGE = "Something went wrong fetching facility group codes."
// eslint-disable-next-line max-len
const FACILITY_GROUP_CODES_DESCRIPTION = "Add or remove facility group codes from this facility. Facility group codes can be used in workflow conditions."
// eslint-disable-next-line max-len
const NO_FACILITY_GROUP_CODES_DESCRIPTION = "This organization does not currently have any facility group codes. Visit Admin Panel > General Settings to manage facility group codes."

const FacilityGroupCodes = () => {
  const [facilityGroupCodes, setFacilityGroupCodes] = useState([])

  useEffect(() => {
    async function getFacilityGroupCodes() {
      const response = await API.facilityGroupCodes()

      if (response.ok) {
        setFacilityGroupCodes(response.data)
      } else {
        errorToast(GET_FAILURE_MESSAGE)
      }
    }
    getFacilityGroupCodes()
  }, [])

  return (
    <ContentBar
      title="Facility group codes"
      description={facilityGroupCodes.length > 0
        ? FACILITY_GROUP_CODES_DESCRIPTION : NO_FACILITY_GROUP_CODES_DESCRIPTION}
    >
      {facilityGroupCodes.length > 0 && (
        <FacilityGroupCodesSelect
          facilityGroupCodes={facilityGroupCodes}
        />
      )}
    </ContentBar>
  )
}

export default FacilityGroupCodes
