import React from "react"
import { useSolutionLevelScheduledReportExportContext } from "contexts/SolutionLevelScheduledReportExportContext"
import SubmissionFilters from "shared/ScheduledReportExport/SubmissionFilters"

const NewSolutionLevelScheduledReportExportSubmissionFilters = () => {
  const {
    startDate,
    submissionPreviousRangeLength,
    submissionPreviousRangeUnit,
    setSubmissionPreviousRangeLength,
    lengthOptions,
  } = useSolutionLevelScheduledReportExportContext()

  return (
    <SubmissionFilters
      startDate={startDate}
      submissionPreviousRangeLength={submissionPreviousRangeLength}
      submissionPreviousRangeUnit={submissionPreviousRangeUnit}
      setSubmissionPreviousRangeLength={setSubmissionPreviousRangeLength}
      lengthOptions={lengthOptions}
    />
  )
}

export default NewSolutionLevelScheduledReportExportSubmissionFilters
