import { useEffect, useMemo } from "react"
import { isEqual } from "lodash-es"
import { useDispatch, useSelector } from "react-redux"
import { getOrganizationId } from "reduxSlices/currentSettingsSlice"
import { initialize, settingsToPersist } from "reduxSlices/formIndexSlice"
import { currentUserId as getCurrentUserId } from "reduxSlices/sessionSlice"
import { LocalStoragePersistence } from "utils/Persistence"

const SETTINGS_PERSISTENCE_PROPERTY = "settings"

const useFormIndexSettings = ({ forms = [], persistence = LocalStoragePersistence } = {}) => {
  const dispatch = useDispatch()

  const organizationId = useSelector(getOrganizationId)
  const currentUserId = useSelector(getCurrentUserId)
  const formIndexSettingsToPersist = useSelector(settingsToPersist, isEqual)

  const key = useMemo(() => (
    `form-index-settings-org-${organizationId}-user-${currentUserId}`
  ), [currentUserId, organizationId])

  // On load, get persisted settings and pass them to redux
  // when initializing the form index slice
  useEffect(() => {
    const persistedSettings = persistence.lookup({ key, property: SETTINGS_PERSISTENCE_PROPERTY })

    dispatch(initialize({ forms, persistedSettings }))
  }, [forms])

  // Keep settings up to date in local storage
  useEffect(() => {
    persistence.persist({ key, property: SETTINGS_PERSISTENCE_PROPERTY, value: formIndexSettingsToPersist })
  }, [formIndexSettingsToPersist, key, persistence])
}

export default useFormIndexSettings
