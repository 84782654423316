import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const IndicatorIcon = ({ className = "w-8 h-8", ...rest }) => (
  <SvgSpriteIcon
    iconName="indicator"
    className={className}
    {...rest}
  />
)

IndicatorIcon.propTypes = {
  className: types.string,
}

export default IndicatorIcon
