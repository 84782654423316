import React from "react"
import types from "prop-types"
import { ListSelect } from "shared/selects"
import {
  dayjsOrgTz, DAYS, MONTHS, WEEKS,
} from "utils/dateHelpers"
import DaysSubmissionDescription from "./DateFilterDescriptions/SubmissionDescription/DaysSubmissionDescription"
import WeeksSubmissionDescription from "./DateFilterDescriptions/SubmissionDescription/WeeksSubmissionDescription"
import MonthsSubmissionDescription from "./DateFilterDescriptions/SubmissionDescription/MonthsSubmissionDescription"

const SubmissionFilters = ({
  startDate, submissionPreviousRangeLength = null, submissionPreviousRangeUnit, setSubmissionPreviousRangeLength, lengthOptions,
}) => (
  <>
    <div className="flex items-center my-4">
      <span className="required-prompt">Submissions received in the previous</span>
      <ListSelect
        className="bordered-base-input ml-2 max-w-max"
        clearable={false}
        backspaceDelete={false}
        placeholder="1"
        name="submission_previous_range_length"
        required
        options={lengthOptions}
        value={submissionPreviousRangeLength}
        onChange={setSubmissionPreviousRangeLength}
      />
      <div className="ml-2">{submissionPreviousRangeUnit}</div>
    </div>

    {
      submissionPreviousRangeUnit === DAYS
      && (
        <DaysSubmissionDescription
          // DateInput sets value as a string
          startDate={dayjsOrgTz(startDate, "YYYY-MM-DD")}
          submissionPreviousRangeLength={submissionPreviousRangeLength}
        />
      )
    }
    {
      submissionPreviousRangeUnit === WEEKS
      && (
        <WeeksSubmissionDescription
          startDate={startDate}
          submissionPreviousRangeLength={submissionPreviousRangeLength}
        />
      )
    }
    {
      submissionPreviousRangeUnit === MONTHS
      && (
        <MonthsSubmissionDescription
          startDate={startDate}
          submissionPreviousRangeLength={submissionPreviousRangeLength}
        />
      )
    }
  </>
)

SubmissionFilters.propTypes = {
  startDate: types.object.isRequired,
  submissionPreviousRangeLength: types.number,
  submissionPreviousRangeUnit: types.string.isRequired,
  setSubmissionPreviousRangeLength: types.func.isRequired,
  lengthOptions: types.arrayOf(types.number).isRequired,
}

export default SubmissionFilters
