import React from "react"
import types from "prop-types"
import { useTaskFormContext } from "contexts/TaskFormContext"
import { ProfileIcon } from "shared/icons"
import CircularIconButton from "shared/buttons/CircularIconButton"
import ItemRow from "shared/ItemRow"
import Switch from "shared/Switch"

const TaskUser = ({ removeFromTask, user }) => {
  const {
    addNotifyId,
    addNotifyOnCompleteId,
    removeNotifyId,
    removeNotifyOnCompleteId,
    taskNotifyIds,
    taskNotifyOnCompleteIds,
  } = useTaskFormContext()

  const userNotified = taskNotifyIds.includes(user.id)
  const userNotifiedOnComplete = taskNotifyOnCompleteIds.includes(user.id)

  const toggleNotifyId = () => {
    const toggleFunction = userNotified ? removeNotifyId : addNotifyId
    toggleFunction(user.id)
  }

  const toggleNotifyOnCompleteId = () => {
    const toggleFunction = userNotifiedOnComplete ? removeNotifyOnCompleteId : addNotifyOnCompleteId
    toggleFunction(user.id)
  }

  return (
    <ItemRow
      className="dark"
      itemRowHeaderContent={(
        <>
          <div className="grow flex gap-3 items-center mr-8 lg:mr-0 mb-4 lg:mb-0">
            <ProfileIcon user={user} className="w-8 h-8" />
            <span className="grow mr-3">{user.fullName}</span>
          </div>
          <div className="lg:flex lg:items-center item-row-header-content-breaker">
            <div className="flex items-center justify-between lg:mr-11 mb-4 lg:mb-0">
              <span className="text-sm block lg:hidden">Notify on Assign</span>
              <Switch
                checked={userNotified}
                onChange={toggleNotifyId}
              />
            </div>
            <div className="flex items-center justify-between lg:mr-4">
              <span className="text-sm block lg:hidden">Notify on Complete</span>
              <Switch
                checked={userNotifiedOnComplete}
                onChange={toggleNotifyOnCompleteId}
              />
            </div>
          </div>
        </>
      )}
      itemRowHeaderControls={(
        <CircularIconButton icon="close" onClick={removeFromTask} />
      )}
    />
  )
}

TaskUser.propTypes = {
  removeFromTask: types.func.isRequired,
  /* eslint-disable-next-line react/forbid-prop-types */
  user: types.object.isRequired,
}

export default TaskUser
