import React from "react"
import clsx from "clsx"
import Button from "./Button"
import buttonPropTypes from "./buttonPropTypes"

const SecondaryButton = ({ className, isDark = false, ...rest }) => (
  <Button
    className={clsx(className, "secondary-button", isDark && "dark")}
    {...rest}
  />
)

SecondaryButton.propTypes = buttonPropTypes

export default SecondaryButton
