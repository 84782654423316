import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { formSubmissionData } from "reduxSlices/formSubmissionSlice"
import { CustomIconButton } from "shared/buttons"
import DetailBannerInfo from "./DetailBannerInfo"
import FormSubmissionForm from "./FormSubmissionForm"
import FormSubmissionCreatedDate from "./FormSubmissionCreatedDate"
import FormSubmissionFlagging from "./FormSubmissionFlagging"
import FormSubmissionStatus from "./FormSubmissionStatus"
import FormSubmissionExport from "./FormSubmissionExport"
import SurveyBatchSendDate from "./SurveyBatchSendDate"

const DetailsBanner = ({
  canDeleteSubmission,
  deleteFormSubmission,
  canExportSubmission,
  exportFormSubmissionData,
}) => {
  const {
    slug, createdAt, important, batchSentAt,
  } = useSelector(formSubmissionData)

  if (!slug || !createdAt) return null

  return (
    <div
      className="w-full z-30 bg-light-100 lg:sticky"
      style={{ boxShadow: "inset 0px -1px 0px #D9DCF2" }}
    >
      <div className="flex flex-col xl:flex-row">
        <div className="shrink-0 flex flex-center justify-center p-4 pb-0 lg:pb-2 xl:p-6 text-2xl font-semibold text-dark lg:border-r border-lightgray-3">
          {`ID - ${slug}`}
        </div>
        <div className="flex gap-6 flex-col lg:justify-between lg:flex-row grow items-start p-4">
          <div className="grow flex gap-6 justify-start flex-col md:flex-row">
            <DetailBannerInfo name="Form">
              <FormSubmissionForm />
            </DetailBannerInfo>
            <div className="flex flex-row gap-12 md:gap-6">
              <DetailBannerInfo name="Submission Date">
                <FormSubmissionCreatedDate createdAt={createdAt} />
              </DetailBannerInfo>
              {
                batchSentAt && (
                  <DetailBannerInfo name="Survey Date">
                    <SurveyBatchSendDate sentAt={batchSentAt} />
                  </DetailBannerInfo>
                )
              }
            </div>
            {
              important && (
                <DetailBannerInfo name="Flagged" hideName>
                  <FormSubmissionFlagging />
                </DetailBannerInfo>
              )
            }
          </div>
          <div className="flex gap-4 items-end flex-row-reverse lg:flex-row w-full lg:w-auto">
            {
              canDeleteSubmission && (
                <DetailBannerInfo name="" hideName>
                  <CustomIconButton
                    text="Delete"
                    className="border border-light text-sm font-semibold"
                    icon="trash-can"
                    onClick={deleteFormSubmission}
                  />
                </DetailBannerInfo>
              )
            }
            {
              canExportSubmission && (
                <DetailBannerInfo name="" hideName className="hidden lg:block">
                  <FormSubmissionExport exportFormSubmissionData={exportFormSubmissionData} />
                </DetailBannerInfo>
              )
            }
            <DetailBannerInfo name="Status" className="w-full xl:w-auto">
              <FormSubmissionStatus />
            </DetailBannerInfo>
          </div>
        </div>
      </div>
    </div>
  )
}

DetailsBanner.propTypes = {
  canDeleteSubmission: types.bool.isRequired,
  canExportSubmission: types.bool.isRequired,
  deleteFormSubmission: types.func.isRequired,
  exportFormSubmissionData: types.func.isRequired,
}

export default DetailsBanner
