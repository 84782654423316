import React from "react"
import types from "prop-types"
import { TaskGeneralIcon, TaskSubformIcon, CheckmarkCircleIcon } from "shared/icons"

const TaskIcon = ({ className = "", isSubform, isOpen }) => (
  <div className="relative">
    {
      isSubform
        ? <TaskSubformIcon className={className} />
        : <TaskGeneralIcon className={className} />
    }
    {
      !isOpen && <CheckmarkCircleIcon className="absolute h-3 w-3 -right-0.5 -bottom-0.5" />
    }
  </div>
)

TaskIcon.propTypes = {
  className: types.string,
  isSubform: types.bool.isRequired,
  isOpen: types.bool.isRequired,
}

export default TaskIcon
