import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { useSurveyScheduledReportExportContext } from "contexts/SurveyScheduledReportExportContext"
import MassSelection from "shared/MassSelection"
import LocationAnswerFiltersLocationDetailsSelectedFacilityDepartmentCheckbox from "./LocationAnswerFiltersLocationDetailsSelectedFacilityDepartmentCheckbox"

const LocationAnswerFiltersLocationDetailsSelectedFacilityDepartments = ({ className = "", selectedFacilityId }) => {
  const {
    addAllFacilityDepartmentsToLocationAnswerFilters,
    facilities,
    facilityDepartments,
    removeAllFacilityDepartmentsFromLocationAnswerFilters,
  } = useSurveyScheduledReportExportContext()

  const selectedFacility = facilities.find((facility) => facility.id === selectedFacilityId)

  const departments = facilityDepartments(selectedFacilityId)

  return (
    <div className={clsx("border border-lightgray-3 rounded p-4 mb-4", className)}>
      <p className="mb-4">{selectedFacility?.name}</p>
      <MassSelection
        className="mb-4"
        deselectAll={() => removeAllFacilityDepartmentsFromLocationAnswerFilters(selectedFacilityId)}
        selectAll={() => addAllFacilityDepartmentsToLocationAnswerFilters(selectedFacilityId)}
      />
      {
        departments.map((department) => (
          <LocationAnswerFiltersLocationDetailsSelectedFacilityDepartmentCheckbox
            key={department.id}
            department={department}
          />
        ))
      }
    </div>
  )
}

LocationAnswerFiltersLocationDetailsSelectedFacilityDepartments.propTypes = {
  className: types.string,
  selectedFacilityId: types.number.isRequired,
}

export default LocationAnswerFiltersLocationDetailsSelectedFacilityDepartments
