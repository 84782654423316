import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { useHistoricalAccessToolContext } from "contexts/HistoricalAccessToolContext"

const HistoricalAccessToolPreviewList = ({ className = "", formSubmissionCounts }) => {
  const { formsForSubmitting } = useHistoricalAccessToolContext()

  return (
    <>
      <div className="flex justify-between items-end px-4 mb-2 tracking-tight font-medium text-sm text-dark">
        <span>Form</span>
        <span>Submission Count</span>
      </div>
      <div className={clsx("rounded-lg border border-light overflow-hidden", className)}>
        <ul className="max-h-1/2-screen overflow-auto p-0 m-0">
          {
            formsForSubmitting.map((form) => {
              const countData = formSubmissionCounts.find(({ formId }) => form.id === formId)

              if (!countData) return null

              const { id: formId, title: formTitle } = form
              const { count } = countData

              return (
                <li key={formId} className="px-4 pb-2 first:pt-2 border-b last:border-b-0 border-light mb-2 last:mb-0 flex justify-between items-center gap-4">
                  <span className="min-w-0">{formTitle}</span>
                  <span>{count}</span>
                </li>

              )
            })
          }
        </ul>
      </div>
    </>
  )
}

HistoricalAccessToolPreviewList.propTypes = {
  className: types.string,
  formSubmissionCounts: types.arrayOf(types.shape({
    count: types.number.isRequired,
    formId: types.number.isRequired,
  })).isRequired,
}

export default HistoricalAccessToolPreviewList
