import React from "react"
import types from "prop-types"

const DataCollectorStepConnector = ({ stepNumber }) => {
  if (stepNumber < 2) return null

  return (
    <div className="border-r border-lightgray-3 h-9 mx-auto w-0" />
  )
}

DataCollectorStepConnector.propTypes = {
  stepNumber: types.number.isRequired,
}

export default DataCollectorStepConnector
