import React, { useEffect, useState } from "react"
import types from "prop-types"
import { isEmpty, sortBy } from "lodash-es"
import * as API from "services/api"
import { reportExportFormSubmissionReportShape } from "utils/propTypeShapes"
import { errorToast } from "components/shared/toast"
import { CSV_EXPORT_TYPE } from "utils/reportExportHelpers"
import FormSubmissionReportExportContext from "../FormSubmissionReportExportContext"

const LOAD_QUESTIONS_ERROR_MESSAGE = "Something went wrong.  Unable to load form questions."
const LOAD_FACILITIES_ERROR_MESSAGE = "Something went wrong.  Unable to load facilities."
const LOAD_DEPARTMENTS_ERROR_MESSAGE = "Something went wrong.  Unable to load departments."

const DATE_ANSWER_FILTER_TEMPLATE = {
  questionUuid: null,
  earliestDate: null,
  latestDate: null,
}

const LOCATION_ANSWER_FILTER_TEMPLATE = {
  questionUuid: null,
  departmentIds: [],
}

const FormSubmissionReportExportContextProvider = ({ children, formSubmissionReport: propFormSubmissionReport }) => {
  const [formSubmissionReport] = useState(propFormSubmissionReport)
  const { formSlug } = formSubmissionReport

  const [reportExport, setReportExport] = useState({})

  // For filtering by submission attributes
  const [earliestSubmissionDate, setEarliestSubmissionDate] = useState("")
  const [latestSubmissionDate, setLatestSubmissionDate] = useState("")

  // For filtering by answers
  const [questions, setQuestions] = useState([])

  const [reportExportFileType, setReportExportFileType] = useState(CSV_EXPORT_TYPE)

  useEffect(() => {
    const loadQuestions = async () => {
      const response = await API.formQuestionHistory({ formSlug })

      if (response.ok) {
        setQuestions(response.data)
      } else {
        errorToast(LOAD_QUESTIONS_ERROR_MESSAGE)
      }
    }

    loadQuestions()
  }, [formSlug])

  // ... date answers
  const [dateAnswerFilters, setDateAnswerFilters] = useState([])

  const setDateAnswerFilterQuestionUuid = (questionUuid) => {
    let newDateFilters = []

    if (questionUuid) {
      const currentDateFilter = dateAnswerFilters[0] ?? { ...DATE_ANSWER_FILTER_TEMPLATE }
      newDateFilters = [{ ...currentDateFilter, questionUuid }]
    }

    setDateAnswerFilters(newDateFilters)
  }

  const setDateAnswerFilterEarliestDate = (date) => {
    if (isEmpty(date)) date = null

    const currentDateFilter = dateAnswerFilters[0]

    if (!currentDateFilter) return

    setDateAnswerFilters([{ ...currentDateFilter, earliestDate: date }])
  }

  const setDateAnswerFilterLatestDate = (date) => {
    if (isEmpty(date)) date = null

    const currentDateFilter = dateAnswerFilters[0]

    if (!currentDateFilter) return

    setDateAnswerFilters([{ ...currentDateFilter, latestDate: date }])
  }

  // ... location answers
  const [facilities, setFacilities] = useState([])
  const [departments, setDepartments] = useState({})

  useEffect(() => {
    const loadFacilities = async () => {
      const response = await API.getFacilities()

      if (response.ok) {
        setFacilities(sortBy(response.data, "name"))
      } else {
        errorToast(LOAD_FACILITIES_ERROR_MESSAGE)
      }
    }

    loadFacilities()
  }, [])

  useEffect(() => {
    const loadDepartments = async () => {
      const response = await API.getDepartments()

      if (response.ok) {
        const departmentsByFacility = sortBy(response.data, "name").reduce((aggregator, department) => {
          if (aggregator[department.facilityId]) {
            aggregator[department.facilityId].push(department)
          } else {
            aggregator[department.facilityId] = [department]
          }

          return aggregator
        }, {})

        setDepartments(departmentsByFacility)
      } else {
        errorToast(LOAD_DEPARTMENTS_ERROR_MESSAGE)
      }
    }

    loadDepartments()
  }, [])

  const [locationAnswerFilters, setLocationAnswerFilters] = useState([])

  const facilityDepartments = (facilityId) => departments[facilityId] ?? []

  const setLocationAnswerFilterQuestionUuid = (questionUuid) => {
    let newLocationFilters = []

    if (questionUuid) {
      const currentLocationFilter = locationAnswerFilters[0] ?? { ...LOCATION_ANSWER_FILTER_TEMPLATE }
      newLocationFilters = [{ ...currentLocationFilter, questionUuid }]
    }

    setLocationAnswerFilters(newLocationFilters)
  }

  const updateLocationAnswerFiltersDepartmentIds = (departmentIds) => {
    const currentLocationFilter = locationAnswerFilters[0]

    setLocationAnswerFilters(
      [
        {
          ...currentLocationFilter,
          departmentIds,
        },
      ],
    )
  }

  const addDepartmentIdsToLocationAnswerFilters = (departmentIds) => {
    const currentLocationFilter = locationAnswerFilters[0]
    const updatedDepartmentIds = Array.from(new Set([...currentLocationFilter.departmentIds, ...departmentIds]))
    updateLocationAnswerFiltersDepartmentIds(updatedDepartmentIds)
  }

  const addAllFacilityDepartmentsToLocationAnswerFilters = (facilityId) => {
    addDepartmentIdsToLocationAnswerFilters(
      facilityDepartments(facilityId).map((department) => department.id),
    )
  }

  const removeDepartmentIdsFromLocationAnswerFilters = (departmentIds) => {
    const currentLocationFilter = locationAnswerFilters[0]
    const updatedDepartmentIds = currentLocationFilter.departmentIds.filter((id) => !departmentIds.includes(id))
    updateLocationAnswerFiltersDepartmentIds(updatedDepartmentIds)
  }

  const removeAllFacilityDepartmentsFromLocationAnswerFilters = (facilityId) => {
    removeDepartmentIdsFromLocationAnswerFilters(
      facilityDepartments(facilityId).map((department) => department.id),
    )
  }

  // Object exposed to context consumers
  const contextConsumerValue = {
    addAllFacilityDepartmentsToLocationAnswerFilters,
    addDepartmentIdsToLocationAnswerFilters,
    dateAnswerFilters,
    departments,
    earliestSubmissionDate,
    facilities,
    facilityDepartments,
    formSubmissionReport,
    latestSubmissionDate,
    locationAnswerFilters,
    questions,
    removeAllFacilityDepartmentsFromLocationAnswerFilters,
    removeDepartmentIdsFromLocationAnswerFilters,
    reportExport,
    reportExportFileType,
    setDateAnswerFilterEarliestDate,
    setDateAnswerFilterLatestDate,
    setDateAnswerFilterQuestionUuid,
    setEarliestSubmissionDate,
    setLatestSubmissionDate,
    setLocationAnswerFilterQuestionUuid,
    setReportExport,
    setReportExportFileType,
  }

  return (
    <FormSubmissionReportExportContext.Provider value={contextConsumerValue}>
      {children}
    </FormSubmissionReportExportContext.Provider>
  )
}

FormSubmissionReportExportContextProvider.propTypes = {
  children: types.node.isRequired,
  formSubmissionReport: reportExportFormSubmissionReportShape.isRequired,
}

export default FormSubmissionReportExportContextProvider
