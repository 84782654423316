import React from "react"
import types from "prop-types"
import { MULTI_ENTRIES_TYPE } from "Forms/FormElementTypes"
import { taskShape, questionShape } from "utils/propTypeShapes"
import StrikeAround from "shared/StrikeAround"
import SubformAnswerEntryCard from "./SubformAnswerEntryCard"

const SubformQuestionMultiEntryAnswers = ({ task, question, multiEntryAnswers }) => {
  if (question.type !== MULTI_ENTRIES_TYPE) return null

  const entries = multiEntryAnswers[question.uuid]

  if (!entries) return null

  const sortedEntries = [...entries]

  sortedEntries.sort((entry1, entry2) => {
    if (new Date(entry1.created_at) > new Date(entry2.created_at)) return -1
    if (new Date(entry1.created_at) < new Date(entry2.created_at)) return 1
    return 0
  })

  return (
    <>
      <StrikeAround className="my-6">
        <div className="text-dark flex items-center">
          <span className="font-semibold mr-2">Answers</span>
          <span className="rounded-full h-8 w-8 inline-flex items-center justify-center bg-light opacity-50">
            <span className="text-xl font-medium">
              { entries.length }
            </span>
          </span>
        </div>
      </StrikeAround>
      {
        sortedEntries.map((entry) => (
          <SubformAnswerEntryCard
            key={`${entry.user_id}-${entry.answer}-${entry.created_at}`}
            task={task}
            question={question}
            entry={entry}
          />
        ))
      }
    </>
  )
}

SubformQuestionMultiEntryAnswers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  multiEntryAnswers: types.object.isRequired,
  task: taskShape.isRequired,
  question: questionShape.isRequired,
}

export default SubformQuestionMultiEntryAnswers
