export const RIGHT = "right"
export const LEFT = "left"
export const CURSORS = [
  "auto",
  "default",
  "pointer",
  "wait",
  "text",
  "move",
  "help",
  "not-allowed",
]
export const TYPES = [
  "button",
  "submit",
  "reset",
]
