import React from "react"
import types from "prop-types"

const DataCollectorStepHeader = ({ heading = "" }) => {
  if (!heading) return null

  return (
    <div
      className="font-medium text-lg py-1 flex items-center justify-center border-b border-lightgray-3"
      style={{ background: "#F1F1FA" }}
    >
      {heading}
    </div>
  )
}

DataCollectorStepHeader.propTypes = {
  heading: types.string,
}

export default DataCollectorStepHeader
