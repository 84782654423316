import React from "react"
import { useSelector } from "react-redux"
import { getSurveyBatches } from "reduxSlices/surveyBatchTableSlice"
import types from "prop-types"
import clsx from "clsx"
import { LargeWrap } from "shared/wraps"
import BatchesHeader from "./BatchesHeader"
import BatchesList from "./BatchesList"

const BatchesContent = ({ hiddenSidebar, toggleSidebar }) => {
  const surveyBatches = useSelector(getSurveyBatches)

  return (
    <LargeWrap className={clsx(!hiddenSidebar && "pl-80")}>
      <BatchesHeader hiddenSidebar={hiddenSidebar} toggleSidebar={toggleSidebar} />
      <BatchesList batches={surveyBatches} />
    </LargeWrap>
  )
}

BatchesContent.propTypes = {
  hiddenSidebar: types.bool.isRequired,
  toggleSidebar: types.func.isRequired,
}

export default BatchesContent
