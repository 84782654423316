import React, { Component } from "react"
import types from "prop-types"
import { uniqueConditionalOperatorsOnSection } from "utils/QuestionBranching"
import { sectionShape } from "utils/propTypeShapes"
import BaseSectionConditionalCheck from "./ConditionalCheck/baseSectionConditionalCheck"
import ConditionalCheck from "../QuestionBranchingConditionalCheck/ConditionalCheck"

class SectionBranchingConditionalCheck extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checkComponent: ConditionalCheck({
        operators: uniqueConditionalOperatorsOnSection(props),
        baseCheckComponent: BaseSectionConditionalCheck,
      }),
    }
  }

  render() {
    const { checkComponent: CheckComponent } = this.state
    const {
      children, isFirstSection, formAnswers, section,
    } = this.props

    return (
      <CheckComponent
        formAnswers={formAnswers}
        isFirstSection={isFirstSection}
        operatorData={{}}
        section={section}
      >
        { children }
      </CheckComponent>
    )
  }
}

SectionBranchingConditionalCheck.propTypes = {
  children: types.node.isRequired,
  isFirstSection: types.bool.isRequired,
  /* eslint-disable-next-line react/forbid-prop-types */
  formAnswers: types.object.isRequired,
  section: sectionShape.isRequired,
}

export default SectionBranchingConditionalCheck
