/* eslint-disable camelcase */
import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"

const TaskUserCreatedAuditItemDetails = ({ audit }) => {
  const {
    auditedChanges,
    task,
    taskAssignee,
    taskMember,
  } = audit

  const { relationship_type } = auditedChanges

  if (relationship_type === "assignee") {
    return (
      <div>
        <span>
          <AuditedUser audit={audit} /> assigned&nbsp;
          <span className="font-semibold">{taskAssignee.fullName}</span> to
          the task titled <span className="font-semibold">{task.title}</span>.
        </span>
      </div>
    )
  }

  if (relationship_type === "member") {
    return (
      <div>
        <span>
          <AuditedUser audit={audit} /> added&nbsp;
          <span className="font-semibold">{taskMember.fullName}</span> to
          the task titled <span className="font-semibold">{task.title}</span> as a member.
        </span>
      </div>
    )
  }

  return (
    <div>
      <span>
        <AuditedUser audit={audit} /> added a user to
        the task titled <span className="font-semibold">{task.title}</span>.
      </span>
    </div>
  )
}

TaskUserCreatedAuditItemDetails.propTypes = {
  audit: auditShape.isRequired,
}

export default TaskUserCreatedAuditItemDetails
