import React, { useMemo } from "react"
import types from "prop-types"
import {
  cloneDeep, difference, isEmpty, isEqual, sortBy,
} from "lodash-es"
import { useSelector } from "react-redux"
import {
  getActiveOrMatchingFacilities,
  getDepartmentFacilityMapping,
  getFacilityDepartments,
} from "reduxSlices/formBuilderSlice"
import InactiveOptionsItemRenderer from "shared/selects/InactiveOptionsItemRenderer"
import { Checkbox } from "shared/checkboxes"
import { MultiSelect } from "shared/multiSelects"

const DEFAULT_VALUE = {
  departmentIds: [],
  facilityIds: [],
}

const itemsToOptions = (list) => list.map((item) => (
  { label: item.name, value: item.id, active: item.active }
))

const LocationIsAnyOfDepartmentsMatchValueInput = ({ onChange, value = cloneDeep(DEFAULT_VALUE) }) => {
  // Department lists organized by facility id
  const facilityDepartments = useSelector(getFacilityDepartments, isEqual)

  // Map of department ids to their facility ids
  const departmentFacilityMapping = useSelector(getDepartmentFacilityMapping, isEqual)

  // These are the facilities that may be selected
  const selectableFacilities = useSelector(getActiveOrMatchingFacilities(departmentFacilityMapping[value]), isEqual)
  const sortedSelectableFacilities = useMemo(() => sortBy(selectableFacilities, "name"), [selectableFacilities])
  const facilityOptions = useMemo(() => itemsToOptions(sortedSelectableFacilities), [sortedSelectableFacilities])

  // Value should be an object with two properties:  departmentIds and facilityIds.
  // Ensure that a null or undefined value is converted.
  if (!value || isEmpty(value)) value = cloneDeep(DEFAULT_VALUE)

  // Assign the currently selected facility ids and department ids to variables.
  const { departmentIds: currentDepartmentIds, facilityIds: currentFacilityIds } = value
  const selectedFacilities = sortedSelectableFacilities.filter((facility) => currentFacilityIds.includes(facility.id))

  // When facilities are updated, check to see if there's actually a change in the ids.
  // If there's not, then ignore.  If there's a change where a facility is added,
  // do not add the facility's departments.  If there's a change where a facility is removed,
  // remove the facility's departments.
  const updateAfterFacilityChange = (updatedFacilityIds) => {
    if (isEqual(sortBy(currentFacilityIds), sortBy(updatedFacilityIds))) return

    let updatedDepartmentIds = cloneDeep(currentDepartmentIds)

    const removedFacilityIds = difference(currentFacilityIds, updatedFacilityIds)

    if (!isEmpty(removedFacilityIds)) {
      const departmentIdsToRemove = removedFacilityIds.flatMap((removedFacilityId) => (
        facilityDepartments[removedFacilityId].map((department) => department.id)
      ))

      updatedDepartmentIds = difference(updatedDepartmentIds, departmentIdsToRemove)
    }

    onChange({ departmentIds: updatedDepartmentIds, facilityIds: updatedFacilityIds })
  }

  // Functions for managing departments
  const departmentSelected = (departmentId) => currentDepartmentIds.includes(departmentId)

  const deselectDepartment = (departmentId) => {
    const updatedValue = cloneDeep(value)
    updatedValue.departmentIds = difference(currentDepartmentIds, [departmentId])

    onChange(updatedValue)
  }

  const selectDepartment = (departmentId) => {
    const updatedValue = cloneDeep(value)
    updatedValue.departmentIds.push(departmentId)

    onChange(updatedValue)
  }

  return (
    <div>
      <MultiSelect
        backspaceDelete={false}
        className="mb-2"
        isFullWidth
        itemRenderer={InactiveOptionsItemRenderer}
        onChange={updateAfterFacilityChange}
        options={facilityOptions}
        required
        values={currentFacilityIds}
      />
      {
        selectedFacilities.map((facility) => {
          const { id: facilityId, name: facilityName } = facility

          const departmentOptions = facilityDepartments[facilityId]

          return (
            <div key={facilityId} className="flex flex-col gap-2 mb-4 last:mb-0">
              <span className="text-xs font-semibold">{facilityName}</span>
              {
                departmentOptions.map((departmentOption) => {
                  const { id: departmentId, name: departmentName } = departmentOption

                  const departmentOptionIsSelected = departmentSelected(departmentId)

                  const toggleDepartment = () => {
                    const toggle = departmentOptionIsSelected ? deselectDepartment : selectDepartment
                    toggle(departmentId)
                  }

                  return (
                    <label key={`${facilityId}-${departmentId}`} className="flex gap-2 max-w-max cursor-pointer">
                      <Checkbox
                        onChange={toggleDepartment}
                        value={departmentOptionIsSelected}
                      />
                      <span>{departmentName}</span>
                    </label>
                  )
                })
              }
            </div>
          )
        })
      }
    </div>
  )
}

LocationIsAnyOfDepartmentsMatchValueInput.propTypes = {
  onChange: types.func.isRequired,
  value: types.shape({
    departmentIds: types.arrayOf(types.number),
    facilityIds: types.arrayOf(types.number),
  }),
}

export default LocationIsAnyOfDepartmentsMatchValueInput
