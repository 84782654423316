import React from "react"
import types from "prop-types"
import { adminCategoryUrl } from "utils/routeHelpers"
import CategoryReset from "./CategoryReset"

const Category = ({
  category,
  subdomain,
  onResetCategory,
}) => (
  <div className="flex">
    <a className="mr-4" href={adminCategoryUrl({ subdomain, category })}>{category.name}</a>
    <CategoryReset
      category={category}
      subdomain={subdomain}
      onResetCategory={onResetCategory}
    />
  </div>
)

Category.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  category: types.object.isRequired,
  onResetCategory: types.func.isRequired,
  subdomain: types.string.isRequired,
}

export default Category
