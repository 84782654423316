import React from "react"
import { useFileUploadContext } from "contexts/FileUploadContext"
import FilePreviewCard from "./FilePreviewCard"

const FilePreviewList = () => {
  const { files } = useFileUploadContext()

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-4 min-w-full">
      {
        files.map((file) => (
          <FilePreviewCard
            key={file.key}
            file={file}
          />
        ))
      }
    </div>
  )
}

export default FilePreviewList
