import React, { useState } from "react"
import types from "prop-types"
import { sortBy } from "lodash-es"
import { organizationAccessShape, userOptionShape } from "utils/propTypeShapes"
import * as API from "services/api"
import withReduxProvider from "shared/withReduxProvider"
import { errorToast, successToast } from "shared/toast"
import PhpAdminOrganizationAccessCheckbox from "./PhpAdminOrganizationAccessCheckbox"

const ManagePhpAdminOrganizationAccess = ({
  organizationAccesses: propsOrganizationAccesses,
  organizationSubdomain,
  phpAdmins,
}) => {
  const [organizationAccesses, setOrganizationAccesses] = useState(propsOrganizationAccesses)

  const userOrganizationAccessId = (userId) => {
    const organizationAccess = organizationAccesses.find((access) => (
      access.userId === userId
    ))

    return organizationAccess?.id
  }

  const removeAccess = (organizationAccessId) => async () => {
    const response = await API.deleteOrganizationAccess({ organizationAccessId, organizationSubdomain })

    if (response.ok) {
      setOrganizationAccesses((previousOrganizationAccesses) => (
        previousOrganizationAccesses.filter((organizationAccess) => (
          organizationAccess.id !== organizationAccessId
        ))
      ))
      successToast("Organization access successfully removed.")
    } else {
      errorToast("Something went wrong.  Unable to remove access.")
    }
  }

  const addAccess = (userId) => async () => {
    const response = await API.createOrganizationAccess({ organizationSubdomain, userId })

    if (response.ok) {
      setOrganizationAccesses((previousOrganizationAccesses) => (
        [
          ...previousOrganizationAccesses,
          response.data,
        ]
      ))
      successToast("Organization access successfully granted.")
    } else {
      errorToast("Something went wrong.  Unable to add access.")
    }
  }

  return (
    <div>
      {
        sortBy(phpAdmins, ["lastName", "firstName"]).map((phpAdmin) => {
          const organizationAccessId = userOrganizationAccessId(phpAdmin.id)
          const hasAccess = Boolean(organizationAccessId)

          return (
            <PhpAdminOrganizationAccessCheckbox
              key={phpAdmin.id}
              hasAccess={hasAccess}
              onChange={hasAccess ? removeAccess(organizationAccessId) : addAccess(phpAdmin.id)}
              phpAdmin={phpAdmin}
            />
          )
        })
      }
    </div>
  )
}

ManagePhpAdminOrganizationAccess.propTypes = {
  organizationAccesses: types.arrayOf(organizationAccessShape).isRequired,
  organizationSubdomain: types.string.isRequired,
  phpAdmins: types.arrayOf(userOptionShape).isRequired,
}

export default withReduxProvider(ManagePhpAdminOrganizationAccess)
