import React from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { useSelector } from "react-redux"
import { getFormQuestionAttributeCsv } from "reduxSlices/formBuilderSlice"

const DETAIL_LABELS = {
  filename: "Uploaded File",
  rows: "Options",
  uploadedBy: "Uploaded By",
  uploadedAt: "Uploaded At",
}

const SelectCSVUploadDetailsPreviousUpload = ({ questionIndex, sectionIndex }) => {
  const questionCsv = useSelector(getFormQuestionAttributeCsv({ sectionIndex, questionIndex }), isEqual)

  if (!questionCsv) return null

  return (
    <>
      {
        Object.keys(DETAIL_LABELS).map((csvDetail) => (
          <React.Fragment key={csvDetail}>
            <div className="pb-3 last:pb-0 mb-3 last:mb-0 flex-center border-b border-light last:border-b-0">
              <div className="whitespace-nowrap mr-6">{DETAIL_LABELS[csvDetail]}</div>
              <div className="text-right">{questionCsv[csvDetail]}</div>
            </div>
          </React.Fragment>
        ))
      }
    </>
  )
}

SelectCSVUploadDetailsPreviousUpload.propTypes = {
  questionIndex: types.number.isRequired,
  sectionIndex: types.number.isRequired,
}

export default SelectCSVUploadDetailsPreviousUpload
