import React from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import {
  formSubmissionSlug as getFormSubmissionSlug,
  setRelatedFormSubmissions,
} from "reduxSlices/formSubmissionSlice"
import * as API from "services/api"
import StrikeAround from "components/shared/StrikeAround"
import FormSubmissionResult from "components/shared/FormSubmissionResult"
import { errorToast } from "components/shared/toast"
import LoadingSearchResults from "./LoadingSearchResults"

const SearchResults = ({
  close,
  hasCompletedSearch,
  results,
  searchInProgress,
}) => {
  const formSubmissionSlug = useSelector(getFormSubmissionSlug)
  const dispatch = useDispatch()

  const createFormSubmissionRelationship = async (result) => {
    const response = await API.createFormSubmissionRelationship({
      formSubmissionSlug, relatedFormSubmissionId: result.id,
    })

    if (response.ok) {
      dispatch(setRelatedFormSubmissions(response.data))
      close()
    } else {
      errorToast(response.data.errors.join(", "))
    }
  }

  return (
    <>
      {
        searchInProgress && (
          <div className="pt-16">
            <LoadingSearchResults />
          </div>
        )
      }
      {
        (hasCompletedSearch && !searchInProgress) && (
          <div className="related-form-submissions-search-results">
            <StrikeAround className="font-medium text-xl py-3 flex items-center text-dark mb-6">
              <div className="rounded-full bg-light h-8 w-8 mr-2.5 flex items-center justify-center">
                {results.length}
              </div>
              Search Results
            </StrikeAround>
            <div>
              {
                results.map((result) => (
                  <FormSubmissionResult
                    key={result.id}
                    onClickResult={createFormSubmissionRelationship}
                    result={result}
                  />
                ))
              }
            </div>
          </div>
        )
      }
    </>
  )
}

SearchResults.propTypes = {
  close: types.func.isRequired,
  searchInProgress: types.bool.isRequired,
  hasCompletedSearch: types.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  results: types.arrayOf(types.object).isRequired,
}

export default SearchResults
