import React from "react"
import types from "prop-types"
import clsx from "clsx"
import TableHeadSortIcon from "./TableHeadSortIcon"

const SortableTableHead = ({ column, currentSort, onSort = null }) => {
  const sortSubmissions = () => onSort?.(column)
  const { width = "inherit" } = column

  return (
    <th
      onClick={sortSubmissions}
      className={clsx(onSort && "cursor-pointer", "text-dark")}
      style={{ width }}
    >
      { column.label }
      <TableHeadSortIcon currentSort={currentSort} column={column} />
    </th>
  )
}

SortableTableHead.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  column: types.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  currentSort: types.object.isRequired,
  onSort: types.func,
}

export default SortableTableHead
