import React from "react"
import types from "prop-types"
import clsx from "clsx"
import TableHeader from "./TableHeader"
import TableRow from "./TableRow"

const Table = ({
  className = "",
  columns,
  noDataText = "Nothing to show",
  rowHrefFunc = undefined,
  rowKey,
  tableData,
  onSort = null,
  onRowClick = null,
  currentSort = {
    column: {},
    isAsc: false,
  },
  entryClassHelper = undefined,
}) => {
  if (!tableData.length) {
    return <div>{ noDataText }</div>
  }

  return (
    <table className={clsx("table", className)}>
      <TableHeader
        columns={columns}
        onSort={onSort}
        currentSort={currentSort}
      />
      <tbody>
        {
          tableData.map((rowData) => (
            <TableRow
              key={rowData[rowKey]}
              rowData={rowData}
              rowHrefFunc={rowHrefFunc}
              columns={columns}
              onRowClick={onRowClick}
              entryClassHelper={entryClassHelper}
            />
          ))
        }
      </tbody>
    </table>
  )
}

Table.propTypes = {
  className: types.string,
  rowKey: types.string.isRequired,
  noDataText: types.string,
  rowHrefFunc: types.func,
  onSort: types.func,
  onRowClick: types.func,
  // eslint-disable-next-line react/forbid-prop-types
  currentSort: types.object,
  columns: types.arrayOf(types.object).isRequired,
  tableData: types.arrayOf(types.object).isRequired,
  entryClassHelper: types.object,
}

export default Table
