import React from "react"
import types from "prop-types"
import { taskShape } from "utils/propTypeShapes"
import TaskPanelSection from "./TaskPanelSection"
import TaskUserProfileIconList from "./TaskUserProfileIconList"

const TaskPanelMembers = ({ className, task }) => {
  const { memberIds } = task

  return (
    <TaskPanelSection className={className} name="Members">
      {
        memberIds.length > 0
          ? <TaskUserProfileIconList userIds={memberIds} />
          : <span>No one is a member of this task.</span>
      }
    </TaskPanelSection>
  )
}

TaskPanelMembers.defaultProps = {
  className: "",
}

TaskPanelMembers.propTypes = {
  className: types.string,
  task: taskShape.isRequired,
}

export default TaskPanelMembers
