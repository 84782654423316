import React from "react"
import { useHistoricalAccessToolContext } from "contexts/HistoricalAccessToolContext"
import { ADD_HISTORICAL_ACCESS, REMOVE_HISTORICAL_ACCESS } from "contexts/HistoricalAccessToolContext/HistoricalAccessToolContextProvider"
import HistoricalAccessToolAddSteps from "./HistoricalAccessToolAddSteps"
import HistoricalAccessToolRemoveSteps from "./historicalAccessToolRemoveSteps"

const HistoricalAccessToolDataCollectorActionSteps = () => {
  const { historicalAccessAction } = useHistoricalAccessToolContext()

  if (historicalAccessAction === ADD_HISTORICAL_ACCESS) return <HistoricalAccessToolAddSteps />
  if (historicalAccessAction === REMOVE_HISTORICAL_ACCESS) return <HistoricalAccessToolRemoveSteps />

  return null
}

export default HistoricalAccessToolDataCollectorActionSteps
