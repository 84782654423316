import { useContext } from "react"
import SolutionLevelReportExportContext from "../SolutionLevelReportExportContext"

const useSolutionLevelReportExportContext = () => {
  const providedValue = useContext(SolutionLevelReportExportContext)

  return { ...providedValue }
}

export default useSolutionLevelReportExportContext
