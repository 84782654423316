export const GROUP_BY_RELATIONSHIP_TYPE = "relationshipType"
export const GROUP_BY_CATEGORY = "category"
export const GROUP_BY_IS_OPEN = "isOpen"

const ASSIGNEE_TASKS = "My Open Tasks"
const MEMBER_TASKS = "Tasks I'm a Member Of"
const CLOSED_TASKS = "Closed in the last 7 days"
const UNASSIGNED_TASKS = "Unassigned Tasks"

const relationshipGrouping = (type) => {
  switch (type) {
  case "member":
    return MEMBER_TASKS;
  case "assignee":
    return ASSIGNEE_TASKS;
  default:
    return UNASSIGNED_TASKS;
  }
}

const INITIAL_GROUPING = () => ({
  [ASSIGNEE_TASKS]: [],
  [MEMBER_TASKS]: [],
  [UNASSIGNED_TASKS]: [],
})

const openTasks = (tasks) => tasks.filter(({ isOpen }) => isOpen === true)
const closedTasks = (tasks) => tasks.filter(({ isOpen }) => isOpen === false)

export const taskByCategory = (tasks) => openTasks(tasks)?.reduce((acc, task) => {
  const { category: { name } } = task

  if (!acc[name]) {
    acc[name] = [task]
  } else {
    acc[name].push(task)
  }

  return acc
}, {})

export const groupByRelationshipType = (tasks) => openTasks(tasks)?.reduce((acc, task) => {
  const { relationshipType } = task
  const groupName = relationshipGrouping(relationshipType)
  acc[groupName].push(task)

  return acc
}, INITIAL_GROUPING())

// show tasks grouped by category
export const groupByCategory = (tasks) => (taskByCategory(tasks))

// only want to show closed tasks
export const groupByIsOpen = (tasks) => ({ [CLOSED_TASKS]: closedTasks(tasks) })

export const GROUPABLE_COLUMNS = {
  [GROUP_BY_RELATIONSHIP_TYPE]: {
    label: "Responsibility",
    value: GROUP_BY_RELATIONSHIP_TYPE,
    groupingMethod: groupByRelationshipType,
  },
  [GROUP_BY_CATEGORY]: {
    label: "Solution",
    value: GROUP_BY_CATEGORY,
    groupingMethod: groupByCategory,
  },
}

export const getGroupingMethod = ({ value }) => GROUPABLE_COLUMNS[value].groupingMethod
