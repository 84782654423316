import React from "react"
import { useSelector } from "react-redux"
import { currentAnnouncement as getFeatureAnnouncement } from "reduxSlices/featureAnnouncementSlice"
import ReadReceiptWritingFeatureAnnouncement from "./ReadReceiptWritingFeatureAnnouncement"

const Panel = () => {
  const featureAnnouncement = useSelector(getFeatureAnnouncement)

  return (
    featureAnnouncement
      ? (
        <ReadReceiptWritingFeatureAnnouncement featureAnnouncement={featureAnnouncement} />
      )
      : (
        <div className="feature-announcement-panel">
          <p className="feature-announcement-markdown">There are no feature announcements at this time.</p>
        </div>
      )
  )
}

export default Panel
