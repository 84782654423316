import React from "react"
import types from "prop-types"
import clsx from "clsx"

const CircularIconButton = ({ className = "", icon, onClick }) => (
  <button
    className={clsx(className, "circular-icon-button")}
    onClick={onClick}
  >
    <svg>
      <use xlinkHref={`#${icon}`} />
    </svg>
  </button>
)

CircularIconButton.propTypes = {
  className: types.string,
  icon: types.string.isRequired,
  onClick: types.func.isRequired,
}

export default CircularIconButton
