const buildStatus = () => (
  {
    id: null,
    name: "",
  }
)

export const openStatus = ({ statuses }) => statuses.find((status) => status.isOpenStatus)

export const closedStatus = ({ statuses }) => statuses.find((status) => status.isClosedStatus)

export const reorderableStatuses = ({ statuses }) => statuses.filter((status) => (
  !(status.isOpenStatus || status.isClosedStatus)
))

export const allStatusesExceptClosed = ({ statuses }) => statuses.filter((status) => (
  statuses.indexOf(status) !== statuses.indexOf(closedStatus({ statuses }))
))

export const renameStatus = ({ statuses, statusIndex, newName }) => (
  statuses.map((status, index) => {
    if (statusIndex !== index) return status
    return (
      {
        ...status,
        name: newName,
      }
    )
  })
)

export const discardStatus = ({ statuses, statusIndex }) => statuses.filter((status, index) => statusIndex !== index)

export const addStatus = ({ statuses }) => {
  const newStatuses = [...statuses]
  newStatuses.splice(statuses.length - 1, 0, buildStatus())

  return newStatuses
}

export const reorderStatuses = ({ statuses, fromIndex, toIndex }) => {
  const newStatuses = [...statuses]
  const [statusToMove] = newStatuses.splice(fromIndex, 1)
  newStatuses.splice(toIndex, 0, statusToMove)

  return newStatuses
}

export const statusListOptions = (statuses, canCloseSubmission, allTasksClosed) => {
  if (canCloseSubmission && allTasksClosed) {
    return statuses.map(({ name }) => ({ label: name, value: name }))
  }
  return allStatusesExceptClosed({ statuses }).map(({ name }) => ({ label: name, value: name }))
}

export const closedStatusListOptions = (statuses) => {
  const closedName = closedStatus({ statuses })?.name

  return [closedName, "Reopen"].map((value) => ({ label: value, value }))
}

const MAX_STATUS_NAME_LENGTH = 22
const BLANK_NAME_ERROR_MESSAGE = "Cannot be blank"
const NAME_TOO_LONG_ERROR_MESSAGE = "Must be no more than 22 characters"

const statusNameBlank = (name) => !name.trim()

const statusNameUnderLengthLimit = (name) => name.length <= MAX_STATUS_NAME_LENGTH

export const isStatusNameValid = (name) => (
  !statusNameBlank(name) && statusNameUnderLengthLimit(name)
)

export const statusErrorMessages = (status) => {
  const errorMessages = []

  if (statusNameBlank(status.name)) {
    errorMessages.push(BLANK_NAME_ERROR_MESSAGE)
  }

  if (!statusNameUnderLengthLimit(status.name)) {
    errorMessages.push(NAME_TOO_LONG_ERROR_MESSAGE)
  }

  return errorMessages
}

export const isStatusValid = (status) => (
  isStatusNameValid(status.name)
)

export const allStatusesValid = (statuses) => (
  statuses.every(isStatusValid)
)
