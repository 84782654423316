import React from "react"
import { isEqual } from "lodash-es"
import { useSelector } from "react-redux"
import { selectedTaskForms } from "reduxSlices/reportBuilderSlice"
import TaskFormQuestionColumnOptions from "./TaskFormQuestionColumnOptions"
import TaskFormQuestionColumnSelections from "./TaskFormQuestionColumnSelections"
import SelectorSection from "./SelectorSection"

const TaskFormQuestionSelector = () => {
  const taskForms = useSelector(selectedTaskForms, isEqual)

  if (taskForms.length === 0) return null

  return (
    <div className="space-y-8">
      <div className="space-y-2">
        <p>Which task form questions would you like to include?</p>
        <p className="text-xs text-dark">Your selections can be custom ordered within their task form by dragging and dropping</p>
      </div>
      {
        taskForms.map((taskForm) => (
          <div key={taskForm.id}>
            <SelectorSection
              optionsComponent={<TaskFormQuestionColumnOptions taskForm={taskForm} />}
              selectionsComponent={<TaskFormQuestionColumnSelections taskForm={taskForm} />}
            />
          </div>
        ))
      }
    </div>
  )
}

export default TaskFormQuestionSelector
