import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getFacilityAndDepartmentFromDepartmentId } from "reduxSlices/facilitiesSlice"

const formattedAnswer = (facility, department) => (
  `Facility: ${facility.name}, Department: ${department.name}`
)

const LocationDataElement = ({ value = {} }) => {
  const facilityAndDepartment = useSelector(getFacilityAndDepartmentFromDepartmentId(value?.department_id))

  if (!value?.department_id) return (<span />)

  return (
    <span>
      { formattedAnswer(...facilityAndDepartment) }
    </span>
  )
}

LocationDataElement.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: types.object,
}

export default LocationDataElement
