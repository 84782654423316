import React, { useEffect } from "react"
import { useSelector } from "react-redux";
import { activeDepartments as getDepartments } from "reduxSlices/formSubmissionSlice"
import { useTaskFormContext } from "contexts/TaskFormContext"
/* eslint-disable max-len */
import TaskDepartmentSelector from "components/views/FormSubmission/FormSubmissionTabs/ActivitiesContent/TaskForms/TaskFields/UI/TaskDepartmentSelector"
/* eslint-enable max-len */

const TaskAssigneeDepartmentSelector = () => {
  const departments = useSelector(getDepartments)

  const {
    addAssigneeDepartmentByDepartmentId,
    taskAssigneeDepartments,
    taskMemberDepartments,
  } = useTaskFormContext()

  useEffect(() => {
    window.dispatchEvent(new window.Event("resize"))
  }, [taskAssigneeDepartments])

  const assigneeDepartmentIds = new Set(taskAssigneeDepartments?.map((dept) => dept.departmentId))
  const memberDepartmentIds = new Set(taskMemberDepartments?.map((dept) => dept.departmentId))

  const selectableDepartments = departments.reduce((departmentList, department) => {
    if (!assigneeDepartmentIds.has(department.id)) {
      const marker = []
      if (memberDepartmentIds.has(department.id)) {
        marker.push("Member")
      }

      if (!(department.active ?? true)) {
        marker.push("Inactive")
      }

      departmentList.push(
        {
          ...department,
          marker,
        },
      )
    }
    return departmentList
  }, [])

  if (selectableDepartments.length === 0) return null

  return (
    <TaskDepartmentSelector
      departments={selectableDepartments}
      onChange={addAssigneeDepartmentByDepartmentId}
    />
  )
}

export default TaskAssigneeDepartmentSelector
