import React from "react"
import displayName from "utils/displayName"
import { Provider } from "react-redux"
import { store } from "store"

export default function withReduxProviderHOC(WrappedComponent) {
  function withReduxProvider(props) {
    return (
      <Provider store={store}>
        <WrappedComponent {...props} />
      </Provider>
    )
  }

  withReduxProvider.displayName = `withReduxProvider(${displayName(WrappedComponent)})`

  return withReduxProvider
}
