import React from "react"
import FacilityGroupCodeList from "./FacilityGroupCodeList"

export const FACILITY_GROUP_CODES_TAB_HEADING = "Facility Group Codes"

const FacilityGroupCodesTab = () => (
  <>
    <h2 className="tab-title">{ FACILITY_GROUP_CODES_TAB_HEADING }</h2>
    <FacilityGroupCodeList />
  </>
)

export default FacilityGroupCodesTab
