import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const WorkflowActionAddMembersIcon = ({ className = "w-8 h-8" }) => (
  <SvgSpriteIcon iconName="workflow-actions-add-members" className={className} />
)

WorkflowActionAddMembersIcon.propTypes = {
  className: types.string,
}

export default WorkflowActionAddMembersIcon
