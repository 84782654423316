import React, { useEffect } from "react"
import { withFormsy } from "formsy-react"
import { ListSelect, InactiveOptionsItemRenderer } from "shared/selects"

const FormsyDepartmentSelect = withFormsy(({
  setValue,
  isFormSubmitted,
  isPristine,
  setShowErrors,
  value,
  ...rest
}) => {
  useEffect(() => {
    setShowErrors(isFormSubmitted || !isPristine)
  }, [isFormSubmitted, isPristine, setShowErrors])

  return (
    <ListSelect
      isFormSubmitted={isFormSubmitted}
      isPristine={isPristine}
      onChange={(newValue) => setValue(newValue ? { department_id: newValue } : {})}
      value={value?.department_id}
      itemRenderer={InactiveOptionsItemRenderer}
      {...rest}
    />
  )
})

FormsyDepartmentSelect.displayName = "FormsyDepartmentSelect"

export default FormsyDepartmentSelect
