import React from "react"
import types from "prop-types"
import clsx from "clsx"

const MediumWrap = ({ className = "", children = null }) => (
  <div
    className={clsx(
      "medium-wrap",
      className,
    )}
  >
    {children}
  </div>
)

MediumWrap.propTypes = {
  children: types.node,
  className: types.string,
}

export default MediumWrap
