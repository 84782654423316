import React from "react"
import types from "prop-types"
import * as LocationAttributes from "./LocationAttributes"
import QuestionRequired from "./QuestionRequired"
import QuestionShowDescription from "./QuestionShowDescription"

const SelectInputConfig = ({ sectionIndex, questionIndex }) => (
  <>
    <QuestionRequired
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <QuestionShowDescription
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <LocationAttributes.LocationDisplayAs
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <LocationAttributes.LocationColumnCount
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <LocationAttributes.DefaultFacility
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
  </>
)

SelectInputConfig.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default SelectInputConfig
