import React from "react"
import { TextInput } from "shared/inputs"
import { useDispatch, useSelector } from "react-redux"
import { getFormDescription, setFormDescription } from "reduxSlices/formBuilderSlice"

const FormDescription = () => {
  const dispatch = useDispatch()
  const value = useSelector(getFormDescription)

  const updateDescription = (newValue) => dispatch(setFormDescription(newValue))

  return (
    <TextInput
      className="!text-2xl font-medium mb-3"
      value={value}
      onChange={updateDescription}
      placeholder="Form Description (optional)"
    />
  )
}

export default FormDescription
