import React from "react"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const EmptyFilteredUsers = () => (
  <div className="flex flex-col items-center pt-4">
    <SvgSpriteIcon iconName="empty-users" className="h-10 w-10 mb-4" />
    <div className="font-medium text-sm">
      There are no matches for your search.
      Please try again.
    </div>
  </div>
)

export default EmptyFilteredUsers
