import React from "react"
import types from "prop-types"
import { dynamicRangeSurveysExample, monthRangeSurveyInclusionDescription } from "utils/scheduledReportExportHelpers"

const MonthsSurveyDateDescription = ({ startDate, surveySentPreviousRangeLength = null }) => {
  const monthCount = surveySentPreviousRangeLength || 1

  const beginningDate = startDate.subtract(monthCount, "M").startOf("month")
  const endingDate = startDate.subtract(1, "M").endOf("month")

  return (
    <div className="text-xs text-dark mt-2">
      <p className="mb-4">
        {monthRangeSurveyInclusionDescription}
      </p>
      <p>
        {dynamicRangeSurveysExample({ startDate, beginningDate, endingDate })}
      </p>
    </div>
  )
}

MonthsSurveyDateDescription.propTypes = {
  startDate: types.object.isRequired,
  surveySentPreviousRangeLength: types.number,
}

export default MonthsSurveyDateDescription
