import React from "react"
import types from "prop-types"
import { addOnFeatureDisplayName } from "utils/addOnFeatureHelpers"
import { Checkbox } from "shared/checkboxes"

const OrganizationAddOnFeatureCheckbox = ({ addOnFeatureName, onChange, subscribed }) => (
  <div className="flex items-center">
    <Checkbox
      className="mr-2 cursor-pointer"
      uuid={`organization-add-on-feature-${addOnFeatureName}`}
      value={subscribed}
      onChange={onChange}
    />
    <label htmlFor={`organization-add-on-feature-${addOnFeatureName}`} className="cursor-pointer">
      {addOnFeatureDisplayName(addOnFeatureName)}
    </label>
  </div>
)

OrganizationAddOnFeatureCheckbox.propTypes = {
  addOnFeatureName: types.string.isRequired,
  onChange: types.func.isRequired,
  subscribed: types.bool.isRequired,
}

export default OrganizationAddOnFeatureCheckbox
