/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react"
import types from "prop-types"
import clsx from "clsx"

const FieldLabel = ({
  boldLabel = false,
  children,
  className = "",
  required = false,
  text,
}) => (
  <label className={clsx(className, "block max-w-max mb-4")}>
    <span className={clsx(boldLabel && "font-semibold", required && "required-prompt")}>
      {text}
    </span>
    {children}
  </label>
)

FieldLabel.propTypes = {
  boldLabel: types.bool,
  children: types.node.isRequired,
  className: types.string,
  required: types.bool,
  text: types.string.isRequired,
}

export default FieldLabel
