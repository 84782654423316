import React from "react"
import types from "prop-types"
import DropdownListWrapper from "./DropdownListWrapper"
import SelectableIconColor from "./SelectableIconColor"

const IconColorList = ({ selectedIconColor, colorOptions, setSelectedIconColor }) => {
  const isColorSelected = (iconColor) => iconColor === selectedIconColor

  return (
    <DropdownListWrapper title="Colors">
      {
        colorOptions.map(({ iconColor }) => (
          <SelectableIconColor
            key={iconColor}
            isSelected={isColorSelected(iconColor)}
            iconColor={iconColor}
            setSelectedIconColor={setSelectedIconColor}
          />
        ))
      }
    </DropdownListWrapper>
  )
}

IconColorList.propTypes = {
  selectedIconColor: types.string.isRequired,
  setSelectedIconColor: types.func.isRequired,
  colorOptions: types.arrayOf(types.object).isRequired,
}

export default IconColorList
