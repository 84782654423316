import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { categoryShape } from "utils/propTypeShapes"
import * as API from "services/api"
import StrikeAround from "shared/StrikeAround"
import { groups as getGroups } from "reduxSlices/categorySlice"
import FormBuilderSearch from "./FormBuilderSearch"
import GroupFormBuilderList from "./GroupFormBuilderList"
import UserFormBuilderList from "./UserFormBuilderList"

const FormBuildersTab = ({ category }) => {
  const categorySlug = category.slug
  const [formBuilderLists, setFormBuilderLists] = useState({
    groups: [],
    users: [],
  })
  const groups = useSelector(getGroups)

  const deleteCategoryAbility = (abilityId) => async () => {
    const response = await API.deleteCategoryAbility({ categorySlug, abilityId })
    if (response.ok) {
      setFormBuilderLists(response.data)
    } else {
      console.error("Error deleting form builder ability", response)
    }
  }

  useEffect(() => {
    (async function fetchFormBuilders() {
      const response = await API.getFormBuildersList({ categorySlug })
      if (response.ok) {
        setFormBuilderLists(response.data)
      } else {
        console.error("Error fetching form builders: ", response)
      }
    }())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorySlug])

  const [possibleFormBuilderUsers, setPossibleFormBuilderUsers] = useState([])
  useEffect(() => {
    (async function fetchUsers() {
      const response = await API.getPossibleFormBuilderUsers({ categorySlug })
      if (response.ok) {
        setPossibleFormBuilderUsers(response.data)
      } else {
        console.error("Error fetching form builder users: ", response)
      }
    }())
  }, [categorySlug])

  return (
    <>
      <div className="tab-title">Form Builders</div>
      <p className="tab-description mb-4">
        Here you can grant the form builder ability to non-admins, either entire groups or individual users.
      </p>
      <FormBuilderSearch
        formBuilderLists={formBuilderLists}
        category={category}
        setFormBuilderLists={setFormBuilderLists}
        users={possibleFormBuilderUsers}
        groups={groups}
      />
      <StrikeAround>
        <h3 className="font-secondary font-medium">Groups</h3>
      </StrikeAround>
      <GroupFormBuilderList
        formBuilderGroups={formBuilderLists.groups}
        deleteCategoryAbility={deleteCategoryAbility}
      />
      <StrikeAround>
        <h3 className="font-secondary font-medium">Individuals</h3>
      </StrikeAround>
      <UserFormBuilderList
        users={formBuilderLists.users}
        deleteCategoryAbility={deleteCategoryAbility}
      />
    </>
  )
}

FormBuildersTab.propTypes = {
  category: categoryShape.isRequired,
}

export default FormBuildersTab
