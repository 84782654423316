import React from "react"
import types from "prop-types"
import { snakeToCapitalCase } from "utils/stringHelpers"
import { FORM_TRIGGER_ICONS } from "utils/workflowHelpers"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

// eslint-disable-next-line react/prop-types
const TriggerIconLabel = ({ trigger }) => (
  <div className="workflow-white-label">
    <SvgSpriteIcon iconName={FORM_TRIGGER_ICONS[trigger]} className="mr-2" />
    { snakeToCapitalCase(trigger) }
  </div>
)

const TriggerLabel = ({ workflow }) => {
  if (workflow.frequency) {
    return (
      <div className="flex items-center">
        <TriggerIconLabel trigger={workflow.trigger} />
        <span className="mx-2">run it</span>
        <span className="workflow-white-label">{snakeToCapitalCase(workflow.frequency)}</span>
      </div>
    )
  }

  return (
    <TriggerIconLabel trigger={workflow.trigger} />
  )
}

TriggerLabel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  workflow: types.object.isRequired,
}

export default TriggerLabel
