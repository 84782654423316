import React from "react";
import types from "prop-types"
import { withFormsy } from "formsy-react"
import { userOptionShape } from "utils/propTypeShapes"
import { Select } from "shared/selects"
import SearchInput from "shared/selects/SearchInput"
import GroupAndUserAndDepartmentDropdown from "./GroupAndUserAndDepartmentDropdown"

// value is prefixed with model name to differentiate the ids
const usersToOptions = (users) => users.filter((user) => (user.active)).map(({
  fullName, id, firstName, lastName, active,
}) => ({
  label: fullName, value: `User-${id}`, firstName, lastName, active,
}))

const groupsToOptions = (groups) => groups.map(({ name, id }) => (
  { label: name, value: `Group-${id}` }
))

const departmentsToOptions = (departments) => departments.map(({ name, id, active }) => (
  { label: name, value: `Department-${id}`, marker: !(active ?? true) ? ["Inactive"] : [] }
))

const GroupAndUserAndDepartmentSelect = ({
  placeholder = "Search for users, groups, and departments",
  className = "",
  onChange,
  users = [],
  groups = [],
  departments = [],
  value = undefined,
  ...rest
}) => {
  const userOptions = usersToOptions(users)
  const groupOptions = groupsToOptions(groups)
  const departmentOptions = departmentsToOptions(departments)

  return (
    <Select
      className={className}
      isFullWidth
      contentRenderer={SearchInput}
      dropdownRenderer={GroupAndUserAndDepartmentDropdown(userOptions, groupOptions, departmentOptions)}
      placeholder={placeholder}
      onChange={onChange}
      dropdownHandle={false}
      options={[...userOptions, ...groupOptions, ...departmentOptions]}
      searchable
      backspaceDelete={false}
      value={value}
      {...rest}
    />
  )
}

GroupAndUserAndDepartmentSelect.propTypes = {
  className: types.string,
  onChange: types.func.isRequired,
  placeholder: types.string,
  users: types.arrayOf(userOptionShape),
  groups: types.arrayOf(types.object),
  departments: types.arrayOf(types.object),
  value: types.arrayOf(types.number),
}

export default GroupAndUserAndDepartmentSelect

export const FormsyGroupAndUserAndDepartmentSelect = withFormsy(({ setValue, ...rest }) => (
  <GroupAndUserAndDepartmentSelect onChange={setValue} {...rest} />
))

FormsyGroupAndUserAndDepartmentSelect.displayName = "FormsyGroupAndUserAndDepartmentSelect"
