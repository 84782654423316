import React from "react"
import { useTaskFormContext } from "contexts/TaskFormContext"
import { FormsyHiddenInput } from "shared/inputs"

const TaskNotifyGroupIds = () => {
  const { taskNotifyGroupIds } = useTaskFormContext()

  return (
    <FormsyHiddenInput name="task.notify_group_ids" value={taskNotifyGroupIds} />
  )
}

export default TaskNotifyGroupIds
