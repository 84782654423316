import React from "react"
import types from "prop-types"
import { useReportContext } from "contexts/ReportContext"
import { EllipsisIcon } from "shared/icons"
import ExpandMenu, { ExpandMenuItem } from "shared/ExpandMenu"
import clsx from "clsx"

const ReportMenu = ({ report }) => {
  const { deleteReport, goToReportEditPage } = useReportContext()

  const { editable, deletable } = report

  const hideMenu = !editable && !deletable

  return (
    <div className={clsx(hideMenu && "invisible pointer-events-none")}>
      <ExpandMenu
        expandMenuComponent={(
          <div className="relative left-14 bg-light-100 rounded py-2.5 px-0.5 h-1/2 self-center">
            <EllipsisIcon className="h-1 w-5" />
          </div>
        )}
      >
        {
          editable && (
            <ExpandMenuItem onClick={goToReportEditPage(report.id)}>
              Edit
            </ExpandMenuItem>
          )
        }
        {
          deletable && (
            <ExpandMenuItem onClick={deleteReport(report.id)}>
              Delete
            </ExpandMenuItem>
          )
        }
      </ExpandMenu>
    </div>
  )
}

ReportMenu.propTypes = {
  report: types.objectOf(types.any).isRequired,
}

export default ReportMenu
