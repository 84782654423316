import React, { useEffect } from "react"
import types from "prop-types"
import FHIR from "fhirclient"
import ActivityIndicator from "shared/ActivityIndicator"

// Our Meditech SMART application is classified as "public".  Meditech requires
// all public apps to use Proof Key for Code Exchange (PKCE).
// FHIR client options for PCKE mode described at
// https://docs.smarthealthit.org/client-js/api.html
// The library defaults to "ifSupported".
const IF_SUPPORTED_PKCE_MODE = "ifSupported"
const REQUIRED_PKCE_MODE = "required"

const Authorization = ({
  clientId,
  ehrName,
  redirectUri,
  requirePKCE = false,
  scope,
}) => {
  useEffect(() => {
    const authorizeFhir = () => (
      FHIR.oauth2.authorize({
        clientId,
        pkceMode: requirePKCE ? REQUIRED_PKCE_MODE : IF_SUPPORTED_PKCE_MODE,
        redirectUri,
        scope,
      })
    )

    authorizeFhir()
  }, [])

  return (
    <div className="flex flex-col w-full items-center mt-32">
      <ActivityIndicator className="mb-4" />
      <span>{`Communicating with ${ehrName} ...`}</span>
    </div>
  )
}

Authorization.propTypes = {
  clientId: types.string.isRequired,
  ehrName: types.string.isRequired,
  redirectUri: types.string.isRequired,
  requirePKCE: types.bool,
  scope: types.string.isRequired,
}

export default Authorization
