import React from "react"
import types from "prop-types"
import useFormSubmissionsListSettings from "hooks/useFormSubmissionsListSettings"
import { useHiddenSidebar } from "hooks"
import withReduxProvider from "shared/withReduxProvider"
import Sidebar from "./Sidebar"
import SubmissionContent from "./Content/SubmissionContent"

const FormSubmissionList = ({ categories, categorySlug }) => {
  useFormSubmissionsListSettings({ categories, defaultCategorySlug: categorySlug })

  const { hiddenSidebar, toggleSidebar } = useHiddenSidebar()

  return (
    <>
      <Sidebar hiddenSidebar={hiddenSidebar} />
      <SubmissionContent
        hiddenSidebar={hiddenSidebar}
        toggleSidebar={toggleSidebar}
      />
      <div />
    </>
  )
}

FormSubmissionList.propTypes = {
  categories: types.arrayOf(types.object).isRequired,
  categorySlug: types.string.isRequired,
}

export default withReduxProvider(FormSubmissionList)
