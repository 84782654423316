import { useContext } from "react"
import FormSubmissionExportContext from "../FormSubmissionExportContext"

const useFormSubmissionExportContext = () => {
  const providedValue = useContext(FormSubmissionExportContext)

  return { ...providedValue }
}

export default useFormSubmissionExportContext
