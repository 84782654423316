import { useEffect } from "react"
import removeQueryStringFromLocation from "utils/removeQueryStringFromLocation"

const useRemoveQueryStringFromLocation = () => {
  useEffect(() => {
    window.addEventListener("beforeunload", removeQueryStringFromLocation)

    return () => {
      window.removeEventListener("beforeunload", removeQueryStringFromLocation)
    }
  }, [])
}

export default useRemoveQueryStringFromLocation
