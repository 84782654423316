import React, { useEffect } from "react"
import types from "prop-types"
import { Select } from "shared/selects"
import { CONTROLS, DEFAULT_CONTROL } from "utils/QuestionBranching"
import { capitalize } from "utils/stringHelpers"
import FormsyValidation from "shared/FormsyValidation"

const controlOptions = CONTROLS.map((value) => ({ label: capitalize(value), value }));

const ConditionControl = ({ fieldPath, updateConditionalsControl, value }) => {
  useEffect(() => {
    if (!value) updateConditionalsControl(DEFAULT_CONTROL)
  }, [value, updateConditionalsControl])

  return (
    <>
      <Select
        className="text-sm mb-3"
        style={{ height: "32px", minWidth: "100", width: "max-content" }}
        options={controlOptions}
        value={value}
        onChange={updateConditionalsControl}
      />
      <FormsyValidation
        name={fieldPath}
        value={value}
        validations={{ isNotBlankString: true }}
        validationErrors={{ isDefaultRequiredValue: "required" }}
      />
    </>
  )
}

ConditionControl.propTypes = {
  fieldPath: types.string.isRequired,
  updateConditionalsControl: types.func.isRequired,
  value: types.string.isRequired,
}

export default ConditionControl
