import React from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { useDispatch, useSelector } from "react-redux"
import {
  getFormQuestionAttributeEndTimeQuestionUuid,
  getFormQuestionAttributeEndTimeQuestionUuidOptions,
  setQuestionAttributeEndTimeQuestionUuid,
} from "reduxSlices/formBuilderSlice"
import { QuestionSelect } from "shared/selects"

const EndTimeQuestionUuid = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const questionEndTimeQuestionUuid = useSelector(getFormQuestionAttributeEndTimeQuestionUuid({ sectionIndex, questionIndex }))
  const questionEndTimeQuestionOptions = useSelector(getFormQuestionAttributeEndTimeQuestionUuidOptions({ sectionIndex, questionIndex }), isEqual)

  const updateEndTimeQuestionUuid = (newEndTimeQuesitonUuid) => dispatch(setQuestionAttributeEndTimeQuestionUuid({
    sectionIndex, questionIndex, newEndTimeQuesitonUuid,
  }))

  return (
    <QuestionSelect
      backspaceDelete={false}
      className="text-sm"
      isFullWidth
      onChange={updateEndTimeQuestionUuid}
      placeholder="End time"
      questions={questionEndTimeQuestionOptions}
      selectedQuestionUuid={questionEndTimeQuestionUuid}
      style={{ height: "32px" }}
    />
  )
}

EndTimeQuestionUuid.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default EndTimeQuestionUuid
