import React from "react"
import { store } from "store"
import { addFormSection } from "reduxSlices/formBuilderSlice"
import { Button, RIGHT } from "shared/buttons"

const AddSectionButton = () => {
  const addSection = () => store.dispatch(addFormSection())

  return (
    <div className="flex justify-center w-full">
      <Button
        className="text-focus font-medium border border-lightgray-3 mt-16"
        onClick={addSection}
        icon="fas fa-plus"
        background="white"
        iconPosition={RIGHT}
        text="Add Section"
      />
    </div>
  )
}

export default AddSectionButton
