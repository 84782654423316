import React from "react"
import types from "prop-types"
import clsx from "clsx"

const DEFAULT_CLASSES = "flex flex-col bg-white border border-lightgray-3 rounded-lg overflow-hidden"

const Tile = ({ children, className = "" }) => (
  <div className={clsx(className, DEFAULT_CLASSES)}>
    {children}
  </div>
)

Tile.propTypes = {
  children: types.node.isRequired,
  className: types.string,
}

export default Tile
