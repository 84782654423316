import React from "react"
import types from "prop-types"
import clsx from "clsx"

const SelectorSection = ({
  instructionComponent = null,
  largeDisplay = false,
  optionsComponent,
  selectionsComponent,
}) => (
  <div className="space-y-8">
    { instructionComponent }
    <div className={clsx("flex space-x-20", largeDisplay ? "max-h-2/3-screen" : "max-h-1/2-screen")}>
      <div className="basis-3/5 border border-lightgray-3 rounded-md">
        { selectionsComponent }
      </div>
      <div className="basis-2/5 border border-lightgray-3 rounded-md">
        { optionsComponent }
      </div>
    </div>
  </div>
)

SelectorSection.propTypes = {
  instructionComponent: types.node,
  largeDisplay: types.bool,
  optionsComponent: types.node.isRequired,
  selectionsComponent: types.node.isRequired,
}

export default SelectorSection
