import React from "react"
import * as API from "services/api"
import * as StatusListHelpers from "utils/statusListHelpers"
import { useSelector } from "react-redux"
import { store } from "store"
import { category, formSubmissionData, reopenSubmission } from "reduxSlices/formSubmissionSlice"
import { errorToast } from "shared/toast"
import { Select } from "shared/selects"

const reopenFormSubmission = async (formSubmissionSlug, statusName) => {
  const response = await API.reopenSubmission({ formSubmissionSlug })
  if (response.ok) {
    store.dispatch(reopenSubmission(statusName))
  } else {
    errorToast("Something went wrong. Unable to reopen form submission.")
  }
}

const ClosedSubmissionSelect = () => {
  const { statusName, slug } = useSelector(formSubmissionData)
  const { statuses } = useSelector(category)
  const openStatus = StatusListHelpers.openStatus({ statuses })

  const closedStatusListOptions = StatusListHelpers.closedStatusListOptions(statuses)

  return (
    <Select
      className="bg-white font-medium text-sm"
      hasBaseInputStyle={false}
      style={{
        minHeight: "32px",
        borderRadius: "4px",
        minWidth: "200px",
      }}
      options={closedStatusListOptions}
      value={statusName}
      placeholder="Select status"
      onChange={() => reopenFormSubmission(slug, openStatus.name)}
      backspaceDelete={false}
    />
  )
}

export default ClosedSubmissionSelect
