import React from "react"
import types from "prop-types"
import { IconButton } from "shared/buttons"

const ExpandIconButton = ({ isExpanded, toggleAccordion }) => (
  <IconButton
    className="border rounded-full border-lightgray-3"
    background="default"
    icon={`fas fa-chevron-${isExpanded ? "down" : "up"}`}
    onClick={toggleAccordion}
  />
)

ExpandIconButton.propTypes = {
  isExpanded: types.bool.isRequired,
  toggleAccordion: types.func.isRequired,
}

export default ExpandIconButton
