import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"

// Details about the failure are recorded in the audit's comment,
// but they are not easily understood, so they are not displayed.
// They should also be available in the error reporting service.
const FormSubmissionWorkflowFailedAuditItemDetails = ({ audit }) => (
  <div>
    <p className="mb-6">
      <AuditedUser audit={audit} /> failed to perform an action.
    </p>
  </div>
)

FormSubmissionWorkflowFailedAuditItemDetails.propTypes = {
  audit: auditShape.isRequired,
}

export default FormSubmissionWorkflowFailedAuditItemDetails
