import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"

const FacilityCreated = ({ audit }) => {
  const { auditedChanges, comment = "" } = audit

  return (
    <div>
      <span>
        <AuditedUser audit={audit} /> added facility <span className="font-semibold">{auditedChanges.name}</span> {comment}
      </span>
    </div>
  )
}

FacilityCreated.propTypes = {
  audit: auditShape.isRequired,
}

export default FacilityCreated
