import React, { useEffect, useState } from "react"
import types from "prop-types"
import { sortBy } from "lodash-es"
import * as API from "services/api"
import { useSurveySentRelativeDateFilter } from "hooks"
import { reportExportSurveyReportShape } from "utils/propTypeShapes"
import { errorToast, successToast } from "components/shared/toast"
import {
  dayjsOrgTz,
  DAYS, MONTHS, WEEKS,
} from "utils/dateHelpers"
import { ACTIVITY_HUB_FORM_SUBMISSION_REPORTS_PATH, redirectTo } from "utils/routeHelpers"
import {
  CREATE_SCHEDULED_REPORT_EXPORT_ERROR_MESSAGE, MAX_DAYS, MAX_WEEKS, MAX_MONTHS,
} from "utils/scheduledReportExportHelpers"
import { DATE_TYPE } from "views/Forms/FormElementTypes"
import SurveyScheduledReportExportContext from "../SurveyScheduledReportExportContext"

const LOAD_QUESTIONS_ERROR_MESSAGE = "Something went wrong. Unable to load form questions."
const LOAD_FACILITIES_ERROR_MESSAGE = "Something went wrong. Unable to load facilities."
const LOAD_DEPARTMENTS_ERROR_MESSAGE = "Something went wrong. Unable to load departments."
const LOAD_SURVEYS_ERROR_MESSAGE = "Something went wrong. Unable to load surveys."

const LOCATION_ANSWER_FILTER_TEMPLATE = {
  questionUuid: null,
  departmentIds: [],
}

const SurveyScheduledReportExportContextProvider = ({ children, surveyReport: propSurveyReport, surveyScheduledReportExport: propSurveyScheduledReportExport }) => {
  const {
    surveySentPreviousRangeLength, surveySentPreviousRangeUnit, setSurveySentPreviousRangeLength, setSurveySentPreviousRangeUnit,
  } = useSurveySentRelativeDateFilter()

  const [surveyReport] = useState(propSurveyReport)
  const { id: surveyReportId, formSlug, formTitle } = surveyReport

  const updatingExistingRecord = !!propSurveyScheduledReportExport

  const [surveyConfigurations, setSurveyConfigurations] = useState([])

  useEffect(() => {
    const loadSurveyConfigurations = async () => {
      const response = await API.formSurveyConfigurations({ formSlug })

      if (response.ok) {
        setSurveyConfigurations(response.data)
      } else {
        errorToast(LOAD_SURVEYS_ERROR_MESSAGE)
      }
    }

    loadSurveyConfigurations()
  }, [formSlug])

  const [scheduledReportExport, setScheduledReportExport] = useState({})

  const [questions, setQuestions] = useState([])
  const [dateAnswerFilters, setDateAnswerFilters] = useState([])

  useEffect(() => {
    const loadQuestions = async () => {
      const response = await API.formQuestionHistory({ formSlug })

      if (response.ok) {
        const formQuestions = response.data

        setQuestions(formQuestions)

        const dateQuestions = formQuestions.filter((question) => question.type === DATE_TYPE)

        setDateAnswerFilters(dateQuestions)
      } else {
        errorToast(LOAD_QUESTIONS_ERROR_MESSAGE)
      }
    }

    loadQuestions()
  }, [formSlug])

  const [dateAnswerQuestionUuid, setDateAnswerQuestionUuid] = useState()
  const [dateAnswerPreviousRangeLength, setDateAnswerPreviousRangeLength] = useState()
  const [dateAnswerPreviousRangeUnit, setDateAnswerPreviousRangeUnit] = useState()

  const [scheduledReportExportName, setScheduledReportExportName] = useState("")
  const [activeStatus, setActiveStatus] = useState(false)
  const [frequency, setReportFrequency] = useState("")
  const [recurrenceDayOfWeek, setRecurrenceDayOfWeek] = useState("")
  const [recurrenceDayOfMonth, setRecurrenceDayOfMonth] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [selectedFacilityIds, setSelectedFacilityIds] = useState([])

  const maxBasedOnUnit = () => {
    if (surveySentPreviousRangeUnit === DAYS) {
      return MAX_DAYS
    } if (surveySentPreviousRangeUnit === WEEKS) {
      return MAX_WEEKS
    } if (surveySentPreviousRangeUnit === MONTHS) {
      return MAX_MONTHS
    }
    return 10
  }

  const lengthOptions = Array.from({ length: maxBasedOnUnit() }, (_, index) => index + 1)

  const [facilities, setFacilities] = useState([])
  const [departments, setDepartments] = useState({})

  useEffect(() => {
    const loadFacilities = async () => {
      const response = await API.getFacilities()

      if (response.ok) {
        setFacilities(sortBy(response.data, "name"))
      } else {
        errorToast(LOAD_FACILITIES_ERROR_MESSAGE)
      }
    }

    loadFacilities()
  }, [])

  useEffect(() => {
    const loadDepartments = async () => {
      const response = await API.getDepartments()

      if (response.ok) {
        const departmentsByFacility = sortBy(response.data, "name").reduce((aggregator, department) => {
          if (aggregator[department.facilityId]) {
            aggregator[department.facilityId].push(department)
          } else {
            aggregator[department.facilityId] = [department]
          }

          return aggregator
        }, {})

        setDepartments(departmentsByFacility)
      } else {
        errorToast(LOAD_DEPARTMENTS_ERROR_MESSAGE)
      }
    }

    loadDepartments()
  }, [])

  const [locationAnswerFilters, setLocationAnswerFilters] = useState([])

  const facilityDepartments = (facilityId) => departments[facilityId] ?? []

  const setLocationAnswerFilterQuestionUuid = (questionUuid) => {
    let newLocationFilters = []

    if (questionUuid) {
      const currentLocationFilter = locationAnswerFilters[0] ?? { ...LOCATION_ANSWER_FILTER_TEMPLATE }
      newLocationFilters = [{ ...currentLocationFilter, questionUuid }]
    }

    setLocationAnswerFilters(newLocationFilters)
  }

  const updateLocationAnswerFiltersDepartmentIds = (departmentIds) => {
    const currentLocationFilter = locationAnswerFilters[0]

    setLocationAnswerFilters(
      [
        {
          ...currentLocationFilter,
          departmentIds,
        },
      ],
    )
  }

  const addDepartmentIdsToLocationAnswerFilters = (departmentIds) => {
    const currentLocationFilter = locationAnswerFilters[0]
    const updatedDepartmentIds = Array.from(new Set([...currentLocationFilter.departmentIds, ...departmentIds]))
    updateLocationAnswerFiltersDepartmentIds(updatedDepartmentIds)
  }

  const addAllFacilityDepartmentsToLocationAnswerFilters = (facilityId) => {
    addDepartmentIdsToLocationAnswerFilters(
      facilityDepartments(facilityId).map((department) => department.id),
    )
  }

  const removeDepartmentIdsFromLocationAnswerFilters = (departmentIds) => {
    const currentLocationFilter = locationAnswerFilters[0]
    const updatedDepartmentIds = currentLocationFilter.departmentIds.filter((id) => !departmentIds.includes(id))
    updateLocationAnswerFiltersDepartmentIds(updatedDepartmentIds)
  }

  const removeAllFacilityDepartmentsFromLocationAnswerFilters = (facilityId) => {
    removeDepartmentIdsFromLocationAnswerFilters(
      facilityDepartments(facilityId).map((department) => department.id),
    )
  }

  const [selectedConfigurationIds, setSelectedConfigurationIds] = useState([])
  const [includeCurrentAndFutureConfigurations, setIncludeCurrentAndFutureConfigurations] = useState(true)

  const selectAllConfigurationIds = () => setSelectedConfigurationIds(surveyConfigurations.map((config) => config.id))
  const deselectAllConfigurationIds = () => setSelectedConfigurationIds([])
  const isConfigurationSelected = (id) => selectedConfigurationIds?.includes(id)
  const selectConfiguration = (id) => setSelectedConfigurationIds(Array.from(new Set([...selectedConfigurationIds, id])))
  const deselectConfiguration = (id) => setSelectedConfigurationIds(selectedConfigurationIds.filter((configurationId) => configurationId !== id))
  const chooseAllCurrentAndFutureConfigurations = () => {
    setIncludeCurrentAndFutureConfigurations(true)
    deselectAllConfigurationIds()
  }

  const chooseSpecificConfigurations = () => setIncludeCurrentAndFutureConfigurations(false)

  const saveScheduledReportExport = async () => {
    let payload = {
      activeStatus,
      frequency,
      locationAnswerFilters,
      recurrenceDayOfWeek,
      recurrenceDayOfMonth,
      scheduledReportExportName,
      startDate,
      surveyFilters: [{
        survey_configuration_ids: selectedConfigurationIds,
        previous_range_length: surveySentPreviousRangeLength,
        previous_range_unit: surveySentPreviousRangeUnit,
      }],
      reportId: surveyReportId,
    }

    if (dateAnswerQuestionUuid) {
      payload = {
        ...payload,
        dateAnswerFilters: [{
          previous_range_length: dateAnswerPreviousRangeLength,
          previous_range_unit: dateAnswerPreviousRangeUnit,
          question_uuid: dateAnswerQuestionUuid,
        }],
      }
    } else {
      payload = {
        ...payload,
        dateAnswerFilters: [],
      }
    }

    let response
    if (updatingExistingRecord) {
      const { id: scheduledReportExportId } = propSurveyScheduledReportExport

      response = await API.updateSurveyScheduledReportExport({ ...payload, scheduledReportExportId })
    } else {
      response = await API.createSurveyScheduledReportExport(payload)
    }

    if (response.ok) {
      setScheduledReportExport(response.data)
      successToast(`Scheduled report export ${scheduledReportExportName} has been saved.`)

      setTimeout(() => { redirectTo(ACTIVITY_HUB_FORM_SUBMISSION_REPORTS_PATH) }, 1000)
    } else {
      errorToast(CREATE_SCHEDULED_REPORT_EXPORT_ERROR_MESSAGE)
    }
  }

  const cancelForm = () => { window.location.pathname = ACTIVITY_HUB_FORM_SUBMISSION_REPORTS_PATH }

  // if editing existing scheduled report export:
  useEffect(() => {
    if (updatingExistingRecord) {
      const {
        dateAnswerPreviousRangeLength: propDateAnswerPreviousRangeLength,
        dateQuestionUuid: propDateQuestionUuid,
        departmentIds,
        facilityIds,
        locationQuestionUuid,
        surveyConfigurationIds,
        surveySentPreviousRangeLength: propSurveySentPreviousRangeLength,
        surveySentPreviousRangeUnit: propSurveySentPreviousRangeUnit,
        name: propName,
        scheduledReportExportSchedule: propScheduledReportExportSchedule,
      } = propSurveyScheduledReportExport

      const {
        active: propActive,
        frequency: propFrequency,
        recurrenceDayOfMonth: propRecurrenceDayOfMonth,
        recurrenceDayOfWeek: propRecurrenceDayOfWeek,
        startDate: propStartDate,
      } = propScheduledReportExportSchedule

      setSurveySentPreviousRangeLength(propSurveySentPreviousRangeLength)
      setSurveySentPreviousRangeUnit(propSurveySentPreviousRangeUnit)
      setScheduledReportExportName(propName)
      setActiveStatus(propActive)
      setReportFrequency(propFrequency)
      setRecurrenceDayOfMonth(propRecurrenceDayOfMonth)
      setRecurrenceDayOfWeek(propRecurrenceDayOfWeek)
      setStartDate(dayjsOrgTz(propStartDate))

      setDateAnswerQuestionUuid(propDateQuestionUuid)
      setDateAnswerPreviousRangeLength(propDateAnswerPreviousRangeLength)

      // date answer unit will always be same as [required] survey sent unit
      setDateAnswerPreviousRangeUnit(propSurveySentPreviousRangeUnit)

      if (surveyConfigurationIds.length > 0) {
        setSelectedConfigurationIds(surveyConfigurationIds)
        setIncludeCurrentAndFutureConfigurations(false)
      } else {
        setIncludeCurrentAndFutureConfigurations(true)
      }

      if (locationQuestionUuid) {
        setLocationAnswerFilters([
          {
            questionUuid: locationQuestionUuid,
            departmentIds,
          },
        ])
        setSelectedFacilityIds(facilityIds)
      }
    }
  }, [])

  // Object exposed to context consumers
  const contextConsumerValue = {
    activeStatus,
    addAllFacilityDepartmentsToLocationAnswerFilters,
    addDepartmentIdsToLocationAnswerFilters,
    scheduledReportExport,
    cancelForm,
    chooseAllCurrentAndFutureConfigurations,
    chooseSpecificConfigurations,
    dateAnswerQuestionUuid,
    dateAnswerPreviousRangeLength,
    dateAnswerFilters,
    departments,
    deselectAllConfigurationIds,
    deselectConfiguration,
    frequency,
    facilities,
    facilityDepartments,
    formTitle,
    includeCurrentAndFutureConfigurations,
    isConfigurationSelected,
    lengthOptions,
    locationAnswerFilters,
    recurrenceDayOfMonth,
    recurrenceDayOfWeek,
    removeAllFacilityDepartmentsFromLocationAnswerFilters,
    removeDepartmentIdsFromLocationAnswerFilters,
    dateAnswerPreviousRangeUnit,
    propSurveyScheduledReportExport,
    questions,
    saveScheduledReportExport,
    selectAllConfigurationIds,
    selectConfiguration,
    selectedConfigurationIds,
    selectedFacilityIds,
    setActiveStatus,
    setScheduledReportExport,
    setReportFrequency,
    setRecurrenceDayOfMonth,
    setRecurrenceDayOfWeek,
    setDateAnswerPreviousRangeLength,
    setDateAnswerPreviousRangeUnit,
    setDateAnswerQuestionUuid,
    setLocationAnswerFilterQuestionUuid,
    setScheduledReportExportName,
    setSelectedFacilityIds,
    setStartDate,
    setSurveySentPreviousRangeLength,
    setSurveySentPreviousRangeUnit,
    scheduledReportExportName,
    surveyConfigurations,
    surveyReport,
    startDate,
    surveySentPreviousRangeLength,
    surveySentPreviousRangeUnit,
  }

  return (
    <SurveyScheduledReportExportContext.Provider value={contextConsumerValue}>
      {children}
    </SurveyScheduledReportExportContext.Provider>
  )
}

SurveyScheduledReportExportContextProvider.propTypes = {
  children: types.node.isRequired,
  surveyReport: reportExportSurveyReportShape.isRequired,
  surveyScheduledReportExport: types.object.isRequired,
}

export default SurveyScheduledReportExportContextProvider
