import React from "react"
import { SidebarCollapsibleContent } from "shared/Sidebar"
import TaskCategoryList from "./TaskCategoryList"

const TaskCategorySelect = () => (
  <SidebarCollapsibleContent title="Showing">
    <TaskCategoryList />
  </SidebarCollapsibleContent>
)

export default TaskCategorySelect
