import { useEffect } from "react"
import types from "prop-types"
import { store } from "store"
import { setFacilities } from "reduxSlices/facilitiesSlice"

const SetupFacilitiesInRedux = ({ facilities = [] }) => {
  useEffect(() => {
    store.dispatch(setFacilities(facilities))
  }, [facilities])

  return null
}

SetupFacilitiesInRedux.propTypes = {
  facilities: types.arrayOf(types.object),
}

export default SetupFacilitiesInRedux
