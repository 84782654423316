import React from "react"
import types from "prop-types"
import { HistoricalAccessToolContextProvider } from "contexts/HistoricalAccessToolContext"
import HistoricalAccessToolDataCollector from "./HistoricalAccessToolDataCollector"

const HistoricalAccessTool = ({ user }) => (
  <HistoricalAccessToolContextProvider user={user}>
    <HistoricalAccessToolDataCollector />
  </HistoricalAccessToolContextProvider>
)

HistoricalAccessTool.propTypes = {
  user: types.object.isRequired,
}

export default HistoricalAccessTool
