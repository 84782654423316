import React, { useCallback, useEffect } from "react"
import types from "prop-types"
import { useTaskFormContext } from "contexts/TaskFormContext"
import { Select } from "shared/selects"
import { NumberInput } from "shared/inputs"
import FormsyValidation from "shared/FormsyValidation"

const PERIOD_OPTIONS = [
  { value: "days", label: "Days" },
  { value: "weeks", label: "Weeks" },
  { value: "months", label: "Months" },
]

const NUMBER_VALIDATIONS = {
  isNumberGreaterThanOrEqualTo: { min: 1, errorMessage: "Must be at least 1" },
  isInt: "Must be at least 1",
  isNotBlankString: true,
}

const PERIOD_VALIDATIONS = { isNotBlankString: true }

const TaskRelativeDueDate = ({ className = "", actionIndex }) => {
  const { taskRelativeDueDate, updateTaskRelativeDueDate } = useTaskFormContext()
  const { number, period } = taskRelativeDueDate

  const updateRelativeDueDate = useCallback((value) => (
    updateTaskRelativeDueDate({
      ...taskRelativeDueDate,
      ...value,
    })
  ), [taskRelativeDueDate, updateTaskRelativeDueDate])

  useEffect(() => {
    if (!period) {
      updateRelativeDueDate({ period: PERIOD_OPTIONS[0].value })
    }
  }, [period, updateRelativeDueDate])

  return (
    <div className={className}>
      <span className="block mb-1 font-semibold required-prompt">Due Date</span>
      <div className="flex items-center">
        <div className="relative mr-2">
          <NumberInput
            className="w-12 bordered-base-input h-9"
            min={0}
            onChange={(newNumber) => updateRelativeDueDate({ number: newNumber })}
            value={number}
          />
          <FormsyValidation
            name={`actions[${actionIndex}].data.task.relative_due_date.number`}
            validations={NUMBER_VALIDATIONS}
            value={number}
          />
        </div>
        <div className="relative mr-2">
          <Select
            backspaceDelete={false}
            onChange={(newPeriod) => updateRelativeDueDate({ period: newPeriod })}
            options={PERIOD_OPTIONS}
            style={{ height: "32px" }}
            value={period}
          />
          <FormsyValidation
            name={`actions[${actionIndex}].data.task.relative_due_date.period`}
            validations={PERIOD_VALIDATIONS}
            value={period}
          />
        </div>
        <span>after submission date</span>
      </div>
    </div>
  )
}

TaskRelativeDueDate.propTypes = {
  actionIndex: types.number.isRequired,
  className: types.string,
}

export default TaskRelativeDueDate
