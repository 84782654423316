import React from "react"
import types from "prop-types"
import { FormsyHiddenInput } from "shared/inputs"

const EntryUuid = ({ initialValue }) => (
  <FormsyHiddenInput
    name="entry_uuid"
    value={initialValue}
  />
)

EntryUuid.propTypes = {
  initialValue: types.string.isRequired,
}

export default EntryUuid
