import React from "react"
import types from "prop-types"
import { SidebarVisibilityControl } from "shared/Sidebar"

const SubmissionHeader = ({ hiddenSidebar, toggleSidebar }) => (
  <div className="relative mb-12">
    <SidebarVisibilityControl
      hiddenSidebar={hiddenSidebar}
      toggleSidebar={toggleSidebar}
    />
    <h1 className="header-title tracking-tight absolute-horizontal-center top-0 my-0 -ml-6">
      Submissions
    </h1>
  </div>
)

SubmissionHeader.propTypes = {
  hiddenSidebar: types.bool.isRequired,
  toggleSidebar: types.func.isRequired,
}

export default SubmissionHeader
