/* eslint-disable react/prop-types */
import React, { useRef } from "react"
import clsx from "clsx"
import Options from "shared/Options"
import SearchInput from "./SearchInput"

const doesMatchSearch = (search, label) => label.toLowerCase().includes(search.toLowerCase())

// eslint-disable-next-line react/display-name
const Dropdown = (isSelectorHidden, toggleSelectorVisibility, placeholder) => ({
  props: { options },
  state: {
    search,
  },
  methods: { setSearch, addItem },
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const searchInputRef = useRef()

  const selectOption = (option) => {
    addItem(option)
    toggleSelectorVisibility()
    if (!isSelectorHidden) {
      searchInputRef?.current?.focus()
    }
  }
  const filteredOptions = options.filter(({ label }) => doesMatchSearch(search, label))

  const onSearchInputEnter = ({ key }) => {
    if (key === "Enter" && filteredOptions.length === 1) {
      selectOption(filteredOptions[0])
    }
  }

  return (
    <div className={clsx("top-0 visible bg-white border w-full rounded-md border-lightgray-3 overflow-y-auto")}>
      <SearchInput
        ref={searchInputRef}
        isSelectorHidden={isSelectorHidden}
        search={search}
        setSearch={setSearch}
        onSearchInputEnter={onSearchInputEnter}
        placeholder={placeholder}
      />
      <Options
        selectOption={selectOption}
        options={filteredOptions}
        search={search}
      />
    </div>
  )
}

export default Dropdown
