import React, { useEffect, useState } from "react"
import types from "prop-types"
import { sortBy } from "lodash-es"
import * as API from "services/api"
import { useSubmissionRelativeDateFilter } from "hooks"
import {
  dayjsOrgTz,
  DAYS, MONTHS, WEEKS,
} from "utils/dateHelpers"
import { reportExportFormSubmissionReportShape } from "utils/propTypeShapes"
import { errorToast, successToast } from "components/shared/toast"
import { ACTIVITY_HUB_FORM_SUBMISSION_REPORTS_PATH, redirectTo } from "utils/routeHelpers"
import { DATE_TYPE } from "views/Forms/FormElementTypes"
import {
  CREATE_SCHEDULED_REPORT_EXPORT_ERROR_MESSAGE, MAX_DAYS, MAX_WEEKS, MAX_MONTHS, SUBMISSION_DATE_FILTER_HEADER, DATE_QUESTION_FILTER_HEADER,
} from "utils/scheduledReportExportHelpers"
import FormSubmissionScheduledReportExportContext from "../FormSubmissionScheduledReportExportContext"

const LOAD_QUESTIONS_ERROR_MESSAGE = "Something went wrong. Unable to load form questions."
const LOAD_FACILITIES_ERROR_MESSAGE = "Something went wrong. Unable to load facilities."
const LOAD_DEPARTMENTS_ERROR_MESSAGE = "Something went wrong. Unable to load departments."

const LOCATION_ANSWER_FILTER_TEMPLATE = {
  questionUuid: null,
  departmentIds: [],
}

export const DATE_FILTER_TYPE_OPTIONS = [SUBMISSION_DATE_FILTER_HEADER, DATE_QUESTION_FILTER_HEADER]

const FormSubmissionScheduledReportExportContextProvider = ({ children, formSubmissionReport: propFormSubmissionReport, formSubmissionScheduledReportExport: propFormSubmissionScheduledReportExport }) => {
  const {
    submissionPreviousRangeLength, submissionPreviousRangeUnit, setSubmissionPreviousRangeLength, setSubmissionPreviousRangeUnit,
  } = useSubmissionRelativeDateFilter()

  const [formSubmissionReport] = useState(propFormSubmissionReport)
  const { id: formSubmissionReportId, formSlug } = formSubmissionReport

  const [scheduledReportExport, setScheduledReportExport] = useState({})

  const updatingExistingRecord = !!propFormSubmissionScheduledReportExport

  const [questions, setQuestions] = useState([])
  const [dateAnswerFilters, setDateAnswerFilters] = useState([])
  const [dateFilterType, setDateFilterType] = useState("")

  const [dateAnswerQuestionUuid, setDateAnswerQuestionUuid] = useState()
  const [dateAnswerPreviousRangeLength, setDateAnswerPreviousRangeLength] = useState()
  const [dateAnswerPreviousRangeUnit, setDateAnswerPreviousRangeUnit] = useState()

  const [scheduledReportExportName, setScheduledReportExportName] = useState("")
  const [activeStatus, setActiveStatus] = useState(false)
  const [frequency, setReportFrequency] = useState("")
  const [recurrenceDayOfWeek, setRecurrenceDayOfWeek] = useState("")
  const [recurrenceDayOfMonth, setRecurrenceDayOfMonth] = useState(null)
  const [startDate, setStartDate] = useState(null)

  const [selectedFacilityIds, setSelectedFacilityIds] = useState([])

  const setDateFilterTypeAndClearValues = (selectedDateFilterType) => {
    setDateAnswerPreviousRangeLength()
    setSubmissionPreviousRangeLength()

    setDateFilterType(selectedDateFilterType)
  }

  const maxBasedOnUnit = () => {
    if (submissionPreviousRangeUnit === DAYS) {
      return MAX_DAYS
    } if (submissionPreviousRangeUnit === WEEKS) {
      return MAX_WEEKS
    } if (submissionPreviousRangeUnit === MONTHS) {
      return MAX_MONTHS
    }
    return 10
  }

  const lengthOptions = Array.from({ length: maxBasedOnUnit() }, (_, index) => index + 1)

  const [facilities, setFacilities] = useState([])
  const [departments, setDepartments] = useState({})
  const [locationAnswerFilters, setLocationAnswerFilters] = useState([])

  const facilityDepartments = (facilityId) => departments[facilityId] ?? []

  const setLocationAnswerFilterQuestionUuid = (questionUuid) => {
    let newLocationFilters = []

    if (questionUuid) {
      const currentLocationFilter = locationAnswerFilters[0] ?? { ...LOCATION_ANSWER_FILTER_TEMPLATE }
      newLocationFilters = [{ ...currentLocationFilter, questionUuid }]
    }

    setLocationAnswerFilters(newLocationFilters)
  }

  const updateLocationAnswerFiltersDepartmentIds = (departmentIds) => {
    const currentLocationFilter = locationAnswerFilters[0]

    setLocationAnswerFilters(
      [
        {
          ...currentLocationFilter,
          departmentIds,
        },
      ],
    )
  }

  const addDepartmentIdsToLocationAnswerFilters = (departmentIds) => {
    const currentLocationFilter = locationAnswerFilters[0]
    const updatedDepartmentIds = Array.from(new Set([...currentLocationFilter.departmentIds, ...departmentIds]))
    updateLocationAnswerFiltersDepartmentIds(updatedDepartmentIds)
  }

  const addAllFacilityDepartmentsToLocationAnswerFilters = (facilityId) => {
    addDepartmentIdsToLocationAnswerFilters(
      facilityDepartments(facilityId).map((department) => department.id),
    )
  }

  const removeDepartmentIdsFromLocationAnswerFilters = (departmentIds) => {
    const currentLocationFilter = locationAnswerFilters[0]
    const updatedDepartmentIds = currentLocationFilter.departmentIds.filter((id) => !departmentIds.includes(id))
    updateLocationAnswerFiltersDepartmentIds(updatedDepartmentIds)
  }

  const removeAllFacilityDepartmentsFromLocationAnswerFilters = (facilityId) => {
    removeDepartmentIdsFromLocationAnswerFilters(
      facilityDepartments(facilityId).map((department) => department.id),
    )
  }

  const saveScheduledReportExport = async () => {
    let payload = {
      activeStatus,
      locationAnswerFilters,
      recurrenceDayOfMonth,
      recurrenceDayOfWeek,
      frequency,
      scheduledReportExportName,
      startDate,
      reportId: formSubmissionReportId,
    }

    if (dateFilterType === SUBMISSION_DATE_FILTER_HEADER) {
      payload = {
        ...payload,
        dateAnswerFilters: [],
        submissionPreviousRangeLength,
        submissionPreviousRangeUnit,
      }
    } else if (dateFilterType === DATE_QUESTION_FILTER_HEADER) {
      payload = {
        ...payload,
        dateAnswerFilters: [{
          previous_range_length: dateAnswerPreviousRangeLength,
          previous_range_unit: dateAnswerPreviousRangeUnit,
          question_uuid: dateAnswerQuestionUuid,
        }],
        submissionPreviousRangeLength: null,
        submissionPreviousRangeUnit: null,
      }
    }

    let response
    if (updatingExistingRecord) {
      const { id: scheduledReportExportId } = propFormSubmissionScheduledReportExport

      response = await API.updateFormSubmissionScheduledReportExport({ ...payload, scheduledReportExportId })
    } else {
      response = await API.createFormSubmissionScheduledReportExport(payload)
    }

    if (response.ok) {
      setScheduledReportExport(response.data)
      successToast(`Scheduled report export ${scheduledReportExportName} has been saved.`)

      setTimeout(() => { redirectTo(ACTIVITY_HUB_FORM_SUBMISSION_REPORTS_PATH) }, 1000)
    } else {
      errorToast(CREATE_SCHEDULED_REPORT_EXPORT_ERROR_MESSAGE)
    }
  }

  const cancelForm = () => { redirectTo(ACTIVITY_HUB_FORM_SUBMISSION_REPORTS_PATH) }

  useEffect(() => {
    const loadQuestions = async () => {
      const response = await API.formQuestionHistory({ formSlug })

      if (response.ok) {
        const formQuestions = response.data

        setQuestions(formQuestions)

        const dateQuestions = formQuestions.filter((question) => question.type === DATE_TYPE)

        setDateAnswerFilters(dateQuestions)

        if (!updatingExistingRecord) {
          setDateFilterType(dateQuestions.length < 1 ? SUBMISSION_DATE_FILTER_HEADER : "")
        }
      } else {
        errorToast(LOAD_QUESTIONS_ERROR_MESSAGE)
      }
    }
    loadQuestions()
  }, [formSlug])

  useEffect(() => {
    const loadFacilities = async () => {
      const response = await API.getFacilities()

      if (response.ok) {
        setFacilities(sortBy(response.data, "name"))
      } else {
        errorToast(LOAD_FACILITIES_ERROR_MESSAGE)
      }
    }

    loadFacilities()
  }, [])

  useEffect(() => {
    const loadDepartments = async () => {
      const response = await API.getDepartments()

      if (response.ok) {
        const departmentsByFacility = sortBy(response.data, "name").reduce((aggregator, department) => {
          if (aggregator[department.facilityId]) {
            aggregator[department.facilityId].push(department)
          } else {
            aggregator[department.facilityId] = [department]
          }

          return aggregator
        }, {})

        setDepartments(departmentsByFacility)
      } else {
        errorToast(LOAD_DEPARTMENTS_ERROR_MESSAGE)
      }
    }

    loadDepartments()
  }, [])

  // if editing existing scheduled report export:
  useEffect(() => {
    if (updatingExistingRecord) {
      const {
        dateAnswerPreviousRangeLength: propDateAnswerPreviousRangeLength,
        dateAnswerPreviousRangeUnit: propDateAnswerPreviousRangeUnit,
        dateQuestionUuid: propDateQuestionUuid,
        departmentIds,
        facilityIds,
        locationQuestionUuid,
        formSubmissionPreviousRangeLength: propFormSubmissionPreviousRangeLength,
        formSubmissionPreviousRangeUnit: propFormSubmissionPreviousRangeUnit,
        name: propName,
        scheduledReportExportSchedule: propScheduledReportExportSchedule,
      } = propFormSubmissionScheduledReportExport

      const {
        active: propActive,
        frequency: propFrequency,
        recurrenceDayOfMonth: propRecurrenceDayOfMonth,
        recurrenceDayOfWeek: propRecurrenceDayOfWeek,
        startDate: propStartDate,
      } = propScheduledReportExportSchedule

      setSubmissionPreviousRangeLength(propFormSubmissionPreviousRangeLength)
      setScheduledReportExportName(propName)
      setActiveStatus(propActive)
      setReportFrequency(propFrequency)
      setRecurrenceDayOfMonth(propRecurrenceDayOfMonth)
      setRecurrenceDayOfWeek(propRecurrenceDayOfWeek)
      setStartDate(dayjsOrgTz(propStartDate))

      setDateAnswerQuestionUuid(propDateQuestionUuid)
      setDateAnswerPreviousRangeLength(propDateAnswerPreviousRangeLength)

      // unit will be the same for both, but only one gets stored on record
      if (propFormSubmissionPreviousRangeUnit) {
        setDateAnswerPreviousRangeUnit(propFormSubmissionPreviousRangeUnit)
        setSubmissionPreviousRangeUnit(propFormSubmissionPreviousRangeUnit)
      } else {
        setDateAnswerPreviousRangeUnit(propDateAnswerPreviousRangeUnit)
        setSubmissionPreviousRangeUnit(propDateAnswerPreviousRangeUnit)
      }

      propFormSubmissionPreviousRangeLength ? setDateFilterType(SUBMISSION_DATE_FILTER_HEADER) : setDateFilterType(DATE_QUESTION_FILTER_HEADER)

      if (locationQuestionUuid) {
        setLocationAnswerFilters([
          {
            questionUuid: locationQuestionUuid,
            departmentIds,
          },
        ])
        setSelectedFacilityIds(facilityIds)
      }
    }
  }, [])

  // Object exposed to context consumers
  const contextConsumerValue = {
    activeStatus,
    addAllFacilityDepartmentsToLocationAnswerFilters,
    addDepartmentIdsToLocationAnswerFilters,
    scheduledReportExport,
    cancelForm,
    dateFilterType,
    dateAnswerQuestionUuid,
    dateAnswerPreviousRangeLength,
    dateAnswerFilters,
    departments,
    frequency,
    facilities,
    facilityDepartments,
    lengthOptions,
    locationAnswerFilters,
    recurrenceDayOfMonth,
    recurrenceDayOfWeek,
    removeAllFacilityDepartmentsFromLocationAnswerFilters,
    removeDepartmentIdsFromLocationAnswerFilters,
    dateAnswerPreviousRangeUnit,
    questions,
    saveScheduledReportExport,
    selectedFacilityIds,
    setActiveStatus,
    setSelectedFacilityIds,
    setScheduledReportExport,
    setDateFilterTypeAndClearValues,
    setReportFrequency,
    setRecurrenceDayOfMonth,
    setRecurrenceDayOfWeek,
    setDateAnswerPreviousRangeLength,
    setDateAnswerPreviousRangeUnit,
    setDateAnswerQuestionUuid,
    setLocationAnswerFilterQuestionUuid,
    setScheduledReportExportName,
    setStartDate,
    setSubmissionPreviousRangeLength,
    setSubmissionPreviousRangeUnit,
    scheduledReportExportName,
    formSubmissionReport,
    startDate,
    submissionPreviousRangeLength,
    submissionPreviousRangeUnit,
  }

  return (
    <FormSubmissionScheduledReportExportContext.Provider value={contextConsumerValue}>
      {children}
    </FormSubmissionScheduledReportExportContext.Provider>
  )
}

FormSubmissionScheduledReportExportContextProvider.propTypes = {
  children: types.node.isRequired,
  formSubmissionReport: reportExportFormSubmissionReportShape.isRequired,
  formSubmissionScheduledReportExport: types.object,
}

export default FormSubmissionScheduledReportExportContextProvider
