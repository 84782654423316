import { useContext } from "react"
import FormSubmissionFormContext from "../FormSubmissionFormContext"

const useFormSubmissionFormContext = () => {
  const providedValue = useContext(FormSubmissionFormContext)

  return { ...providedValue }
}

export default useFormSubmissionFormContext
