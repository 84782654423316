import React from "react"
import { useSolutionLevelReportExportContext } from "contexts/SolutionLevelReportExportContext"
import FileTypeSelect from "../ReportExport/FileTypeSelect"

const NewSolutionLevelReportExportFileTypeSelect = () => {
  const {
    reportExportFileType,
    setReportExportFileType,
  } = useSolutionLevelReportExportContext()

  return (
    <FileTypeSelect
      selectedFileType={reportExportFileType}
      onChange={setReportExportFileType}
    />
  )
}

export default NewSolutionLevelReportExportFileTypeSelect
