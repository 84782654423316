import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { getDepartmentFacilityMapping } from "reduxSlices/facilitiesSlice"
import { questionBranchingOperatorDataShape } from "utils/propTypeShapes"
import { DEPARTMENT_FACILITY_MAPPING_OPERATOR_DATA_KEY } from "./operatorDataKeys"

const withIsFacilityOperatorData = (WrappedComponent) => {
  const IsFacilityOperatorDataProvider = ({ operatorData, ...rest }) => {
    const mapping = useSelector(getDepartmentFacilityMapping)

    const updatedOperatorData = useMemo(() => (
      {
        ...operatorData,
        [DEPARTMENT_FACILITY_MAPPING_OPERATOR_DATA_KEY]: mapping,
      }
    ), [operatorData, mapping])

    return (
      <WrappedComponent
        operatorData={updatedOperatorData}
        {...rest}
      />
    )
  }

  IsFacilityOperatorDataProvider.propTypes = {
    operatorData: questionBranchingOperatorDataShape.isRequired,
  }

  return IsFacilityOperatorDataProvider
}

export default withIsFacilityOperatorData
