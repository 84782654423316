import { useContext } from "react"
import FormSubmissionAttachmentDropzoneContext from "../FormSubmissionAttachmentDropzoneContext"

const useFormSubmissionAttachmentDropzoneContext = () => {
  const providedValue = useContext(FormSubmissionAttachmentDropzoneContext)

  return { ...providedValue }
}

export default useFormSubmissionAttachmentDropzoneContext
