import React from "react"
import clsx from "clsx"
import types from "prop-types"
import ExpansionControl from "./ExpansionControl"

const AccordionHeader = ({
  children, className = "", isExpanded, toggleAccordion, isDisabled, headerColor,
}) => (
  <div className={clsx(className, isExpanded && "mb-3", `rounded-lg ${headerColor}`)}>
    <div className="flex-center pointer">
      { children }
      <ExpansionControl
        isExpanded={isExpanded}
        isDisabled={isDisabled}
        toggleAccordion={toggleAccordion}
      />
    </div>
  </div>
)

AccordionHeader.propTypes = {
  headerColor: types.string.isRequired,
  children: types.node.isRequired,
  className: types.string,
  isDisabled: types.bool.isRequired,
  isExpanded: types.bool.isRequired,
  toggleAccordion: types.func.isRequired,
}

export default AccordionHeader
