import React from "react"
import types from "prop-types"
import { snakeToCapitalCase } from "utils/stringHelpers"
import { RadioButton } from "shared/inputs"

const FilterActions = ({ dateFilterType, setDateFilterTypeAndClearValues, filterOptions }) => (
  <>
    {
      filterOptions.map((option) => (
        <RadioButton
          key={option}
          checked={dateFilterType === option}
          className="mb-4 last:mb-0"
          label={snakeToCapitalCase(option)}
          name="scheduled-report-export-filter-action"
          onChange={setDateFilterTypeAndClearValues}
          value={option}
        />
      ))
    }
  </>
)

FilterActions.propTypes = {
  dateFilterType: types.string.isRequired,
  setDateFilterTypeAndClearValues: types.func.isRequired,
  filterOptions: types.array.isRequired,
}

export default FilterActions
