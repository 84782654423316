import React from "react"
import types from "prop-types"
import { dayjsOrgTz, dateOrdinal, dateOrdinalOptions } from "utils/dateHelpers"
import { Select } from "shared/selects"

export const END_OF_MONTH_EXPLANATION = "If the selected day of the month does not occur in a given month, the report will be uploaded on the last day of that month."
const todayDate = dayjsOrgTz().date()

const MonthlyRecurrenceDetails = ({ recurrenceDayOfMonth, setRecurrenceDayOfMonth, setStartDate }) => {
  const setRecurrenceDetails = (selectedRecurrenceDayOfMonth) => {
    setRecurrenceDayOfMonth(selectedRecurrenceDayOfMonth)

    let startingDate = dayjsOrgTz().date(selectedRecurrenceDayOfMonth)

    // If start day has occurred this month, start the following month on that day of the month.
    // If the start day has not occurred, then start this month.
    if (selectedRecurrenceDayOfMonth <= todayDate) {
      startingDate = startingDate.add(1, "M")
    }

    setStartDate(startingDate)
  }

  return (
    <>
      <div className="flex items-center mr-2">
        <div className="mr-2">on the</div>
        <div className="w-16 mr-2">
          <Select
            className="bordered-base-input"
            name="recurrence_day_of_month"
            backspaceDelete={false}
            required
            placeholder={dateOrdinal(1)}
            options={dateOrdinalOptions()}
            value={recurrenceDayOfMonth}
            onChange={setRecurrenceDetails}
          />
        </div>
        {
          (recurrenceDayOfMonth <= todayDate)
          && <div className="flex ml-4">starting next month.</div>
        }
      </div>
      {
        recurrenceDayOfMonth > 28
        && (
          <p className="ml-4 mt-2 text-sm">{END_OF_MONTH_EXPLANATION}</p>
        )
      }
    </>
  )
}

MonthlyRecurrenceDetails.propTypes = {
  recurrenceDayOfMonth: types.number.isRequired,
  setRecurrenceDayOfMonth: types.func.isRequired,
  setStartDate: types.func.isRequired,
}

export default MonthlyRecurrenceDetails
