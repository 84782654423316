import React, { useCallback, useEffect, useState } from "react"
import types from "prop-types"
import { useModal } from "hooks"
import Modal, { DEFAULT_MODAL_STYLE, ModalHeader } from "shared/Modal"
import { errorToast } from "shared/toast"
import ExportMetabaseDashboardButton from "./ExportMetabaseDashboardButton"
import MetabaseDashboardImageCaptureProcessingCheck from "./MetabaseDashboardImageCaptureProcessingCheck"

const IMAGE_CAPTURE_ERROR_MESSAGE = "Something went wrong.  Exporting failed."

const MODAL_STYLE = {
  content: {
    ...DEFAULT_MODAL_STYLE.content,
    top: "35%",
    overflow: "visible",
  },
}

const ExportMetabaseDashboard = ({ metabaseOrigin }) => {
  const { modalIsOpen, openModal, closeModal } = useModal()

  const [metabaseUrl, setMetabaseUrl] = useState("")
  const [exportInProgress, setExportInProgress] = useState(false)
  const [imageCapture, setImageCapture] = useState({})
  const { id: imageCaptureId, path, processingStatus } = imageCapture

  const updateMetabaseUrl = useCallback((messageEvent) => {
    if (messageEvent.origin !== metabaseOrigin) return

    const { data = {} } = messageEvent
    const { metabase = {} } = data
    const { location = {} } = metabase
    const { href } = location

    if (!href) return

    setMetabaseUrl(href)
  }, [metabaseOrigin, setMetabaseUrl])

  useEffect(() => {
    window.addEventListener("message", updateMetabaseUrl)

    return () => window.removeEventListener("message", updateMetabaseUrl)
  }, [updateMetabaseUrl])

  const close = useCallback(() => {
    setExportInProgress(false)
    setImageCapture({})
  }, [setExportInProgress, setImageCapture])

  useEffect(() => {
    exportInProgress ? openModal() : closeModal()
  }, [exportInProgress, openModal, closeModal])

  useEffect(() => {
    if (processingStatus === "failed") {
      errorToast(IMAGE_CAPTURE_ERROR_MESSAGE)
      close()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processingStatus])

  useEffect(() => {
    if (path) {
      window.open(path, "_self")
      close()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path])

  return (
    <>
      <ExportMetabaseDashboardButton
        metabaseUrl={metabaseUrl}
        exportInProgress={exportInProgress}
        setExportInProgress={setExportInProgress}
        setImageCapture={setImageCapture}
      />
      <Modal isOpen={modalIsOpen} style={MODAL_STYLE}>
        <ModalHeader
          closeModal={close}
          heading="Dashboard Export"
        />
        <MetabaseDashboardImageCaptureProcessingCheck
          imageCaptureId={imageCaptureId}
          onError={close}
          setImageCapture={setImageCapture}
        />
      </Modal>
    </>
  )
}

ExportMetabaseDashboard.propTypes = {
  metabaseOrigin: types.string.isRequired,
}

export default ExportMetabaseDashboard
