import React from "react"
import { adminFacilityDepartmentShape } from "utils/propTypeShapes"
import { useSCIMResourceManagement } from "hooks"
import RemoveMemberDropzone from "./RemoveMemberDropzone"
import DepartmentSegmentList from "./DepartmentSegmentList"

const DepartmentMembers = ({ department }) => {
  const { id: departmentId, segments } = department

  const { departmentsManagedBySCIM } = useSCIMResourceManagement()

  return (
    <div className="w-full">
      <span className="text-xs">Members</span>
      <div className="w-full flex items-start gap-2">
        <DepartmentSegmentList className="grow" departmentId={departmentId} segments={segments} />
        { !departmentsManagedBySCIM && <RemoveMemberDropzone department={department} /> }
      </div>
    </div>

  )
}

DepartmentMembers.propTypes = {
  department: adminFacilityDepartmentShape.isRequired,
}

export default DepartmentMembers
