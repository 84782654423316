import OPERATOR_DATA_PROVIDERS from "../OperatorDataProviders"

const ConditionalCheck = ({ operators, baseCheckComponent }) => (
  operators.reduce((checkComponent, operator) => {
    const higherOrderComponent = OPERATOR_DATA_PROVIDERS[operator]
    return higherOrderComponent ? higherOrderComponent(checkComponent) : checkComponent
  }, baseCheckComponent)
)

export default ConditionalCheck
