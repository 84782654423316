import React from "react"
import types from "prop-types"
import { ACTION_OPTIONS } from "utils/workflowHelpers"
import { IconSelect } from "shared/selects"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const renderContentIcon = ({ icon }) => icon && <SvgSpriteIcon iconName={icon} />
const itemRenderer = ({ item, methods }) => (
  <div
    className="flex items-center py-2.5"
    onClickCapture={() => methods.addItem(item)}
  >
    <SvgSpriteIcon iconName={item.icon} className="mx-2" />
    <span className="text-sm font-medium">{ item.label }</span>
  </div>
)

const SelectActionType = ({ changeActionType, value }) => (
  <IconSelect
    backspaceDelete={false}
    onChange={changeActionType}
    options={ACTION_OPTIONS}
    style={{ height: "32px", minWidth: "200px" }}
    placeholder="Select action... *"
    required
    renderContentIcon={renderContentIcon}
    itemRenderer={itemRenderer}
    value={value}
  />
)

SelectActionType.propTypes = {
  changeActionType: types.func.isRequired,
  value: types.string.isRequired,
}

export default SelectActionType
