import React from "react"
import types from "prop-types"
import ExpandMenu, { ExpandMenuItem } from "shared/ExpandMenu"
import EllipsisIcon from "shared/icons/EllipsisIcon"

const goToViewFacility = (facility) => () => {
  window.location.pathname = `/admin/facilities/${facility.id}`
}

const FacilityMenu = ({ facility }) => (
  <ExpandMenu expandMenuComponent={<EllipsisIcon />}>
    <ExpandMenuItem onClick={goToViewFacility(facility)}>
      Edit
    </ExpandMenuItem>
  </ExpandMenu>
)

FacilityMenu.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  facility: types.object.isRequired,
}

export default FacilityMenu
