import React, { useEffect, useState } from "react"
import types from "prop-types"
import * as API from "services/api"
import { errorToast } from "shared/toast"
import { useSelector } from "react-redux"
import {
  currentUserIsInAdminRole as getCurrentUserIsInAdminRole,
  currentUserIsPhpAdmin as getCurrentUserIsPhpAdmin,
} from "reduxSlices/sessionSlice"
import { REPORT_SFTP_FEATURE_NAME } from "utils/addOnFeatureHelpers"
import { subscribedToAddOnFeature } from "reduxSlices/addOnFeatureSubscriptionsSlice"
import SFTPSettingsContext from "../SFTPSettingsContext"

const SFTPSettingsContextProvider = ({ children }) => {
  const [sftpRemoteServer, setSftpRemoteServer] = useState({})
  const [formIsPristine, setFormIsPristine] = useState(true)
  const currentUserIsInAdminRole = useSelector(getCurrentUserIsInAdminRole)
  const currentUserIsPhpAdmin = useSelector(getCurrentUserIsPhpAdmin)
  const organizationSubscribedToSFTPFeature = useSelector(
    subscribedToAddOnFeature(REPORT_SFTP_FEATURE_NAME),
  )

  const canViewCredentials = organizationSubscribedToSFTPFeature && currentUserIsInAdminRole
  const canEditCredentials = currentUserIsInAdminRole && !currentUserIsPhpAdmin

  useEffect(() => {
    if (!canViewCredentials) return

    const loadSftpRemoteServer = async () => {
      const response = await API.showSftpRemoteServer()

      if (response.ok) {
        setSftpRemoteServer(response.data)
      } else {
        errorToast("We encountered an issue. Please refresh the page.")
      }
    }

    loadSftpRemoteServer()
  }, [])

  // Object exposed to context consumers
  const contextConsumerValue = {
    canEditCredentials,
    canViewCredentials,
    formIsPristine,
    sftpRemoteServer,
    setFormIsPristine,
    setSftpRemoteServer,
  }

  return (
    <SFTPSettingsContext.Provider displayName="SFTP Settings Context" value={contextConsumerValue}>
      {children}
    </SFTPSettingsContext.Provider>
  )
}

SFTPSettingsContextProvider.propTypes = {
  children: types.node.isRequired,
}

export default SFTPSettingsContextProvider
