import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const SearchIcon = ({ className = "w-8 h-8" }) => <SvgSpriteIcon iconName="search" className={className} />

SearchIcon.propTypes = {
  className: types.string,
}

export default SearchIcon
