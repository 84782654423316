import React from "react"
import { Provider } from "react-redux"
import { store } from "store"
import GeneralSettingsTabs from "./GeneralSettingsTabs"

const GeneralSettings = () => (
  <Provider store={store}>
    <GeneralSettingsTabs />
  </Provider>
)

export default GeneralSettings
