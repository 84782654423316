import React from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { useDispatch, useSelector } from "react-redux"
import { getFormQuestionQuestionTagId, setQuestionQuestionTag, getFormQuestionQuestionTagOptions } from "reduxSlices/formBuilderSlice"
import { Select } from "shared/selects"

const mapToOptions = (questionTags) => questionTags.map(({ name, id }) => ({ label: name, value: id }))

const QuestionTagSelect = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const questionQuestionTagId = useSelector(getFormQuestionQuestionTagId({ sectionIndex, questionIndex }))
  const questionQuestionTagOptions = useSelector(getFormQuestionQuestionTagOptions({ sectionIndex, questionIndex }), isEqual)

  const updateDefaultAnswer = (newQuestionTagId) => dispatch(setQuestionQuestionTag({
    sectionIndex, questionIndex, newQuestionTagId,
  }))

  return (
    <Select
      className="text-sm"
      isFullWidth
      clearable
      options={mapToOptions(questionQuestionTagOptions)}
      value={questionQuestionTagId}
      onChange={updateDefaultAnswer}
    />
  )
}

QuestionTagSelect.propTypes = {
  questionIndex: types.number.isRequired,
  sectionIndex: types.number.isRequired,
}

export default QuestionTagSelect
