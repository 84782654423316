/* eslint-disable import/prefer-default-export */
export const ensureDropdownVisible = () => {
  const dropdownList = document.querySelectorAll(".react-dropdown-select-dropdown")

  dropdownList?.forEach((dropdown) => {
    const { offsetTop, parentElement } = dropdown
    dropdown.style.width = "100%"
    if (offsetTop <= 10) {
      const top = parentElement?.offsetHeight || 36
      dropdown.style.top = `${top}px`
    }
  })
}
