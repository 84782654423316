import React from "react"
import types from "prop-types"
import { useTaskFormContext } from "contexts/TaskFormContext"
import FormsyValidation from "shared/FormsyValidation"
import { FormsyHiddenInput } from "shared/inputs"
import TaskMemberDepartmentSelector from "./TaskMemberDepartmentSelector"
import TaskMemberGroupList from "./TaskMemberGroupList"
import TaskMemberGroupSelector from "./TaskMemberGroupSelector"
import TaskMemberList from "./TaskMemberList"
import TaskMemberSelector from "./TaskMemberSelector"
import TaskMemberDepartmentList from "./TaskMemberDepartmentList"

const TaskMembers = ({ actionIndex, className = "" }) => {
  const { taskMemberDepartments } = useTaskFormContext()

  return (
    <div className={className}>
      <span className="block mb-1 font-semibold">Members</span>
      <TaskMemberList className="mb-4" />
      <TaskMemberGroupList className="mb-4" />
      <TaskMemberDepartmentList className="mb-4" />
      <div className="relative max-w-max">
        <div className="flex">
          <TaskMemberSelector className="mr-2" />
          <TaskMemberGroupSelector className="mr-2" />
          <TaskMemberDepartmentSelector />
        </div>
        <FormsyValidation
          name={`actions[${actionIndex}].data.task.members_validation`}
          validations={{
            requireMemberDepartmentSegment: (values) => {
              const departments = values[`actions[${actionIndex}].data.task.member_departments`]

              if (!departments?.length > 0) return true
              if (departments.filter((dept) => !dept.segments?.length > 0).length === 0) return true

              return "at least one department segment must be selected"
            },
          }}
        />
      </div>
      <FormsyHiddenInput
        name={`actions[${actionIndex}].data.task.member_departments`}
        value={taskMemberDepartments ?? []}
      />
    </div>
  )
}

TaskMembers.propTypes = {
  actionIndex: types.number.isRequired,
  className: types.string,
}

export default TaskMembers
