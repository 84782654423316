import React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  deselectAllReportableFieldColumns,
  deselectSubmissionColumn,
  orderedSubmissionColumns,
  reportableFields,
  selectAllReportableFieldColumns,
  selectSubmissionColumn,
} from "reduxSlices/reportBuilderSlice"
import MassSelection from "shared/MassSelection"
import ColumnOption from "../ColumnOption"

const ReportableFieldColumnOptions = () => {
  const dispatch = useDispatch()
  const reportableFieldOptions = useSelector(reportableFields)
  const selectedSubmissionColumns = useSelector(orderedSubmissionColumns)

  const deselectAll = () => dispatch(deselectAllReportableFieldColumns())
  const selectAll = () => dispatch(selectAllReportableFieldColumns())

  const addOption = (column) => dispatch(selectSubmissionColumn(column))
  const removeOption = (column) => dispatch(deselectSubmissionColumn(column))

  return (
    <div>
      <div className="mb-1 text-xl">Reportable Fields</div>
      <MassSelection
        deselectAll={deselectAll}
        selectAll={selectAll}
      />
      <div className="w-full mt-4 space-y-2">
        {
          reportableFieldOptions.map((option) => (
            <ColumnOption
              key={`${option.type}-${option.id}`}
              option={option}
              selectedColumns={selectedSubmissionColumns}
              selectOption={addOption}
              deselectOption={removeOption}
            />
          ))
        }
      </div>
    </div>
  )
}

export default ReportableFieldColumnOptions
