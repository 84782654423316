import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import * as API from "services/api"
import { sortBy } from "lodash-es"
import { errorToast } from "shared/toast"
import { getGroupingMethod } from "views/FormSubmissionList/submissionGroupingHelper"
import {
  createReportableFieldColumns,
  setReportableAnswersOnSubmissions,
} from "views/FormSubmissionList/submissionReportableFieldHelper"
import {
  getDataSortingMethod, DATE_TYPE, FLAGGED_TYPE, FORM_TYPE, STATUS_TYPE, TEXT_TYPE,
} from "shared/Table/TableDataElement/tableDataType"

const SLUG_COLUMN = {
  label: "ID",
  value: "slug",
  sortable: true,
  width: "130px",
  dataType: TEXT_TYPE,
}

const FLAGGED_COLUMN = {
  label: "Flagged",
  value: "important",
  sortable: true,
  width: "50px",
  dataType: FLAGGED_TYPE,
}

const FORM_COLUMN = {
  label: "Form",
  value: "form",
  sortable: true,
  width: "350px",
  dataType: FORM_TYPE,
}

const SUBMISSION_DATE_COLUMN = {
  label: "Submission Date",
  value: "createdAt",
  sortable: true,
  width: "90px",
  dataType: DATE_TYPE,
}

const SUBMISSION_UPDATED_COLUMN = {
  label: "Updated Date",
  value: "updatedAt",
  sortable: true,
  width: "90px",
  dataType: DATE_TYPE,
}

const SURVEY_SEND_DATE_COLUMN = {
  label: "Survey Date",
  value: "batchSentAt",
  sortable: true,
  width: "90px",
  dataType: DATE_TYPE,
}

const STATUS_COLUMN = {
  label: "Status",
  value: "statusName",
  sortable: true,
  width: "150px",
  dataType: STATUS_TYPE,
}

const DEFAULT_COLUMNS = [
  STATUS_COLUMN,
  SLUG_COLUMN,
  FORM_COLUMN,
  SUBMISSION_DATE_COLUMN,
  SUBMISSION_UPDATED_COLUMN,
  SURVEY_SEND_DATE_COLUMN,
  FLAGGED_COLUMN,
]

const DEFAULT_VISIBLE_COLUMNS = [
  STATUS_COLUMN.value,
  SLUG_COLUMN.value,
  FORM_COLUMN.value,
  SUBMISSION_DATE_COLUMN.value,
  SURVEY_SEND_DATE_COLUMN.value,
  FLAGGED_COLUMN.value,
]

const DEFAULT_COLUMN_SORT = {
  column: SLUG_COLUMN.value,
  isAsc: true,
}

const SURVEY_RELATED_COLUMNS = [
  SURVEY_SEND_DATE_COLUMN,
]

const INITIAL_STATE = {
  forms: [],
  formSubmissions: [],
  selectedCategorySlug: undefined,
  categories: [],
  categorySubmissionStatuses: [],
  columns: DEFAULT_COLUMNS,
  columnOrder: DEFAULT_COLUMNS.map((column) => column.value),
  currentSort: DEFAULT_COLUMN_SORT,
  formIdsToHide: [],
  groupBy: STATUS_COLUMN.value,
  showOnlyClosedSubmissions: false,
  showOnlyDeletedSubmissions: false,
  isLoading: true,
  visibleColumns: DEFAULT_VISIBLE_COLUMNS,
  surveyCategories: [],
}

// When switching solutions we need to fetch
//   1.  Form submissions for the new solution
//   2.  Public forms for the new solution
//   3.  Reportable fields for the new solution
//
// Once we've fetched that data, then we update the redux state.
// See the extraReducers passed to createSlice to see how
// loading, fulfilled, and rejected are handled.
export const switchSolution = createAsyncThunk(
  "formSubmissionTable/switchSolution",
  async ({ categorySlug, usedForSurveys }) => {
    if (!categorySlug) {
      return {
        categorySlug: undefined,
        forms: [],
        formSubmissions: [],
        reportableFields: [],
        statusList: [],
      }
    }

    try {
      // 1. Fetch form submissions
      const formSubmissionsResponse = await API.getCategoryFormSubmissions({ categorySlug })
      if (!formSubmissionsResponse.ok) {
        throw new Error("Unable to load form submissions.")
      }

      const formSubmissions = formSubmissionsResponse.data

      // 2. Fetch forms
      let formsResponse
      if (usedForSurveys) {
        formsResponse = await API.categorySurveyForms({ categorySlug })
      } else {
        formsResponse = await API.getCategoryForms({ categorySlug })
      }

      if (!formsResponse.ok) {
        throw new Error("Unable to load forms.")
      }

      const forms = formsResponse.data

      // 3. Fetch reportable fields
      const reportableFieldsResponse = await API.getCategoryReportableFields({ categorySlug })
      if (!reportableFieldsResponse.ok) {
        throw new Error("Unable to load forms.")
      }

      const reportableFields = reportableFieldsResponse.data

      // 4. Fetch the solution's status list
      const statusListResponse = await API.statusList({ categorySlug })
      if (!statusListResponse.ok) {
        throw new Error("Unable to load status list.")
      }

      const statusList = statusListResponse.data

      return {
        categorySlug,
        forms,
        formSubmissions,
        reportableFields,
        statusList,
      }
    } catch (error) {
      throw new Error(`Something went wrong. ${error.message}`)
    }
  },
)

export const formSubmissionTableSlice = createSlice({
  name: "formSubmissionTable",
  initialState: INITIAL_STATE,
  reducers: {
    hideColumn: (state, action) => {
      state.visibleColumns = state.visibleColumns.filter((value) => value !== action.payload)
    },
    hideForm: (state, action) => {
      state.formIdsToHide.push(action.payload)
    },
    reorderColumns: (state, action) => {
      const { startIndex, endIndex } = action.payload

      const orderedColumns = sortBy(state.columns, [(column) => state.columnOrder.indexOf(column.value)])

      const valueToMove = orderedColumns[startIndex].value
      const valueAtDesiredIndex = orderedColumns[endIndex].value

      const desiredIndexInColumnOrder = state.columnOrder.indexOf(valueAtDesiredIndex)

      const newColumnOrder = state.columnOrder.filter((value) => value !== valueToMove)
      newColumnOrder.splice(desiredIndexInColumnOrder, 0, valueToMove)

      state.columnOrder = newColumnOrder
    },
    setCategories: (state, action) => {
      state.categories = action.payload.map(({ name, notClosedSubmissionCount, slug }) => ({
        name: `${name} (${notClosedSubmissionCount})`,
        slug,
        notClosedSubmissionCount,
      }))

      state.surveyCategories = action.payload.reduce((acc, category) => {
        if (category.usedForSurvey) {
          acc.push(category.slug)
        }
        return acc
      }, [])
    },
    setCurrentSort: (state, action) => {
      state.currentSort = action.payload
    },
    setColumnOrder: (state, action) => {
      state.columnOrder = action.payload
    },
    setFormIdsToHide: (state, action) => {
      state.formIdsToHide = action.payload
    },
    setSelectedGrouping: (state, action) => {
      state.groupBy = action.payload
    },
    setShowClosedSubmissions: (state, action) => {
      state.showOnlyClosedSubmissions = action.payload
    },
    setShowDeletedSubmissions: (state, action) => {
      state.showOnlyDeletedSubmissions = action.payload
    },
    setVisibleColumns: (state, action) => {
      state.visibleColumns = action.payload
    },
    showColumn: (state, action) => {
      state.visibleColumns.push(action.payload)
    },
    showForm: (state, action) => {
      state.formIdsToHide = state.formIdsToHide.filter((id) => id !== action.payload)
    },
    sortByColumn: (state, action) => {
      const { column: oldSortColumn, isAsc: currentSortOrder } = state.currentSort
      const { payload: newSortColumn } = action

      state.currentSort = {
        column: newSortColumn,
        isAsc: oldSortColumn === newSortColumn ? !currentSortOrder : true,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(switchSolution.pending, (state) => {
        state.isLoading = true
      })
      .addCase(switchSolution.fulfilled, (state, action) => {
        const {
          categorySlug,
          forms,
          formSubmissions,
          reportableFields,
          statusList,
        } = action.payload

        const attributeColumns = [...DEFAULT_COLUMNS]

        // Don't include survey-related columns if selected category not used for surveys
        if (!state.surveyCategories.includes(categorySlug)) {
          SURVEY_RELATED_COLUMNS.forEach((surveyColumn) => {
            const index = attributeColumns.indexOf(surveyColumn)
            attributeColumns.splice(index, 1)

            // reset sort if current sorting column was removed
            if (state.currentSort.column === surveyColumn.value) {
              state.currentSort = DEFAULT_COLUMN_SORT
            }
          })
        }

        const columns = [...attributeColumns, ...createReportableFieldColumns(reportableFields)]

        state.selectedCategorySlug = categorySlug
        state.categorySubmissionStatuses = statusList.submissionStatuses
        state.forms = forms
        state.formSubmissions = setReportableAnswersOnSubmissions(formSubmissions)
        state.columns = columns

        state.columnOrder = columns.reduce((columnOrder, column) => {
          if (!columnOrder.includes(column.value)) {
            columnOrder.push(column.value)
          }

          return columnOrder
        }, state.columnOrder)

        state.isLoading = false
      })
      .addCase(switchSolution.rejected, (state, action) => {
        errorToast(action.error.message)
        state.isLoading = false
      })
  },
})

export const {
  hideColumn,
  hideForm,
  reorderColumns,
  setCategories,
  setColumnOrder,
  setCurrentSort,
  setFormIdsToHide,
  setSelectedGrouping,
  setShowClosedSubmissions,
  setShowDeletedSubmissions,
  setVisibleColumns,
  showColumn,
  showForm,
  sortByColumn,
} = formSubmissionTableSlice.actions

export const getCurrentSort = (state) => (
  {
    ...state.formSubmissionTable.currentSort,
    column: state.formSubmissionTable.columns.find((column) => (
      column.value === state.formSubmissionTable.currentSort.column
    )),
  }
)

export const getFormSubmissionTable = (state) => state.formSubmissionTable

export const getForms = (state) => state.formSubmissionTable.forms

export const getIsLoading = (state) => state.formSubmissionTable.isLoading

export const getOrderedColumns = (state) => (
  sortBy(
    state.formSubmissionTable.columns,
    [
      (column) => state.formSubmissionTable.columnOrder.indexOf(column.value),
    ],
  )
)

export const getVisibleColumns = (state) => {
  const { columns, columnOrder, visibleColumns } = state.formSubmissionTable

  return sortBy(
    columns.filter((column) => visibleColumns.includes(column.value)),
    [
      (column) => columnOrder.indexOf(column.value),
    ],
  )
}

export const getFilteredSortedGroupedSubmissions = (state) => {
  const {
    categorySubmissionStatuses,
    columns,
    currentSort,
    formIdsToHide,
    formSubmissions,
    groupBy,
    showOnlyClosedSubmissions,
    showOnlyDeletedSubmissions,
  } = state.formSubmissionTable

  let filteredSubmissions = formSubmissions.filter(({ form }) => !formIdsToHide.includes(form.id))

  if (showOnlyClosedSubmissions) {
    filteredSubmissions = filteredSubmissions.filter(({ isClosed }) => isClosed)
  } else if (showOnlyDeletedSubmissions) {
    filteredSubmissions = filteredSubmissions.filter(({ isDeleted }) => isDeleted)
  } else {
    filteredSubmissions = filteredSubmissions.filter(({ isOpen }) => isOpen)
  }

  const sortColumn = columns.find((column) => column.value === currentSort.column)

  const dataSortingMethod = getDataSortingMethod(sortColumn)
  filteredSubmissions = dataSortingMethod(
    filteredSubmissions,
    {
      column: sortColumn,
      isAsc: currentSort.isAsc,
    },
  )

  const groupingMethod = getGroupingMethod({ value: groupBy })
  return groupingMethod(filteredSubmissions, { categorySubmissionStatuses })
}

export default formSubmissionTableSlice.reducer
