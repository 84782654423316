import React from "react"
import { Draggable } from "react-beautiful-dnd"
import { useDispatch, useSelector } from "react-redux"
import {
  getFormSectionUuid, isFocusInSection, moveFormSection, setQuestionFocus,
} from "reduxSlices/formBuilderSlice"
import AddQuestionButton from "FormBuilder/UIElements/AddQuestionButton"
import DragHandle from "shared/DragHandle"
import SectionName from "./SectionName"
import SectionDescription from "./SectionDescription"
import SectionQuestions from "./SectionQuestions"

// The main section is always the first form section
const MAIN_SECTION_INDEX = 0

const MainSection = () => {
  const dispatch = useDispatch()

  const focusInSection = useSelector(isFocusInSection(MAIN_SECTION_INDEX))
  const sectionUuid = useSelector(getFormSectionUuid(MAIN_SECTION_INDEX))

  const setFocus = () => {
    dispatch(setQuestionFocus({ sectionIndex: MAIN_SECTION_INDEX, questionIndex: null }))
  }

  const moveSection = (action) => dispatch(moveFormSection(action))

  const moveSectionDown = () => moveSection({
    from: {
      currentIndex: MAIN_SECTION_INDEX,
    },
    to: {
      newIndex: MAIN_SECTION_INDEX + 1,
    },
  })

  return (
    <Draggable
      key={sectionUuid}
      draggableId={sectionUuid}
      index={MAIN_SECTION_INDEX}
    >
      {
        (dragProvided) => (
          <div
            className="relative mt-10"
            ref={dragProvided.innerRef}
            {...dragProvided.draggableProps}
          >
            <DragHandle
              className="absolute-vertical-center -left-32 bg-white"
              dragProvided={dragProvided}
              moveDown={moveSectionDown}
              isFocused={focusInSection}
            />
            <div className="section-border" />
            <div onClickCapture={setFocus}>
              <SectionName sectionIndex={MAIN_SECTION_INDEX} />
              <SectionDescription sectionIndex={MAIN_SECTION_INDEX} />
            </div>
            <SectionQuestions
              sectionUuid={sectionUuid}
              sectionIndex={MAIN_SECTION_INDEX}
            />
            <AddQuestionButton sectionIndex={MAIN_SECTION_INDEX} />
          </div>
        )
      }
    </Draggable>
  )
}

export default MainSection
