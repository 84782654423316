import React from "react"
import StrikeAround from "components/shared/StrikeAround"
import RelatedFormSubmissionsList from "./RelatedFormSubmissionsList"
import AddRelatedFormSubmission from "./AddRelatedFormSubmission"

const RelatedFormSubmissions = () => (
  <>
    <StrikeAround className="mb-6">
      <h3>Related Form Submissions</h3>
    </StrikeAround>
    <RelatedFormSubmissionsList />
    <AddRelatedFormSubmission />
  </>
)

export default RelatedFormSubmissions
