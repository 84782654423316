import React from "react"
import types from "prop-types"
import { CircularIconButton } from "shared/buttons"
import ItemRow from "shared/ItemRow"
import Switch from "shared/Switch"

const TaskDepartment = ({
  departmentSelection,
  removeFromTask,
  toggleNotify,
  toggleNotifyOnComplete,
  labelComponent: DepartmentLabel,
  segmentsComponent: DepartmentSegments,
}) => (
  <ItemRow
    className="dark"
    itemRowHeaderContentClassName="mb-4"
    itemRowHeaderContent={(
      <>
        <DepartmentLabel className="mb-6 lg:mb-0" departmentOption={departmentSelection} />
        <div className="lg:flex lg:items-center item-row-header-content-breaker">
          <div className="flex items-center justify-between lg:mr-11 mb-4 lg:mb-0">
            <span className="text-sm block lg:hidden">Notify on Assign</span>
            <Switch
              checked={departmentSelection.notify}
              onChange={toggleNotify}
            />
          </div>
          <div className="flex items-center justify-between lg:mr-4">
            <span className="text-sm block lg:hidden">Notify on Complete</span>
            <Switch
              checked={departmentSelection.notifyOnComplete}
              onChange={toggleNotifyOnComplete}
            />
          </div>
        </div>
      </>
    )}
    itemRowHeaderControls={(
      <CircularIconButton icon="close" onClick={removeFromTask} />
    )}
    itemRowBody={(
      <DepartmentSegments department={departmentSelection} />
    )}
  />
)

TaskDepartment.propTypes = {
  removeFromTask: types.func.isRequired,
  toggleNotify: types.func.isRequired,
  toggleNotifyOnComplete: types.func.isRequired,
  departmentSelection: types.object.isRequired,
  labelComponent: types.elementType.isRequired,
  segmentsComponent: types.elementType.isRequired,
}

export default TaskDepartment
