import React, { Component } from "react"
import { isEqual } from "lodash-es"
import { questionBranchingOperatorDataShape, taskShape } from "utils/propTypeShapes"
import { uniqueConditionOperatorsOnWholeForm } from "utils/QuestionBranching"
import OPERATOR_DATA_PROVIDERS from "Forms/FormElements/QuestionBranchingConditionalCheck/OperatorDataProviders"
import TaskQuestionsValidator from "../TaskQuestionsValidator"

const taskCompleteProvider = (WrappedComponent) => {
  const TaskCompleteProvider = ({ operatorData, task, ...rest }) => {
    const { answers, subform } = task
    const taskComplete = new TaskQuestionsValidator(answers, subform, operatorData).isValid()

    return <WrappedComponent task={task} taskComplete={taskComplete} {...rest} />
  }

  TaskCompleteProvider.propTypes = {
    operatorData: questionBranchingOperatorDataShape.isRequired,
    task: taskShape.isRequired,
  }

  return TaskCompleteProvider
}

const buildOperatorDataProvider = (WrappedComponent, operators) => (
  operators.reduce((providerComponent, operator) => {
    const higherOrderComponent = OPERATOR_DATA_PROVIDERS[operator]
    return higherOrderComponent ? higherOrderComponent(providerComponent) : providerComponent
  }, taskCompleteProvider(WrappedComponent))
)

export default (WrappedComponent) => {
  class withTaskComplete extends Component {
    constructor(props) {
      super(props)

      const { task } = props

      this.state = {
        hoc: buildOperatorDataProvider(
          WrappedComponent,
          uniqueConditionOperatorsOnWholeForm({ form: task.subform }),
        ),
      }
    }

    componentDidUpdate(prevProps) {
      const { task } = this.props

      if (!isEqual(prevProps.task, task)) {
        this.setState({
          hoc: buildOperatorDataProvider(
            WrappedComponent,
            uniqueConditionOperatorsOnWholeForm({ form: task.subform }),
          ),
        })
      }
    }

    render() {
      const { hoc: HOC } = this.state

      return <HOC operatorData={{}} {...this.props} />
    }
  }

  withTaskComplete.propTypes = {
    task: taskShape.isRequired,
  }

  return withTaskComplete
}
