import React from "react"
import { useHistoricalAccessToolContext } from "contexts/HistoricalAccessToolContext"
import { yearMonthDayDate } from "utils/dateHelpers"
import { DateInput } from "shared/inputs"

const HistoricalAccessToolSubmissionDateFilter = () => {
  const {
    earliestSubmissionDate,
    latestSubmissionDate,
    setEarliestSubmissionDate,
    setLatestSubmissionDate,
  } = useHistoricalAccessToolContext()

  return (
    <>
      <label>
        <span>Earliest Date:</span>
        <DateInput
          className="mb-4"
          clearable
          max={latestSubmissionDate ? yearMonthDayDate(latestSubmissionDate) : yearMonthDayDate()}
          name="historical_access_tool_earliest_submission_date"
          onChange={setEarliestSubmissionDate}
          value={earliestSubmissionDate}
        />
      </label>
      <label>
        <span>Latest Date:</span>
        <DateInput
          className="mb-4"
          clearable
          min={earliestSubmissionDate ? yearMonthDayDate(earliestSubmissionDate) : null}
          max={yearMonthDayDate()}
          name="historical_access_tool_latest_submission_date"
          onChange={setLatestSubmissionDate}
          value={latestSubmissionDate}
        />
      </label>
    </>
  )
}

export default HistoricalAccessToolSubmissionDateFilter
