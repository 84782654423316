import React from "react"
import types from "prop-types"
import { adminUserShowDepartmentMemberships } from "utils/propTypeShapes"
import { CustomHeaderAccordion, ExpansionControl, useAccordion } from "shared/accordions"
import DepartmentSpan from "./DepartmentSpan"

const FacilitiesWithDepartmentMemberships = ({
  user, departmentMemberships, facility, removeDepartmentMembership, updateDepartmentMembershipSegment,
}) => {
  const [isExpanded, toggleAccordion] = useAccordion(departmentMemberships.length > 0)

  return (
    <div className="list-row block bg-white">
      <CustomHeaderAccordion
        isExpanded={isExpanded}
        header={(
          <div className="flex-center">
            {facility.name}
            <ExpansionControl toggleAccordion={toggleAccordion} isExpanded={isExpanded} />
          </div>
        )}
        content={(
          <>
            <div className="py-5">
              {
                departmentMemberships.map((departmentMembership) => (
                  <DepartmentSpan
                    key={departmentMembership.id}
                    user={user}
                    departmentMembership={departmentMembership}
                    removeDepartmentMembership={removeDepartmentMembership}
                    updateDepartmentMembershipSegment={updateDepartmentMembershipSegment}
                  />
                ))
              }
            </div>
          </>
        )}
      />
    </div>
  )
}

FacilitiesWithDepartmentMemberships.propTypes = {
  user: types.shape({
    id: types.number,
  }).isRequired,
  departmentMemberships: adminUserShowDepartmentMemberships.isRequired,
  facility: types.shape({
    id: types.number,
    name: types.string,
  }).isRequired,
  removeDepartmentMembership: types.func.isRequired,
  updateDepartmentMembershipSegment: types.func.isRequired,
}

export default FacilitiesWithDepartmentMemberships
