import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const SingleUserIcon = ({ className = "w-8 h-8", ...rest }) => (
  <SvgSpriteIcon iconName="single-user" className={className} {...rest} />
)

SingleUserIcon.propTypes = {
  className: types.string,
}

export default SingleUserIcon
