import React, { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { moveSubmissionColumn, orderedSubmissionColumns } from "reduxSlices/reportBuilderSlice"
import { DraggableColumnTypes } from "../DragAndDrop/DraggableColumnTypes"
import SelectedColumn from "../SelectedColumn"
import useScrollToAdded from "../DragAndDrop/useScrollToAdded"
import SelectionDropZone from "../DragAndDrop/SelectionDropZone"

const SubmissionRelatedColumnSelections = () => {
  const dispatch = useDispatch()
  const ref = useRef(null)
  const selections = useSelector(orderedSubmissionColumns)

  useScrollToAdded(ref, selections)

  const moveColumn = (column, toIndex) => (
    dispatch(moveSubmissionColumn({ column, toIndex }))
  )

  return (
    <SelectionDropZone
      acceptableTypes={DraggableColumnTypes.SUBMISSION_RELATED_SELECTION}
      ref={ref}
    >
      {
        selections.map((column, index) => (
          <SelectedColumn
            key={`${column.type}-${column.id}`}
            column={{ ...column, index }}
            moveColumn={moveColumn}
          />
        ))
      }
    </SelectionDropZone>
  )
}

export default SubmissionRelatedColumnSelections
