import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { useSurveyBatchContext } from "contexts/SurveyBatchContext"
import SurveyBatchStat from "./SurveyBatchStat"

const SurveyBatchStatistics = ({ className = "" }) => {
  const {
    bouncedCount, expectedSubmissionsCount, submissionCount, submissionRate,
  } = useSurveyBatchContext()

  return (
    <div className={clsx(className, "flex gap-8 w-full")}>
      <SurveyBatchStat className="flex-grow" stat={expectedSubmissionsCount} text="Contacts" />
      <SurveyBatchStat className="flex-grow" stat={bouncedCount} text="Bounced" error={bouncedCount > 0} />
      <SurveyBatchStat
        className="flex-grow"
        stat={`${submissionCount} (${submissionRate}%)`}
        text="Submitted"
        success={submissionRate === 100}
      />
    </div>
  )
}

SurveyBatchStatistics.propTypes = {
  className: types.string,
}

export default SurveyBatchStatistics
