import React from "react"
import types from "prop-types"

const Tab = ({ children }) => (
  <div>
    {children}
  </div>
)

/* eslint-disable react/no-unused-prop-types */
// These properties (except for children) are used by the Tabs component to render
// its list of tabs.
Tab.propTypes = {
  children: types.node.isRequired,
  title: types.string.isRequired,
}
/* eslint-enable react/no-unused-prop-types */
export default Tab
