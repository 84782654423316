import React from "react"
import clsx from "clsx"
import { auditShape } from "utils/propTypeShapes"
import LocationDataElement from "shared/Table/TableDataElement/LocationDataElement"
import { AuditedUser } from "shared/audits"
import { formatAnswer } from "../ChangeInAnswer"

const originalForm = (audit) => audit.auditedChanges.form.from
const newForm = (audit) => audit.auditedChanges.form.to
const retainedAnswers = (audit) => audit.auditedChanges.retained_answers
const unretainedAnswers = (audit) => audit.auditedChanges.unretained_answers

const originalPrompt = (answer) => answer.original_prompt
const newPrompt = (answer) => answer.new_prompt
const value = (answer) => {
  if (answer.value.department_id) return <LocationDataElement value={answer.value} />

  return formatAnswer(answer.value)
}

const FormSubmissionFormTypeChangedAuditItemDetails = ({ audit }) => {
  const hasRetainedAnswers = retainedAnswers(audit).length > 0
  const hasUnretainedAnswers = unretainedAnswers(audit).length > 0

  return (
    <div>
      <p className="mb-6">
        <AuditedUser audit={audit} /> {audit.comment}.
      </p>
      <p className={clsx((hasRetainedAnswers || hasUnretainedAnswers) && "mb-6")}>
        The form type was changed from {originalForm(audit)} to {newForm(audit)}.
      </p>
      {
        hasRetainedAnswers && (
          <div className={clsx(hasUnretainedAnswers && "mb-6")}>
            <span className="block mb-1">
              The following answers were retained:
            </span>
            <ul className="m-0 p-0">
              {
                retainedAnswers(audit).map((retainedAnswer) => (
                  <li className="flex flex-col mb-2" key={originalPrompt(retainedAnswer)}>
                    <span>Original Question: {originalPrompt(retainedAnswer)}</span>
                    <span>New Question: {newPrompt(retainedAnswer)}</span>
                    <span>Answer: {value(retainedAnswer)}</span>
                  </li>
                ))
              }
            </ul>
          </div>
        )
      }
      {
        hasUnretainedAnswers && (
          <div>
            <span className="block mb-1">
              The following answers were not retained:
            </span>
            <ul className="m-0 p-0">
              {
                unretainedAnswers(audit).map((unretainedAnswer) => (
                  <li className="flex flex-col mb-2" key={originalPrompt(unretainedAnswer)}>
                    <span>Original Question: {originalPrompt(unretainedAnswer)}</span>
                    <span>Answer: {value(unretainedAnswer)}</span>
                  </li>
                ))
              }
            </ul>
          </div>
        )
      }

    </div>
  )
}

FormSubmissionFormTypeChangedAuditItemDetails.propTypes = {
  audit: auditShape.isRequired,
}

export default FormSubmissionFormTypeChangedAuditItemDetails
