import React, { Component, createRef } from "react"
import types from "prop-types"
import Formsy from "formsy-react"
import { connect } from "react-redux"
import * as API from "services/api"
import { addTask } from "reduxSlices/formSubmissionSlice"
import { TaskFormContextProvider } from "contexts/TaskFormContext"
import CircularIconButton from "shared/buttons/CircularIconButton"
import { errorToast, successToast } from "shared/toast"
import ItemRow from "shared/ItemRow"
import { CancelButton, SaveTaskButton } from "./TaskButtons"
import * as TaskFields from "./TaskFields"

class NewTaskForm extends Component {
  state = { canSubmit: false }

  formRef = createRef()

  itemRowRef = createRef()

  componentDidUpdate(prevProp) {
    const { taskFormIsOpen } = this.props

    if (taskFormIsOpen && prevProp.taskFormIsOpen !== taskFormIsOpen && this.itemRowRef.current) {
      this.itemRowRef.current.scrollIntoView({ offset: 500 })
    }
  }

  enableSubmit = () => this.setState({ canSubmit: true })

  disableSubmit = () => this.setState({ canSubmit: false })

  currentTaskData = () => this.formRef.current?.getModel() || { task: {} }

  cancel = () => {
    const { onCancel } = this.props

    this.formRef.current?.reset()
    if (onCancel) onCancel()
  }

  createTask = async (model) => {
    const { canSubmit } = this.state

    if (!canSubmit) return

    const {
      addTask: addNewTask,
      formSubmissionSlug,
      onCreate,
    } = this.props

    const createTaskResponse = await API.createTask({ formSubmissionSlug, model })

    if (!createTaskResponse.ok) {
      errorToast("Something went wrong. Unable to create task.")
      console.error("ERROR CREATING TASK: ", createTaskResponse.data)
      return
    }

    const { data: newTask } = createTaskResponse

    addNewTask(newTask)
    this.formRef.current?.reset()
    if (onCreate) onCreate()
    successToast("Task created successfully!")
  }

  render() {
    const { onCancel, taskFormIsOpen } = this.props

    if (!taskFormIsOpen) return null

    return (
      <TaskFormContextProvider>
        <ItemRow
          ref={this.itemRowRef}
          itemRowHeaderContent={(
            <h3 className="m-0">New Task</h3>
          )}
          itemRowHeaderControls={(
            <CircularIconButton icon="close" onClick={onCancel} />
          )}
          itemRowBody={(
            <Formsy
              id="task-form"
              onValidSubmit={this.createTask}
              onValid={this.enableSubmit}
              onInvalid={this.disableSubmit}
              ref={this.formRef}
            >
              <TaskFields.Title className="mb-8" />
              <TaskFields.Description className="mb-8" />
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-8 mb-8">
                <TaskFields.DueDate className="w-full" />
                <TaskFields.Subform />
              </div>
              <TaskFields.NotifyGroupIds />
              <TaskFields.NotifyOnCompleteGroupIds />
              <TaskFields.NotifyIds />
              <TaskFields.NotifyOnCompleteIds />
              <TaskFields.Assignees className="mb-8" />
              <TaskFields.Members className="mb-8" />
              <TaskFields.OtherTaskAccess className="mb-12" />
              <div className="flex flex-col items-center mb-8">
                <div>
                  <CancelButton cancel={this.cancel} />
                  <SaveTaskButton text="Add this Task" />
                </div>
              </div>
            </Formsy>
          )}
        />
      </TaskFormContextProvider>
    )
  }
}

NewTaskForm.defaultProps = {
  onCancel: undefined,
  onCreate: undefined,
  formSubmissionSlug: "",
}

NewTaskForm.propTypes = {
  addTask: types.func.isRequired,
  taskFormIsOpen: types.bool.isRequired,
  formSubmissionSlug: types.string,
  onCancel: types.func,
  onCreate: types.func,
}

const mapStateToProps = (state) => (
  {
    formSubmissionSlug: state.formSubmission.data.slug,
  }
)

export default connect(mapStateToProps, { addTask })(NewTaskForm)
