import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { DrawerIcon } from "shared/icons"

const DrawerToggle = ({ className = "", isOpen, toggleDrawer }) => (
  <span
    className={clsx(className, "flex justify-center items-center h-9 w-9 border border-lightgray-3 rounded-md bg-white cursor-pointer")}
    onClickCapture={toggleDrawer}
  >
    <DrawerIcon isOpen={isOpen} className="shrink-0 h-5 w-5" />
  </span>
)

DrawerToggle.propTypes = {
  className: types.string,
  isOpen: types.bool.isRequired,
  toggleDrawer: types.func.isRequired,
}

export default DrawerToggle
