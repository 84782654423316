import { Parser } from "html-to-react"

export default (rawHtml) => {
  try {
    const htmlToReactParser = new Parser()
    const reactElement = htmlToReactParser.parse(`${rawHtml}`)

    return reactElement
  } catch (error) {
    console.error(error)
    return null
  }
}
