import { useEffect } from "react"
import types from "prop-types"
import { store } from "store"
import { setFeatures } from "reduxSlices/featuresSlice"

const SetupFeaturesInRedux = ({ overview }) => {
  useEffect(() => {
    store.dispatch(setFeatures(overview))
  }, [overview])

  return null
}

SetupFeaturesInRedux.propTypes = {
  overview: types.object.isRequired,
}

export default SetupFeaturesInRedux
