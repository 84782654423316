/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from "react"
import types from "prop-types"
import clsx from "clsx"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import { useOnClickOutside } from "hooks"

// This is used for tabs that contains Formsy components. Formsy fields
// needs to be on the DOM, but hidden to work correctly

const isActive = (index, activeIndex) => (index === activeIndex)

const TabContents = ({ activeIndex, tabs }) => (
  tabs.map(({ title, TabContent, props = {} }, index) => (
    <div key={title} className={clsx(!isActive(index, activeIndex) && "hidden")}>
      <TabContent {...props} />
    </div>
  )))

const HiddenTabs = ({ className = "", tabs, initialActiveIndex = 0 }) => {
  const [activeIndex, setActiveIndex] = useState(initialActiveIndex)
  const tabRef = useRef([])

  const [tabNavOpen, setTabNavOpen] = useState(false)
  const openTabNav = () => setTabNavOpen(true)
  const closeTabNav = () => setTabNavOpen(false)

  const tabNavRef = useRef()
  useOnClickOutside(tabNavRef, closeTabNav)

  const activeTab = tabs[activeIndex]

  return (
    <>
      <div className="tab-nav-container">
        <div className="lg:hidden text-sm font-medium text-dark mb-1">
          <span>Navigation</span>
        </div>
        <svg className="tab-nav-icon"><use xlinkHref={tabNavOpen ? "#chevron-up" : "#chevron-down"} /></svg>
        <ul ref={tabNavRef} className={clsx(className, "tab-nav", tabNavOpen && "open")} onClick={tabNavOpen ? closeTabNav : openTabNav}>
          {
            tabs.map(({ iconName, title }, index) => (
              <li
                key={title}
                ref={(el) => {
                  tabRef.current[index] = el
                }}
                onClickCapture={() => setActiveIndex(index)}
                className={clsx(isActive(index, activeIndex) && "active", "tab-link")}
              >
                {
                  (iconName) && (
                    <SvgSpriteIcon iconName={iconName} className="mr-2" />
                  )
                }
                { title }
              </li>
            ))
          }
        </ul>
        {
          activeTab.additionalControls && (
            <div className="absolute right-4 z-30 lg:p-2.5">
              {activeTab.additionalControls}
            </div>
          )
        }
      </div>

      <TabContents tabs={tabs} activeIndex={activeIndex} />
    </>
  )
}

HiddenTabs.propTypes = {
  className: types.string,
  tabs: types.arrayOf(types.object).isRequired,
  initialActiveIndex: types.number,
}

export default HiddenTabs
