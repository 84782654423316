/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { currentUserIsPhpAdmin } from "reduxSlices/sessionSlice"
import { categoryShape } from "utils/propTypeShapes"
import { FormsyTextInput } from "shared/inputs"

const CategoryName = ({ category, className = "" }) => {
  const userMayEditCategoryName = useSelector(currentUserIsPhpAdmin)

  return (
    <div className={className}>
      <label className="field-name">Name</label>
      {
        userMayEditCategoryName
          ? (
            <FormsyTextInput
              className="bordered-base-input text-sm"
              name="name"
              placeholder="Name"
              value={category.name}
              required
              validations={{ isNotBlankString: true }}
              validationErrors={{ isDefaultRequiredValue: "required" }}
            />
          )
          : (
            <p className="text-sm">{category.name}</p>
          )
      }
    </div>
  )
}

CategoryName.propTypes = {
  category: categoryShape.isRequired,
  className: types.string,
}

export default CategoryName
