import React from "react"
import types from "prop-types"
import Sidebar from "shared/Sidebar"
import BatchStatusFilter from "./BatchStatusFilter"
import BatchFormsFilter from "./BatchFormsFilter"

const BatchesSidebar = ({ hiddenSidebar }) => (
  <Sidebar hiddenSidebar={hiddenSidebar}>
    <BatchStatusFilter />
    <BatchFormsFilter />
  </Sidebar>
)

BatchesSidebar.propTypes = {
  hiddenSidebar: types.bool.isRequired,
}

export default BatchesSidebar
