import React from "react"
import types from "prop-types"
import { UserCountBadge } from "components/shared/badges"

const UsersDataElement = ({ value, rowData, rowHrefFunc = undefined }) => {
  if (value.length === 0) return null

  return (
    <a className="text-dark-900" href={rowHrefFunc(rowData)}>
      <UserCountBadge count={value.length} userNames={value} />
    </a>
  )
}

UsersDataElement.propTypes = {
  rowData: types.object.isRequired,
  rowHrefFunc: types.func,
  value: types.arrayOf(types.string).isRequired,
}

export default UsersDataElement
