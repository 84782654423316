import React from "react"
import types from "prop-types"
import * as NumberAttributes from "./NumberAttributes"
import QuestionRequired from "./QuestionRequired"
import QuestionShowDescription from "./QuestionShowDescription"

const NumberInputConfig = ({ sectionIndex, questionIndex }) => (
  <>
    <QuestionRequired
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <QuestionShowDescription
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <NumberAttributes.IsDropdown
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <NumberAttributes.Min
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <NumberAttributes.Max
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <NumberAttributes.Precision
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
  </>
)

NumberInputConfig.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default NumberInputConfig
