import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { currentUserId as getCurrentUserId, currentUserIsInAdminRole as getCurrentUserIsInAdminRole } from "reduxSlices/sessionSlice";
import { getTasks } from "reduxSlices/formSubmissionSlice"
import { useTaskFormContext } from "contexts/TaskFormContext"
import { FormsyHiddenInput } from "shared/inputs"
import MassSelection from "components/shared/MassSelection";
import OtherTaskAccessCheckbox from "./OtherTaskAccessCheckbox"

const tasksExceptCurrent = (tasks, taskId) => tasks.filter((task) => task.id !== taskId)

const isRelatedTaskUser = (task, userId) => task.assigneeIds.includes(userId) || task.memberIds.includes(userId)

const userAccessibleTasks = (tasks, isAdmin, userId) => (
  isAdmin
    ? tasks
    : tasks.filter((task) => isRelatedTaskUser(task, userId))
)

const TaskOtherTaskAccess = ({ className }) => {
  const currentUserId = useSelector(getCurrentUserId)
  const currentUserIsInAdminRole = useSelector(getCurrentUserIsInAdminRole)
  const tasks = useSelector(getTasks)

  const { taskId, taskManageAccessTaskIds, updateManageAccessTaskIds } = useTaskFormContext()

  const accessibleTasks = userAccessibleTasks(tasks, currentUserIsInAdminRole, currentUserId)
  const otherTasks = tasksExceptCurrent(accessibleTasks, taskId)

  const addAccessToAllTasks = () => updateManageAccessTaskIds(otherTasks.map((task) => task.id))
  const removeAccessToAllTasks = () => updateManageAccessTaskIds([])

  return (
    <div className={className}>
      <div className="mb-4">
        <span className="font-semibold text-left mb-1">Access to Other Tasks</span>
        <p className="text-sm">
          {
            otherTasks.length > 0
              ? "Assignees and members of this task will have access to selected tasks."
              : "There are no tasks that you may make accessible to assignees and members."
          }
        </p>
      </div>
      {
        otherTasks.length > 0 && (
          <>
            <div className="mb-4">
              {
                otherTasks.map((task) => <OtherTaskAccessCheckbox key={task.id} task={task} />)
              }
            </div>
            <MassSelection selectAll={addAccessToAllTasks} deselectAll={removeAccessToAllTasks} />
          </>
        )
      }
      <FormsyHiddenInput name="task.manage_access_task_ids" value={taskManageAccessTaskIds} />
    </div>
  )
}

TaskOtherTaskAccess.defaultProps = {
  className: "",
}

TaskOtherTaskAccess.propTypes = {
  className: types.string,
}

export default TaskOtherTaskAccess
