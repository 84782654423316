import React from "react"
import types from "prop-types"
import Tooltip from "components/shared/Tooltip"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const ContactInfoHeader = ({ contact }) => (
  <>
    <div className="flex-grow flex-center mr-2">
      <span className="pr-4">
        {
          (contact.lastName || contact.firstName)
          && `${contact.lastName || ""}, ${contact.firstName || ""}`
        }
      </span>
      <span className="break-all font-normal">
        {contact.email}
      </span>
    </div>

    <div className="w-4 mr-4">
      {
        contact.emailSuppressed
        && (
          <Tooltip text={"We've been unable to deliver emails to this address."}>
            <SvgSpriteIcon iconName="warning-icon" />
          </Tooltip>
        )
      }
    </div>
  </>
)

ContactInfoHeader.propTypes = {
  contact: types.object.isRequired,
}

export default ContactInfoHeader
