import React, { useCallback } from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { Select } from "shared/selects"
import {
  getFormQuestionAttributeCalcQuestionUuidsCount,
  getFormQuestionAttributeOperator,
  setQuestionAttributeOperator,
} from "reduxSlices/formBuilderSlice"
import {
  ADD_OPERATOR,
  DIVIDE_OPERATOR,
  MAX_QUESTION_COUNT_FOR_NUMBER_CALCULATION_NON_ADDITION_OPERATIONS,
  SUBTRACT_OPERATOR,
} from "utils/calculationOperators"

const ADDITION_OPTION = { label: "ADDITION", value: ADD_OPERATOR }
const SUBTRACTION_OPTION = { label: "SUBTRACTION", value: SUBTRACT_OPERATOR }
const DIVISION_OPTION = { label: "DIVISION", value: DIVIDE_OPERATOR }

const ALL_OPTIONS = [ADDITION_OPTION, SUBTRACTION_OPTION, DIVISION_OPTION]
const ONLY_ADDITION_OPTIONS = [ADDITION_OPTION]

// If there are more than two number questions selected for the number calculation,
// then only the addition operator is supported.
const operatorOptions = (questionsLength) => (
  questionsLength > MAX_QUESTION_COUNT_FOR_NUMBER_CALCULATION_NON_ADDITION_OPERATIONS ? ONLY_ADDITION_OPTIONS : ALL_OPTIONS
)

const Operator = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const calcQuestionsCount = useSelector(getFormQuestionAttributeCalcQuestionUuidsCount({ sectionIndex, questionIndex }))
  const questionOperator = useSelector(getFormQuestionAttributeOperator({ sectionIndex, questionIndex }))

  const updateOperator = useCallback((newOperator) => {
    if (newOperator !== questionOperator) {
      dispatch(setQuestionAttributeOperator({
        sectionIndex, questionIndex, newOperator,
      }))
    }
  }, [sectionIndex, questionIndex, questionOperator, dispatch])

  return (
    <div className="flex-center mb-4">
      <div className="font-medium">Operation</div>
      <Select
        className="text-sm"
        style={{ height: "32px" }}
        options={operatorOptions(calcQuestionsCount)}
        value={questionOperator}
        onChange={updateOperator}
      />
    </div>
  )
}

Operator.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default Operator
