import React from "react"
import types from "prop-types"
import { questionShape } from "utils/propTypeShapes"
import SubmissionEditQuestion from "./SubmissionEditQuestion"

const SubmissionEditQuestions = ({ questions, formAnswers }) => (
  questions.map((question) => (
    <SubmissionEditQuestion
      key={question.uuid}
      question={question}
      formAnswers={formAnswers}
    />
  ))
)

SubmissionEditQuestions.propTypes = {
  questions: types.arrayOf(questionShape).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formAnswers: types.object.isRequired,
}

export default SubmissionEditQuestions
