import React from "react"
import types from "prop-types"
import FormWithWorkflows from "./FormWithWorkflows"
import EmptyWorkflows from "./EmptyWorkflows"

const groupWorkflowsByForm = (workflows) => (
  workflows.reduce((forms, workflow) => {
    const { form } = workflow
    const { id: formId } = form

    if (!forms[formId]) {
      forms[formId] = { form, workflows: [] }
    }

    forms[formId].workflows.push(workflow)
    return forms
  }, {})
)

const WorkflowsTab = ({ associatedWorkflows, groupOrUser }) => {
  if (associatedWorkflows.length === 0) {
    return <EmptyWorkflows groupOrUser={groupOrUser} />
  }

  const forms = groupWorkflowsByForm(associatedWorkflows)

  return (
    <>
      <h2 className="tab-title">Associated Workflows</h2>
      {
        Object.entries(forms).map(([formId, { form, workflows }]) => (
          <FormWithWorkflows
            key={`form-id-${formId}`}
            form={form}
            workflows={workflows}
            groupOrUser={groupOrUser}
          />
        ))
      }
    </>
  )
}

WorkflowsTab.propTypes = {
  associatedWorkflows: types.arrayOf(types.object).isRequired,
  groupOrUser: types.oneOf(["group", "user"]).isRequired,
}

export default WorkflowsTab
