import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"

const DepartmentMembershipDestroyed = ({ audit }) => (
  <div>
    <span>
      <AuditedUser audit={audit} /> removed member <span className="font-semibold">{audit.departmentMember.fullName} </span>
      from department <span className="font-semibold">{audit.department.name}</span>
    </span>
  </div>
)

DepartmentMembershipDestroyed.propTypes = {
  audit: auditShape.isRequired,
}

export default DepartmentMembershipDestroyed
