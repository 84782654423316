import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { getTaskTable } from "reduxSlices/taskTableSlice"
import TaskCategoryCheckbox from "./TaskCategoryCheckbox"

const TaskCategoryList = () => {
  const { tasks } = useSelector(getTaskTable)

  const uniqueCategories = useMemo(() => {
    const seenIds = new Set()

    return tasks.reduce((categories, { category }) => {
      if (!seenIds.has(category.id)) {
        categories.push(category)
        seenIds.add(category.id)
      }

      return categories
    }, [])
  }, [tasks])

  if (!uniqueCategories.length) return <span>You must be an assignee or member of a task to filter by solution</span>

  return (
    <div>
      {uniqueCategories.map((category) => (
        <div className="sidebar-border-wrap flex justify-between w-full last:mb-0" key={category.id}>
          <div key={`${category.id}-div`}>
            {category.name}
          </div>
          <TaskCategoryCheckbox
            key={category.id}
            category={category}
          />
        </div>

      ))}
    </div>
  )
}

export default TaskCategoryList
