import React from "react"
import types from "prop-types"
import { Button } from "shared/buttons"

const AddFacilityGroupCodeButton = ({ onClick }) => (
  <Button
    className="border border-light-300 text-focus"
    text="+ Add Facility Group Code"
    onClick={onClick}
  />
)

AddFacilityGroupCodeButton.propTypes = {
  onClick: types.func.isRequired,
}

export default AddFacilityGroupCodeButton
