import React from "react"
import types from "prop-types"
import { FormsyUserRoleSelect } from "shared/selects"

const UserRole = ({ user }) => (
  <>
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label>Role</label>
    <FormsyUserRoleSelect
      isFullWidth
      name="role"
      placeholder="Role"
      value={user.role || ""}
    />
  </>
)

UserRole.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: types.object.isRequired,
}

export default UserRole
