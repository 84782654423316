import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { departmentSegmentShape } from "utils/propTypeShapes"
import DepartmentSegment from "./DepartmentSegment"

const DepartmentSegmentList = ({ className = "", departmentId, segments }) => (
  <div className={clsx(className, "flex flex-col gap-2")}>
    {
      segments.map((segment) => (
        <DepartmentSegment
          key={segment.name}
          className="p-2 border rounded-md border-light"
          departmentId={departmentId}
          segment={segment}
        />
      ))
    }
  </div>
)

DepartmentSegmentList.propTypes = {
  className: types.string,
  departmentId: types.number.isRequired,
  segments: types.arrayOf(departmentSegmentShape).isRequired,
}

export default DepartmentSegmentList
