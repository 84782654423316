import React from "react"
import { withFormsy } from "formsy-react"
import types from "prop-types"
import Select from "./Select"

// Values must match roles in User model
const OPTIONS = [
  { label: "Admin", value: "admin" },
  { label: "Non Admin", value: "non_admin" },
]

const UserRoleSelect = ({ onChange, value = null, ...props }) => (
  <Select
    onChange={onChange}
    options={OPTIONS}
    value={value}
    required
    validations={{ isNotBlankString: true }}
    validationErrors={{ isDefaultRequiredValue: "required" }}
    {...props}
  />
)

UserRoleSelect.propTypes = {
  onChange: types.func.isRequired,
  value: types.string,
}

export const FormsyUserRoleSelect = withFormsy(({ setValue, ...rest }) => (
  <UserRoleSelect {...rest} onChange={setValue} />
))

FormsyUserRoleSelect.displayName = "FormsyUserRoleSelect"

export default UserRoleSelect
