import React from "react"
import types from "prop-types"
import { formShape } from "utils/propTypeShapes"
import { CustomHeaderAccordion, ExpansionControl, useAccordion } from "shared/accordions"
import { FormIcon } from "shared/icons"
import TaskFormsIcon from "shared/icons/TaskFormsIcon"
import UserAndGroupAssociations from "./UserAndGroupAssociations"

const FormWithWorkflows = ({ form, groupOrUser, workflows }) => {
  const [isExpanded, toggleAccordion] = useAccordion(true)

  return (
    <div className="list-row block bg-white">
      <CustomHeaderAccordion
        isExpanded={isExpanded}
        header={(
          <div className="flex-center">
            {
              form.icon
                ? <FormIcon className="mr-3 w-8 h-8" form={form} />
                : <TaskFormsIcon className="mr-3 w-8 h-8 task-form-icon" />
            }
            <span className="flex-grow">{form.title}</span>
            <ExpansionControl
              isDisabled={false}
              isExpanded={isExpanded}
              toggleAccordion={toggleAccordion}
            />
          </div>
        )}
        content={(
          <>
            <div className="py-5" />
            {
              workflows.map((workflow) => (
                <div
                  key={`form-workflow-${workflow.id}`}
                  className="flex justify-between mb-1 list-row border-0 py-3 bg-light-100"
                >
                  <p>{workflow.name}</p>
                  {
                    groupOrUser === "user"
                    && <UserAndGroupAssociations workflow={workflow} />
                  }
                </div>
              ))
            }
          </>
        )}
      />
    </div>
  )
}

FormWithWorkflows.propTypes = {
  form: formShape.isRequired,
  groupOrUser: types.oneOf(["group", "user"]).isRequired,
  workflows: types.arrayOf(types.object).isRequired,
}

export default FormWithWorkflows
