import React from "react"
import types from "prop-types"
import EditSurveyButton from "./EditSurveyButton"

const SettingsTitle = ({ editMode, openEditMode, heading }) => (
  <div className="flex">
    <h2 className="tab-title">
      { heading }
    </h2>
    {
      !editMode
          && <EditSurveyButton onClick={openEditMode} className="mt-4 ml-4" />
    }
  </div>
)

SettingsTitle.propTypes = {
  editMode: types.bool.isRequired,
  openEditMode: types.func.isRequired,
  heading: types.string.isRequired,
}

export default SettingsTitle
