import React from "react"
import types from "prop-types"
import clsx from "clsx"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const LightXIcon = ({
  className = "", width = undefined, height = undefined, onClick = undefined,
}) => (
  <div className="rounded-full h-8 w-8 flex items-center justify-center border border-white absolute right-5 mt-3">
    <SvgSpriteIcon
      className={clsx(className, "text-light fill-current stroke-current")}
      iconName="x"
      width={width}
      height={height}
      onClick={onClick}
    />
  </div>
)

LightXIcon.propTypes = {
  className: types.string,
  width: types.number,
  height: types.number,
  onClick: types.func,
}

export default LightXIcon
