import React, { useCallback } from "react"
import Formsy from "formsy-react"
import types from "prop-types"
import clsx from "clsx"
import { useSubmit } from "hooks"
import * as API from "services/api"
import { Button, PrimaryButton } from "shared/buttons"
import { errorToast, successToast } from "components/shared/toast"
import FacilityGroupCodeFormFields from "./FacilityGroupCodeFormFields"

const CREATION_SUCCESSFUL_MESSAGE = "Facility group code created successfully!"
const CREATION_FAILURE_MESSAGE = "Something went wrong. Unable to add facility group code."

const NewFacilityGroupCodeForm = ({
  addFacilityGroupCode,
  className = "",
  onCancel,
  facilityGroupCodeNames,
}) => {
  const { canSubmit, enableSubmit, disableSubmit } = useSubmit(false)
  const createFacilityGroupCode = useCallback(async (formData) => {
    const response = await API.createFacilityGroupCode(formData)

    if (response.ok) {
      successToast(CREATION_SUCCESSFUL_MESSAGE)
      addFacilityGroupCode(response.data)
      onCancel()
    } else {
      errorToast(CREATION_FAILURE_MESSAGE)
    }
  }, [addFacilityGroupCode, onCancel])

  return (
    <Formsy
      className={clsx(className)}
      onInvalid={disableSubmit}
      onInvalidSubmit={null}
      onValid={enableSubmit}
      onValidSubmit={createFacilityGroupCode}
    >
      <FacilityGroupCodeFormFields
        className={canSubmit ? "mb-8" : "mb-16"}
        facilityGroupCodeNames={facilityGroupCodeNames}
      />
      <div className="flex justify-center">
        <PrimaryButton
          className="mr-4"
          disabled={!canSubmit}
          text="Add Facility Group Code"
          type="submit"
        />
        <Button
          className="bg-white border border-lightgray-3"
          text="Cancel"
          onClick={onCancel}
        />
      </div>
    </Formsy>
  )
}

NewFacilityGroupCodeForm.propTypes = {
  addFacilityGroupCode: types.func.isRequired,
  className: types.string,
  onCancel: types.func.isRequired,
  facilityGroupCodeNames: types.arrayOf(types.string).isRequired,
}

export default NewFacilityGroupCodeForm
