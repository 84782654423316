import React from "react"
import types from "prop-types"
import { useSCIMResourceManagement } from "hooks"
import Switch from "shared/Switch"
import ContentBar from "shared/lists/ContentBar"
import * as API from "services/api"

const DEACTIVATE_CONFIRMATION_PROMPT = "Are you sure you'd like to deactivate this user?"
  + "\n\n"
  + "When a user is deactivated they are removed as members "
  + "from any groups and departments where they are a member, "
  + "they will be removed from workflows where they are individually assigned, "
  + "The user will remain associated with Tasks to which they are assigned or are "
  + "a member, but they will appear inactive / grayed out and will not receive emails."

const activeStatusUpdater = (active) => (
  active ? API.activateUserAccount : API.deactivateUserAccount
)

const NORMAL_DESCRIPTION = "Deactivating a user acts as a soft delete."
const SCIM_ENABLED_DESCRIPTION = "Users are managed via SCIM user provisioning.\n\nPlease contact your internal support to activate and deactivate users."

// eslint-disable-next-line no-alert
const confirmDeactivation = () => window.confirm(DEACTIVATE_CONFIRMATION_PROMPT)

const UserActive = ({ user, updateActive }) => {
  const { usersManagedBySCIM } = useSCIMResourceManagement()

  const description = usersManagedBySCIM ? SCIM_ENABLED_DESCRIPTION : NORMAL_DESCRIPTION

  const updateUserActive = async (active) => {
    if (active || confirmDeactivation()) {
      const response = await activeStatusUpdater(active)({ userId: user.id })

      if (response.ok) {
        updateActive(active)
      } else {
        console.error("Error updating user's active status: ", response);
      }
    }
  }

  return (
    <ContentBar
      description={description}
      title="Account active"
    >
      <Switch
        checked={user.active}
        disabled={usersManagedBySCIM}
        onChange={updateUserActive}
      />
    </ContentBar>
  )
}

UserActive.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: types.object.isRequired,
  updateActive: types.func.isRequired,
}

export default UserActive
