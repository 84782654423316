import { questionList } from "utils/formDataHelpers"
import { evaluateBranchingConditionals } from "utils/QuestionBranching"
import { MULTI_ENTRIES_TYPE, NUMBER_CALCULATION_TYPE, EMAIL_TYPE } from "Forms/FormElementTypes"
import { GENERAL_TASK_MULTI_ENTRY_QUESTION_UUID } from "./constants"

export const simplifyAnswers = (fullTaskAnswers) => (
  Object.entries(fullTaskAnswers).reduce((simpleAnswers, [questionUuid, details]) => {
    simpleAnswers[questionUuid] = details?.answer
    return simpleAnswers
  }, {})
)

class TaskQuestionsValidator {
  constructor(taskAnswers, subform, operatorData = {}) {
    this.isGeneralTask = !subform
    this.taskAnswers = taskAnswers
    this.subform = subform
    this.operatorData = operatorData
  }

  isConditionMet(conditionals) {
    return evaluateBranchingConditionals({
      conditionals,
      formAnswers: simplifyAnswers(this.taskAnswers),
      operatorData: this.operatorData,
    })
  }

  sectionsWithConditionMet() {
    return {
      sections: this.subform.sections.filter(({ conditionals }) => this.isConditionMet(conditionals)),
    }
  }

  isAnswerValid(question) {
    const { conditionals, required, uuid } = question

    if (question.type === EMAIL_TYPE) {
      if (!this.taskAnswers[uuid]?.answer && !required) return true
      // from https://github.com/formsy/formsy-react/blob/master/src/validationRules.ts
      // eslint-disable-next-line max-len
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
      const answer = this.taskAnswers[uuid]?.answer
      return emailRegex.test(answer)
    }

    if (!this.isConditionMet(conditionals)) return true
    if (!required) return true
    if (question.type === NUMBER_CALCULATION_TYPE) return true

    let answer

    if (question.type === MULTI_ENTRIES_TYPE) {
      answer = this.taskAnswers[uuid]
      return answer?.length > 0
    }

    answer = simplifyAnswers(this.taskAnswers)[uuid] ?? undefined
    return answer !== undefined
  }

  isGeneralTaskValid() {
    const generalAnswers = this.taskAnswers[GENERAL_TASK_MULTI_ENTRY_QUESTION_UUID]
    return generalAnswers?.length > 0
  }

  isValid() {
    if (this.isGeneralTask) {
      return this.isGeneralTaskValid()
    }

    const questions = questionList(this.sectionsWithConditionMet())
    return questions.every((question) => this.isAnswerValid(question))
  }
}

export default TaskQuestionsValidator
