import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import {
  submissionPermissions as getSubmissionPermissions,
} from "reduxSlices/formSubmissionSlice"
import { taskShape } from "utils/propTypeShapes"
import RecoverTaskMenu from "./RecoverTaskMenu"
import TaskMenu from "./TaskMenu"

const TaskItemRowControls = ({ startEditTaskMode, task }) => {
  const {
    canAssignTaskWithSubform: userCanAssignTaskWithSubform,
    canAssignTask: userCanAssignGeneralTask,
  } = useSelector(getSubmissionPermissions)

  const {
    isDeleted: taskIsDeleted,
    subform: taskSubform,
    userTaskPermissions,
  } = task

  const {
    canSeeDetails: userCanSeeTaskDetails,
    canDelete: userCanDeleteTask,
  } = userTaskPermissions

  const taskIsGeneralTask = !taskSubform

  const userCanRecoverTask = (
    userCanSeeTaskDetails
      && taskIsGeneralTask ? userCanAssignGeneralTask : userCanAssignTaskWithSubform
  )

  if (taskIsDeleted && userCanRecoverTask) {
    return (
      <RecoverTaskMenu task={task} taskIsRecoverable />
    )
  }

  if (!taskIsDeleted && (userCanSeeTaskDetails || userCanDeleteTask)) {
    return (
      <TaskMenu
        task={task}
        startEditTaskMode={startEditTaskMode}
        taskIsDeletable={userCanDeleteTask && userCanRecoverTask}
      />
    )
  }

  return (
    <div className="p-3">
      <svg height="20px" width="20px">
        <use xlinkHref="#lock" />
      </svg>
    </div>
  )
}

TaskItemRowControls.propTypes = {
  startEditTaskMode: types.func.isRequired,
  task: taskShape.isRequired,
}

export default TaskItemRowControls
