import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { useHistoricalAccessToolContext } from "contexts/HistoricalAccessToolContext"
import { Checkbox } from "shared/checkboxes"

const HistoricalAccessToolFormForSubmitting = ({ className = "", id, title }) => {
  const { deselectFormForSubmitting, isFormForSubmittingSelected, selectFormForSubmitting } = useHistoricalAccessToolContext()

  const formIsSelected = isFormForSubmittingSelected(id)

  const toggleSelection = formIsSelected
    ? () => deselectFormForSubmitting(id)
    : () => selectFormForSubmitting(id)

  return (
    <div className={clsx(className, "flex")}>
      <Checkbox
        className="cursor-pointer mr-2"
        uuid={id}
        value={formIsSelected}
        onChange={toggleSelection}
      />
      <label htmlFor={id} className="cursor-pointer">
        {title}
      </label>
    </div>
  )
}

HistoricalAccessToolFormForSubmitting.propTypes = {
  className: types.string,
  id: types.number.isRequired,
  title: types.string.isRequired,
}

export default HistoricalAccessToolFormForSubmitting
