import React from "react"
import types from "prop-types"
import { questionHistoryQuestionShape } from "utils/propTypeShapes"
import Option from "shared/Options/Option"
import Select from "./Select"

const questionsToOptions = (questions) => questions.map((question) => (
  {
    label: question.prompt,
    value: question.uuid,
    marker: question.removedAt ? "removed" : undefined,
  }
))

const itemRenderer = ({ item, methods }) => (
  <Option
    className="px-2 !py-1 hover:bg-dropdownHover"
    option={item}
    search=""
    selectOption={methods.addItem}
  />
)

const QuestionHistoryQuestionSelect = ({
  className = "",
  clearable = false,
  onChange,
  questions,
  value = undefined,
  ...rest
}) => (
  <Select
    className={className}
    clearable={clearable}
    itemRenderer={itemRenderer}
    onChange={onChange}
    options={questionsToOptions(questions)}
    value={value}
    {...rest}
  />
)

QuestionHistoryQuestionSelect.propTypes = {
  className: types.string,
  clearable: types.bool,
  onChange: types.func.isRequired,
  questions: types.arrayOf(questionHistoryQuestionShape).isRequired,
  value: types.string,
}

export default QuestionHistoryQuestionSelect
