import React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  deselectAllQuestionColumns,
  deselectSubmissionColumn,
  formQuestions,
  orderedSubmissionColumns,
  selectAllQuestionColumns,
  selectSubmissionColumn,
} from "reduxSlices/reportBuilderSlice"
import MassSelection from "shared/MassSelection"
import ColumnOption from "../ColumnOption"

const SubmissionQuestionColumnOptions = () => {
  const dispatch = useDispatch()
  const questionOptions = useSelector(formQuestions)
  const selectedSubmissionColumns = useSelector(orderedSubmissionColumns)

  const deselectAll = () => dispatch(deselectAllQuestionColumns())
  const selectAll = () => dispatch(selectAllQuestionColumns())

  const addOption = (column) => dispatch(selectSubmissionColumn(column))
  const removeOption = (column) => dispatch(deselectSubmissionColumn(column))

  return (
    <div>
      <div className="mb-1 text-xl">Form Questions</div>
      <MassSelection
        deselectAll={deselectAll}
        selectAll={selectAll}
      />
      <div className="w-full mt-4 space-y-2">
        {
          questionOptions.map((option) => (
            <ColumnOption
              key={`${option.type}-${option.id}`}
              option={option}
              selectedColumns={selectedSubmissionColumns}
              selectOption={addOption}
              deselectOption={removeOption}
            />
          ))
        }
      </div>
    </div>
  )
}

export default SubmissionQuestionColumnOptions
