import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { ReportContextProvider } from "contexts/ReportContext"
import * as API from "services/api"
import { subscribedToAddOnFeature } from "reduxSlices/addOnFeatureSubscriptionsSlice"
import { SURVEY_FEATURE_NAME } from "utils/addOnFeatureHelpers"
import {
  editSurveyScheduledReportExportPath, surveyReportEditPath, newSurveyReportExportPath, newSurveyScheduledReportExportPath,
} from "utils/routeHelpers"
import ReportsSection from "./ReportsSection"

const SurveyReportsSection = ({ className = "", reports = [] }) => {
  const surveyFeatureEnabled = useSelector(subscribedToAddOnFeature(SURVEY_FEATURE_NAME))

  if (!surveyFeatureEnabled) return null

  return (
    <ReportContextProvider
      reports={reports}
      apiCallToDeleteReport={API.deleteSurveyReport}
      editPath={surveyReportEditPath}
    >
      <ReportsSection
        className={className}
        newReportExportPath={newSurveyReportExportPath}
        newScheduledReportExportPath={newSurveyScheduledReportExportPath}
        editScheduledReportExportPath={editSurveyScheduledReportExportPath}
        title="Survey Reports"
      />
    </ReportContextProvider>
  )
}

SurveyReportsSection.propTypes = {
  className: types.string,
  reports: types.arrayOf(types.object),
}

export default SurveyReportsSection
