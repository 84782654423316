import React from "react"
import { useFormSubmissionFormContext } from "contexts/FormSubmissionFormContext"
import { FormIcon } from "shared/icons"

const FormSubmissionForm = () => {
  const { form } = useFormSubmissionFormContext()

  return (
    <div className="flex items-center gap-2">
      <FormIcon form={form} className="shrink-0 h-6 w-6" />
      <span>{form.title}</span>
    </div>
  )
}

export default FormSubmissionForm
