import React, { useState, useRef, useEffect } from "react"
import { withFormsy } from "formsy-react"
import types from "prop-types"
import clsx from "clsx"
import dayjs from "dayjs"
import Calendar from "react-calendar"
import getIsMobile from "is-mobile"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import { CalendarIcon } from "shared/icons"
import InputErrorMessage from "shared/InputErrorMessage"

import "react-calendar/dist/Calendar.css"

const isMobile = getIsMobile()

const DateInput = ({
  border = "thin",
  hasBaseInputStyle = true,
  uuid = undefined,
  name = undefined,
  value = null,
  required = false,
  disabled = false,
  min = "",
  max = "",
  errorMessages = [],
  isPristine = true,
  onChange,
  onFocus = () => { },
  onBlur = () => { },
  className = "",
}) => {
  const inputRef = useRef(null)
  const calendarRef = useRef(null)
  const [isCalendarShown, setIsCalendarShown] = useState(false)
  const showCalendar = () => setIsCalendarShown(true)
  const hideCalendar = () => setIsCalendarShown(false)

  const calendarMin = min ? new Date(dayjs(min)) : null
  const calendarMax = max ? new Date(dayjs(max)) : null
  const calendarValue = value ? new Date(dayjs(value)) : null

  const selectDate = (date) => {
    onChange(dayjs(date).format("YYYY-MM-DD"))
    hideCalendar()
  }

  useEffect(() => {
    const clickOutsideCalendar = ({ target }) => {
      // Check if the click was outside the calendar or date input
      if (
        calendarRef.current
        && inputRef.current
        && !calendarRef.current.contains(target)
        && !inputRef.current.contains(target)
        && isCalendarShown
      ) {
        hideCalendar()
      }
    }

    document.addEventListener("mousedown", clickOutsideCalendar);
    return () => document.removeEventListener("mousedown", clickOutsideCalendar)
  }, [calendarRef, isCalendarShown])

  return (
    <>
      <div className={clsx("bg-white", className)}>
        <div
          className="relative w-full"
          onClickCapture={showCalendar}
        >
          <input
            className={clsx(
              hasBaseInputStyle && "base-input",
              !value && "date-input-empty",
              hasBaseInputStyle && `component-border-${border}`,
            )}
            ref={inputRef}
            id={uuid}
            name={name || uuid}
            type="date"
            disabled={disabled || isMobile}
            required={required}
            min={min}
            max={max}
            value={value}
            onChange={({ target }) => onChange(target.value)}
            onClick={(event) => event.preventDefault()}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={hideCalendar}
            placeholder="YYYY-MM-DD"
          />
          <div
            className={clsx(
              "cursor-pointer absolute top-1/2 transform -translate-y-1/2 right-1",
              isMobile && "w-auto",
            )}
          >
            <CalendarIcon />
          </div>
          {
            isCalendarShown && (
              <Calendar
                calendarType="ISO 8601"
                locale="en-US"
                className="absolute top-full z-1"
                inputRef={calendarRef}
                value={calendarValue}
                defaultView="month"
                minDetail="decade"
                minDate={calendarMin}
                maxDate={calendarMax}
                prevLabel={(
                  <SvgSpriteIcon
                    iconName="calendar-chevron-left"
                    className="flex items-center mx-auto h-6 w-6"
                  />
                )}
                prev2Label="«"
                nextLabel={(
                  <SvgSpriteIcon
                    iconName="calendar-chevron-right"
                    className="flex items-center mx-auto h-6 w-6"
                  />
                )}
                next2Label="»"
                onChange={selectDate}
                formatMonthYear={(_locale, date) => dayjs(date).format("MMM - YYYY")}
                formatShortWeekday={(_locale, date) => dayjs(date).format("dd")}
              />
            )
          }
        </div>
      </div>
      <InputErrorMessage isPristine={isPristine} errorMessages={errorMessages} />
    </>
  )
}

DateInput.propTypes = {
  border: types.oneOf(["thin", "bold", "bolder"]),
  hasBaseInputStyle: types.bool,
  uuid: types.string,
  name: types.string,
  value: types.string,
  required: types.bool,
  disabled: types.bool,
  onChange: types.func.isRequired,
  onFocus: types.func,
  onBlur: types.func,
  className: types.string,
  min: types.string,
  max: types.string,
  isPristine: types.bool,
  errorMessages: types.arrayOf(types.string),
}

export const FormsyDateInput = withFormsy(({ setValue, ...rest }) => (
  <DateInput onChange={setValue} {...rest} />
))

FormsyDateInput.displayName = "FormsyDateInput"

export default DateInput
