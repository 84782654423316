import React from "react"
import types from "prop-types"

const EmptyWorkflows = ({ groupOrUser }) => {
  const NO_DATA_TEXT = `There are no workflows associated with this ${groupOrUser}`

  return (
    <>
      <h2 className="tab-title">Associated Workflows</h2>
      <div>{ NO_DATA_TEXT }</div>
    </>
  )
}

EmptyWorkflows.propTypes = {
  groupOrUser: types.oneOf(["group", "user"]).isRequired,
}

export default EmptyWorkflows
