import React, { useCallback, useEffect, useMemo } from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getCategoryPublicForms } from "reduxSlices/workflowsSlice"
import { workflowConditionShape } from "utils/propTypeShapes"
import { Select } from "shared/selects"

// Converts form data from the server to options for the select component
const formsToOptions = (forms) => (
  forms
    .map((form) => ({
      value: form.id,
      label: form.title,
    }))
    .sort((formA, formB) => {
      if (formA.label < formB.label) return -1
      if (formA.label > formB.label) return 1
      return 0
    })
)

const DefineSubmittedFormValue = ({ changeConditionValue, condition }) => {
  const categoryForms = useSelector(getCategoryPublicForms)

  // Convert the category forms into options for the select component
  // Recalculate when the forms change
  const formOptions = useMemo(() => formsToOptions(categoryForms), [categoryForms])

  // Transform the selected option into the appropriate value object
  // and update the value
  const updateValue = useCallback((formId) => {
    changeConditionValue({ form_id: formId })
  }, [changeConditionValue])

  const selectedValue = condition.value?.form_id || formOptions[0]?.value

  useEffect(() => {
    if (condition.operator) {
      updateValue(selectedValue)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition.operator])

  return (
    <Select
      backspaceDelete={false}
      onChange={updateValue}
      options={formOptions}
      placeholder="Select a form"
      style={{ height: "32px" }}
      value={selectedValue}
    />
  )
}

DefineSubmittedFormValue.propTypes = {
  changeConditionValue: types.func.isRequired,
  condition: workflowConditionShape.isRequired,
}

export default DefineSubmittedFormValue
