import React from "react"
import types from "prop-types"
import { store } from "store"
import { useSelector } from "react-redux"
import { getCurrentSort, sortByColumn } from "reduxSlices/formSubmissionTableSlice"
import { CustomHeaderAccordion, useAccordion } from "shared/accordions"
import GroupingAccordionHeader from "shared/headers/GroupingAccordionHeader"
import Table from "shared/Table"
import { activityHubSubmissionPath } from "utils/routeHelpers"

const rowHrefFunc = (rowData) => activityHubSubmissionPath(rowData.slug)

const SubmissionGrouping = ({ columns, groupName, tableData }) => {
  const [isExpanded, toggleAccordion] = useAccordion(true)
  const currentSort = useSelector(getCurrentSort)
  const onSort = (column) => store.dispatch(sortByColumn(column.value))

  if (!tableData.length) return null

  return (
    <CustomHeaderAccordion
      className="mb-12"
      isExpanded={isExpanded}
      header={(
        <GroupingAccordionHeader
          numberOfSubmissions={tableData.length}
          groupName={groupName}
          isExpanded={isExpanded}
          toggleAccordion={toggleAccordion}
        />
      )}
      content={(
        <Table
          className="submissions"
          rowKey="slug"
          columns={columns}
          currentSort={currentSort}
          onSort={onSort}
          rowHrefFunc={rowHrefFunc}
          tableData={tableData}
        />
      )}
    />
  )
}

SubmissionGrouping.propTypes = {
  groupName: types.string.isRequired,
  columns: types.arrayOf(types.object).isRequired,
  tableData: types.arrayOf(types.object).isRequired,
}

export default SubmissionGrouping
