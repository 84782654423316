import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getFormSubmissionTable } from "reduxSlices/formSubmissionTableSlice"
import Switch from "shared/Switch"

const SubmissionDeletedFilter = ({ toggleShowDeletedSubmissions }) => {
  const { showOnlyDeletedSubmissions } = useSelector(getFormSubmissionTable)

  return (
    <div className="p-6 border-b border-lightgray-3">
      <div className="font-semibold flex-center mb-2">
        Show deleted submissions
        <Switch
          checked={showOnlyDeletedSubmissions}
          onChange={toggleShowDeletedSubmissions}
        />
      </div>
    </div>
  )
}

SubmissionDeletedFilter.propTypes = {
  toggleShowDeletedSubmissions: types.func.isRequired,
}

export default SubmissionDeletedFilter
