/* eslint-disable no-unused-vars */
import React, { useEffect } from "react"
import types from "prop-types"
import clsx from "clsx"
import { useDrag } from "react-dnd"
import { getEmptyImage } from "react-dnd-html5-backend"
import { sourceListUserShape } from "utils/propTypeShapes"
import { ProfileIcon } from "shared/icons"
import { DraggableItemTypes } from "../DraggableItemTypes"

const DraggableMember = ({ departmentId, segmentName, user }) => {
  const [collected, drag, dragPreview] = useDrag(() => ({
    type: DraggableItemTypes.MEMBER_PROFILE_ICON,
    item: {
      departmentId,
      memberId: user.id,
      segmentName,
      user,
    },
    collect: (monitor, props) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true })
  }, [dragPreview])

  return (
    <div ref={drag} className={clsx("select-none", collected.isDragging && "opacity-25")}>
      <ProfileIcon user={user} />
    </div>
  )
}

DraggableMember.propTypes = {
  departmentId: types.number.isRequired,
  segmentName: types.string.isRequired,
  user: sourceListUserShape.isRequired,
}

export default DraggableMember
