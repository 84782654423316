/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"
import types from "prop-types"
import clsx from "clsx"
import {
  processingInProgress,
  processingDone,
  processingFailed,
} from "utils/processingHelpers"
import { redirectTo, surveyBatchFromConfigPath } from "utils/routeHelpers"
import { shortMonthDate } from "utils/dateHelpers"

const redirectToBatch = (batchId) => redirectTo(surveyBatchFromConfigPath(batchId))

const processingNote = (batch) => {
  if (processingInProgress({ processable: batch })) {
    return "This batch is currently being processed"
  }

  if (processingFailed({ processable: batch })) {
    return "There was an error processing this batch"
  }

  return "This batch will be processed shortly"
}

const BatchInfo = ({ batch }) => (
  <div
    className={
      clsx(
        "flex justify-between items-center",
        "light-shadow rounded-lg border border-lightgray-2 bg-white",
        "mb-4 px-4 py-3 min-h-[74px]",
        "cursor-pointer",
      )
    }
    onClick={() => redirectToBatch(batch.id)}
  >
    <span className="flex-grow">{shortMonthDate(batch.createdAt)}</span>
    {
      processingDone({ processable: batch })
        ? (
          <div className="flex items-center gap-16">
            <div>
              <div className="font-semibold">
                {batch.expectedSubmissionsCount}
              </div>
              <div>Contacts</div>
            </div>
            <div>
              <div className={clsx("font-semibold", (batch.bouncedCount > 0 && "text-error"))}>
                {batch.bouncedCount}
              </div>
              <div>Bounced</div>
            </div>
            <div>
              <div className={clsx("font-semibold", (batch.submissionRate === 100 && "text-success"))}>
                {batch.submissionCount} ({batch.submissionRate}%)
              </div>
              <div>Submitted</div>
            </div>
          </div>
        )
        : (
          <p>
            {processingNote(batch)}
          </p>
        )
    }
  </div>
)

BatchInfo.propTypes = {
  batch: types.object.isRequired,
}

export default BatchInfo
