import React from "react"
import { surveyConfigurationForTileGridShape } from "utils/propTypeShapes"
import { FormIcon } from "shared/icons"
import { Tile, TileContent, TileHeader } from "shared/tiles"
import { SurveyConfigurationContextProvider } from "contexts/SurveyConfigurationContext"
import SurveyConfigurationTileFooter from "./SurveyConfigurationTileFooter"
import SurveyConfigurationTileMenu from "./SurveyConfigurationTileMenu"

const SurveyConfigurationTile = ({ surveyConfiguration }) => {
  const { description, name, surveyForm } = surveyConfiguration

  return (
    <SurveyConfigurationContextProvider surveyConfiguration={surveyConfiguration}>
      <Tile>
        <TileHeader>
          <SurveyConfigurationTileMenu />
        </TileHeader>
        <TileContent className="px-8">
          <FormIcon form={surveyForm} className="w-12 h-12 mb-3" />
          <p className="font-bold mb-4">{surveyForm.title}</p>
          <div className="flex items-center mb-4">
            <p className="font-bold text-sm text-gray-800">{name}</p>
          </div>
          <p className="text-sm font-semibold leading-relaxed max-h-60 overflow-y-auto">{description}</p>
        </TileContent>
        <SurveyConfigurationTileFooter />
      </Tile>
    </SurveyConfigurationContextProvider>
  )
}

SurveyConfigurationTile.propTypes = {
  surveyConfiguration: surveyConfigurationForTileGridShape.isRequired,
}

export default SurveyConfigurationTile
