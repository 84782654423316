import React from "react"
import types from "prop-types"
import { SmallWrap } from "shared/wraps"
import withReduxProvider from "shared/withReduxProvider"
import FormLevelReportsSection from "./FormLevelReportsSection"
import SolutionLevelReportsSection from "./SolutionLevelReportsSection"
import SurveyReportsSection from "./SurveyReportsSection"

const FormSubmissionReport = ({ formSubmissionReports = [], solutionLevelReports = [], surveyReports = [] }) => (
  <SmallWrap>
    <FormLevelReportsSection className="mb-20" reports={formSubmissionReports} />
    <SolutionLevelReportsSection className="mb-20" reports={solutionLevelReports} />
    <SurveyReportsSection reports={surveyReports} />
  </SmallWrap>
)

FormSubmissionReport.propTypes = {
  formSubmissionReports: types.arrayOf(types.object),
  solutionLevelReports: types.arrayOf(types.object),
  surveyReports: types.arrayOf(types.object),
}

export default withReduxProvider(FormSubmissionReport)
