import React, { useEffect, forwardRef } from "react"
import types from "prop-types"
import { TextInput } from "shared/inputs"
import { IconButton } from "shared/buttons"

// eslint-disable-next-line react/display-name
const SearchInput = forwardRef(({
  isSelectorHidden, search, setSearch, onSearchInputEnter, placeholder,
}, searchInputRef) => {
  useEffect(() => {
    if (!isSelectorHidden) {
      searchInputRef?.current?.focus()
    }
  }, [isSelectorHidden, searchInputRef])

  return (
    <div className="my-1 border-b border-lightgray-3 flex-center">
      <TextInput
        ref={searchInputRef}
        value={search}
        onChange={(value) => setSearch({ target: { value } })}
        onKeyDown={onSearchInputEnter}
        placeholder={placeholder}
      />
      <IconButton icon="fas fa-search" />
    </div>
  )
})

SearchInput.propTypes = {
  isSelectorHidden: types.bool.isRequired,
  onSearchInputEnter: types.func.isRequired,
  search: types.string.isRequired,
  setSearch: types.func.isRequired,
  placeholder: types.string.isRequired,
}

export default SearchInput
