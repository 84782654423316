import React, { useState } from "react"
import types from "prop-types"
import CreateGroupModal from "shared/modals/CreateGroupModal"
import { CustomHeaderAccordion, useAccordion } from "shared/accordions"
import { ChevronDown, ChevronUp } from "shared/icons"
import StrikeAround from "shared/StrikeAround"
import EmptyGroups from "./EmptyGroups"
import GroupWithMembers from "./GroupWithMembers"

const GroupList = ({ groups: initialGroups }) => {
  const [groups, setGroups] = useState(initialGroups)
  const [isExpanded, toggleAccordion] = useAccordion(true)
  const removeGroup = (groupId) => setGroups(groups.filter(({ id }) => id !== groupId))

  if (groups.length === 0) return <EmptyGroups />

  const Chevron = isExpanded ? ChevronDown : ChevronUp

  return (
    <CustomHeaderAccordion
      isExpanded={isExpanded}
      header={(
        <div className="flex-center">
          <StrikeAround className="text-2xl font-semibold pt-6 pb-3 flex-grow">
            Groups
          </StrikeAround>
          <span className="pt-6 pb-3">
            <Chevron
              className="h-8 w-8 p-2 ml-2 cursor-pointer"
              onClick={toggleAccordion}
            />
          </span>
        </div>
      )}
      content={(
        <>
          <CreateGroupModal />
          {
            groups.map((group) => (
              <GroupWithMembers key={group.id} group={group} removeGroup={removeGroup} />
            ))
          }
        </>
      )}
    />
  )
}

GroupList.propTypes = {
  groups: types.arrayOf(types.object).isRequired,
}

export default GroupList
