import React from "react"
import { useSelector } from "react-redux"
import { getTasks } from "reduxSlices/formSubmissionSlice"
import Task from "./Task"

const TaskList = () => {
  const tasks = useSelector(getTasks)

  return tasks.map((task) => (
    <Task key={task.id} task={task} />
  ))
}

export default TaskList
