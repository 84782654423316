/* eslint-disable react/destructuring-assignment */
import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { withFormsy } from "formsy-react"
import { Select } from "shared/selects"

import * as styles from "./IconSelect.module.scss"

const defaultRenderIcon = ({ icon }) => icon && <i className={clsx(icon, "justify-self-center")} />

const renderIconStyle = ({ icon }) => icon && styles.selectIconStyle

const defaultContentRenderer = ({ props: { values, placeholder, renderContentIcon } }) => {
  const [item = {}] = values
  const hasIcon = !!item.icon
  return (
    <div className={clsx(styles.contentStyle, renderIconStyle(item), !hasIcon && "pl-3")}>
      {
        hasIcon && <span className="justify-self-center">{ renderContentIcon(item) }</span>
      }
      <span>{ item.label || placeholder }</span>
    </div>
  )
}

const defaultItemRenderer = ({ item, methods, props: { renderItemIcon } }) => (
  <div className={clsx(styles.itemStyle, renderIconStyle(item))} onClickCapture={() => methods.addItem(item)}>
    <span className="justify-self-center">{ renderItemIcon(item) }</span>
    <span>{ item.label }</span>
  </div>
)

const IconSelect = ({
  contentRenderer = defaultContentRenderer,
  disabled = false,
  itemRenderer = defaultItemRenderer,
  options = [],
  renderContentIcon = defaultRenderIcon,
  renderItemIcon = defaultRenderIcon,
  style = {},
  value = undefined,
  ...rest
}) => (
  <Select
    contentRenderer={contentRenderer}
    disabled={disabled}
    itemRenderer={itemRenderer}
    options={options}
    renderContentIcon={renderContentIcon}
    renderItemIcon={renderItemIcon}
    style={{ ...style, paddingLeft: 0 }}
    value={value}
    {...rest}
  />
)

IconSelect.propTypes = {
  contentRenderer: types.func,
  itemRenderer: types.func,
  onChange: types.func.isRequired,
  options: types.arrayOf(types.object),
  value: types.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: types.object,
  renderItemIcon: types.func,
  renderContentIcon: types.func,
  disabled: types.bool,
}

export const FormsyIconSelect = withFormsy(({ setValue, ...rest }) => (
  <IconSelect onChange={setValue} {...rest} />
))

FormsyIconSelect.displayName = "FormsyIconSelect"

export default IconSelect
