/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState, useRef } from "react"
import clsx from "clsx"
import types from "prop-types"
import { useOnClickOutside } from "hooks"

const Tabs = ({ className = "", initialActiveIndex = 0, children }) => {
  const [activeIndex, setActiveIndex] = useState(initialActiveIndex)

  const [tabNavOpen, setTabNavOpen] = useState(false)
  const openTabNav = () => setTabNavOpen(true)
  const closeTabNav = () => setTabNavOpen(false)

  const tabNavRef = useRef()
  useOnClickOutside(tabNavRef, closeTabNav)

  const tabRef = useRef([])

  const { props: { children: activeTabContent } } = children[activeIndex] || children[0]

  useEffect(() => {
    setActiveIndex(initialActiveIndex)
  }, [initialActiveIndex])

  return (
    <>
      <div className="tab-nav-container">
        <div className="lg:hidden text-sm font-medium text-dark mb-1">
          <span>Navigation</span>
        </div>
        <svg className="tab-nav-icon"><use xlinkHref={tabNavOpen ? "#chevron-up" : "#chevron-down"} /></svg>
        <ul ref={tabNavRef} className={clsx(className, "tab-nav", tabNavOpen && "open")} onClick={tabNavOpen ? closeTabNav : openTabNav}>
          {
            children.map(({ props }, index) => {
              const { title, NotificationBadge } = props

              return (
                <li
                  key={title}
                  ref={(el) => {
                    tabRef.current[index] = el
                  }}
                  onClickCapture={() => setActiveIndex(index)}
                  className={clsx((index === activeIndex) && "active", "tab-link")}
                >
                  <span className={clsx(NotificationBadge && "mr-2")}>{ title }</span>
                  {NotificationBadge && <NotificationBadge />}
                </li>
              )
            })
          }
        </ul>
      </div>
      { activeTabContent }
    </>
  )
}

Tabs.propTypes = {
  initialActiveIndex: types.number,
  className: types.string,
  children: types.node.isRequired,
}

export default Tabs
