import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"

// Audit comment expected to be formated like ...
// "Some Name" report
// This matcher will provide a capture group
// for the text between the quotes
const BETWEEN_DOUBLE_QUOTES_MATCHER = /"(.*)"/

const reportName = (audit) => {
  const { comment } = audit

  return comment.match(BETWEEN_DOUBLE_QUOTES_MATCHER)[1]
}

const TaskExportedInSolutionLevelReportAuditItemDetails = ({ audit }) => {
  const { task } = audit

  return (
    <div>
      <span>
        <AuditedUser audit={audit} /> included
        the task titled <span className="font-semibold">{task.title}</span> in
        an export of the {reportName(audit)} solution level report.
      </span>
    </div>
  )
}

TaskExportedInSolutionLevelReportAuditItemDetails.propTypes = {
  audit: auditShape.isRequired,
}

export default TaskExportedInSolutionLevelReportAuditItemDetails
