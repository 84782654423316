import React from "react"
import types from "prop-types"
import { FormIcon } from "shared/icons"

const FormDataElement = ({ value: form }) => (
  <div className="w-max p-1.5 rounded flex items-center bg-light-100 text-sm font-medium tracking-tight">
    <FormIcon form={form} className="mr-2 h-6 w-6" />
    { form.title }
  </div>
)

FormDataElement.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: types.object.isRequired,
}

export default FormDataElement
