import { createSlice } from "@reduxjs/toolkit"
import {
  getDataSortingMethod, DATE_TYPE, FORM_TYPE, TEXT_TYPE, NUMBER_TYPE,
} from "shared/Table/TableDataElement/tableDataType"

const ALL_BATCHES = "all"
const CLOSED_BATCHES = "closed"
const OPEN_BATCHES = "open"

export const STATUS_DISPLAY_OPTIONS = [
  {
    label: "All Batches",
    value: ALL_BATCHES,
  },
  {
    label: "Open Batches Only",
    value: OPEN_BATCHES,
  },
  {
    label: "Closed Batches Only",
    value: CLOSED_BATCHES,
  },
]

const BATCH_DATE_COLUMN = {
  label: "Batch Date",
  value: "createdAt",
  sortable: true,
  width: "120px",
  dataType: DATE_TYPE,
}

const BATCH_NAME_COLUMN = {
  label: "Batch Name",
  value: "name",
  sortable: true,
  width: "350px",
  dataType: TEXT_TYPE,
}

const BATCH_FORM_COLUMN = {
  label: "Form",
  value: "form",
  sortable: true,
  width: "325px",
  dataType: FORM_TYPE,
}

const BATCH_SENT_COLUMN = {
  label: "Sent",
  value: "expectedSubmissionsCount",
  sortable: true,
  width: "90px",
  dataType: NUMBER_TYPE,
}

const BATCH_BOUNCED_COLUMN = {
  label: "Bounced",
  value: "bouncedCount",
  sortable: true,
  width: "95px",
  dataType: NUMBER_TYPE,
}

const BATCH_SUBMITTED_COLUMN = {
  label: "Submitted",
  value: "submissionCount",
  sortable: true,
  width: "110px",
  dataType: NUMBER_TYPE,
}

const BATCH_RATE_COLUMN = {
  label: "% Done",
  value: "submissionRate",
  sortable: true,
  width: "90px",
  dataType: NUMBER_TYPE,
}

const INITIAL_STATE = {
  surveyBatches: [],
  forms: [],
  formIdsToHide: [],
  formCounts: {},
  columns: [
    BATCH_DATE_COLUMN,
    BATCH_NAME_COLUMN,
    BATCH_FORM_COLUMN,
    BATCH_SENT_COLUMN,
    BATCH_BOUNCED_COLUMN,
    BATCH_SUBMITTED_COLUMN,
    BATCH_RATE_COLUMN,
  ],
  currentSort: {
    column: BATCH_DATE_COLUMN,
    isAsc: false,
  },
  statusDisplay: ALL_BATCHES,
}

const SUBMISSION_RATE_FUNC = (rowData) => (rowData.submissionRate === 100 ? "text-success" : "")
const BOUNCED_COUNT_FUNC = (rowData) => (rowData.bouncedCount > 0 ? "text-error" : "")

export const ENTRY_CLASS_FUNCTIONS = new Map([
  [BATCH_BOUNCED_COLUMN.value, BOUNCED_COUNT_FUNC],
  [BATCH_SUBMITTED_COLUMN.value, SUBMISSION_RATE_FUNC],
  [BATCH_RATE_COLUMN.value, SUBMISSION_RATE_FUNC],
])

export const surveyBatchTableSlice = createSlice({
  name: "surveyBatchTable",
  initialState: INITIAL_STATE,
  reducers: {
    hideForm: (state, action) => {
      state.formIdsToHide.push(action.payload)
    },
    setCurrentSort: (state, action) => {
      const selectedColumn = action.payload
      if (state.currentSort.column.value === selectedColumn.value) {
        state.currentSort.isAsc = !state.currentSort.isAsc
      } else {
        state.currentSort = {
          column: selectedColumn,
          isAsc: true,
        }
      }
    },
    setForms: (state, action) => {
      state.forms = action.payload
    },
    setStatusDisplay: (state, action) => {
      state.statusDisplay = action.payload
    },
    setSurveyBatches: (state, action) => {
      state.surveyBatches = action.payload

      state.formCounts = action.payload.reduce((acc, batch) => {
        const formId = batch.form.id
        if (acc[formId]) {
          acc[formId] += 1
        } else {
          acc[formId] = 1
        }

        return acc
      }, {})
    },
    showForm: (state, action) => {
      state.formIdsToHide = state.formIdsToHide.filter((id) => id !== action.payload)
    },
  },
})

export const {
  hideForm,
  setCurrentSort,
  setForms,
  setStatusDisplay,
  setSurveyBatches,
  showForm,
} = surveyBatchTableSlice.actions;

export const getSurveyBatchTable = (state) => state.surveyBatchTable
export const getSurveyBatches = ({ surveyBatchTable }) => {
  const {
    currentSort,
    formIdsToHide,
    statusDisplay,
    surveyBatches,
  } = surveyBatchTable;

  let filteredBatches = surveyBatches.filter(({ form }) => !formIdsToHide.includes(form.id))

  if (statusDisplay === CLOSED_BATCHES) {
    filteredBatches = filteredBatches.filter(({ open }) => !open)
  } else if (statusDisplay === OPEN_BATCHES) {
    filteredBatches = filteredBatches.filter(({ open }) => open)
  }

  const sortingMethod = getDataSortingMethod(currentSort.column)
  const sortedBatches = sortingMethod(filteredBatches, currentSort)

  return sortedBatches
}
export const getColumns = (state) => state.surveyBatchTable.columns
export const getFormCounts = (state) => state.surveyBatchTable.formCounts
export const getForms = (state) => state.surveyBatchTable.forms

export default surveyBatchTableSlice.reducer
