import React from "react"
import { useSolutionLevelReportExportContext } from "contexts/SolutionLevelReportExportContext"
import { DATE_REPORTABLE_FIELD_TYPE } from "utils/reportableFieldHelpers"
import { DateInput } from "shared/inputs"
import { ReportableFieldSelect } from "shared/selects"

const NewSolutionLevelReportExportFormReportableFieldDateAnswerFilters = () => {
  const {
    reportableFieldDateAnswerFilters,
    reportableFields,
    setReportableFieldDateAnswerFilterEarliestDate,
    setReportableFieldDateAnswerFilterLatestDate,
    setReportableFieldDateAnswerFilterReportableFieldId,
  } = useSolutionLevelReportExportContext()

  const dateReportableFields = reportableFields.filter((reportableField) => (
    reportableField.questionType === DATE_REPORTABLE_FIELD_TYPE
  ))

  if (dateReportableFields.length === 0) {
    return (
      <p>There are no date reportable fields in the solution associated with this report.</p>
    )
  }

  const reportableFieldDateAnswerFilter = reportableFieldDateAnswerFilters[0] ?? {}
  const { earliestDate, latestDate, reportableFieldId } = reportableFieldDateAnswerFilter

  return (
    <>
      <ReportableFieldSelect
        className="!w-full bordered-base-input mb-4"
        clearable
        onChange={setReportableFieldDateAnswerFilterReportableFieldId}
        reportableFields={dateReportableFields}
        value={reportableFieldId}
      />
      {
        reportableFieldId && (
          <>
            <label>
              <span>Earliest Date:</span>
              <DateInput
                className="mb-4"
                clearable
                onChange={setReportableFieldDateAnswerFilterEarliestDate}
                value={earliestDate}
              />
            </label>
            <label>
              <span>Latest Date:</span>
              <DateInput
                className="mb-4"
                clearable
                onChange={setReportableFieldDateAnswerFilterLatestDate}
                value={latestDate}
              />
            </label>
          </>
        )
      }
    </>
  )
}

export default NewSolutionLevelReportExportFormReportableFieldDateAnswerFilters
