import React from "react"
import { isEmpty } from "lodash-es"
import { auditShape } from "utils/propTypeShapes"
import { getInitials } from "shared/icons/ProfileIcon"

const FALLBACK_USER_NAME = "Anonymous"

const AuditImpersonatorLabel = () => (
  <span className="text-xs font-semibold inline-block py-1 px-2 rounded-full
                   text-pink-600 bg-pink-200 uppercase"
  >
    Impersonator
  </span>
)

const AuditedUser = ({ audit }) => {
  const { impersonator, user } = audit

  const userName = user.fullName || FALLBACK_USER_NAME

  if (isEmpty(impersonator)) {
    return (
      <span className="font-semibold">
        {userName}
      </span>
    )
  }

  return (
    <span className="font-semibold inline-block mr-1">
      <span className="mr-2">
        {impersonator.role} - {getInitials(impersonator)} as {userName}
      </span>
      <AuditImpersonatorLabel />
    </span>
  )
}

AuditedUser.propTypes = {
  audit: auditShape.isRequired,
}

export default AuditedUser
