import React from "react"
import { SidebarCollapsibleContent } from "shared/Sidebar"
import ColumnList from "./ColumnList"

const ColumnSelector = () => (
  <SidebarCollapsibleContent title="Column Selector">
    <ColumnList />
  </SidebarCollapsibleContent>
)

export default ColumnSelector
