import React from "react"
import { useFormSubmissionScheduledReportExportContext } from "contexts/FormSubmissionScheduledReportExportContext"
import LocationAnswerFiltersQuestionSelect from "./LocationAnswerFiltersQuestionSelect"
import LocationAnswerFiltersLocationDetails from "./LocationAnswerFiltersLocationDetails"

const NewFormSubmissionScheduledReportExportFormLocationAnswerFilters = () => {
  const { locationAnswerFilters } = useFormSubmissionScheduledReportExportContext()

  const locationAnswerFilter = locationAnswerFilters[0] ?? {}
  const { questionUuid } = locationAnswerFilter

  return (
    <div className="w-full">
      <LocationAnswerFiltersQuestionSelect />
      {
        questionUuid && (
          <LocationAnswerFiltersLocationDetails />
        )
      }
    </div>
  )
}

export default NewFormSubmissionScheduledReportExportFormLocationAnswerFilters
