import React from "react"
import types from "prop-types"

const AttachmentUploadInstructions = ({ className = "" }) => (
  <div className={className}>
    <p className="mb-4">
      <span className="hidden lg:inline">Drag and drop files here or </span>
      <span className="capitalize lg:normal-case">click</span> the <strong>+ Upload File</strong> button above to browse.
    </p>
    <p className="text-sm text-dark">Attachment size limit: 35 MB</p>
  </div>
)

AttachmentUploadInstructions.propTypes = {
  className: types.string,
}

export default AttachmentUploadInstructions
