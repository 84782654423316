import React from "react";
import types from "prop-types"
import { withFormsy } from "formsy-react"
import { Select } from "shared/selects"

const optionsFromList = (options) => options.map((opt) => ({ label: opt.name, value: opt.id }))

const OrganizationSelect = ({
  className = "",
  value = undefined,
  options = [],
  onChange,
  ...rest
}) => (
  <Select
    className={className}
    options={optionsFromList(options)}
    value={value}
    onChange={onChange}
    {...rest}
  />
)

OrganizationSelect.propTypes = {
  onChange: types.func.isRequired,
  options: types.arrayOf(types.object),
  value: types.number,
  className: types.string,
}

export const FormsyOrganizationSelect = withFormsy(({ setValue, ...rest }) => (
  <OrganizationSelect onChange={setValue} {...rest} />
))

FormsyOrganizationSelect.displayName = "FormsyOrganizationSelect"

export default OrganizationSelect
