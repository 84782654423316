import React from "react"
import { useHistoricalAccessToolContext } from "contexts/HistoricalAccessToolContext"
import HistoricalAccessToolLocationFilterReportableField from "./HistoricalAccessToolLocationFilterReportableField"
import HistoricalAccessToolLocationFilterDetails from "./HistoricalAccessToolLocationFilterDetails"

const HistoricalAccessToolLocationFilter = () => {
  const { reportableFieldLocationAnswerFilters } = useHistoricalAccessToolContext()

  const reportableFieldLocationAnswerFilter = reportableFieldLocationAnswerFilters[0] ?? {}
  const { reportableFieldId } = reportableFieldLocationAnswerFilter

  return (
    <>
      <HistoricalAccessToolLocationFilterReportableField />
      {
        reportableFieldId && (
          <HistoricalAccessToolLocationFilterDetails />
        )
      }
    </>
  )
}

export default HistoricalAccessToolLocationFilter
