import React, { useState } from "react"
import types from "prop-types"
import { shortMonthDate, yearMonthDayDateWithTz } from "utils/dateHelpers"
import { PUBLIC_STATUS_BOUNCED } from "utils/surveyHelpers"
import SurveyBatchContext from "../SurveyBatchContext"

const SurveyBatchContextProvider = ({ children, surveyBatch: propsSurveyBatch }) => {
  // eslint-disable-next-line no-unused-vars
  const [surveyBatch, setSurveyBatch] = useState(propsSurveyBatch)
  const [surveyBatchCloseDate, setSurveyBatchCloseDate] = useState(surveyBatch.closesAt ? yearMonthDayDateWithTz(surveyBatch.closesAt, surveyBatch.timezone) : undefined)
  const [surveyBatchOpen, setIsSurveyBatchOpen] = useState(surveyBatch.open)

  const surveyBatchCreationDate = shortMonthDate(surveyBatch.createdAt)

  const surveyBatchAcceptingNewContacts = surveyBatch.open

  const updateSurveyBatch = (updatedSurveyBatch) => {
    setSurveyBatch(
      {
        ...surveyBatch,
        reminders: updatedSurveyBatch.reminders,
        name: updatedSurveyBatch.name,
        open: updatedSurveyBatch.open,
        closesAt: updatedSurveyBatch.closesAt,
      },
    )
  }

  // Related to the batch being anonymous
  const surveyBatchIsAnonymous = surveyBatch.anonymous

  // Related to the survey batch's tokens (displayed as contacts)
  const addSurveyToken = (surveyToken) => setSurveyBatch(
    {
      ...surveyBatch,
      surveyTokens: [
        ...surveyBatch.surveyTokens,
        surveyToken,
      ],
    },
  )

  const removeSurveyToken = (surveyTokenId) => setSurveyBatch(
    {
      ...surveyBatch,
      surveyTokens: surveyBatch.surveyTokens.filter((item) => item.id !== surveyTokenId),
    },
  )

  // Related to the batch statistics
  // This value will change when there can be multiple submissions [required] for a surveyToken
  const expectedSubmissionsCount = surveyBatch.surveyTokens.length
  const bouncedCount = surveyBatch.surveyTokens.filter((item) => item.publicStatus === PUBLIC_STATUS_BOUNCED).length
  const submissionCount = surveyBatch.surveyTokens.filter((item) => item.hasRequiredSubmissions === true).length
  const submissionRate = expectedSubmissionsCount <= 0 ? 0 : Math.floor((submissionCount / expectedSubmissionsCount) * 100)

  // Object exposed to context consumers
  const contextConsumerValue = {
    addSurveyToken,
    bouncedCount,
    expectedSubmissionsCount,
    removeSurveyToken,
    setIsSurveyBatchOpen,
    setSurveyBatchCloseDate,
    submissionCount,
    submissionRate,
    surveyBatch,
    surveyBatchAcceptingNewContacts,
    surveyBatchCloseDate,
    surveyBatchCreationDate,
    surveyBatchIsAnonymous,
    surveyBatchOpen,
    updateSurveyBatch,
  }

  return (
    <SurveyBatchContext.Provider value={contextConsumerValue}>
      {children}
    </SurveyBatchContext.Provider>
  )
}

SurveyBatchContextProvider.propTypes = {
  children: types.node.isRequired,
  surveyBatch: types.object.isRequired,
}

export default SurveyBatchContextProvider
