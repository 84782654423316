import React, { useRef, useState, useEffect } from "react"
import types from "prop-types"
import clsx from "clsx"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const AttachmentTypeIcon = ({ fileType, className = "h-8 w-8" }) => {
  const typeDisplay = fileType.substring(0, 3).toUpperCase()

  const initialContainerEl = useRef(null)

  const [fontSize, setFontSize] = useState("large")
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (initialContainerEl.current) {
      const containerWidth = initialContainerEl.current.clientWidth
      const size = containerWidth ? `${containerWidth * 0.25}px` : "small"
      setFontSize(size)
    }
  })

  return (
    <span
      ref={initialContainerEl}
      className={clsx("relative", className)}
    >
      <div
        className="font-bold text-white bottom-0.5 absolute-horizontal-center tracking-widest"
        style={{ fontSize }}
      >
        { typeDisplay }
      </div>
      <SvgSpriteIcon
        iconName="attachment"
        className="w-full h-full"
      />
    </span>
  )
}

AttachmentTypeIcon.propTypes = {
  fileType: types.string.isRequired,
  className: types.string,
}

export default AttachmentTypeIcon
