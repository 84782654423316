import React, { useCallback, useEffect, useState } from "react"
import types from "prop-types"
import * as API from "services/api"
import { ADD_ON_FEATURES, addOnFeatureDisplayName, addOnFeatureConfigurationPropertyDisplayName } from "utils/addOnFeatureHelpers"
import { errorToast, successToast } from "shared/toast"
import OrganizationAddOnConfigurationCheckbox from "./OrganizationAddOnConfigurationCheckbox"
import OrganizationAddOnFeatureCheckbox from "./OrganizationAddOnFeatureCheckbox"

const OrganizationAddOnFeatureSubscriptions = ({ organizationSubdomain }) => {
  const [subscriptions, setSubscriptions] = useState([])

  const updateSubscriptions = useCallback(async () => {
    const response = await API.organizationAddOnFeatureSubscriptions({ organizationSubdomain })

    if (response.ok) {
      setSubscriptions(response.data)
    } else {
      errorToast("Something went wrong.  Unable to load add-on feature subscriptions.")
    }
  }, [organizationSubdomain])

  // Load the organization's add-on feature subscriptions.
  // This should only occur once when the component mounts
  // because the organizationSubdomain prop is passed from
  // the Rails template and should not change.
  useEffect(() => {
    updateSubscriptions({ organizationSubdomain })
  }, [organizationSubdomain, updateSubscriptions])

  const subscription = (featureName) => subscriptions.find((sub) => sub.addOnFeatureName === featureName)

  // Functions for add-on feature subscribing/unsubscribing
  const subscribe = (featureName) => async () => {
    const response = await API.subscribeOrganizationToAddOnFeature({
      organizationSubdomain,
      addOnFeatureName: featureName,
    })

    if (response.ok) {
      updateSubscriptions()
      successToast(`Organization successfully subscribed to ${addOnFeatureDisplayName(featureName)}.`)
    } else {
      errorToast(`Something went wrong.  Unable to subscribe organization to ${addOnFeatureDisplayName(featureName)}.`)
    }
  }

  const unsubscribe = (featureName) => async () => {
    const response = await API.unsubscribeOrganizationFromAddOnFeature({
      organizationSubdomain,
      addOnFeatureName: featureName,
    })

    if (response.ok) {
      updateSubscriptions()
      successToast(`Organization successfully unsubscribed from ${addOnFeatureDisplayName(featureName)}.`)
    } else {
      errorToast(`Something went wrong.  Unable to unsubscribe organization from ${addOnFeatureDisplayName(featureName)}.`)
    }
  }

  // Functions for add-on feature configuration enabling/disabling
  const enableConfiguration = (addOnFeatureName) => (property) => async () => {
    const response = await API.enableOrganizationAddOnFeatureConfiguration({ organizationSubdomain, addOnFeatureName, property })

    if (response.ok) {
      updateSubscriptions()
      successToast(`${addOnFeatureConfigurationPropertyDisplayName(property)} successfully enabled.`)
    } else {
      errorToast(`Something went wrong.  Unable to enable ${addOnFeatureConfigurationPropertyDisplayName(property)}.`)
    }
  }

  const disableConfgiruation = (addOnFeatureName) => (property) => async () => {
    const response = await API.disableOrganizationAddOnFeatureConfiguration({ organizationSubdomain, addOnFeatureName, property })

    if (response.ok) {
      updateSubscriptions()
      successToast(`${addOnFeatureConfigurationPropertyDisplayName(property)} successfully disabled.`)
    } else {
      errorToast(`Something went wrong.  Unable to disable ${addOnFeatureConfigurationPropertyDisplayName(property)}.`)
    }
  }

  return (
    <div>
      {
        ADD_ON_FEATURES.map((featureName) => {
          const addOnFeatureSubscription = subscription(featureName)
          const organizationIsSubscribedToFeature = !!addOnFeatureSubscription
          const toggleSubscription = organizationIsSubscribedToFeature ? unsubscribe(featureName) : subscribe(featureName)

          return (
            <>
              <OrganizationAddOnFeatureCheckbox
                key={featureName}
                addOnFeatureName={featureName}
                subscribed={organizationIsSubscribedToFeature}
                onChange={toggleSubscription}
              />
              {
                organizationIsSubscribedToFeature && (
                  <>
                    {
                      addOnFeatureSubscription.addOnFeatureConfigurations.map((configuration) => {
                        const { enabled, property } = configuration
                        const toggleConfiguration = enabled
                          ? disableConfgiruation(featureName)(property)
                          : enableConfiguration(featureName)(property)

                        return (
                          <OrganizationAddOnConfigurationCheckbox
                            key={`${featureName}-${property}`}
                            className="ml-7"
                            enabled={enabled}
                            onChange={toggleConfiguration}
                            property={property}
                          />
                        )
                      })
                    }
                  </>
                )
              }
            </>
          )
        })
      }
    </div>
  )
}

OrganizationAddOnFeatureSubscriptions.propTypes = {
  organizationSubdomain: types.string.isRequired,
}

export default OrganizationAddOnFeatureSubscriptions
