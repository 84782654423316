import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { isEqual } from "lodash-es"
import {
  excludeAllFormSlugs,
  forms,
  includeAllFormSlugs,
} from "reduxSlices/reportBuilderSlice"
import MassSelection from "shared/MassSelection"
import FormFilterOption from "./FormFilterOption"

const FormFilterSelector = () => {
  const dispatch = useDispatch()
  const storedForms = useSelector(forms, isEqual)

  const deselectAll = () => dispatch(excludeAllFormSlugs())
  const selectAll = () => dispatch(includeAllFormSlugs())

  return (
    <div className="mt-8">
      <div className="space-y-1 mb-4">
        <p className="">Which forms would you like the report to include submissions from?</p>
        <p className="text-xs text-dark">At least one form must be selected</p>
      </div>
      <MassSelection deselectAll={deselectAll} selectAll={selectAll} className="mb-5" />
      <div className="space-y-2">
        {
          storedForms.map((form) => (
            <FormFilterOption
              key={form.slug}
              form={form}
            />
          ))
        }
      </div>
    </div>
  )
}

export default FormFilterSelector
