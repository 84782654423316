import React from "react"
import clsx from "clsx"
import types from "prop-types"
import SideTabsContainer from "./SideTabsContainer"

const isActive = (index, activeIndex) => (index === activeIndex)

const SideTabContents = ({ activeIndex, tabs }) => (
  tabs.map(({ title, TabContent, props = {} }, index) => (
    <div key={title} className={clsx(!isActive(index, activeIndex) && "hidden", "flex-grow")}>
      <TabContent {...props} />
    </div>
  ))
)

const HiddenSideTabs = ({ className = "", tabs, initialActiveIndex = 0 }) => (
  <SideTabsContainer
    className={className}
    tabs={tabs}
    initialActiveIndex={initialActiveIndex}
    SideTabContents={SideTabContents}
  />
)

HiddenSideTabs.propTypes = {
  className: types.string,
  tabs: types.arrayOf(types.object).isRequired,
  initialActiveIndex: types.number,
}

export default HiddenSideTabs
