import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import ExpandMenu, { ExpandMenuItem } from "shared/ExpandMenu"
import { store } from "store"
import * as API from "services/api"
import { formSubmissionSlug as getFormSubmissionSlug, setAttachments, submissionPermissions as getSubmissionPermissions } from "reduxSlices/formSubmissionSlice"
import { EllipsisIcon } from "shared/icons"

const AttachmentMenu = ({ attachment }) => {
  const formSubmissionSlug = useSelector(getFormSubmissionSlug)
  const { canDeleteAttachments } = useSelector(getSubmissionPermissions)

  const deleteAttachment = async () => {
    // eslint-disable-next-line no-alert
    if (window.confirm("Are you sure you want to delete this attachment?")) {
      const attachmentId = attachment.id
      const apiFunction = attachment.type === "attachments" ? API.deleteAttachment : API.deleteSubmittedFile

      const response = await apiFunction({ formSubmissionSlug, attachmentId })

      if (response.ok) {
        store.dispatch(setAttachments(response.data))
      } else {
        console.error("Error deleting attachment: ", response)
      }
    }
  }

  return (
    <ExpandMenu
      expandMenuComponent={(
        <span className="absolute top-2 right-2 bg-white rounded p-1.5">
          <EllipsisIcon />
        </span>
      )}
    >
      {
        canDeleteAttachments && attachment.isDeletable
        && (
          <ExpandMenuItem onClick={deleteAttachment}>
            <span>Delete</span>
          </ExpandMenuItem>
        )
      }
      <ExpandMenuItem>
        <a
          href={attachment.path}
          target="_blank"
          rel="noopener noreferrer"
        >
          Download
        </a>
      </ExpandMenuItem>
    </ExpandMenu>
  )
}

AttachmentMenu.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  attachment: types.object.isRequired,
}

export default AttachmentMenu
