import React from "react"
import { IconButton } from "shared/buttons"

const LockedIcon = () => (
  <IconButton
    className="border rounded-full border-lightgray-3"
    background="default"
    icon="fas fa-lock"
    onClick={null}
    disabled
  />

)

export default LockedIcon
