import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"

const RelatedFormSubmissionCreatedAuditItemDetails = ({ audit }) => {
  const { relatedFormSubmissionSlug } = audit

  return (
    <div>
      <span>
        <AuditedUser audit={audit} /> added {relatedFormSubmissionSlug} as
        a related form submission.
      </span>
    </div>
  )
}

RelatedFormSubmissionCreatedAuditItemDetails.propTypes = {
  audit: auditShape.isRequired,
}

export default RelatedFormSubmissionCreatedAuditItemDetails
