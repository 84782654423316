import React from "react"
import types from "prop-types"
import { HiddenSideTabs } from "shared/tabs"
import GeneralTab from "./GeneralTab"
import MembersTab from "./MembersTab"
import GroupWorkflowsTab from "./GroupWorkflowsTab"

const TABS = (tabProps) => [{
  title: "General",
  TabContent: GeneralTab,
  props: tabProps,
}, {
  title: "Members",
  TabContent: MembersTab,
  props: tabProps,
}, {
  title: "Workflows",
  TabContent: GroupWorkflowsTab,
  props: tabProps,
}]

const GroupTabs = ({ tabProps }) => (
  <HiddenSideTabs tabs={TABS(tabProps)} />
)

GroupTabs.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tabProps: types.object.isRequired,
}

export default GroupTabs
