import React, { useEffect, useState } from "react"
import types from "prop-types"
import { isEmpty } from "lodash-es"
import { useDispatch, useSelector } from "react-redux"
import { useDropzone } from "react-dropzone"
import {
  formSubmissionSlug as getFormSubmissionSlug,
  addAttachment as addFormSubmissionAttachment,
} from "reduxSlices/formSubmissionSlice"
import { convertMegabytesToBytes } from "utils/fileSizeHelpers"
import FormSubmissionAttachmentDropzoneContext from "../FormSubmissionAttachmentDropzoneContext"
import RejectedFile from "./RejectedFile"
import AcceptedFile from "./AcceptedFile"

// Max size for attachments is 35 MB
const MAX_ATTACHMENT_SIZE_IN_BYTES = convertMegabytesToBytes(35)

const ACCEPTED_FILE_CONTENT_TYPES = {
  "application/pdf": [],
  "application/msword": [],
  "application/vnd.apple.numbers": [],
  "application/vnd.apple.pages": [],
  "application/vnd.ms-excel": [],
  "application/vnd.ms-outlook": [],
  "application/vnd.ms-powerpoint": [],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
  "application/zip": [],
  "audio/vnd.wav": [],
  "audio/wave": [],
  "audio/wav": [],
  "audio/x-wav": [],
  "audio/x-pn-wav": [],
  "audio/mpeg": [],
  "audio/mpeg3": [],
  "audio/x-mpeg-3": [],
  "image/bmp": [],
  "image/gif": [],
  "image/jpeg": [],
  "image/png": [],
  "image/tiff": [],
  "message/rfc822": [], // .eml
  "text/csv": [],
  "video/mp4": [],
  "video/quicktime": [],
  "video/x-msvideo": [".avi"],
}

const FormSubmissionAttachmentDropzoneContextProvider = ({ children }) => {
  const dispatch = useDispatch()

  const [isDragOver, setIsDragOver] = useState(false)

  const [uploadInitiated, setUploadInitiated] = useState(false)

  const [acceptedFiles, setAcceptedFiles] = useState([])

  const [rejectedFiles, setRejectedFiles] = useState([])

  const formSubmissionSlug = useSelector(getFormSubmissionSlug)

  const uploadComplete = () => acceptedFiles.every((acceptedFile) => acceptedFile.attached || acceptedFile.error)

  const refreshUploadProgress = () => {
    if (!uploadComplete()) {
      setTimeout(refreshUploadProgress, 50)
    }

    setAcceptedFiles([...acceptedFiles])
  }

  useEffect(() => {
    if (uploadInitiated) {
      refreshUploadProgress()
    } else {
      setAcceptedFiles([])
      setRejectedFiles([])
    }
  }, [uploadInitiated])

  const onDrop = (accepted, rejected) => {
    setIsDragOver(false)

    if (!isEmpty(accepted) || !isEmpty(rejected)) {
      setUploadInitiated(true)
    }

    if (!isEmpty(rejected)) {
      setRejectedFiles(rejected.map((rejectedFileData) => new RejectedFile(rejectedFileData)))
    }

    if (!isEmpty(accepted)) {
      setAcceptedFiles(
        accepted.map((acceptedFileData) => {
          const acceptedFile = new AcceptedFile(
            acceptedFileData,
            formSubmissionSlug,
            (attachmentData) => dispatch(addFormSubmissionAttachment(attachmentData)),
          )

          acceptedFile.process()

          return acceptedFile
        }),
      )
    }
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: ACCEPTED_FILE_CONTENT_TYPES,
    maxSize: MAX_ATTACHMENT_SIZE_IN_BYTES,
    onDragEnter: () => setIsDragOver(true),
    onDragLeave: () => setIsDragOver(false),
    onDrop,
    noClick: true,
  })

  const resetDropzone = () => setUploadInitiated(false)

  const contextConsumerValue = {
    acceptedFiles,
    isOverAttachmentDropzone: isDragOver,
    getAttachmentDropzoneRootProps: getRootProps,
    getAttachmentDropzoneInputProps: getInputProps,
    openAttachmentFileDialog: open,
    rejectedFiles,
    resetDropzone,
    uploadComplete,
    uploadInitiated,
  }

  return (
    <FormSubmissionAttachmentDropzoneContext.Provider value={contextConsumerValue}>
      {children}
    </FormSubmissionAttachmentDropzoneContext.Provider>
  )
}

FormSubmissionAttachmentDropzoneContextProvider.propTypes = {
  children: types.node.isRequired,
}

export default FormSubmissionAttachmentDropzoneContextProvider
