import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"

const FormSubmissionExportedAuditItemDetails = ({ audit }) => {
  const hasComment = audit.comment

  return (
    <div>
      <span>
        <AuditedUser audit={audit} /> exported the form submission{ hasComment ? ` (${audit.comment}).` : "." }
      </span>
    </div>
  )
}

FormSubmissionExportedAuditItemDetails.propTypes = {
  audit: auditShape.isRequired,
}

export default FormSubmissionExportedAuditItemDetails
