import React, { useRef, useState, useEffect } from "react"
import types from "prop-types"
import clsx from "clsx"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const BACKGROUND_COLORS = [
  "bg-blue",
  "bg-purple",
  "bg-navy",
  "bg-red",
  "bg-orange",
  "bg-green",
]

export const getInitials = (user) => {
  if (!user) return ""

  const { firstName, lastName } = user
  if (!firstName || !lastName) return ""

  return `${firstName?.[0]}${lastName?.[0]}`.toLocaleUpperCase()
}

const getForegroundClass = (active) => {
  if (active) {
    return "text-primary-light";
  }

  return "text-gray-200";
}
/*
  Sums up the charCode for the initials and mods length of BACKGROUND_COLORS,
  so the same initials should get the same bg color
*/
const getBackgroundColor = (initials, active) => {
  if (!active) {
    return "bg-gray-100";
  }
  const charCodeSum = initials.charCodeAt(0) + initials.charCodeAt(1)
  const numOfColors = BACKGROUND_COLORS.length
  const backgroundColor = BACKGROUND_COLORS[charCodeSum % numOfColors]

  return backgroundColor ?? BACKGROUND_COLORS[0]
}

const getBackgroundClass = (initials, active) => getBackgroundColor(initials, active)

const ProfileIcon = ({ className = "", user = null }) => {
  const initials = getInitials(user)
  const initialContainerEl = useRef(null)

  const [fontSize, setFontSize] = useState("large")
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    (async () => {
      await document.fonts.ready
      if (initialContainerEl.current) {
        const containerWidth = initialContainerEl.current.clientWidth
        const size = containerWidth ? `${containerWidth * 0.5}px` : "large"
        setFontSize(size)
      }
    })()
  })

  if (!user || !initials) {
    return (
      <SvgSpriteIcon iconName="profile" className={`w-8 h-8 ${className}`} />
    )
  }

  const tooltipText = () => (user.active ? user.fullName : `${user.fullName} is inactive`)

  return (
    <div
      ref={initialContainerEl}
      className={clsx(
        getForegroundClass(user.active),
        getBackgroundClass(initials, user.active),
        "rounded-full font-semibold tracking-wide",
        "w-8 h-8 shrink-0",
        "flex items-center justify-center",
        className,
      )}
    >
      <span className="select-none" style={{ fontSize }} title={tooltipText(user)}>
        { initials }
      </span>
    </div>
  )
}

ProfileIcon.propTypes = {
  className: types.string,
  user: types.shape({
    firstName: types.string,
    lastName: types.string,
    fullName: types.string,
    active: types.bool,
  }),
}

export default ProfileIcon
