import React, { Component, createRef } from "react"
import types from "prop-types"
import Formsy from "formsy-react"
import { taskShape, questionShape } from "utils/propTypeShapes"
import Modal, { DEFAULT_MODAL_STYLE } from "shared/Modal"
import { PrimaryButton, IconButton, Button } from "shared/buttons"
import * as API from "services/api"
import EntryUuid from "./EntryUuid"
import EntryValue from "./EntryValue"

const MODAL_STYLE = {
  content: {
    ...DEFAULT_MODAL_STYLE.content,
    minWidth: "500px",
    maxWidth: "1000px",
    padding: 0,
  },
}

class EditEntryModal extends Component {
  state = { isEntryFormValid: false }

  formRef = createRef()

  entryData = () => this.formRef.current?.getModel() || { task: {} }

  enableSubmit = () => this.setState({ isEntryFormValid: true })

  disableSubmit = () => this.setState({ isEntryFormValid: false })

  onSubmit = async (model) => {
    const { task } = this.props
    const response = await API.updateTaskAnswer({ taskId: task.id, answer: model })

    if (!response.ok) {
      console.error(response)
    }
  }

  render() {
    const { isEntryFormValid } = this.state
    const {
      entryUuid,
      modalIsOpen,
      closeModal,
      question,
      answer,
    } = this.props

    if (!modalIsOpen) return null

    return (
      <Modal isOpen={modalIsOpen} style={MODAL_STYLE}>
        <h3 className="flex justify-center">
          Edit Entry
          <IconButton
            className="absolute right-4"
            icon="fas fa-times"
            onClick={closeModal}
          />
        </h3>
        <Formsy
          id="entry-form"
          onSubmit={this.onSubmit}
          onValid={this.enableSubmit}
          onInvalid={this.disableSubmit}
          ref={this.formRef}
        >
          <div className="p-8 bg-primary-light">
            <EntryUuid initialValue={entryUuid} />
            <EntryValue name={question.uuid} initialValue={answer} />
          </div>
          <div className="flex flex-col items-center py-5">
            <PrimaryButton
              form="entry-form"
              className="w-50"
              text="Save Entry"
              type="submit"
              disabled={!isEntryFormValid}
            />
            <Button
              className="border-none w-50"
              text="Cancel"
              onClick={closeModal}
            />
          </div>
        </Formsy>
      </Modal>
    )
  }
}

EditEntryModal.propTypes = {
  modalIsOpen: types.bool.isRequired,
  closeModal: types.func.isRequired,
  entryUuid: types.string.isRequired,
  task: taskShape.isRequired,
  question: questionShape.isRequired,
  answer: types.string.isRequired,
}

export default EditEntryModal
