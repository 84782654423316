import React from "react"
import types from "prop-types"

const DropdownListWrapper = ({ title, children }) => (
  <div className="px-3 pb-2">
    <div className="pb-1 font-bold text-md text-gray-800">{ title }</div>
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(9, 1fr)",
        gridGap: "0.25rem",
      }}
    >
      { children }
    </div>
  </div>
)

DropdownListWrapper.propTypes = {
  title: types.string.isRequired,
  children: types.node.isRequired,
}

export default DropdownListWrapper
