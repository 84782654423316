import React from "react"
import types from "prop-types"
import { workflowActionShape } from "utils/propTypeShapes"
import {
  ADD_DEPARTMENT,
  ADD_MEMBERS,
  CHANGE_STATUS,
  CREATE_TASK,
  REMOVE_ALL_MEMBERS,
  SEND_NOTIFICATION,
} from "utils/workflowHelpers"
import ChangeStatusActionLabel from "./ChangeStatusActionLabel"
import CreateTaskActionLabel from "./CreateTaskActionLabel"
import DefaultActionLabel from "./DefaultActionLabel"
import AddDepartmentActionLabel from "./AddDepartmentActionLabel"
import AddMembersActionLabel from "./AddMembersActionLabel"
import SendNotificationActionLabel from "./SendNotificationActionLabel"
import RemoveAllMembersActionLabel from "./RemoveAllMembersActionLabel"

const LABELS_FOR_ACTION_TYPES = {
  [ADD_DEPARTMENT]: AddDepartmentActionLabel,
  [ADD_MEMBERS]: AddMembersActionLabel,
  [CHANGE_STATUS]: ChangeStatusActionLabel,
  [CREATE_TASK]: CreateTaskActionLabel,
  [REMOVE_ALL_MEMBERS]: RemoveAllMembersActionLabel,
  [SEND_NOTIFICATION]: SendNotificationActionLabel,
}

const ActionLabel = ({ action, className = "" }) => {
  const { type } = action
  const ActionLabelForActionType = LABELS_FOR_ACTION_TYPES[type] || DefaultActionLabel

  return (
    <ActionLabelForActionType className={className} action={action} />
  )
}

ActionLabel.propTypes = {
  action: workflowActionShape.isRequired,
  className: types.string,
}

export default ActionLabel
