import React from "react"
import types from "prop-types"
import QuestionSidebarSection from "../QuestionSidebarSection"
import QuestionTagSelect from "./QuestionTagSelect"

const QuestionTagsSection = ({ sectionIndex, questionIndex, isFocused }) => (
  <QuestionSidebarSection
    description="Apply a tag, which can be used to open the form with an answer to this question provided."
    hide={!isFocused}
    title="Question Tag"
    headerClassName="py-2.5 text-dark"
  >
    <QuestionTagSelect sectionIndex={sectionIndex} questionIndex={questionIndex} />
  </QuestionSidebarSection>
)

QuestionTagsSection.propTypes = {
  isFocused: types.bool.isRequired,
  questionIndex: types.number.isRequired,
  sectionIndex: types.number.isRequired,
}

export default QuestionTagsSection
