import React from "react"
import { CustomIconButton } from "shared/buttons"
import CreateUserModal from "shared/modals/CreateUserModal"
import { ACTIVITY_HUB_USERS_IMPORTS_PATH, redirectTo } from "utils/routeHelpers"

const CreateUserButtons = () => (
  <div className="flex justify-center items-center">
    <CreateUserModal />
    <span className="mx-4 text-dark font-medium text-sm">or</span>
    <CustomIconButton
      text="Import From CSV"
      className="tertiary-button"
      icon="import-csv"
      onClick={() => redirectTo(ACTIVITY_HUB_USERS_IMPORTS_PATH)}
    />
  </div>
)

export default CreateUserButtons
