import React, { forwardRef } from "react"
import SelectionDropZone from "./DragAndDrop/SelectionDropZone"

const SelectableOptions = forwardRef(({
  ...rest
}, ref) => (
  <SelectionDropZone
    className="px-4 py-2 space-y-10"
    ref={ref}
    {...rest}
  />
))

SelectableOptions.displayName = "SelectableOptions"

export default SelectableOptions
