import React from "react"
import types from "prop-types"
import clsx from "clsx"
import {
  includesLowercaseLetter,
  includesUppercaseLetter,
  includesNumber,
  isMinLength,
  meetsPasswordStrengthRequirement,
} from "utils/validationHelpers"
import PasswordRequirement from "./PasswordRequirement"

const VALIDATIONS = [
  includesLowercaseLetter,
  includesUppercaseLetter,
  includesNumber,
  isMinLength,
  meetsPasswordStrengthRequirement,
]

const PasswordRequirements = ({ className = "", value = "" }) => (
  <div className={clsx("text-left", className)}>
    <p className="text-sm font-semibold mb-1">Password Requirements:</p>
    <ul className="list-none p-0 m-0 mb-4">
      {
        VALIDATIONS.map((validation) => (
          <li key={validation.message} className="text-sm mb-1 last:mb-0">
            <PasswordRequirement requirementMet={validation.test(value)} label={validation.message} />
          </li>
        ))
      }
    </ul>
  </div>
)

PasswordRequirements.propTypes = {
  className: types.string,
  value: types.string,
}

export default PasswordRequirements
