import React from "react"
import types from "prop-types"
import { DateInput } from "shared/inputs"
import { tomorrowDate } from "utils/dateHelpers"

const DailyRecurrenceDetails = ({ setStartDate, startDate }) => {
  if (startDate && typeof startDate !== "string") {
    startDate = startDate.format("YYYY-MM-DD")
  }

  return (
    <div className="flex items-center mr-2">
      <div className="mr-2">starting on</div>
      <DateInput
        min={tomorrowDate()}
        required
        name="start_date"
        value={startDate}
        onChange={setStartDate}
      />
    </div>
  )
}
DailyRecurrenceDetails.propTypes = {
  setStartDate: types.func.isRequired,
  startDate: types.object.isRequired,
}

export default DailyRecurrenceDetails
