import React from "react"
import { useSelector } from "react-redux"
import {
  relatedFormSubmissions as getRelatedFormSubmissions,
} from "reduxSlices/formSubmissionSlice"
import RelatedFormSubmission from "./RelatedFormSubmission"

const RelatedFormSubmissionsList = () => {
  const relatedFormSubmissions = useSelector(getRelatedFormSubmissions)

  return (
    <div className="mb-8">
      {
        relatedFormSubmissions.map((submission) => (
          <RelatedFormSubmission
            key={submission.id}
            submission={submission}
          />
        ))
      }
    </div>
  )
}

export default RelatedFormSubmissionsList
