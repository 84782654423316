import React from "react"
import types from "prop-types"

const TileGrid = ({ children }) => (
  <div className="grid gap-10 grid-cols-tile auto-rows-fr">
    {children}
  </div>
)

TileGrid.propTypes = {
  children: types.node.isRequired,
}

export default TileGrid
