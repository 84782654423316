import React from "react"
import types from "prop-types"
import RequirementLevelSelectInput from "./RequirementLevelSelectInput"
import RequirementAction from "./RequirementAction"

const CategoryAction = ({
  id, index, action, accessLevel, accessLevelOptions,
}) => (
  <tr className="h-16 rounded-tr bordered-table-row">
    <RequirementAction id={id} index={index} value={action} />
    <RequirementLevelSelectInput
      name={`access_level_requirements_attributes[${index}].access_level`}
      accessLevelOptions={accessLevelOptions}
      value={accessLevel}
    />
  </tr>
)

CategoryAction.propTypes = {
  id: types.number.isRequired,
  index: types.number.isRequired,
  action: types.string.isRequired,
  accessLevel: types.string.isRequired,
  accessLevelOptions: types.arrayOf(types.string).isRequired,
}

export default CategoryAction
