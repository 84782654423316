import React, { useEffect, useState } from "react"
import types from "prop-types"
import { isEmpty, sortBy } from "lodash-es"
import * as API from "services/api"
import { useReportableFieldLocationAnswerFilter, useSubmissionDateFilter } from "hooks"
import { reportExportSolutionLevelReportShape } from "utils/propTypeShapes"
import { errorToast } from "components/shared/toast"
import { CSV_EXPORT_TYPE } from "utils/reportExportHelpers"
import SolutionLevelReportExportContext from "../SolutionLevelReportExportContext"

const LOAD_REPORTABLE_FIELDS_ERROR_MESSAGE = "Something went wrong.  Unable to load reportable fields."

const REPORTABLE_FIELD_DATE_ANSWER_FILTER_TEMPLATE = {
  reportableFieldId: null,
  earliestDate: null,
  latestDate: null,
}

const SolutionLevelReportExportContextProvider = ({ children, solutionLevelReport: propSolutionLevelReport }) => {
  const {
    earliestSubmissionDate, latestSubmissionDate, setEarliestSubmissionDate, setLatestSubmissionDate,
  } = useSubmissionDateFilter()

  const {
    addAllFacilityDepartmentsToReportableFieldLocationAnswerFilters,
    addDepartmentIdsToReportableFieldLocationAnswerFilters,
    departments,
    facilities,
    facilityDepartments,
    removeAllFacilityDepartmentsFromReportableFieldLocationAnswerFilters,
    removeDepartmentIdsFromReportableFieldLocationAnswerFilters,
    reportableFieldLocationAnswerFilters,
    setReportableFieldLocationAnswerFilterReportableFieldId,
  } = useReportableFieldLocationAnswerFilter()

  const [solutionLevelReport] = useState(propSolutionLevelReport)
  const { categorySlug } = solutionLevelReport

  const [reportExport, setReportExport] = useState({})

  // For filtering by answers
  const [reportableFields, setReportableFields] = useState([])

  const [reportExportFileType, setReportExportFileType] = useState(CSV_EXPORT_TYPE)

  useEffect(() => {
    const loadReportableFields = async () => {
      const response = await API.getCategoryReportableFields({ categorySlug })

      if (response.ok) {
        setReportableFields(sortBy(response.data, "name"))
      } else {
        errorToast(LOAD_REPORTABLE_FIELDS_ERROR_MESSAGE)
      }
    }

    loadReportableFields()
  }, [categorySlug])

  // ... reportable field date answers
  const [reportableFieldDateAnswerFilters, setReportableFieldDateAnswerFilters] = useState([])

  const setReportableFieldDateAnswerFilterReportableFieldId = (reportableFieldId) => {
    let newReportableFieldDateFilters = []

    if (reportableFieldId) {
      const currentReportableFieldDateFilter = reportableFieldDateAnswerFilters[0] ?? { ...REPORTABLE_FIELD_DATE_ANSWER_FILTER_TEMPLATE }
      newReportableFieldDateFilters = [{ ...currentReportableFieldDateFilter, reportableFieldId }]
    }

    setReportableFieldDateAnswerFilters(newReportableFieldDateFilters)
  }

  const setReportableFieldDateAnswerFilterEarliestDate = (date) => {
    if (isEmpty(date)) date = null

    const currentReportableFieldDateFilter = reportableFieldDateAnswerFilters[0]

    if (!currentReportableFieldDateFilter) return

    setReportableFieldDateAnswerFilters([{ ...currentReportableFieldDateFilter, earliestDate: date }])
  }

  const setReportableFieldDateAnswerFilterLatestDate = (date) => {
    if (isEmpty(date)) date = null

    const currentReportableFieldDateFilter = reportableFieldDateAnswerFilters[0]

    if (!currentReportableFieldDateFilter) return

    setReportableFieldDateAnswerFilters([{ ...currentReportableFieldDateFilter, latestDate: date }])
  }

  // Object exposed to context consumers
  const contextConsumerValue = {
    addAllFacilityDepartmentsToReportableFieldLocationAnswerFilters,
    addDepartmentIdsToReportableFieldLocationAnswerFilters,
    departments,
    earliestSubmissionDate,
    facilities,
    facilityDepartments,
    latestSubmissionDate,
    removeAllFacilityDepartmentsFromReportableFieldLocationAnswerFilters,
    removeDepartmentIdsFromReportableFieldLocationAnswerFilters,
    reportableFieldDateAnswerFilters,
    reportableFieldLocationAnswerFilters,
    reportableFields,
    reportExport,
    reportExportFileType,
    setEarliestSubmissionDate,
    setLatestSubmissionDate,
    setReportableFieldDateAnswerFilterEarliestDate,
    setReportableFieldDateAnswerFilterLatestDate,
    setReportableFieldDateAnswerFilterReportableFieldId,
    setReportableFieldLocationAnswerFilterReportableFieldId,
    setReportExport,
    setReportExportFileType,
    solutionLevelReport,
  }

  return (
    <SolutionLevelReportExportContext.Provider value={contextConsumerValue}>
      {children}
    </SolutionLevelReportExportContext.Provider>
  )
}

SolutionLevelReportExportContextProvider.propTypes = {
  children: types.node.isRequired,
  solutionLevelReport: reportExportSolutionLevelReportShape.isRequired,
}

export default SolutionLevelReportExportContextProvider
