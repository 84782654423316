import React from "react"
import types from "prop-types"
import { FormsySelect } from "shared/selects"
import { snakeToCapitalCase } from "utils/stringHelpers"

const RequirementLevelSelectInput = ({ name, accessLevelOptions, value }) => {
  const options = accessLevelOptions.map((accessLevel) => (
    { value: accessLevel, label: snakeToCapitalCase(accessLevel) }
  ))

  return (
    <td>
      <FormsySelect
        name={name}
        options={options}
        value={value}
        backspaceDelete={false}
      />
    </td>
  )
}

RequirementLevelSelectInput.propTypes = {
  name: types.string.isRequired,
  accessLevelOptions: types.arrayOf(types.string).isRequired,
  value: types.string.isRequired,
}

export default RequirementLevelSelectInput
