import React from "react"
import types from "prop-types"
import { useModal } from "hooks"
import { CircularIconButton } from "shared/buttons"
import Modal, { DEFAULT_MODAL_STYLE, ModalHeader } from "shared/Modal"
import Tooltip from "shared/Tooltip"
import SubmissionFormTypeConverter from "./SubmissionFormTypeConverter"

const MODAL_STYLE = {
  content: {
    ...DEFAULT_MODAL_STYLE.content,
    top: "47%",
  },
}

const ChangeSubmissionFormType = ({ onFormTypeChange }) => {
  const { modalIsOpen, openModal, closeModal } = useModal()

  return (
    <>
      <Tooltip text="Change form type">
        <CircularIconButton icon="pencil" onClick={openModal} />
      </Tooltip>
      <Modal isOpen={modalIsOpen} style={MODAL_STYLE}>
        <ModalHeader
          closeModal={closeModal}
          heading="Change Form Type"
        />
        <p className="text-sm text-center text-gray mb-8 w-5/6 mx-auto">
          Only answers to reporting fields will be retained.
          All other answers must be manually entered.
        </p>
        <SubmissionFormTypeConverter
          onCancel={closeModal}
          onFormTypeChange={() => {
            closeModal()
            onFormTypeChange()
          }}
        />
      </Modal>
    </>
  )
}

ChangeSubmissionFormType.propTypes = {
  onFormTypeChange: types.func.isRequired,
}

export default ChangeSubmissionFormType
