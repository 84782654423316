import React from "react"
import types from "prop-types"
import * as API from "services/api"
import { CustomIconButton } from "shared/buttons"
import { errorToast } from "shared/toast"

// Metabase dashboard URLs look like
// https://metabase.example.com/dashboard/4-solutions-dashboard
const METABASE_DASHBOARD_URL_PATTERN = /\/dashboard\//

const ExportMetabaseDashboardButton = ({
  exportInProgress,
  metabaseUrl,
  setExportInProgress,
  setImageCapture,
}) => {
  const startExport = async () => {
    const response = await API.createMetabaseDashboardImageCapture({ dashboardUrl: metabaseUrl })

    if (response.ok) {
      setExportInProgress(true)
      setImageCapture(response.data)
    } else {
      setExportInProgress(false)
      errorToast("Something went wrong.  Unable to initiate export")
    }
  }

  if (!metabaseUrl.match(METABASE_DASHBOARD_URL_PATTERN)) return null

  return (
    <CustomIconButton
      className="tertiary-button"
      disabled={exportInProgress}
      icon="export"
      onClick={startExport}
      text="Export Dashboard Charts"
    />
  )
}

ExportMetabaseDashboardButton.propTypes = {
  exportInProgress: types.bool.isRequired,
  metabaseUrl: types.string.isRequired,
  setExportInProgress: types.func.isRequired,
  setImageCapture: types.func.isRequired,
}

export default ExportMetabaseDashboardButton
