import React, { useState } from "react"
import types from "prop-types"
import { categoryOptionShape } from "utils/propTypeShapes"
import { FormsyHiddenInput } from "shared/inputs"
import { CategorySelect } from "shared/selects"
import FieldLabel from "../FieldLabel"

const TargetCategoryIdField = ({ categories }) => {
  const [targetCategoryId, setTargetCategoryId] = useState(null)

  return (
    <FieldLabel text="Destination solution">
      <CategorySelect
        options={categories}
        value={null}
        onChange={setTargetCategoryId}
      />
      <FormsyHiddenInput
        name="target_category_id"
        required
        value={targetCategoryId}
      />
    </FieldLabel>
  )
}

TargetCategoryIdField.propTypes = {
  categories: types.arrayOf(categoryOptionShape).isRequired,
}

export default TargetCategoryIdField
