import React from "react"
import types from "prop-types"
import { reportExportFormSubmissionReportShape } from "utils/propTypeShapes"
import { FormSubmissionScheduledReportExportContextProvider } from "contexts/FormSubmissionScheduledReportExportContext"
import NewFormSubmissionScheduledReportExportForm from "../NewFormSubmissionScheduledReportExport/NewFormSubmissionScheduledReportExportForm"

const EditFormSubmissionScheduledReportExport = ({ formSubmissionReport, formSubmissionScheduledReportExport }) => (
  <FormSubmissionScheduledReportExportContextProvider
    formSubmissionReport={formSubmissionReport}
    formSubmissionScheduledReportExport={formSubmissionScheduledReportExport}
  >
    <NewFormSubmissionScheduledReportExportForm />
  </FormSubmissionScheduledReportExportContextProvider>
)

EditFormSubmissionScheduledReportExport.propTypes = {
  formSubmissionReport: reportExportFormSubmissionReportShape.isRequired,
  formSubmissionScheduledReportExport: types.object.isRequired,
}

export default EditFormSubmissionScheduledReportExport
