const PROCESSING_STATUS_INITIAL = "received"
const PROCESSING_STATUS_IN_PROGRESS = "in progress"
const PROCESSING_STATUS_DONE = "done"
const PROCESSING_STATUS_FAILED = "failed"

export const processingReceived = ({ processable }) => (
  processable.processingStatus === PROCESSING_STATUS_INITIAL
)

export const processingInProgress = ({ processable }) => (
  processable.processingStatus === PROCESSING_STATUS_IN_PROGRESS
)

export const processingDone = ({ processable }) => (
  processable.processingStatus === PROCESSING_STATUS_DONE
)

export const processingFailed = ({ processable }) => (
  processable.processingStatus === PROCESSING_STATUS_FAILED
)
