import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { formSubmissionData } from "reduxSlices/formSubmissionSlice"
import { useFormSubmissionFormContext } from "contexts/FormSubmissionFormContext"
import SectionBranchingConditionalCheck from "views/Forms/FormElements/SectionBranchingConditionalCheck"
import SubmissionSection from "../SubmissionSection"
import OverviewQuestion from "./OverviewQuestion"

const OverviewSection = ({ isFirstSection, section }) => {
  const { questions } = section
  const { answers } = useSelector(formSubmissionData)

  const { newQuestions } = useFormSubmissionFormContext()

  const newQuestionUuids = newQuestions.map((question) => question.uuid)

  return (
    <SectionBranchingConditionalCheck
      isFirstSection={isFirstSection}
      formAnswers={answers}
      section={section}
    >
      <SubmissionSection section={section}>
        <ol>
          {
            questions.map((question) => (
              <li key={question.uuid}>
                <OverviewQuestion
                  question={question}
                  isNewQuestion={newQuestionUuids.includes(question.uuid)}
                  answers={answers}
                />
              </li>
            ))
          }
        </ol>
      </SubmissionSection>
    </SectionBranchingConditionalCheck>
  )
}

OverviewSection.propTypes = {
  isFirstSection: types.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  section: types.object.isRequired,
}

export default OverviewSection
