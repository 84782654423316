import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { taskShape } from "utils/propTypeShapes"
import TaskIcon from "./TaskIcon"

export const taskTextIdentifier = (task) => task.subform?.title ?? task.title

const TaskShortcut = ({ className = "", onClick, task }) => (
  <button className={clsx(className, "flex items-center gap-2 text-primary max-w-full")} onClick={onClick}>
    <TaskIcon className="w-8 h-8" isOpen={task.isOpen} isSubform={!!task.subform} />
    <span className="truncate text-sm" title={taskTextIdentifier(task)}>
      {taskTextIdentifier(task)}
    </span>
  </button>
)

TaskShortcut.propTypes = {
  className: types.string,
  onClick: types.func.isRequired,
  task: taskShape.isRequired,
}

export default TaskShortcut
