import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { isEmpty } from "lodash-es"
import { AuditedUser } from "shared/audits"

const memberDescription = ({ departmentMember }) => {
  const { fullName } = departmentMember

  if (fullName) {
    return (<>member <span className="font-semibold">{fullName} </span></>)
  }

  return ("a member ")
}

const DepartmentMembershipUpdated = ({ audit }) => {
  const { auditedChanges } = audit
  const { segment = [] } = auditedChanges

  if (isEmpty(segment)) return null

  return (
    <div>
      <span>
        <AuditedUser audit={audit} /> moved {memberDescription(audit)}
        from <span className="font-semibold">Segment {segment[0].toUpperCase()} </span>
        to <span className="font-semibold"> Segment {segment[1].toUpperCase()} </span>
        of department <span className="font-semibold"> {audit.department.name}</span>
      </span>
    </div>
  )
}

DepartmentMembershipUpdated.propTypes = {
  audit: auditShape.isRequired,
}

export default DepartmentMembershipUpdated
