import React from "react"
import types from "prop-types"
import Switch from "components/shared/Switch"

const ActiveStatus = ({ activeStatus = false, setActiveStatus }) => (
  <div className="flex justify-between items-start">
    <div>
      <p className="font-bold mb-2">Active</p>
      <p>Automatic uploads will be <strong>{activeStatus ? "active" : "inactive"}</strong> when saved.</p>
    </div>
    <Switch
      checked={activeStatus}
      onChange={setActiveStatus}
    />
  </div>
)

ActiveStatus.propTypes = {
  activeStatus: types.bool,
  setActiveStatus: types.func.isRequired,
}

export default ActiveStatus
