import React, { useCallback, useState } from "react"
import Formsy from "formsy-react"
import { useModal } from "hooks"
import * as API from "services/api"
import { useSurveyBatchContext } from "contexts/SurveyBatchContext"
import { PrimaryButton, Button } from "shared/buttons"
import { FormsyTextInput } from "shared/inputs"
import Modal, { ModalHeader } from "shared/Modal"
import { successToast, errorToast } from "shared/toast"

const CreateContactForSurveyBatch = () => {
  const { modalIsOpen, openModal, closeModal } = useModal()
  const { addSurveyToken, surveyBatch } = useSurveyBatchContext()
  const { id: surveyBatchId } = surveyBatch
  const [isFormValid, setIsFormValid] = useState(false)

  const close = useCallback(() => {
    closeModal()
    setIsFormValid(false)
  }, [closeModal, setIsFormValid])

  const enableSubmit = () => setIsFormValid(true)
  const disableSubmit = () => setIsFormValid(false)

  const onSubmit = async (model) => {
    const response = await API.createSurveyToken({ surveyBatchId, surveyTokenWithContact: model })

    if (response.ok) {
      successToast("Contact created successfully!")
      closeModal()
      addSurveyToken(response.data)
    } else {
      console.error("Error creating contact: ", response)
      errorToast("Something went wrong. Unable to create contact.", response)
    }
  }

  return (
    <>
      <div className="flex">
        <Button
          text="+ Add Contact"
          onClick={openModal}
          className="text-focus border border-light my-4"
        />
      </div>

      <Modal isOpen={modalIsOpen}>
        <ModalHeader closeModal={closeModal} heading="New Contact" />
        <Formsy
          onSubmit={onSubmit}
          onValid={enableSubmit}
          onInvalid={disableSubmit}
        >
          <div className="w-3/4 mx-auto">
            <div className="flex-center">
              <div className="mr-3">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>First Name</label>
                <FormsyTextInput
                  className="mb-3 mt-1 bordered-base-input text-sm"
                  name="first_name"
                  placeholder="First Name"
                  value=""
                />
              </div>
              <div className="mr-3">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>Last Name</label>
                <FormsyTextInput
                  className="mb-3 mt-1 bordered-base-input text-sm"
                  name="last_name"
                  placeholder="Last Name"
                  value=""
                />
              </div>
            </div>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="required-prompt">Email</label>
            <FormsyTextInput
              className="mb-3 mt-1 bordered-base-input text-sm"
              name="email"
              placeholder="Email"
              value=""
              required
              validations={{ isNotBlankString: true, isEmail: true }}
              validationErrors={{ isDefaultRequiredValue: "required", isEmail: "Invalid email address" }}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Reminder Email Cc:</label>
            <FormsyTextInput
              className="mb-3 mt-1 bordered-base-input text-sm"
              name="reminder_cc_email"
              placeholder="cc:"
              value=""
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Comment</label>
            <FormsyTextInput
              className="mb-3 mt-1 bordered-base-input text-sm"
              name="comment"
              placeholder="location, note, etc."
              value=""
            />
            <div className="text-xs">
              Note: Contact will be emailed this survey only. If you want them to receive future surveys, add them to survey configuration.
            </div>
            <div className="flex justify-center mt-8">
              <Button
                text="Cancel"
                onClick={close}
                className="border border-light-300 mr-2"
              />
              <PrimaryButton
                text="Add"
                type="submit"
                disabled={!isFormValid}
              />
            </div>
          </div>
        </Formsy>
      </Modal>
    </>
  )
}

export default CreateContactForSurveyBatch
