import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import IconContainer from "shared/icons/IconContainer"

const TaskFormsIcon = ({ className = "" }) => (
  <IconContainer className={className}>
    <SvgSpriteIcon iconName="task-form" style={{ height: "82%", width: "82%" }} />
  </IconContainer>
)

TaskFormsIcon.propTypes = {
  className: types.string,
}

export default TaskFormsIcon
