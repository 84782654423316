import React from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { deselectCategory, selectCategory, isCategorySelected } from "reduxSlices/formIndexSlice"
import { Checkbox } from "shared/checkboxes"
import { SidebarContentItem } from "shared/Sidebar"

const FormIndexSidebarCategorySectionCategoryCheckbox = ({ categoryName }) => {
  const dispatch = useDispatch()

  const categoryIsSelected = useSelector(isCategorySelected(categoryName))

  const toggleReducer = categoryIsSelected ? deselectCategory : selectCategory

  const toggleSelection = () => dispatch(toggleReducer({ categoryName }))

  return (
    <SidebarContentItem onClickCapture={toggleSelection}>
      <div className="flex justify-between">
        <span>{categoryName}</span>
        <Checkbox onChange={toggleSelection} value={categoryIsSelected} />
      </div>
    </SidebarContentItem>
  )
}

FormIndexSidebarCategorySectionCategoryCheckbox.propTypes = {
  categoryName: types.string.isRequired,
}

export default FormIndexSidebarCategorySectionCategoryCheckbox
