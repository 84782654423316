import queryParameter from "utils/queryParameter"

const TAB_QUERY_PARAM_KEY = "tab"

export const getInitialActiveIndexFromQueryParams = (tabs) => {
  const tabTitle = queryParameter(TAB_QUERY_PARAM_KEY)
  const queryParamTabIndex = tabs.findIndex(({ title }) => title === tabTitle)

  return queryParamTabIndex === -1 ? 0 : queryParamTabIndex
}
