import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import FormTypeCard from "./FormTypeCard"

const TaskFormCard = ({ onClick }) => (
  <FormTypeCard
    footerColor="bg-task-form-color"
    description="Forms completed in the Activity Hub as follow-up to Submissions."
    icon={<SvgSpriteIcon iconName="task-form" className="w-10 h-10" />}
    title="Task Form"
    onClick={onClick}
  />
)

TaskFormCard.propTypes = {
  onClick: types.func.isRequired,
}

export default TaskFormCard
