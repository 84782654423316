/* eslint-disable camelcase */
import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"
import AuditAnswerDetails from "../AuditAnswerDetails"

const FormSubmissionEditedAuditItemDetails = ({ audit }) => {
  const { auditedChanges } = audit
  const { discarded_at } = auditedChanges

  return (
    <div>
      <span className="block">
        <AuditedUser audit={audit} /> edited the form submission.
      </span>
      {
        !discarded_at && (
          Object.keys(auditedChanges).map((prompt) => (
            <AuditAnswerDetails key={prompt} prompt={prompt} audit={audit} />
          ))
        )
      }
    </div>
  )
}

FormSubmissionEditedAuditItemDetails.propTypes = {
  audit: auditShape.isRequired,
}

export default FormSubmissionEditedAuditItemDetails
