import React from "react"
import { useSelector } from "react-redux"
import {
  relatedFormSubmissions as getRelatedFormSubmissions,
} from "reduxSlices/formSubmissionSlice"
import { CountBadge } from "components/shared/badges"

const SupplementalCountBadge = () => {
  const relatedFormSubmissions = useSelector(getRelatedFormSubmissions)
  const count = relatedFormSubmissions.length

  return count ? <CountBadge count={count} /> : null
}

export default SupplementalCountBadge
