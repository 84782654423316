import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const CheckmarkCircleIcon = ({ className = "w-8 h-8" }) => <SvgSpriteIcon iconName="checkmark-circle" className={className} />

CheckmarkCircleIcon.propTypes = {
  className: types.string,
}

export default CheckmarkCircleIcon
