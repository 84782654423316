import React from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import {
  addQuestionAttributeCaclQuestionUuid,
  getFormNumberTypeQuestionCount,
  getFormQuestionAttributeCalcQuestionUuidsCount,
} from "reduxSlices/formBuilderSlice"
import { TertiaryButton } from "shared/buttons"

const AddQuestionButton = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const totalNumberTypeQuestionCount = useSelector(getFormNumberTypeQuestionCount)
  const calcQuestionsCount = useSelector(getFormQuestionAttributeCalcQuestionUuidsCount({ sectionIndex, questionIndex }))

  const addCalcQuestion = () => dispatch(addQuestionAttributeCaclQuestionUuid({ sectionIndex, questionIndex }))

  const isAddQuestionVisible = (calcQuestionsCount < totalNumberTypeQuestionCount)

  return isAddQuestionVisible && (
    <TertiaryButton
      text="Add Question"
      onClick={addCalcQuestion}
    />
  )
}

AddQuestionButton.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default AddQuestionButton
