import React from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { getFormIsSubform, getFormQuestionAttributeSetCurrentTime, setQuestionAttributeSetCurrentTime } from "reduxSlices/formBuilderSlice"
import { SwitchInput } from "shared/inputs"

const SetCurrentTime = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const isSubform = useSelector(getFormIsSubform)
  const questionSetCurrentTime = useSelector(getFormQuestionAttributeSetCurrentTime({ sectionIndex, questionIndex }))

  const updateSetCurrentTime = (newSetCurrentTime) => dispatch(setQuestionAttributeSetCurrentTime({
    sectionIndex, questionIndex, newSetCurrentTime,
  }))

  if (isSubform) return null

  return (
    <div className="mb-4 flex-center">
      <div className="font-medium">Default to Current Time</div>
      <SwitchInput
        value={questionSetCurrentTime}
        onChange={updateSetCurrentTime}
      />
    </div>
  )
}

SetCurrentTime.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default SetCurrentTime
