import React from "react"
import { useFormSubmissionAuditTrailContext } from "contexts/FormSubmissionAuditTrailContext"
import { TertiaryButton } from "shared/buttons"

const LoadMoreButton = () => {
  const { allAuditsLoaded, fetchAudits } = useFormSubmissionAuditTrailContext()

  if (allAuditsLoaded) return null

  return (
    <TertiaryButton className="block mx-auto" onClick={fetchAudits} text="Load more" />
  )
}

export default LoadMoreButton
