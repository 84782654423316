import React from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import {
  getFormQuestionAttributeIsDropdown, getFormQuestionAttributeMax, getFormQuestionAttributeMin, setQuestionAttributeMin,
} from "reduxSlices/formBuilderSlice"
import FormsyValidation from "shared/FormsyValidation"
import { NumberInput } from "shared/inputs"

const oneBelow = (number) => {
  if (!number && number !== 0) return number

  return number - 1
}

const Min = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const questionMin = useSelector(getFormQuestionAttributeMin({ sectionIndex, questionIndex }))
  const questionMax = useSelector(getFormQuestionAttributeMax({ sectionIndex, questionIndex }))
  const questionIsDropdown = useSelector(getFormQuestionAttributeIsDropdown({ sectionIndex, questionIndex }))

  const updateMin = (newMin) => dispatch(setQuestionAttributeMin({
    sectionIndex, questionIndex, newMin,
  }))

  return (
    <div className="mb-4 flex-center">
      <div className="font-medium">Min</div>
      <div className="w-2/5">
        <NumberInput
          className="bordered-base-input h-8 text-sm"
          value={questionMin}
          placeholder="Min"
          max={oneBelow(questionMax)}
          onChange={updateMin}
        />
        <FormsyValidation
          name={`sections[${sectionIndex}].questions[${questionIndex}].attributes.min`}
          required={questionIsDropdown}
          value={questionMin}
          validations={{
            isNumberLessThanOrEqualTo: {
              max: (questionMax || questionMax === 0) ? questionMax - 1 : null,
              errorMessage: "Must be less than the max",
            },
            isNumberGreaterThanOrEqualTo: {
              min: (questionMin < 0) ? 0 : null,
              errorMessage: "Must be zero or greater",
            },
          }}
          validationErrors={{
            isDefaultRequiredValue: "Min required",
          }}
        />
      </div>
    </div>
  )
}

Min.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default Min
