import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const OpenSidebarIcon = ({ className = "w-8 h-8" }) => (
  <SvgSpriteIcon iconName="open-sidebar" className={className} />
)

OpenSidebarIcon.propTypes = {
  className: types.string,
}

export default OpenSidebarIcon
