import React, { useState } from "react"
import { useSurveyConfigurationContext } from "contexts/SurveyConfigurationContext"
import SurveyConfigurationEdit from "./SurveyConfigurationEdit"
import SettingsTitle from "./SettingsTitle"
import SurveyConfigurationOverview from "./SurveyConfigurationOverview"

export const SETTINGS_HEADING = "Settings"

const SurveyConfigurationSettingsTab = () => {
  const { surveyConfiguration } = useSurveyConfigurationContext()
  const [editMode, setEditMode] = useState(false)
  const openEditMode = () => setEditMode(true)
  const closeEditMode = () => setEditMode(false)

  return (
    <>
      <SettingsTitle
        editMode={editMode}
        openEditMode={openEditMode}
        heading={SETTINGS_HEADING}
      />

      <div>
        {
          editMode
            ? (
              <SurveyConfigurationEdit
                surveyConfiguration={surveyConfiguration}
                activeTab={SETTINGS_HEADING}
                closeEditMode={closeEditMode}
              />
            )
            : <SurveyConfigurationOverview />
        }
      </div>
    </>
  )
}

export default SurveyConfigurationSettingsTab
