import React from "react"
import Switch from "shared/Switch"
import { SidebarCollapsibleContent } from "shared/Sidebar"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getTaskTable } from "reduxSlices/taskTableSlice"

const ClosedTasksFilter = ({ toggleShowClosedTasks }) => {
  const { showOnlyClosedTasks } = useSelector(getTaskTable)

  return (
    <SidebarCollapsibleContent title="Options">
      <div className="flex-center text-sm font-semibold">
        <span>Show closed tasks</span>
        <Switch
          checked={showOnlyClosedTasks}
          onChange={toggleShowClosedTasks}
        />
      </div>
      <div className="mt-3 text-sm">
        Will show only the tasks closed in the last 7 days
      </div>
    </SidebarCollapsibleContent>
  )
}

ClosedTasksFilter.propTypes = {
  toggleShowClosedTasks: types.func.isRequired,
}

export default ClosedTasksFilter
