import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const NonRecurringSurveyConfigurationIcon = ({ className = "w-8 h-8", ...rest }) => (
  <SvgSpriteIcon iconName="survey-one" className={className} {...rest} />
)

NonRecurringSurveyConfigurationIcon.propTypes = {
  className: types.string,
}

export default NonRecurringSurveyConfigurationIcon
