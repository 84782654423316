const SHORT_DAY_DATE_FORMAT = "ddd, MMMM D, YYYY"
export const CREATE_SCHEDULED_REPORT_EXPORT_ERROR_MESSAGE = "Something went wrong. Unable to save scheduled report export."
export const MAX_DAYS = 14
export const MAX_WEEKS = 10
export const MAX_MONTHS = 12

export const SUBMISSION_DATE_FILTER_HEADER = "Filter by Submission Date"
export const DATE_QUESTION_FILTER_HEADER = "Filter by the Answer to a Date Question"
export const REPORTABLE_FIELD_DATE_FILTER_HEADER = "Filter by Reportable Fields Date Answer"

/* eslint-disable max-len */
export const dynamicRangeSubmissionsExample = ({ startDate, beginningDate, endingDate }) => (
  `For example, a report run on ${startDate.format(SHORT_DAY_DATE_FORMAT)}, will include submissions from 00:00 ${beginningDate.format(SHORT_DAY_DATE_FORMAT)} to 23:59 ${endingDate.format(SHORT_DAY_DATE_FORMAT)}.`
)

export const dynamicRangeAnswersExample = ({ startDate, beginningDate, endingDate }) => (
  `For example, a report run on ${startDate.format(SHORT_DAY_DATE_FORMAT)}, will include submissions where the answer is between 00:00 ${beginningDate.format(SHORT_DAY_DATE_FORMAT)} and 23:59 ${endingDate.format(SHORT_DAY_DATE_FORMAT)}.`
)

export const dynamicRangeReportableFieldsExample = ({ startDate, beginningDate, endingDate }) => (
  `For example, a report run on ${startDate.format(SHORT_DAY_DATE_FORMAT)}, will include submissions where the reportable field is between 00:00 ${beginningDate.format(SHORT_DAY_DATE_FORMAT)} and 23:59 ${endingDate.format(SHORT_DAY_DATE_FORMAT)}.`
)

export const dynamicRangeSurveysExample = ({ startDate, beginningDate, endingDate }) => (
  `For example, a report run on ${startDate.format(SHORT_DAY_DATE_FORMAT)}, will include submissions from surveys sent between 00:00 ${beginningDate.format(SHORT_DAY_DATE_FORMAT)} and 23:59 ${endingDate.format(SHORT_DAY_DATE_FORMAT)}.`
)

export const dayRangeSubmissionInclusionDescription = "The report will not include submissions from the day on which the report is generated."
export const dayRangeAnswerInclusionDescription = "The report will not include submissions where the answer value is the day on which the report is generated."
export const dayRangeReportableFieldInclusionDescription = "The report will not include submissions where the reportable field value is the day on which the report is generated."
export const dayRangeSurveyInclusionDescription = "The report will not include submissions from surveys sent on the day the report is generated."

export const weekRangeSubmissionInclusionDescription = "A week is Sunday to Saturday, and the report will not include submissions from the week in which the report is generated."
export const weekRangeAnswerInclusionDescription = "A week is Sunday to Saturday, and the report will not include submissions where the answer value is from the week in which the report is generated."
export const weekRangeReportableFieldInclusionDescription = "A week is Sunday to Saturday, and the report will not include submissions where the reportable field value is from the week in which the report is generated."
export const weekRangeSurveyInclusionDescription = "A week is Sunday to Saturday, and the report will not include submissions from surveys sent during the week in which the report is generated."

export const monthRangeSubmissionInclusionDescription = "The report will not include submissions from the calendar month in which the report is generated."
export const monthRangeAnswerInclusionDescription = "The report will not include submissions where the answer value is in the calendar month in which the report is generated."
export const monthRangeReportableFieldInclusionDescription = "The report will not include submissions where the reportable field value is in the calendar month in which the report is generated."
export const monthRangeSurveyInclusionDescription = "The report will not include submissions from surveys sent during the calendar month in which the report is generated."
/* eslint-enable max-len */
