import React from "react"
import * as API from "services/api"
import * as StatusListHelpers from "utils/statusListHelpers"
import { useSelector } from "react-redux"
import { store } from "store"
import {
  category, formSubmissionData, submissionPermissions, closeSubmission, setSubmissionStatus, getTasks,
} from "reduxSlices/formSubmissionSlice"
import { useFormSubmissionFormContext } from "contexts/FormSubmissionFormContext"
import { errorToast } from "shared/toast"
import { Select } from "shared/selects"
import Tooltip from "shared/Tooltip"
import ClosedSubmissionSelect from "./ClosedSubmissionSelect"

const FormSubmissionStatus = () => {
  const {
    statusName: currentStatusName,
    slug: formSubmissionSlug,
  } = useSelector(formSubmissionData)

  const { editMode } = useFormSubmissionFormContext()

  const { canCloseSubmission } = useSelector(submissionPermissions)
  const { statuses } = useSelector(category)
  const tasks = useSelector(getTasks)

  const closedStatus = StatusListHelpers.closedStatus({ statuses })
  const statusIsClosed = currentStatusName === closedStatus?.name

  if (statusIsClosed && !canCloseSubmission) return <span>{currentStatusName}</span>

  const isClosedStatus = (task) => !task.isOpen
  const allTasksClosed = tasks.every(isClosedStatus)
  const statusListOptions = StatusListHelpers.statusListOptions(statuses, canCloseSubmission, allTasksClosed)

  if (statusIsClosed) {
    return (
      <ClosedSubmissionSelect />
    )
  }

  const closeFormSubmission = async (newStatusName) => {
    const response = await API.closeSubmission({ formSubmissionSlug })

    if (response.ok) {
      store.dispatch(closeSubmission(newStatusName))
    } else {
      errorToast("Something went wrong. Unable to close form submission.")
    }
  }

  const updateStatus = async (newStatusName) => {
    if (newStatusName === currentStatusName) return

    if (closedStatus.name === newStatusName) {
      closeFormSubmission(newStatusName)
    } else {
      const response = await API.updateSubmissionStatus({ formSubmissionSlug, statusName: newStatusName })

      if (response.ok) {
        store.dispatch(setSubmissionStatus(newStatusName))
      } else {
        errorToast("Something went wrong. Unable to change form submission status.")
      }
    }
  }

  return (
    <Tooltip text={editMode ? "Cannot change status while editing a submission." : ""}>
      <Select
        className="bg-white font-medium text-sm"
        hasBaseInputStyle={false}
        style={{
          minHeight: "34px",
          borderRadius: "4px",
          minWidth: "200px",
          zIndex: "100",
        }}
        disabled={editMode}
        options={statusListOptions}
        value={currentStatusName}
        placeholder="Select status"
        onChange={updateStatus}
        backspaceDelete={false}
      />
    </Tooltip>
  )
}

export default FormSubmissionStatus
