/* eslint-disable no-unused-vars */
import React, { useEffect } from "react"
import { useDrag } from "react-dnd"
import { getEmptyImage } from "react-dnd-html5-backend"
import { sourceListUserShape } from "utils/propTypeShapes"
import UserInfo from "shared/UserInfo"
import { DraggableItemTypes } from "../DraggableItemTypes"

const DraggableUser = ({ user }) => {
  const [collected, drag, dragPreview] = useDrag(() => ({
    type: DraggableItemTypes.USER_PROFILE_ICON,
    item: { id: user.id, user },
  }))

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true })
  }, [dragPreview])

  return (
    <div className="select-none">
      <span ref={drag} className="flex items-center max-w-max">
        <UserInfo user={user} className="mr-2" />
      </span>
    </div>
  )
}

DraggableUser.propTypes = {
  user: sourceListUserShape.isRequired,
}

export default DraggableUser
