import React from "react"
import { isEqual } from "lodash-es"
import pluralize from "pluralize"
import { useSelector } from "react-redux"
import { getGroups, getUsers, getFormQuestionByUuid } from "reduxSlices/formBuilderSlice"
import { SEGMENTS } from "utils/departmentSegmentHelpers"
import { workflowActionShape } from "utils/propTypeShapes"
import { NOTIFY_ALL_MEMBERS, NOTIFY_DEPARTMENT_MEMBERS, NOTIFY_SELECTED } from "utils/workflowHelpers"
import { WorkflowActionSendNotificationIcon } from "shared/icons"
import ActionDetails from "./ActionDetails"
import ActionAbbreviatedUserList from "./ActionAbbreviatedUserList"
import ActionHeading from "./ActionHeading"
import ActionLabelContainer from "./ActionLabelContainer"

const AllMembersDetails = () => (
  <ActionDetails>
    <span className="workflow-label">all submission members</span>
  </ActionDetails>
)

const SpecificUsersDetails = ({ action }) => {
  const { groupIds, userIds } = action.data.recipients

  const groupsToNotify = useSelector(getGroups).filter((potentialGroup) => (
    groupIds.includes(potentialGroup.id)
  ))

  const usersToNotify = useSelector(getUsers).filter((potentialUser) => (
    userIds.includes(potentialUser.id)
  ))

  return (
    <ActionDetails>
      <span>
        {
          groupsToNotify.map((group) => (
            <span key={group.id} className="workflow-label mr-2 mb-2">{group.name}</span>
          ))
        }
      </span>
      <ActionAbbreviatedUserList users={usersToNotify} />
    </ActionDetails>
  )
}

SpecificUsersDetails.propTypes = {
  action: workflowActionShape.isRequired,
}

const DepartmentMemberDetails = ({ action }) => {
  const { data } = action
  const { questionUUID, segments } = data

  const allSegmentsSelected = segments.length === SEGMENTS.length

  const question = useSelector(getFormQuestionByUuid(questionUUID), isEqual)

  return (
    <ActionDetails>
      {
        allSegmentsSelected
          ? (
            `All members of the department selected in "${question.prompt}"`
          )
          : (
            `
              Members of the department selected in "${question.prompt}"
              who are in ${pluralize("Segment", segments.length)} ${segments.map((segment) => segment.toUpperCase()).join(" and ")}
            `
          )
      }
    </ActionDetails>
  )
}

DepartmentMemberDetails.propTypes = {
  action: workflowActionShape.isRequired,
}

const SendNotificationDetails = ({ action }) => {
  const { subtype } = action

  switch (subtype) {
  case NOTIFY_ALL_MEMBERS: {
    return <AllMembersDetails />
  }
  case NOTIFY_DEPARTMENT_MEMBERS: {
    return <DepartmentMemberDetails action={action} />
  }
  case NOTIFY_SELECTED: {
    return <SpecificUsersDetails action={action} />
  }
  default:
    return null
  }
}

SendNotificationDetails.propTypes = {
  action: workflowActionShape.isRequired,
}

const SendNotificationActionLabel = ({ action }) => (
  <ActionLabelContainer>
    <ActionHeading
      iconComponent={WorkflowActionSendNotificationIcon}
      text="Send notification:"
    />
    <SendNotificationDetails action={action} />
  </ActionLabelContainer>
)

SendNotificationActionLabel.propTypes = {
  action: workflowActionShape.isRequired,
}

export default SendNotificationActionLabel
