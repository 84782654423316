import React from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { getFormQuestionAttributeIsDropdown, setQuestionAttributeIsDropdown } from "reduxSlices/formBuilderSlice"
import { SwitchInput } from "shared/inputs"

const IsDropdown = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const questionIsDropdown = useSelector(getFormQuestionAttributeIsDropdown({ sectionIndex, questionIndex }))

  const updateIsDropdown = (newIsDropdown) => dispatch(setQuestionAttributeIsDropdown({
    sectionIndex, questionIndex, newIsDropdown,
  }))

  return (
    <div className="mb-4 flex-center">
      <div className="font-medium">Make Dropdown</div>
      <SwitchInput
        value={questionIsDropdown}
        onChange={updateIsDropdown}
      />
    </div>
  )
}

IsDropdown.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default IsDropdown
