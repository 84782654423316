import React from "react"
import types from "prop-types"
import ConditionDefiner from "FormBuilder/QuestionBranching/ConditionDefiner"
import AddConditionButton from "FormBuilder/UIElements/AddConditionButton"
import ConditionControl from "./ConditionControl"

const Conditions = ({
  addBranchingConditionalsCondition,
  removeBranchingConditionalsCondition,
  unusedQuestionOptions,
  updateBranchingConditionalsControl,
  updateBranchingConditionalsConditionMatchValue,
  updateBranchingConditionalsConditionOperator,
  updateBranchingConditionalsConditionQuestionUuid,
  conditionals,
  fieldPath,
}) => {
  const { conditions = [], control = "" } = conditionals
  const hasConditionsDefined = conditions.length > 0
  const canAddAnotherCondition = unusedQuestionOptions.length > 0

  return (
    <>
      {
        hasConditionsDefined && (
          <ConditionControl
            fieldPath={`${fieldPath}.control`}
            updateConditionalsControl={updateBranchingConditionalsControl}
            value={control}
          />
        )
      }
      {
        conditions.map((condition, index) => (
          <ConditionDefiner
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            fieldPath={`${fieldPath}.conditions`}
            condition={condition}
            unusedQuestionOptions={unusedQuestionOptions}
            updateConditionMatchValue={updateBranchingConditionalsConditionMatchValue(index)}
            updateConditionOperator={updateBranchingConditionalsConditionOperator(index)}
            updateConditionQuestionUuid={updateBranchingConditionalsConditionQuestionUuid(index)}
            deleteCondition={removeBranchingConditionalsCondition(index)}
          />
        ))
      }
      {
        canAddAnotherCondition && (
          <AddConditionButton addCondition={addBranchingConditionalsCondition} />
        )
      }
    </>
  )
}

Conditions.propTypes = {
  addBranchingConditionalsCondition: types.func.isRequired,
  removeBranchingConditionalsCondition: types.func.isRequired,
  unusedQuestionOptions: types.arrayOf(types.object).isRequired,
  updateBranchingConditionalsControl: types.func.isRequired,
  updateBranchingConditionalsConditionMatchValue: types.func.isRequired,
  updateBranchingConditionalsConditionOperator: types.func.isRequired,
  updateBranchingConditionalsConditionQuestionUuid: types.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  conditionals: types.object.isRequired,
  fieldPath: types.string.isRequired,
}

export default Conditions
