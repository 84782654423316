import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { store } from "store"
import { Draggable } from "react-beautiful-dnd"
import Switch from "shared/Switch"
import { DragGripHandleIcon } from "shared/icons"
import { setColumnVisible, setColumnHidden } from "reduxSlices/taskTableSlice"

const Column = ({
  label, columns, index, value,
}) => {
  const isColumnChecked = (columnName) => columns.find((column) => column.value === columnName).visible

  const toggleSelectedColumns = (columnName) => () => {
    if (isColumnChecked(columnName)) {
      store.dispatch(setColumnHidden(columnName))
    } else {
      store.dispatch(setColumnVisible(columnName))
    }
  }

  return (
    <Draggable draggableId={value} index={index}>
      {
        (dragProvided) => (
          <div
            ref={dragProvided.innerRef}
            className="flex-center"
            {...dragProvided.draggableProps}
          >
            <DragGripHandleIcon dragProvided={dragProvided} />
            <div className={clsx(
              "flex-center flex-grow",
              "font-medium text-sm",
              "sidebar-border-wrap",
            )}
            >
              { label }
              <Switch
                checked={isColumnChecked(value)}
                onChange={toggleSelectedColumns(value)}
              />
            </div>
          </div>
        )
      }
    </Draggable>
  )
}

Column.propTypes = {
  label: types.string.isRequired,
  index: types.number.isRequired,
  value: types.string.isRequired,
  columns: types.arrayOf(types.object).isRequired,
}

export default Column
