import React from "react"
import { useSelector } from "react-redux"
import { getCategoryStatuses } from "reduxSlices/formBuilderSlice"
import findById from "utils/findById"
import { workflowActionShape } from "utils/propTypeShapes"
import { WorkflowActionChangeStatusIcon } from "shared/icons"
import ActionHeading from "./ActionHeading"
import ActionDetails from "./ActionDetails"
import ActionLabelContainer from "./ActionLabelContainer"

const ChangeStatusActionLabel = ({ action }) => {
  const statuses = useSelector(getCategoryStatuses)
  const statusName = findById(action.data.statusId, statuses)?.name

  return (
    <ActionLabelContainer>
      <ActionHeading
        iconComponent={WorkflowActionChangeStatusIcon}
        text="Change status:"
      />
      <ActionDetails>
        <span>to <span className="workflow-label">{statusName}</span></span>
      </ActionDetails>
    </ActionLabelContainer>
  )
}

ChangeStatusActionLabel.propTypes = {
  action: workflowActionShape.isRequired,
}

export default ChangeStatusActionLabel
