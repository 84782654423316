import React from "react"
import { useTaskFormContext } from "contexts/TaskFormContext"
import { taskShape } from "utils/propTypeShapes"
import { Checkbox } from "components/shared/checkboxes";

const OtherTaskAccessCheckbox = ({ task }) => {
  const { addAccessToTask, removeAccessToTask, taskManageAccessTaskIds } = useTaskFormContext()

  const checked = taskManageAccessTaskIds.includes(task.id)

  const toggleAccess = () => (
    checked ? removeAccessToTask(task.id) : addAccessToTask(task.id)
  )

  return (
    <div className="flex gap-2 items-center">
      <Checkbox
        className="cursor-pointer"
        uuid={task.id}
        value={checked}
        onChange={toggleAccess}
      />
      <label htmlFor={task.id} className="cursor-pointer">
        {task.title}
      </label>
    </div>
  )
}

OtherTaskAccessCheckbox.propTypes = {
  task: taskShape.isRequired,
}

export default OtherTaskAccessCheckbox
