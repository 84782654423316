import {
  dayjsOrgTz, DAY_UNIT, WEEK_UNIT, MONTH_UNIT,
} from "utils/dateHelpers"

const NOW = dayjsOrgTz()
const TODAY = "Today"
const THIS_WEEK = "This Week"
const START_OF_WEEK = NOW.subtract(7, DAY_UNIT)
const ONE_MONTH_AGO = NOW.subtract(1, MONTH_UNIT)
const THREE_MONTHS_AGO = NOW.subtract(90, DAY_UNIT)

const OVER_3_MONTHS_AGO = "Over 3 Months Ago"
const over3MonthsAgo = (value) => dayjsOrgTz(value).isBefore(THREE_MONTHS_AGO) && OVER_3_MONTHS_AGO

const OVER_1_MONTH_AGO = "a Month to 3 Months Ago"
const over1MonthAgo = (value) => dayjsOrgTz(value).isBefore(ONE_MONTH_AGO) && OVER_1_MONTH_AGO

const OVER_1_WEEK_AGO = "a Week to a Month Ago"
const over1WeekAgo = (value) => dayjsOrgTz(value).isBefore(START_OF_WEEK) && OVER_1_WEEK_AGO

const YESTERDAY = NOW.subtract(1, DAY_UNIT)
const THREE_DAYS_AGO = NOW.subtract(2, DAY_UNIT)
const ONE_WEEK_AGO = NOW.subtract(1, WEEK_UNIT)

const OVER_7_DAYS_AGO = "Over a Week Ago"
const over7DaysAgo = (value) => dayjsOrgTz(value).isBefore(ONE_WEEK_AGO) && OVER_7_DAYS_AGO

const OVER_3_DAYS_AGO = "3 Days to a Week Ago"
const over3DaysAgo = (value) => dayjsOrgTz(value).isBefore(THREE_DAYS_AGO) && OVER_3_DAYS_AGO

const OVER_1_DAY_AGO = "Yesterday to 3 Days Ago"
const over1DayAgo = (value) => dayjsOrgTz(value).isBefore(YESTERDAY) && OVER_1_DAY_AGO

export const lastModifiedByDayGrouping = (value) => over7DaysAgo(value)
  || over3DaysAgo(value)
  || over1DayAgo(value)
  || TODAY

export const lastModifiedByWeekGrouping = (value) => over3MonthsAgo(value)
  || over1MonthAgo(value)
  || over1WeekAgo(value)
  || THIS_WEEK

export const dateCreatedGrouping = (value) => over3MonthsAgo(value)
  || over1MonthAgo(value)
  || over1WeekAgo(value)
  || THIS_WEEK

export const submittedDateGrouping = (value) => over3MonthsAgo(value)
  || over1MonthAgo(value)
  || over1WeekAgo(value)
  || THIS_WEEK

export const INITIAL_DATE_BY_DAYS_GROUPING = () => ({
  [TODAY]: [],
  [OVER_1_DAY_AGO]: [],
  [OVER_3_DAYS_AGO]: [],
  [OVER_7_DAYS_AGO]: [],
})

export const INITIAL_DATE_BY_WEEK_GROUPING = () => ({
  [THIS_WEEK]: [],
  [OVER_1_WEEK_AGO]: [],
  [OVER_1_MONTH_AGO]: [],
  [OVER_3_MONTHS_AGO]: [],
})
