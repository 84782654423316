import { INITIAL_DATE_BY_WEEK_GROUPING, lastModifiedByWeekGrouping } from "utils/groupingHelpers"

export const GROUP_BY_LAST_MODIFIED = "updatedAt"
export const GROUP_BY_CATEGORY = "categoryName"

export const groupByLastModified = (forms) => forms.reduce((acc, form) => {
  const { updatedAt } = form
  const groupName = lastModifiedByWeekGrouping(updatedAt)
  acc[groupName].push(form)

  return acc
}, INITIAL_DATE_BY_WEEK_GROUPING())

export const groupByCategory = (forms) => {
  const initialCategoryGrouping = Object.fromEntries(
    forms.map(({ categoryName }) => [categoryName, []]).sort(),
  )

  return forms.reduce((acc, form) => {
    const { categoryName } = form
    acc[categoryName].push(form)
    return acc
  }, initialCategoryGrouping)
}

export const GROUPABLE_COLUMNS = {
  [GROUP_BY_CATEGORY]: {
    label: "Solution",
    value: GROUP_BY_CATEGORY,
    groupingMethod: groupByCategory,
  },
  [GROUP_BY_LAST_MODIFIED]: {
    label: "Last Modified",
    value: GROUP_BY_LAST_MODIFIED,
    groupingMethod: groupByLastModified,
  },
}

export const getGroupingMethod = (groupName) => GROUPABLE_COLUMNS[groupName].groupingMethod
