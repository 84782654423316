import React from "react"
import types from "prop-types"
import clsx from "clsx"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const XIcon = ({
  className = "", width = undefined, height = undefined, onClick = undefined,
}) => (
  <SvgSpriteIcon
    className={clsx(className, "text-dark fill-current stroke-current cursor-pointer")}
    iconName="x"
    width={width}
    height={height}
    onClick={onClick}
  />
)

XIcon.propTypes = {
  className: types.string,
  width: types.number,
  height: types.number,
  onClick: types.func,
}

export default XIcon
