import { useContext } from "react"
import SolutionLevelScheduledReportExportContext from "../SolutionLevelScheduledReportExportContext"

const useSolutionLevelScheduledReportExportContext = () => {
  const providedValue = useContext(SolutionLevelScheduledReportExportContext)

  return { ...providedValue }
}

export default useSolutionLevelScheduledReportExportContext
