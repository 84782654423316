import { useState } from "react"

const useSubmit = (initialCanSubmit = false) => {
  const [canSubmit, setCanSubmit] = useState(initialCanSubmit)
  const enableSubmit = () => setCanSubmit(true)
  const disableSubmit = () => setCanSubmit(false)

  return {
    canSubmit,
    enableSubmit,
    disableSubmit,
  }
}

export default useSubmit
