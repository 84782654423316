import React from "react"
import types from "prop-types"
import { PrimaryButton } from "shared/buttons"

const ReopenTaskButton = ({ reopenTask }) => (
  <PrimaryButton text="Reopen" onClick={reopenTask} />
)

ReopenTaskButton.propTypes = {
  reopenTask: types.func.isRequired,
}

export default ReopenTaskButton
