import React from "react"
import types from "prop-types"
import Formsy from "formsy-react"
import { store } from "store"
import { RemoveButton, PrimaryButton, Button } from "shared/buttons"
import { FormsyTextInput } from "shared/inputs"
import { useSubmit } from "hooks"
import Modal, { DEFAULT_MODAL_STYLE } from "shared/Modal"
import { setNewWorkflowName } from "reduxSlices/formBuilderSlice"

const MODAL_STYLE = {
  content: {
    ...DEFAULT_MODAL_STYLE.content,
    minWidth: "650px",
    maxWidth: "1000px",
    // minHeight: "400px",
    maxHeight: "100vh",
    padding: "1em",
    overflow: "auto",
  },
}

const CreateWorkflowModal = ({ onSubmit, closeForm, isOpen }) => {
  const { canSubmit, enableSubmit, disableSubmit } = useSubmit()
  const resetWorkflowName = () => store.dispatch(setNewWorkflowName(""))

  return (
    <Modal isOpen={isOpen} style={MODAL_STYLE} onAfterOpen={resetWorkflowName}>
      <RemoveButton
        className="absolute right-4"
        onClick={closeForm}
      />
      <div className="font-semibold text-xl text-center mt-8">
        New Workflow
      </div>
      <Formsy
        onSubmit={onSubmit}
        onValid={enableSubmit}
        onInvalid={disableSubmit}
      >
        <div className="w-3/4 mx-auto mt-8">
          <FormsyTextInput
            className="bordered-base-input text-sm"
            name="name"
            placeholder="Name this workflow..."
            value=""
            required
            validations={{ isNotBlankString: true }}
            validationErrors={{ isDefaultRequiredValue: "required" }}
          />
          <div className="flex justify-center mt-8 mb-10">
            <Button
              text="Cancel"
              onClick={closeForm}
              className="border border-light-300 mr-2"
            />
            <PrimaryButton
              text="Create"
              type="submit"
              disabled={!canSubmit}
            />
          </div>
        </div>
      </Formsy>
    </Modal>
  )
}

CreateWorkflowModal.propTypes = {
  onSubmit: types.func.isRequired,
  closeForm: types.func.isRequired,
  isOpen: types.bool.isRequired,
}

export default CreateWorkflowModal
