import React, { forwardRef, useEffect, useCallback } from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import { TextInput } from "shared/inputs"
import { debounce } from "lodash-es"

const DEBOUNCE_TIME = 1000
const DEBOUNCE_OPTIONS = {
  leading: false,
  trailing: true,
}

const SearchInput = forwardRef(({
  className, search, searchQuery, setSearchQuery,
}, ref) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(debounce(
    search,
    DEBOUNCE_TIME,
    DEBOUNCE_OPTIONS,
  ), [])

  useEffect(
    () => {
      const trimmedQuery = searchQuery.trim()
      if (trimmedQuery && trimmedQuery.length > 2) {
        debouncedSearch(trimmedQuery)
      }
    },
    [debouncedSearch, searchQuery],
  )

  return (
    <div className="relative">
      <TextInput
        className={className}
        value={searchQuery}
        onChange={setSearchQuery}
        ref={ref}
      />
      <SvgSpriteIcon
        iconName="search"
        className="absolute-vertical-center right-4 h-5 w-5"
      />
    </div>
  )
})

SearchInput.defaultProps = {
  className: "popup-search-input",
}

SearchInput.propTypes = {
  className: types.string,
  search: types.func.isRequired,
  searchQuery: types.string.isRequired,
  setSearchQuery: types.func.isRequired,
}

SearchInput.displayName = "SearchInput"

export default SearchInput
