import React, { useEffect } from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { users as getUsers } from "reduxSlices/formSubmissionSlice"
import { useTaskFormContext } from "contexts/TaskFormContext"
/* eslint-disable-next-line max-len */
import TaskUserSelector from "views/FormSubmission/FormSubmissionTabs/ActivitiesContent/TaskForms/TaskFields/UI/TaskUserSelector"

const TaskMemberSelector = ({ className }) => {
  const users = useSelector(getUsers)

  const { addMemberId, taskAssigneeIds, taskMemberIds } = useTaskFormContext()

  useEffect(() => {
    window.dispatchEvent(new window.Event("resize"))
  }, [taskMemberIds.length])

  const selectableUsers = users.reduce((userList, user) => {
    if (user.active && !taskMemberIds.includes(user.id)) {
      userList.push(
        {
          ...user,
          ...(taskAssigneeIds.includes(user.id) && { marker: "Assignee" }),
        },
      )
    }
    return userList
  }, [])

  return (
    <TaskUserSelector
      addUserId={addMemberId}
      className={className}
      taskUserType="Members"
      users={selectableUsers}
    />
  )
}

TaskMemberSelector.defaultProps = {
  className: "",
}

TaskMemberSelector.propTypes = {
  className: types.string,
}

export default TaskMemberSelector
