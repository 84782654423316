import { v4 as makeUuid } from "uuid"

export const addKeys = (collection) => collection.map((item) => {
  if (item.key) return item

  return (
    {
      ...item,
      key: makeUuid(),
    }
  )
})

export const keyless = (collection) => collection.map(({ key, ...rest }) => rest)
