import React, { useState, useEffect } from "react"
import types from "prop-types"
import { Provider } from "react-redux"
import { store } from "store"
import { setTasks } from "reduxSlices/taskTableSlice"
import Sidebar from "./Sidebar"
import Content from "./Content"

const TaskList = ({ tasks }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    store.dispatch(setTasks(tasks))
  }, [])

  const [hiddenSidebar, setHiddenSidebar] = useState(true)
  const toggleSidebar = () => setHiddenSidebar(!hiddenSidebar)

  return (
    <Provider store={store}>
      <Sidebar hiddenSidebar={hiddenSidebar} />
      <Content
        hiddenSidebar={hiddenSidebar}
        toggleSidebar={toggleSidebar}
      />
      <div />
    </Provider>
  )
}

TaskList.propTypes = {
  tasks: types.arrayOf(types.object).isRequired,
}

export default TaskList
