import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { withFormsy } from "formsy-react"
import { questionShape } from "utils/propTypeShapes"
import { getTimeDifference } from "utils/timeDifferenceHelpers"

const fetchNumber = (formAnswers, uuid) => formAnswers[uuid]

const FormsyDiv = withFormsy(({ name, value, className }) => (
  <div className={clsx("my-1 form-input py-2 px-3 flex items-center", className)} name={name} value={value}>
    { value }
    {/* Keeps the height if there is no value */}
    &nbsp;
  </div>
))

const TimeDifferenceAnswerField = ({ question, formAnswers, className = "" }) => {
  const { uuid, attributes } = question
  const { startTimeQuestionUuid, endTimeQuestionUuid } = attributes

  let result = null

  const startTime = fetchNumber(formAnswers, startTimeQuestionUuid)
  const endTime = fetchNumber(formAnswers, endTimeQuestionUuid)

  if (startTime && endTime) {
    result = getTimeDifference(startTime, endTime)
  }
  return (
    <FormsyDiv name={uuid} value={result} className={className} />
  )
}

TimeDifferenceAnswerField.propTypes = {
  className: types.string,
  // eslint-disable-next-line react/forbid-prop-types
  formAnswers: types.object.isRequired,
  question: questionShape.isRequired,
}

export default TimeDifferenceAnswerField
