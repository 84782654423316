import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const DrawerToggleIcon = ({
  className = "", isOpen = false, width = undefined, height = undefined,
}) => {
  if (isOpen) {
    return (
      <SvgSpriteIcon
        className={className}
        iconName="drawer-close"
        width={width}
        height={height}
      />
    )
  }

  return (
    <SvgSpriteIcon
      className={className}
      iconName="drawer-open"
      width={width}
      height={height}
    />
  )
}

DrawerToggleIcon.propTypes = {
  isOpen: types.bool,
  className: types.string,
  width: types.number,
  height: types.number,
}

export default DrawerToggleIcon
