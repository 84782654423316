export const PUBLIC_USAGE = "public"
export const SURVEY_USAGE = "surveys"
export const TASK_USAGE = "tasks"

export const FORM_USAGES = [PUBLIC_USAGE, SURVEY_USAGE, TASK_USAGE]

const FORM_USAGE_FORM_TYPE_DESCRIPTIONS = {
  [PUBLIC_USAGE]: "forms",
  [SURVEY_USAGE]: "surveys",
  [TASK_USAGE]: "task forms",
}

export const USAGES_TO_EXCLUDE_FORM_SUBMISSION_STATUS = [SURVEY_USAGE, TASK_USAGE]

export const USAGES_TO_EXCLUDE_FORM_COPY_LINK = [SURVEY_USAGE, TASK_USAGE]

export const USAGES_TO_EXCLUDE_FORM_LISTED_SETTING = [SURVEY_USAGE, TASK_USAGE]

export const USAGES_TO_EXCLUDE_FORM_TOOLTIP_DESCRIPTION = [SURVEY_USAGE, TASK_USAGE]

export const USAGES_TO_EXCLUDE_FORM_ACCEPTS_SUBMITTED_FILES = [TASK_USAGE]

export const formUsageFormTypeDescription = (usage) => (
  FORM_USAGE_FORM_TYPE_DESCRIPTIONS[usage]
)
