import React from "react"
import Formsy from "formsy-react"
import { useDispatch, useSelector } from "react-redux"
import { facilityId, setFacility } from "reduxSlices/adminFacilitySlice"
import useSubmit from "hooks/useSubmit"
import * as API from "services/api"
import { PrimaryButton } from "shared/buttons"
import { successToast, errorToast } from "shared/toast"
import FacilityName from "./FacilityName"

const GeneralTab = () => {
  const { canSubmit, enableSubmit, disableSubmit } = useSubmit()

  const dispatch = useDispatch()

  const id = useSelector(facilityId)

  const updateFacility = async (facilityData) => {
    const response = await API.updateFacility({ facilityId: id, facility: facilityData })

    if (response.ok) {
      dispatch(setFacility(response.data))
      successToast("Facility updated successfully!")
    } else {
      errorToast("Something went wrong. Unable to update facility.", response)
    }
  }

  return (
    <>
      <h2 className="tab-title">General</h2>
      <Formsy
        onSubmit={updateFacility}
        onValid={enableSubmit}
        onInvalid={disableSubmit}
        preventDefaultSubmit
      >
        <FacilityName />
        <PrimaryButton
          className="absolute-horizontal-center bottom-16"
          text="Save changes"
          type="submit"
          disabled={!canSubmit}
        />
      </Formsy>
    </>
  )
}

export default GeneralTab
