import { snakeToCapitalCase } from "utils/stringHelpers"
import {
  DAILY,
  MONTHLY,
  QUARTERLY,
  WEEKLY,
} from "utils/recurrenceDetailsHelpers"

// public statuses match return values
// from SurveyToken#public_status
export const PUBLIC_STATUS_PENDING = "pending"
export const PUBLIC_STATUS_DELIVERED = "delivered"
export const PUBLIC_STATUS_BOUNCED = "bounced"
export const PUBLIC_STATUS_SUBMITTED = "submitted"

// survey_schedule_attributes.batch_frequency options
// must match RecurrenceDetails::FREQUENCY
export const BATCH_RECURRENCE_UNITS = [DAILY, WEEKLY, MONTHLY, QUARTERLY]

// Matches reminders in app/models/concerns/survey_reminder_schedulable.rb
export const NO_REMINDERS = "none"
export const BIWEEKLY_REMINDERS = "biweekly"
export const BIWEEKLY_REMINDERS_TITLE = "Biweekly (once every two weeks)"
export const REMINDER_FREQUENCIES = [
  NO_REMINDERS,
  DAILY,
  "every_other_day",
  WEEKLY,
  BIWEEKLY_REMINDERS,
]

export const reminderFrequencyHumanized = (frequency) => (frequency === BIWEEKLY_REMINDERS ? BIWEEKLY_REMINDERS_TITLE : snakeToCapitalCase(frequency))

export const REMINDER_FREQUENCY_OPTIONS = REMINDER_FREQUENCIES.map((opt) => ({
  label: reminderFrequencyHumanized(opt),
  value: opt,
}))

// Matches SurveyConfiguration.batch_duration_units.keys
export const BATCH_DURATION_UNITS = [
  "days",
  "weeks",
  "months",
]

// Attribute titles
export const BATCH_AUTO_CLOSE_TITLE = "Batches Close Automatically"
export const BATCH_RECURRENCE_TITLE = "Schedule Recurring Batches"

// Attribute descriptions
// eslint-disable-next-line max-len
export const ANONYMOUS_SURVEY_DESCRIPTION = "Survey submissions will be anonymous. You will not be able to track a survey submission to the respondent."

export const TRACKED_SURVEY_DESCRIPTION = "Survey submissions will be tracked and associated with a respondent."

export const MANUAL_SEND_DESCRIPTION = "Surveys must be sent manually."

export const AUTO_SEND_DESCRIPTION_PREFIX = "Survey will be sent"

export const MANUAL_CLOSE_DESCRIPTION = "Survey submissions will be accepted until a batch is manually closed."

export const AUTO_CLOSE_DESCRIPTION_PREFIX = "Submissions will be accepted for"

export const END_OF_MONTH_EXPLANATION = "If the selected day of the month does not occur in a given month, the survey will be sent on the last day of the month."

export const ACTIVE_SURVEY_DESCRIPTION = "When active, new surveys can be sent (manually and recurring)."
