import pluralize from "pluralize"
import { dateOrdinal } from "utils/dateHelpers"

export const DAILY = "daily"
export const WEEKLY = "weekly"
export const MONTHLY = "monthly"
export const QUARTERLY = "quarterly"

export const DAILY_DESC = "Daily"
export const weeklyDesc = (date) => `Weekly on ${pluralize(date)}`
export const monthlyDesc = (date) => `Monthly on the ${dateOrdinal(date)}`

// These recurrence options are used for scheduled report exports.
// They are not applicable to surveys, which may also recur quarterly.
export const RECURRENCE_OPTIONS = [DAILY, WEEKLY, MONTHLY]
