import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs"
import utcPlugin from "dayjs/plugin/utc"
import timezonePlugin from "dayjs/plugin/timezone"

dayjs.extend(utcPlugin)
dayjs.extend(timezonePlugin)

const INITIAL_STATE = {
  organizationId: null,
  timezone: undefined,
  taskReminders: undefined,
  taskNotifySettings: [],
}

export const currentSettingsSlice = createSlice({
  name: "currentSettings",
  initialState: INITIAL_STATE,
  reducers: {
    setInitialSettings: (state, action) => {
      Object.assign(state, action.payload)
      dayjs.tz.setDefault(action.payload.timezone)
    },
    setTimezone: (state, action) => {
      state.timezone = action.payload
      dayjs.tz.setDefault(action.payload.timezone)
    },
    setTaskReminders: (state, action) => {
      state.taskReminders = action.payload
    },
    enableTaskNotifySetting: (state, action) => {
      const type = action.payload
      const setting = state.taskNotifySettings.find(({ settingType }) => settingType === type)
      setting.isEnabled = true
    },
    disableTaskNotifySetting: (state, action) => {
      const type = action.payload
      const setting = state.taskNotifySettings.find(({ settingType }) => settingType === type)
      setting.isEnabled = false
    },
  },
})

export const {
  setInitialSettings,
  setTimezone,
  setTaskReminders,
  enableTaskNotifySetting,
  disableTaskNotifySetting,
} = currentSettingsSlice.actions

export const getOrganizationId = (state) => state.currentSettings.organizationId

export const getTimezone = (state) => state.currentSettings.timezone

export const getTaskReminders = (state) => state.currentSettings.taskReminders

export const getTaskNotifySettings = (state) => state.currentSettings.taskNotifySettings

export const isTaskNotifySettingEnabled = (type) => (state) => (
  state.currentSettings.taskNotifySettings.find((setting) => setting.settingType === type).isEnabled
)

export default currentSettingsSlice.reducer
