import React, { Component } from "react"
import types from "prop-types"
import Formsy from "formsy-react"
import { connect } from "react-redux"
import { addOnFeatureConfigurationEnabled, subscribedToAddOnFeature } from "reduxSlices/addOnFeatureSubscriptionsSlice"
import { SCIM_DEPARTMENTS_MANAGED_PROPERTY, SCIM_FEATURE_NAME } from "utils/addOnFeatureHelpers"
import * as API from "services/api"
import Modal from "shared/Modal"
import { XIcon } from "shared/icons"
import { PrimaryButton, Button } from "shared/buttons"
import { successToast, errorToast } from "shared/toast"
import ExternalIdentifier from "./Department/Fields/ExternalIdentifier"
import Name from "./Department/Fields/Name"

class CreateDepartmentModal extends Component {
  state = { isFormValid: false, modalIsOpen: false }

  closeModal = () => this.setState({ modalIsOpen: false })

  openModal = () => this.setState({ modalIsOpen: true })

  enableSubmit = () => this.setState({ isFormValid: true })

  disableSubmit = () => this.setState({ isFormValid: false })

  onSubmit = async (model) => {
    const { onDepartmentCreated, facilityId } = this.props
    const response = await API.createFacilityDepartment({ facilityId, department: model })
    if (response.ok) {
      onDepartmentCreated(response.data)
      this.closeModal()
      successToast("Department created successfully!")
    } else {
      console.error("Error updating department: ", response)
      errorToast("Something went wrong. Unable to create department.", response)
    }
  }

  render() {
    const { isFormValid, modalIsOpen } = this.state
    const { facilityId, departmentsManagedBySCIM } = this.props

    if (!facilityId) return null

    return (
      <>
        <Button
          className="text-focus border border-light my-4"
          text="+ Add Department"
          onClick={this.openModal}
        />

        <Modal isOpen={modalIsOpen}>
          <div className="text-center text-xl font-semibold mb-8 relative">
            New Department
            <XIcon
              className="absolute -top-4 right-4 rounded-full bg-primary-light p-2 h-8 w-8"
              onClick={this.closeModal}
            />
          </div>
          <Formsy
            onSubmit={this.onSubmit}
            onValid={this.enableSubmit}
            onInvalid={this.disableSubmit}
          >
            <div className="w-3/4 mx-auto grid gap-3">
              <Name />
              {
                departmentsManagedBySCIM && <ExternalIdentifier />
              }
              <div className="flex justify-center mt-8">
                <Button
                  text="Cancel"
                  onClick={this.closeModal}
                  className="border border-light-300 mr-2"
                />
                <PrimaryButton
                  text="Create"
                  type="submit"
                  disabled={!isFormValid}
                />
              </div>
            </div>
          </Formsy>
        </Modal>
      </>
    )
  }
}

CreateDepartmentModal.defaultProps = {
  facilityId: undefined,
  departmentsManagedBySCIM: false,
}

CreateDepartmentModal.propTypes = {
  facilityId: types.number,
  onDepartmentCreated: types.func.isRequired,
  departmentsManagedBySCIM: types.bool,
}

// Repeating logic from hooks/useSCIMResourceManagement
// because this is a class-based component.
const mapStateToProps = (state) => {
  const isSubscribedToSCIM = subscribedToAddOnFeature(SCIM_FEATURE_NAME)(state)
  const isSCIMDepartmentManagementEnabled = addOnFeatureConfigurationEnabled(SCIM_FEATURE_NAME, SCIM_DEPARTMENTS_MANAGED_PROPERTY)(state)

  return (
    {
      departmentsManagedBySCIM: isSubscribedToSCIM && isSCIMDepartmentManagementEnabled,
    }
  )
}

export default connect(mapStateToProps)(CreateDepartmentModal)
