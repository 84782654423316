import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const NewReportExportLink = ({ location }) => (
  <a className="button tertiary-button text-primary" href={location}>
    <SvgSpriteIcon iconName="export" className="mr-2" />
    <span>Export</span>
  </a>
)

NewReportExportLink.propTypes = {
  location: types.string.isRequired,
}

export default NewReportExportLink
