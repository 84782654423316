import React from "react"
import types from "prop-types"
import { useDispatch } from "react-redux"
import { setWorkflowActiveStatus } from "reduxSlices/formBuilderSlice"
import Switch from "shared/Switch"
import * as API from "services/api"

const activeStatusUpdater = (active) => (
  active ? API.activateWorkflow : API.deactivateWorkflow
)

const ActiveToggle = ({ workflow, formSlug }) => {
  const dispatch = useDispatch()

  const toggleActive = async (active) => {
    const response = await activeStatusUpdater(active)({ formSlug, workflowId: workflow.id })

    if (response.ok) {
      dispatch(setWorkflowActiveStatus({ id: workflow.id, active }))
    } else {
      console.error("Error updating workflow's active status: ", response)
    }
  }

  return (
    <Switch
      checked={workflow.active}
      onChange={(checked) => toggleActive(checked)}
    />
  )
}

ActiveToggle.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  workflow: types.object.isRequired,
  formSlug: types.string.isRequired,
}

export default ActiveToggle
