import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { dayjsOrgTz } from "utils/dateHelpers"

const DueDateDataElement = ({ value = "", rowData }) => {
  const { isOverdue } = rowData
  const date = dayjsOrgTz(value)

  return (
    <div className={clsx(isOverdue && "overdue", "due-date")}>
      {
        date.isValid()
          ? date.format("M/D/YY")
          : ""
      }
    </div>
  )
}

DueDateDataElement.propTypes = {
  value: types.string,
  // eslint-disable-next-line react/forbid-prop-types
  rowData: types.object.isRequired,
}

export default DueDateDataElement
