import React from "react"
import types from "prop-types"
import Formsy from "formsy-react"
import useSubmit from "hooks/useSubmit"
import { PrimaryButton } from "shared/buttons"
import GroupName from "./GroupName"

const GeneralTab = ({ group, updateGroup }) => {
  const { canSubmit, enableSubmit, disableSubmit } = useSubmit()

  return (
    <>
      <h2 className="tab-title">General</h2>
      <Formsy
        onSubmit={updateGroup}
        onValid={enableSubmit}
        onInvalid={disableSubmit}
        preventDefaultSubmit
      >
        <GroupName group={group} />
        <PrimaryButton
          className="absolute-horizontal-center bottom-16"
          text="Save changes"
          type="submit"
          disabled={!canSubmit}
        />
      </Formsy>
    </>
  )
}

GeneralTab.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  group: types.object.isRequired,
  updateGroup: types.func.isRequired,
}

export default GeneralTab
