import React from "react"
import types from "prop-types"

const ArrayDataElement = ({ value = [] }) => (
  <span>{ value.join(", ") }</span>
)

ArrayDataElement.propTypes = {
  value: types.arrayOf(types.any),
}

export default ArrayDataElement
