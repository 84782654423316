import React from "react"
import types from "prop-types"

const headerText = (searchQuery, hasCompletedSearch) => {
  if (searchQuery && hasCompletedSearch) {
    return `Here is everything matching "${searchQuery}"`
  }

  return "Start typing below to search..."
}

const SearchTitleHeader = ({ searchQuery, hasCompletedSearch }) => (
  <h1 className="font-medium tracking-tight text-center py-7">
    { headerText(searchQuery, hasCompletedSearch) }
  </h1>
)

SearchTitleHeader.propTypes = {
  searchQuery: types.string.isRequired,
  hasCompletedSearch: types.bool.isRequired,
}

export default SearchTitleHeader
