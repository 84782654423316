import React from "react"
import types from "prop-types"
import Modal, { DEFAULT_MODAL_STYLE, ModalHeader } from "shared/Modal"
import { PrimaryButton } from "components/shared/buttons"

const MODAL_STYLE = {
  content: {
    ...DEFAULT_MODAL_STYLE.content,
    top: "35%",
  },
}

const TaskDepartmentWarning = ({ isOpen, close }) => (
  <Modal isOpen={isOpen} style={MODAL_STYLE}>
    <ModalHeader
      closeModal={close}
      heading="Department Change"
    />
    <p className="mb-8">
      You have edited a location question. If the new department members need access to the tasks on this submission,
      go to the Activities tab and add the new department to the tasks.
    </p>
    <PrimaryButton className="block mx-auto" text="Close" onClick={close} />
  </Modal>
)

TaskDepartmentWarning.propTypes = {
  isOpen: types.bool.isRequired,
  close: types.func.isRequired,
}

export default TaskDepartmentWarning
