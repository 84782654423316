import React from "react"
import types from "prop-types"
import Portal from "shared/Portal"

export const GLOBAL_HEADER_CHILDREN_PORTAL_TARGET_ID = "global-header-children"

const GlobalHeaderChildrenPortal = ({ children }) => (
  <Portal targetId={GLOBAL_HEADER_CHILDREN_PORTAL_TARGET_ID}>
    { children }
  </Portal>
)

GlobalHeaderChildrenPortal.propTypes = {
  children: types.node.isRequired,
}

export default GlobalHeaderChildrenPortal
