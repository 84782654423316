import React, { useCallback, useEffect, useState } from "react"
import clsx from "clsx"
import { useDispatch, useSelector } from "react-redux"
import {
  formSubmissionSlug,
  getTasks,
  updateAccessOverview,
} from "reduxSlices/formSubmissionSlice"
import ActivitiesSidebar from "./ActivitiesSidebar"
import ActivitiesMain from "./ActivitiesMain"
import EmptyActivities from "./EmptyActivities"
import TasksHeader from "./TasksHeader"

const ActivitiesContent = () => {
  const dispatch = useDispatch()
  const tasks = useSelector(getTasks)
  const slug = useSelector(formSubmissionSlug)

  const [taskFormIsOpen, setTaskFormIsOpen] = useState(false)
  const openForm = () => setTaskFormIsOpen(true)
  const closeForm = () => setTaskFormIsOpen(false)

  const [hiddenSidebar, setHiddenSidebar] = useState(true)
  const toggleSidebar = () => setHiddenSidebar(!hiddenSidebar)

  const refreshAccessOverview = useCallback(() => {
    dispatch(updateAccessOverview({ formSubmissionSlug: slug }))
  }, [dispatch, slug])

  useEffect(() => { refreshAccessOverview() }, [refreshAccessOverview])

  if (tasks.length === 0) {
    return (
      <EmptyActivities
        taskFormIsOpen={taskFormIsOpen}
        openForm={openForm}
        closeForm={closeForm}
        hiddenSidebar={hiddenSidebar}
        toggleSidebar={toggleSidebar}
      />
    )
  }

  return (
    <div className="relative grow">
      <div className="inner-container mx-auto max-w-screen-xl">
        <TasksHeader
          openForm={openForm}
          hiddenSidebar={hiddenSidebar}
          toggleSidebar={toggleSidebar}
        />
        <div className={clsx("flex", !hiddenSidebar && "pl-80")}>
          <ActivitiesSidebar className="hidden lg:block lg:w-1/6 lg:mr-12" />
          <ActivitiesMain taskFormIsOpen={taskFormIsOpen} closeForm={closeForm} />
        </div>
      </div>
    </div>
  )
}

export default ActivitiesContent
