import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { isEmpty } from "lodash-es"
import { currentUserId as getCurrentUserId } from "reduxSlices/sessionSlice"
import { getOrganizationId } from "reduxSlices/currentSettingsSlice"
import {
  getFormSubmissionTable,
  setCategories,
  setColumnOrder,
  setCurrentSort,
  setFormIdsToHide,
  setSelectedGrouping,
  setShowClosedSubmissions,
  setShowDeletedSubmissions,
  setVisibleColumns,
  switchSolution,
} from "reduxSlices/formSubmissionTableSlice"
import { LocalStoragePersistence } from "utils/Persistence"

const CATEGORY_SLUG = "categorySlug"
const COLUMN_ORDER = "columnOrder"
const GROUP_BY = "groupBy"
const HIDDEN_FORM_IDS = "formIdsToHide"
const SHOW_CLOSED = "showClosed"
const SHOW_DELETED = "showDeleted"
const SORT = "sort"
const VISIBLE_COLUMNS = "visibleColumns"

const useFormSubmissionsListSettings = ({
  categories,
  defaultCategorySlug,
  persistence = LocalStoragePersistence,
}) => {
  const dispatch = useDispatch()

  const currentUserId = useSelector(getCurrentUserId)
  const organizationId = useSelector(getOrganizationId)

  const {
    columnOrder,
    currentSort,
    formIdsToHide,
    groupBy,
    selectedCategorySlug,
    showOnlyClosedSubmissions,
    showOnlyDeletedSubmissions,
    visibleColumns,
  } = useSelector(getFormSubmissionTable)

  const oldKey = useMemo(() => (
    `form-submission-list-settings-${currentUserId}`
  ), [currentUserId])
  const key = useMemo(() => (
    `form-submission-index-settings-org-${organizationId}-user-${currentUserId}`
  ), [currentUserId, organizationId])

  const newKeyValues = persistence.getValue({ key })

  if (isEmpty(newKeyValues)) {
    persistence.copyAllValues({ keyToCopy: oldKey, keyToSet: key })

    persistence.clear({ key: oldKey })
  }

  // If these properties have been persisted,
  // we'll update their values in the store on mount
  const propertiesToLookupOnMount = [
    { property: COLUMN_ORDER, reducer: setColumnOrder },
    { property: GROUP_BY, reducer: setSelectedGrouping },
    { property: HIDDEN_FORM_IDS, reducer: setFormIdsToHide },
    { property: SHOW_CLOSED, reducer: setShowClosedSubmissions },
    { property: SHOW_DELETED, reducer: setShowDeletedSubmissions },
    { property: SORT, reducer: setCurrentSort },
    { property: VISIBLE_COLUMNS, reducer: setVisibleColumns },
  ]

  // These properties' values will be persisted
  const propertiesToPersist = [
    { property: CATEGORY_SLUG, value: selectedCategorySlug },
    { property: COLUMN_ORDER, value: columnOrder },
    { property: GROUP_BY, value: groupBy },
    { property: HIDDEN_FORM_IDS, value: formIdsToHide },
    { property: SHOW_CLOSED, value: showOnlyClosedSubmissions },
    { property: SHOW_DELETED, value: showOnlyDeletedSubmissions },
    { property: SORT, value: currentSort },
    { property: VISIBLE_COLUMNS, value: visibleColumns },
  ]

  useEffect(() => {
    // Always set categories
    dispatch(setCategories(categories))

    propertiesToLookupOnMount.forEach(({ property, reducer }) => {
      if (persistence.isPropertySet({ key, property })) {
        dispatch(reducer(persistence.lookup({ key, property })))
      }
    })

    if (persistence.isPropertySet({ key, property: SORT })) {
      dispatch(setCurrentSort(persistence.lookup({ key, property: SORT })))
    }

    // Always set a solution
    // Default to the user's previous settings, as stored in localStorage.
    // If no previous settings, fallback to the solution specified by the backend.
    // If the user does not have access to any form submissions in the solution
    // specified by the backend, fallback to the first solution to which
    // the user does have access to form submissions.
    let initialCategorySlug = persistence.lookup({ key, property: CATEGORY_SLUG })

    if (!initialCategorySlug) {
      const categoriesWithAccessibleFormSubmissions = categories.filter((category) => (
        category.notClosedSubmissionCount > 0
      ))

      let initialCategory = categoriesWithAccessibleFormSubmissions.find((category) => (
        category.slug === defaultCategorySlug
      ))

      if (!initialCategory) {
        [initialCategory] = categoriesWithAccessibleFormSubmissions
      }

      initialCategorySlug = initialCategory?.slug
    }

    const usedForSurveys = categories.some((category) => category.usedForSurvey && (category.slug === initialCategorySlug))

    dispatch(switchSolution({ categorySlug: initialCategorySlug, usedForSurveys }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Disabling some eslint rules because we're iterating over a hardcoded array
  propertiesToPersist.forEach(({ property, value }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      persistence.persist({ key, property, value })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key, persistence, property, value])
  })

  return {}
}

export default useFormSubmissionsListSettings
