import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { ACTIVITY_HUB_SURVEYS_NEW_SURVEY_CONFIGURATION_PATH } from "utils/routeHelpers"
import { PrimaryButton } from "shared/buttons"
import { SidebarVisibilityControl } from "shared/Sidebar"

const goToNewSurveyConfiguration = () => {
  window.location.pathname = ACTIVITY_HUB_SURVEYS_NEW_SURVEY_CONFIGURATION_PATH
}
const toggleSidebar = () => null

const SurveyHeader = ({ className = "" }) => (
  <div className={clsx(className, "flex items-center justify-between")}>
    {/* placeholder Options toggle. Will Implement with rest of index view */}
    <div className="invisible">
      <SidebarVisibilityControl hiddenSidebar toggleSidebar={toggleSidebar} />
    </div>
    <h1 className="header-title">Survey Configurations</h1>
    <PrimaryButton
      text="+ New Survey"
      onClick={goToNewSurveyConfiguration}
    />
  </div>
)

SurveyHeader.propTypes = {
  className: types.string,
}

export default SurveyHeader
