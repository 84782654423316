import React, { useCallback, useEffect } from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { useDispatch, useSelector } from "react-redux"
import {
  getFormIsSubform, getFormQuestionAttributeDefaultAnswer, getFormQuestionOptions, setQuestionAttributeDefaultAnswer,
} from "reduxSlices/formBuilderSlice"
import { ListSelect } from "shared/selects"

const DefaultAnswer = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const isSubform = useSelector(getFormIsSubform)
  const questionDefaultAnswer = useSelector(getFormQuestionAttributeDefaultAnswer({ sectionIndex, questionIndex }))
  const questionOptions = useSelector(getFormQuestionOptions({ sectionIndex, questionIndex }), isEqual)

  const updateDefaultAnswer = useCallback((newDefaultAnswer) => dispatch(setQuestionAttributeDefaultAnswer({
    sectionIndex, questionIndex, newDefaultAnswer,
  })), [sectionIndex, questionIndex, dispatch])

  const EMPTY_DEFAULT_ANSWER = ""

  useEffect(() => {
    if (isSubform && questionDefaultAnswer !== EMPTY_DEFAULT_ANSWER) {
      updateDefaultAnswer(EMPTY_DEFAULT_ANSWER)
    }
  }, [isSubform, questionDefaultAnswer, updateDefaultAnswer])

  if (isSubform) return null

  return (
    <div className="flex-center mb-4">
      <div className="font-medium">Default</div>
      <ListSelect
        className="text-sm"
        style={{ height: "32px" }}
        options={questionOptions}
        value={questionDefaultAnswer}
        onChange={updateDefaultAnswer}
        clearable={Boolean(questionDefaultAnswer)}
      />
    </div>
  )
}

DefaultAnswer.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default DefaultAnswer
