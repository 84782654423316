import types from "prop-types"
import {
  CURSORS, LEFT, RIGHT, TYPES,
} from "./constants"

export default {
  background: types.string,
  className: types.string,
  color: types.string,
  cursor: types.oneOf(CURSORS),
  disabled: types.bool,
  form: types.string,
  icon: types.string,
  iconPosition: types.oneOf([LEFT, RIGHT]),
  onClick: types.func,
  text: types.oneOfType([types.string, types.object]),
  type: types.oneOf(TYPES),
  noTab: types.bool,
  isDark: types.bool,
}
