import React, { useCallback } from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { useDispatch, useSelector } from "react-redux"
import { deleteFormQuestion, getFormQuestionDeletable } from "reduxSlices/formBuilderSlice"
import SvgSprintIcon from "shared/SvgSpriteIcon"
import Tooltip from "shared/Tooltip"
import QuestionButton from "./QuestionButton"

const DeleteQuestionButton = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const [deletable, descriptions] = useSelector(getFormQuestionDeletable({ sectionIndex, questionIndex }), isEqual)

  const deleteQuestion = useCallback(() => (
    dispatch(deleteFormQuestion({ sectionIndex, questionIndex }))
  ), [dispatch, questionIndex, sectionIndex])

  const tooltipText = deletable ? "" : `Cannot delete question:  ${descriptions.join(", ")}`

  return (
    <Tooltip text={tooltipText}>
      <QuestionButton
        disabled={!deletable}
        onClick={deleteQuestion}
      >
        <SvgSprintIcon iconName="trash-can" width={18} height={18} />
      </QuestionButton>
    </Tooltip>
  )
}

DeleteQuestionButton.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default DeleteQuestionButton
