import React, { useEffect, useMemo, useRef } from "react"
import types from "prop-types"
import clsx from "clsx"
import { FormsyTextInput } from "shared/inputs"

const VALIDATION_ERRORS = {
  isAlpha: "Only letters permitted",
}

const buildQuestionTagValidations = ({ tagNames }) => (
  {
    isAlpha: true,
    isNotBlankString: true,
    mayNotBeIncludedIn: {
      errorMessage: "Question tag already exists",
      denyList: tagNames,
    },
  }
)

const QuestionTagFormFields = ({ className = "", tagNames }) => {
  const firstInputRef = useRef()

  useEffect(() => {
    firstInputRef.current?.focus()
  }, [])

  const validations = useMemo(() => buildQuestionTagValidations({ tagNames }), [tagNames])

  return (
    <div className={clsx(className)}>
      <FormsyTextInput
        className="bordered-base-input text-sm bg-white base-input"
        name="questionTag.name"
        inputRef={firstInputRef}
        validationErrors={VALIDATION_ERRORS}
        validations={validations}
        value=""
      />
    </div>
  )
}

QuestionTagFormFields.propTypes = {
  className: types.string,
  tagNames: types.arrayOf(types.string).isRequired,
}

export default QuestionTagFormFields
