import React from "react"
import types from "prop-types"
import * as API from "services/api"
import { useOncePerPageLoadMessage } from "hooks"
import { UserSearch } from "shared/searches"
import { errorToast } from "shared/toast"
import MemberList from "./MemberList"

const GROUP_REMOVAL_MESSAGE = "Removing a user from a group does not remove the user "
                              + "from any existing tasks or form submissions. Removal from existing "
                              + "tasks or form submissions must be done manually or by using "
                              + "the Historical Access Tool under the user's profile in the Admin area."

const MembersTab = ({ group, addMember, removeMember }) => {
  const { messageAcknowledged, confirmMessage } = useOncePerPageLoadMessage(GROUP_REMOVAL_MESSAGE, "group_removal_message")

  const createGroupMembership = async (memberId) => {
    const response = await API.createGroupMembership({
      groupId: group.id,
      group_membership: { member_id: memberId },
    })
    if (response.ok) {
      addMember(response.data)
    } else {
      errorToast("Something went wrong.  Unable to add user to group.")
    }
  }

  const deleteGroupMembership = async (memberId) => {
    if (messageAcknowledged() || confirmMessage()) {
      const response = await API.deleteGroupMembership({
        groupId: group.id,
        memberId,
      })
      if (response.ok) {
        removeMember(memberId)
      } else {
        errorToast("Something went wrong.  Unable to remove user from group.")
      }
    }
  }

  return (
    <>
      <h2 className="tab-title">Members</h2>
      <UserSearch
        userList={group.members}
        onUserSelected={createGroupMembership}
      />
      <MemberList members={group.members} deleteGroupMembership={deleteGroupMembership} />
    </>
  )
}

MembersTab.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  group: types.object.isRequired,
  addMember: types.func.isRequired,
  removeMember: types.func.isRequired,
}

export default MembersTab
