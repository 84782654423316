import React from "react"
import types from "prop-types"
import { Select } from "shared/selects"
import { snakeToCapitalCase } from "utils/stringHelpers"
import { CONDITION_OPERATORS, DEFAULT_OPERATOR } from "utils/workflowHelpers"

const operatorOptions = CONDITION_OPERATORS.map((operator) => ({
  label: snakeToCapitalCase(operator),
  value: operator,
}))

const SelectOperator = ({ operator = undefined, updateOperator }) => (
  <Select
    backspaceDelete={false}
    style={{ height: "32px", minWidth: "100px" }}
    options={operatorOptions}
    value={operator ?? DEFAULT_OPERATOR}
    onChange={updateOperator}
  />
)

SelectOperator.propTypes = {
  operator: types.string,
  updateOperator: types.func.isRequired,
}

export default SelectOperator
