/* eslint-disable no-unused-vars */
import React from "react"
import { adminFacilityDepartmentShape } from "utils/propTypeShapes"
import clsx from "clsx"
import DepartmentActiveStatus from "./DepartmentActiveStatus"
import DepartmentHeader from "./DepartmentHeader"
import DepartmentMembers from "./DepartmentMembers"

const SHARED_SECTION_CLASSES = "w-full p-4 flex"

const DepartmentListItem = ({ department }) => (
  <div className="flex-col items-start p-0 list-row dark divide-y divide-light-300">
    <div className={clsx("flex-col", SHARED_SECTION_CLASSES)}>
      <DepartmentHeader department={department} />
    </div>
    <div className={clsx("flex-col items-start", SHARED_SECTION_CLASSES)}>
      <DepartmentMembers department={department} />
    </div>
    <div className={clsx("justify-end", SHARED_SECTION_CLASSES)}>
      <DepartmentActiveStatus department={department} />
    </div>
  </div>
)

DepartmentListItem.propTypes = {
  department: adminFacilityDepartmentShape.isRequired,
}

export default DepartmentListItem
