import React from "react"
import types from "prop-types"

const ActionLabelContainer = ({ children }) => (
  <div className="flex flex-col mb-6 last:mb-0">
    {children}
  </div>
)

ActionLabelContainer.propTypes = {
  children: types.node.isRequired,
}

export default ActionLabelContainer
