import React from "react"
import types from "prop-types"
import { dynamicRangeReportableFieldsExample, dayRangeReportableFieldInclusionDescription } from "utils/scheduledReportExportHelpers"

const DaysReportableFieldDescription = ({ startDate, reportableFieldAnswerPreviousRangeLength = null }) => {
  const daysCount = reportableFieldAnswerPreviousRangeLength || 1

  const beginningDate = startDate.subtract(daysCount, "d")
  const endingDate = startDate.subtract(1, "d")

  return (
    <div className="text-xs text-dark mt-2">
      <p className="mb-4">
        {dayRangeReportableFieldInclusionDescription}
      </p>
      <p>
        {dynamicRangeReportableFieldsExample({ startDate, beginningDate, endingDate })}
      </p>
    </div>
  )
}

DaysReportableFieldDescription.propTypes = {
  startDate: types.object.isRequired,
  reportableFieldAnswerPreviousRangeLength: types.number,
}

export default DaysReportableFieldDescription
