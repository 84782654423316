import React from "react"
import LazyLoad from "react-lazyload"
import { orderBy } from "lodash-es"
import { useSurveyConfigurationContext } from "contexts/SurveyConfigurationContext"
import { dayjsOrgTz } from "utils/dateHelpers"
import BatchInfo from "./BatchInfo"

const BatchesList = () => {
  const { surveyConfiguration } = useSurveyConfigurationContext()
  const { surveyBatches } = surveyConfiguration

  return (
    orderBy(surveyBatches, [(batch) => dayjsOrgTz(batch.createdAt)], ["desc"]).map((batch) => (
      <LazyLoad key={`batch-row-${batch.id}`} height={90} offset={100} once resize>
        <BatchInfo batch={batch} />
      </LazyLoad>
    ))
  )
}

export default BatchesList
