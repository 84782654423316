import React, { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import activeBeforeInactive from "utils/activeBeforeInactive"
import { facilityDepartments } from "reduxSlices/adminFacilitySlice"
import DepartmentActiveStatusFilter, {
  ACTIVE_STATUS,
  INACTIVE_STATUS,
} from "./DepartmentActiveStatusFilter"
import DepartmentListItem from "./DepartmentListItem"

const checkIfActive = (department, activeStatusFilter) => {
  if (activeStatusFilter === ACTIVE_STATUS) {
    return department.active
  }

  if (activeStatusFilter === INACTIVE_STATUS) {
    return !department.active
  }

  return true
}

const DepartmentList = () => {
  const [activeStatusFilter, setActiveStatusFilter] = useState(ACTIVE_STATUS)

  const departments = useSelector(facilityDepartments)

  const filteredDepartments = useMemo(() => {
    const filtered = departments.filter((department) => (
      checkIfActive(department, activeStatusFilter)
    ))

    return filtered.sort(activeBeforeInactive)
  }, [activeStatusFilter, departments])

  if (departments.length === 0) {
    return <span className="m-2">No Facility Departments</span>
  }

  return (
    <>
      <div className="mb-6">
        <DepartmentActiveStatusFilter
          onChange={setActiveStatusFilter}
          value={activeStatusFilter}
        />
      </div>
      <ol>
        {
          filteredDepartments.map((department) => (
            <li key={department.id}>
              <DepartmentListItem department={department} />
            </li>
          ))
        }
      </ol>
    </>
  )
}

export default DepartmentList
