import React from "react"
import types from "prop-types"

const CategoryInfo = ({ category, goToCategoryEdit }) => (
  <div
    className="flex-grow cursor-pointer flex-center"
    onClickCapture={goToCategoryEdit(category)}
  >
    <span>{ category.name }</span>
  </div>
)

CategoryInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  category: types.object.isRequired,
  goToCategoryEdit: types.func.isRequired,
}

export default CategoryInfo
