import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const WorkflowActionSendNotificationIcon = ({ className = "w-8 h-8" }) => (
  <SvgSpriteIcon iconName="workflow-actions-send-notification" className={className} />
)

WorkflowActionSendNotificationIcon.propTypes = {
  className: types.string,
}

export default WorkflowActionSendNotificationIcon
