import React, { useState } from "react"
import types from "prop-types"
import * as API from "services/api"
import { redirectTo } from "utils/routeHelpers"
import { errorToast, successToast } from "shared/toast"
import ReportContext from "../ReportContext"

const ReportContextProvider = ({
  apiCallToDeleteReport,
  children,
  editPath,
  reports: propReports,
}) => {
  const [reports, setReports] = useState(propReports)

  const getScheduleActiveStatus = (scheduleId) => {
    const foundReport = reports.find((report) => report.scheduledReportExport?.scheduledReportExportSchedule?.id === scheduleId)

    if (foundReport?.scheduledReportExport) {
      return foundReport.scheduledReportExport.scheduledReportExportSchedule?.active
    }
    return null
  }

  const setScheduleActiveStatus = (scheduleId, active) => {
    const updatedReports = reports.map((report) => {
      if (report.scheduledReportExport?.scheduledReportExportSchedule?.id === scheduleId) {
        return {
          ...report,
          scheduledReportExport: {
            ...report.scheduledReportExport,
            scheduledReportExportSchedule: {
              ...report.scheduledReportExport.scheduledReportExportSchedule,
              active,
            },
          },
        }
      }
      return report
    })

    setReports(updatedReports)
  }

  const deleteReport = (reportId) => async () => {
    // eslint-disable-next-line no-alert
    if (window.confirm("Are you sure you want to delete this report?")) {
      const response = await apiCallToDeleteReport({ id: reportId })

      if (response.ok) {
        const filteredReports = reports.filter((r) => r.id !== reportId)
        setReports(filteredReports)
        successToast("Report deleted successfully!")
      } else {
        errorToast("Something went wrong. Unable to delete report.")
      }
    }
  }

  const goToReportEditPage = (reportId) => () => redirectTo(editPath(reportId))

  const activate = (scheduleId) => async () => {
    const response = await API.activateScheduledReportExportSchedule({ scheduleId })

    if (response.ok) {
      setScheduleActiveStatus(scheduleId, true)
    } else {
      errorToast("Something went wrong. Unable to activate SFTP uploads")
    }
  }

  const deactivate = (scheduleId) => async () => {
    const response = await API.deactivateScheduledReportExportSchedule({ scheduleId })

    if (response.ok) {
      setScheduleActiveStatus(scheduleId, false)
    } else {
      errorToast(`Unable to deactivate SFTP uploads: ${response.data.errors.join(", ")}.`)
    }
  }

  // Object exposed to context consumers
  const contextConsumerValue = {
    activate,
    deactivate,
    deleteReport,
    goToReportEditPage,
    reports,
    getScheduleActiveStatus,
  }

  return (
    <ReportContext.Provider value={contextConsumerValue}>
      {children}
    </ReportContext.Provider>
  )
}

ReportContextProvider.propTypes = {
  children: types.node.isRequired,
  reports: types.arrayOf(types.object).isRequired,
  apiCallToDeleteReport: types.func.isRequired,
  editPath: types.func.isRequired,
}

export default ReportContextProvider
