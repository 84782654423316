import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import types from "prop-types"
import withReduxProvider from "shared/withReduxProvider"
import {
  selectAllSubmissionDefaultColumns,
  selectAllSurveyDefaultColumns,
  selectAllTaskDefaultColumns,
  setCategories,
  setReportType,
  setSubmissionColumnOptions,
  setSurveyColumnOptions,
  setTaskColumnOptions,
  SOLUTION_REPORT_TYPE,
  SUBMISSION_REPORT_TYPE,
  SURVEY_REPORT_TYPE,
} from "reduxSlices/reportBuilderSlice"
import { reportExportBuilderColumnOptionShape } from "utils/propTypeShapes"
import ReportBuilder from "../ReportBuilder"

const ReportCreator = ({
  categories,
  reportType,
  submissionColumnOptions,
  surveyColumnOptions = [],
  surveyNonAnonymousColumnOptions = [],
  taskColumnOptions,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setCategories(categories))
    dispatch(setReportType(reportType))

    dispatch(setSubmissionColumnOptions(submissionColumnOptions))
    dispatch(selectAllSubmissionDefaultColumns())

    if (reportType === SURVEY_REPORT_TYPE) {
      dispatch(setSurveyColumnOptions({ surveyColumnOptions, surveyNonAnonymousColumnOptions }))
      dispatch(selectAllSurveyDefaultColumns())
    }

    dispatch(setTaskColumnOptions(taskColumnOptions))
    dispatch(selectAllTaskDefaultColumns())
  },
  [
    dispatch,
    categories,
    reportType,
    submissionColumnOptions,
    surveyColumnOptions,
    surveyNonAnonymousColumnOptions,
    taskColumnOptions,
  ])

  return (<ReportBuilder />)
}

ReportCreator.propTypes = {
  categories: types.arrayOf(types.shape({
    name: types.string.isRequired,
    slug: types.string.isRequired,
  })).isRequired,
  reportType: types.oneOf([SOLUTION_REPORT_TYPE, SUBMISSION_REPORT_TYPE, SURVEY_REPORT_TYPE]).isRequired,
  submissionColumnOptions: types.arrayOf(reportExportBuilderColumnOptionShape).isRequired,
  surveyColumnOptions: types.arrayOf(reportExportBuilderColumnOptionShape),
  surveyNonAnonymousColumnOptions: types.arrayOf(reportExportBuilderColumnOptionShape),
  taskColumnOptions: types.arrayOf(reportExportBuilderColumnOptionShape).isRequired,
}

export default withReduxProvider(ReportCreator)
