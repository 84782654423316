import React from "react"
import types from "prop-types"
import ChangeInAnswer from "./ChangeInAnswer"

const AuditAnswerDetails = ({ audit, prompt }) => {
  const answerFrom = audit.auditedChanges[prompt].from
  const answerTo = audit.auditedChanges[prompt].to

  return (
    <div className="mb-4 last:mb-0">
      <p>{prompt}:</p>
      <ChangeInAnswer answerFrom={answerFrom} answerTo={answerTo} />
    </div>
  )
}

AuditAnswerDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  audit: types.object.isRequired,
  prompt: types.string.isRequired,
}

export default AuditAnswerDetails
