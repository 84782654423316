import React, { useState } from "react"
import types from "prop-types"
import clsx from "clsx"
import ReactPaginate from "react-paginate"
import SvgSpriteIcon from "./SvgSpriteIcon"

// https://github.com/AdeleD/react-paginate

const Paginate = ({
  className = "",
  initialPage = 0,
  marginPagesDisplayed = 2,
  onPageChange,
  pageCount,
  pageRangeDisplayed = 3,
}) => {
  const [selectedPage, setSelectedPage] = useState(initialPage)
  const changePage = ({ selected }) => {
    setSelectedPage(selected)
    onPageChange(selected)
  }

  const previousLabel = (
    <div className={clsx("page w-1.5 h-3", selectedPage === 0 && "invisible")}>
      <SvgSpriteIcon iconName="paginate-previous" />
    </div>
  )

  const nextLabel = (
    <div className={clsx("page w-1.5 h-3", selectedPage === pageCount - 1 && "invisible")}>
      <SvgSpriteIcon iconName="paginate-next" />
    </div>
  )

  if (pageCount < 2) return null

  return (
    <div className={className}>
      <ReactPaginate
        containerClassName="paginate"
        pageClassName="page"
        pageLinkClassName="page-link"
        previousLabel={previousLabel}
        nextLabel={nextLabel}
        pageCount={pageCount}
        initialPage={initialPage}
        pageRangeDisplayed={pageRangeDisplayed}
        marginPagesDisplayed={marginPagesDisplayed}
        onPageChange={changePage}
      />
    </div>
  )
}

Paginate.propTypes = {
  className: types.string,
  pageCount: types.number.isRequired,
  onPageChange: types.func.isRequired,
  initialPage: types.number,
  pageRangeDisplayed: types.number,
  marginPagesDisplayed: types.number,
}

export default Paginate
