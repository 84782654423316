import React from "react"
import types from "prop-types"
import clsx from "clsx"

const ContentHeader = ({
  className = "", heading, leftElements = null, rightElements = null,
}) => (
  <div className={clsx("content-header", className)}>
    <div className="content-header-left">
      {leftElements}
    </div>
    <div className="content-header-heading">
      {heading}
    </div>
    <div className="content-header-right">
      {rightElements}
    </div>
  </div>
)

ContentHeader.propTypes = {
  className: types.string,
  heading: types.node.isRequired,
  leftElements: types.node,
  rightElements: types.node,
}

export default ContentHeader
