import React, { createRef, useState } from "react"
import types from "prop-types"
import Formsy from "formsy-react"
import { useSurveyBatchContext } from "contexts/SurveyBatchContext"
import * as API from "services/api"
import { yearMonthDayDateWithTz, yearMonthDayDate, yesterdayDateWithTimezone } from "utils/dateHelpers"
import {
  ANONYMOUS_SURVEY_DESCRIPTION,
  REMINDER_FREQUENCY_OPTIONS,
} from "utils/surveyHelpers"
import { PrimaryButton, Button } from "shared/buttons"
import FieldLabel from "shared/CopyFormToOrganization/FieldLabel"
import {
  FormsyDateInput, FormsyHiddenInput, FormsyRadioButton, FormsyTextInput,
} from "shared/inputs"
import { FormsySelect } from "shared/selects"
import { errorToast, successToast } from "shared/toast"

const SurveyBatchDetails = ({ className = "" }) => {
  const {
    setIsSurveyBatchOpen,
    setSurveyBatchCloseDate,
    surveyBatch,
    surveyBatchCloseDate,
    surveyBatchOpen,
    updateSurveyBatch,
  } = useSurveyBatchContext()

  const [isFormValid, setIsFormValid] = useState(true)

  const { id: surveyBatchId } = surveyBatch
  const { id: surveyConfigurationId } = surveyBatch.surveyConfiguration

  const onSubmit = async (model) => {
    const response = await API.updateSurveyBatch({ surveyConfigurationId, surveyBatchId, surveyBatch: model })

    if (response.ok) {
      successToast("Survey Updated successfully!")
      updateSurveyBatch(response.data)
    } else {
      errorToast("Something went wrong. Unable to update survey.", response)
    }
  }

  const formRef = createRef()

  const closeSurveyBatch = () => {
    setIsSurveyBatchOpen(false)
    // set closes_on to yesterday so batch won't accept more submissions
    setSurveyBatchCloseDate(yesterdayDateWithTimezone(surveyBatch.timezone))
  }

  const openSurveyBatch = () => {
    setIsSurveyBatchOpen(true)
    setSurveyBatchCloseDate(null)
  }

  const resetForm = () => {
    formRef.current?.reset(surveyBatch)
    setIsSurveyBatchOpen(surveyBatch.open)
    setSurveyBatchCloseDate(surveyBatch.closesAt ? yearMonthDayDateWithTz(surveyBatch.closesAt, surveyBatch.timezone) : undefined)
  }

  const enableSubmit = () => setIsFormValid(true)
  const disableSubmit = () => setIsFormValid(false)

  return (
    <div className={className}>
      <Formsy
        ref={formRef}
        onSubmit={onSubmit}
        onValid={enableSubmit}
        onInvalid={disableSubmit}
      >

        <FieldLabel text="Name" className="!max-w-full" boldLabel required>
          <FormsyTextInput
            required
            className="bordered-base-input"
            name="name"
            placeholder="Survey name..."
            value={surveyBatch.name}
            validations={{ isNotBlankString: true }}
          />
        </FieldLabel>

        <FieldLabel text="Email Reminders" className="mb-6" boldLabel required>
          <FormsySelect
            className="min-w-80"
            required
            name="reminders"
            backspaceDelete={false}
            options={REMINDER_FREQUENCY_OPTIONS}
            value={surveyBatch.reminders}
          />
        </FieldLabel>

        <FieldLabel text="Status" className="mb-4" boldLabel required>
          <div className="flex gap-6">
            <FormsyRadioButton
              label="Open"
              value={surveyBatchOpen.toString()}
              name="open"
              onChange={openSurveyBatch}
              checked={surveyBatchOpen}
            />

            <FormsyRadioButton
              label="Closed"
              value={(!surveyBatchOpen).toString()}
              name="close"
              onChange={closeSurveyBatch}
              checked={!surveyBatchOpen}
            />
          </div>
        </FieldLabel>

        {
          surveyBatchOpen
            ? (
              <>
                <span>
                  Select an end date or leave blank to keep open:
                </span>
                <FormsyDateInput
                  className="mb-4"
                  clearable
                  name="closes_on"
                  min={yearMonthDayDate()}
                  value={surveyBatchCloseDate || ""}
                />
              </>
            )
            : (
              <div className="mb-4">
                Submissions not accepted after {surveyBatchCloseDate}
                <FormsyHiddenInput
                  name="closes_on"
                  value={surveyBatchCloseDate}
                />
              </div>
            )
        }
        <FieldLabel text={surveyBatch.anonymous ? "Anonymous Submissions" : "Tracked Submissions"} className="mb-6" boldLabel>
          <div>
            {(
              surveyBatch.anonymous ? ANONYMOUS_SURVEY_DESCRIPTION : "Submissions are tracked and associated with respondent."
            )}
          </div>
        </FieldLabel>

        <div className="flex justify-center mt-8">
          <Button
            text="Reset"
            onClick={resetForm}
            className="border border-light-300 mr-2"
          />
          <PrimaryButton
            text="Update"
            type="submit"
            disabled={!isFormValid}
          />
        </div>
      </Formsy>

    </div>
  )
}

SurveyBatchDetails.propTypes = {
  className: types.string,
}

export default SurveyBatchDetails
