import React, { useEffect, useState } from "react"
import types from "prop-types"
import { isEmpty } from "lodash-es"
import StrikeAround from "components/shared/StrikeAround"
import { useSelector } from "react-redux"
import { formSubmissionData } from "reduxSlices/formSubmissionSlice"
import * as API from "services/api"
import { errorToast } from "shared/toast"
import SurveyTokenPublicDetails from "./SurveyTokenPublicDetails"

const DETAIL_LOAD_ERROR_MESSAGE = "Something went wrong.  Unable to load survey details."

const SurveyDetails = ({ className }) => {
  const { forSurvey, slug: formSubmissionSlug } = useSelector(formSubmissionData)

  const [details, setDetails] = useState({})

  useEffect(() => {
    if (!forSurvey) return

    const loadDetails = async () => {
      const response = await API.formSubmissionSurveyTokenPublicDetails({ formSubmissionSlug })

      if (response.ok) {
        setDetails(response.data)
      } else {
        errorToast(DETAIL_LOAD_ERROR_MESSAGE)
      }
    }

    loadDetails()
  }, [formSubmissionSlug, forSurvey])

  if (!forSurvey || isEmpty(details)) return null

  return (
    <div className={className}>
      <StrikeAround className="mb-6">
        <h3>Survey Details</h3>
      </StrikeAround>
      <SurveyTokenPublicDetails className="p-4 bg-white rounded-lg border border-light-300" surveyDetails={details} />
    </div>
  )
}

SurveyDetails.defaultProps = {
  className: "",
}

SurveyDetails.propTypes = {
  className: types.string,
}

export default SurveyDetails
