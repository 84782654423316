import React from "react"
import types from "prop-types"
import { TextInput } from "shared/inputs"
import {
  DAILY,
  WEEKLY,
  MONTHLY,
} from "utils/recurrenceDetailsHelpers"
import DailyRecurrenceDetails from "./RecurrenceDetails/DailyRecurrenceDetails"
import MonthlyRecurrenceDetails from "./RecurrenceDetails/MonthlyRecurrenceDetails"
import WeeklyRecurrenceDetails from "./RecurrenceDetails/WeeklyRecurrenceDetails"

const DetailFields = ({
  frequency, recurrenceDayOfMonth, recurrenceDayOfWeek, setRecurrenceDayOfMonth, setRecurrenceDayOfWeek, setScheduledReportExportName, setStartDate, scheduledReportExportName, startDate = null,
}) => {
  const renderRecurrenceDetails = () => {
    switch (frequency) {
    case DAILY:
      return (
        <DailyRecurrenceDetails
          setStartDate={setStartDate}
          startDate={startDate}
        />
      )
    case WEEKLY:
      return (
        <WeeklyRecurrenceDetails
          recurrenceDayOfWeek={recurrenceDayOfWeek}
          setRecurrenceDayOfWeek={setRecurrenceDayOfWeek}
          setStartDate={setStartDate}
        />
      )
    case MONTHLY:
      return (
        <MonthlyRecurrenceDetails
          recurrenceDayOfMonth={recurrenceDayOfMonth}
          setRecurrenceDayOfMonth={setRecurrenceDayOfMonth}
          setStartDate={setStartDate}
        />
      )
    default:
      return null
    }
  }

  return (
    <>
      <TextInput
        className="border-none p-0 m-0"
        name="name"
        value={scheduledReportExportName}
        onChange={setScheduledReportExportName}
      />

      <div className="flex flex-wrap items-center my-4">
        <span className="mr-1">
          Upload reports
          <span className="mx-1 font-bold">
            {frequency}
          </span>
        </span>
        {renderRecurrenceDetails()}
      </div>
    </>
  )
}

DetailFields.propTypes = {
  frequency: types.string.isRequired,
  recurrenceDayOfMonth: types.number.isRequired,
  recurrenceDayOfWeek: types.string.isRequired,
  setRecurrenceDayOfMonth: types.func.isRequired,
  setRecurrenceDayOfWeek: types.func.isRequired,
  setScheduledReportExportName: types.func.isRequired,
  setStartDate: types.func.isRequired,
  scheduledReportExportName: types.string.isRequired,
  startDate: types.object,
}

export default DetailFields
