import React, { Component } from "react"
import Formsy from "formsy-react"
import Modal from "shared/Modal"
import { XIcon } from "shared/icons"
import { PrimaryButton, Button } from "shared/buttons"
import { FormsyTextInput } from "shared/inputs"
import * as API from "services/api"
import { successToast, errorToast } from "shared/toast"

class CreateFacilityModal extends Component {
  state = { isFormValid: false, modalIsOpen: false }

  static onSubmit = async (model) => {
    const response = await API.createFacility({ facility: model })
    if (response.ok) {
      window.location = `/admin/facilities/${response.data.id}`
      successToast("Facility created successfully!")
    } else {
      console.error("Error updating facility: ", response)
      errorToast("Something went wrong. Unable to create facility.", response)
    }
  }

  closeModal = () => this.setState({ modalIsOpen: false })

  openModal = () => this.setState({ modalIsOpen: true })

  enableSubmit = () => this.setState({ isFormValid: true })

  disableSubmit = () => this.setState({ isFormValid: false })

  render() {
    const { isFormValid, modalIsOpen } = this.state

    return (
      <>
        <div className="flex justify-center">
          <Button
            text="+ Add Facility"
            onClick={this.openModal}
            className="text-focus border border-light my-4"
          />
        </div>

        <Modal isOpen={modalIsOpen}>
          <div className="text-center text-xl font-semibold mb-8 relative">
            New Facility
            <XIcon
              className="absolute -top-4 right-4 rounded-full bg-primary-light p-2 h-8 w-8"
              onClick={this.closeModal}
            />
          </div>
          <Formsy
            onSubmit={CreateFacilityModal.onSubmit}
            onValid={this.enableSubmit}
            onInvalid={this.disableSubmit}
          >
            <div className="w-3/4 mx-auto">
              <FormsyTextInput
                className="bordered-base-input text-sm"
                name="name"
                placeholder="Name"
                value=""
                required
                validations={{ isNotBlankString: true }}
                validationErrors={{ isDefaultRequiredValue: "required" }}
              />
              <div className="flex justify-center mt-8">
                <Button
                  text="Cancel"
                  onClick={this.closeModal}
                  className="border border-light-300 mr-2"
                />
                <PrimaryButton
                  text="Create"
                  type="submit"
                  disabled={!isFormValid}
                />
              </div>
            </div>
          </Formsy>
        </Modal>
      </>
    )
  }
}

export default CreateFacilityModal
