import React from "react"
import types from "prop-types"
import * as API from "services/api"
import { useSelector, useDispatch } from "react-redux"
import {
  disableTaskNotifySetting,
  enableTaskNotifySetting,
  isTaskNotifySettingEnabled,
} from "reduxSlices/currentSettingsSlice"
import Switch from "components/shared/Switch"
import { errorToast } from "components/shared/toast"

const UPDATE_ERROR_MESSAGE = "Something went wrong. Unable to update task notification setting."

const NotificationSetting = ({ label, settingType }) => {
  const isEnabled = useSelector(isTaskNotifySettingEnabled(settingType))
  const dispatch = useDispatch()

  const enable = (type) => async () => {
    const response = await API.enableNotificationSetting({ settingType: type })

    if (response.ok) {
      dispatch(enableTaskNotifySetting(type))
    } else {
      errorToast(UPDATE_ERROR_MESSAGE)
    }
  }

  const disable = (type) => async () => {
    const response = await API.disableNotificationSetting({ settingType: type })

    if (response.ok) {
      dispatch(disableTaskNotifySetting(type))
    } else {
      errorToast(UPDATE_ERROR_MESSAGE)
    }
  }

  return (
    <div className="flex gap-8 items-center">
      <span className="flex flex-grow justify-start ml-16">{label}</span>
      <Switch
        checked={isEnabled}
        onChange={isEnabled ? disable(settingType) : enable(settingType)}
      />
    </div>
  )
}

NotificationSetting.propTypes = {
  label: types.string.isRequired,
  settingType: types.string.isRequired,
}

export default NotificationSetting
