import React from "react"
import ActivityIndicator from "shared/ActivityIndicator"

const ConnectionTestingCheck = () => (
  <div className="flex flex-col items-center">
    <ActivityIndicator className="mb-8" />
    <p className="text-center">
      Testing connection...
    </p>
  </div>
)

export default React.memo(ConnectionTestingCheck)
