import { v4 as makeUuid } from "uuid"
import { DirectUpload } from "@rails/activestorage"
import * as API from "services/api"
import { ACTIVE_STORAGE_DIRECT_UPLOADS_PATH } from "utils/routeHelpers"

class AcceptedFile {
  constructor(acceptedFileData, formSubmissionSlug, onComplete) {
    this.attached = false
    this.bytesUploaded = 0
    this.directUpload = new DirectUpload(acceptedFileData, ACTIVE_STORAGE_DIRECT_UPLOADS_PATH, this)
    this.error = false
    this.file = acceptedFileData
    this.formSubmissionSlug = formSubmissionSlug
    this.onComplete = onComplete
    this.uuid = makeUuid()
  }

  get filename() {
    return this.file.name
  }

  get fileSize() {
    return this.file.size
  }

  get percentUploaded() {
    return Math.floor(100 * (this.bytesUploaded / this.fileSize))
  }

  get uploadComplete() {
    return this.percentUploaded === 100
  }

  updateProgress(event) {
    this.bytesUploaded = event.loaded
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", (event) => this.updateProgress(event));
  }

  async attachBlobToFormSubmission(blob) {
    const { signed_id: blobSignedId } = blob

    const response = await API.createAttachment({
      formSubmissionSlug: this.formSubmissionSlug,
      blobSignedId,
    })

    if (response.ok) {
      this.attached = true
      this.onComplete(response.data)
    } else {
      this.error = true
    }
  }

  process() {
    this.directUpload.create((error, blob) => {
      if (error) {
        this.error = true
      } else {
        this.attachBlobToFormSubmission(blob)
      }
    })
  }
}

export default AcceptedFile
