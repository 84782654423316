import React from "react"
import { useSurveyReportExportContext } from "contexts/SurveyReportExportContext"
import FileTypeSelect from "../ReportExport/FileTypeSelect"

const NewSurveyReportExportFileTypeSelect = () => {
  const {
    reportExportFileType,
    setReportExportFileType,
  } = useSurveyReportExportContext()

  console.log(setReportExportFileType)

  return (
    <FileTypeSelect
      selectedFileType={reportExportFileType}
      onChange={setReportExportFileType}
    />
  )
}

export default NewSurveyReportExportFileTypeSelect
