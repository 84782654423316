import React, { useState, useEffect } from "react"
import * as API from "services/api"
import { SmallWrap } from "shared/wraps"
import queryParameter from "utils/queryParameter"
import { SearchInput } from "shared/inputs"
import SearchTitleHeader from "./SearchTitleHeader"
import PaginatedSearchResults from "./PaginatedSearchResults"

const PER_PAGE = 6

const searchQueryParams = queryParameter("searchQuery") || ""

const TasksAndSubmissionsSearch = () => {
  const [searchQuery, setSearchQuery] = useState(searchQueryParams)
  const [hasCompletedSearch, setHasCompletedSearch] = useState(false)
  const [results, setResults] = useState([])

  const search = async (query) => {
    const res = await API.tasksAndSubmissionsSearch({ query, pageSize: 100 })
    if (res.ok) {
      setResults(res.data)
      setHasCompletedSearch(true)
    } else {
      console.error("Error searching:", res);
    }
  }

  useEffect(() => {
    if (searchQueryParams) {
      search(searchQueryParams)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SmallWrap>
      <SearchTitleHeader
        searchQuery={searchQuery}
        hasCompletedSearch={hasCompletedSearch}
      />
      <SearchInput
        search={search}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <PaginatedSearchResults
        results={results}
        hasCompletedSearch={hasCompletedSearch}
        perPage={PER_PAGE}
      />
    </SmallWrap>
  )
}

export default TasksAndSubmissionsSearch
