import React from "react"

const EmptyAudits = () => {
  const NO_DATA_TEXT = "User has no sign-in attempts"

  return (
    <>
      <h2 className="tab-title">Sign-in History</h2>
      <div>{ NO_DATA_TEXT }</div>
    </>
  )
}

export default EmptyAudits
