import React from "react"
import { useHistoricalAccessToolContext } from "contexts/HistoricalAccessToolContext"
import MassSelection from "shared/MassSelection"
import HistoricalAccessToolTaskForm from "./HistoricalAccessToolTaskForm"

const HistoricalAccessToolTaskForms = () => {
  const { deselectAllTaskForms, selectAllTaskForms, taskForms } = useHistoricalAccessToolContext()

  return (
    <>
      <MassSelection deselectAll={deselectAllTaskForms} selectAll={selectAllTaskForms} className="mb-5" />
      {
        taskForms.map(({ id, title }) => (
          <HistoricalAccessToolTaskForm
            key={id}
            className="mb-4 last:mb-0"
            id={id}
            title={title}
          />
        ))
      }
    </>
  )
}

export default HistoricalAccessToolTaskForms
