import React from "react"
import types from "prop-types"
import { useDispatch } from "react-redux"
import { copyFormQuestion } from "reduxSlices/formBuilderSlice"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import QuestionButton from "./QuestionButton"

const CopyQuestionButton = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const copyQuestion = () => dispatch(copyFormQuestion({ sectionIndex, questionIndex }))

  return (
    <QuestionButton onClick={copyQuestion}>
      <SvgSpriteIcon iconName="copy-question" width={18} height={18} />
    </QuestionButton>
  )
}

CopyQuestionButton.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default CopyQuestionButton
