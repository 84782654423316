import React, { useEffect } from "react"
import withReduxProvider from "shared/withReduxProvider"
import { endAllTabSessionsListener } from "utils/endSession"
import { allTabImpersonationsListener } from "utils/impersonation"
import GlobalHeaderDropdown from "shared/headers/GlobalHeader/GlobalHeaderDropdown"
import Panel from "./Panel"
import PanelTrigger from "./PanelTrigger"

const UsersPanel = () => {
  useEffect(() => {
    endAllTabSessionsListener()
    allTabImpersonationsListener()
  })

  return (
    <GlobalHeaderDropdown
      dropdownStyles="p-6"
      trigger={(
        <div className="p-3">
          <PanelTrigger />
        </div>
      )}
    >
      <Panel />
    </GlobalHeaderDropdown>
  )
}

export default withReduxProvider(UsersPanel)
