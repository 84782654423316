import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { PencilIcon } from "shared/icons"

const DEFAULT_STYLES = "rounded-full bg-light-300 h-8 w-8 flex justify-center items-center cursor-pointer"

const EditSurveyButton = ({ className = "", onClick }) => (
  <button className={clsx(className, DEFAULT_STYLES)} onClick={onClick}>
    <PencilIcon className="h-4 w-4" />
  </button>
)

EditSurveyButton.propTypes = {
  className: types.string,
  onClick: types.func.isRequired,
}

export default EditSurveyButton
