import React, { useState, useEffect, useCallback } from "react"
import types from "prop-types"
import { debounce } from "lodash-es"
import { MultilineTextInput } from "shared/inputs"

const DEBOUNCE_TIME = 1000
const DEBOUNCE_OPTIONS = {
  leading: false,
  trailing: true,
}

const TooltipDescriptionInput = ({ updateTooltipDescription, tooltipDescription }) => {
  const [formTooltip, setFormTooltip] = useState(tooltipDescription)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateFormDescription = useCallback(debounce(
    async (newDescription) => {
      const { ok } = await updateTooltipDescription(newDescription)
      if (!ok) {
        setFormTooltip(tooltipDescription)
      }
    },
    DEBOUNCE_TIME,
    DEBOUNCE_OPTIONS,
  ), [])

  useEffect(
    () => {
      if (formTooltip !== tooltipDescription) {
        debouncedUpdateFormDescription(formTooltip)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formTooltip],
  )

  return (
    <MultilineTextInput
      className="bordered-base-input"
      placeholder="Optional Tooltip..."
      value={formTooltip}
      onChange={setFormTooltip}
    />
  )
}

TooltipDescriptionInput.propTypes = {
  tooltipDescription: types.string.isRequired,
  updateTooltipDescription: types.func.isRequired,
}

export default TooltipDescriptionInput
