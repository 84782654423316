import React from "react"
import { useSurveyScheduledReportExportContext } from "contexts/SurveyScheduledReportExportContext"
import { ListSelect } from "shared/selects"
import {
  dayjsOrgTz, DAYS, MONTHS, WEEKS,
} from "utils/dateHelpers"
import DaysSurveyDateDescription from "./SurveyDateDescriptions/DaysSurveyDateDescription"
import WeeksSurveyDateDescription from "./SurveyDateDescriptions/WeeksSurveyDateDescription"
import MonthsSurveyDateDescription from "./SurveyDateDescriptions/MonthsSurveyDateDescription"

const NewSurveyScheduledReportExportSurveyDateFilters = () => {
  const {
    startDate,
    surveySentPreviousRangeLength,
    surveySentPreviousRangeUnit,
    setSurveySentPreviousRangeLength,
    lengthOptions,
  } = useSurveyScheduledReportExportContext()

  return (
    <>
      <div className="flex items-center mb-4">
        <span className="required-prompt">Surveys sent in the previous</span>
        <ListSelect
          className="bordered-base-input ml-2 max-w-max"
          clearable={false}
          backspaceDelete={false}
          placeholder="1"
          name="submission_previous_range_length"
          required
          options={lengthOptions}
          value={surveySentPreviousRangeLength}
          onChange={setSurveySentPreviousRangeLength}
        />
        <div className="ml-2">{surveySentPreviousRangeUnit}</div>
      </div>

      {
        surveySentPreviousRangeUnit === DAYS
        && (
          <DaysSurveyDateDescription
            // DateInput sets value as a string
            startDate={dayjsOrgTz(startDate, "YYYY-MM-DD")}
            surveySentPreviousRangeLength={surveySentPreviousRangeLength}
          />
        )
      }
      {
        surveySentPreviousRangeUnit === WEEKS
        && (
          <WeeksSurveyDateDescription
            startDate={startDate}
            surveySentPreviousRangeLength={surveySentPreviousRangeLength}
          />
        )
      }
      {
        surveySentPreviousRangeUnit === MONTHS
        && (
          <MonthsSurveyDateDescription
            startDate={startDate}
            surveySentPreviousRangeLength={surveySentPreviousRangeLength}
          />
        )
      }
    </>
  )
}

export default NewSurveyScheduledReportExportSurveyDateFilters
