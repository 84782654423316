import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { SearchIcon } from "shared/icons"
import { TextInput } from "shared/inputs"

const NameFilter = ({ className = "", onChange, value }) => (
  <div className={clsx(className, "relative")}>
    <TextInput className="bordered-base-input !text-sm bg-white" onChange={onChange} value={value} />
    <SearchIcon className="absolute-vertical-center right-3" />
  </div>
)

NameFilter.propTypes = {
  className: types.string,
  onChange: types.func.isRequired,
  value: types.string.isRequired,
}

export default NameFilter
