import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { FORM_ICON_COLOR_MAP } from "./constants"

const SelectableIconColor = ({ iconColor, isSelected, setSelectedIconColor }) => (
  <div
    className={clsx(
      "flex items-center justify-center rounded-xl border-4",
      !isSelected && "border-transparent",
      isSelected && "border-lightgray-3",
    )}
  >
    <div
      className={clsx(
        "w-9 h-9 m-px rounded-md",
        FORM_ICON_COLOR_MAP[iconColor] ?? "bg-icon-gray",
      )}
      onClickCapture={() => setSelectedIconColor(iconColor)}
    />
  </div>
)

SelectableIconColor.propTypes = {
  iconColor: types.string.isRequired,
  setSelectedIconColor: types.func.isRequired,
  isSelected: types.bool.isRequired,
}

export default SelectableIconColor
