import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getFormIsSubform, getFormQuestionType } from "reduxSlices/formBuilderSlice"
import { formElementTypeOptions, questionTypeIcon } from "views/Forms/FormElementTypes"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const QuestionTypeOverview = ({ sectionIndex, questionIndex }) => {
  const isSubform = useSelector(getFormIsSubform)
  const questionType = useSelector(getFormQuestionType({ sectionIndex, questionIndex }))

  const elementType = formElementTypeOptions(isSubform).find(({ value }) => value === questionType)
  const iconName = questionTypeIcon({ questionType })

  return (
    <div className="p-6 border-b border-lightgray-3">
      <div className="pb-2">
        <SvgSpriteIcon
          iconName={iconName}
          className="p-1.5 rounded bg-white h-8 w-8"
        />
      </div>
      <div className="font-semibold">
        { elementType.label }
      </div>
      <div className="pt-2 leading-relaxed">
        { elementType.description }
      </div>
    </div>
  )
}

QuestionTypeOverview.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default QuestionTypeOverview
