import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { sectionShape } from "utils/propTypeShapes"
import SectionBranchingConditionalCheck from "components/views/Forms/FormElements/SectionBranchingConditionalCheck"
import SectionName from "./SectionName"
import SectionDescription from "./SectionDescription"
import SectionQuestions from "./SectionQuestions"

const FormSection = ({
  section,
  isFirstSection,
  isLastSection,
  formAnswers,
}) => {
  const {
    name,
    description,
    questions,
  } = section

  return (
    <SectionBranchingConditionalCheck
      isFirstSection={isFirstSection}
      formAnswers={formAnswers}
      section={section}
    >
      <div className={clsx(
        "bg-white pt-10 sm:pt-16 pb-12 px-2",
        isFirstSection ? "rounded-b-xl" : "rounded-xl",
        isLastSection ? "mb-0 rounded-b-none" : "mb-5",
      )}
      >
        <div className="public-form-content">
          <SectionName>{ name }</SectionName>
          <SectionDescription>{ description }</SectionDescription>
          <SectionQuestions questions={questions} formAnswers={formAnswers} />
        </div>
      </div>
    </SectionBranchingConditionalCheck>
  )
}

FormSection.propTypes = {
  section: sectionShape.isRequired,
  isFirstSection: types.bool.isRequired,
  isLastSection: types.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formAnswers: types.object.isRequired,
}

export default FormSection
