import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"

const DepartmentMembershipCreated = ({ audit }) => {
  const { auditedChanges } = audit
  const segmentText = `(Segment ${auditedChanges.segment.toUpperCase()})`

  return (
    <div>
      <span>
        <AuditedUser audit={audit} /> added <span className="font-semibold"> {audit.departmentMember.fullName} </span>
        as a member of department <span className="font-semibold">{audit.department.name}</span> {segmentText}
      </span>
    </div>
  )
}

DepartmentMembershipCreated.propTypes = {
  audit: auditShape.isRequired,
}

export default DepartmentMembershipCreated
