import React from "react"
import types from "prop-types"
import { useTaskFormContext } from "contexts/TaskFormContext"
import { TextInput } from "shared/inputs"
import FormsyValidation from "shared/FormsyValidation"

const TaskTitle = ({ className }) => {
  const { taskTitle, updateTaskTitle } = useTaskFormContext()

  return (
    <div className={className}>
      <span className="block mb-1 font-semibold required-prompt">Task Name</span>
      <TextInput
        className="rounded-lg bordered-base-input !text-sm"
        onChange={(value) => updateTaskTitle(value.trimStart())}
        placeholder="Task name ..."
        value={taskTitle}
      />
      <FormsyValidation
        name="task.title"
        required
        validations={{ isNotBlankString: true, noHTML: true, noExcelMetacharacters: true }}
        validationErrors={{ isDefaultRequiredValue: "required" }}
        value={taskTitle || ""}
      />
    </div>
  )
}

TaskTitle.defaultProps = {
  className: "",
}

TaskTitle.propTypes = {
  className: types.string,
}

export default TaskTitle
