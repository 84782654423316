import React from "react"
import types from "prop-types"
import FormsyValidation from "shared/FormsyValidation"

const OptionsLengthValidation = ({ value = [], sectionIndex, questionIndex }) => (
  <FormsyValidation
    name={`sections[${sectionIndex}].questions[${questionIndex}].optionsLength`}
    value={value}
    validations={{
      requiresOneOption(_model, val) {
        if (val?.length > 0) return true
        return "Must have at least one option"
      },
    }}
  />
)

OptionsLengthValidation.propTypes = {
  value: types.arrayOf(types.oneOfType([types.string, types.number])),
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default OptionsLengthValidation
