import { useEffect } from "react"
import { store } from "store"
import { setFeatureAnnouncement } from "reduxSlices/featureAnnouncementSlice"
import { featureAnnouncementShape } from "utils/propTypeShapes"

const SetupFeatureAnnouncementInRedux = ({ featureAnnouncement = {} }) => {
  useEffect(() => {
    store.dispatch(setFeatureAnnouncement(featureAnnouncement))
  }, [featureAnnouncement])

  return null
}

SetupFeatureAnnouncementInRedux.propTypes = {
  featureAnnouncement: featureAnnouncementShape,
}

export default SetupFeatureAnnouncementInRedux
