import React from "react"
import types from "prop-types"
import { ChevronDown, ChevronUp } from "shared/icons"

const ToggleGroupAccordion = ({ group, isExpanded, toggleAccordion }) => {
  if (group?.members?.length === 0) return null

  const Chevron = isExpanded ? ChevronDown : ChevronUp
  return (
    <div className="ml-6 rounded bg-light-100 p-1">
      <Chevron
        className="h-4 w-4 cursor-pointer"
        onClick={toggleAccordion}
      />
    </div>
  )
}

ToggleGroupAccordion.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  group: types.object.isRequired,
  toggleAccordion: types.func.isRequired,
  isExpanded: types.bool.isRequired,
}

export default ToggleGroupAccordion
