import React from "react"
import types from "prop-types"
import { Select } from "shared/selects"
import { useSelector } from "react-redux"
import { workflowConditionShape } from "utils/propTypeShapes"
import { getCategoryStatuses } from "reduxSlices/formBuilderSlice"
import FormsyValidation from "shared/FormsyValidation"

const DefineStatusValue = ({ condition, conditionIndex, changeConditionValue }) => {
  const statuses = useSelector(getCategoryStatuses)
  const statusListOptions = statuses.map((status) => ({
    value: status.id,
    label: status.name,
  }))
  const updateStatus = (statusId) => changeConditionValue({ statusId })
  return (
    <span className="relative">
      <Select
        options={statusListOptions}
        value={condition.value?.statusId}
        placeholder="Select status"
        style={{ height: "32px" }}
        onChange={updateStatus}
      />
      <FormsyValidation
        name={`conditions[${conditionIndex}].value.statusId`}
        value={condition?.value?.statusId}
        validations={{ isNotBlankString: true }}
        validationErrors={{ isDefaultRequiredValue: "required" }}
      />
    </span>
  )
}

DefineStatusValue.propTypes = {
  condition: workflowConditionShape.isRequired,
  changeConditionValue: types.func.isRequired,
  conditionIndex: types.number.isRequired,
}

export default DefineStatusValue
