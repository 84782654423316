import React from "react"
import types from "prop-types"
import { CircularIconButton } from "shared/buttons"
import ItemRow from "shared/ItemRow"

const GroupSpan = ({
  groupMembership, memberId, removeMembership,
}) => {
  if (!groupMembership) return null

  const { id, group = {} } = groupMembership
  const { name: groupName, id: groupId } = group

  const handleClick = () => {
    removeMembership(id, groupId, memberId)
  }

  return (
    <ItemRow
      key={id}
      className="dark"
      itemRowHeaderContent={(
        <span>{groupName}</span>
      )}
      itemRowHeaderControls={(
        <CircularIconButton icon="close" onClick={handleClick} />
      )}
    />
  )
}

GroupSpan.propTypes = {
  groupMembership: types.object.isRequired,
  memberId: types.number.isRequired,
  removeMembership: types.func.isRequired,
}

export default GroupSpan
