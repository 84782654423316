/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react"
import { BroadcastChannel } from "broadcast-channel"
import { useSelector } from "react-redux"
import { currentUserFullName, currentUserRole } from "reduxSlices/sessionSlice"
import { IMPERSONATION, END_IMPERSONATION } from "utils/impersonation"
import { snakeToCapitalCase } from "utils/stringHelpers"
import * as API from "services/api"
import { errorToast, successToast } from "shared/toast"

const impersonationsChannel = new BroadcastChannel(IMPERSONATION)

const endImpersonation = async () => {
  const response = await API.endImpersonation()

  if (response.ok) {
    impersonationsChannel.postMessage(END_IMPERSONATION)
    successToast("Returning you to your normally scheduled program.")
  } else {
    console.error({ message: "Error ending impersonation", response });
    errorToast("Something went wrong. Unable to impersonate. Try logging out.", response)
  }
}

const ImpersonationBanner = () => {
  const impersonateeFullName = useSelector(currentUserFullName)
  const impersonateeRole = useSelector(currentUserRole)

  const buttonClasses = "text-sm cursor-pointer bg-white text-impersonation min-w-fit font-semibold"
                      + " hover:bg-gray-100 hover:text-black py-1 px-4 mr-2 rounded-full";

  return (
    <div className="banner flex justify-between items-center z-50 py-2 px-2">
      <div className="text-sm px-4">
        Viewing <span className="hidden lg:inline">the app</span> as <span className="font-semibold">{impersonateeFullName} ({snakeToCapitalCase(impersonateeRole)})</span>
      </div>
      <div className={buttonClasses} onClick={endImpersonation}>
        End <span className="hidden lg:inline">Impersonation</span>
      </div>
    </div>
  )
}

export default ImpersonationBanner
