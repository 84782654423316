import React, { useEffect } from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getGroups } from "reduxSlices/formBuilderSlice"
import { useTaskFormContext } from "contexts/TaskFormContext"
/* eslint-disable max-len */
import TaskGroupSelector from "views/FormSubmission/FormSubmissionTabs/ActivitiesContent/TaskForms/TaskFields/UI/TaskGroupSelector"
/* eslint-enable max-len */

const TaskMemberGroupSelector = ({ className = "" }) => {
  const groups = useSelector(getGroups)

  const { addMemberGroupId, taskAssigneeGroupIds, taskMemberGroupIds } = useTaskFormContext()

  const selectableGroups = groups.reduce((groupList, group) => {
    if (!taskMemberGroupIds.includes(group.id)) {
      groupList.push(
        {
          ...group,
          ...(taskAssigneeGroupIds.includes(group.id) && { marker: "Assignee" }),
        },
      )
    }
    return groupList
  }, [])

  useEffect(() => {
    window.dispatchEvent(new window.Event("resize"))
  }, [taskMemberGroupIds.length])

  return (
    <TaskGroupSelector className={className} groups={selectableGroups} onChange={addMemberGroupId} />
  )
}

TaskMemberGroupSelector.propTypes = {
  className: types.string,
}

export default TaskMemberGroupSelector
