import React from "react"
import { useHistoricalAccessToolContext } from "contexts/HistoricalAccessToolContext"
import MassSelection from "shared/MassSelection"
import HistoricalAccessToolFormForSubmitting from "./HistoricalAccessToolFormForSubmitting"

const HistoricalAccessToolFormsForSubmitting = () => {
  const { deselectAllFormsForSubmitting, formsForSubmitting, selectAllFormsForSubmitting } = useHistoricalAccessToolContext()

  return (
    <>
      <MassSelection deselectAll={deselectAllFormsForSubmitting} selectAll={selectAllFormsForSubmitting} className="mb-5" />
      {
        formsForSubmitting.map(({ id, title }) => (
          <HistoricalAccessToolFormForSubmitting
            key={id}
            className="mb-4 last:mb-0"
            id={id}
            title={title}
          />
        ))
      }
    </>
  )
}

export default HistoricalAccessToolFormsForSubmitting
