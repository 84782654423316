import React from "react"
import { FormSubmissionReportExportContextProvider } from "contexts/FormSubmissionReportExportContext"
import { reportExportFormSubmissionReportShape } from "utils/propTypeShapes"
import NewFormSubmissionReportExportForm from "./NewFormSubmissionReportExportForm"
import NewFormSubmissionReportExportProcessingCheck from "./NewFormSubmissionReportExportProcessingCheck"

const NewFormSubmissionReportExport = ({ formSubmissionReport }) => (
  <FormSubmissionReportExportContextProvider formSubmissionReport={formSubmissionReport}>
    <NewFormSubmissionReportExportForm />
    <NewFormSubmissionReportExportProcessingCheck />
  </FormSubmissionReportExportContextProvider>
)

NewFormSubmissionReportExport.propTypes = {
  formSubmissionReport: reportExportFormSubmissionReportShape.isRequired,
}

export default NewFormSubmissionReportExport
