import React from "react"
import types from "prop-types"
import * as API from "services/api"
import { useInterval } from "hooks"
import ActivityIndicator from "shared/ActivityIndicator"
import { errorToast } from "shared/toast"

const IMAGE_CAPTURE_ERROR_MESSAGE = "Something went wrong.  Unable to get export status."

const ReportExportProcessingCheck = ({ imageCaptureId, onError, setImageCapture }) => {
  useInterval(async () => {
    const response = await API.metabaseDashboardImageCapture({ imageCaptureId })

    if (response.ok) {
      setImageCapture(response.data)
    } else {
      errorToast(IMAGE_CAPTURE_ERROR_MESSAGE)
      onError()
    }
  }, 2000)

  return (
    <div className="flex flex-col items-center">
      <ActivityIndicator className="mb-8" />
      <p className="text-center">
        Your export is being processed.<br />
        Download will initiate when your export is ready.
      </p>
    </div>
  )
}

ReportExportProcessingCheck.propTypes = {
  imageCaptureId: types.number.isRequired,
  onError: types.func.isRequired,
  setImageCapture: types.func.isRequired,
}

export default React.memo(ReportExportProcessingCheck)
