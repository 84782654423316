/* eslint-disable jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content */
import React from "react"
import types from "prop-types"
import { getDataRenderComponent } from "./tableDataType"

const TableDataElement = ({
  column,
  rowHrefFunc = undefined,
  rowData,
  entryClassHelper = undefined,
}) => {
  const DataElement = getDataRenderComponent(column)
  const classNameFunc = entryClassHelper?.get(column.value) || (() => "");

  return (
    <td className={column.dataType}>
      {
        rowHrefFunc && (
          <a className="row-link" href={rowHrefFunc(rowData)} />
        )
      }
      <DataElement value={rowData[column.value]} rowData={rowData} rowHrefFunc={rowHrefFunc} className={classNameFunc(rowData)} />
    </td>
  )
}

TableDataElement.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  column: types.object.isRequired,
  rowHrefFunc: types.func,
  // eslint-disable-next-line react/forbid-prop-types
  rowData: types.object.isRequired,
  entryClassHelper: types.object,
}

export default TableDataElement
