import React from "react"
import { isEqual } from "lodash-es"
import { useSelector } from "react-redux"
import { getFormAdditionalSectionUuids } from "reduxSlices/formBuilderSlice"
import Section from "./Section"

// The first section of the form is rendered with the MainSection component.
// If the form has multiple sections, this component will render the other sections.
const AdditionalSections = () => {
  const additionalSectionUuids = useSelector(getFormAdditionalSectionUuids, isEqual)

  return (
    <>
      {
        additionalSectionUuids.map((sectionUuid, index) => (
          <Section key={sectionUuid} sectionIndex={index + 1} />
        ))
      }
    </>
  )
}

export default AdditionalSections
