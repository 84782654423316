import React from "react"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import CreateUserButtons from "./CreateUserButtons"

const EmptyGroups = () => (
  <div className="flex flex-col items-center pt-4">
    <SvgSpriteIcon iconName="empty-users" className="h-10 w-10 mb-4" />
    <div className="font-medium text-sm">
      You do not have any users yet
    </div>
    <CreateUserButtons />
  </div>
)

export default EmptyGroups
