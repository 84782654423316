import React from "react"
import types from "prop-types"
import { withFormsy } from "formsy-react"
import NumericInput from "react-numeric-input"
import clsx from "clsx"
import InputErrorMessage from "shared/InputErrorMessage"

// https://github.com/vlad-ignatov/react-numeric-input

const NumberInput = ({
  hasBaseInputStyle = true,
  errorMessages = [],
  isPristine = true,
  className = "",
  disabled = false,
  max = Number.MAX_SAFE_INTEGER,
  min = Number.MIN_SAFE_INTEGER,
  name = "",
  onChange,
  onFocus = () => {},
  onBlur = () => {},
  placeholder = "",
  precision = 0,
  readOnly = false,
  required = false,
  snap = false,
  step = 1,
  uuid = undefined,
  value = undefined,
}) => (
  <>
    <NumericInput
      className={clsx(className, hasBaseInputStyle && "base-input", "input-numeric")}
      disabled={disabled}
      inputMode="decimal"
      max={max}
      min={min}
      name={name || uuid}
      onChange={(val) => onChange(val)}
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder={placeholder}
      precision={precision}
      readOnly={readOnly}
      required={required}
      snap={snap}
      step={step}
      // eslint-disable-next-line react/style-prop-object
      style={false}
      value={value}
    />
    <InputErrorMessage isPristine={isPristine} errorMessages={errorMessages} />
  </>
)

NumberInput.propTypes = {
  hasBaseInputStyle: types.bool,
  errorMessages: types.arrayOf(types.string),
  isPristine: types.bool,
  className: types.string,
  disabled: types.bool,
  max: types.number,
  min: types.number,
  name: types.string,
  onChange: types.func.isRequired,
  onFocus: types.func,
  onBlur: types.func,
  placeholder: types.string,
  precision: types.number,
  readOnly: types.bool,
  required: types.bool,
  snap: types.bool,
  step: types.number,
  uuid: types.string,
  value: types.number,
}

export const FormsyNumberInput = withFormsy(({ setValue, ...rest }) => (
  <NumberInput onChange={setValue} {...rest} />
))

FormsyNumberInput.displayName = "FormsyNumberInput"

export default NumberInput
