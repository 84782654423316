import React from "react"
import types from "prop-types"

const DataCollectorContainerHeader = ({ children }) => (
  <div
    className="flex items-center justify-center py-4 relative"
    style={{ boxShadow: "inset 0px -2px 0px #E5E7F5" }}
  >
    { children }
  </div>
)

DataCollectorContainerHeader.propTypes = {
  children: types.node.isRequired,
}

export default DataCollectorContainerHeader
