import React from "react"
import types from "prop-types"
import QuestionBranchingConditionalCheck from "Forms/FormElements/QuestionBranchingConditionalCheck"
import { answerComponent } from "../answers"
import SubmissionQuestion from "../SubmissionQuestion"

const OverviewQuestion = ({ question, isNewQuestion, answers }) => {
  const {
    prompt,
    type,
    conditionals = null,
  } = question

  const AnswerComponent = answerComponent(type)

  const answer = answers[question.uuid]

  return (
    <QuestionBranchingConditionalCheck conditionals={conditionals} formAnswers={answers}>
      <SubmissionQuestion isNewQuestion={isNewQuestion} prompt={prompt}>
        <AnswerComponent question={question} rawAnswer={answer} isNewQuestion={isNewQuestion} />
      </SubmissionQuestion>
    </QuestionBranchingConditionalCheck>
  )
}

OverviewQuestion.defaultProps = {
  isNewQuestion: false,
}

OverviewQuestion.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  question: types.object.isRequired,
  isNewQuestion: types.bool,
  // eslint-disable-next-line react/forbid-prop-types
  answers: types.object.isRequired,
}

export default OverviewQuestion
