import { useContext } from "react"
import HistoricalAccessToolContext from "../HistoricalAccessToolContext"

const useHistoricalAccessToolContext = () => {
  const providedValue = useContext(HistoricalAccessToolContext)

  return { ...providedValue }
}

export default useHistoricalAccessToolContext
