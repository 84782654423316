import { createSlice } from "@reduxjs/toolkit"
import { pick, sortBy } from "lodash-es"
import { GROUP_BY_CATEGORY, getGroupingMethod } from "Forms/FormIndex/formGroupingHelper"

const SLICE_NAME = "formIndex"

const INITIAL_STATE = {
  categories: {},
  forms: [],
  selectedGrouping: GROUP_BY_CATEGORY,
  inactiveFormsVisible: true,
  usages: {},
}

const uniqueCategoryNames = (forms) => (
  Array.from(new Set(forms.map((form) => form.categoryName)))
)

const buildCategoriesData = (forms, persistedCategories) => (
  sortBy(uniqueCategoryNames(forms)).reduce((data, name) => {
    data[name] = persistedCategories[name] ?? true
    return data
  }, {})
)

const uniqueUsages = (forms) => (
  Array.from(new Set(forms.map((form) => form.usage)))
)

const buildUsagesData = (forms, persistedUsages) => (
  sortBy(uniqueUsages(forms)).reduce((data, usage) => {
    data[usage] = persistedUsages[usage] ?? true
    return data
  }, {})
)

const filterForms = ({
  categories, forms, inactiveFormsVisible, usages,
}) => (
  forms.filter(({ active: formActive, categoryName: formCategoryName, usage: formUsage }) => {
    if (!categories[formCategoryName]) return false
    if (!usages[formUsage]) return false

    return inactiveFormsVisible || formActive
  })
)

export const formIndexSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    deselectCategory: (state, action) => {
      const { categoryName } = action.payload

      state.categories[categoryName] = false
    },
    deselectUsage: (state, action) => {
      const { usage } = action.payload

      state.usages[usage] = false
    },
    initialize: (state, action) => {
      const { forms = [], persistedSettings = {} } = action.payload

      const {
        categories: persistedCategories = {},
        selectedGrouping: persistedSelectedGrouping,
        inactiveFormsVisible: persistedInactiveFormsVisible,
        usages: persistedUsages = {},
      } = persistedSettings

      // Set state based on the list of forms
      state.forms = forms
      state.selectedGrouping = persistedSelectedGrouping || INITIAL_STATE.selectedGrouping
      state.inactiveFormsVisible = persistedInactiveFormsVisible ?? INITIAL_STATE.inactiveFormsVisible
      state.categories = buildCategoriesData(state.forms, persistedCategories)
      state.usages = buildUsagesData(state.forms, persistedUsages)
    },
    selectCategory: (state, action) => {
      const { categoryName } = action.payload

      state.categories[categoryName] = true
    },
    hideInactiveForms: (state) => {
      state.inactiveFormsVisible = false
    },
    selectUsage: (state, action) => {
      const { usage } = action.payload

      state.usages[usage] = true
    },
    setSelectedGrouping: (state, action) => {
      state.selectedGrouping = action.payload
    },
    setFormActiveStatus: (state, action) => {
      const { payload } = action
      const { id, active } = payload

      const formIndex = state.forms.findIndex((form) => form.id === id)
      state.forms[formIndex].active = active
    },
    setFormBuilderPermissions: (state, action) => {
      state.formBuilderPermissions = action.payload
    },
    showInactiveForms: (state) => {
      state.inactiveFormsVisible = true
    },
  },
})

export const {
  deselectCategory,
  deselectUsage,
  hideInactiveForms,
  selectCategory,
  selectUsage,
  initialize,
  setSelectedGrouping,
  setFormActiveStatus,
  setFormBuilderPermissions,
  showInactiveForms,
} = formIndexSlice.actions

export const areInactiveFormsShown = (state) => state[SLICE_NAME].inactiveFormsVisible

export const getCategoryNames = (state) => Object.keys(state[SLICE_NAME].categories)

export const getFormGrouping = (state) => state[SLICE_NAME]

export const getFormBuilderPermissions = (state) => state[SLICE_NAME].formBuilderPermissions

export const getFormUsages = (state) => Object.keys(state[SLICE_NAME].usages)

export const getGroupedForms = (state) => {
  const {
    categories, forms, inactiveFormsVisible, selectedGrouping, usages,
  } = state[SLICE_NAME]

  const groupingMethod = getGroupingMethod(selectedGrouping)

  const filteredForms = filterForms({
    forms, categories, inactiveFormsVisible, usages,
  })

  return groupingMethod(filteredForms)
}

export const isCategorySelected = (categoryName) => (state) => state[SLICE_NAME].categories[categoryName]

export const isUsageSelected = (usage) => (state) => state[SLICE_NAME].usages[usage]

export const settingsToPersist = (state) => (
  pick(state[SLICE_NAME], ["categories", "selectedGrouping", "inactiveFormsVisible", "usages"])
)

export default formIndexSlice.reducer
