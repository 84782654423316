import React from "react"
import types from "prop-types"
import { shortMonthDate } from "utils/dateHelpers"
import { AttachmentTypeIcon } from "shared/icons"
import TruncateText from "shared/TruncateText"
import AttachmentMenu from "./AttachmentMenu"

const VIA_FORM_TYPE = "submitted_files"
const PDF_FILE_TYPE = "pdf"

const AttachmentCard = ({ attachment }) => (
  <div className="bg-primary-light border border-lightgray-2 h-60 rounded-2xl light-shadow-lg relative">
    <AttachmentMenu attachment={attachment} />
    <div className="flex justify-center h-2/3">
      {
        attachment.isRepresentable && attachment.fileType !== PDF_FILE_TYPE
          ? (<img src={attachment.path} alt="" />)
          : (
            <AttachmentTypeIcon
              className="h-16 w-16 self-center"
              fileType={attachment.fileType}
            />
          )
      }
    </div>
    <div className="bg-white p-4 h-1/3 rounded-b-2xl border-t border-lightgray-2">
      <TruncateText truncateOn={attachment.fileType} className="font-semibold">
        <span>{attachment.filename}</span>
      </TruncateText>
      <div className="text-dark text-sm font-medium">
        { shortMonthDate(attachment.createdAt) }
        { attachment.type === VIA_FORM_TYPE ? " via form" : " via Activity Hub" }
      </div>
    </div>
  </div>
)

AttachmentCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  attachment: types.object.isRequired,
}

export default AttachmentCard
