import React from "react"
import types from "prop-types"
import { SurveyConfigurationContextProvider } from "contexts/SurveyConfigurationContext"
import SurveyConfigurationButtons from "./Content/SurveyConfigurationButtons"
import SurveyConfigurationTabs from "./Content/SurveyConfigurationTabs"

const SurveyConfigurationShow = ({ surveyConfiguration }) => (
  <SurveyConfigurationContextProvider surveyConfiguration={surveyConfiguration}>
    <SurveyConfigurationButtons className="mb-8" />
    <SurveyConfigurationTabs />
  </SurveyConfigurationContextProvider>
)

SurveyConfigurationShow.propTypes = {
  surveyConfiguration: types.object.isRequired,
}

export default SurveyConfigurationShow
