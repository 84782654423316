import React from "react"
import types from "prop-types"
import DataCollectorContainerHeader from "./DataCollectorContainerHeader"

const DataCollectorContainer = ({ children, renderHeaderContent = null }) => (
  <div
    className="bg-white w-full rounded-lg border-2 border-light-300 mb-40"
    style={{ boxShadow: "0px 1px 1px #D6D8E4" }}
  >
    {
      renderHeaderContent && (
        <DataCollectorContainerHeader>
          {renderHeaderContent()}
        </DataCollectorContainerHeader>
      )
    }
    <div className="py-10 px-6">
      {children}
    </div>
  </div>
)

DataCollectorContainer.propTypes = {
  children: types.node.isRequired,
  renderHeaderContent: types.func,
}

export default DataCollectorContainer
