import React from "react"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"

const FormSubmissionCreatedAuditItemDetails = ({ audit }) => (
  <div>
    <span>
      <AuditedUser audit={audit} /> submitted the form.
    </span>
  </div>
)

FormSubmissionCreatedAuditItemDetails.propTypes = {
  audit: auditShape.isRequired,
}

export default FormSubmissionCreatedAuditItemDetails
