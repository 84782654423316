import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { isEqual } from "lodash-es"
import {
  chooseAllCurrentAndFutureForms,
  chooseSpecificForms,
  filterFormsEnabled,
  forms,
  selectedCategorySlug,
} from "reduxSlices/reportBuilderSlice"
import { RadioButton } from "shared/inputs"
import Tooltip from "shared/Tooltip"
import FormFilterSelector from "./FormFilterSelector"

const SolutionReportFormFilters = () => {
  const categorySlug = useSelector(selectedCategorySlug)
  const filterForms = useSelector(filterFormsEnabled)
  const storedForms = useSelector(forms, isEqual)
  const dispatch = useDispatch()

  const emptySolution = storedForms.length === 0

  const renderFilterOption = () => (
    <RadioButton
      checked={filterForms}
      className="mb-4 last:mb-0"
      label="Specify which forms to include submissions from"
      name="choose-specific"
      onChange={() => dispatch(chooseSpecificForms())}
      value={filterForms}
      disabled={emptySolution}
    />
  )

  if (!categorySlug) return null

  return (
    <div className="mt-5">
      <RadioButton
        checked={!filterForms}
        className="mb-4 last:mb-0"
        label="Include submissions from all current and future forms for the selected solution"
        name="choose-all-current-and-future-forms"
        onChange={() => dispatch(chooseAllCurrentAndFutureForms())}
        value={!filterForms}
      />
      { emptySolution
        ? (
          <div className="max-w-fit">
            <Tooltip text="There aren't any Forms configured for the selected Solution">
              { renderFilterOption() }
            </Tooltip>
          </div>
        )
        : renderFilterOption() }
      { filterForms && <FormFilterSelector /> }
    </div>
  )
}

export default SolutionReportFormFilters
