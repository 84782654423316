import React, { Component, createRef } from "react"
import Formsy from "formsy-react"
import types from "prop-types"
import { Button, PrimaryButton } from "shared/buttons"
import SubmissionEditSection from "./SubmissionEditSection"

class SubmissionEditForm extends Component {
  state = { canSubmit: false }

  formRef = createRef()

  enableSubmit = () => this.setState({ canSubmit: true })

  disableSubmit = () => this.setState({ canSubmit: false })

  onSubmit = async (model) => {
    const { onSubmit } = this.props
    onSubmit(model)
  }

  formAnswers = () => {
    const { answers } = this.props
    return this.formRef.current?.getModel() || answers
  }

  render() {
    const { onCancel, sections } = this.props
    const { canSubmit } = this.state

    return (
      <Formsy
        id="submission-edit-form"
        ref={this.formRef}
        autoComplete="off"
        onSubmit={this.onSubmit}
        onValid={this.enableSubmit}
        onInvalid={this.disableSubmit}
        preventDefaultSubmit
      >
        {
          sections.map((section, sectionIndex) => (
            <SubmissionEditSection
              key={section.uuid}
              section={section}
              formAnswers={this.formAnswers()}
              isFirstSection={sectionIndex === 0}
            />
          ))
        }
        <div className="flex justify-center">
          <Button
            className="border border-light mr-4"
            onClick={onCancel}
            text="Cancel"
          />
          <PrimaryButton
            disabled={!canSubmit}
            text="Save"
            type="submit"
          />
        </div>
      </Formsy>
    )
  }
}

SubmissionEditForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  answers: types.object.isRequired,
  onCancel: types.func.isRequired,
  onSubmit: types.func.isRequired,
  sections: types.arrayOf(types.object).isRequired,
}

export default SubmissionEditForm
