import React from "react"
import { isEqual } from "lodash-es"
import { useSelector } from "react-redux"
import { getCategoryNames } from "reduxSlices/formIndexSlice"
import { SidebarCollapsibleContent } from "shared/Sidebar"
import FormIndexSidebarCategorySectionCategoryCheckbox from "./FormIndexSidebarCategorySectionCategoryCheckbox"

const FormIndexSidebarCategorySection = () => {
  const categoryNames = useSelector(getCategoryNames, isEqual)

  return (
    <SidebarCollapsibleContent title="Solutions">
      {
        categoryNames.map((categoryName) => (
          <FormIndexSidebarCategorySectionCategoryCheckbox key={categoryName} categoryName={categoryName} />
        ))
      }
    </SidebarCollapsibleContent>
  )
}

export default FormIndexSidebarCategorySection
