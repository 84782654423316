import React, { useMemo } from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { users as getUsers } from "reduxSlices/formSubmissionSlice"
import { ProfileIcon } from "shared/icons"

const TaskUserProfileIconList = ({ userIds }) => {
  const users = useSelector(getUsers)

  const assignees = useMemo(() => (
    users.filter((user) => userIds.includes(user.id))
  ), [users, userIds])

  return (
    <div className="flex flex-wrap gap-1">
      {
        assignees.map((assignee) => (
          <ProfileIcon key={assignee.id} user={assignee} className="flex items-center justify-center h-6 w-6" />
        ))
      }
    </div>
  )
}

TaskUserProfileIconList.propTypes = {
  userIds: types.arrayOf(types.number).isRequired,
}

export default TaskUserProfileIconList
