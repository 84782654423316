import { createSlice } from "@reduxjs/toolkit"
import { uniqBy } from "lodash-es"
import { getGroupingMethod, groupByIsOpen } from "TaskList/taskGroupingHelper"
import {
  getDataSortingMethod, ASSIGNEES_TYPE, FORM_TYPE, LONG_TEXT_TYPE, TEXT_TYPE, DUE_DATE_TYPE, MEMBERS_TYPE,
} from "shared/Table/TableDataElement/tableDataType"

const DUE_DATE_COLUMN = {
  label: "Due Date",
  value: "dueDate",
  visible: true,
  sortable: true,
  width: "120px",
  dataType: DUE_DATE_TYPE,
}
const SLUG_COLUMN = {
  label: "Submission ID",
  value: "submissionSlug",
  visible: true,
  sortable: true,
  width: "150px",
  dataType: TEXT_TYPE,
}
const TASK_NAME_COLUMN = {
  label: "Task Name",
  value: "title",
  visible: true,
  sortable: true,
  width: "200px",
  dataType: TEXT_TYPE,
}
const SUBFORM_TITLE_COLUMN = {
  label: "Task Form",
  value: "subformTitle",
  visible: true,
  sortable: true,
  width: "200px",
  dataType: TEXT_TYPE,
}
const FORM_COLUMN = {
  label: "Form",
  value: "form",
  visible: true,
  sortable: true,
  width: "300px",
  dataType: FORM_TYPE,
}
const DESCRIPTION_COLUMN = {
  label: "Notes",
  value: "description",
  visible: true,
  sortable: true,
  width: "200px",
  dataType: LONG_TEXT_TYPE,
};

const SUBMISSION_STATUS_COLUMN = {
  label: "Submission Status",
  value: "submissionStatus",
  visible: true,
  sortable: true,
  dataType: TEXT_TYPE,
};

const RELATIONSHIP_TYPE_COLUMN = {
  value: "relationshipType",
  visible: false,
}

const CATEGORY_COLUMN = {
  value: "category",
  visible: false,
}

const ASSIGNEES_COLUMN = {
  label: "Assignees",
  value: "activeAssigneeNames",
  visible: true,
  sortable: true,
  width: "50px",
  dataType: ASSIGNEES_TYPE,
}

const MEMBERS_COLUMN = {
  label: "Members",
  value: "activeMemberNames",
  visible: true,
  sortable: true,
  width: "50px",
  dataType: MEMBERS_TYPE,
}

const INITIAL_STATE = {
  tasks: [],
  columns: [
    DUE_DATE_COLUMN,
    SLUG_COLUMN,
    FORM_COLUMN,
    SUBFORM_TITLE_COLUMN,
    TASK_NAME_COLUMN,
    DESCRIPTION_COLUMN,
    ASSIGNEES_COLUMN,
    MEMBERS_COLUMN,
    SUBMISSION_STATUS_COLUMN,
  ],
  groupByColumns: [RELATIONSHIP_TYPE_COLUMN, CATEGORY_COLUMN],
  currentSort: {
    column: SLUG_COLUMN,
    isAsc: true,
  },
  selectedGroupingColumn: RELATIONSHIP_TYPE_COLUMN,
  showOnlyClosedTasks: false,
  formIdsToFilterBy: [],
  forms: [],
  taskCategoryIdsToShow: [],
};

export const taskTableSlice = createSlice({
  name: "taskTable",
  initialState: INITIAL_STATE,
  reducers: {
    reorderColumns: (state, action) => {
      const { startIndex, endIndex } = action.payload
      const result = [...state.columns];
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      state.columns = result;
    },
    setColumnHidden: (state, action) => {
      const columnName = action.payload
      const column = state.columns.find(({ value }) => value === columnName)
      column.visible = false
    },
    setColumnVisible: (state, action) => {
      const columnName = action.payload
      const column = state.columns.find(({ value }) => value === columnName)
      column.visible = true
    },
    setCurrentSort: (state, action) => {
      const selectedColumn = action.payload
      if (state.currentSort.column.value === selectedColumn.value) {
        state.currentSort.isAsc = !state.currentSort.isAsc
      } else {
        state.currentSort = {
          column: selectedColumn,
          isAsc: true,
        }
      }
    },
    setTasks: (state, action) => {
      state.tasks = action.payload
      const taskForms = state.tasks.map(({ form }) => ({ ...form }))
      state.forms = uniqBy(
        taskForms,
        ({ id }) => id,
      )
      state.formIdsToFilterBy = state.forms.map(({ id }) => id)
      state.taskCategoryIdsToShow = state.tasks.map(({ category }) => category.id);
    },
    setFormIdsToFilterBy: (state, action) => {
      state.formIdsToFilterBy = action.payload
    },
    setSelectedGrouping: (state, action) => {
      const column = state.groupByColumns.find(({ value }) => value === action.payload)
      state.selectedGroupingColumn = column
    },
    setShowClosedTasks: (state, action) => {
      state.showOnlyClosedTasks = action.payload
    },
    hideTaskCategory: (state, action) => {
      state.taskCategoryIdsToShow = state.taskCategoryIdsToShow.filter((id) => id !== action.payload)
    },
    showTaskCategory: (state, action) => {
      state.taskCategoryIdsToShow.push(action.payload);
    },
  },
})

export const {
  reorderColumns,
  setColumnHidden,
  setColumnVisible,
  setCurrentSort,
  setFormIdsToFilterBy,
  setTasks,
  setSelectedGrouping,
  setShowClosedTasks,
  showTaskCategory,
  hideTaskCategory,
} = taskTableSlice.actions;

export const getTaskTable = (state) => state.taskTable
export const getVisibleColumns = (state) => state.taskTable.columns.filter(({ visible }) => visible)
export const getGroupedTasks = ({ taskTable }) => {
  const {
    tasks,
    currentSort,
    formIdsToFilterBy,
    selectedGroupingColumn,
    taskCategoryIdsToShow,
    showOnlyClosedTasks,
  } = taskTable;

  let filteredTasks = tasks.filter(({ form, category }) => (
    formIdsToFilterBy.includes(form.id) && (taskCategoryIdsToShow.includes(category.id))
  ))

  let groupingMethod

  if (showOnlyClosedTasks) {
    filteredTasks = filteredTasks.filter(({ isOpen }) => isOpen === false)
    groupingMethod = () => groupByIsOpen(filteredTasks);
  } else {
    filteredTasks = filteredTasks.filter(({ isOpen }) => (isOpen))
    groupingMethod = getGroupingMethod(selectedGroupingColumn);
  }

  const sortingMethod = getDataSortingMethod(currentSort.column)
  const sortedTasks = sortingMethod(filteredTasks, currentSort)

  return groupingMethod(sortedTasks, selectedGroupingColumn)
}

export const getCurrentGrouping = (state) => state.taskTable.selectedGroupingColumn

export const isTaskCategoryVisible = (id) => (state) => state.taskTable.taskCategoryIdsToShow.includes(id)

export default taskTableSlice.reducer
