const timeToMinutes = (timeString) => {
  const hoursAndMinutes = timeString.split(":").map((string) => parseInt(string, 10))
  return hoursAndMinutes[0] * 60 + hoursAndMinutes[1]
}

const numberToTimeInterval = (number) => [
  Math.floor(number / 60).toString().padStart(2, "0"),
  (number % 60).toString().padStart(2, "0"),
].join(":")

export const getTimeDifference = (startTime, endTime) => {
  const startTotalMinutes = timeToMinutes(startTime)
  const endTotalMinutes = timeToMinutes(endTime)

  let result

  if (startTotalMinutes <= endTotalMinutes) {
    const difference = endTotalMinutes - startTotalMinutes
    result = numberToTimeInterval(difference)
  } else {
    const firstDayMinutes = (24 * 60) - startTotalMinutes
    const secondDayMinutes = endTotalMinutes
    const sum = firstDayMinutes + secondDayMinutes
    result = numberToTimeInterval(sum)
  }
  return result
}
