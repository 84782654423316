import React from "react"
import Formsy from "formsy-react"
import types from "prop-types"
import { categoryShape } from "utils/propTypeShapes"
import { PrimaryButton } from "shared/buttons"
import * as API from "services/api"
import { useSubmit } from "hooks"
import { successToast, errorToast } from "shared/toast"
import CategoryName from "./CategoryName"
import CategoryDescription from "./CategoryDescription"
import CategoryUsageType from "./CategoryUsageType"

const GeneralTab = ({ category, setCategory }) => {
  const { canSubmit, enableSubmit, disableSubmit } = useSubmit()

  const updateCategory = async (model) => {
    const response = await API.updateCategory({
      categorySlug: category.slug,
      category: model,
    })
    if (response.ok) {
      setCategory({ ...category, ...model })
      successToast("Updated solution successfully!")
    } else {
      console.error("Error updating category: ", response)
      errorToast("Something went wrong. Unable to update solution.", response)
    }
  }

  return (
    <Formsy
      onSubmit={updateCategory}
      onValid={enableSubmit}
      onInvalid={disableSubmit}
      preventDefaultSubmit
    >
      <div className="tab-title">General</div>
      <CategoryName className="mb-8" category={category} />
      <CategoryDescription category={category} />
      <CategoryUsageType className="mt-8" category={category} />
      <PrimaryButton
        className="absolute-horizontal-center bottom-16"
        text="Save Changes"
        type="submit"
        disabled={!canSubmit}
      />
    </Formsy>
  )
}

GeneralTab.propTypes = {
  category: categoryShape.isRequired,
  setCategory: types.func.isRequired,
}

export default GeneralTab
