import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { subscribedToAddOnFeature } from "reduxSlices/addOnFeatureSubscriptionsSlice"
import { currentUserIsInAdminRole as getCurrentUserIsInAdminRole } from "reduxSlices/sessionSlice"
import { REPORT_SFTP_FEATURE_NAME } from "utils/addOnFeatureHelpers"
import ScheduledReportExportDetails from "./ScheduledReportExportDetails"
import NewScheduledReportExportModal from "./NewScheduledReportExportModal"

const ExpandedRowContent = ({ editScheduledReportExportPath, report, newScheduledReportExportPath }) => {
  const currentUserIsInAdminRole = useSelector(getCurrentUserIsInAdminRole)
  const subscribedToSFTP = useSelector(subscribedToAddOnFeature(REPORT_SFTP_FEATURE_NAME))

  if (!currentUserIsInAdminRole || !subscribedToSFTP) return null

  const { id, scheduledReportExport } = report

  if (!scheduledReportExport) return <NewScheduledReportExportModal report={report} newScheduledReportExportPath={newScheduledReportExportPath} />

  return (
    <div className="flex-col items-start divide-y mt-2 p-2 list-row dark divide-light-300">
      <ScheduledReportExportDetails reportId={id} scheduledReportExport={scheduledReportExport} editScheduledReportExportPath={editScheduledReportExportPath} />
    </div>
  )
}

ExpandedRowContent.propTypes = {
  report: types.objectOf(types.any).isRequired,
  newScheduledReportExportPath: types.func.isRequired,
  editScheduledReportExportPath: types.func.isRequired,
}

export default ExpandedRowContent
