import React from "react"
import types from "prop-types"
import Switch from "components/shared/Switch"

const Active = ({ active = false, setWorkflow }) => (
  <div className="flex justify-between items-start">
    <div>
      <p className="font-bold mb-2">Active</p>
      {
        active
          ? <p>Workflow will be <strong>active</strong> when saved.</p>
          : <p>Workflow will be <strong>inactive</strong> when saved.</p>
      }
    </div>
    <Switch
      checked={active}
      value={active}
      onChange={(value) => setWorkflow((wf) => ({ ...wf, active: value }))}
    />
  </div>
)

Active.propTypes = {
  active: types.bool,
  setWorkflow: types.func.isRequired,
}

export default Active
