import { yearMonthDayDate } from "utils/dateHelpers"
import {
  ENCOUNTER_ACCOUNT_FIN_NUMBER_QUESTION_TAG,
  ENCOUNTER_ROOM_NUMBER_QUESTION_TAG,
  ENCOUNTER_ADMISSION_DATE_QUESTION_TAG,
  ENCOUNTER_ATTENDING_PHYSICIAN,
} from "./encounterQuestionTags"

class EncounterDataReader {
  constructor(fhirEncounterData) {
    this.fhirEncounterData = fhirEncounterData
  }

  // Cerner: fin nbr
  // Meditech: account number
  encounterFinNumberNode() {
    return this.fhirEncounterData?.identifier?.find((node) => (
      node.type?.text?.match(/(fin nbr)|(account number)/i)
    ))
  }

  encounterLocationNode() {
    return this.fhirEncounterData?.location?.[0]
  }

  // Cerner: attending physician
  // Meditech: attender
  // Epic:  attender
  encounterAttendingPhysicianNode() {
    return this.fhirEncounterData?.participant?.find((node) => (
      node.type?.some((nodeType) => (
        nodeType.text?.match(/(attending physician)|(attender)/i)
      ))
    ))
  }

  get [ENCOUNTER_ACCOUNT_FIN_NUMBER_QUESTION_TAG]() {
    return this.encounterFinNumberNode()?.value ?? ""
  }

  get [ENCOUNTER_ROOM_NUMBER_QUESTION_TAG]() {
    return this.encounterLocationNode()?.location?.display ?? ""
  }

  get [ENCOUNTER_ADMISSION_DATE_QUESTION_TAG]() {
    const timestamp = this.fhirEncounterData?.period?.start
    return timestamp ? yearMonthDayDate(timestamp) : ""
  }

  get [ENCOUNTER_ATTENDING_PHYSICIAN]() {
    return this.encounterAttendingPhysicianNode()?.individual?.display ?? ""
  }
}

export default EncounterDataReader
