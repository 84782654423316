import React from "react"
import types from "prop-types"
import FormsyValidation from "shared/FormsyValidation"

const NoDuplicateOptionsValidation = ({ value = [], sectionIndex, questionIndex }) => (
  <FormsyValidation
    name={`sections[${sectionIndex}].questions[${questionIndex}].noDuplicateOptions`}
    value={value}
    validations={{
      noDuplicateOptions(_model, val) {
        if (new Set(val).size === val.length) return true
        return "Duplicate options prohibited"
      },
    }}
  />
)

NoDuplicateOptionsValidation.propTypes = {
  value: types.arrayOf(types.string),
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default NoDuplicateOptionsValidation
