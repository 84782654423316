import React from "react"
import types from "prop-types"
import { questionShape } from "utils/propTypeShapes"
import FormInputContainer from "./AnswerFields/FormInputContainer"
import QuestionAnswerField from "./QuestionAnswerField"
import QuestionBranchingConditionalCheck from "./QuestionBranchingConditionalCheck"

const Question = ({ question, formAnswers, disabled = false }) => {
  const {
    prompt,
    description,
    required,
    showDescription,
    conditionals = null,
  } = question

  return (
    <QuestionBranchingConditionalCheck conditionals={conditionals} formAnswers={formAnswers}>
      <div className="py-5">
        <FormInputContainer
          label={prompt}
          description={description}
          required={required}
          showDescription={showDescription}
        >
          <QuestionAnswerField
            question={question}
            formAnswers={formAnswers}
            disabled={disabled}
          />
        </FormInputContainer>
      </div>
    </QuestionBranchingConditionalCheck>
  )
}

Question.propTypes = {
  question: questionShape.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formAnswers: types.object.isRequired,
  disabled: types.bool,
}

export default Question
