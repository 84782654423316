import React from "react"
import types from "prop-types"
import { SEGMENTS } from "utils/departmentSegmentHelpers"
import { ListSelect } from "shared/selects"

const DepartmentMembershipSegmentSelect = ({ onChange, value }) => (
  <ListSelect
    backspaceDelete={false}
    onChange={onChange}
    options={SEGMENTS}
    value={value}
  />
)

DepartmentMembershipSegmentSelect.propTypes = {
  onChange: types.func.isRequired,
  value: types.oneOf(SEGMENTS).isRequired,
}

export default DepartmentMembershipSegmentSelect
