import React from "react"
import types from "prop-types"
import { SwitchInput } from "shared/inputs"
import { useDispatch, useSelector } from "react-redux"
import {
  getFormIsSubform,
  getFormQuestionAttributeSetCurrentDate,
  getFormQuestionAttributePreventPastDate,
  getFormQuestionAttributePreventFutureDate,
  setQuestionAttributeSetCurrentDate,
  setQuestionAttributePreventPastDate,
  setQuestionAttributePreventFutureDate,
} from "reduxSlices/formBuilderSlice"
import QuestionShowDescription from "./QuestionShowDescription"
import QuestionRequired from "./QuestionRequired"

const DateInputConfig = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const isSubform = useSelector(getFormIsSubform)
  const questionSetCurrentDate = useSelector(getFormQuestionAttributeSetCurrentDate({ sectionIndex, questionIndex }))
  const questionPreventPastDate = useSelector(getFormQuestionAttributePreventPastDate({ sectionIndex, questionIndex }))
  const questionPreventFutureDate = useSelector(getFormQuestionAttributePreventFutureDate({ sectionIndex, questionIndex }))

  const updateSetCurrentDate = (newSetCurrentDate) => dispatch(setQuestionAttributeSetCurrentDate({
    sectionIndex, questionIndex, newSetCurrentDate,
  }))

  const updatePreventPastDate = (newPreventPastDate) => dispatch(setQuestionAttributePreventPastDate({
    sectionIndex, questionIndex, newPreventPastDate,
  }))

  const updatePreventFutureDate = (newPreventFutureDate) => dispatch(setQuestionAttributePreventFutureDate({
    sectionIndex, questionIndex, newPreventFutureDate,
  }))

  return (
    <>
      <QuestionRequired
        sectionIndex={sectionIndex}
        questionIndex={questionIndex}
      />
      <QuestionShowDescription
        sectionIndex={sectionIndex}
        questionIndex={questionIndex}
      />
      {
        !isSubform && (
          <div className="mb-4 flex-center">
            <div className="font-medium">Default to Current Date</div>
            <SwitchInput
              value={questionSetCurrentDate}
              onChange={updateSetCurrentDate}
            />
          </div>

        )
      }
      <div className="mb-4 flex-center">
        <div className="font-medium">Prevent Past Date</div>
        <SwitchInput
          value={questionPreventPastDate}
          onChange={updatePreventPastDate}
        />
      </div>
      <div className="mb-4 flex-center">
        <div className="font-medium">Prevent Future Date</div>
        <SwitchInput
          value={questionPreventFutureDate}
          onChange={updatePreventFutureDate}
        />
      </div>
    </>
  )
}

DateInputConfig.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default DateInputConfig
