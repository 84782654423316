import React from "react"
import types from "prop-types"
import clsx from "clsx"
import Popup from "reactjs-popup";
import { ensureDropdownVisible } from "utils/reactSelectHelpers"
import Dropdown from "shared/multiSelects/Dropdown"
import { CustomIconButton } from "shared/buttons"
import { Select } from "shared/selects"

const groupsToOptions = (groups) => groups.map(({ name, id, ...rest }) => (
  {
    label: name,
    value: id,
    ...rest,
  }
))

const TaskGroupSelector = ({ className, groups, onChange }) => (
  <div className={clsx("flex", className)}>
    <Popup
      trigger={
        (
          <CustomIconButton
            className="w-full md:w-auto border border-light text-sm justify-end"
            icon="add-group"
            text="Add Groups"
          />
        )
      }
      position="bottom left"
    >
      <div className="h-0 invisible">
        <div className="w-96">
          <Select
            backspaceDelete={false}
            className="react-dropdown-select base-input component-border-thin rounded-md -top-11 -left-[1px]"
            dropdownGap={0}
            dropdownRenderer={Dropdown(false, () => {}, "Add groups by name ...")}
            keepOpen
            labelField="label"
            multi={false}
            onChange={onChange}
            onDropdownOpen={ensureDropdownVisible}
            options={groupsToOptions(groups)}
            searchable
            style={{ width: "24rem", maxWidth: "calc(100vw - 4rem + 2px)" }}
          />
        </div>
      </div>
    </Popup>
  </div>
)

TaskGroupSelector.defaultProps = {
  className: "",
}

TaskGroupSelector.propTypes = {
  className: types.string,
  groups: types.arrayOf(types.shape({
    id: types.number.isRequired,
    name: types.string.isRequired,
  })).isRequired,
  onChange: types.func.isRequired,
}

export default TaskGroupSelector
