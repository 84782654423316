import React from "react"
import types from "prop-types"
import { FormsyTextInput } from "shared/inputs"

const Name = ({ department = undefined }) => (
  <>
    <label htmlFor="name">Name</label>
    <FormsyTextInput
      className="bordered-base-input text-sm"
      name="name"
      placeholder="Name"
      value={department?.name || ""}
      required
      validations={{ isNotBlankString: true }}
      validationErrors={{ isDefaultRequiredValue: "required" }}
    />
  </>
)

Name.propTypes = {
  department: types.object,
}

export default Name
