import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { capitalize } from "utils/stringHelpers"
import { PUBLIC_STATUS_BOUNCED, PUBLIC_STATUS_SUBMITTED } from "utils/surveyHelpers"
import SurveyTokenPublicStatusFormSubmissionLink from "./SurveyTokenPublicStatusFormSubmissionLink"

const classList = ({ className, bounced }) => clsx(
  "text-center rounded-md bg-white py-1",
  className,
  bounced && (
    "text-red-800 !bg-red-100"
  ),
)

const SurveyTokenPublicStatus = ({ className = "", formSubmissionSlug = undefined, publicStatus }) => {
  if (publicStatus === PUBLIC_STATUS_SUBMITTED) {
    return (
      <SurveyTokenPublicStatusFormSubmissionLink
        className={className}
        formSubmissionSlug={formSubmissionSlug}
        publicStatus={publicStatus}
      />
    )
  }

  const bounced = publicStatus === PUBLIC_STATUS_BOUNCED

  return (
    <span className={classList({ className, bounced })}>
      { capitalize(publicStatus) }
    </span>
  )
}

SurveyTokenPublicStatus.propTypes = {
  className: types.string,
  formSubmissionSlug: types.string,
  publicStatus: types.string.isRequired,
}

export default SurveyTokenPublicStatus
