import React from "react"
import types from "prop-types"
import { ACTIVITY_HUB_FORM_SUBMISSION_REPORTS_PATH } from "utils/routeHelpers"
import { PrimaryButton } from "shared/buttons"

const NewSolutionLevelReportExportFormActions = ({ exportSolutionLevelReport }) => (
  <div className="flex justify-center gap-4">
    <a className="button secondary-button" href={ACTIVITY_HUB_FORM_SUBMISSION_REPORTS_PATH}>
      Cancel
    </a>
    <PrimaryButton text="Export" onClick={exportSolutionLevelReport} />
  </div>
)

NewSolutionLevelReportExportFormActions.propTypes = {
  exportSolutionLevelReport: types.func.isRequired,
}

export default NewSolutionLevelReportExportFormActions
