import React, { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  deselectSubmissionColumn,
  selectedReportType,
  SOLUTION_REPORT_TYPE,
  SURVEY_REPORT_TYPE,
} from "reduxSlices/reportBuilderSlice"
import SubmissionDefaultColumnOptions from "./SubmissionDefaultColumnOptions"
import SubmissionQuestionColumnOptions from "./SubmissionQuestionColumnOptions"
import SurveyDefaultColumnOptions from "./SurveyDefaultColumnOptions"
import { DraggableColumnTypes } from "../DragAndDrop/DraggableColumnTypes"
import ReportableFieldColumnOptions from "./ReportableFieldColumnOptions"
import SelectableOptions from "../SelectableOptions"

const SubmissionRelatedColumnOptions = () => {
  const reportType = useSelector(selectedReportType)
  const dispatch = useDispatch()
  const ref = useRef(null)

  const deselectOption = (column) => dispatch(deselectSubmissionColumn(column))

  const answerRelatedColumnOptions = (reportType !== SOLUTION_REPORT_TYPE)
    ? <SubmissionQuestionColumnOptions />
    : <ReportableFieldColumnOptions />

  return (
    <SelectableOptions
      acceptableTypes={DraggableColumnTypes.SUBMISSION_RELATED_SELECTION}
      dropFunction={deselectOption}
      ref={ref}
    >
      <SubmissionDefaultColumnOptions />
      { (reportType === SURVEY_REPORT_TYPE) && <SurveyDefaultColumnOptions /> }
      { answerRelatedColumnOptions }
    </SelectableOptions>
  )
}

export default SubmissionRelatedColumnOptions
