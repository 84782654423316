import React, { useState } from "react"
import clsx from "clsx"
import { useHistoricalAccessToolContext } from "contexts/HistoricalAccessToolContext"
import HistoricalAccessToolLocationFilterDetailsFacilitySelect from "./HistoricalAccessToolLocationFilterDetailsFacilitySelect"
import HistoricalAccessToolLocationFilterDetailsSelectedFacilities from "./HistoricalAccessToolLocationFilterDetailsFacilities"

const HistoricalAccessToolLocationFilterDetails = () => {
  const {
    addAllFacilityDepartmentsToReportableFieldLocationAnswerFilters,
    removeAllFacilityDepartmentsFromReportableFieldLocationAnswerFilters,
  } = useHistoricalAccessToolContext()

  const [selectedFacilityIds, setSelectedFacilityIds] = useState([])

  const updateSelectedFacilities = (facilityIds) => {
    const currentSelectedFacilityIds = selectedFacilityIds

    const facilityIdsToAdd = facilityIds.filter((id) => !currentSelectedFacilityIds.includes(id))
    facilityIdsToAdd.forEach(addAllFacilityDepartmentsToReportableFieldLocationAnswerFilters)

    const facilityIdsToRemove = currentSelectedFacilityIds.filter((id) => !facilityIds.includes(id))
    facilityIdsToRemove.forEach(removeAllFacilityDepartmentsFromReportableFieldLocationAnswerFilters)

    setSelectedFacilityIds(facilityIds)
  }

  return (
    <>
      <HistoricalAccessToolLocationFilterDetailsFacilitySelect
        className={clsx(selectedFacilityIds.length > 0 && "mb-4")}
        onChange={updateSelectedFacilities}
        values={selectedFacilityIds}
      />
      {
        selectedFacilityIds.length > 0 && (
          <div>
            <span className="block mb-4">Departments:</span>
            <HistoricalAccessToolLocationFilterDetailsSelectedFacilities selectedFacilityIds={selectedFacilityIds} />
          </div>
        )
      }
    </>
  )
}

export default HistoricalAccessToolLocationFilterDetails
