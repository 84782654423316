import React from "react"
import types from "prop-types"
import { FormsyHiddenInput } from "shared/inputs"
import { snakeToCapitalCase } from "utils/stringHelpers"
import RequirementId from "./RequirementId"

const VIEW_UPLOAD_DOWNLOAD_ATTACHMENTS_KEY = "view_upload_download_submission_attachments"
const VIEW_UPLOAD_DOWNLOAD_ATTACHMENTS_LABEL = "View/Upload/Download Submission Attachments"

const formatLabel = (value) => {
  if (value === VIEW_UPLOAD_DOWNLOAD_ATTACHMENTS_KEY) {
    return VIEW_UPLOAD_DOWNLOAD_ATTACHMENTS_LABEL
  }
  return snakeToCapitalCase(value)
}

const RequirementAction = ({ id, index, value }) => (
  <td className="pl-5 font-semibold">
    {/* Needs to be in this order or it would make the border invisible */}
    <RequirementId index={index} value={id} />
    <FormsyHiddenInput
      name={`access_level_requirements_attributes[${index}].action`}
      value={value}
    />
    { formatLabel(value) }
  </td>
)

RequirementAction.propTypes = {
  id: types.number.isRequired,
  index: types.number.isRequired,
  value: types.string.isRequired,
}

export default RequirementAction
