import React from "react"
import types from "prop-types"
import { SearchSelectedGroup } from "shared/searches"
import AccessLevelIdentifier from "./AccessLevelIdentifier"

const GroupGranteeList = ({
  groups, formSlug, updateAccessGranteeLists, deleteFormGrant,
}) => {
  if (!groups.length) {
    return <span>No Group with Grants</span>
  }

  return (
    groups.map(({ grantee, formGrant, categoryGrant }) => (
      <SearchSelectedGroup
        key={grantee.id}
        className="mb-2"
        group={grantee}
        showDeselectButton={!!formGrant}
        deselect={deleteFormGrant(formGrant?.id)}
      >
        <AccessLevelIdentifier
          formSlug={formSlug}
          formGrant={formGrant}
          categoryGrant={categoryGrant}
          updateAccessGranteeLists={updateAccessGranteeLists}
          granteeType="Group"
          granteeId={grantee.id}
        />
      </SearchSelectedGroup>
    ))
  )
}

GroupGranteeList.propTypes = {
  groups: types.arrayOf(types.object).isRequired,
  formSlug: types.string.isRequired,
  updateAccessGranteeLists: types.func.isRequired,
  deleteFormGrant: types.func.isRequired,
}

export default GroupGranteeList
