import React from "react"
import { useSelector } from "react-redux"
import { getFormQuestions } from "reduxSlices/formBuilderSlice"
import { workflowActionShape } from "utils/propTypeShapes"
import { WorkflowActionAddDepartmentIcon } from "shared/icons"
import ActionHeading from "./ActionHeading"
import ActionDetails from "./ActionDetails"
import ActionLabelContainer from "./ActionLabelContainer"

const AddDepartmentActionLabel = ({ action }) => {
  const questions = useSelector(getFormQuestions)

  const question = questions.find((q) => q.uuid === action.data.questionUUID)

  return (
    <ActionLabelContainer>
      <ActionHeading
        iconComponent={WorkflowActionAddDepartmentIcon}
        text="Add department:"
      />
      <ActionDetails>
        <span>selected in <span className="workflow-label">{question.prompt}</span></span>
      </ActionDetails>
    </ActionLabelContainer>
  )
}

AddDepartmentActionLabel.propTypes = {
  action: workflowActionShape.isRequired,
}

export default AddDepartmentActionLabel
