import { createSlice } from "@reduxjs/toolkit";

const SLICE_NAME = "features"

const INITIAL_STATE = {}

export const featuresSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setFeatures: (state, action) => {
      Object.assign(state, action.payload)
    },
  },
})

export const { setFeatures } = featuresSlice.actions

export const isFeatureEnabled = (featureName) => (state) => (
  Boolean(state[SLICE_NAME][featureName])
)

export default featuresSlice.reducer
