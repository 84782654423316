import React from "react"
import types from "prop-types"
import { FormsyTextInput } from "shared/inputs"

const UserEmail = ({ user }) => (
  <>
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label>Email</label>
    <FormsyTextInput
      className="mb-3 mt-1 bordered-base-input text-sm"
      name="email"
      placeholder="Email"
      value={user.email || ""}
      required
      validations={{ isNotBlankString: true, isEmail: true }}
      validationErrors={{ isDefaultRequiredValue: "required", isEmail: "Invalid email address" }}
    />
  </>
)

UserEmail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: types.object.isRequired,
}

export default UserEmail
