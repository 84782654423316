import { useContext } from "react"
import SurveyBatchContext from "../SurveyBatchContext"

const useSurveyBatchContext = () => {
  const providedValue = useContext(SurveyBatchContext)

  return { ...providedValue }
}

export default useSurveyBatchContext
