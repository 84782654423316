// Helpers for working with access level values.  See:
// app/models/concerns/access_levelable.rb
const NONE_ACCESS = "none_access"
export const SUPER_ACCESS = "super_access"
export const STANDARD_ACCESS = "standard_access"

const ACCESS_LEVEL_ENUM_VALUES = {
  0: NONE_ACCESS,
  1: STANDARD_ACCESS,
  2: SUPER_ACCESS,
}

export const accessLevelDescription = (enumValue) => (
  ACCESS_LEVEL_ENUM_VALUES[enumValue].split("_")[0]
)
