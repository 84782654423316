/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getGroups, getUsers } from "reduxSlices/formBuilderSlice"
import { workflowActionShape } from "utils/propTypeShapes"
import { GroupAndUserSearch } from "shared/searches"
import StrikeAround from "components/shared/StrikeAround"
import { actionValidations } from "utils/workflowHelpers"
import FormsyValidation from "shared/FormsyValidation"
import SelectedGroups from "./SelectedGroups"
import SelectedUsers from "./SelectedUsers"

const EMPTY_RECIPIENTS = {
  recipients: {
    groupIds: [],
    userIds: [],
  },
}

const DefineNotifySelectedUsersData = ({ action, actionIndex, changeActionData }) => {
  const { data } = action
  const groups = useSelector(getGroups)
  const users = useSelector(getUsers)

  useEffect(() => {
    if (data.recipients) return
    changeActionData(EMPTY_RECIPIENTS)
  }, [])

  const addGroup = (groupId) => {
    const updatedData = {
      ...data,
      recipients: {
        ...(data.recipients),
        groupIds: [...(data.recipients.groupIds), Number(groupId)],
      },
    }

    changeActionData(updatedData)
  }

  const addUser = (userId) => {
    const updatedData = {
      ...data,
      recipients: {
        ...(data.recipients),
        userIds: [...(data.recipients.userIds), Number(userId)],
      },
    }

    changeActionData(updatedData)
  }

  const addRecipient = (value) => {
    const [type, id] = value.split("-")
    const addFunction = type === "Group" ? addGroup : addUser

    addFunction(id)
  }

  const removeGroup = (groupId) => {
    const newGroups = data.recipients.groupIds.filter((id) => id !== groupId)
    const updatedData = {
      ...data,
      recipients: {
        ...(data.recipients),
        groupIds: newGroups,
      },
    }

    changeActionData(updatedData)
  }

  const removeUser = (userId) => {
    const newUsers = data.recipients.userIds.filter((id) => id !== userId)
    const updatedData = {
      ...data,
      recipients: {
        ...(data.recipients),
        userIds: newUsers,
      },
    }

    changeActionData(updatedData)
  }

  const selectedGroups = groups.filter((group) => data.recipients?.groupIds.includes(group.id))
  const selectedUsers = users.filter((user) => data.recipients?.userIds.includes(user.id))
  const validations = actionValidations(action.type)

  return (
    <div className="w-full mt-4">
      <div className="relative">
        <GroupAndUserSearch
          className="h-8"
          onSearchSelected={addRecipient}
          selectedGroups={selectedGroups}
          selectedUsers={selectedUsers}
          groups={groups}
          users={users}
        />
        <FormsyValidation
          name={`actions[${actionIndex}]`}
          value={action}
          validations={{ validations }}
        />
      </div>
      <StrikeAround>
        <span className="my-3 font-medium">Groups</span>
      </StrikeAround>
      <SelectedGroups groups={selectedGroups} removeGroup={removeGroup} />
      <StrikeAround>
        <span className="my-3 font-medium">Individuals</span>
      </StrikeAround>
      <SelectedUsers users={selectedUsers} removeUser={removeUser} />
    </div>
  )
}

DefineNotifySelectedUsersData.propTypes = {
  action: workflowActionShape.isRequired,
  changeActionData: types.func.isRequired,
  actionIndex: types.number.isRequired,
}

export default DefineNotifySelectedUsersData
