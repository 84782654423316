import React from "react"
import types from "prop-types"
import { formAccessExplanation } from "./utils"

const AccessExplanation = ({ categoryGrant = null, formGrant = null }) => {
  const explanation = formAccessExplanation({ categoryGrant, formGrant })
  if (!explanation) {
    return null
  }

  return (
    <span className="mr-8 text-gray-300">{ explanation }</span>
  )
}

/* eslint-disable react/forbid-prop-types */
AccessExplanation.propTypes = {
  categoryGrant: types.object,
  formGrant: types.object,
}
/* eslint-enable react/forbid-prop-types */

export default AccessExplanation
