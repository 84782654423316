import React from "react"
import types from "prop-types"
import clsx from "clsx"

const TaskPanelSection = ({ children, className, name }) => (
  <div className={clsx(className, "flex flex-col")}>
    <span className="text-sm font-medium mb-1">{name}</span>
    {children}
  </div>
)

TaskPanelSection.defaultProps = {
  className: "",
}

TaskPanelSection.propTypes = {
  children: types.node.isRequired,
  className: types.string,
  name: types.string.isRequired,
}

export default TaskPanelSection
