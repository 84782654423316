/* eslint-disable camelcase */
import React from "react"
import { accessLevelDescription } from "utils/accessLevelHelpers"
import { auditShape } from "utils/propTypeShapes"
import { AuditedUser } from "shared/audits"

const AccessLevelGrantCreatedAuditItemDetails = ({ audit }) => {
  const { auditedChanges, grantee } = audit
  const { access_level } = auditedChanges

  return (
    <div>
      <span>
        <AuditedUser audit={audit} /> granted { accessLevelDescription(access_level) } access
        to the form submission to <span className="font-semibold">{grantee.fullName}</span>.
      </span>
    </div>
  )
}

AccessLevelGrantCreatedAuditItemDetails.propTypes = {
  audit: auditShape.isRequired,
}

export default AccessLevelGrantCreatedAuditItemDetails
