import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getFormIsSubform, getFormQuestionType, isFocusOnQuestion } from "reduxSlices/formBuilderSlice"
import { getIsQuestionFlaggable, getIsQuestionTaggable } from "components/views/Forms/FormElementTypes"
import QuestionTypeOverview from "./QuestionTypeOverview"
import QuestionAttributesSection from "./QuestionAttributesSection"
import QuestionBranchingSection from "./QuestionBranchingSection"
import ReportableFieldsSection from "./ReportableFieldsSection"
import QuestionTagsSection from "./QuestionTagsSection"
import QuestionFlagConditionSection from "./QuestionFlagConditionSection"

const QuestionSidebar = ({ sectionIndex, questionIndex }) => {
  const isSubform = useSelector(getFormIsSubform)
  const questionType = useSelector(getFormQuestionType({ sectionIndex, questionIndex }))
  const focusIsOnQuestion = useSelector(isFocusOnQuestion({ sectionIndex, questionIndex }))

  const isQuestionFlaggable = getIsQuestionFlaggable({ questionType })
  const isQuestionTaggable = getIsQuestionTaggable({ questionType })

  return (
    <>
      {
        focusIsOnQuestion && (
          <QuestionTypeOverview sectionIndex={sectionIndex} questionIndex={questionIndex} />
        )
      }
      <QuestionAttributesSection
        isFocused={focusIsOnQuestion}
        questionIndex={questionIndex}
        questionType={questionType}
        sectionIndex={sectionIndex}
      />
      {
        !isSubform && isQuestionFlaggable && (
          <QuestionFlagConditionSection
            isFocused={focusIsOnQuestion}
            sectionIndex={sectionIndex}
            questionIndex={questionIndex}
          />
        )
      }
      <QuestionBranchingSection
        isFocused={focusIsOnQuestion}
        questionIndex={questionIndex}
        sectionIndex={sectionIndex}
      />
      {
        !isSubform && (
          <ReportableFieldsSection
            isFocused={focusIsOnQuestion}
            sectionIndex={sectionIndex}
            questionIndex={questionIndex}
          />
        )
      }
      {
        !isSubform && isQuestionTaggable && (
          <QuestionTagsSection
            isFocused={focusIsOnQuestion}
            sectionIndex={sectionIndex}
            questionIndex={questionIndex}
          />
        )
      }
    </>
  )
}

QuestionSidebar.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default QuestionSidebar
