import React from "react"
import { SolutionLevelReportExportContextProvider } from "contexts/SolutionLevelReportExportContext"
import { reportExportSolutionLevelReportShape } from "utils/propTypeShapes"
import NewSolutionLevelReportExportForm from "./NewSolutionLevelReportExportForm"
import NewSolutionLevelReportExportProcessingCheck from "./NewSolutionLevelReportExportProcessingCheck"

const NewSolutionLevelReportExport = ({ solutionLevelReport }) => (
  <SolutionLevelReportExportContextProvider solutionLevelReport={solutionLevelReport}>
    <NewSolutionLevelReportExportForm />
    <NewSolutionLevelReportExportProcessingCheck />
  </SolutionLevelReportExportContextProvider>
)

NewSolutionLevelReportExport.propTypes = {
  solutionLevelReport: reportExportSolutionLevelReportShape.isRequired,
}

export default NewSolutionLevelReportExport
