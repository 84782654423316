import React, { useMemo } from "react"
import types from "prop-types"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { useDispatch, useSelector } from "react-redux"
import { getFormQuestionOptionsCount, moveFormQuestionOption } from "reduxSlices/formBuilderSlice"
import Option from "./Option"
import * as SelectOptionsValidations from "./SelectOptionsValidations"
/* eslint-disable react/no-array-index-key */

const droppableId = ({ sectionIndex, questionIndex }) => `s[${sectionIndex}]-q[${questionIndex}]-options`
const optionId = ({ sectionIndex, questionIndex, optionIndex }) => `${droppableId({ sectionIndex, questionIndex })}[${optionIndex}]`

const arrayOfIndexes = (length) => [...Array(length).keys()]

// When rendering the questions options, we render each option
// plus one additional "option" that serves as a placeholder
// for a new option to be entered.  The placeholder option is
// rendered within the map() call along with the actual options
// to keep its key so that the component remains when a value is entered.
const Options = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const optionsCount = useSelector(getFormQuestionOptionsCount({ sectionIndex, questionIndex }))

  const optionIndexes = useMemo(
    () => arrayOfIndexes(optionsCount + 1),
    [optionsCount],
  )

  const moveOption = ({ destination, source }) => {
    const { index: destinationIndex } = destination
    const { index: sourceIndex } = source

    dispatch(moveFormQuestionOption({
      sectionIndex, questionIndex, destinationIndex, sourceIndex,
    }))
  }

  return (
    <>
      <DragDropContext onDragEnd={moveOption}>
        <Droppable droppableId={droppableId({ sectionIndex, questionIndex })}>
          {
            ({ innerRef, droppableProps, placeholder }) => (
              <div ref={innerRef} {...droppableProps}>
                {
                  optionIndexes.map((optionIndex) => (
                    <Option
                      key={optionId({ sectionIndex, questionIndex, optionIndex })}
                      sectionIndex={sectionIndex}
                      questionIndex={questionIndex}
                      draggableId={optionId({ sectionIndex, questionIndex, optionIndex })}
                      optionIndex={optionIndex}
                      isNewOptionPlaceholder={optionIndex >= optionsCount}
                    />
                  ))
                }
                { placeholder }
              </div>
            )
          }
        </Droppable>
      </DragDropContext>
      <SelectOptionsValidations.OptionsLengthValidation
        value={optionIndexes.slice(0, -1)}
        sectionIndex={sectionIndex}
        questionIndex={questionIndex}
      />
    </>
  )
}
/* eslint-enable react/no-array-index-key */

Options.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default Options
