import React from "react"
import types from "prop-types"
import { withFormsy } from "formsy-react"
import Switch from "shared/Switch"

const SwitchInput = ({
  value = false, disabled = false, onChange, className = "",
}) => (
  <Switch
    checked={value}
    disabled={disabled}
    onChange={onChange}
    className={className}
  />
)

SwitchInput.propTypes = {
  disabled: types.bool,
  onChange: types.func.isRequired,
  value: types.bool,
  className: types.string,
}

export const FormsySwitchInput = withFormsy(({ setValue, ...rest }) => (
  <SwitchInput onChange={setValue} {...rest} />
))

FormsySwitchInput.displayName = "FormsySwitchInput"

export default SwitchInput
