import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const PencilIcon = ({
  className = "w-8 h-8",
  onClick = () => { },
}) => (
  <SvgSpriteIcon
    iconName="pencil"
    className={className}
    onClick={onClick}
  />
)

PencilIcon.propTypes = {
  className: types.string,
  onClick: types.func,
}

export default PencilIcon
