import React from "react"
import types from "prop-types"
import clsx from "clsx"
import SvgSpriteIcon from "shared/SvgSpriteIcon"
import IconTapTarget from "shared/IconTapTarget"

const MobileMenuButton = ({ menuIsOpen, onClick }) => {
  const icon = menuIsOpen ? "close" : "hamburger"

  return (
    <IconTapTarget
      className={clsx(
        "px-6 inline-grid items-center justify-center border-x border-lightgray-3 border-r-primary-light lg:hidden",
        menuIsOpen && "bg-white !border-r-white",
      )}
      onClick={onClick}
    >
      <SvgSpriteIcon className="fill-dark" iconName={icon} />
    </IconTapTarget>

  )
}

MobileMenuButton.propTypes = {
  menuIsOpen: types.bool.isRequired,
  onClick: types.func.isRequired,
}

export default MobileMenuButton
