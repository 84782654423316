import React from "react"
import clsx from "clsx"
import Button from "./Button"
import buttonPropTypes from "./buttonPropTypes"

const PrimaryButton = ({
  text, form, type, disabled, onClick, className, isDark = false,
}) => (
  <Button
    className={clsx("primary-button", className, isDark && "dark")}
    text={text}
    form={form}
    type={type}
    color="white"
    disabled={disabled}
    onClick={onClick}
  />
)

PrimaryButton.propTypes = buttonPropTypes

export default PrimaryButton
