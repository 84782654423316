import React from "react"
import types from "prop-types"

const FormDescription = ({ children: text }) => {
  if (!text) return null

  return (
    <div className="mt-1 text-gray text-sm md:text-base">{text}</div>
  )
}

FormDescription.defaultProps = {
  children: "",
}

FormDescription.propTypes = {
  children: types.string,
}

export default FormDescription
