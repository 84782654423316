import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { updateLocalReadStatus } from "reduxSlices/featureAnnouncementSlice"
import * as API from "services/api"
import { featureAnnouncementShape } from "utils/propTypeShapes"
import withReduxProvider from "shared/withReduxProvider"
import FeatureAnnouncement from "./FeatureAnnouncement"

const ReadReceiptWritingFeatureAnnouncement = ({ featureAnnouncement = {} }) => {
  const dispatch = useDispatch()

  // When the component mounts, mark the feature announcement as read
  useEffect(() => {
    const markRead = async () => {
      if (featureAnnouncement.read) return

      const response = await API.markFeatureAnnouncementAsRead({
        featureAnnouncementId: featureAnnouncement.id,
      })

      if (response.ok) {
        dispatch(updateLocalReadStatus())
      } else {
        console.error("Error creating read receipt: ", response)
      }
    }

    markRead()
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  return (
    <FeatureAnnouncement featureAnnouncement={featureAnnouncement} />
  )
}

ReadReceiptWritingFeatureAnnouncement.propTypes = {
  featureAnnouncement: featureAnnouncementShape,
}

export default withReduxProvider(ReadReceiptWritingFeatureAnnouncement)
