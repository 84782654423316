import React from "react"
import types from "prop-types"
import clsx from "clsx"

const RadioButtonNumber = ({
  checked,
  className = "",
  disabled = false,
  id = "",
  label = "",
  name = "",
  onChange,
  questionUuid,
  title = "",
  value,
}) => {
  // If an id is passed to the radio button, use it as the input's id.
  // If no id is passed, build one from the props.
  const inputId = id || `${questionUuid}-${name}-${value}-radio-button`

  return (
    <div
      className={clsx(className, "flex items-center cursor-pointer")}
      title={title}
      onClickCapture={() => onChange(value)}
    >
      <input
        checked={checked}
        className="mr-2 cursor-pointer"
        disabled={disabled}
        id={inputId}
        name={name}
        onChange={(event) => onChange(Number(event.target.value))}
        type="radio"
        value={value}
      />
      <label className="cursor-pointer" htmlFor={inputId}>{ label || value }</label>
    </div>
  )
}

RadioButtonNumber.propTypes = {
  checked: types.bool.isRequired,
  className: types.string,
  disabled: types.bool,
  id: types.string,
  label: types.string,
  name: types.string,
  onChange: types.func.isRequired,
  questionUuid: types.string.isRequired,
  title: types.string,
  value: types.number.isRequired,
}

export default RadioButtonNumber
