import React, { useEffect } from "react"
import types from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { currentUserId as getCurrentUserId } from "reduxSlices/sessionSlice"
import { deleteTaskAnswer, updateTaskAnswer } from "reduxSlices/formSubmissionSlice"
import consumer from "channels/consumer"
import * as API from "services/api"

const TaskAnswersSync = ({ id }) => {
  const dispatch = useDispatch()

  const currentUserId = useSelector(getCurrentUserId)

  useEffect(
    () => {
      const subscription = consumer.subscriptions.create(
        { channel: "TaskAnswersChannel", task_id: id },
        {
          connected() {
            // Called when the subscription is ready for use on the server
          },

          disconnected() {
            // Called when the subscription has been terminated by the server
          },

          received: async (data) => {
            const { answer = {}, multiEntry, questionUuid } = data
            const { user: { id: answerAuthorId } = {} } = answer

            const updatedAnswerPayload = { answer, id, questionUuid }

            if (multiEntry) {
              const response = await API.taskAnswer({ questionUuid, taskId: id })
              const responseAnswer = response.data?.answer

              if (!responseAnswer) {
                dispatch(deleteTaskAnswer({ id, questionUuid }))
                return
              }

              updatedAnswerPayload.answer = response.data.answer
            }

            // Update task answer in redux if (1) the question is a multi-entry question
            // or (2) the answer is being pushed to the user (i.e., the current user did not update the answer)
            // Updating redux for the user answering the question was causing issues
            // where edits were being overwritten by out-of-date state updates.
            //
            // For the user answering the question, redux is updated as part of saving the update.
            // See app/javascript/components/views/FormSubmission/FormSubmissionTabs/ActivitiesContent/ActivitiesMain/Task/TaskQuestion.jsx
            if (multiEntry || currentUserId !== answerAuthorId) {
              dispatch(updateTaskAnswer(updatedAnswerPayload))
            }
          },
        },
      )

      return () => {
        subscription.unsubscribe()

        if (consumer.subscriptions.subscriptions.length === 0) {
          consumer.disconnect()
        }
      }
    },
  )

  return null
}

TaskAnswersSync.propTypes = {
  id: types.number.isRequired,
}

export default React.memo(TaskAnswersSync)
