import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const RotateIcon = ({ className = "w-8 h-8", ...rest }) => <SvgSpriteIcon iconName="rotate" className={className} {...rest} />

RotateIcon.propTypes = {
  className: types.string,
}

export default RotateIcon
