import React, { useState } from "react"
import { isEmpty } from "lodash-es"
import { useModal } from "hooks"
import * as API from "services/api"
import { useHistoricalAccessToolContext } from "contexts/HistoricalAccessToolContext"
import { PrimaryButton, TertiaryButton } from "shared/buttons"
import Modal, { DEFAULT_MODAL_STYLE, ModalHeader } from "shared/Modal"
import { errorToast, successToast } from "shared/toast"
import HistoricalAccessToolPreviewList from "shared/HistoricalAccessTool/HistoricalAccessToolPreviewList"

const HistoricalAccessToolRemoveAccessPreview = () => {
  const { modalIsOpen, openModal, closeModal } = useModal()
  const [formSubmissionCounts, setFormSubmissionCounts] = useState([])

  const {
    allRequiredRemoveAccessInstructionsDataEntered,
    earliestSubmissionDate,
    latestSubmissionDate,
    reportableFieldLocationAnswerFilters,
    resetHistoricalAccessToolData,
    selectedFormsForSubmittingIds,
    userId,
  } = useHistoricalAccessToolContext()

  const loadFormSubmissionCounts = async () => {
    const response = await API.historicalAccessToolRemoveAccessPreviews({
      earliestSubmissionDate,
      formsForSubmittingIds: selectedFormsForSubmittingIds,
      latestSubmissionDate,
      reportableFieldLocationAnswerFilters,
      userId,
    })

    if (response.ok) {
      setFormSubmissionCounts(response.data.formSubmissionCounts)
    } else {
      errorToast("Something went wrong.  Unable to generate preview.")
    }
  }

  const removeAccess = async () => {
    const response = await API.historicalAccessToolRemoveAccess({
      earliestSubmissionDate,
      formsForSubmittingIds: selectedFormsForSubmittingIds,
      latestSubmissionDate,
      reportableFieldLocationAnswerFilters,
      userId,
    })

    if (response.ok) {
      successToast("Remove access request received.  We'll email you with results once your request has been processed.")
      resetHistoricalAccessToolData()
      closeModal()
      setFormSubmissionCounts([])
    } else {
      errorToast("Something went wrong.  Unable to remove access.")
    }
  }

  const generatePreview = () => {
    openModal()
    loadFormSubmissionCounts()
  }

  const cancel = () => {
    closeModal()
    setFormSubmissionCounts([])
  }

  return (
    <>
      <div className="flex justify-center">
        <PrimaryButton text="Preview" disabled={!allRequiredRemoveAccessInstructionsDataEntered} onClick={generatePreview} />
      </div>
      <Modal isOpen={modalIsOpen} style={DEFAULT_MODAL_STYLE}>
        <ModalHeader
          closeModal={cancel}
          heading="Remove Access Preview"
        />
        {
          !isEmpty(formSubmissionCounts) && (
            <HistoricalAccessToolPreviewList formSubmissionCounts={formSubmissionCounts} className="mb-4" />
          )
        }
        <p className="text-sm text-gray mb-8 mx-auto">
          If the user is setup to be granted submission access based on certain forms, workflows, groups, etc.,
          they may still gain access to future submissions both during and after this access removal request is processed.
        </p>
        <div className="flex justify-center gap-4">
          <TertiaryButton text="Cancel" onClick={cancel} />
          <PrimaryButton text="Confirm" onClick={removeAccess} />
        </div>
      </Modal>
    </>
  )
}

export default HistoricalAccessToolRemoveAccessPreview
