import React from "react"
import { snakeToCapitalCase } from "utils/stringHelpers"
import { useDispatch, useSelector } from "react-redux"
import { getTaskReminders, setTaskReminders } from "reduxSlices/currentSettingsSlice"
import * as API from "services/api"
import ContentBar from "shared/lists/ContentBar"
import { ListSelect } from "shared/selects"
import { STYLE, FREQUENCIES } from "utils/generalSettingsHelpers"

const optionsFromList = () => FREQUENCIES.map((opt) => ({ label: snakeToCapitalCase(opt), value: opt }))

const TaskRemindersSetting = () => {
  const dispatch = useDispatch()
  const reminders = useSelector(getTaskReminders)

  const updateReminders = async (newSetting) => {
    const response = await API.updateTaskReminders({ task_reminders: newSetting })

    if (response.ok) {
      dispatch(setTaskReminders(newSetting))
    } else {
      console.error("Error updating task reminders: ", response)
    }
  }

  return (
    <ContentBar title="Task Reminders">
      <div>
        <ListSelect
          style={STYLE}
          options={FREQUENCIES}
          optionsFromList={optionsFromList}
          value={reminders}
          onChange={updateReminders}
        />
      </div>
    </ContentBar>
  )
}

export default TaskRemindersSetting
