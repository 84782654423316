import React from "react"
import types from "prop-types"
import { workflowActionShape } from "utils/propTypeShapes"
import ActionLabel from "./ActionLabel"

const ActionLabels = ({ actions }) => (
  <>
    {
      actions.map((action, index) => (
        <ActionLabel
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          action={action}
          className="mb-2"
        />
      ))
    }
  </>
)

ActionLabels.propTypes = {
  actions: types.arrayOf(workflowActionShape).isRequired,
}

export default ActionLabels
