import React from "react"
import types from "prop-types"
import * as TimeAttributes from "./TimeAttributes"
import QuestionShowDescription from "./QuestionShowDescription"
import QuestionRequired from "./QuestionRequired"

const TimeInputConfig = ({ sectionIndex, questionIndex }) => (
  <>
    <QuestionRequired
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <QuestionShowDescription
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
    <TimeAttributes.SetCurrentTime
      sectionIndex={sectionIndex}
      questionIndex={questionIndex}
    />
  </>
)

TimeInputConfig.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default TimeInputConfig
