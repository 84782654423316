import React from "react"
import types from "prop-types"

const ToastValidations = ({ message, errors = [] }) => {
  if (errors.length) {
    const listErrors = errors.map((error) => <li key={error}>{error}</li>)
    return (
      <div id="toast-validation-errors">
        <ol className="text-sm">{listErrors}</ol>
      </div>
    )
  }
  return (
    <div>
      <p className="text-sm">{message}</p>
    </div>
  )
}

ToastValidations.propTypes = {
  message: types.string.isRequired,
  errors: types.arrayOf(types.string),
}

export default ToastValidations
