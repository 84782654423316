import React, { useEffect } from "react"
import types from "prop-types"
import { store } from "store"
import * as API from "services/api"
import { SideTabs } from "shared/tabs"
import { setGroups, setUsers } from "reduxSlices/categorySlice"
import GeneralTab from "./GeneralTab"
import ActionsTab from "./ActionsTab"
import AccessTab from "./AccessTab"
import SubmissionStatusesTab from "./SubmissionStatusesTab"
import FormBuildersTab from "./FormBuildersTab"
import FormsOrderTab from "./FormsOrderTab"
import ReportableFieldsTab from "./ReportableFieldsTab"

const TABS = (tabProps) => [{
  title: "General",
  TabContent: GeneralTab,
  props: tabProps,
}, {
  title: "Actions",
  TabContent: ActionsTab,
  props: tabProps,
}, {
  title: "Access",
  TabContent: AccessTab,
  props: tabProps,
}, {
  title: "Submission Statuses",
  TabContent: SubmissionStatusesTab,
  props: tabProps,
}, {
  title: "Form Builders",
  TabContent: FormBuildersTab,
  props: tabProps,
},
{
  title: "Forms Order",
  TabContent: FormsOrderTab,
  props: tabProps,
},
{
  title: "Reporting Fields",
  TabContent: ReportableFieldsTab,
  props: tabProps,
}]

const CategoryTabs = ({ tabProps }) => {
  useEffect(() => {
    (async function getUsers() {
      const response = await API.getUsers({ active: true })

      if (response.ok) {
        store.dispatch(setUsers(response.data))
      } else {
        console.error("Error fetching users: ", response)
      }
    }())
  }, [])

  useEffect(() => {
    (async function getGroups() {
      const response = await API.getGroups()

      if (response.ok) {
        store.dispatch(setGroups(response.data))
      } else {
        console.error("Error fetching groups: ", response)
      }
    }())
  }, [])

  return (
    <SideTabs tabs={TABS(tabProps)} />
  )
}

CategoryTabs.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tabProps: types.object.isRequired,
}

export default CategoryTabs
