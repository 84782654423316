import React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getFormListed, getFormSlug, getFormUsage, setFormListed,
} from "reduxSlices/formBuilderSlice"
import * as API from "services/api"
import Switch from "shared/Switch"
import ContentBar from "shared/lists/ContentBar"
import { USAGES_TO_EXCLUDE_FORM_LISTED_SETTING } from "utils/formHelpers"

const TITLE = "Show Form as Listed"
const DESCRIPTION = "When listed, the form will be visible on the solution's landing page."

const FormListedSetting = () => {
  const dispatch = useDispatch()
  const formListed = useSelector(getFormListed)
  const formSlug = useSelector(getFormSlug)
  const formUsage = useSelector(getFormUsage)

  if (USAGES_TO_EXCLUDE_FORM_LISTED_SETTING.includes(formUsage)) return null

  const updateListed = async (listed) => {
    const response = await API.updateFormListed({ formSlug, listed })
    if (response.ok) {
      dispatch(setFormListed(listed))
    } else {
      console.error("Error updating form listed: ", response.errors)
    }
  }

  return (
    <ContentBar
      title={TITLE}
      description={DESCRIPTION}
    >
      <Switch
        checked={formListed}
        onChange={updateListed}
      />
    </ContentBar>
  )
}

export default FormListedSetting
