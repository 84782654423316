import React from "react"
import { questionShape } from "utils/propTypeShapes"
import RestoreFormVersionDeletedQuestion from "./RestoreFormVersionDeletedQuestion"
import FormVersionDeletedQuestionDetails from "./FormVersionDeletedQuestionDetails"

const FormVersionDeletedQuestion = ({ question }) => (
  <div className="deleted-question-container">
    <FormVersionDeletedQuestionDetails question={question} />
    <RestoreFormVersionDeletedQuestion question={question} />
  </div>
)

FormVersionDeletedQuestion.propTypes = {
  question: questionShape.isRequired,
}

export default FormVersionDeletedQuestion
