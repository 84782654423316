import React from "react"
import types from "prop-types"
import { dateOrdinalOptions, todayDayOfMonth } from "utils/dateHelpers"
import { FormsySelect } from "shared/selects"

const MonthlyRecurrenceDetails = ({ recurrenceDate = undefined }) => (
  <div className="flex items-center mr-2">
    <div className="mr-2">on the</div>
    <div className="w-16 mr-2">
      <FormsySelect
        className="bordered-base-input"
        name="survey_schedule_attributes.recurrence_day_of_month"
        required
        options={dateOrdinalOptions()}
        value={recurrenceDate}
      />
    </div>
    {
      (recurrenceDate?.toString() === todayDayOfMonth())
        && <div className="ml-2">starting next month.</div>
    }
  </div>
)

MonthlyRecurrenceDetails.propTypes = {
  recurrenceDate: types.number,
}

export default MonthlyRecurrenceDetails
