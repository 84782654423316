import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getFacilityAndDepartmentFromDepartmentId } from "reduxSlices/facilitiesSlice"
import TaskDepartmentLabel from "."

const TaskDepartmentByIdLabel = ({ className = "", departmentOption }) => {
  const [facility, department] = useSelector(getFacilityAndDepartmentFromDepartmentId(departmentOption.departmentId))

  return <TaskDepartmentLabel className={className} labelText={`${facility.name}: ${department.name}`} />
}

TaskDepartmentByIdLabel.propTypes = {
  className: types.string,
  departmentOption: types.object.isRequired,
}

export default TaskDepartmentByIdLabel
