import React from "react"
import types from "prop-types"
import clsx from "clsx"
import {
  getFormQuestionHasConditionals,
  getFormQuestionIsLastInSection,
  getFormQuestionType,
  getFormQuestionUuid,
  getFormSectionUuid,
  isFocusOnQuestion,
  setQuestionFocus,
  moveFormQuestion,
} from "reduxSlices/formBuilderSlice"
import { Draggable } from "react-beautiful-dnd"
import { useDispatch, useSelector } from "react-redux"
import { questionTypeSupportsNote, questionTypeSupportsOptions } from "views/Forms/FormElementTypes"
import DeleteQuestionButton from "FormBuilder/UIElements/DeleteQuestionButton"
import CopyQuestionButton from "FormBuilder/UIElements/CopyQuestionButton"
import ConditionalTag from "FormBuilder/UIElements/ConditionalTag"
import DragHandle from "shared/DragHandle"
import QuestionType from "./QuestionType"
import QuestionPrompt from "./QuestionPrompt"
import QuestionDescription from "./QuestionDescription"
import QuestionOptions from "./QuestionOptions"
import QuestionNote from "./QuestionNote"

import * as styles from "./Question.module.scss"

const questionClasses = (focusIsOnQuestion) => clsx(
  styles.questionStyle,
  "bg-white",
  {
    "border-4 border-gray-300": focusIsOnQuestion,
    "rounded-tr-lg rounded-br-lg rounded-bl-lg": focusIsOnQuestion,
    "border border-lightgray-3 rounded-lg": !focusIsOnQuestion,
  },
)
// QuestionSidebar children are rendered in the form builder sidebar
const Question = ({ questionIndex, sectionIndex }) => {
  const dispatch = useDispatch()

  const questionUuid = useSelector(getFormQuestionUuid({ sectionIndex, questionIndex }))
  const questionType = useSelector(getFormQuestionType({ sectionIndex, questionIndex }))
  const questionHasConditionals = useSelector(getFormQuestionHasConditionals({ sectionIndex, questionIndex }))
  const questionIsLastInSection = useSelector(getFormQuestionIsLastInSection({ sectionIndex, questionIndex }))
  const focusIsOnQuestion = useSelector(isFocusOnQuestion({ sectionIndex, questionIndex }))
  const sectionUuid = useSelector(getFormSectionUuid(sectionIndex))

  const setFocusOnQuestion = () => dispatch(setQuestionFocus({ sectionIndex, questionIndex }))

  const moveQuestion = (action) => dispatch(moveFormQuestion(action))

  // The first question in a section cannot move up in the list
  const moveQuestionUp = questionIndex === 0
    ? undefined
    : () => moveQuestion({
      questionUuid,
      from: { sectionUuid, currentIndex: questionIndex },
      to: { sectionUuid, newIndex: questionIndex - 1 },
    })

  // The last question in a section cannot move down in the list
  const moveQuestionDown = questionIsLastInSection
    ? undefined
    : () => moveQuestion({
      questionUuid,
      from: { sectionUuid, currentIndex: questionIndex },
      to: { sectionUuid, newIndex: questionIndex + 1 },
    })

  const textBoxId = `textbox-${questionUuid}`

  return (
    <Draggable key={questionUuid} draggableId={questionUuid} index={questionIndex}>
      {
        (dragProvided) => (
          <div
            className="mt-4"
            ref={dragProvided.innerRef}
            {...dragProvided.draggableProps}
          >
            <QuestionType sectionIndex={sectionIndex} questionIndex={questionIndex} />
            <div className={questionClasses(focusIsOnQuestion)} onClickCapture={setFocusOnQuestion}>
              <DragHandle
                dragProvided={dragProvided}
                moveDown={moveQuestionDown}
                moveUp={moveQuestionUp}
                isFocused={focusIsOnQuestion}
              />
              <div id={textBoxId} className="flex flex-col w-full">
                {
                  questionHasConditionals && (
                    <ConditionalTag className="flex pt-4 pr-4 self-end" />
                  )
                }
                <div className="px-8 py-4">
                  <div className="mb-4">
                    <QuestionPrompt
                      sectionIndex={sectionIndex}
                      questionIndex={questionIndex}
                      textBoxId={textBoxId}
                    />
                    <QuestionDescription
                      sectionIndex={sectionIndex}
                      questionIndex={questionIndex}
                    />
                  </div>
                  {
                    questionTypeSupportsOptions({ questionType }) && (
                      <QuestionOptions
                        sectionIndex={sectionIndex}
                        questionIndex={questionIndex}
                      />
                    )
                  }
                  {
                    questionTypeSupportsNote({ questionType }) && (
                      <QuestionNote
                        sectionIndex={sectionIndex}
                        questionIndex={questionIndex}
                      />
                    )
                  }
                </div>
              </div>
              <div className="flex flex-col justify-center items-end">
                {
                  focusIsOnQuestion && (
                    <>
                      <CopyQuestionButton sectionIndex={sectionIndex} questionIndex={questionIndex} />
                      <DeleteQuestionButton
                        sectionIndex={sectionIndex}
                        questionIndex={questionIndex}
                      />
                    </>
                  )
                }
              </div>
            </div>
          </div>
        )
      }
    </Draggable>
  )
}

Question.propTypes = {
  questionIndex: types.number.isRequired,
  sectionIndex: types.number.isRequired,
}

export default Question
