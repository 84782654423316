import React from "react"
import QuestionTagList from "./QuestionTagList"

export const QUESTION_TAGS_TAB_HEADING = "Question Tags"

const QuestionTagTab = () => (
  <>
    <h2 className="tab-title">{ QUESTION_TAGS_TAB_HEADING }</h2>
    <QuestionTagList />
  </>
)

export default QuestionTagTab
