import React from "react"
import types from "prop-types"
import clsx from "clsx"
import StrikeAround from "shared/StrikeAround"
import { IconButton } from "shared/buttons"

const GroupingAccordionHeader = ({
  numberOfSubmissions = null, groupName, isExpanded, toggleAccordion, className = "",
}) => (
  <div className={clsx(className, "flex-center")}>
    <StrikeAround className="flex-grow">
      <div className="text-xl font-medium text-dark flex items-center">
        <span>{ groupName }</span>
        {
          (numberOfSubmissions || numberOfSubmissions === 0) && (
            <span
              className="ml-2.5 h-8 w-8 rounded-full flex items-center justify-center"
              style={{ background: "rgba(217, 220, 242, 0.5)" }}
            >
              { numberOfSubmissions }
            </span>
          )
        }
      </div>
    </StrikeAround>
    <IconButton
      className="p-3 ml-2"
      icon={`fas fa-chevron-${isExpanded ? "down" : "up"}`}
      onClick={toggleAccordion}
    />
  </div>
)

GroupingAccordionHeader.propTypes = {
  numberOfSubmissions: types.number,
  groupName: types.string.isRequired,
  isExpanded: types.bool.isRequired,
  toggleAccordion: types.func.isRequired,
  className: types.string,
}

export default GroupingAccordionHeader
