import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getFormSectionName, isFocusOnSection } from "reduxSlices/formBuilderSlice"
import QuestionSidebarSection from "views/FormBuilder/QuestionFields/QuestionSidebar/QuestionSidebarSection"
import SectionCondition from "FormBuilder/SectionFields/SectionCondition"

const SectionBranching = ({ sectionIndex }) => {
  const sectionName = useSelector(getFormSectionName(sectionIndex))
  const focusOnSection = useSelector(isFocusOnSection(sectionIndex))

  const supportsBranching = sectionIndex !== 0

  if (!supportsBranching) return null

  return (
    <QuestionSidebarSection
      hide={!focusOnSection}
      title="Section Branching"
    >
      <>
        <p className="mb-2">
          <span>Control whether the </span>
          <strong>{ sectionName }</strong>
          <span> section is shown to someone filling out the form.</span>
        </p>
        <SectionCondition sectionIndex={sectionIndex} />
      </>
    </QuestionSidebarSection>
  )
}

SectionBranching.propTypes = {
  sectionIndex: types.number.isRequired,
}

export default SectionBranching
