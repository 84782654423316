import React from "react"
import { useSurveyScheduledReportExportContext } from "contexts/SurveyScheduledReportExportContext"
import { Checkbox } from "shared/checkboxes"
import { RadioButton } from "shared/inputs"
import MassSelection from "shared/MassSelection"

const NewSurveyScheduledReportExportSurveyConfigurationsFilters = () => {
  const {
    chooseAllCurrentAndFutureConfigurations,
    chooseSpecificConfigurations,
    deselectAllConfigurationIds,
    deselectConfiguration,
    formTitle,
    includeCurrentAndFutureConfigurations,
    isConfigurationSelected,
    selectAllConfigurationIds,
    selectConfiguration,
    surveyConfigurations,
  } = useSurveyScheduledReportExportContext()

  const configurationIsSelected = (id) => isConfigurationSelected(id)

  const toggleSelection = (id) => (configurationIsSelected(id)
    ? () => deselectConfiguration(id)
    : () => selectConfiguration(id))

  return (
    <>
      <RadioButton
        checked={includeCurrentAndFutureConfigurations}
        className="mb-4 last:mb-0"
        label={`Include all current and future configurations for ${formTitle || "this report's form"}`}
        name="choose-all-current-and-future-configs"
        onChange={chooseAllCurrentAndFutureConfigurations}
        value={includeCurrentAndFutureConfigurations}
      />

      <RadioButton
        checked={!includeCurrentAndFutureConfigurations}
        className="mb-4 last:mb-0"
        label="Include specific configurations"
        name="choose-specific-configs"
        onChange={chooseSpecificConfigurations}
        value={!includeCurrentAndFutureConfigurations}
      />

      {
        !includeCurrentAndFutureConfigurations
        && (
          <div className="mt-8">
            <div className="mb-2">
              Survey configurations to include:
            </div>
            <MassSelection deselectAll={deselectAllConfigurationIds} selectAll={selectAllConfigurationIds} className="my-2" />

            {surveyConfigurations.map((configuration) => (
              <div key={configuration.id}>
                <Checkbox
                  className="cursor-pointer mr-2"
                  uuid={configuration.id}
                  value={configurationIsSelected(configuration.id)}
                  onChange={toggleSelection(configuration.id)}
                />
                <label htmlFor={configuration.id} className="cursor-pointer">
                  {configuration.name}
                </label>
              </div>
            ))}
          </div>
        )
      }
    </>
  )
}

export default NewSurveyScheduledReportExportSurveyConfigurationsFilters
