import React from "react"
import { useSolutionLevelReportExportContext } from "contexts/SolutionLevelReportExportContext"
import { yearMonthDayDate } from "utils/dateHelpers"
import { DateInput } from "shared/inputs"

const NewSolutionLevelReportExportSubmissionFilters = () => {
  const {
    earliestSubmissionDate,
    latestSubmissionDate,
    setEarliestSubmissionDate,
    setLatestSubmissionDate,
  } = useSolutionLevelReportExportContext()

  return (
    <>
      <label>
        <span>Earliest Date:</span>
        <DateInput
          className="mb-4"
          clearable
          max={latestSubmissionDate ? yearMonthDayDate(latestSubmissionDate) : yearMonthDayDate()}
          name="solution_level_report_export_earliest_date"
          onChange={setEarliestSubmissionDate}
          value={earliestSubmissionDate}
        />
      </label>
      <label>
        <span>Latest Date:</span>
        <DateInput
          className="mb-4"
          clearable
          min={earliestSubmissionDate ? yearMonthDayDate(earliestSubmissionDate) : null}
          max={yearMonthDayDate()}
          name="solution_level_report_export_latest_date"
          onChange={setLatestSubmissionDate}
          value={latestSubmissionDate}
        />
      </label>
    </>
  )
}

export default NewSolutionLevelReportExportSubmissionFilters
