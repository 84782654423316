import * as FacilityAuditItemDetails from "views/Facilities/FacilityTabs/AuditTrailTab/AuditItem/AuditItemDetails"
import * as AuditItemDetails from "views/FormSubmission/FormSubmissionTabs/AuditTrailContent/AuditItem/AuditItemDetails"
import { weekDayMonthDate, isToday } from "./dateHelpers"

const FORM_SUBMISSION_AUDIT_TYPE = "FORM_SUBMISSION_AUDIT_TYPE"
const TASK_AUDIT_TYPE = "TASK_AUDIT_TYPE"

/* eslint-disable max-len */
// Property names match return values from auditTypeCanonicalName()
const AUDIT_DATA = {
  "formsubmission-create": {
    auditItemDetailsComponent: AuditItemDetails.FormSubmissionCreatedAuditItemDetails,
    auditType: FORM_SUBMISSION_AUDIT_TYPE,
  },
  "formsubmission-edited": {
    auditItemDetailsComponent: AuditItemDetails.FormSubmissionEditedAuditItemDetails,
    auditType: FORM_SUBMISSION_AUDIT_TYPE,
  },
  "formsubmission-export": {
    auditItemDetailsComponent: AuditItemDetails.FormSubmissionExportedAuditItemDetails,
    auditType: FORM_SUBMISSION_AUDIT_TYPE,
  },
  "formsubmission-export-formsubmissionreport": {
    auditItemDetailsComponent: AuditItemDetails.FormSubmissionExportedInFormSubmissionReportAuditItemDetails,
    auditType: FORM_SUBMISSION_AUDIT_TYPE,
  },
  "formsubmission-export-solutionlevelreport": {
    auditItemDetailsComponent: AuditItemDetails.FormSubmissionExportedInSolutionLevelReportAuditItemDetails,
    auditType: FORM_SUBMISSION_AUDIT_TYPE,
  },
  "formsubmission-form_submission_email_sent": {
    auditItemDetailsComponent: AuditItemDetails.FormSubmissionEmailSentAuditItemDetails,
    auditType: FORM_SUBMISSION_AUDIT_TYPE,
  },
  "formsubmission-form_type_change": {
    auditItemDetailsComponent: AuditItemDetails.FormSubmissionFormTypeChangedAuditItemDetails,
    auditType: FORM_SUBMISSION_AUDIT_TYPE,
  },
  "formsubmission-update": {
    auditItemDetailsComponent: AuditItemDetails.FormSubmissionUpdatedAuditItemDetails,
    auditType: FORM_SUBMISSION_AUDIT_TYPE,
  },
  "formsubmission-view": {
    auditItemDetailsComponent: AuditItemDetails.FormSubmissionViewedAuditItemDetails,
    auditType: FORM_SUBMISSION_AUDIT_TYPE,
  },
  "formsubmission-workflow_failure-workflow": {
    auditItemDetailsComponent: AuditItemDetails.FormSubmissionWorkflowFailedAuditItemDetails,
    auditType: FORM_SUBMISSION_AUDIT_TYPE,
  },
  "task-answered": {
    auditItemDetailsComponent: AuditItemDetails.TaskAnsweredAuditItemDetails,
    auditType: TASK_AUDIT_TYPE,
  },
  "task-create": {
    auditItemDetailsComponent: AuditItemDetails.TaskCreatedAuditItemDetails,
    auditType: TASK_AUDIT_TYPE,
  },
  "task-export-formsubmission": {
    auditItemDetailsComponent: AuditItemDetails.TaskExportedInFormSubmissionAuditItemDetails,
    auditType: TASK_AUDIT_TYPE,
  },
  "task-export-formsubmissionreport": {
    auditItemDetailsComponent: AuditItemDetails.TaskExportedInFormSubmissionReportAuditItemDetails,
    auditType: TASK_AUDIT_TYPE,
  },
  "task-export-solutionlevelreport": {
    auditItemDetailsComponent: AuditItemDetails.TaskExportedInSolutionLevelReportAuditItemDetails,
    auditType: TASK_AUDIT_TYPE,
  },
  "task-task_email_sent-formsubmission": {
    auditItemDetailsComponent: AuditItemDetails.TaskEmailSentAuditItemDetails,
    auditType: TASK_AUDIT_TYPE,
  },
  "task-update": {
    auditItemDetailsComponent: AuditItemDetails.TaskUpdatedAuditItemDetails,
    auditType: TASK_AUDIT_TYPE,
  },
  "task-view": {
    auditItemDetailsComponent: AuditItemDetails.TaskViewedAuditItemDetails,
    auditType: TASK_AUDIT_TYPE,
  },
  "taskuser-create-task": {
    auditItemDetailsComponent: AuditItemDetails.TaskUserCreatedAuditItemDetails,
    auditType: TASK_AUDIT_TYPE,
  },
  "taskuser-destroy-task": {
    auditItemDetailsComponent: AuditItemDetails.TaskUserRemovedAuditItemDetails,
    auditType: TASK_AUDIT_TYPE,
  },
  "accesslevelgrant-access_level_grant_access_level_change-formsubmission": {
    auditItemDetailsComponent: AuditItemDetails.AccessLevelGrantAccessLevelChangedAuditItemDetails,
    auditType: FORM_SUBMISSION_AUDIT_TYPE,
  },
  "accesslevelgrant-create-formsubmission": {
    auditItemDetailsComponent: AuditItemDetails.AccessLevelGrantCreatedAuditItemDetails,
    auditType: FORM_SUBMISSION_AUDIT_TYPE,
  },
  "accesslevelgrant-destroy-formsubmission": {
    auditItemDetailsComponent: AuditItemDetails.AccessLevelGrantRemovedAuditItemDetails,
    auditType: FORM_SUBMISSION_AUDIT_TYPE,
  },
  "accesslevelgrant-update-formsubmission": {
    auditItemDetailsComponent: AuditItemDetails.AccessLevelGrantUpdatedAuditItemDetails,
    auditType: FORM_SUBMISSION_AUDIT_TYPE,
  },
  "activestorage::attachment-create-formsubmission": {
    auditItemDetailsComponent: AuditItemDetails.AttachmentCreatedAuditItemDetails,
    auditType: FORM_SUBMISSION_AUDIT_TYPE,
  },
  "activestorage::attachment-destroy-formsubmission": {
    auditItemDetailsComponent: AuditItemDetails.AttachmentRemovedAuditItemDetails,
    auditType: FORM_SUBMISSION_AUDIT_TYPE,
  },
  "formsubmissionrelationship-create-formsubmission": {
    auditItemDetailsComponent: AuditItemDetails.RelatedFormSubmissionCreatedAuditItemDetails,
    auditType: FORM_SUBMISSION_AUDIT_TYPE,
  },
  "formsubmissionrelationship-update-formsubmission": {
    auditItemDetailsComponent: AuditItemDetails.RelatedFormSubmissionUpdatedAuditItemDetails,
    auditType: FORM_SUBMISSION_AUDIT_TYPE,
  },
  "facility-create": {
    auditItemDetailsComponent: FacilityAuditItemDetails.FacilityCreated,
  },
  "facility-update": {
    auditItemDetailsComponent: FacilityAuditItemDetails.FacilityUpdated,
  },
  "department-create-facility": {
    auditItemDetailsComponent: FacilityAuditItemDetails.DepartmentCreated,
  },
  "department-update-facility": {
    auditItemDetailsComponent: FacilityAuditItemDetails.DepartmentUpdated,
  },
  "departmentmembership-create-department": {
    auditItemDetailsComponent: FacilityAuditItemDetails.DepartmentMembershipCreated,
  },
  "departmentmembership-destroy-department": {
    auditItemDetailsComponent: FacilityAuditItemDetails.DepartmentMembershipDestroyed,
  },
  "departmentmembership-update-department": {
    auditItemDetailsComponent: FacilityAuditItemDetails.DepartmentMembershipUpdated,
  },
}
/* eslint-enable max-len */

export const auditTypeCanonicalName = (audit) => {
  const { action, auditableType, associatedType } = audit

  return (
    `${auditableType}-${action}${associatedType ? `-${associatedType}` : ""}`.toLowerCase()
  )
}

export const groupAudit = (audits) => audits.reduce(
  (acc, audit) => {
    const formattedDate = weekDayMonthDate(audit.createdAt)
    if (isToday(audit.createdAt)) {
      if (acc.Today) {
        acc.Today.push(audit)
      } else {
        acc.Today = [audit]
      }
    } else if (acc[formattedDate]) {
      acc[formattedDate].push(audit)
    } else {
      acc[formattedDate] = [audit]
    }
    return acc
  },
  {},
)

export const auditItemDetailsComponent = (audit) => (
  AUDIT_DATA[auditTypeCanonicalName(audit)]?.auditItemDetailsComponent || AuditItemDetails.FallbackAuditItemDetails
)

export const auditForFormSubmission = (audit) => (
  AUDIT_DATA[auditTypeCanonicalName(audit)]?.auditType === FORM_SUBMISSION_AUDIT_TYPE
)

export const auditForATask = (audit) => (
  AUDIT_DATA[auditTypeCanonicalName(audit)]?.auditType === TASK_AUDIT_TYPE
)
