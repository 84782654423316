import React, { useState } from "react"
import Formsy from "formsy-react"
import { useSelector } from "react-redux"
import { getFormBuilder, addWorkflow, hideWorkflowForm } from "reduxSlices/formBuilderSlice"
import { store } from "store"
import * as API from "services/api"
import { Button, PrimaryButton } from "shared/buttons"
import { DEFAULT_OPERATOR } from "utils/workflowHelpers"
import { useSubmit } from "hooks"
import { DataCollectorStep } from "shared/dataCollector"
import * as WorkflowFields from "./WorkflowFields"

const NewWorkflowForm = () => {
  const { newWorkflowName } = useSelector(getFormBuilder)
  const { form } = useSelector(getFormBuilder)
  const { isSubform, slug: formSlug } = form

  const initialWorkflow = {
    name: newWorkflowName,
    onetime: false,
    conditions: { operator: DEFAULT_OPERATOR, conditions: [] },
  }
  const [workflow, setWorkflow] = useState(initialWorkflow)
  const { canSubmit, enableSubmit, disableSubmit } = useSubmit()

  const hideForm = () => store.dispatch(hideWorkflowForm())

  const createWorkflow = async () => {
    const response = await API.createFormWorkflow({ formSlug, workflow })

    if (response.ok) {
      store.dispatch(addWorkflow(response.data))
    } else {
      console.error("Error creating workflow: ", response)
    }

    hideForm()
  }

  return (
    <Formsy
      onSubmit={createWorkflow}
      onValidSubmit={hideForm}
      onValid={enableSubmit}
      onInvalid={disableSubmit}
    >
      <DataCollectorStep stepNumber={1}>
        <WorkflowFields.Name name={workflow.name} setWorkflow={setWorkflow} />
      </DataCollectorStep>

      <DataCollectorStep stepNumber={2} heading="Trigger">
        {
          isSubform ? (
            <WorkflowFields.TaskFormTrigger
              trigger={workflow.trigger}
              setWorkflow={setWorkflow}
            />
          ) : (
            <WorkflowFields.FormTrigger
              trigger={workflow.trigger}
              setWorkflow={setWorkflow}
              frequency={workflow.frequency}
            />
          )
        }
      </DataCollectorStep>

      <DataCollectorStep stepNumber={3} heading="If">
        <WorkflowFields.Conditions
          conditions={workflow.conditions}
          isSubform={isSubform}
          onetime={workflow.onetime}
          setWorkflow={setWorkflow}
        />
      </DataCollectorStep>

      <DataCollectorStep stepNumber={4} heading="Then">
        <WorkflowFields.Actions
          formSlug={formSlug}
          actions={workflow.actions}
          setWorkflow={setWorkflow}
          name={workflow.name}
        />
      </DataCollectorStep>

      <DataCollectorStep stepNumber={5}>
        <WorkflowFields.Active
          active={workflow.active}
          setWorkflow={setWorkflow}
        />
      </DataCollectorStep>

      <div className="flex justify-center pt-6 pb-4">
        <Button className="border border-light mr-2" text="Cancel" onClick={hideForm} />
        <PrimaryButton text="Save" type="submit" disabled={!canSubmit} />
      </div>
    </Formsy>
  )
}

export default NewWorkflowForm
