import React from "react"
import types from "prop-types"

const highlightText = ({
  index, prevStartMatchIndex, value, search, matchIndex, matches,
}) => (
  <span key={index}>
    <span>{ value.slice(prevStartMatchIndex, index) }</span>
    <span className="font-extrabold">{ value.slice(index, index + search.length) }</span>
    {
      (matchIndex === matches.length - 1) && (
        <span>{ value.slice(index + search.length) }</span>
      )
    }
  </span>
)

const MatchedOptionText = ({ search, value }) => {
  if (!search) {
    return <span className="mt-1">{ value }</span>
  }

  const regexp = new RegExp(search, "ig")
  const matches = [...value.matchAll(regexp)]

  let prevStartMatchIndex = 0

  return (
    matches.map(({ index }, matchIndex) => {
      const HighlightedText = highlightText({
        index, prevStartMatchIndex, value, search, matchIndex, matches,
      })

      prevStartMatchIndex = index + search.length
      return HighlightedText
    })
  )
}

MatchedOptionText.propTypes = {
  search: types.string.isRequired,
  value: types.string.isRequired,
}

export default MatchedOptionText
