import React from "react"
import types from "prop-types"
import clsx from "clsx"
import uniqueRandomIndexes from "utils/uniqueRandomIndexes"
import {
  FORM_ICONS, FORM_ICON_COLORS,
} from "views/FormBuilder/FormBuilderTabs/FormSettingsTab/IconSetting/IconSelector/constants"
import { FormIcon } from "shared/icons"

const ICON_COUNT = 3

const ActivityIndicator = ({ className = "" }) => {
  const formIconIndexes = uniqueRandomIndexes({ count: ICON_COUNT, arraySize: FORM_ICONS.length })
  const formIcons = formIconIndexes.map((iconIndex) => FORM_ICONS[iconIndex])

  const formIconColorIndexes = uniqueRandomIndexes({ count: ICON_COUNT, arraySize: FORM_ICON_COLORS.length })
  const formColors = formIconColorIndexes.map((colorIndex) => FORM_ICON_COLORS[colorIndex])

  return (
    <div className={clsx(className, "activity-indicator")}>
      {
        formIcons.map((formIcon, index) => (
          <div key={formIcon} className={`bounce-${index}`}>
            <FormIcon
              className="w-12 h-12"
              form={{ icon: formIcon, iconColor: formColors[index] }}
            />
          </div>
        ))
      }
    </div>
  )
}

ActivityIndicator.propTypes = {
  className: types.string,
}

export default ActivityIndicator
