import React from "react"
import types from "prop-types"
import { isEqual } from "lodash-es"
import { useDispatch, useSelector } from "react-redux"
import {
  getFormQuestionAttributeDefaultFacility,
  getFormQuestionAttributeDefaultFacilityOptions,
  setQuestionAttributeDefaultFacility,
} from "reduxSlices/formBuilderSlice"
import { ListSelect } from "shared/selects"
import InactiveOptionsItemRenderer from "shared/selects/InactiveOptionsItemRenderer"
import ContentRenderer from "./ContentRenderer"

const optionsFromList = (options) => options.map((opt) => (
  { label: opt.name, value: opt.id, active: opt.active }
))

const DefaultFacility = ({ sectionIndex, questionIndex }) => {
  const dispatch = useDispatch()

  const questionDefaultFacility = useSelector(getFormQuestionAttributeDefaultFacility({ sectionIndex, questionIndex }))
  const questionDefaultFacilityOptions = useSelector(getFormQuestionAttributeDefaultFacilityOptions({ sectionIndex, questionIndex }), isEqual)

  const updateDefaultFacility = (newDefaultFacilityId) => dispatch(setQuestionAttributeDefaultFacility({
    sectionIndex, questionIndex, newDefaultFacilityId,
  }))

  return (
    <>
      <div className="flex-center mb-4">
        <div className="font-medium">Default Facility</div>
      </div>
      <ListSelect
        className="text-sm"
        clearable
        contentRenderer={ContentRenderer}
        itemRenderer={InactiveOptionsItemRenderer}
        onChange={updateDefaultFacility}
        options={questionDefaultFacilityOptions}
        optionsFromList={optionsFromList}
        style={{ height: "32px" }}
        value={questionDefaultFacility}
      />
    </>
  )
}

DefaultFacility.propTypes = {
  sectionIndex: types.number.isRequired,
  questionIndex: types.number.isRequired,
}

export default DefaultFacility
