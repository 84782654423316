import React from "react"
import { PrimaryButton } from "shared/buttons"
import UndeleteButton from "views/FormBuilder/UIElements/UndeleteButton"
import { useSelector } from "react-redux"
import { isDeletedQuestionPresent } from "reduxSlices/formBuilderSlice"
import FormPreview from "./FormPreview"

const DesignControls = () => {
  const questionToRestore = useSelector(isDeletedQuestionPresent)

  return (
    <div className="flex items-center">
      {
        questionToRestore && (
          <UndeleteButton className="mr-2" />
        )
      }
      <FormPreview className="mr-2" />
      <PrimaryButton
        form="form-builder"
        text="Save"
        type="submit"
      />
    </div>
  )
}

export default DesignControls
