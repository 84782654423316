import React from "react"
import types from "prop-types"
import { adminUserShowDepartmentMemberships } from "utils/propTypeShapes"
import FacilitiesWithDepartmentMemberships from "./FacilitiesWithDepartmentMemberships"

function LocationTab({
  user, facilities, removeDepartmentMembership, updateDepartmentMembershipSegment,
}) {
  const { departmentMemberships } = user

  return (
    <>
      <h1 className="tab-title">Departments</h1>
      {
        (departmentMemberships.length === 0)
          ? (
            <span className="font-light">
              This user is not a member of any departments.
            </span>
          )
          : facilities.map((facility) => {
            const filteredMemberships = departmentMemberships.filter(({ department }) => department.facilityId === facility.id)

            if (filteredMemberships.length === 0) return null

            return (
              <FacilitiesWithDepartmentMemberships
                key={facility.id}
                user={user}
                departmentMemberships={filteredMemberships}
                facility={facility}
                removeDepartmentMembership={removeDepartmentMembership}
                updateDepartmentMembershipSegment={updateDepartmentMembershipSegment}
              />
            )
          })
      }
    </>
  )
}

LocationTab.propTypes = {
  user: types.shape({
    id: types.number,
    departmentMemberships: adminUserShowDepartmentMemberships,
    email: types.string,
    first_name: types.string,
    last_name: types.string,
    locked: types.bool,
    role: types.string,
  }).isRequired,
  facilities: types.arrayOf(types.object).isRequired,
  removeDepartmentMembership: types.func.isRequired,
  updateDepartmentMembershipSegment: types.func.isRequired,
}

export default LocationTab
