import React from "react"
import { questionShape } from "utils/propTypeShapes"

const FormVersionDeletedQuestionDetails = ({ question }) => (
  <div className="flex flex-col justify-center">
    <span className="mb-1 text-dark font-medium text-lg">{question.prompt}</span>
    <span className="mb-1 text-dark font-small text-md">{question.description}</span>
    <p>Question Type: {question.type}</p>
  </div>
)

FormVersionDeletedQuestionDetails.propTypes = {
  question: questionShape.isRequired,
}

export default FormVersionDeletedQuestionDetails
