import React from "react"
import { taskShape } from "utils/propTypeShapes"
import { MULTI_ENTRIES_TYPE } from "Forms/FormElementTypes"
import { makeQuestion } from "utils/formDataHelpers"
import { GENERAL_TASK_MULTI_ENTRY_QUESTION_UUID } from "./constants"
import TaskQuestion from "./TaskQuestion"

const multiEntryQuestion = makeQuestion({
  uuid: GENERAL_TASK_MULTI_ENTRY_QUESTION_UUID,
  type: MULTI_ENTRIES_TYPE,
})

const TaskGeneralResponse = ({ task }) => (
  <TaskQuestion
    className="w-full mb-6"
    question={multiEntryQuestion}
    task={task}
  />
)

TaskGeneralResponse.propTypes = {
  task: taskShape.isRequired,
}

export default TaskGeneralResponse
