/* eslint-disable camelcase */
import React from "react"
import types from "prop-types"
import { taskShape, questionShape, userOptionShape } from "utils/propTypeShapes"
import { orgLocalDateTime } from "utils/dateHelpers"
import { ProfileIcon } from "shared/icons"
import SubformAnswerEntryMenu from "./SubformAnswerEntryMenu"

const SubformAnswerEntryCard = ({ task, question, entry }) => {
  const {
    answer, created_at, user,
  } = entry

  return (
    <div className="flex lg:rounded-lg bg-light-100 border-b lg:border lg:border-lightgray-3 last:border-b-0 lg:last:border-b mb-6 lg:p-3 pb-6 last:pb-0 lg:last:pb-3">
      <ProfileIcon className="hidden lg:flex shrink-0 mr-3" user={user} />
      <div className="grow">
        <div className="grid grid-cols-1-auto lg:gap-4 w-full mb-2">
          <span className="flex flex-col lg:flex-row lg:justify-between">
            <span className="grow mr-4 font-semibold text-sm break-anywhere">
              { user.fullName }
            </span>
            <span className="shrink-0 text-xs lg:text-sm text-primary-300">
              { orgLocalDateTime(created_at) }
            </span>
          </span>
          <SubformAnswerEntryMenu
            className="shrink-0"
            task={task}
            question={question}
            entry={entry}
          />
        </div>
        <div className="whitespace-pre-wrap break-anywhere">
          { answer }
        </div>
      </div>
    </div>
  )
}

SubformAnswerEntryCard.propTypes = {
  task: taskShape.isRequired,
  question: questionShape.isRequired,
  entry: types.shape({
    answer: types.string.isRequired,
    created_at: types.string.isRequired,
    uuid: types.string.isRequired,
    user: userOptionShape.isRequired,
  }).isRequired,
}

export default SubformAnswerEntryCard
