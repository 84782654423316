import React from "react"
import types from "prop-types"
import { NumberInput } from "shared/inputs"
import { workflowConditionShape } from "utils/propTypeShapes"
import FormsyValidation from "shared/FormsyValidation"

const DefineSubmissionAgeValue = ({ condition, changeConditionValue, conditionIndex }) => {
  const updateValue = (days) => changeConditionValue({ days })

  return (
    <>
      <span className="relative">
        <NumberInput
          placeholder="Number"
          onChange={updateValue}
          className="w-24 bordered-base-input h-8"
          value={condition.value?.days}
        />
        <FormsyValidation
          name={`conditions[${conditionIndex}].value.days`}
          value={condition.value?.days ?? 0}
          validations={{ isNumberGreaterThanOrEqualTo: { min: 1, errorMessage: "Must be at least 1" } }}
          validationErrors={{ isDefaultRequiredValue: "required" }}
        />
      </span>
      <span className="min-w-max">day(s) old or older</span>
    </>
  )
}

DefineSubmissionAgeValue.propTypes = {
  condition: workflowConditionShape.isRequired,
  changeConditionValue: types.func.isRequired,
  conditionIndex: types.number.isRequired,
}

export default DefineSubmissionAgeValue
