import React from "react"
import types from "prop-types"
import clsx from "clsx"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const OptionDragHandle = ({ isHidden, dragProvided }) => (
  <span
    {...dragProvided.dragHandleProps}
    className={clsx(
      isHidden && "pointer-events-none",
      "absolute -left-6",
    )}
    tabIndex={null}
  >
    <SvgSpriteIcon
      iconName="drag-handle"
      className={clsx(isHidden && "hidden")}
    />
  </span>
)

OptionDragHandle.propTypes = {
  isHidden: types.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  dragProvided: types.object.isRequired,
}

export default OptionDragHandle
