import React from "react"
import { useSelector } from "react-redux"
import { store } from "store"
import { RadioButton } from "shared/inputs"
import { SidebarCollapsibleContent } from "shared/Sidebar"
import { getSurveyBatchTable, setStatusDisplay, STATUS_DISPLAY_OPTIONS } from "reduxSlices/surveyBatchTableSlice"

const BatchStatusFilter = () => {
  const { statusDisplay } = useSelector(getSurveyBatchTable)
  const statusDisplaySelect = (column) => () => store.dispatch(setStatusDisplay(column))

  return (
    <SidebarCollapsibleContent title="Display">
      {
        STATUS_DISPLAY_OPTIONS.map(({ label, value }) => (
          <div
            key={value}
            className="mb-2 rounded-md cursor-pointer p-2 border border-lightgray-3 text-sm font-medium"
            onClickCapture={statusDisplaySelect(value)}
          >
            <RadioButton
              className="flex-row-reverse justify-between"
              label={label}
              value={value}
              onChange={statusDisplaySelect(value)}
              checked={value === statusDisplay}
            />
          </div>
        ))
      }
    </SidebarCollapsibleContent>
  )
}

export default BatchStatusFilter
