/* eslint-disable react/forbid-prop-types */
import React, { useState } from "react"
import clsx from "clsx"
import types from "prop-types"
import { PrimaryButton, TertiaryButton } from "shared/buttons"
import IconList from "./IconList"
import IconColorList from "./IconColorList"

const IconSelectorDropdown = ({
  props: {
    iconOptions, colorOptions, updateIcon, icon, iconColor,
  },
  methods: { dropDown },
}) => {
  const [selectedIcon, setSelectedIcon] = useState(icon)
  const [selectedIconColor, setSelectedIconColor] = useState(iconColor)
  const closeDropdown = () => dropDown("close")

  const saveFormIcon = () => {
    updateIcon({
      icon: selectedIcon,
      iconColor: selectedIconColor,
    })
    closeDropdown()
  }

  return (
    <div>
      <div className={clsx(
        "h-10 leading-10 mb-3",
        "font-bold text-center text-dark",
        "border-b border-lightgray-3",
      )}
      >
        Select an icon and a background color
      </div>
      <IconList
        iconOptions={iconOptions}
        selectedIcon={selectedIcon}
        setSelectedIcon={setSelectedIcon}
      />
      <IconColorList
        colorOptions={colorOptions}
        selectedIconColor={selectedIconColor}
        setSelectedIconColor={setSelectedIconColor}
      />
      <div className="flex items-center justify-center py-3 mx-auto border-t border-lightgray-3">
        <TertiaryButton
          className="mr-2"
          text="Cancel"
          onClick={closeDropdown}
        />
        <PrimaryButton text="Set Icon" onClick={saveFormIcon} />
      </div>
    </div>
  )
}

IconSelectorDropdown.propTypes = {
  props: types.object.isRequired,
  methods: types.object.isRequired,
}

export default IconSelectorDropdown
