import React from "react"
import types from "prop-types"
import { sectionShape } from "utils/propTypeShapes"
import SectionBranchingConditionalCheck from "components/views/Forms/FormElements/SectionBranchingConditionalCheck"
import SubmissionEditQuestions from "./SubmissionEditQuestions"
import SubmissionSection from "../../SubmissionSection"

const SubmissionEditSection = ({ section, formAnswers, isFirstSection }) => {
  const { questions } = section

  return (
    <SectionBranchingConditionalCheck
      isFirstSection={isFirstSection}
      formAnswers={formAnswers}
      section={section}
    >
      <SubmissionSection section={section}>
        <SubmissionEditQuestions questions={questions} formAnswers={formAnswers} />
      </SubmissionSection>
    </SectionBranchingConditionalCheck>
  )
}

SubmissionEditSection.propTypes = {
  section: sectionShape.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formAnswers: types.object.isRequired,
  isFirstSection: types.bool.isRequired,
}

export default SubmissionEditSection
