import { sortBy } from "lodash-es"
import {
  PATIENT_FIRST_NAME_QUESTION_TAG,
  PATIENT_LAST_NAME_QUESTION_TAG,
  PATIENT_FULL_NAME_QUESTION_TAG,
  PATIENT_MEDICAL_RECORD_NUMBER_QUESTION_TAG,
  PATIENT_SEX_QUESTION_TAG,
  PATIENT_DATE_OF_BIRTH_QUESTION_TAG,
  PATIENT_ADDRESS_STREET_ONE_QUESTION_TAG,
  PATIENT_ADDRESS_STREET_TWO_QUESTION_TAG,
  PATIENT_ADDRESS_CITY_QUESTION_TAG,
  PATIENT_ADDRESS_STATE_QUESTION_TAG,
  PATIENT_ADDRESS_ZIP_CODE_QUESTION_TAG,
  PATIENT_CELL_PHONE_QUESTION_TAG,
  PATIENT_HOME_PHONE_QUESTION_TAG,
} from "./patientQuestionTags"

class PatientDataReader {
  constructor(fhirPatientData) {
    this.fhirPatientData = fhirPatientData
  }

  // Meditech patient name data does not appear to have a "use" property,
  // so we fall back to the name listed first.
  patientOfficialNameNode() {
    return this.fhirPatientData?.name?.find((node) => (
      node.use?.match(/official/i)
    )) || this.fhirPatientData?.name?.[0]
  }

  // Cerner and Meditech patient identifiers contain a "type" property
  // with a "coding" list.  We look for a matching coding display value.
  // Based on sandbox data, Epic patient identifiers have a "type" property,
  // but they are without a "coding" list.  We match on the "text" property instead.
  patientMedicalRecordNode() {
    return this.fhirPatientData?.identifier?.find((node) => (
      node.type?.coding?.[0]?.display?.match(/(mrn)|(medical\srecord\snumber)/i)
      || node.type?.text?.match(/mrn/i)
    ))
  }

  patientAddressNode() {
    const homeAddress = this.fhirPatientData?.address?.find((node) => (
      node.use?.match(/home/i)
    ))

    return homeAddress || this.fhirPatientData?.address?.[0]
  }

  patientCellPhoneNode() {
    const mobileNumbers = this.fhirPatientData?.telecom?.filter((node) => (
      node.system?.match(/phone/i) && node.use?.match(/mobile/i)
    ))

    return mobileNumbers ? sortBy(mobileNumbers, ["rank"])[0] : undefined
  }

  patientHomePhoneNode() {
    const homeNumbers = this.fhirPatientData?.telecom?.filter((node) => (
      node.system?.match(/phone/i) && node.use?.match(/home/i)
    ))

    return homeNumbers ? sortBy(homeNumbers, ["rank"])[0] : undefined
  }

  get [PATIENT_FIRST_NAME_QUESTION_TAG]() {
    return this.patientOfficialNameNode()?.given?.join(" ") ?? ""
  }

  get [PATIENT_LAST_NAME_QUESTION_TAG]() {
    return this.patientOfficialNameNode()?.family ?? ""
  }

  get [PATIENT_FULL_NAME_QUESTION_TAG]() {
    return this.patientOfficialNameNode()?.text ?? ""
  }

  get [PATIENT_MEDICAL_RECORD_NUMBER_QUESTION_TAG]() {
    return this.patientMedicalRecordNode()?.value ?? ""
  }

  get [PATIENT_SEX_QUESTION_TAG]() {
    return this.fhirPatientData?.gender ?? ""
  }

  get [PATIENT_DATE_OF_BIRTH_QUESTION_TAG]() {
    return this.fhirPatientData?.birthDate ?? ""
  }

  get [PATIENT_ADDRESS_STREET_ONE_QUESTION_TAG]() {
    return this.patientAddressNode()?.line?.[0] ?? ""
  }

  get [PATIENT_ADDRESS_STREET_TWO_QUESTION_TAG]() {
    return this.patientAddressNode()?.line?.[1] ?? ""
  }

  get [PATIENT_ADDRESS_CITY_QUESTION_TAG]() {
    return this.patientAddressNode()?.city ?? ""
  }

  get [PATIENT_ADDRESS_STATE_QUESTION_TAG]() {
    return this.patientAddressNode()?.state ?? ""
  }

  get [PATIENT_ADDRESS_ZIP_CODE_QUESTION_TAG]() {
    return this.patientAddressNode()?.postalCode ?? ""
  }

  get [PATIENT_CELL_PHONE_QUESTION_TAG]() {
    return this.patientCellPhoneNode()?.value ?? ""
  }

  get [PATIENT_HOME_PHONE_QUESTION_TAG]() {
    return this.patientHomePhoneNode()?.value ?? ""
  }
}

export default PatientDataReader
