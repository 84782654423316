import { dayjsOrgTz } from "utils/dateHelpers"

const sortAscending = (val1, val2) => val1.localeCompare(val2)
const sortDescending = (val1, val2) => val2.localeCompare(val1)

export const sortByText = (items, { column, isAsc }) => items.sort((sub1, sub2) => {
  const val1 = sub1[column.value] ?? ""
  const val2 = sub2[column.value] ?? ""
  if (isAsc) return sortAscending(val1, val2)
  return sortDescending(val1, val2)
})

export const sortByArray = (items, { column, isAsc }) => items.sort((sub1, sub2) => {
  const val1 = sub1[column.value]?.toString() ?? ""
  const val2 = sub2[column.value]?.toString() ?? ""
  if (isAsc) return sortAscending(val1, val2)
  return sortDescending(val1, val2)
})

export const sortByBoolean = (items, { isAsc, column }) => items.sort((sub1, sub2) => {
  const val1 = sub1[column.value]
  const val2 = sub2[column.value]
  if (isAsc) return val2 - val1
  return val1 - val2
})

export const sortByFormName = (items, { isAsc }) => items.sort((sub1, sub2) => {
  const val1 = sub1.form.title
  const val2 = sub2.form.title
  if (isAsc) return sortAscending(val1, val2)
  return sortDescending(val1, val2)
})

export const sortByNumber = (items, { isAsc, column }) => items.sort((sub1, sub2) => {
  const val1 = sub1[column.value]
  const val2 = sub2[column.value]
  if (isAsc) return val1 - val2
  return val2 - val1
})

const sortAscendingDateTime = (val1, val2) => {
  if (dayjsOrgTz(val1).isBefore(val2)) return -1
  if (dayjsOrgTz(val1).isAfter(val2)) return 1
  return 0
}

const sortDescendingDateTime = (val1, val2) => {
  if (dayjsOrgTz(val1).isAfter(val2)) return -1
  if (dayjsOrgTz(val1).isBefore(val2)) return 1
  return 0
}

export const sortByDateTime = (items, { isAsc, column }) => items.sort((sub1, sub2) => {
  if (isAsc) return sortAscendingDateTime(sub1[column.value], sub2[column.value])
  return sortDescendingDateTime(sub1[column.value], sub2[column.value])
})

export const sortByAssigneeCount = (items, { isAsc }) => items.sort((sub1, sub2) => {
  const val1 = sub1.activeAssigneeNames.length
  const val2 = sub2.activeAssigneeNames.length
  if (isAsc) return val1 - val2
  return val2 - val1
})

export const sortByMemberCount = (items, { isAsc }) => items.sort((sub1, sub2) => {
  const val1 = sub1.activeMemberNames.length
  const val2 = sub2.activeMemberNames.length
  if (isAsc) return val1 - val2
  return val2 - val1
})
